/* tslint:disable */
/* eslint-disable */
/**
 * PetCloudBackend
 * API implementation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AbstractAddressResponse
 */
export interface AbstractAddressResponse {
    /**
     * 
     * @type {string}
     * @memberof AbstractAddressResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AbstractAddressResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AbstractAddressResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractAddressResponse
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractAddressResponse
     */
    'streetNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractAddressResponse
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof AbstractAddressResponse
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof AbstractAddressResponse
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractAddressResponse
     */
    'additionalAddressLine1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractAddressResponse
     */
    'additionalAddressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractAddressResponse
     */
    'countryId'?: string;
    /**
     * 
     * @type {CountryResponse}
     * @memberof AbstractAddressResponse
     */
    'country'?: CountryResponse | null;
}
/**
 * 
 * @export
 * @interface AbstractAddressResponseAllOf
 */
export interface AbstractAddressResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof AbstractAddressResponseAllOf
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractAddressResponseAllOf
     */
    'streetNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractAddressResponseAllOf
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof AbstractAddressResponseAllOf
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof AbstractAddressResponseAllOf
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractAddressResponseAllOf
     */
    'additionalAddressLine1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractAddressResponseAllOf
     */
    'additionalAddressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractAddressResponseAllOf
     */
    'countryId'?: string;
    /**
     * 
     * @type {CountryResponse}
     * @memberof AbstractAddressResponseAllOf
     */
    'country'?: CountryResponse | null;
}
/**
 * 
 * @export
 * @interface AbstractAssetResponse
 */
export interface AbstractAssetResponse {
    /**
     * 
     * @type {string}
     * @memberof AbstractAssetResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AbstractAssetResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AbstractAssetResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractAssetResponse
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof AbstractAssetResponse
     */
    'uri': string;
}
/**
 * 
 * @export
 * @interface AbstractAssetResponseAllOf
 */
export interface AbstractAssetResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof AbstractAssetResponseAllOf
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof AbstractAssetResponseAllOf
     */
    'uri': string;
}
/**
 * 
 * @export
 * @interface AbstractBaseResponse
 */
export interface AbstractBaseResponse {
    /**
     * 
     * @type {string}
     * @memberof AbstractBaseResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AbstractBaseResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AbstractBaseResponse
     */
    'updatedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface AbstractCreateAddressRequest
 */
export interface AbstractCreateAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof AbstractCreateAddressRequest
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractCreateAddressRequest
     */
    'streetNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractCreateAddressRequest
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof AbstractCreateAddressRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof AbstractCreateAddressRequest
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractCreateAddressRequest
     */
    'additionalAddressLine1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractCreateAddressRequest
     */
    'additionalAddressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractCreateAddressRequest
     */
    'countryId'?: string;
}
/**
 * 
 * @export
 * @interface AbstractOpenAPISchema
 */
export interface AbstractOpenAPISchema {
    /**
     * 
     * @type {any}
     * @memberof AbstractOpenAPISchema
     */
    'actualInstance'?: any | null;
    /**
     * 
     * @type {boolean}
     * @memberof AbstractOpenAPISchema
     */
    'isNullable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AbstractOpenAPISchema
     */
    'schemaType'?: string | null;
}
/**
 * 
 * @export
 * @interface AbstractOpenAPISchema2
 */
export interface AbstractOpenAPISchema2 {
    /**
     * 
     * @type {any}
     * @memberof AbstractOpenAPISchema2
     */
    'actualInstance'?: any | null;
    /**
     * 
     * @type {boolean}
     * @memberof AbstractOpenAPISchema2
     */
    'isNullable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AbstractOpenAPISchema2
     */
    'schemaType'?: string | null;
}
/**
 * 
 * @export
 * @interface AbstractProductResponse
 */
export interface AbstractProductResponse {
    /**
     * 
     * @type {string}
     * @memberof AbstractProductResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AbstractProductResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AbstractProductResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractProductResponse
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {ProductState}
     * @memberof AbstractProductResponse
     */
    'state': ProductState;
    /**
     * 
     * @type {string}
     * @memberof AbstractProductResponse
     */
    'ean': string;
    /**
     * 
     * @type {boolean}
     * @memberof AbstractProductResponse
     */
    'isBatchControlled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AbstractProductResponse
     */
    'isBestBeforeControlled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AbstractProductResponse
     */
    'isDangerousGoods': boolean;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof AbstractProductResponse
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof AbstractProductResponse
     */
    'productLine'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {Array<ProductPriceResponse>}
     * @memberof AbstractProductResponse
     */
    'productPrices'?: Array<ProductPriceResponse> | null;
    /**
     * 
     * @type {Array<ProductStockResponse>}
     * @memberof AbstractProductResponse
     */
    'productStocks'?: Array<ProductStockResponse> | null;
    /**
     * 
     * @type {Array<ProductAssetResponse>}
     * @memberof AbstractProductResponse
     */
    'productAssets'?: Array<ProductAssetResponse> | null;
    /**
     * 
     * @type {Array<ProductAdditionalInformationResponse>}
     * @memberof AbstractProductResponse
     */
    'additionalInformation'?: Array<ProductAdditionalInformationResponse> | null;
    /**
     * 
     * @type {Array<ProductAnalyticConstituentResponse>}
     * @memberof AbstractProductResponse
     */
    'analyticConstituents'?: Array<ProductAnalyticConstituentResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractProductResponse
     */
    'productNumber': string;
    /**
     * 
     * @type {string}
     * @memberof AbstractProductResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof AbstractProductResponse
     */
    'activeProductVersionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractProductResponse
     */
    'brandId': string;
    /**
     * 
     * @type {BrandResponse}
     * @memberof AbstractProductResponse
     */
    'brand'?: BrandResponse | null;
    /**
     * 
     * @type {Array<AnimalSpeciesResponse>}
     * @memberof AbstractProductResponse
     */
    'animalSpecies': Array<AnimalSpeciesResponse>;
}
/**
 * 
 * @export
 * @interface AbstractProductResponseAllOf
 */
export interface AbstractProductResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof AbstractProductResponseAllOf
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {ProductState}
     * @memberof AbstractProductResponseAllOf
     */
    'state': ProductState;
    /**
     * 
     * @type {string}
     * @memberof AbstractProductResponseAllOf
     */
    'ean': string;
    /**
     * 
     * @type {boolean}
     * @memberof AbstractProductResponseAllOf
     */
    'isBatchControlled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AbstractProductResponseAllOf
     */
    'isBestBeforeControlled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AbstractProductResponseAllOf
     */
    'isDangerousGoods': boolean;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof AbstractProductResponseAllOf
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof AbstractProductResponseAllOf
     */
    'productLine'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {Array<ProductPriceResponse>}
     * @memberof AbstractProductResponseAllOf
     */
    'productPrices'?: Array<ProductPriceResponse> | null;
    /**
     * 
     * @type {Array<ProductStockResponse>}
     * @memberof AbstractProductResponseAllOf
     */
    'productStocks'?: Array<ProductStockResponse> | null;
    /**
     * 
     * @type {Array<ProductAssetResponse>}
     * @memberof AbstractProductResponseAllOf
     */
    'productAssets'?: Array<ProductAssetResponse> | null;
    /**
     * 
     * @type {Array<ProductAdditionalInformationResponse>}
     * @memberof AbstractProductResponseAllOf
     */
    'additionalInformation'?: Array<ProductAdditionalInformationResponse> | null;
    /**
     * 
     * @type {Array<ProductAnalyticConstituentResponse>}
     * @memberof AbstractProductResponseAllOf
     */
    'analyticConstituents'?: Array<ProductAnalyticConstituentResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractProductResponseAllOf
     */
    'productNumber': string;
    /**
     * 
     * @type {string}
     * @memberof AbstractProductResponseAllOf
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof AbstractProductResponseAllOf
     */
    'activeProductVersionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractProductResponseAllOf
     */
    'brandId': string;
    /**
     * 
     * @type {BrandResponse}
     * @memberof AbstractProductResponseAllOf
     */
    'brand'?: BrandResponse | null;
    /**
     * 
     * @type {Array<AnimalSpeciesResponse>}
     * @memberof AbstractProductResponseAllOf
     */
    'animalSpecies': Array<AnimalSpeciesResponse>;
}
/**
 * 
 * @export
 * @interface AbstractUpdateAddressRequest
 */
export interface AbstractUpdateAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof AbstractUpdateAddressRequest
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractUpdateAddressRequest
     */
    'streetNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractUpdateAddressRequest
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof AbstractUpdateAddressRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof AbstractUpdateAddressRequest
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractUpdateAddressRequest
     */
    'additionalAddressLine1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractUpdateAddressRequest
     */
    'additionalAddressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractUpdateAddressRequest
     */
    'countryId'?: string;
}
/**
 * 
 * @export
 * @interface AbstractUpsertProductAdditionalInformation
 */
export interface AbstractUpsertProductAdditionalInformation {
    /**
     * 
     * @type {string}
     * @memberof AbstractUpsertProductAdditionalInformation
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractUpsertProductAdditionalInformation
     */
    'informationGroupId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AbstractUpsertProductAdditionalInformation
     */
    'position'?: number | null;
}
/**
 * 
 * @export
 * @interface AbstractWarehouseResponse
 */
export interface AbstractWarehouseResponse {
    /**
     * 
     * @type {string}
     * @memberof AbstractWarehouseResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AbstractWarehouseResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AbstractWarehouseResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbstractWarehouseResponse
     */
    'name': string;
    /**
     * 
     * @type {WarehouseType}
     * @memberof AbstractWarehouseResponse
     */
    'type': WarehouseType;
    /**
     * 
     * @type {string}
     * @memberof AbstractWarehouseResponse
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface AbstractWarehouseResponseAllOf
 */
export interface AbstractWarehouseResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof AbstractWarehouseResponseAllOf
     */
    'name': string;
    /**
     * 
     * @type {WarehouseType}
     * @memberof AbstractWarehouseResponseAllOf
     */
    'type': WarehouseType;
    /**
     * 
     * @type {string}
     * @memberof AbstractWarehouseResponseAllOf
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface AccountHolder
 */
export interface AccountHolder {
    /**
     * 
     * @type {StatusEnum3}
     * @memberof AccountHolder
     */
    'status'?: StatusEnum3 | null;
    /**
     * 
     * @type {string}
     * @memberof AccountHolder
     */
    'balancePlatform'?: string | null;
    /**
     * 
     * @type {{ [key: string]: AccountHolderCapability; }}
     * @memberof AccountHolder
     */
    'capabilities'?: { [key: string]: AccountHolderCapability; } | null;
    /**
     * 
     * @type {ContactDetails}
     * @memberof AccountHolder
     * @deprecated
     */
    'contactDetails'?: ContactDetails | null;
    /**
     * 
     * @type {string}
     * @memberof AccountHolder
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountHolder
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountHolder
     */
    'legalEntityId'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AccountHolder
     */
    'metadata'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof AccountHolder
     */
    'migratedAccountHolderCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountHolder
     */
    'primaryBalanceAccount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountHolder
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountHolder
     */
    'timeZone'?: string | null;
    /**
     * 
     * @type {Array<VerificationDeadline>}
     * @memberof AccountHolder
     */
    'verificationDeadlines'?: Array<VerificationDeadline> | null;
}
/**
 * 
 * @export
 * @interface AccountHolderCapability
 */
export interface AccountHolderCapability {
    /**
     * 
     * @type {AllowedLevelEnum}
     * @memberof AccountHolderCapability
     */
    'allowedLevel'?: AllowedLevelEnum | null;
    /**
     * 
     * @type {RequestedLevelEnum}
     * @memberof AccountHolderCapability
     */
    'requestedLevel'?: RequestedLevelEnum | null;
    /**
     * 
     * @type {VerificationStatusEnum}
     * @memberof AccountHolderCapability
     */
    'verificationStatus'?: VerificationStatusEnum | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccountHolderCapability
     */
    'allowed'?: boolean | null;
    /**
     * 
     * @type {CapabilitySettings}
     * @memberof AccountHolderCapability
     */
    'allowedSettings'?: CapabilitySettings | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccountHolderCapability
     */
    'enabled'?: boolean | null;
    /**
     * 
     * @type {Array<CapabilityProblem2>}
     * @memberof AccountHolderCapability
     */
    'problems'?: Array<CapabilityProblem2> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccountHolderCapability
     */
    'requested'?: boolean | null;
    /**
     * 
     * @type {CapabilitySettings}
     * @memberof AccountHolderCapability
     */
    'requestedSettings'?: CapabilitySettings | null;
    /**
     * 
     * @type {Array<AccountSupportingEntityCapability>}
     * @memberof AccountHolderCapability
     */
    'transferInstruments'?: Array<AccountSupportingEntityCapability> | null;
}
/**
 * 
 * @export
 * @interface AccountHolderInfo
 */
export interface AccountHolderInfo {
    /**
     * 
     * @type {string}
     * @memberof AccountHolderInfo
     */
    'balancePlatform'?: string | null;
    /**
     * 
     * @type {{ [key: string]: AccountHolderCapability; }}
     * @memberof AccountHolderInfo
     */
    'capabilities'?: { [key: string]: AccountHolderCapability; } | null;
    /**
     * 
     * @type {ContactDetails}
     * @memberof AccountHolderInfo
     * @deprecated
     */
    'contactDetails'?: ContactDetails | null;
    /**
     * 
     * @type {string}
     * @memberof AccountHolderInfo
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountHolderInfo
     */
    'legalEntityId'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AccountHolderInfo
     */
    'metadata'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof AccountHolderInfo
     */
    'migratedAccountHolderCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountHolderInfo
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountHolderInfo
     */
    'timeZone'?: string | null;
}
/**
 * 
 * @export
 * @interface AccountHolderUpdateRequest
 */
export interface AccountHolderUpdateRequest {
    /**
     * 
     * @type {StatusEnum5}
     * @memberof AccountHolderUpdateRequest
     */
    'status'?: StatusEnum5 | null;
    /**
     * 
     * @type {string}
     * @memberof AccountHolderUpdateRequest
     */
    'balancePlatform'?: string | null;
    /**
     * 
     * @type {{ [key: string]: AccountHolderCapability; }}
     * @memberof AccountHolderUpdateRequest
     */
    'capabilities'?: { [key: string]: AccountHolderCapability; } | null;
    /**
     * 
     * @type {ContactDetails}
     * @memberof AccountHolderUpdateRequest
     * @deprecated
     */
    'contactDetails'?: ContactDetails | null;
    /**
     * 
     * @type {string}
     * @memberof AccountHolderUpdateRequest
     */
    'description'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AccountHolderUpdateRequest
     */
    'metadata'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof AccountHolderUpdateRequest
     */
    'migratedAccountHolderCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountHolderUpdateRequest
     */
    'primaryBalanceAccount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountHolderUpdateRequest
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountHolderUpdateRequest
     */
    'timeZone'?: string | null;
    /**
     * 
     * @type {Array<VerificationDeadline>}
     * @memberof AccountHolderUpdateRequest
     */
    'verificationDeadlines'?: Array<VerificationDeadline> | null;
}
/**
 * 
 * @export
 * @interface AccountSupportingEntityCapability
 */
export interface AccountSupportingEntityCapability {
    /**
     * 
     * @type {AllowedLevelEnum2}
     * @memberof AccountSupportingEntityCapability
     */
    'allowedLevel'?: AllowedLevelEnum2 | null;
    /**
     * 
     * @type {RequestedLevelEnum2}
     * @memberof AccountSupportingEntityCapability
     */
    'requestedLevel'?: RequestedLevelEnum2 | null;
    /**
     * 
     * @type {VerificationStatusEnum2}
     * @memberof AccountSupportingEntityCapability
     */
    'verificationStatus'?: VerificationStatusEnum2 | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSupportingEntityCapability
     */
    'allowed'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSupportingEntityCapability
     */
    'enabled'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AccountSupportingEntityCapability
     */
    'id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSupportingEntityCapability
     */
    'requested'?: boolean | null;
}
/**
 * 
 * @export
 * @interface AddUserRolePoliciesRequest
 */
export interface AddUserRolePoliciesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AddUserRolePoliciesRequest
     */
    'policies': Array<string>;
}
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'houseNumberOrName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'stateOrProvince'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'street'?: string | null;
}
/**
 * 
 * @export
 * @interface Address2
 */
export interface Address2 {
    /**
     * 
     * @type {string}
     * @memberof Address2
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address2
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address2
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address2
     */
    'stateOrProvince'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address2
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address2
     */
    'street2'?: string | null;
}
/**
 * 
 * @export
 * @interface AdyenAccountHolderResponse
 */
export interface AdyenAccountHolderResponse {
    /**
     * 
     * @type {string}
     * @memberof AdyenAccountHolderResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenAccountHolderResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenAccountHolderResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenAccountHolderResponse
     */
    'accountHolderId': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenAccountHolderResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenAccountHolderResponse
     */
    'balancePlatform': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenAccountHolderResponse
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenAccountHolderResponse
     */
    'legalEntityId': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenAccountHolderResponse
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenAccountHolderResponse
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenAccountHolderResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {Array<AdyenBalanceAccountResponse>}
     * @memberof AdyenAccountHolderResponse
     */
    'balanceAccounts'?: Array<AdyenBalanceAccountResponse> | null;
    /**
     * 
     * @type {AccountHolder}
     * @memberof AdyenAccountHolderResponse
     */
    'accountHolder'?: AccountHolder | null;
}
/**
 * 
 * @export
 * @interface AdyenAccountHolderResponseAllOf
 */
export interface AdyenAccountHolderResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof AdyenAccountHolderResponseAllOf
     */
    'accountHolderId': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenAccountHolderResponseAllOf
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenAccountHolderResponseAllOf
     */
    'balancePlatform': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenAccountHolderResponseAllOf
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenAccountHolderResponseAllOf
     */
    'legalEntityId': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenAccountHolderResponseAllOf
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenAccountHolderResponseAllOf
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenAccountHolderResponseAllOf
     */
    'manufacturerId': string;
    /**
     * 
     * @type {Array<AdyenBalanceAccountResponse>}
     * @memberof AdyenAccountHolderResponseAllOf
     */
    'balanceAccounts'?: Array<AdyenBalanceAccountResponse> | null;
    /**
     * 
     * @type {AccountHolder}
     * @memberof AdyenAccountHolderResponseAllOf
     */
    'accountHolder'?: AccountHolder | null;
}
/**
 * 
 * @export
 * @interface AdyenBalanceAccountResponse
 */
export interface AdyenBalanceAccountResponse {
    /**
     * 
     * @type {string}
     * @memberof AdyenBalanceAccountResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenBalanceAccountResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenBalanceAccountResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenBalanceAccountResponse
     */
    'balanceAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenBalanceAccountResponse
     */
    'defaultCurrencyCode': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenBalanceAccountResponse
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenBalanceAccountResponse
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenBalanceAccountResponse
     */
    'accountHolderId'?: string;
    /**
     * 
     * @type {BalanceAccount}
     * @memberof AdyenBalanceAccountResponse
     */
    'balanceAccount'?: BalanceAccount | null;
}
/**
 * 
 * @export
 * @interface AdyenBalanceAccountResponseAllOf
 */
export interface AdyenBalanceAccountResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof AdyenBalanceAccountResponseAllOf
     */
    'balanceAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenBalanceAccountResponseAllOf
     */
    'defaultCurrencyCode': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenBalanceAccountResponseAllOf
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenBalanceAccountResponseAllOf
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenBalanceAccountResponseAllOf
     */
    'accountHolderId'?: string;
    /**
     * 
     * @type {BalanceAccount}
     * @memberof AdyenBalanceAccountResponseAllOf
     */
    'balanceAccount'?: BalanceAccount | null;
}
/**
 * 
 * @export
 * @interface AdyenBalancePlatformNotificationRequest
 */
export interface AdyenBalancePlatformNotificationRequest {
    /**
     * 
     * @type {string}
     * @memberof AdyenBalancePlatformNotificationRequest
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenBalancePlatformNotificationRequest
     */
    'environment': string;
    /**
     * 
     * @type {any}
     * @memberof AdyenBalancePlatformNotificationRequest
     */
    'data': any;
}
/**
 * 
 * @export
 * @interface AdyenBalancePlatformResponse
 */
export interface AdyenBalancePlatformResponse {
    /**
     * 
     * @type {string}
     * @memberof AdyenBalancePlatformResponse
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenBalancePlatformResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenBalancePlatformResponse
     */
    'status'?: string | null;
}
/**
 * 
 * @export
 * @interface AdyenDocumentResponse
 */
export interface AdyenDocumentResponse {
    /**
     * 
     * @type {TypeEnum24}
     * @memberof AdyenDocumentResponse
     */
    'type'?: TypeEnum24;
    /**
     * 
     * @type {Attachment}
     * @memberof AdyenDocumentResponse
     */
    'attachment'?: Attachment | null;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof AdyenDocumentResponse
     */
    'attachments'?: Array<Attachment> | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenDocumentResponse
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdyenDocumentResponse
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenDocumentResponse
     * @deprecated
     */
    'expiryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenDocumentResponse
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenDocumentResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenDocumentResponse
     * @deprecated
     */
    'issuerCountry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenDocumentResponse
     * @deprecated
     */
    'issuerState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenDocumentResponse
     */
    'modificationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdyenDocumentResponse
     */
    'number'?: string | null;
    /**
     * 
     * @type {OwnerEntity}
     * @memberof AdyenDocumentResponse
     */
    'owner'?: OwnerEntity | null;
}
/**
 * 
 * @export
 * @interface AdyenDocumentResponseAllOf
 */
export interface AdyenDocumentResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof AdyenDocumentResponseAllOf
     */
    'id'?: string | null;
}
/**
 * 
 * @export
 * @interface AdyenIbanAccountIdentification
 */
export interface AdyenIbanAccountIdentification {
    /**
     * 
     * @type {TypeEnum2}
     * @memberof AdyenIbanAccountIdentification
     */
    'type'?: TypeEnum2;
    /**
     * 
     * @type {string}
     * @memberof AdyenIbanAccountIdentification
     */
    'iban'?: string | null;
}
/**
 * 
 * @export
 * @interface AdyenIbanAccountIdentificationAllOf
 */
export interface AdyenIbanAccountIdentificationAllOf {
    /**
     * 
     * @type {TypeEnum2}
     * @memberof AdyenIbanAccountIdentificationAllOf
     */
    'type'?: TypeEnum2;
}
/**
 * 
 * @export
 * @interface AdyenLegalEntityResponse
 */
export interface AdyenLegalEntityResponse {
    /**
     * 
     * @type {string}
     * @memberof AdyenLegalEntityResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenLegalEntityResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenLegalEntityResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenLegalEntityResponse
     */
    'legalEntityId': string;
    /**
     * 
     * @type {TypeEnum10}
     * @memberof AdyenLegalEntityResponse
     */
    'type': TypeEnum10;
    /**
     * 
     * @type {string}
     * @memberof AdyenLegalEntityResponse
     */
    'reference': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenLegalEntityResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdyenLegalEntityResponse
     */
    'transferInstrumentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdyenLegalEntityResponse
     */
    'documentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<AdyenAccountHolderResponse>}
     * @memberof AdyenLegalEntityResponse
     */
    'accountHolders'?: Array<AdyenAccountHolderResponse> | null;
    /**
     * 
     * @type {LegalEntity}
     * @memberof AdyenLegalEntityResponse
     */
    'adyenLegalEntity'?: LegalEntity | null;
}
/**
 * 
 * @export
 * @interface AdyenLegalEntityResponseAllOf
 */
export interface AdyenLegalEntityResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof AdyenLegalEntityResponseAllOf
     */
    'legalEntityId': string;
    /**
     * 
     * @type {TypeEnum10}
     * @memberof AdyenLegalEntityResponseAllOf
     */
    'type': TypeEnum10;
    /**
     * 
     * @type {string}
     * @memberof AdyenLegalEntityResponseAllOf
     */
    'reference': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenLegalEntityResponseAllOf
     */
    'manufacturerId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdyenLegalEntityResponseAllOf
     */
    'transferInstrumentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdyenLegalEntityResponseAllOf
     */
    'documentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<AdyenAccountHolderResponse>}
     * @memberof AdyenLegalEntityResponseAllOf
     */
    'accountHolders'?: Array<AdyenAccountHolderResponse> | null;
    /**
     * 
     * @type {LegalEntity}
     * @memberof AdyenLegalEntityResponseAllOf
     */
    'adyenLegalEntity'?: LegalEntity | null;
}
/**
 * 
 * @export
 * @interface AdyenPaymentRefundDetailResponse
 */
export interface AdyenPaymentRefundDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentRefundDetailResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentRefundDetailResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentRefundDetailResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentRefundDetailResponse
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentRefundDetailResponse
     */
    'orderPositionId': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentRefundDetailResponse
     */
    'orderReturnPositionId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AdyenPaymentRefundDetailResponse
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof AdyenPaymentRefundDetailResponse
     */
    'commission': number;
    /**
     * 
     * @type {Array<OrderPositionUnitRefundAmountResponse>}
     * @memberof AdyenPaymentRefundDetailResponse
     */
    'orderPositionUnitRefundAmounts': Array<OrderPositionUnitRefundAmountResponse>;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentRefundDetailResponse
     */
    'adyenPaymentRefundId': string;
}
/**
 * 
 * @export
 * @interface AdyenPaymentRefundDetailResponseAllOf
 */
export interface AdyenPaymentRefundDetailResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentRefundDetailResponseAllOf
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentRefundDetailResponseAllOf
     */
    'orderPositionId': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentRefundDetailResponseAllOf
     */
    'orderReturnPositionId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AdyenPaymentRefundDetailResponseAllOf
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof AdyenPaymentRefundDetailResponseAllOf
     */
    'commission': number;
    /**
     * 
     * @type {Array<OrderPositionUnitRefundAmountResponse>}
     * @memberof AdyenPaymentRefundDetailResponseAllOf
     */
    'orderPositionUnitRefundAmounts': Array<OrderPositionUnitRefundAmountResponse>;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentRefundDetailResponseAllOf
     */
    'adyenPaymentRefundId': string;
}
/**
 * 
 * @export
 * @interface AdyenPaymentRefundResponse
 */
export interface AdyenPaymentRefundResponse {
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentRefundResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentRefundResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentRefundResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentRefundResponse
     */
    'paymentSplitId'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdyenPaymentRefundResponse
     */
    'refundedShippingCosts'?: number | null;
    /**
     * 
     * @type {{ [key: string]: AdyenPaymentRefundStatus; }}
     * @memberof AdyenPaymentRefundResponse
     */
    'status'?: { [key: string]: AdyenPaymentRefundStatus; } | null;
    /**
     * 
     * @type {{ [key: string]: AdyenPaymentRefundStatus; }}
     * @memberof AdyenPaymentRefundResponse
     */
    'commissionStatus'?: { [key: string]: AdyenPaymentRefundStatus; } | null;
    /**
     * 
     * @type {Array<AdyenPaymentRefundDetailResponse>}
     * @memberof AdyenPaymentRefundResponse
     */
    'adyenPaymentRefundDetails': Array<AdyenPaymentRefundDetailResponse>;
    /**
     * 
     * @type {number}
     * @memberof AdyenPaymentRefundResponse
     */
    'totalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof AdyenPaymentRefundResponse
     */
    'totalCommission': number;
}
/**
 * 
 * @export
 * @interface AdyenPaymentRefundResponseAllOf
 */
export interface AdyenPaymentRefundResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentRefundResponseAllOf
     */
    'paymentSplitId'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdyenPaymentRefundResponseAllOf
     */
    'refundedShippingCosts'?: number | null;
    /**
     * 
     * @type {{ [key: string]: AdyenPaymentRefundStatus; }}
     * @memberof AdyenPaymentRefundResponseAllOf
     */
    'status'?: { [key: string]: AdyenPaymentRefundStatus; } | null;
    /**
     * 
     * @type {{ [key: string]: AdyenPaymentRefundStatus; }}
     * @memberof AdyenPaymentRefundResponseAllOf
     */
    'commissionStatus'?: { [key: string]: AdyenPaymentRefundStatus; } | null;
    /**
     * 
     * @type {Array<AdyenPaymentRefundDetailResponse>}
     * @memberof AdyenPaymentRefundResponseAllOf
     */
    'adyenPaymentRefundDetails': Array<AdyenPaymentRefundDetailResponse>;
    /**
     * 
     * @type {number}
     * @memberof AdyenPaymentRefundResponseAllOf
     */
    'totalAmount': number;
    /**
     * 
     * @type {number}
     * @memberof AdyenPaymentRefundResponseAllOf
     */
    'totalCommission': number;
}
/**
 * 
 * @export
 * @interface AdyenPaymentRefundStatus
 */
export interface AdyenPaymentRefundStatus {
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentRefundStatus
     */
    'adyenBalancePlatformNotificationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentRefundStatus
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentRefundStatus
     */
    'bookingDate'?: string | null;
}
/**
 * 
 * @export
 * @interface AdyenPaymentSplitResponse
 */
export interface AdyenPaymentSplitResponse {
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentSplitResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentSplitResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentSplitResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AdyenPaymentSplitResponse
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentSplitResponse
     */
    'currency': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentSplitResponse
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentSplitResponse
     */
    'reference': string;
    /**
     * 
     * @type {TypeEnum}
     * @memberof AdyenPaymentSplitResponse
     */
    'type': TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentSplitResponse
     */
    'paymentId': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentSplitResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentSplitResponse
     */
    'balanceAccountId': string;
}
/**
 * 
 * @export
 * @interface AdyenPaymentSplitResponseAllOf
 */
export interface AdyenPaymentSplitResponseAllOf {
    /**
     * 
     * @type {number}
     * @memberof AdyenPaymentSplitResponseAllOf
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentSplitResponseAllOf
     */
    'currency': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentSplitResponseAllOf
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentSplitResponseAllOf
     */
    'reference': string;
    /**
     * 
     * @type {TypeEnum}
     * @memberof AdyenPaymentSplitResponseAllOf
     */
    'type': TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentSplitResponseAllOf
     */
    'paymentId': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentSplitResponseAllOf
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentSplitResponseAllOf
     */
    'balanceAccountId': string;
}
/**
 * 
 * @export
 * @interface AdyenPaymentTransactionResponse
 */
export interface AdyenPaymentTransactionResponse {
    /**
     * 
     * @type {Transaction}
     * @memberof AdyenPaymentTransactionResponse
     */
    'transaction'?: Transaction | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenPaymentTransactionResponse
     */
    'orderId'?: string | null;
}
/**
 * 
 * @export
 * @interface AdyenPaymentTransactionsResponse
 */
export interface AdyenPaymentTransactionsResponse {
    /**
     * 
     * @type {CursorPaginator}
     * @memberof AdyenPaymentTransactionsResponse
     */
    'cursor': CursorPaginator;
    /**
     * 
     * @type {Array<AdyenPaymentTransactionResponse>}
     * @memberof AdyenPaymentTransactionsResponse
     */
    'data'?: Array<AdyenPaymentTransactionResponse> | null;
}
/**
 * 
 * @export
 * @interface AdyenPaymentTransactionsResponseAllOf
 */
export interface AdyenPaymentTransactionsResponseAllOf {
    /**
     * 
     * @type {Array<AdyenPaymentTransactionResponse>}
     * @memberof AdyenPaymentTransactionsResponseAllOf
     */
    'data'?: Array<AdyenPaymentTransactionResponse> | null;
}
/**
 * 
 * @export
 * @interface AdyenTransferInstrumentBankAccountInfo
 */
export interface AdyenTransferInstrumentBankAccountInfo {
    /**
     * 
     * @type {AdyenIbanAccountIdentification}
     * @memberof AdyenTransferInstrumentBankAccountInfo
     */
    'accountIdentification'?: AdyenIbanAccountIdentification | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenTransferInstrumentBankAccountInfo
     * @deprecated
     */
    'accountType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenTransferInstrumentBankAccountInfo
     */
    'bankName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenTransferInstrumentBankAccountInfo
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdyenTransferInstrumentBankAccountInfo
     */
    'trustedSource'?: boolean | null;
}
/**
 * 
 * @export
 * @interface AdyenTransferInstrumentBankAccountInfoAllOf
 */
export interface AdyenTransferInstrumentBankAccountInfoAllOf {
    /**
     * 
     * @type {AdyenIbanAccountIdentification}
     * @memberof AdyenTransferInstrumentBankAccountInfoAllOf
     */
    'accountIdentification'?: AdyenIbanAccountIdentification | null;
}
/**
 * 
 * @export
 * @interface AdyenTransferInstrumentResponse
 */
export interface AdyenTransferInstrumentResponse {
    /**
     * 
     * @type {TypeEnum3}
     * @memberof AdyenTransferInstrumentResponse
     */
    'type'?: TypeEnum3;
    /**
     * 
     * @type {AdyenTransferInstrumentBankAccountInfo}
     * @memberof AdyenTransferInstrumentResponse
     */
    'bankAccount'?: AdyenTransferInstrumentBankAccountInfo | null;
    /**
     * 
     * @type {{ [key: string]: SupportingEntityCapability; }}
     * @memberof AdyenTransferInstrumentResponse
     */
    'capabilities'?: { [key: string]: SupportingEntityCapability; } | null;
    /**
     * 
     * @type {Array<DocumentReference>}
     * @memberof AdyenTransferInstrumentResponse
     */
    'documentDetails'?: Array<DocumentReference> | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenTransferInstrumentResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdyenTransferInstrumentResponse
     */
    'legalEntityId'?: string | null;
    /**
     * 
     * @type {Array<CapabilityProblem>}
     * @memberof AdyenTransferInstrumentResponse
     */
    'problems'?: Array<CapabilityProblem> | null;
}
/**
 * 
 * @export
 * @interface AdyenTransferInstrumentResponseAllOf
 */
export interface AdyenTransferInstrumentResponseAllOf {
    /**
     * 
     * @type {AdyenTransferInstrumentBankAccountInfo}
     * @memberof AdyenTransferInstrumentResponseAllOf
     */
    'bankAccount'?: AdyenTransferInstrumentBankAccountInfo | null;
    /**
     * 
     * @type {TypeEnum3}
     * @memberof AdyenTransferInstrumentResponseAllOf
     */
    'type'?: TypeEnum3;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum AllowedLevelEnum {
    High = 'high',
    Low = 'low',
    Medium = 'medium',
    NotApplicable = 'notApplicable'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum AllowedLevelEnum2 {
    High = 'high',
    Low = 'low',
    Medium = 'medium',
    NotApplicable = 'notApplicable'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum AllowedLevelEnum3 {
    High = 'high',
    Low = 'low',
    Medium = 'medium',
    NotApplicable = 'notApplicable'
}

/**
 * 
 * @export
 * @interface Amount
 */
export interface Amount {
    /**
     * 
     * @type {string}
     * @memberof Amount
     */
    'currency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Amount
     */
    'value'?: number | null;
}
/**
 * 
 * @export
 * @interface Amount2
 */
export interface Amount2 {
    /**
     * 
     * @type {string}
     * @memberof Amount2
     */
    'currency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Amount2
     */
    'value'?: number | null;
}
/**
 * 
 * @export
 * @interface Amount3
 */
export interface Amount3 {
    /**
     * 
     * @type {string}
     * @memberof Amount3
     */
    'currency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Amount3
     */
    'value'?: number | null;
}
/**
 * 
 * @export
 * @interface AnimalSpeciesResponse
 */
export interface AnimalSpeciesResponse {
    /**
     * 
     * @type {string}
     * @memberof AnimalSpeciesResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalSpeciesResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalSpeciesResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof AnimalSpeciesResponse
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof AnimalSpeciesResponse
     */
    'slug': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {string}
     * @memberof AnimalSpeciesResponse
     */
    'identifier': string;
}
/**
 * 
 * @export
 * @interface AnimalSpeciesResponseAllOf
 */
export interface AnimalSpeciesResponseAllOf {
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof AnimalSpeciesResponseAllOf
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof AnimalSpeciesResponseAllOf
     */
    'slug': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {string}
     * @memberof AnimalSpeciesResponseAllOf
     */
    'identifier': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum AssetContext {
    Manufacturer = 'Manufacturer',
    Product = 'Product',
    DataImport = 'DataImport',
    User = 'User',
    Order = 'Order',
    Message = 'Message',
    Property = 'Property',
    Receipt = 'Receipt',
    OnBoarding = 'OnBoarding',
    Other = 'Other'
}

/**
 * 
 * @export
 * @interface AssetFolderResponse
 */
export interface AssetFolderResponse {
    /**
     * 
     * @type {string}
     * @memberof AssetFolderResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AssetFolderResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AssetFolderResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetFolderResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AssetFolderResponse
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<AssetResponse>}
     * @memberof AssetFolderResponse
     */
    'assets': Array<AssetResponse>;
    /**
     * 
     * @type {string}
     * @memberof AssetFolderResponse
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetFolderResponse
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetFolderResponse
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetFolderResponse
     */
    'isRoot': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AssetFolderResponse
     */
    'isRestricted': boolean;
}
/**
 * 
 * @export
 * @interface AssetFolderResponseAllOf
 */
export interface AssetFolderResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof AssetFolderResponseAllOf
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AssetFolderResponseAllOf
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<AssetResponse>}
     * @memberof AssetFolderResponseAllOf
     */
    'assets': Array<AssetResponse>;
    /**
     * 
     * @type {string}
     * @memberof AssetFolderResponseAllOf
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetFolderResponseAllOf
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetFolderResponseAllOf
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetFolderResponseAllOf
     */
    'isRoot': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AssetFolderResponseAllOf
     */
    'isRestricted': boolean;
}
/**
 * 
 * @export
 * @interface AssetResponse
 */
export interface AssetResponse {
    /**
     * 
     * @type {string}
     * @memberof AssetResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AssetResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AssetResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetResponse
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof AssetResponse
     */
    'uri': string;
    /**
     * 
     * @type {string}
     * @memberof AssetResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AssetResponse
     */
    'size': number;
    /**
     * 
     * @type {string}
     * @memberof AssetResponse
     */
    'extension': string;
    /**
     * 
     * @type {string}
     * @memberof AssetResponse
     */
    'mimeType': string;
    /**
     * 
     * @type {string}
     * @memberof AssetResponse
     */
    'metaData': string;
    /**
     * 
     * @type {string}
     * @memberof AssetResponse
     */
    'description'?: string | null;
    /**
     * 
     * @type {AssetType}
     * @memberof AssetResponse
     */
    'assetType': AssetType;
    /**
     * 
     * @type {AssetContext}
     * @memberof AssetResponse
     */
    'assetContext': AssetContext;
    /**
     * 
     * @type {string}
     * @memberof AssetResponse
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof AssetResponse
     */
    'assetFolderId': string;
    /**
     * 
     * @type {Array<TagResponse>}
     * @memberof AssetResponse
     */
    'tags'?: Array<TagResponse> | null;
}
/**
 * 
 * @export
 * @interface AssetResponseAllOf
 */
export interface AssetResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof AssetResponseAllOf
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AssetResponseAllOf
     */
    'size': number;
    /**
     * 
     * @type {string}
     * @memberof AssetResponseAllOf
     */
    'extension': string;
    /**
     * 
     * @type {string}
     * @memberof AssetResponseAllOf
     */
    'mimeType': string;
    /**
     * 
     * @type {string}
     * @memberof AssetResponseAllOf
     */
    'metaData': string;
    /**
     * 
     * @type {string}
     * @memberof AssetResponseAllOf
     */
    'description'?: string | null;
    /**
     * 
     * @type {AssetType}
     * @memberof AssetResponseAllOf
     */
    'assetType': AssetType;
    /**
     * 
     * @type {AssetContext}
     * @memberof AssetResponseAllOf
     */
    'assetContext': AssetContext;
    /**
     * 
     * @type {string}
     * @memberof AssetResponseAllOf
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof AssetResponseAllOf
     */
    'assetFolderId': string;
    /**
     * 
     * @type {Array<TagResponse>}
     * @memberof AssetResponseAllOf
     */
    'tags'?: Array<TagResponse> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum AssetType {
    Image = 'Image',
    Video = 'Video',
    Audio = 'Audio',
    Document = 'Document',
    Text = 'Text',
    Unknown = 'Unknown'
}

/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     * @deprecated
     */
    'contentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     * @deprecated
     */
    'filename'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'pageName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'pageType'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum AuthenticationMode {
    OAuth2 = 'OAuth2',
    AccessToken = 'AccessToken',
    Hmac = 'HMAC',
    Login = 'Login',
    BasicAuth = 'BasicAuth'
}

/**
 * 
 * @export
 * @interface Balance
 */
export interface Balance {
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    'available'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    '_Balance'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Balance
     */
    'currency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    'pending'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    'reserved'?: number | null;
}
/**
 * 
 * @export
 * @interface BalanceAccount
 */
export interface BalanceAccount {
    /**
     * 
     * @type {StatusEnum2}
     * @memberof BalanceAccount
     */
    'status'?: StatusEnum2 | null;
    /**
     * 
     * @type {string}
     * @memberof BalanceAccount
     */
    'accountHolderId'?: string | null;
    /**
     * 
     * @type {Array<Balance>}
     * @memberof BalanceAccount
     */
    'balances'?: Array<Balance> | null;
    /**
     * 
     * @type {string}
     * @memberof BalanceAccount
     */
    'defaultCurrencyCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BalanceAccount
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BalanceAccount
     */
    'id'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof BalanceAccount
     */
    'metadata'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof BalanceAccount
     */
    'migratedAccountCode'?: string | null;
    /**
     * 
     * @type {PlatformPaymentConfiguration}
     * @memberof BalanceAccount
     */
    'platformPaymentConfiguration'?: PlatformPaymentConfiguration | null;
    /**
     * 
     * @type {string}
     * @memberof BalanceAccount
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BalanceAccount
     */
    'timeZone'?: string | null;
}
/**
 * 
 * @export
 * @interface BalanceAccountBase
 */
export interface BalanceAccountBase {
    /**
     * 
     * @type {StatusEnum6}
     * @memberof BalanceAccountBase
     */
    'status'?: StatusEnum6 | null;
    /**
     * 
     * @type {string}
     * @memberof BalanceAccountBase
     */
    'accountHolderId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BalanceAccountBase
     */
    'defaultCurrencyCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BalanceAccountBase
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BalanceAccountBase
     */
    'id'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof BalanceAccountBase
     */
    'metadata'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof BalanceAccountBase
     */
    'migratedAccountCode'?: string | null;
    /**
     * 
     * @type {PlatformPaymentConfiguration}
     * @memberof BalanceAccountBase
     */
    'platformPaymentConfiguration'?: PlatformPaymentConfiguration | null;
    /**
     * 
     * @type {string}
     * @memberof BalanceAccountBase
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BalanceAccountBase
     */
    'timeZone'?: string | null;
}
/**
 * 
 * @export
 * @interface BalanceAccountInfo
 */
export interface BalanceAccountInfo {
    /**
     * 
     * @type {string}
     * @memberof BalanceAccountInfo
     */
    'accountHolderId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BalanceAccountInfo
     */
    'defaultCurrencyCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BalanceAccountInfo
     */
    'description'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof BalanceAccountInfo
     */
    'metadata'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof BalanceAccountInfo
     */
    'migratedAccountCode'?: string | null;
    /**
     * 
     * @type {PlatformPaymentConfiguration}
     * @memberof BalanceAccountInfo
     */
    'platformPaymentConfiguration'?: PlatformPaymentConfiguration | null;
    /**
     * 
     * @type {string}
     * @memberof BalanceAccountInfo
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BalanceAccountInfo
     */
    'timeZone'?: string | null;
}
/**
 * 
 * @export
 * @interface BalanceAccountUpdateRequest
 */
export interface BalanceAccountUpdateRequest {
    /**
     * 
     * @type {StatusEnum4}
     * @memberof BalanceAccountUpdateRequest
     */
    'status'?: StatusEnum4 | null;
    /**
     * 
     * @type {string}
     * @memberof BalanceAccountUpdateRequest
     */
    'accountHolderId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BalanceAccountUpdateRequest
     */
    'description'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof BalanceAccountUpdateRequest
     */
    'metadata'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {PlatformPaymentConfiguration}
     * @memberof BalanceAccountUpdateRequest
     */
    'platformPaymentConfiguration'?: PlatformPaymentConfiguration | null;
    /**
     * 
     * @type {string}
     * @memberof BalanceAccountUpdateRequest
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BalanceAccountUpdateRequest
     */
    'timeZone'?: string | null;
}
/**
 * 
 * @export
 * @interface BalancePlatform
 */
export interface BalancePlatform {
    /**
     * 
     * @type {string}
     * @memberof BalancePlatform
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BalancePlatform
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BalancePlatform
     */
    'status'?: string | null;
}
/**
 * 
 * @export
 * @interface BankAccountInfo
 */
export interface BankAccountInfo {
    /**
     * 
     * @type {BankAccountInfoAccountIdentification}
     * @memberof BankAccountInfo
     */
    'accountIdentification'?: BankAccountInfoAccountIdentification | null;
    /**
     * 
     * @type {string}
     * @memberof BankAccountInfo
     * @deprecated
     */
    'accountType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankAccountInfo
     */
    'bankName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankAccountInfo
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BankAccountInfo
     */
    'trustedSource'?: boolean | null;
}
/**
 * 
 * @export
 * @interface BankAccountInfoAccountIdentification
 */
export interface BankAccountInfoAccountIdentification {
    /**
     * 
     * @type {any}
     * @memberof BankAccountInfoAccountIdentification
     */
    'actualInstance'?: any | null;
    /**
     * 
     * @type {boolean}
     * @memberof BankAccountInfoAccountIdentification
     */
    'isNullable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BankAccountInfoAccountIdentification
     */
    'schemaType'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum BestSellingProductsOrderBy {
    Revenue = 'Revenue',
    Quantity = 'Quantity'
}

/**
 * 
 * @export
 * @interface BirthData
 */
export interface BirthData {
    /**
     * 
     * @type {string}
     * @memberof BirthData
     */
    'dateOfBirth'?: string | null;
}
/**
 * 
 * @export
 * @interface BrandContactAddressResponse
 */
export interface BrandContactAddressResponse {
    /**
     * 
     * @type {string}
     * @memberof BrandContactAddressResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BrandContactAddressResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BrandContactAddressResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandContactAddressResponse
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandContactAddressResponse
     */
    'streetNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandContactAddressResponse
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof BrandContactAddressResponse
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof BrandContactAddressResponse
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandContactAddressResponse
     */
    'additionalAddressLine1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandContactAddressResponse
     */
    'additionalAddressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandContactAddressResponse
     */
    'countryId'?: string;
    /**
     * 
     * @type {CountryResponse}
     * @memberof BrandContactAddressResponse
     */
    'country'?: CountryResponse | null;
    /**
     * 
     * @type {Department}
     * @memberof BrandContactAddressResponse
     */
    'department': Department;
    /**
     * 
     * @type {string}
     * @memberof BrandContactAddressResponse
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof BrandContactAddressResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandContactAddressResponse
     */
    'salutation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandContactAddressResponse
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandContactAddressResponse
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandContactAddressResponse
     */
    'brandId': string;
}
/**
 * 
 * @export
 * @interface BrandContactAddressResponseAllOf
 */
export interface BrandContactAddressResponseAllOf {
    /**
     * 
     * @type {Department}
     * @memberof BrandContactAddressResponseAllOf
     */
    'department': Department;
    /**
     * 
     * @type {string}
     * @memberof BrandContactAddressResponseAllOf
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof BrandContactAddressResponseAllOf
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandContactAddressResponseAllOf
     */
    'salutation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandContactAddressResponseAllOf
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandContactAddressResponseAllOf
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandContactAddressResponseAllOf
     */
    'brandId': string;
}
/**
 * 
 * @export
 * @interface BrandResponse
 */
export interface BrandResponse {
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BrandResponse
     */
    'isActiveInStorefront': boolean;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'slug': string;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof BrandResponse
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof BrandResponse
     */
    'claim'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfNullableGuid}
     * @memberof BrandResponse
     */
    'logoId'?: TranslatedTypeResponseOfNullableGuid | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfAssetResponse}
     * @memberof BrandResponse
     */
    'logo'?: TranslatedTypeResponseOfAssetResponse | null;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'syncedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'deliveryPausedFrom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandResponse
     */
    'deliveryPausedUntil'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BrandResponse
     */
    'earliestDeliveryTimeInDays'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BrandResponse
     */
    'latestDeliveryTimeInDays'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof BrandResponse
     */
    'overrideProductDeliveryTime'?: boolean;
}
/**
 * 
 * @export
 * @interface BrandResponseAllOf
 */
export interface BrandResponseAllOf {
    /**
     * 
     * @type {boolean}
     * @memberof BrandResponseAllOf
     */
    'isActiveInStorefront': boolean;
    /**
     * 
     * @type {string}
     * @memberof BrandResponseAllOf
     */
    'slug': string;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof BrandResponseAllOf
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof BrandResponseAllOf
     */
    'claim'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfNullableGuid}
     * @memberof BrandResponseAllOf
     */
    'logoId'?: TranslatedTypeResponseOfNullableGuid | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfAssetResponse}
     * @memberof BrandResponseAllOf
     */
    'logo'?: TranslatedTypeResponseOfAssetResponse | null;
    /**
     * 
     * @type {string}
     * @memberof BrandResponseAllOf
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof BrandResponseAllOf
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandResponseAllOf
     */
    'syncedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandResponseAllOf
     */
    'deliveryPausedFrom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandResponseAllOf
     */
    'deliveryPausedUntil'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BrandResponseAllOf
     */
    'earliestDeliveryTimeInDays'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BrandResponseAllOf
     */
    'latestDeliveryTimeInDays'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof BrandResponseAllOf
     */
    'overrideProductDeliveryTime'?: boolean;
}
/**
 * 
 * @export
 * @interface BrandStoreResponse
 */
export interface BrandStoreResponse {
    /**
     * 
     * @type {string}
     * @memberof BrandStoreResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BrandStoreResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BrandStoreResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandStoreResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BrandStoreResponse
     */
    'primaryColorHex': string;
    /**
     * 
     * @type {string}
     * @memberof BrandStoreResponse
     */
    'secondaryColorHex'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandStoreResponse
     */
    'primaryContrastColorHex': string;
    /**
     * 
     * @type {string}
     * @memberof BrandStoreResponse
     */
    'secondaryContrastColorHex'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandStoreResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {BrandStoreState}
     * @memberof BrandStoreResponse
     */
    'state': BrandStoreState;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof BrandStoreResponse
     */
    'claim'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof BrandStoreResponse
     */
    'description': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfDictionaryOfStringAndString}
     * @memberof BrandStoreResponse
     */
    'faq'?: TranslatedTypeResponseOfDictionaryOfStringAndString | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfAssetResponse}
     * @memberof BrandStoreResponse
     */
    'heroBannerAsset': TranslatedTypeResponseOfAssetResponse;
    /**
     * 
     * @type {string}
     * @memberof BrandStoreResponse
     */
    'brandId': string;
    /**
     * 
     * @type {BrandResponse}
     * @memberof BrandStoreResponse
     */
    'brand'?: BrandResponse | null;
    /**
     * 
     * @type {string}
     * @memberof BrandStoreResponse
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandStoreResponse
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface BrandStoreResponseAllOf
 */
export interface BrandStoreResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof BrandStoreResponseAllOf
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BrandStoreResponseAllOf
     */
    'primaryColorHex': string;
    /**
     * 
     * @type {string}
     * @memberof BrandStoreResponseAllOf
     */
    'secondaryColorHex'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandStoreResponseAllOf
     */
    'primaryContrastColorHex': string;
    /**
     * 
     * @type {string}
     * @memberof BrandStoreResponseAllOf
     */
    'secondaryContrastColorHex'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandStoreResponseAllOf
     */
    'manufacturerId': string;
    /**
     * 
     * @type {BrandStoreState}
     * @memberof BrandStoreResponseAllOf
     */
    'state': BrandStoreState;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof BrandStoreResponseAllOf
     */
    'claim'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof BrandStoreResponseAllOf
     */
    'description': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfDictionaryOfStringAndString}
     * @memberof BrandStoreResponseAllOf
     */
    'faq'?: TranslatedTypeResponseOfDictionaryOfStringAndString | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfAssetResponse}
     * @memberof BrandStoreResponseAllOf
     */
    'heroBannerAsset': TranslatedTypeResponseOfAssetResponse;
    /**
     * 
     * @type {string}
     * @memberof BrandStoreResponseAllOf
     */
    'brandId': string;
    /**
     * 
     * @type {BrandResponse}
     * @memberof BrandStoreResponseAllOf
     */
    'brand'?: BrandResponse | null;
    /**
     * 
     * @type {string}
     * @memberof BrandStoreResponseAllOf
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandStoreResponseAllOf
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum BrandStoreState {
    Draft = 'Draft',
    Active = 'Active',
    Inactive = 'Inactive'
}

/**
 * 
 * @export
 * @interface BulkEditorHistoryResponse
 */
export interface BulkEditorHistoryResponse {
    /**
     * 
     * @type {string}
     * @memberof BulkEditorHistoryResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BulkEditorHistoryResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BulkEditorHistoryResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BulkEditorHistoryResponse
     */
    'bulkEditorSessionId': string;
    /**
     * 
     * @type {string}
     * @memberof BulkEditorHistoryResponse
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof BulkEditorHistoryResponse
     */
    'data': string;
    /**
     * 
     * @type {string}
     * @memberof BulkEditorHistoryResponse
     */
    'comment'?: string | null;
}
/**
 * 
 * @export
 * @interface BulkEditorHistoryResponseAllOf
 */
export interface BulkEditorHistoryResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof BulkEditorHistoryResponseAllOf
     */
    'bulkEditorSessionId': string;
    /**
     * 
     * @type {string}
     * @memberof BulkEditorHistoryResponseAllOf
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof BulkEditorHistoryResponseAllOf
     */
    'data': string;
    /**
     * 
     * @type {string}
     * @memberof BulkEditorHistoryResponseAllOf
     */
    'comment'?: string | null;
}
/**
 * 
 * @export
 * @interface BulkEditorSessionResponse
 */
export interface BulkEditorSessionResponse {
    /**
     * 
     * @type {string}
     * @memberof BulkEditorSessionResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BulkEditorSessionResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BulkEditorSessionResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BulkEditorSessionResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof BulkEditorSessionResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface BulkEditorSessionResponseAllOf
 */
export interface BulkEditorSessionResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof BulkEditorSessionResponseAllOf
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof BulkEditorSessionResponseAllOf
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface BulkUpdateProductStatesRequest
 */
export interface BulkUpdateProductStatesRequest {
    /**
     * 
     * @type {ProductState}
     * @memberof BulkUpdateProductStatesRequest
     */
    'productState': ProductState;
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkUpdateProductStatesRequest
     */
    'productIds': Array<string>;
}
/**
 * 
 * @export
 * @interface BulkUpsertProductRequest
 */
export interface BulkUpsertProductRequest {
    /**
     * 
     * @type {boolean}
     * @memberof BulkUpsertProductRequest
     */
    'autoRequestApproval'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BulkUpsertProductRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BulkUpsertProductRequest
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BulkUpsertProductRequest
     */
    'mainVariantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BulkUpsertProductRequest
     */
    'brandId': string;
    /**
     * 
     * @type {string}
     * @memberof BulkUpsertProductRequest
     */
    'productGroupId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkUpsertProductRequest
     */
    'animalSpeciesIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkUpsertProductRequest
     */
    'categoryIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BulkUpsertProductRequest
     */
    'mainCategoryId'?: string | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof BulkUpsertProductRequest
     */
    'name': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof BulkUpsertProductRequest
     */
    'description': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof BulkUpsertProductRequest
     */
    'productLine'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof BulkUpsertProductRequest
     */
    'marketingText'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof BulkUpsertProductRequest
     */
    'seoProductTitle'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {string}
     * @memberof BulkUpsertProductRequest
     */
    'ean'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BulkUpsertProductRequest
     */
    'manufacturerProductNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BulkUpsertProductRequest
     */
    'releaseDate': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkUpsertProductRequest
     */
    'variantOptionIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkUpsertProductRequest
     */
    'propertyIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<InlineBulkUpsertProductStockRequest>}
     * @memberof BulkUpsertProductRequest
     */
    'productStocks'?: Array<InlineBulkUpsertProductStockRequest> | null;
    /**
     * 
     * @type {number}
     * @memberof BulkUpsertProductRequest
     */
    'restockTimeDays'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof BulkUpsertProductRequest
     */
    'isCloseout'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BulkUpsertProductRequest
     */
    'isBatchControlled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BulkUpsertProductRequest
     */
    'isBestBeforeControlled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BulkUpsertProductRequest
     */
    'isDangerousGoods'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BulkUpsertProductRequest
     */
    'purchaseSteps'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BulkUpsertProductRequest
     */
    'minPurchase'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BulkUpsertProductRequest
     */
    'maxPurchase'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BulkUpsertProductRequest
     */
    'purchaseUnit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BulkUpsertProductRequest
     */
    'referenceUnit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BulkUpsertProductRequest
     */
    'weight'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BulkUpsertProductRequest
     */
    'width'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BulkUpsertProductRequest
     */
    'height'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BulkUpsertProductRequest
     */
    'length'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BulkUpsertProductRequest
     */
    'productUnitId'?: string | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof BulkUpsertProductRequest
     */
    'packUnit'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof BulkUpsertProductRequest
     */
    'packUnitPlural'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {Array<InlineCreateDeliveryTimeRequest>}
     * @memberof BulkUpsertProductRequest
     */
    'deliveryTimes'?: Array<InlineCreateDeliveryTimeRequest> | null;
    /**
     * 
     * @type {Array<InlineCreateProductPriceRequest>}
     * @memberof BulkUpsertProductRequest
     */
    'productPrices'?: Array<InlineCreateProductPriceRequest> | null;
    /**
     * 
     * @type {TaxTypeIdentifier}
     * @memberof BulkUpsertProductRequest
     */
    'taxTypeIdentifier': TaxTypeIdentifier;
    /**
     * 
     * @type {Array<InlineCreateProductAssetRequest>}
     * @memberof BulkUpsertProductRequest
     */
    'productAssets'?: Array<InlineCreateProductAssetRequest> | null;
    /**
     * 
     * @type {Array<UpsertProductAdditionalInformationDownloadRequest>}
     * @memberof BulkUpsertProductRequest
     */
    'contentDownloads'?: Array<UpsertProductAdditionalInformationDownloadRequest> | null;
    /**
     * 
     * @type {Array<UpsertProductAdditionalInformationTableRequest>}
     * @memberof BulkUpsertProductRequest
     */
    'contentTables'?: Array<UpsertProductAdditionalInformationTableRequest> | null;
    /**
     * 
     * @type {Array<UpsertProductAdditionalInformationBulletPointsRequest>}
     * @memberof BulkUpsertProductRequest
     */
    'contentBulletPoints'?: Array<UpsertProductAdditionalInformationBulletPointsRequest> | null;
    /**
     * 
     * @type {Array<UpsertProductAdditionalInformationMediaGalleryRequest>}
     * @memberof BulkUpsertProductRequest
     */
    'contentMediaGalleries'?: Array<UpsertProductAdditionalInformationMediaGalleryRequest> | null;
    /**
     * 
     * @type {Array<UpsertProductAdditionalInformationRichTextRequest>}
     * @memberof BulkUpsertProductRequest
     */
    'contentRichTexts'?: Array<UpsertProductAdditionalInformationRichTextRequest> | null;
    /**
     * 
     * @type {Array<CreateProductAnalyticConstituentRequest>}
     * @memberof BulkUpsertProductRequest
     */
    'analyticConstituents'?: Array<CreateProductAnalyticConstituentRequest> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum BusinessTypeEnum {
    Other = 'other',
    ListedPublicCompany = 'listedPublicCompany',
    SubsidiaryOfListedPublicCompany = 'subsidiaryOfListedPublicCompany',
    GovernmentalOrganization = 'governmentalOrganization',
    InternationalOrganization = 'internationalOrganization',
    FinancialInstitution = 'financialInstitution'
}

/**
 * 
 * @export
 * @interface CalculatedTax
 */
export interface CalculatedTax {
    /**
     * 
     * @type {TaxTypeIdentifier}
     * @memberof CalculatedTax
     */
    'taxTypeIdentifier': TaxTypeIdentifier;
    /**
     * 
     * @type {number}
     * @memberof CalculatedTax
     */
    'taxRate': number;
    /**
     * 
     * @type {number}
     * @memberof CalculatedTax
     */
    'tax': number;
    /**
     * 
     * @type {number}
     * @memberof CalculatedTax
     */
    'taxPriceGross': number;
}
/**
 * 
 * @export
 * @interface CalculatedTaxResponse
 */
export interface CalculatedTaxResponse {
    /**
     * 
     * @type {number}
     * @memberof CalculatedTaxResponse
     */
    'tax'?: number;
    /**
     * 
     * @type {number}
     * @memberof CalculatedTaxResponse
     */
    'taxRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof CalculatedTaxResponse
     */
    'price'?: number;
    /**
     * 
     * @type {Array<any>}
     * @memberof CalculatedTaxResponse
     */
    'extensions'?: Array<any> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum CapabilitiesEnum {
    AcceptExternalFunding = 'acceptExternalFunding',
    AcceptPspFunding = 'acceptPspFunding',
    AcceptTransactionInRestrictedCountries = 'acceptTransactionInRestrictedCountries',
    AcceptTransactionInRestrictedCountriesCommercial = 'acceptTransactionInRestrictedCountriesCommercial',
    AcceptTransactionInRestrictedCountriesConsumer = 'acceptTransactionInRestrictedCountriesConsumer',
    AcceptTransactionInRestrictedIndustries = 'acceptTransactionInRestrictedIndustries',
    AcceptTransactionInRestrictedIndustriesCommercial = 'acceptTransactionInRestrictedIndustriesCommercial',
    AcceptTransactionInRestrictedIndustriesConsumer = 'acceptTransactionInRestrictedIndustriesConsumer',
    Acquiring = 'acquiring',
    AtmWithdrawal = 'atmWithdrawal',
    AtmWithdrawalCommercial = 'atmWithdrawalCommercial',
    AtmWithdrawalConsumer = 'atmWithdrawalConsumer',
    AtmWithdrawalInRestrictedCountries = 'atmWithdrawalInRestrictedCountries',
    AtmWithdrawalInRestrictedCountriesCommercial = 'atmWithdrawalInRestrictedCountriesCommercial',
    AtmWithdrawalInRestrictedCountriesConsumer = 'atmWithdrawalInRestrictedCountriesConsumer',
    AuthorisedPaymentInstrumentUser = 'authorisedPaymentInstrumentUser',
    GetGrantOffers = 'getGrantOffers',
    IssueBankAccount = 'issueBankAccount',
    IssueCard = 'issueCard',
    IssueCardCommercial = 'issueCardCommercial',
    IssueCardConsumer = 'issueCardConsumer',
    IssueChargeCard = 'issueChargeCard',
    IssueChargeCardCommercial = 'issueChargeCardCommercial',
    IssueCreditLimit = 'issueCreditLimit',
    LocalAcceptance = 'localAcceptance',
    Payout = 'payout',
    PayoutToTransferInstrument = 'payoutToTransferInstrument',
    Processing = 'processing',
    ReceiveFromBalanceAccount = 'receiveFromBalanceAccount',
    ReceiveFromPlatformPayments = 'receiveFromPlatformPayments',
    ReceiveFromThirdParty = 'receiveFromThirdParty',
    ReceiveFromTransferInstrument = 'receiveFromTransferInstrument',
    ReceiveGrants = 'receiveGrants',
    ReceivePayments = 'receivePayments',
    SendToBalanceAccount = 'sendToBalanceAccount',
    SendToThirdParty = 'sendToThirdParty',
    SendToTransferInstrument = 'sendToTransferInstrument',
    ThirdPartyFunding = 'thirdPartyFunding',
    UseCard = 'useCard',
    UseCardCommercial = 'useCardCommercial',
    UseCardConsumer = 'useCardConsumer',
    UseCardInRestrictedCountries = 'useCardInRestrictedCountries',
    UseCardInRestrictedCountriesCommercial = 'useCardInRestrictedCountriesCommercial',
    UseCardInRestrictedCountriesConsumer = 'useCardInRestrictedCountriesConsumer',
    UseCardInRestrictedIndustries = 'useCardInRestrictedIndustries',
    UseCardInRestrictedIndustriesCommercial = 'useCardInRestrictedIndustriesCommercial',
    UseCardInRestrictedIndustriesConsumer = 'useCardInRestrictedIndustriesConsumer',
    UseChargeCard = 'useChargeCard',
    UseChargeCardCommercial = 'useChargeCardCommercial',
    WithdrawFromAtm = 'withdrawFromAtm',
    WithdrawFromAtmCommercial = 'withdrawFromAtmCommercial',
    WithdrawFromAtmConsumer = 'withdrawFromAtmConsumer',
    WithdrawFromAtmInRestrictedCountries = 'withdrawFromAtmInRestrictedCountries',
    WithdrawFromAtmInRestrictedCountriesCommercial = 'withdrawFromAtmInRestrictedCountriesCommercial',
    WithdrawFromAtmInRestrictedCountriesConsumer = 'withdrawFromAtmInRestrictedCountriesConsumer'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum CapabilitiesEnum2 {
    AcceptExternalFunding = 'acceptExternalFunding',
    AcceptPspFunding = 'acceptPspFunding',
    AcceptTransactionInRestrictedCountries = 'acceptTransactionInRestrictedCountries',
    AcceptTransactionInRestrictedCountriesCommercial = 'acceptTransactionInRestrictedCountriesCommercial',
    AcceptTransactionInRestrictedCountriesConsumer = 'acceptTransactionInRestrictedCountriesConsumer',
    AcceptTransactionInRestrictedIndustries = 'acceptTransactionInRestrictedIndustries',
    AcceptTransactionInRestrictedIndustriesCommercial = 'acceptTransactionInRestrictedIndustriesCommercial',
    AcceptTransactionInRestrictedIndustriesConsumer = 'acceptTransactionInRestrictedIndustriesConsumer',
    Acquiring = 'acquiring',
    AtmWithdrawal = 'atmWithdrawal',
    AtmWithdrawalCommercial = 'atmWithdrawalCommercial',
    AtmWithdrawalConsumer = 'atmWithdrawalConsumer',
    AtmWithdrawalInRestrictedCountries = 'atmWithdrawalInRestrictedCountries',
    AtmWithdrawalInRestrictedCountriesCommercial = 'atmWithdrawalInRestrictedCountriesCommercial',
    AtmWithdrawalInRestrictedCountriesConsumer = 'atmWithdrawalInRestrictedCountriesConsumer',
    AuthorisedPaymentInstrumentUser = 'authorisedPaymentInstrumentUser',
    GetGrantOffers = 'getGrantOffers',
    IssueBankAccount = 'issueBankAccount',
    IssueCard = 'issueCard',
    IssueCardCommercial = 'issueCardCommercial',
    IssueCardConsumer = 'issueCardConsumer',
    IssueChargeCard = 'issueChargeCard',
    IssueChargeCardCommercial = 'issueChargeCardCommercial',
    IssueCreditLimit = 'issueCreditLimit',
    LocalAcceptance = 'localAcceptance',
    Payout = 'payout',
    PayoutToTransferInstrument = 'payoutToTransferInstrument',
    Processing = 'processing',
    ReceiveFromBalanceAccount = 'receiveFromBalanceAccount',
    ReceiveFromPlatformPayments = 'receiveFromPlatformPayments',
    ReceiveFromThirdParty = 'receiveFromThirdParty',
    ReceiveFromTransferInstrument = 'receiveFromTransferInstrument',
    ReceiveGrants = 'receiveGrants',
    ReceivePayments = 'receivePayments',
    SendToBalanceAccount = 'sendToBalanceAccount',
    SendToThirdParty = 'sendToThirdParty',
    SendToTransferInstrument = 'sendToTransferInstrument',
    ThirdPartyFunding = 'thirdPartyFunding',
    UseCard = 'useCard',
    UseCardCommercial = 'useCardCommercial',
    UseCardConsumer = 'useCardConsumer',
    UseCardInRestrictedCountries = 'useCardInRestrictedCountries',
    UseCardInRestrictedCountriesCommercial = 'useCardInRestrictedCountriesCommercial',
    UseCardInRestrictedCountriesConsumer = 'useCardInRestrictedCountriesConsumer',
    UseCardInRestrictedIndustries = 'useCardInRestrictedIndustries',
    UseCardInRestrictedIndustriesCommercial = 'useCardInRestrictedIndustriesCommercial',
    UseCardInRestrictedIndustriesConsumer = 'useCardInRestrictedIndustriesConsumer',
    UseChargeCard = 'useChargeCard',
    UseChargeCardCommercial = 'useChargeCardCommercial',
    WithdrawFromAtm = 'withdrawFromAtm',
    WithdrawFromAtmCommercial = 'withdrawFromAtmCommercial',
    WithdrawFromAtmConsumer = 'withdrawFromAtmConsumer',
    WithdrawFromAtmInRestrictedCountries = 'withdrawFromAtmInRestrictedCountries',
    WithdrawFromAtmInRestrictedCountriesCommercial = 'withdrawFromAtmInRestrictedCountriesCommercial',
    WithdrawFromAtmInRestrictedCountriesConsumer = 'withdrawFromAtmInRestrictedCountriesConsumer'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum CapabilitiesEnum3 {
    AcceptExternalFunding = 'acceptExternalFunding',
    AcceptPspFunding = 'acceptPspFunding',
    AcceptTransactionInRestrictedCountries = 'acceptTransactionInRestrictedCountries',
    AcceptTransactionInRestrictedCountriesCommercial = 'acceptTransactionInRestrictedCountriesCommercial',
    AcceptTransactionInRestrictedCountriesConsumer = 'acceptTransactionInRestrictedCountriesConsumer',
    AcceptTransactionInRestrictedIndustries = 'acceptTransactionInRestrictedIndustries',
    AcceptTransactionInRestrictedIndustriesCommercial = 'acceptTransactionInRestrictedIndustriesCommercial',
    AcceptTransactionInRestrictedIndustriesConsumer = 'acceptTransactionInRestrictedIndustriesConsumer',
    Acquiring = 'acquiring',
    AtmWithdrawal = 'atmWithdrawal',
    AtmWithdrawalCommercial = 'atmWithdrawalCommercial',
    AtmWithdrawalConsumer = 'atmWithdrawalConsumer',
    AtmWithdrawalInRestrictedCountries = 'atmWithdrawalInRestrictedCountries',
    AtmWithdrawalInRestrictedCountriesCommercial = 'atmWithdrawalInRestrictedCountriesCommercial',
    AtmWithdrawalInRestrictedCountriesConsumer = 'atmWithdrawalInRestrictedCountriesConsumer',
    AuthorisedPaymentInstrumentUser = 'authorisedPaymentInstrumentUser',
    GetGrantOffers = 'getGrantOffers',
    IssueBankAccount = 'issueBankAccount',
    IssueCard = 'issueCard',
    IssueCardCommercial = 'issueCardCommercial',
    IssueCardConsumer = 'issueCardConsumer',
    IssueChargeCard = 'issueChargeCard',
    IssueChargeCardCommercial = 'issueChargeCardCommercial',
    IssueCreditLimit = 'issueCreditLimit',
    LocalAcceptance = 'localAcceptance',
    Payout = 'payout',
    PayoutToTransferInstrument = 'payoutToTransferInstrument',
    Processing = 'processing',
    ReceiveFromBalanceAccount = 'receiveFromBalanceAccount',
    ReceiveFromPlatformPayments = 'receiveFromPlatformPayments',
    ReceiveFromThirdParty = 'receiveFromThirdParty',
    ReceiveFromTransferInstrument = 'receiveFromTransferInstrument',
    ReceiveGrants = 'receiveGrants',
    ReceivePayments = 'receivePayments',
    SendToBalanceAccount = 'sendToBalanceAccount',
    SendToThirdParty = 'sendToThirdParty',
    SendToTransferInstrument = 'sendToTransferInstrument',
    ThirdPartyFunding = 'thirdPartyFunding',
    UseCard = 'useCard',
    UseCardCommercial = 'useCardCommercial',
    UseCardConsumer = 'useCardConsumer',
    UseCardInRestrictedCountries = 'useCardInRestrictedCountries',
    UseCardInRestrictedCountriesCommercial = 'useCardInRestrictedCountriesCommercial',
    UseCardInRestrictedCountriesConsumer = 'useCardInRestrictedCountriesConsumer',
    UseCardInRestrictedIndustries = 'useCardInRestrictedIndustries',
    UseCardInRestrictedIndustriesCommercial = 'useCardInRestrictedIndustriesCommercial',
    UseCardInRestrictedIndustriesConsumer = 'useCardInRestrictedIndustriesConsumer',
    UseChargeCard = 'useChargeCard',
    UseChargeCardCommercial = 'useChargeCardCommercial',
    WithdrawFromAtm = 'withdrawFromAtm',
    WithdrawFromAtmCommercial = 'withdrawFromAtmCommercial',
    WithdrawFromAtmConsumer = 'withdrawFromAtmConsumer',
    WithdrawFromAtmInRestrictedCountries = 'withdrawFromAtmInRestrictedCountries',
    WithdrawFromAtmInRestrictedCountriesCommercial = 'withdrawFromAtmInRestrictedCountriesCommercial',
    WithdrawFromAtmInRestrictedCountriesConsumer = 'withdrawFromAtmInRestrictedCountriesConsumer'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum CapabilitiesEnum4 {
    AcceptExternalFunding = 'acceptExternalFunding',
    AcceptPspFunding = 'acceptPspFunding',
    AcceptTransactionInRestrictedCountries = 'acceptTransactionInRestrictedCountries',
    AcceptTransactionInRestrictedCountriesCommercial = 'acceptTransactionInRestrictedCountriesCommercial',
    AcceptTransactionInRestrictedCountriesConsumer = 'acceptTransactionInRestrictedCountriesConsumer',
    AcceptTransactionInRestrictedIndustries = 'acceptTransactionInRestrictedIndustries',
    AcceptTransactionInRestrictedIndustriesCommercial = 'acceptTransactionInRestrictedIndustriesCommercial',
    AcceptTransactionInRestrictedIndustriesConsumer = 'acceptTransactionInRestrictedIndustriesConsumer',
    Acquiring = 'acquiring',
    AtmWithdrawal = 'atmWithdrawal',
    AtmWithdrawalCommercial = 'atmWithdrawalCommercial',
    AtmWithdrawalConsumer = 'atmWithdrawalConsumer',
    AtmWithdrawalInRestrictedCountries = 'atmWithdrawalInRestrictedCountries',
    AtmWithdrawalInRestrictedCountriesCommercial = 'atmWithdrawalInRestrictedCountriesCommercial',
    AtmWithdrawalInRestrictedCountriesConsumer = 'atmWithdrawalInRestrictedCountriesConsumer',
    AuthorisedPaymentInstrumentUser = 'authorisedPaymentInstrumentUser',
    GetGrantOffers = 'getGrantOffers',
    IssueBankAccount = 'issueBankAccount',
    IssueCard = 'issueCard',
    IssueCardCommercial = 'issueCardCommercial',
    IssueCardConsumer = 'issueCardConsumer',
    IssueChargeCard = 'issueChargeCard',
    IssueChargeCardCommercial = 'issueChargeCardCommercial',
    IssueCreditLimit = 'issueCreditLimit',
    LocalAcceptance = 'localAcceptance',
    Payout = 'payout',
    PayoutToTransferInstrument = 'payoutToTransferInstrument',
    Processing = 'processing',
    ReceiveFromBalanceAccount = 'receiveFromBalanceAccount',
    ReceiveFromPlatformPayments = 'receiveFromPlatformPayments',
    ReceiveFromThirdParty = 'receiveFromThirdParty',
    ReceiveFromTransferInstrument = 'receiveFromTransferInstrument',
    ReceiveGrants = 'receiveGrants',
    ReceivePayments = 'receivePayments',
    SendToBalanceAccount = 'sendToBalanceAccount',
    SendToThirdParty = 'sendToThirdParty',
    SendToTransferInstrument = 'sendToTransferInstrument',
    ThirdPartyFunding = 'thirdPartyFunding',
    UseCard = 'useCard',
    UseCardCommercial = 'useCardCommercial',
    UseCardConsumer = 'useCardConsumer',
    UseCardInRestrictedCountries = 'useCardInRestrictedCountries',
    UseCardInRestrictedCountriesCommercial = 'useCardInRestrictedCountriesCommercial',
    UseCardInRestrictedCountriesConsumer = 'useCardInRestrictedCountriesConsumer',
    UseCardInRestrictedIndustries = 'useCardInRestrictedIndustries',
    UseCardInRestrictedIndustriesCommercial = 'useCardInRestrictedIndustriesCommercial',
    UseCardInRestrictedIndustriesConsumer = 'useCardInRestrictedIndustriesConsumer',
    UseChargeCard = 'useChargeCard',
    UseChargeCardCommercial = 'useChargeCardCommercial',
    WithdrawFromAtm = 'withdrawFromAtm',
    WithdrawFromAtmCommercial = 'withdrawFromAtmCommercial',
    WithdrawFromAtmConsumer = 'withdrawFromAtmConsumer',
    WithdrawFromAtmInRestrictedCountries = 'withdrawFromAtmInRestrictedCountries',
    WithdrawFromAtmInRestrictedCountriesCommercial = 'withdrawFromAtmInRestrictedCountriesCommercial',
    WithdrawFromAtmInRestrictedCountriesConsumer = 'withdrawFromAtmInRestrictedCountriesConsumer'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum CapabilitiesEnum5 {
    AcceptExternalFunding = 'acceptExternalFunding',
    AcceptPspFunding = 'acceptPspFunding',
    AcceptTransactionInRestrictedCountries = 'acceptTransactionInRestrictedCountries',
    AcceptTransactionInRestrictedCountriesCommercial = 'acceptTransactionInRestrictedCountriesCommercial',
    AcceptTransactionInRestrictedCountriesConsumer = 'acceptTransactionInRestrictedCountriesConsumer',
    AcceptTransactionInRestrictedIndustries = 'acceptTransactionInRestrictedIndustries',
    AcceptTransactionInRestrictedIndustriesCommercial = 'acceptTransactionInRestrictedIndustriesCommercial',
    AcceptTransactionInRestrictedIndustriesConsumer = 'acceptTransactionInRestrictedIndustriesConsumer',
    Acquiring = 'acquiring',
    AtmWithdrawal = 'atmWithdrawal',
    AtmWithdrawalCommercial = 'atmWithdrawalCommercial',
    AtmWithdrawalConsumer = 'atmWithdrawalConsumer',
    AtmWithdrawalInRestrictedCountries = 'atmWithdrawalInRestrictedCountries',
    AtmWithdrawalInRestrictedCountriesCommercial = 'atmWithdrawalInRestrictedCountriesCommercial',
    AtmWithdrawalInRestrictedCountriesConsumer = 'atmWithdrawalInRestrictedCountriesConsumer',
    AuthorisedPaymentInstrumentUser = 'authorisedPaymentInstrumentUser',
    GetGrantOffers = 'getGrantOffers',
    IssueBankAccount = 'issueBankAccount',
    IssueCard = 'issueCard',
    IssueCardCommercial = 'issueCardCommercial',
    IssueCardConsumer = 'issueCardConsumer',
    IssueChargeCard = 'issueChargeCard',
    IssueChargeCardCommercial = 'issueChargeCardCommercial',
    IssueCreditLimit = 'issueCreditLimit',
    LocalAcceptance = 'localAcceptance',
    Payout = 'payout',
    PayoutToTransferInstrument = 'payoutToTransferInstrument',
    Processing = 'processing',
    ReceiveFromBalanceAccount = 'receiveFromBalanceAccount',
    ReceiveFromPlatformPayments = 'receiveFromPlatformPayments',
    ReceiveFromThirdParty = 'receiveFromThirdParty',
    ReceiveFromTransferInstrument = 'receiveFromTransferInstrument',
    ReceiveGrants = 'receiveGrants',
    ReceivePayments = 'receivePayments',
    SendToBalanceAccount = 'sendToBalanceAccount',
    SendToThirdParty = 'sendToThirdParty',
    SendToTransferInstrument = 'sendToTransferInstrument',
    ThirdPartyFunding = 'thirdPartyFunding',
    UseCard = 'useCard',
    UseCardCommercial = 'useCardCommercial',
    UseCardConsumer = 'useCardConsumer',
    UseCardInRestrictedCountries = 'useCardInRestrictedCountries',
    UseCardInRestrictedCountriesCommercial = 'useCardInRestrictedCountriesCommercial',
    UseCardInRestrictedCountriesConsumer = 'useCardInRestrictedCountriesConsumer',
    UseCardInRestrictedIndustries = 'useCardInRestrictedIndustries',
    UseCardInRestrictedIndustriesCommercial = 'useCardInRestrictedIndustriesCommercial',
    UseCardInRestrictedIndustriesConsumer = 'useCardInRestrictedIndustriesConsumer',
    UseChargeCard = 'useChargeCard',
    UseChargeCardCommercial = 'useChargeCardCommercial',
    WithdrawFromAtm = 'withdrawFromAtm',
    WithdrawFromAtmCommercial = 'withdrawFromAtmCommercial',
    WithdrawFromAtmConsumer = 'withdrawFromAtmConsumer',
    WithdrawFromAtmInRestrictedCountries = 'withdrawFromAtmInRestrictedCountries',
    WithdrawFromAtmInRestrictedCountriesCommercial = 'withdrawFromAtmInRestrictedCountriesCommercial',
    WithdrawFromAtmInRestrictedCountriesConsumer = 'withdrawFromAtmInRestrictedCountriesConsumer'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum CapabilitiesEnum6 {
    AcceptExternalFunding = 'acceptExternalFunding',
    AcceptPspFunding = 'acceptPspFunding',
    AcceptTransactionInRestrictedCountries = 'acceptTransactionInRestrictedCountries',
    AcceptTransactionInRestrictedCountriesCommercial = 'acceptTransactionInRestrictedCountriesCommercial',
    AcceptTransactionInRestrictedCountriesConsumer = 'acceptTransactionInRestrictedCountriesConsumer',
    AcceptTransactionInRestrictedIndustries = 'acceptTransactionInRestrictedIndustries',
    AcceptTransactionInRestrictedIndustriesCommercial = 'acceptTransactionInRestrictedIndustriesCommercial',
    AcceptTransactionInRestrictedIndustriesConsumer = 'acceptTransactionInRestrictedIndustriesConsumer',
    Acquiring = 'acquiring',
    AtmWithdrawal = 'atmWithdrawal',
    AtmWithdrawalCommercial = 'atmWithdrawalCommercial',
    AtmWithdrawalConsumer = 'atmWithdrawalConsumer',
    AtmWithdrawalInRestrictedCountries = 'atmWithdrawalInRestrictedCountries',
    AtmWithdrawalInRestrictedCountriesCommercial = 'atmWithdrawalInRestrictedCountriesCommercial',
    AtmWithdrawalInRestrictedCountriesConsumer = 'atmWithdrawalInRestrictedCountriesConsumer',
    AuthorisedPaymentInstrumentUser = 'authorisedPaymentInstrumentUser',
    GetGrantOffers = 'getGrantOffers',
    IssueBankAccount = 'issueBankAccount',
    IssueCard = 'issueCard',
    IssueCardCommercial = 'issueCardCommercial',
    IssueCardConsumer = 'issueCardConsumer',
    IssueChargeCard = 'issueChargeCard',
    IssueChargeCardCommercial = 'issueChargeCardCommercial',
    IssueCreditLimit = 'issueCreditLimit',
    LocalAcceptance = 'localAcceptance',
    Payout = 'payout',
    PayoutToTransferInstrument = 'payoutToTransferInstrument',
    Processing = 'processing',
    ReceiveFromBalanceAccount = 'receiveFromBalanceAccount',
    ReceiveFromPlatformPayments = 'receiveFromPlatformPayments',
    ReceiveFromThirdParty = 'receiveFromThirdParty',
    ReceiveFromTransferInstrument = 'receiveFromTransferInstrument',
    ReceiveGrants = 'receiveGrants',
    ReceivePayments = 'receivePayments',
    SendToBalanceAccount = 'sendToBalanceAccount',
    SendToThirdParty = 'sendToThirdParty',
    SendToTransferInstrument = 'sendToTransferInstrument',
    ThirdPartyFunding = 'thirdPartyFunding',
    UseCard = 'useCard',
    UseCardCommercial = 'useCardCommercial',
    UseCardConsumer = 'useCardConsumer',
    UseCardInRestrictedCountries = 'useCardInRestrictedCountries',
    UseCardInRestrictedCountriesCommercial = 'useCardInRestrictedCountriesCommercial',
    UseCardInRestrictedCountriesConsumer = 'useCardInRestrictedCountriesConsumer',
    UseCardInRestrictedIndustries = 'useCardInRestrictedIndustries',
    UseCardInRestrictedIndustriesCommercial = 'useCardInRestrictedIndustriesCommercial',
    UseCardInRestrictedIndustriesConsumer = 'useCardInRestrictedIndustriesConsumer',
    UseChargeCard = 'useChargeCard',
    UseChargeCardCommercial = 'useChargeCardCommercial',
    WithdrawFromAtm = 'withdrawFromAtm',
    WithdrawFromAtmCommercial = 'withdrawFromAtmCommercial',
    WithdrawFromAtmConsumer = 'withdrawFromAtmConsumer',
    WithdrawFromAtmInRestrictedCountries = 'withdrawFromAtmInRestrictedCountries',
    WithdrawFromAtmInRestrictedCountriesCommercial = 'withdrawFromAtmInRestrictedCountriesCommercial',
    WithdrawFromAtmInRestrictedCountriesConsumer = 'withdrawFromAtmInRestrictedCountriesConsumer'
}

/**
 * 
 * @export
 * @interface CapabilityProblem
 */
export interface CapabilityProblem {
    /**
     * 
     * @type {CapabilityProblemEntity}
     * @memberof CapabilityProblem
     */
    'entity'?: CapabilityProblemEntity | null;
    /**
     * 
     * @type {Array<VerificationError>}
     * @memberof CapabilityProblem
     */
    'verificationErrors'?: Array<VerificationError> | null;
}
/**
 * 
 * @export
 * @interface CapabilityProblem2
 */
export interface CapabilityProblem2 {
    /**
     * 
     * @type {CapabilityProblemEntity2}
     * @memberof CapabilityProblem2
     */
    'entity'?: CapabilityProblemEntity2 | null;
    /**
     * 
     * @type {Array<VerificationError2>}
     * @memberof CapabilityProblem2
     */
    'verificationErrors'?: Array<VerificationError2> | null;
}
/**
 * 
 * @export
 * @interface CapabilityProblemEntity
 */
export interface CapabilityProblemEntity {
    /**
     * 
     * @type {TypeEnum5}
     * @memberof CapabilityProblemEntity
     */
    'type'?: TypeEnum5 | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CapabilityProblemEntity
     */
    'documents'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CapabilityProblemEntity
     */
    'id'?: string | null;
    /**
     * 
     * @type {CapabilityProblemEntityRecursive}
     * @memberof CapabilityProblemEntity
     */
    'owner'?: CapabilityProblemEntityRecursive | null;
}
/**
 * 
 * @export
 * @interface CapabilityProblemEntity2
 */
export interface CapabilityProblemEntity2 {
    /**
     * 
     * @type {TypeEnum11}
     * @memberof CapabilityProblemEntity2
     */
    'type'?: TypeEnum11 | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CapabilityProblemEntity2
     */
    'documents'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CapabilityProblemEntity2
     */
    'id'?: string | null;
    /**
     * 
     * @type {CapabilityProblemEntityRecursive2}
     * @memberof CapabilityProblemEntity2
     */
    'owner'?: CapabilityProblemEntityRecursive2 | null;
}
/**
 * 
 * @export
 * @interface CapabilityProblemEntityRecursive
 */
export interface CapabilityProblemEntityRecursive {
    /**
     * 
     * @type {TypeEnum6}
     * @memberof CapabilityProblemEntityRecursive
     */
    'type'?: TypeEnum6 | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CapabilityProblemEntityRecursive
     */
    'documents'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CapabilityProblemEntityRecursive
     */
    'id'?: string | null;
}
/**
 * 
 * @export
 * @interface CapabilityProblemEntityRecursive2
 */
export interface CapabilityProblemEntityRecursive2 {
    /**
     * 
     * @type {TypeEnum12}
     * @memberof CapabilityProblemEntityRecursive2
     */
    'type'?: TypeEnum12 | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CapabilityProblemEntityRecursive2
     */
    'documents'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CapabilityProblemEntityRecursive2
     */
    'id'?: string | null;
}
/**
 * 
 * @export
 * @interface CapabilitySettings
 */
export interface CapabilitySettings {
    /**
     * 
     * @type {Array<FundingSourceEnum>}
     * @memberof CapabilitySettings
     */
    'fundingSource'?: Array<FundingSourceEnum> | null;
    /**
     * 
     * @type {IntervalEnum}
     * @memberof CapabilitySettings
     */
    'interval'?: IntervalEnum | null;
    /**
     * 
     * @type {{ [key: string]: Amount2; }}
     * @memberof CapabilitySettings
     */
    'amountPerIndustry'?: { [key: string]: Amount2; } | null;
    /**
     * 
     * @type {boolean}
     * @memberof CapabilitySettings
     */
    'authorizedCardUsers'?: boolean | null;
    /**
     * 
     * @type {Amount2}
     * @memberof CapabilitySettings
     */
    'maxAmount'?: Amount2 | null;
}
/**
 * 
 * @export
 * @interface CapabilitySettings2
 */
export interface CapabilitySettings2 {
    /**
     * 
     * @type {Array<FundingSourceEnum2>}
     * @memberof CapabilitySettings2
     */
    'fundingSource'?: Array<FundingSourceEnum2> | null;
    /**
     * 
     * @type {IntervalEnum2}
     * @memberof CapabilitySettings2
     */
    'interval'?: IntervalEnum2 | null;
    /**
     * 
     * @type {{ [key: string]: Amount3; }}
     * @memberof CapabilitySettings2
     */
    'amountPerIndustry'?: { [key: string]: Amount3; } | null;
    /**
     * 
     * @type {boolean}
     * @memberof CapabilitySettings2
     */
    'authorizedCardUsers'?: boolean | null;
    /**
     * 
     * @type {Amount3}
     * @memberof CapabilitySettings2
     */
    'maxAmount'?: Amount3 | null;
}
/**
 * 
 * @export
 * @interface ContactDetails
 */
export interface ContactDetails {
    /**
     * 
     * @type {Address}
     * @memberof ContactDetails
     */
    'address'?: Address | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDetails
     */
    'email'?: string | null;
    /**
     * 
     * @type {Phone}
     * @memberof ContactDetails
     */
    'phone'?: Phone | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDetails
     */
    'webAddress'?: string | null;
}
/**
 * 
 * @export
 * @interface CountryResponse
 */
export interface CountryResponse {
    /**
     * 
     * @type {string}
     * @memberof CountryResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CountryResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CountryResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CountryResponse
     */
    'iso': string;
    /**
     * 
     * @type {string}
     * @memberof CountryResponse
     */
    'iso3': string;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof CountryResponse
     */
    'name': TranslatedTypeResponseOfString;
}
/**
 * 
 * @export
 * @interface CountryResponseAllOf
 */
export interface CountryResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof CountryResponseAllOf
     */
    'iso': string;
    /**
     * 
     * @type {string}
     * @memberof CountryResponseAllOf
     */
    'iso3': string;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof CountryResponseAllOf
     */
    'name': TranslatedTypeResponseOfString;
}
/**
 * 
 * @export
 * @interface CreateAdyenAccountHolderRequest
 */
export interface CreateAdyenAccountHolderRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenAccountHolderRequest
     */
    'balancePlatform'?: string | null;
    /**
     * 
     * @type {{ [key: string]: AccountHolderCapability; }}
     * @memberof CreateAdyenAccountHolderRequest
     */
    'capabilities'?: { [key: string]: AccountHolderCapability; } | null;
    /**
     * 
     * @type {ContactDetails}
     * @memberof CreateAdyenAccountHolderRequest
     * @deprecated
     */
    'contactDetails'?: ContactDetails | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenAccountHolderRequest
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenAccountHolderRequest
     */
    'legalEntityId'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateAdyenAccountHolderRequest
     */
    'metadata'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenAccountHolderRequest
     */
    'migratedAccountHolderCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenAccountHolderRequest
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenAccountHolderRequest
     */
    'timeZone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenAccountHolderRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenAccountHolderRequest
     */
    'manufacturerId': string;
}
/**
 * 
 * @export
 * @interface CreateAdyenAccountHolderRequestAllOf
 */
export interface CreateAdyenAccountHolderRequestAllOf {
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenAccountHolderRequestAllOf
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenAccountHolderRequestAllOf
     */
    'manufacturerId': string;
}
/**
 * 
 * @export
 * @interface CreateAdyenBalanceAccountRequest
 */
export interface CreateAdyenBalanceAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenBalanceAccountRequest
     */
    'accountHolderId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenBalanceAccountRequest
     */
    'defaultCurrencyCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenBalanceAccountRequest
     */
    'description'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateAdyenBalanceAccountRequest
     */
    'metadata'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenBalanceAccountRequest
     */
    'migratedAccountCode'?: string | null;
    /**
     * 
     * @type {PlatformPaymentConfiguration}
     * @memberof CreateAdyenBalanceAccountRequest
     */
    'platformPaymentConfiguration'?: PlatformPaymentConfiguration | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenBalanceAccountRequest
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenBalanceAccountRequest
     */
    'timeZone'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateAdyenLegalEntityRequest
 */
export interface CreateAdyenLegalEntityRequest {
    /**
     * 
     * @type {TypeEnum10}
     * @memberof CreateAdyenLegalEntityRequest
     */
    'type'?: TypeEnum10;
    /**
     * 
     * @type {{ [key: string]: LegalEntityCapability; }}
     * @memberof CreateAdyenLegalEntityRequest
     */
    'capabilities'?: { [key: string]: LegalEntityCapability; } | null;
    /**
     * 
     * @type {Array<LegalEntityAssociation>}
     * @memberof CreateAdyenLegalEntityRequest
     */
    'entityAssociations'?: Array<LegalEntityAssociation> | null;
    /**
     * 
     * @type {Individual}
     * @memberof CreateAdyenLegalEntityRequest
     */
    'individual'?: Individual | null;
    /**
     * 
     * @type {Organization}
     * @memberof CreateAdyenLegalEntityRequest
     */
    'organization'?: Organization | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenLegalEntityRequest
     */
    'reference'?: string | null;
    /**
     * 
     * @type {SoleProprietorship}
     * @memberof CreateAdyenLegalEntityRequest
     */
    'soleProprietorship'?: SoleProprietorship | null;
    /**
     * 
     * @type {Trust}
     * @memberof CreateAdyenLegalEntityRequest
     */
    'trust'?: Trust | null;
    /**
     * 
     * @type {UnincorporatedPartnership}
     * @memberof CreateAdyenLegalEntityRequest
     */
    'unincorporatedPartnership'?: UnincorporatedPartnership | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenLegalEntityRequest
     */
    'verificationPlan'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenLegalEntityRequest
     */
    'manufacturerId': string;
}
/**
 * 
 * @export
 * @interface CreateAdyenLegalEntityRequestAllOf
 */
export interface CreateAdyenLegalEntityRequestAllOf {
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenLegalEntityRequestAllOf
     */
    'manufacturerId': string;
}
/**
 * 
 * @export
 * @interface CreateAdyenPaymentRefundDetailRequest
 */
export interface CreateAdyenPaymentRefundDetailRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenPaymentRefundDetailRequest
     */
    'orderPositionId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenPaymentRefundDetailRequest
     */
    'orderPositionUnitId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateAdyenPaymentRefundDetailRequest
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenPaymentRefundDetailRequest
     */
    'orderReturnPositionId'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateAdyenPaymentRefundRequest
 */
export interface CreateAdyenPaymentRefundRequest {
    /**
     * 
     * @type {Array<CreateAdyenPaymentRefundDetailRequest>}
     * @memberof CreateAdyenPaymentRefundRequest
     */
    'refundOrderPositions'?: Array<CreateAdyenPaymentRefundDetailRequest> | null;
}
/**
 * 
 * @export
 * @interface CreateAdyenTransferInstrumentRequest
 */
export interface CreateAdyenTransferInstrumentRequest {
    /**
     * 
     * @type {TypeEnum9}
     * @memberof CreateAdyenTransferInstrumentRequest
     */
    'type'?: TypeEnum9;
    /**
     * 
     * @type {CreateAdyenTransferInstrumentRequestBankAccountInfo}
     * @memberof CreateAdyenTransferInstrumentRequest
     */
    'bankAccount'?: CreateAdyenTransferInstrumentRequestBankAccountInfo | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenTransferInstrumentRequest
     */
    'legalEntityId'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateAdyenTransferInstrumentRequestAllOf
 */
export interface CreateAdyenTransferInstrumentRequestAllOf {
    /**
     * 
     * @type {CreateAdyenTransferInstrumentRequestBankAccountInfo}
     * @memberof CreateAdyenTransferInstrumentRequestAllOf
     */
    'bankAccount'?: CreateAdyenTransferInstrumentRequestBankAccountInfo | null;
}
/**
 * 
 * @export
 * @interface CreateAdyenTransferInstrumentRequestBankAccountInfo
 */
export interface CreateAdyenTransferInstrumentRequestBankAccountInfo {
    /**
     * 
     * @type {IbanAccountIdentification}
     * @memberof CreateAdyenTransferInstrumentRequestBankAccountInfo
     */
    'accountIdentification'?: IbanAccountIdentification | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenTransferInstrumentRequestBankAccountInfo
     * @deprecated
     */
    'accountType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenTransferInstrumentRequestBankAccountInfo
     */
    'bankName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAdyenTransferInstrumentRequestBankAccountInfo
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAdyenTransferInstrumentRequestBankAccountInfo
     */
    'trustedSource'?: boolean | null;
}
/**
 * 
 * @export
 * @interface CreateAdyenTransferInstrumentRequestBankAccountInfoAllOf
 */
export interface CreateAdyenTransferInstrumentRequestBankAccountInfoAllOf {
    /**
     * 
     * @type {IbanAccountIdentification}
     * @memberof CreateAdyenTransferInstrumentRequestBankAccountInfoAllOf
     */
    'accountIdentification'?: IbanAccountIdentification | null;
}
/**
 * 
 * @export
 * @interface CreateAnimalSpeciesRequest
 */
export interface CreateAnimalSpeciesRequest {
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateAnimalSpeciesRequest
     */
    'name': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateAnimalSpeciesRequest
     */
    'slug': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {string}
     * @memberof CreateAnimalSpeciesRequest
     */
    'identifier': string;
}
/**
 * 
 * @export
 * @interface CreateAssetFolderRequest
 */
export interface CreateAssetFolderRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAssetFolderRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAssetFolderRequest
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAssetFolderRequest
     */
    'parentId'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateBrandContactAddressRequest
 */
export interface CreateBrandContactAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateBrandContactAddressRequest
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandContactAddressRequest
     */
    'streetNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandContactAddressRequest
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandContactAddressRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandContactAddressRequest
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandContactAddressRequest
     */
    'additionalAddressLine1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandContactAddressRequest
     */
    'additionalAddressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandContactAddressRequest
     */
    'countryId'?: string;
    /**
     * 
     * @type {Department}
     * @memberof CreateBrandContactAddressRequest
     */
    'department': Department;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandContactAddressRequest
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandContactAddressRequest
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandContactAddressRequest
     */
    'salutation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandContactAddressRequest
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandContactAddressRequest
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandContactAddressRequest
     */
    'brandId': string;
}
/**
 * 
 * @export
 * @interface CreateBrandRequest
 */
export interface CreateBrandRequest {
    /**
     * 
     * @type {boolean}
     * @memberof CreateBrandRequest
     */
    'isActiveInStorefront': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequest
     */
    'slug': string;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateBrandRequest
     */
    'name': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateBrandRequest
     */
    'claim'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfNullableGuid}
     * @memberof CreateBrandRequest
     */
    'logoId'?: TranslatedTypeRequestOfNullableGuid | null;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequest
     */
    'deliveryPausedFrom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequest
     */
    'deliveryPausedUntil'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateBrandRequest
     */
    'earliestDeliveryTimeInDays'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateBrandRequest
     */
    'latestDeliveryTimeInDays'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBrandRequest
     */
    'overrideProductDeliveryTime'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandRequest
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateBrandStoreRequest
 */
export interface CreateBrandStoreRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateBrandStoreRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandStoreRequest
     */
    'primaryColorHex': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandStoreRequest
     */
    'secondaryColorHex'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandStoreRequest
     */
    'primaryContrastColorHex': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandStoreRequest
     */
    'secondaryContrastColorHex'?: string | null;
    /**
     * 
     * @type {BrandStoreState}
     * @memberof CreateBrandStoreRequest
     */
    'state': BrandStoreState;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandStoreRequest
     */
    'manufacturerId': string;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateBrandStoreRequest
     */
    'claim'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateBrandStoreRequest
     */
    'description': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {TranslatedTypeRequestOfDictionaryOfStringAndString}
     * @memberof CreateBrandStoreRequest
     */
    'faq'?: TranslatedTypeRequestOfDictionaryOfStringAndString | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfNullableGuid}
     * @memberof CreateBrandStoreRequest
     */
    'heroBannerAssetId': TranslatedTypeRequestOfNullableGuid;
    /**
     * 
     * @type {string}
     * @memberof CreateBrandStoreRequest
     */
    'brandId': string;
}
/**
 * 
 * @export
 * @interface CreateBulkEditorHistoryRequest
 */
export interface CreateBulkEditorHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateBulkEditorHistoryRequest
     */
    'data': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBulkEditorHistoryRequest
     */
    'parentBulkEditorHistoryId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateBulkEditorHistoryRequest
     */
    'comment'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateBulkEditorSessionRequest
 */
export interface CreateBulkEditorSessionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateBulkEditorSessionRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateBulkEditorSessionRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateCredentialRequest
 */
export interface CreateCredentialRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCredentialRequest
     */
    'data': string;
    /**
     * 
     * @type {CredentialContext}
     * @memberof CreateCredentialRequest
     */
    'credentialContext': CredentialContext;
    /**
     * 
     * @type {CredentialType}
     * @memberof CreateCredentialRequest
     */
    'credentialType': CredentialType;
    /**
     * 
     * @type {string}
     * @memberof CreateCredentialRequest
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateCustomerRequest
 */
export interface CreateCustomerRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'shopReferenceId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'shopCustomerReferenceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'customerNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'languageId': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCustomerRequest
     */
    'isGuest': boolean;
    /**
     * 
     * @type {any}
     * @memberof CreateCustomerRequest
     */
    'shopCustomFields'?: any | null;
}
/**
 * 
 * @export
 * @interface CreateDataImportConfigRequest
 */
export interface CreateDataImportConfigRequest {
    /**
     * 
     * @type {DataImportTargetEntity}
     * @memberof CreateDataImportConfigRequest
     */
    'dataImportTargetEntity'?: DataImportTargetEntity | null;
    /**
     * 
     * @type {string}
     * @memberof CreateDataImportConfigRequest
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDataImportConfigRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDataImportConfigRequest
     */
    'apiVersion': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof CreateDataImportConfigRequest
     */
    'config'?: Array<any> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateDataImportConfigRequest
     */
    'handler': string;
}
/**
 * 
 * @export
 * @interface CreateDataImportRequest
 */
export interface CreateDataImportRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateDataImportRequest
     */
    'importFileId': string;
    /**
     * 
     * @type {ImportFileType}
     * @memberof CreateDataImportRequest
     */
    'importFileType': ImportFileType;
    /**
     * 
     * @type {string}
     * @memberof CreateDataImportRequest
     */
    'delimiter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateDataImportRequest
     */
    'qualifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateDataImportRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateDataImportRequest
     */
    'dataImportConfigId': string;
}
/**
 * 
 * @export
 * @interface CreateDeliveryTimeRequest
 */
export interface CreateDeliveryTimeRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryTimeRequest
     */
    'min': number;
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryTimeRequest
     */
    'max': number;
    /**
     * 
     * @type {DeliveryTimeUnit}
     * @memberof CreateDeliveryTimeRequest
     */
    'unit': DeliveryTimeUnit;
    /**
     * 
     * @type {DeliveryTimeType}
     * @memberof CreateDeliveryTimeRequest
     */
    'type': DeliveryTimeType;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryTimeRequest
     */
    'productId'?: string;
}
/**
 * 
 * @export
 * @interface CreateDocumentLayoutRequest
 */
export interface CreateDocumentLayoutRequest {
    /**
     * 
     * @type {DocumentState}
     * @memberof CreateDocumentLayoutRequest
     */
    'state': DocumentState;
    /**
     * 
     * @type {DocumentType}
     * @memberof CreateDocumentLayoutRequest
     */
    'type': DocumentType;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentLayoutRequest
     */
    'documentHandler': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateDocumentLayoutRequest
     */
    'config'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentLayoutRequest
     */
    'fileNamePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentLayoutRequest
     */
    'fileNameSuffix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentLayoutRequest
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateFiegePurchaseOrderRequest
 */
export interface CreateFiegePurchaseOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateFiegePurchaseOrderRequest
     */
    'orderDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFiegePurchaseOrderRequest
     */
    'deliveryDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFiegePurchaseOrderRequest
     */
    'warehouseId': string;
    /**
     * 
     * @type {Array<CreateOrUpdateFiegePurchaseOrderPositionRequest>}
     * @memberof CreateFiegePurchaseOrderRequest
     */
    'positions': Array<CreateOrUpdateFiegePurchaseOrderPositionRequest>;
    /**
     * 
     * @type {string}
     * @memberof CreateFiegePurchaseOrderRequest
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateLoyaltyPointProcessingConfigRequest
 */
export interface CreateLoyaltyPointProcessingConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateLoyaltyPointProcessingConfigRequest
     */
    'handlerName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateLoyaltyPointProcessingConfigRequest
     */
    'handlerArguments'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateLoyaltyPointProcessingConfigRequest
     */
    'isEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateLoyaltyPointProcessingConfigRequest
     */
    'isInitialHandler': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateLoyaltyPointProcessingConfigRequest
     */
    'nextHandlerId'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateLoyaltyPointTransactionRequest
 */
export interface CreateLoyaltyPointTransactionRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateLoyaltyPointTransactionRequest
     */
    'points': number;
    /**
     * 
     * @type {LoyaltyPointTransactionType}
     * @memberof CreateLoyaltyPointTransactionRequest
     */
    'type': LoyaltyPointTransactionType;
    /**
     * 
     * @type {string}
     * @memberof CreateLoyaltyPointTransactionRequest
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLoyaltyPointTransactionRequest
     */
    'orderId'?: string | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateLoyaltyPointTransactionRequest
     */
    'description'?: TranslatedTypeRequestOfString | null;
}
/**
 * 
 * @export
 * @interface CreateLoyaltyRankRequest
 */
export interface CreateLoyaltyRankRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateLoyaltyRankRequest
     */
    'minimumPoints': number;
    /**
     * 
     * @type {number}
     * @memberof CreateLoyaltyRankRequest
     */
    'discountPercentage': number;
    /**
     * 
     * @type {string}
     * @memberof CreateLoyaltyRankRequest
     */
    'validFrom': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLoyaltyRankRequest
     */
    'validTo'?: string | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateLoyaltyRankRequest
     */
    'name': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateLoyaltyRankRequest
     */
    'description'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfGuid}
     * @memberof CreateLoyaltyRankRequest
     */
    'assetId'?: TranslatedTypeRequestOfGuid | null;
}
/**
 * 
 * @export
 * @interface CreateManufacturerAddressRequest
 */
export interface CreateManufacturerAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerAddressRequest
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerAddressRequest
     */
    'streetNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerAddressRequest
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerAddressRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerAddressRequest
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerAddressRequest
     */
    'additionalAddressLine1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerAddressRequest
     */
    'additionalAddressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerAddressRequest
     */
    'countryId': string;
}
/**
 * 
 * @export
 * @interface CreateManufacturerConditionRequest
 */
export interface CreateManufacturerConditionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerConditionRequest
     */
    'manufacturerId': string;
    /**
     * 
     * @type {ManufacturerConditionKey}
     * @memberof CreateManufacturerConditionRequest
     */
    'key': ManufacturerConditionKey;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerConditionRequest
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface CreateManufacturerContactRequest
 */
export interface CreateManufacturerContactRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerContactRequest
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerContactRequest
     */
    'streetNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerContactRequest
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerContactRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerContactRequest
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerContactRequest
     */
    'additionalAddressLine1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerContactRequest
     */
    'additionalAddressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerContactRequest
     */
    'countryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerContactRequest
     */
    'manufacturerId': string;
    /**
     * 
     * @type {Department}
     * @memberof CreateManufacturerContactRequest
     */
    'department': Department;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerContactRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerContactRequest
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerContactRequest
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerContactRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerContactRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerContactRequest
     */
    'phoneNumber': string;
}
/**
 * 
 * @export
 * @interface CreateManufacturerContactRequestAllOf
 */
export interface CreateManufacturerContactRequestAllOf {
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerContactRequestAllOf
     */
    'manufacturerId': string;
    /**
     * 
     * @type {Department}
     * @memberof CreateManufacturerContactRequestAllOf
     */
    'department': Department;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerContactRequestAllOf
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerContactRequestAllOf
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerContactRequestAllOf
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerContactRequestAllOf
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerContactRequestAllOf
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerContactRequestAllOf
     */
    'phoneNumber': string;
}
/**
 * 
 * @export
 * @interface CreateManufacturerRequest
 */
export interface CreateManufacturerRequest {
    /**
     * 
     * @type {boolean}
     * @memberof CreateManufacturerRequest
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerRequest
     */
    'customerNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerRequest
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerRequest
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerRequest
     */
    'vatId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerRequest
     */
    'registrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerRequest
     */
    'shortCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerRequest
     */
    'dataPrivacyUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerRequest
     */
    'termsAndConditionsUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateManufacturerRequest
     */
    'logoAssetId'?: string | null;
    /**
     * 
     * @type {Array<InlineCreateManufacturerConditionRequest>}
     * @memberof CreateManufacturerRequest
     */
    'manufacturerConditions'?: Array<InlineCreateManufacturerConditionRequest> | null;
    /**
     * 
     * @type {CreateManufacturerAddressRequest}
     * @memberof CreateManufacturerRequest
     */
    'companyAddress': CreateManufacturerAddressRequest;
    /**
     * 
     * @type {CreateUserRequest}
     * @memberof CreateManufacturerRequest
     */
    'user': CreateUserRequest;
}
/**
 * 
 * @export
 * @interface CreateOrUpdateFiegePurchaseOrderPositionRequest
 */
export interface CreateOrUpdateFiegePurchaseOrderPositionRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateFiegePurchaseOrderPositionRequest
     */
    'lineNumber': number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateFiegePurchaseOrderPositionRequest
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateFiegePurchaseOrderPositionRequest
     */
    'productId': string;
    /**
     * 
     * @type {FiegePositionUnit}
     * @memberof CreateOrUpdateFiegePurchaseOrderPositionRequest
     */
    'unit': FiegePositionUnit;
}
/**
 * 
 * @export
 * @interface CreateOrderInvoiceAddressRequest
 */
export interface CreateOrderInvoiceAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInvoiceAddressRequest
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInvoiceAddressRequest
     */
    'streetNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInvoiceAddressRequest
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInvoiceAddressRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInvoiceAddressRequest
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInvoiceAddressRequest
     */
    'additionalAddressLine1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInvoiceAddressRequest
     */
    'additionalAddressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInvoiceAddressRequest
     */
    'countryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInvoiceAddressRequest
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInvoiceAddressRequest
     */
    'department'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInvoiceAddressRequest
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInvoiceAddressRequest
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInvoiceAddressRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInvoiceAddressRequest
     */
    'lastName': string;
    /**
     * 
     * @type {any}
     * @memberof CreateOrderInvoiceAddressRequest
     */
    'shopCustomFields'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInvoiceAddressRequest
     */
    'orderId': string;
}
/**
 * 
 * @export
 * @interface CreateOrderInvoiceAddressRequestAllOf
 */
export interface CreateOrderInvoiceAddressRequestAllOf {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInvoiceAddressRequestAllOf
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInvoiceAddressRequestAllOf
     */
    'department'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInvoiceAddressRequestAllOf
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInvoiceAddressRequestAllOf
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInvoiceAddressRequestAllOf
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInvoiceAddressRequestAllOf
     */
    'lastName': string;
    /**
     * 
     * @type {any}
     * @memberof CreateOrderInvoiceAddressRequestAllOf
     */
    'shopCustomFields'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderInvoiceAddressRequestAllOf
     */
    'orderId': string;
}
/**
 * 
 * @export
 * @interface CreateOrderPaymentTransactionRequest
 */
export interface CreateOrderPaymentTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderPaymentTransactionRequest
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderPaymentTransactionRequest
     */
    'paymentProviderId': string;
}
/**
 * 
 * @export
 * @interface CreateOrderPositionRequest
 */
export interface CreateOrderPositionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderPositionRequest
     */
    'shopReferenceId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderPositionRequest
     */
    'parentOrderPositionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderPositionRequest
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderPositionRequest
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderPositionRequest
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderPositionRequest
     */
    'coverId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderPositionRequest
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderPositionRequest
     */
    'unitPrice': number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderPositionRequest
     */
    'totalPrice': number;
    /**
     * 
     * @type {OrderPositionType}
     * @memberof CreateOrderPositionRequest
     */
    'positionType': OrderPositionType;
    /**
     * 
     * @type {any}
     * @memberof CreateOrderPositionRequest
     */
    'payload': any;
    /**
     * 
     * @type {any}
     * @memberof CreateOrderPositionRequest
     */
    'price': any;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrderPositionRequest
     */
    'good': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderPositionRequest
     */
    'position': number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderPositionRequest
     */
    'orderReturnId'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof CreateOrderPositionRequest
     */
    'shopCustomFields'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderPositionRequest
     */
    'orderId': string;
}
/**
 * 
 * @export
 * @interface CreateOrderRequest
 */
export interface CreateOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderRequest
     */
    'shopReferenceId': string;
    /**
     * 
     * @type {CreateCustomerRequest}
     * @memberof CreateOrderRequest
     */
    'customer': CreateCustomerRequest;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderRequest
     */
    'orderNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderRequest
     */
    'currencyId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderRequest
     */
    'currencyFactor': number;
    /**
     * 
     * @type {InlineCreateOrderInvoiceAddressRequest}
     * @memberof CreateOrderRequest
     */
    'orderInvoiceAddress': InlineCreateOrderInvoiceAddressRequest;
    /**
     * 
     * @type {InlineCreateOrderShippingAddressRequest}
     * @memberof CreateOrderRequest
     */
    'orderShippingAddress': InlineCreateOrderShippingAddressRequest;
    /**
     * 
     * @type {any}
     * @memberof CreateOrderRequest
     */
    'price': any;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderRequest
     */
    'orderDateTime': string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderRequest
     */
    'amountTotal': number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderRequest
     */
    'amountNet': number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderRequest
     */
    'positionPrice': number;
    /**
     * 
     * @type {any}
     * @memberof CreateOrderRequest
     */
    'shippingCosts': any;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderRequest
     */
    'shippingTotal': number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderRequest
     */
    'deepLinkCode': string;
    /**
     * 
     * @type {any}
     * @memberof CreateOrderRequest
     */
    'shopCustomFields'?: any | null;
    /**
     * 
     * @type {Array<InlineCreateOrderPositionRequest>}
     * @memberof CreateOrderRequest
     */
    'orderPositions': Array<InlineCreateOrderPositionRequest>;
    /**
     * 
     * @type {Array<InlineCreateOrderStateHistoryRequest>}
     * @memberof CreateOrderRequest
     */
    'orderStateHistories'?: Array<InlineCreateOrderStateHistoryRequest> | null;
    /**
     * 
     * @type {Array<InlineCreateOrderPaymentTransactionRequest>}
     * @memberof CreateOrderRequest
     */
    'paymentTransactions'?: Array<InlineCreateOrderPaymentTransactionRequest> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderRequest
     */
    'manufacturerId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderRequest
     */
    'overallSplitsTotalPrice'?: number | null;
}
/**
 * 
 * @export
 * @interface CreateOrderReturnAnnouncementPositionRequest
 */
export interface CreateOrderReturnAnnouncementPositionRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateOrderReturnAnnouncementPositionRequest
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderReturnAnnouncementPositionRequest
     */
    'orderShippingDetailPositionId': string;
    /**
     * 
     * @type {Array<CreateOrderReturnReasonRequest>}
     * @memberof CreateOrderReturnAnnouncementPositionRequest
     */
    'orderReturnReasons'?: Array<CreateOrderReturnReasonRequest> | null;
}
/**
 * 
 * @export
 * @interface CreateOrderReturnAnnouncementRequest
 */
export interface CreateOrderReturnAnnouncementRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderReturnAnnouncementRequest
     */
    'orderShippingDetailId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderReturnAnnouncementRequest
     */
    'shippingProviderId': string;
    /**
     * 
     * @type {Array<CreateOrderReturnAnnouncementPositionRequest>}
     * @memberof CreateOrderReturnAnnouncementRequest
     */
    'orderReturnAnnouncementPositions'?: Array<CreateOrderReturnAnnouncementPositionRequest> | null;
}
/**
 * 
 * @export
 * @interface CreateOrderReturnPositionRequest
 */
export interface CreateOrderReturnPositionRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateOrderReturnPositionRequest
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderReturnPositionRequest
     */
    'orderPositionId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderReturnPositionRequest
     */
    'batchNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderReturnPositionRequest
     */
    'bestBefore'?: string | null;
    /**
     * 
     * @type {LogisticsReturnReason}
     * @memberof CreateOrderReturnPositionRequest
     */
    'logisticsReturnReason'?: LogisticsReturnReason | null;
    /**
     * 
     * @type {ProductStockQuality}
     * @memberof CreateOrderReturnPositionRequest
     */
    'quality'?: ProductStockQuality | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderReturnPositionRequest
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderReturnPositionRequest
     */
    'returnDate'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateOrderReturnReasonRequest
 */
export interface CreateOrderReturnReasonRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderReturnReasonRequest
     */
    'reasonText'?: string | null;
    /**
     * 
     * @type {ReturnReason}
     * @memberof CreateOrderReturnReasonRequest
     */
    'returnReason': ReturnReason;
}
/**
 * 
 * @export
 * @interface CreateOrderReturnRequest
 */
export interface CreateOrderReturnRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderReturnRequest
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderReturnRequest
     */
    'orderReturnAnnouncementId'?: string | null;
    /**
     * 
     * @type {Array<CreateOrderReturnPositionRequest>}
     * @memberof CreateOrderReturnRequest
     */
    'orderReturnPositions': Array<CreateOrderReturnPositionRequest>;
}
/**
 * 
 * @export
 * @interface CreateOrderShippingAddressRequest
 */
export interface CreateOrderShippingAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderShippingAddressRequest
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderShippingAddressRequest
     */
    'streetNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderShippingAddressRequest
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderShippingAddressRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderShippingAddressRequest
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderShippingAddressRequest
     */
    'additionalAddressLine1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderShippingAddressRequest
     */
    'additionalAddressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderShippingAddressRequest
     */
    'countryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderShippingAddressRequest
     */
    'shopVersionReferenceId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderShippingAddressRequest
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderShippingAddressRequest
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderShippingAddressRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderShippingAddressRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderShippingAddressRequest
     */
    'orderId': string;
}
/**
 * 
 * @export
 * @interface CreateOrderShippingAddressRequestAllOf
 */
export interface CreateOrderShippingAddressRequestAllOf {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderShippingAddressRequestAllOf
     */
    'shopVersionReferenceId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderShippingAddressRequestAllOf
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderShippingAddressRequestAllOf
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderShippingAddressRequestAllOf
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderShippingAddressRequestAllOf
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderShippingAddressRequestAllOf
     */
    'orderId': string;
}
/**
 * 
 * @export
 * @interface CreateOrderShippingDetailRequest
 */
export interface CreateOrderShippingDetailRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderShippingDetailRequest
     */
    'shippingProviderId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderShippingDetailRequest
     */
    'trackingCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderShippingDetailRequest
     */
    'weight'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderShippingDetailRequest
     */
    'transmissionDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderShippingDetailRequest
     */
    'shippingDateEarliest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderShippingDetailRequest
     */
    'shippingDateLatest'?: string | null;
    /**
     * 
     * @type {Array<UpsertOrderShippingDetailPositionRequest>}
     * @memberof CreateOrderShippingDetailRequest
     */
    'positions': Array<UpsertOrderShippingDetailPositionRequest>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrderShippingDetailRequest
     */
    'notifyCustomer'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateOrderStateHistoryRequest
 */
export interface CreateOrderStateHistoryRequest {
    /**
     * 
     * @type {OrderStateContext}
     * @memberof CreateOrderStateHistoryRequest
     */
    'orderStateContext': OrderStateContext;
    /**
     * 
     * @type {OrderStateTechnicalName}
     * @memberof CreateOrderStateHistoryRequest
     */
    'orderStateTechnicalName': OrderStateTechnicalName;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderStateHistoryRequest
     */
    'orderStateId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderStateHistoryRequest
     */
    'orderId': string;
}
/**
 * 
 * @export
 * @interface CreatePaymentProviderRequest
 */
export interface CreatePaymentProviderRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentProviderRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateProductAdditionalInformationRequest
 */
export interface CreateProductAdditionalInformationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateProductAdditionalInformationRequest
     */
    'informationGroupId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateProductAdditionalInformationRequest
     */
    'position': number;
    /**
     * 
     * @type {TranslatedTypeRequestOfStringOf}
     * @memberof CreateProductAdditionalInformationRequest
     */
    'values': TranslatedTypeRequestOfStringOf;
    /**
     * 
     * @type {InformationEntityType}
     * @memberof CreateProductAdditionalInformationRequest
     */
    'informationEntityType': InformationEntityType;
}
/**
 * 
 * @export
 * @interface CreateProductAnalyticConstituentRequest
 */
export interface CreateProductAnalyticConstituentRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateProductAnalyticConstituentRequest
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductAnalyticConstituentRequest
     */
    'position': number;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateProductAnalyticConstituentRequest
     */
    'name': TranslatedTypeRequestOfString;
}
/**
 * 
 * @export
 * @interface CreateProductAssetRequest
 */
export interface CreateProductAssetRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateProductAssetRequest
     */
    'position': number;
    /**
     * 
     * @type {TranslatedTypeRequestOfGuid}
     * @memberof CreateProductAssetRequest
     */
    'assetId': TranslatedTypeRequestOfGuid;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateProductAssetRequest
     */
    'description'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateProductAssetRequest
     */
    'alternative'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProductAssetRequest
     */
    'productId': string;
}
/**
 * 
 * @export
 * @interface CreateProductBundlePriceRequest
 */
export interface CreateProductBundlePriceRequest {
    /**
     * 
     * @type {TaxTypeIdentifier}
     * @memberof CreateProductBundlePriceRequest
     */
    'taxTypeIdentifier': TaxTypeIdentifier;
    /**
     * 
     * @type {number}
     * @memberof CreateProductBundlePriceRequest
     */
    'gross': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductBundlePriceRequest
     */
    'listPriceGross'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProductBundlePriceRequest
     */
    'currencyId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductBundlePriceRequest
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductBundlePriceRequest
     */
    'productId': string;
}
/**
 * 
 * @export
 * @interface CreateProductBundleRequest
 */
export interface CreateProductBundleRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateProductBundleRequest
     */
    'manufacturerId': string;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof CreateProductBundleRequest
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof CreateProductBundleRequest
     */
    'description': TranslatedTypeResponseOfString;
}
/**
 * 
 * @export
 * @interface CreateProductCategoryRequest
 */
export interface CreateProductCategoryRequest {
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateProductCategoryRequest
     */
    'name': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateProductCategoryRequest
     */
    'description'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProductCategoryRequest
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateProductCategoryRequest
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProductCategoryRequest
     */
    'identifier': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProductCategoryRequest
     */
    'productGroupIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProductCategoryRequest
     */
    'animalSpeciesIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface CreateProductGroupMappingRequest
 */
export interface CreateProductGroupMappingRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateProductGroupMappingRequest
     */
    'name': string;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateProductGroupMappingRequest
     */
    'description'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProductGroupMappingRequest
     */
    'isOptional': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProductGroupMappingRequest
     */
    'isUserInputAllowed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProductGroupMappingRequest
     */
    'hasPropertyOptionListing': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateProductGroupMappingRequest
     */
    'propertyGroupId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProductGroupMappingRequest
     */
    'propertyOptionIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProductGroupMappingRequest
     */
    'productGroupIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProductGroupMappingRequest
     */
    'propertyValidationRuleIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface CreateProductGroupRequest
 */
export interface CreateProductGroupRequest {
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateProductGroupRequest
     */
    'name': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateProductGroupRequest
     */
    'description'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProductGroupRequest
     */
    'identifier': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProductGroupRequest
     */
    'animalSpeciesIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProductGroupRequest
     */
    'propertyValidationRuleIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProductGroupRequest
     */
    'propertyGroupIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface CreateProductPriceRequest
 */
export interface CreateProductPriceRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateProductPriceRequest
     */
    'gross'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductPriceRequest
     */
    'listPriceGross'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateProductPriceRequest
     */
    'quantityStart'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateProductPriceRequest
     */
    'quantityEnd'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProductPriceRequest
     */
    'currencyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductPriceRequest
     */
    'countryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductPriceRequest
     */
    'productId'?: string;
}
/**
 * 
 * @export
 * @interface CreateProductRequest
 */
export interface CreateProductRequest {
    /**
     * 
     * @type {ProductState}
     * @memberof CreateProductRequest
     */
    'state': ProductState;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateProductRequest
     */
    'name': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateProductRequest
     */
    'description': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateProductRequest
     */
    'productLine'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateProductRequest
     */
    'marketingText'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateProductRequest
     */
    'seoProductTitle'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequest
     */
    'ean'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequest
     */
    'releaseDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProductRequest
     */
    'isBatchControlled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProductRequest
     */
    'isBestBeforeControlled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProductRequest
     */
    'isDangerousGoods'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequest
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequest
     */
    'mainVariantId'?: string | null;
    /**
     * 
     * @type {Array<InlineCreateVariantProductRequest>}
     * @memberof CreateProductRequest
     */
    'variants'?: Array<InlineCreateVariantProductRequest> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProductRequest
     */
    'propertyIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<InlineCreateProductStockRequest>}
     * @memberof CreateProductRequest
     */
    'productStocks'?: Array<InlineCreateProductStockRequest> | null;
    /**
     * 
     * @type {number}
     * @memberof CreateProductRequest
     */
    'restockTimeDays'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProductRequest
     */
    'isCloseout'?: boolean;
    /**
     * 
     * @type {Array<InlineCreateProductPriceRequest>}
     * @memberof CreateProductRequest
     */
    'productPrices'?: Array<InlineCreateProductPriceRequest> | null;
    /**
     * 
     * @type {TaxTypeIdentifier}
     * @memberof CreateProductRequest
     */
    'taxTypeIdentifier': TaxTypeIdentifier;
    /**
     * 
     * @type {number}
     * @memberof CreateProductRequest
     */
    'purchaseSteps'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateProductRequest
     */
    'minPurchase'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateProductRequest
     */
    'maxPurchase'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateProductRequest
     */
    'purchaseUnit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateProductRequest
     */
    'referenceUnit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateProductRequest
     */
    'weight'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateProductRequest
     */
    'width'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateProductRequest
     */
    'height'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateProductRequest
     */
    'length'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequest
     */
    'productUnitId'?: string | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateProductRequest
     */
    'packUnit'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateProductRequest
     */
    'packUnitPlural'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {Array<InlineCreateDeliveryTimeRequest>}
     * @memberof CreateProductRequest
     */
    'deliveryTimes'?: Array<InlineCreateDeliveryTimeRequest> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProductRequest
     */
    'isShippingFree'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequest
     */
    'manufacturerProductNumber'?: string | null;
    /**
     * 
     * @type {Array<InlineCreateProductAssetRequest>}
     * @memberof CreateProductRequest
     */
    'productAssets'?: Array<InlineCreateProductAssetRequest> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequest
     */
    'coverId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProductRequest
     */
    'tagIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProductRequest
     */
    'categoryIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<CreateProductAdditionalInformationRequest>}
     * @memberof CreateProductRequest
     */
    'additionalInformation'?: Array<CreateProductAdditionalInformationRequest> | null;
    /**
     * 
     * @type {Array<CreateProductAnalyticConstituentRequest>}
     * @memberof CreateProductRequest
     */
    'analyticConstituents'?: Array<CreateProductAnalyticConstituentRequest> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequest
     */
    'productGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequest
     */
    'brandId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProductRequest
     */
    'animalSpeciesIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateProductRequest
     */
    'mainCategoryId'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateProductStockRequest
 */
export interface CreateProductStockRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateProductStockRequest
     */
    'stock': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProductStockRequest
     */
    'available': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateProductStockRequest
     */
    'priority': number;
    /**
     * 
     * @type {string}
     * @memberof CreateProductStockRequest
     */
    'warehouseId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductStockRequest
     */
    'productId': string;
}
/**
 * 
 * @export
 * @interface CreateProductUnitRequest
 */
export interface CreateProductUnitRequest {
    /**
     * 
     * @type {boolean}
     * @memberof CreateProductUnitRequest
     */
    'isBasePriceUnit': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateProductUnitRequest
     */
    'formatPadding'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateProductUnitRequest
     */
    'formatPrecision'?: number | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateProductUnitRequest
     */
    'name': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateProductUnitRequest
     */
    'shortCode': TranslatedTypeRequestOfString;
}
/**
 * 
 * @export
 * @interface CreateProductsFromDataImportResponse
 */
export interface CreateProductsFromDataImportResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateProductsFromDataImportResponse
     */
    'createdProductsCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductsFromDataImportResponse
     */
    'createdVariantsCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductsFromDataImportResponse
     */
    'maliciousProductsCount'?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateProductsFromDataImportResponse
     */
    'productErrors'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface CreatePropertyGroupRequest
 */
export interface CreatePropertyGroupRequest {
    /**
     * 
     * @type {number}
     * @memberof CreatePropertyGroupRequest
     */
    'position': number;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreatePropertyGroupRequest
     */
    'name': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreatePropertyGroupRequest
     */
    'description'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePropertyGroupRequest
     */
    'identifier': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePropertyGroupRequest
     */
    'isLanguageNeutral': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreatePropertyGroupRequest
     */
    'animalSpeciesIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePropertyGroupRequest
     */
    'productUnitId'?: string | null;
}
/**
 * 
 * @export
 * @interface CreatePropertyOptionRequest
 */
export interface CreatePropertyOptionRequest {
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreatePropertyOptionRequest
     */
    'name': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreatePropertyOptionRequest
     */
    'description'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {number}
     * @memberof CreatePropertyOptionRequest
     */
    'position': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePropertyOptionRequest
     */
    'isVariantOption': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreatePropertyOptionRequest
     */
    'assetId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePropertyOptionRequest
     */
    'colorHexCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePropertyOptionRequest
     */
    'propertyGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePropertyOptionRequest
     */
    'identifier': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePropertyOptionRequest
     */
    'isLocked': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreatePropertyOptionRequest
     */
    'animalSpeciesIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface CreatePropertyValidationRuleRequest
 */
export interface CreatePropertyValidationRuleRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePropertyValidationRuleRequest
     */
    'name': string;
    /**
     * 
     * @type {PropertyValidationRuleCallable}
     * @memberof CreatePropertyValidationRuleRequest
     */
    'callable'?: PropertyValidationRuleCallable | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreatePropertyValidationRuleRequest
     */
    'arguments'?: Array<string> | null;
    /**
     * 
     * @type {Array<ValidationConstraint>}
     * @memberof CreatePropertyValidationRuleRequest
     */
    'constraints'?: Array<ValidationConstraint> | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePropertyValidationRuleRequest
     */
    'objectPath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePropertyValidationRuleRequest
     */
    'identifier'?: string | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreatePropertyValidationRuleRequest
     */
    'message'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreatePropertyValidationRuleRequest
     */
    'description'?: TranslatedTypeRequestOfString | null;
}
/**
 * 
 * @export
 * @interface CreateShippingProviderRequest
 */
export interface CreateShippingProviderRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateShippingProviderRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateTagRequest
 */
export interface CreateTagRequest {
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateTagRequest
     */
    'name': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {string}
     * @memberof CreateTagRequest
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateUserInputPropertyOptionRequest
 */
export interface CreateUserInputPropertyOptionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserInputPropertyOptionRequest
     */
    'propertyGroupId': string;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof CreateUserInputPropertyOptionRequest
     */
    'propertyValue': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserInputPropertyOptionRequest
     */
    'animalSpeciesIds'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserInputPropertyOptionRequest
     */
    'isVariantOption'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateUserRoleMappingRequest
 */
export interface CreateUserRoleMappingRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRoleMappingRequest
     */
    'userRoleId': string;
}
/**
 * 
 * @export
 * @interface CreateUserRoleRequest
 */
export interface CreateUserRoleRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRoleRequest
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserRoleRequest
     */
    'policies': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRoleRequest
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRoleRequest
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateVariantProductRequest
 */
export interface CreateVariantProductRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateVariantProductRequest
     */
    'variantOptionIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateVariantProductRequest
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVariantProductRequest
     */
    'ean': string;
}
/**
 * 
 * @export
 * @interface CreateVariantProductsRequest
 */
export interface CreateVariantProductsRequest {
    /**
     * 
     * @type {Array<InlineCreateVariantProductRequest>}
     * @memberof CreateVariantProductsRequest
     */
    'variants': Array<InlineCreateVariantProductRequest>;
}
/**
 * 
 * @export
 * @interface CreateWarehouseRequest
 */
export interface CreateWarehouseRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateWarehouseRequest
     */
    'name': string;
    /**
     * 
     * @type {WarehouseType}
     * @memberof CreateWarehouseRequest
     */
    'type': WarehouseType;
    /**
     * 
     * @type {string}
     * @memberof CreateWarehouseRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateWarehouseRequest
     */
    'encryptedProperties'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum CredentialContext {
    PartnerDataExchange = 'PartnerDataExchange',
    PartnerProductStockApi = 'PartnerProductStockApi',
    PartnerOrdersExportApi = 'PartnerOrdersExportApi',
    PartnerOrderInvoiceDataExportApi = 'PartnerOrderInvoiceDataExportApi',
    IntegrationApi = 'IntegrationApi'
}

/**
 * 
 * @export
 * @interface CredentialResponse
 */
export interface CredentialResponse {
    /**
     * 
     * @type {string}
     * @memberof CredentialResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CredentialResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CredentialResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CredentialResponse
     */
    'data': string;
    /**
     * 
     * @type {CredentialContext}
     * @memberof CredentialResponse
     */
    'credentialContext': CredentialContext;
    /**
     * 
     * @type {CredentialType}
     * @memberof CredentialResponse
     */
    'credentialType': CredentialType;
    /**
     * 
     * @type {EncryptionMethod}
     * @memberof CredentialResponse
     */
    'encryptionMethod': EncryptionMethod;
    /**
     * 
     * @type {string}
     * @memberof CredentialResponse
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface CredentialResponseAllOf
 */
export interface CredentialResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof CredentialResponseAllOf
     */
    'data': string;
    /**
     * 
     * @type {CredentialContext}
     * @memberof CredentialResponseAllOf
     */
    'credentialContext': CredentialContext;
    /**
     * 
     * @type {CredentialType}
     * @memberof CredentialResponseAllOf
     */
    'credentialType': CredentialType;
    /**
     * 
     * @type {EncryptionMethod}
     * @memberof CredentialResponseAllOf
     */
    'encryptionMethod': EncryptionMethod;
    /**
     * 
     * @type {string}
     * @memberof CredentialResponseAllOf
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum CredentialType {
    SftpLogin = 'SftpLogin',
    HttpBasicAuth = 'HttpBasicAuth',
    HttpDigestAuth = 'HttpDigestAuth',
    ShopifyAccessToken = 'ShopifyAccessToken',
    ShopwareOAuth = 'ShopwareOAuth',
    InpettoClientHmacKeyPair = 'InpettoClientHmacKeyPair'
}

/**
 * 
 * @export
 * @interface CurrencyResponse
 */
export interface CurrencyResponse {
    /**
     * 
     * @type {string}
     * @memberof CurrencyResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CurrencyResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CurrencyResponse
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof CurrencyResponse
     */
    'factor': number;
    /**
     * 
     * @type {string}
     * @memberof CurrencyResponse
     */
    'symbol': string;
    /**
     * 
     * @type {number}
     * @memberof CurrencyResponse
     */
    'position': number;
    /**
     * 
     * @type {string}
     * @memberof CurrencyResponse
     */
    'countryId': string;
    /**
     * 
     * @type {CountryResponse}
     * @memberof CurrencyResponse
     */
    'country': CountryResponse;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof CurrencyResponse
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof CurrencyResponse
     */
    'shortName': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {string}
     * @memberof CurrencyResponse
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CurrencyResponse
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface CurrencyResponseAllOf
 */
export interface CurrencyResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof CurrencyResponseAllOf
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof CurrencyResponseAllOf
     */
    'factor': number;
    /**
     * 
     * @type {string}
     * @memberof CurrencyResponseAllOf
     */
    'symbol': string;
    /**
     * 
     * @type {number}
     * @memberof CurrencyResponseAllOf
     */
    'position': number;
    /**
     * 
     * @type {string}
     * @memberof CurrencyResponseAllOf
     */
    'countryId': string;
    /**
     * 
     * @type {CountryResponse}
     * @memberof CurrencyResponseAllOf
     */
    'country': CountryResponse;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof CurrencyResponseAllOf
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof CurrencyResponseAllOf
     */
    'shortName': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {string}
     * @memberof CurrencyResponseAllOf
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CurrencyResponseAllOf
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface CursorPaginatedResponse
 */
export interface CursorPaginatedResponse {
    /**
     * 
     * @type {CursorPaginator}
     * @memberof CursorPaginatedResponse
     */
    'cursor': CursorPaginator;
}
/**
 * 
 * @export
 * @interface CursorPaginator
 */
export interface CursorPaginator {
    /**
     * 
     * @type {string}
     * @memberof CursorPaginator
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CursorPaginator
     */
    'previous'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomerAttributes
 */
export interface CustomerAttributes {
    /**
     * 
     * @type {boolean}
     * @memberof CustomerAttributes
     */
    'LoyaltyOptIn'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerAttributes
     */
    'LoyaltyOptInDateTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerAttributes
     */
    'DtcCustomer'?: boolean | null;
}
/**
 * 
 * @export
 * @interface CustomerResponse
 */
export interface CustomerResponse {
    /**
     * 
     * @type {string}
     * @memberof CustomerResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerResponse
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerResponse
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerResponse
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerResponse
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerResponse
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerResponse
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerResponse
     */
    'customerNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerResponse
     */
    'languageId': string;
    /**
     * 
     * @type {LanguageResponse}
     * @memberof CustomerResponse
     */
    'language'?: LanguageResponse | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerResponse
     */
    'isGuest': boolean;
    /**
     * 
     * @type {CustomerAttributes}
     * @memberof CustomerResponse
     */
    'attributes'?: CustomerAttributes | null;
    /**
     * 
     * @type {any}
     * @memberof CustomerResponse
     */
    'shopCustomFields'?: any | null;
    /**
     * 
     * @type {Array<LoyaltyRankCustomerHistoryResponse>}
     * @memberof CustomerResponse
     */
    'loyaltyRankCustomerHistories'?: Array<LoyaltyRankCustomerHistoryResponse> | null;
    /**
     * 
     * @type {LoyaltyRankCustomerHistoryResponse}
     * @memberof CustomerResponse
     */
    'latestActiveRank'?: LoyaltyRankCustomerHistoryResponse | null;
}
/**
 * 
 * @export
 * @interface CustomerResponseAllOf
 */
export interface CustomerResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof CustomerResponseAllOf
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerResponseAllOf
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerResponseAllOf
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerResponseAllOf
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerResponseAllOf
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerResponseAllOf
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerResponseAllOf
     */
    'customerNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerResponseAllOf
     */
    'languageId': string;
    /**
     * 
     * @type {LanguageResponse}
     * @memberof CustomerResponseAllOf
     */
    'language'?: LanguageResponse | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerResponseAllOf
     */
    'isGuest': boolean;
    /**
     * 
     * @type {CustomerAttributes}
     * @memberof CustomerResponseAllOf
     */
    'attributes'?: CustomerAttributes | null;
    /**
     * 
     * @type {any}
     * @memberof CustomerResponseAllOf
     */
    'shopCustomFields'?: any | null;
    /**
     * 
     * @type {Array<LoyaltyRankCustomerHistoryResponse>}
     * @memberof CustomerResponseAllOf
     */
    'loyaltyRankCustomerHistories'?: Array<LoyaltyRankCustomerHistoryResponse> | null;
    /**
     * 
     * @type {LoyaltyRankCustomerHistoryResponse}
     * @memberof CustomerResponseAllOf
     */
    'latestActiveRank'?: LoyaltyRankCustomerHistoryResponse | null;
}
/**
 * 
 * @export
 * @interface DailyBestSellingProductsResponse
 */
export interface DailyBestSellingProductsResponse {
    /**
     * 
     * @type {string}
     * @memberof DailyBestSellingProductsResponse
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DailyBestSellingProductsResponse
     */
    'date': string;
    /**
     * 
     * @type {Array<SoldProductResponse>}
     * @memberof DailyBestSellingProductsResponse
     */
    'products': Array<SoldProductResponse>;
}
/**
 * 
 * @export
 * @interface DailyRefundsResponse
 */
export interface DailyRefundsResponse {
    /**
     * 
     * @type {string}
     * @memberof DailyRefundsResponse
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DailyRefundsResponse
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof DailyRefundsResponse
     */
    'totalRefundAmount': number;
    /**
     * 
     * @type {number}
     * @memberof DailyRefundsResponse
     */
    'totalNumberOfRefunds': number;
}
/**
 * 
 * @export
 * @interface DailyRevenueResponse
 */
export interface DailyRevenueResponse {
    /**
     * 
     * @type {string}
     * @memberof DailyRevenueResponse
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DailyRevenueResponse
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof DailyRevenueResponse
     */
    'totalRevenueNet': number;
    /**
     * 
     * @type {number}
     * @memberof DailyRevenueResponse
     */
    'totalRevenue': number;
    /**
     * 
     * @type {number}
     * @memberof DailyRevenueResponse
     */
    'totalSuccessOrders': number;
    /**
     * 
     * @type {number}
     * @memberof DailyRevenueResponse
     */
    'totalFailedOrders': number;
}
/**
 * 
 * @export
 * @interface DataImportConfigResponse
 */
export interface DataImportConfigResponse {
    /**
     * 
     * @type {string}
     * @memberof DataImportConfigResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DataImportConfigResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DataImportConfigResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {DataImportTargetEntity}
     * @memberof DataImportConfigResponse
     */
    'dataImportTargetEntity'?: DataImportTargetEntity | null;
    /**
     * 
     * @type {string}
     * @memberof DataImportConfigResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof DataImportConfigResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DataImportConfigResponse
     */
    'apiVersion': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof DataImportConfigResponse
     */
    'config': Array<any>;
    /**
     * 
     * @type {string}
     * @memberof DataImportConfigResponse
     */
    'handler': string;
}
/**
 * 
 * @export
 * @interface DataImportConfigResponseAllOf
 */
export interface DataImportConfigResponseAllOf {
    /**
     * 
     * @type {DataImportTargetEntity}
     * @memberof DataImportConfigResponseAllOf
     */
    'dataImportTargetEntity'?: DataImportTargetEntity | null;
    /**
     * 
     * @type {string}
     * @memberof DataImportConfigResponseAllOf
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof DataImportConfigResponseAllOf
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DataImportConfigResponseAllOf
     */
    'apiVersion': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof DataImportConfigResponseAllOf
     */
    'config': Array<any>;
    /**
     * 
     * @type {string}
     * @memberof DataImportConfigResponseAllOf
     */
    'handler': string;
}
/**
 * 
 * @export
 * @interface DataImportResponse
 */
export interface DataImportResponse {
    /**
     * 
     * @type {string}
     * @memberof DataImportResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DataImportResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DataImportResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {ImportState}
     * @memberof DataImportResponse
     */
    'importState': ImportState;
    /**
     * 
     * @type {string}
     * @memberof DataImportResponse
     */
    'importFileId'?: string | null;
    /**
     * 
     * @type {AssetResponse}
     * @memberof DataImportResponse
     */
    'importFile'?: AssetResponse | null;
    /**
     * 
     * @type {ImportFileType}
     * @memberof DataImportResponse
     */
    'importFileType': ImportFileType;
    /**
     * 
     * @type {string}
     * @memberof DataImportResponse
     */
    'delimiter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DataImportResponse
     */
    'qualifier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DataImportResponse
     */
    'itemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DataImportResponse
     */
    'processedItems': number;
    /**
     * 
     * @type {number}
     * @memberof DataImportResponse
     */
    'unprocessableItems': number;
    /**
     * 
     * @type {number}
     * @memberof DataImportResponse
     */
    'validItems': number;
    /**
     * 
     * @type {number}
     * @memberof DataImportResponse
     */
    'invalidItems': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataImportResponse
     */
    'errorMessages'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DataImportResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof DataImportResponse
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DataImportResponse
     */
    'dataImportConfigId': string;
    /**
     * 
     * @type {DataImportConfigResponse}
     * @memberof DataImportResponse
     */
    'dataImportConfig'?: DataImportConfigResponse | null;
    /**
     * 
     * @type {string}
     * @memberof DataImportResponse
     */
    'lastlyUpdatedByUserId'?: string | null;
}
/**
 * 
 * @export
 * @interface DataImportResponseAllOf
 */
export interface DataImportResponseAllOf {
    /**
     * 
     * @type {ImportState}
     * @memberof DataImportResponseAllOf
     */
    'importState': ImportState;
    /**
     * 
     * @type {string}
     * @memberof DataImportResponseAllOf
     */
    'importFileId'?: string | null;
    /**
     * 
     * @type {AssetResponse}
     * @memberof DataImportResponseAllOf
     */
    'importFile'?: AssetResponse | null;
    /**
     * 
     * @type {ImportFileType}
     * @memberof DataImportResponseAllOf
     */
    'importFileType': ImportFileType;
    /**
     * 
     * @type {string}
     * @memberof DataImportResponseAllOf
     */
    'delimiter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DataImportResponseAllOf
     */
    'qualifier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DataImportResponseAllOf
     */
    'itemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DataImportResponseAllOf
     */
    'processedItems': number;
    /**
     * 
     * @type {number}
     * @memberof DataImportResponseAllOf
     */
    'unprocessableItems': number;
    /**
     * 
     * @type {number}
     * @memberof DataImportResponseAllOf
     */
    'validItems': number;
    /**
     * 
     * @type {number}
     * @memberof DataImportResponseAllOf
     */
    'invalidItems': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataImportResponseAllOf
     */
    'errorMessages'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DataImportResponseAllOf
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof DataImportResponseAllOf
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DataImportResponseAllOf
     */
    'dataImportConfigId': string;
    /**
     * 
     * @type {DataImportConfigResponse}
     * @memberof DataImportResponseAllOf
     */
    'dataImportConfig'?: DataImportConfigResponse | null;
    /**
     * 
     * @type {string}
     * @memberof DataImportResponseAllOf
     */
    'lastlyUpdatedByUserId'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum DataImportTargetEntity {
    Products = 'Products',
    Stocks = 'Stocks',
    Prices = 'Prices'
}

/**
 * 
 * @export
 * @interface DataSynchronizationRequest
 */
export interface DataSynchronizationRequest {
    /**
     * 
     * @type {boolean}
     * @memberof DataSynchronizationRequest
     */
    'skipExistingEntities'?: boolean | null;
}
/**
 * 
 * @export
 * @interface DeliveryTimeResponse
 */
export interface DeliveryTimeResponse {
    /**
     * 
     * @type {string}
     * @memberof DeliveryTimeResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryTimeResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryTimeResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeliveryTimeResponse
     */
    'min': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryTimeResponse
     */
    'max': number;
    /**
     * 
     * @type {DeliveryTimeUnit}
     * @memberof DeliveryTimeResponse
     */
    'unit': DeliveryTimeUnit;
    /**
     * 
     * @type {DeliveryTimeType}
     * @memberof DeliveryTimeResponse
     */
    'type': DeliveryTimeType;
    /**
     * 
     * @type {string}
     * @memberof DeliveryTimeResponse
     */
    'productId': string;
}
/**
 * 
 * @export
 * @interface DeliveryTimeResponseAllOf
 */
export interface DeliveryTimeResponseAllOf {
    /**
     * 
     * @type {number}
     * @memberof DeliveryTimeResponseAllOf
     */
    'min': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryTimeResponseAllOf
     */
    'max': number;
    /**
     * 
     * @type {DeliveryTimeUnit}
     * @memberof DeliveryTimeResponseAllOf
     */
    'unit': DeliveryTimeUnit;
    /**
     * 
     * @type {DeliveryTimeType}
     * @memberof DeliveryTimeResponseAllOf
     */
    'type': DeliveryTimeType;
    /**
     * 
     * @type {string}
     * @memberof DeliveryTimeResponseAllOf
     */
    'productId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum DeliveryTimeType {
    Standard = 'Standard',
    Express = 'Express',
    SelfPickup = 'SelfPickup'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum DeliveryTimeUnit {
    Hours = 'Hours',
    Days = 'Days',
    Weeks = 'Weeks',
    Months = 'Months',
    Years = 'Years'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum Department {
    ManagingDirector = 'ManagingDirector',
    Accounting = 'Accounting',
    Sales = 'Sales',
    ContentDirector = 'ContentDirector',
    CustomerSupport = 'CustomerSupport',
    ProductPreparation = 'ProductPreparation',
    ProductManagement = 'ProductManagement',
    ProductDamageSettlement = 'ProductDamageSettlement',
    ProductManufacturer = 'ProductManufacturer'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum DiscountType {
    Absolute = 'Absolute',
    Percentage = 'Percentage'
}

/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {TypeEnum24}
     * @memberof Document
     */
    'type'?: TypeEnum24;
    /**
     * 
     * @type {Attachment}
     * @memberof Document
     */
    'attachment'?: Attachment | null;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof Document
     */
    'attachments'?: Array<Attachment> | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     * @deprecated
     */
    'expiryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     * @deprecated
     */
    'issuerCountry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     * @deprecated
     */
    'issuerState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'modificationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'number'?: string | null;
    /**
     * 
     * @type {OwnerEntity}
     * @memberof Document
     */
    'owner'?: OwnerEntity | null;
}
/**
 * 
 * @export
 * @interface DocumentDataResponse
 */
export interface DocumentDataResponse {
    /**
     * 
     * @type {number}
     * @memberof DocumentDataResponse
     */
    'number'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentDataResponse
     */
    'finalDocumentNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentDataResponse
     */
    'static': boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentDataResponse
     */
    'orderId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentDataResponse
     */
    'adyenPaymentRefundId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentDataResponse
     */
    'assetId'?: string | null;
    /**
     * 
     * @type {AssetResponse}
     * @memberof DocumentDataResponse
     */
    'asset'?: AssetResponse | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentDataResponse
     */
    'documentLayoutId'?: string | null;
}
/**
 * 
 * @export
 * @interface DocumentLayoutResponse
 */
export interface DocumentLayoutResponse {
    /**
     * 
     * @type {string}
     * @memberof DocumentLayoutResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentLayoutResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentLayoutResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {DocumentState}
     * @memberof DocumentLayoutResponse
     */
    'state': DocumentState;
    /**
     * 
     * @type {DocumentType}
     * @memberof DocumentLayoutResponse
     */
    'type': DocumentType;
    /**
     * 
     * @type {string}
     * @memberof DocumentLayoutResponse
     */
    'documentHandler': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DocumentLayoutResponse
     */
    'config'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentLayoutResponse
     */
    'fileNamePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentLayoutResponse
     */
    'fileNameSuffix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentLayoutResponse
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface DocumentLayoutResponseAllOf
 */
export interface DocumentLayoutResponseAllOf {
    /**
     * 
     * @type {DocumentState}
     * @memberof DocumentLayoutResponseAllOf
     */
    'state': DocumentState;
    /**
     * 
     * @type {DocumentType}
     * @memberof DocumentLayoutResponseAllOf
     */
    'type': DocumentType;
    /**
     * 
     * @type {string}
     * @memberof DocumentLayoutResponseAllOf
     */
    'documentHandler': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DocumentLayoutResponseAllOf
     */
    'config'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentLayoutResponseAllOf
     */
    'fileNamePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentLayoutResponseAllOf
     */
    'fileNameSuffix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentLayoutResponseAllOf
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface DocumentPage
 */
export interface DocumentPage {
    /**
     * 
     * @type {TypeEnum4}
     * @memberof DocumentPage
     */
    'type'?: TypeEnum4 | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentPage
     */
    'pageName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentPage
     */
    'pageNumber'?: number | null;
}
/**
 * 
 * @export
 * @interface DocumentReference
 */
export interface DocumentReference {
    /**
     * 
     * @type {boolean}
     * @memberof DocumentReference
     */
    'active'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentReference
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentReference
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentReference
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentReference
     */
    'modificationDate'?: string;
    /**
     * 
     * @type {Array<DocumentPage>}
     * @memberof DocumentReference
     */
    'pages'?: Array<DocumentPage> | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentReference
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum DocumentState {
    Active = 'Active',
    Draft = 'Draft',
    Discarded = 'Discarded'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum DocumentType {
    OrderInvoice = 'OrderInvoice',
    OrderCancellationInvoice = 'OrderCancellationInvoice',
    OrderCreditNote = 'OrderCreditNote',
    ManufacturerMonthlyInvoice = 'ManufacturerMonthlyInvoice'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum EncryptionMethod {
    Aes = 'Aes'
}

/**
 * 
 * @export
 * @interface EntityReference
 */
export interface EntityReference {
    /**
     * 
     * @type {string}
     * @memberof EntityReference
     */
    'id'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ExchangeDirection {
    In = 'In',
    Out = 'Out'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum FiegePositionUnit {
    Piece = 'Piece'
}

/**
 * 
 * @export
 * @interface FiegePurchaseOrderPositionResponse
 */
export interface FiegePurchaseOrderPositionResponse {
    /**
     * 
     * @type {string}
     * @memberof FiegePurchaseOrderPositionResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FiegePurchaseOrderPositionResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FiegePurchaseOrderPositionResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FiegePurchaseOrderPositionResponse
     */
    'lineNumber': number;
    /**
     * 
     * @type {number}
     * @memberof FiegePurchaseOrderPositionResponse
     */
    'quantity': number;
    /**
     * 
     * @type {ProductStockQuality}
     * @memberof FiegePurchaseOrderPositionResponse
     */
    'quality'?: ProductStockQuality | null;
    /**
     * 
     * @type {FiegePositionUnit}
     * @memberof FiegePurchaseOrderPositionResponse
     */
    'unit': FiegePositionUnit;
    /**
     * 
     * @type {string}
     * @memberof FiegePurchaseOrderPositionResponse
     */
    'productId': string;
    /**
     * 
     * @type {SimpleProductResponse}
     * @memberof FiegePurchaseOrderPositionResponse
     */
    'product'?: SimpleProductResponse | null;
    /**
     * 
     * @type {boolean}
     * @memberof FiegePurchaseOrderPositionResponse
     */
    'isReported'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FiegePurchaseOrderPositionResponse
     */
    'fiegeInboundDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FiegePurchaseOrderPositionResponse
     */
    'fiegeInboundId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FiegePurchaseOrderPositionResponse
     */
    'bestBefore'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FiegePurchaseOrderPositionResponse
     */
    'batchNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface FiegePurchaseOrderPositionResponseAllOf
 */
export interface FiegePurchaseOrderPositionResponseAllOf {
    /**
     * 
     * @type {number}
     * @memberof FiegePurchaseOrderPositionResponseAllOf
     */
    'lineNumber': number;
    /**
     * 
     * @type {number}
     * @memberof FiegePurchaseOrderPositionResponseAllOf
     */
    'quantity': number;
    /**
     * 
     * @type {ProductStockQuality}
     * @memberof FiegePurchaseOrderPositionResponseAllOf
     */
    'quality'?: ProductStockQuality | null;
    /**
     * 
     * @type {FiegePositionUnit}
     * @memberof FiegePurchaseOrderPositionResponseAllOf
     */
    'unit': FiegePositionUnit;
    /**
     * 
     * @type {string}
     * @memberof FiegePurchaseOrderPositionResponseAllOf
     */
    'productId': string;
    /**
     * 
     * @type {SimpleProductResponse}
     * @memberof FiegePurchaseOrderPositionResponseAllOf
     */
    'product'?: SimpleProductResponse | null;
    /**
     * 
     * @type {boolean}
     * @memberof FiegePurchaseOrderPositionResponseAllOf
     */
    'isReported'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FiegePurchaseOrderPositionResponseAllOf
     */
    'fiegeInboundDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FiegePurchaseOrderPositionResponseAllOf
     */
    'fiegeInboundId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FiegePurchaseOrderPositionResponseAllOf
     */
    'bestBefore'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FiegePurchaseOrderPositionResponseAllOf
     */
    'batchNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface FiegePurchaseOrderResponse
 */
export interface FiegePurchaseOrderResponse {
    /**
     * 
     * @type {string}
     * @memberof FiegePurchaseOrderResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FiegePurchaseOrderResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FiegePurchaseOrderResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FiegePurchaseOrderResponse
     */
    'orderNumber': number;
    /**
     * 
     * @type {string}
     * @memberof FiegePurchaseOrderResponse
     */
    'orderDate': string;
    /**
     * 
     * @type {string}
     * @memberof FiegePurchaseOrderResponse
     */
    'deliveryDate': string;
    /**
     * 
     * @type {PurchaseOrderState}
     * @memberof FiegePurchaseOrderResponse
     */
    'state'?: PurchaseOrderState;
    /**
     * 
     * @type {Array<FiegePurchaseOrderPositionResponse>}
     * @memberof FiegePurchaseOrderResponse
     */
    'positions': Array<FiegePurchaseOrderPositionResponse>;
    /**
     * 
     * @type {string}
     * @memberof FiegePurchaseOrderResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof FiegePurchaseOrderResponse
     */
    'warehouseId': string;
}
/**
 * 
 * @export
 * @interface FiegePurchaseOrderResponseAllOf
 */
export interface FiegePurchaseOrderResponseAllOf {
    /**
     * 
     * @type {number}
     * @memberof FiegePurchaseOrderResponseAllOf
     */
    'orderNumber': number;
    /**
     * 
     * @type {string}
     * @memberof FiegePurchaseOrderResponseAllOf
     */
    'orderDate': string;
    /**
     * 
     * @type {string}
     * @memberof FiegePurchaseOrderResponseAllOf
     */
    'deliveryDate': string;
    /**
     * 
     * @type {PurchaseOrderState}
     * @memberof FiegePurchaseOrderResponseAllOf
     */
    'state'?: PurchaseOrderState;
    /**
     * 
     * @type {Array<FiegePurchaseOrderPositionResponse>}
     * @memberof FiegePurchaseOrderResponseAllOf
     */
    'positions': Array<FiegePurchaseOrderPositionResponse>;
    /**
     * 
     * @type {string}
     * @memberof FiegePurchaseOrderResponseAllOf
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof FiegePurchaseOrderResponseAllOf
     */
    'warehouseId': string;
}
/**
 * 
 * @export
 * @interface FooterColumnWidth
 */
export interface FooterColumnWidth {
    /**
     * 
     * @type {number}
     * @memberof FooterColumnWidth
     */
    'columnIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof FooterColumnWidth
     */
    'width'?: number;
}
/**
 * 
 * @export
 * @interface FooterContent
 */
export interface FooterContent {
    /**
     * 
     * @type {number}
     * @memberof FooterContent
     */
    'columnIndex'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof FooterContent
     */
    'content'?: Array<string> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum FundingSourceEnum {
    Credit = 'credit',
    Debit = 'debit',
    Prepaid = 'prepaid'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum FundingSourceEnum2 {
    Credit = 'credit',
    Debit = 'debit',
    Prepaid = 'prepaid'
}

/**
 * 
 * @export
 * @interface GetPaymentTransactionsRequest
 */
export interface GetPaymentTransactionsRequest {
    /**
     * 
     * @type {string}
     * @memberof GetPaymentTransactionsRequest
     */
    'createdSince'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentTransactionsRequest
     */
    'createdUntil'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentTransactionsRequest
     */
    'balanceAccountId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentTransactionsRequest
     */
    'accountHolderId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPaymentTransactionsRequest
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetPaymentTransactionsRequest
     */
    'limit'?: number | null;
}
/**
 * 
 * @export
 * @interface GetProductsListByIdentifiersRequest
 */
export interface GetProductsListByIdentifiersRequest {
    /**
     * 
     * @type {ProductIdentifierType}
     * @memberof GetProductsListByIdentifiersRequest
     */
    'productIdentifierType': ProductIdentifierType;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProductsListByIdentifiersRequest
     */
    'values': Array<string>;
}
/**
 * 
 * @export
 * @interface IbanAccountIdentification
 */
export interface IbanAccountIdentification {
    /**
     * 
     * @type {TypeEnum2}
     * @memberof IbanAccountIdentification
     */
    'type'?: TypeEnum2;
    /**
     * 
     * @type {string}
     * @memberof IbanAccountIdentification
     */
    'iban'?: string | null;
}
/**
 * 
 * @export
 * @interface IdentificationData
 */
export interface IdentificationData {
    /**
     * 
     * @type {TypeEnum18}
     * @memberof IdentificationData
     */
    'type'?: TypeEnum18;
    /**
     * 
     * @type {string}
     * @memberof IdentificationData
     */
    'cardNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentificationData
     */
    'expiryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentificationData
     * @deprecated
     */
    'issuerCountry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentificationData
     */
    'issuerState'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IdentificationData
     */
    'nationalIdExempt'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IdentificationData
     */
    'number'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ImportFileType {
    Json = 'Json',
    Csv = 'Csv',
    Xml = 'Xml'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum ImportState {
    Failed = 'Failed',
    Pending = 'Pending',
    InProcess = 'InProcess',
    NeedsValidation = 'NeedsValidation',
    InValidation = 'InValidation',
    Validated = 'Validated',
    Completed = 'Completed'
}

/**
 * 
 * @export
 * @interface Individual
 */
export interface Individual {
    /**
     * 
     * @type {BirthData}
     * @memberof Individual
     */
    'birthData'?: BirthData | null;
    /**
     * 
     * @type {string}
     * @memberof Individual
     */
    'email'?: string | null;
    /**
     * 
     * @type {IdentificationData}
     * @memberof Individual
     */
    'identificationData'?: IdentificationData | null;
    /**
     * 
     * @type {Name}
     * @memberof Individual
     */
    'name'?: Name | null;
    /**
     * 
     * @type {string}
     * @memberof Individual
     */
    'nationality'?: string | null;
    /**
     * 
     * @type {PhoneNumber}
     * @memberof Individual
     */
    'phone'?: PhoneNumber | null;
    /**
     * 
     * @type {Address2}
     * @memberof Individual
     */
    'residentialAddress'?: Address2 | null;
    /**
     * 
     * @type {Array<TaxInformation>}
     * @memberof Individual
     */
    'taxInformation'?: Array<TaxInformation> | null;
    /**
     * 
     * @type {WebData}
     * @memberof Individual
     */
    'webData'?: WebData | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum InformationContentType {
    Highlights = 'Highlights',
    ProductCharacteristics = 'ProductCharacteristics',
    Material = 'Material',
    Hints = 'Hints',
    MeasurementInstructions = 'MeasurementInstructions',
    SizeChart = 'SizeChart',
    Dimensions = 'Dimensions',
    Composition = 'Composition',
    FeedingRecommendation = 'FeedingRecommendation',
    Storage = 'Storage',
    TipsAndApplication = 'TipsAndApplication',
    Downloads = 'Downloads',
    Additives = 'Additives',
    ProductMedia = 'ProductMedia',
    AnalyticConstituents = 'AnalyticConstituents'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum InformationEntityType {
    ProductAdditionalInformation = 'ProductAdditionalInformation'
}

/**
 * 
 * @export
 * @interface InformationGroupResponse
 */
export interface InformationGroupResponse {
    /**
     * 
     * @type {string}
     * @memberof InformationGroupResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof InformationGroupResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof InformationGroupResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {InformationEntityType}
     * @memberof InformationGroupResponse
     */
    'informationEntityType': InformationEntityType;
    /**
     * 
     * @type {InformationContentType}
     * @memberof InformationGroupResponse
     */
    'informationContentType': InformationContentType;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof InformationGroupResponse
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof InformationGroupResponse
     */
    'description'?: TranslatedTypeResponseOfString | null;
}
/**
 * 
 * @export
 * @interface InformationGroupResponseAllOf
 */
export interface InformationGroupResponseAllOf {
    /**
     * 
     * @type {InformationEntityType}
     * @memberof InformationGroupResponseAllOf
     */
    'informationEntityType': InformationEntityType;
    /**
     * 
     * @type {InformationContentType}
     * @memberof InformationGroupResponseAllOf
     */
    'informationContentType': InformationContentType;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof InformationGroupResponseAllOf
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof InformationGroupResponseAllOf
     */
    'description'?: TranslatedTypeResponseOfString | null;
}
/**
 * 
 * @export
 * @interface InlineBulkUpsertProductStockRequest
 */
export interface InlineBulkUpsertProductStockRequest {
    /**
     * 
     * @type {number}
     * @memberof InlineBulkUpsertProductStockRequest
     */
    'stock': number;
    /**
     * 
     * @type {boolean}
     * @memberof InlineBulkUpsertProductStockRequest
     */
    'available': boolean;
    /**
     * 
     * @type {number}
     * @memberof InlineBulkUpsertProductStockRequest
     */
    'priority': number;
    /**
     * 
     * @type {string}
     * @memberof InlineBulkUpsertProductStockRequest
     */
    'warehouseId': string;
    /**
     * 
     * @type {string}
     * @memberof InlineBulkUpsertProductStockRequest
     */
    'productId': string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineBulkUpsertProductStockRequest
     */
    'id'?: string | null;
}
/**
 * 
 * @export
 * @interface InlineBulkUpsertProductStockRequestAllOf
 */
export interface InlineBulkUpsertProductStockRequestAllOf {
    /**
     * 
     * @type {string}
     * @memberof InlineBulkUpsertProductStockRequestAllOf
     */
    'productId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineBulkUpsertProductStockRequestAllOf
     */
    'id'?: string | null;
}
/**
 * 
 * @export
 * @interface InlineCreateDeliveryTimeRequest
 */
export interface InlineCreateDeliveryTimeRequest {
    /**
     * 
     * @type {number}
     * @memberof InlineCreateDeliveryTimeRequest
     */
    'min': number;
    /**
     * 
     * @type {number}
     * @memberof InlineCreateDeliveryTimeRequest
     */
    'max': number;
    /**
     * 
     * @type {DeliveryTimeUnit}
     * @memberof InlineCreateDeliveryTimeRequest
     */
    'unit': DeliveryTimeUnit;
    /**
     * 
     * @type {DeliveryTimeType}
     * @memberof InlineCreateDeliveryTimeRequest
     */
    'type': DeliveryTimeType;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateDeliveryTimeRequest
     */
    'productId'?: string | null;
}
/**
 * 
 * @export
 * @interface InlineCreateManufacturerConditionRequest
 */
export interface InlineCreateManufacturerConditionRequest {
    /**
     * 
     * @type {string}
     * @memberof InlineCreateManufacturerConditionRequest
     */
    'manufacturerId': string | null;
    /**
     * 
     * @type {ManufacturerConditionKey}
     * @memberof InlineCreateManufacturerConditionRequest
     */
    'key': ManufacturerConditionKey;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateManufacturerConditionRequest
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface InlineCreateManufacturerConditionRequestAllOf
 */
export interface InlineCreateManufacturerConditionRequestAllOf {
    /**
     * 
     * @type {string}
     * @memberof InlineCreateManufacturerConditionRequestAllOf
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface InlineCreateOrderInvoiceAddressRequest
 */
export interface InlineCreateOrderInvoiceAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderInvoiceAddressRequest
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderInvoiceAddressRequest
     */
    'streetNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderInvoiceAddressRequest
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderInvoiceAddressRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderInvoiceAddressRequest
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderInvoiceAddressRequest
     */
    'additionalAddressLine1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderInvoiceAddressRequest
     */
    'additionalAddressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderInvoiceAddressRequest
     */
    'countryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderInvoiceAddressRequest
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderInvoiceAddressRequest
     */
    'department'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderInvoiceAddressRequest
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderInvoiceAddressRequest
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderInvoiceAddressRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderInvoiceAddressRequest
     */
    'lastName': string;
    /**
     * 
     * @type {any}
     * @memberof InlineCreateOrderInvoiceAddressRequest
     */
    'shopCustomFields'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderInvoiceAddressRequest
     */
    'orderId': string | null;
}
/**
 * 
 * @export
 * @interface InlineCreateOrderInvoiceAddressRequestAllOf
 */
export interface InlineCreateOrderInvoiceAddressRequestAllOf {
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderInvoiceAddressRequestAllOf
     */
    'orderId'?: string | null;
}
/**
 * 
 * @export
 * @interface InlineCreateOrderPaymentTransactionRequest
 */
export interface InlineCreateOrderPaymentTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderPaymentTransactionRequest
     */
    'orderId': string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderPaymentTransactionRequest
     */
    'paymentProviderId': string;
}
/**
 * 
 * @export
 * @interface InlineCreateOrderPositionRequest
 */
export interface InlineCreateOrderPositionRequest {
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderPositionRequest
     */
    'shopReferenceId': string;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderPositionRequest
     */
    'parentOrderPositionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderPositionRequest
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderPositionRequest
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderPositionRequest
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderPositionRequest
     */
    'coverId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InlineCreateOrderPositionRequest
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof InlineCreateOrderPositionRequest
     */
    'unitPrice': number;
    /**
     * 
     * @type {number}
     * @memberof InlineCreateOrderPositionRequest
     */
    'totalPrice': number;
    /**
     * 
     * @type {OrderPositionType}
     * @memberof InlineCreateOrderPositionRequest
     */
    'positionType': OrderPositionType;
    /**
     * 
     * @type {any}
     * @memberof InlineCreateOrderPositionRequest
     */
    'payload': any;
    /**
     * 
     * @type {any}
     * @memberof InlineCreateOrderPositionRequest
     */
    'price': any;
    /**
     * 
     * @type {boolean}
     * @memberof InlineCreateOrderPositionRequest
     */
    'good': boolean;
    /**
     * 
     * @type {number}
     * @memberof InlineCreateOrderPositionRequest
     */
    'position': number;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderPositionRequest
     */
    'orderReturnId'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof InlineCreateOrderPositionRequest
     */
    'shopCustomFields'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderPositionRequest
     */
    'orderId': string | null;
}
/**
 * 
 * @export
 * @interface InlineCreateOrderShippingAddressRequest
 */
export interface InlineCreateOrderShippingAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderShippingAddressRequest
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderShippingAddressRequest
     */
    'streetNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderShippingAddressRequest
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderShippingAddressRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderShippingAddressRequest
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderShippingAddressRequest
     */
    'additionalAddressLine1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderShippingAddressRequest
     */
    'additionalAddressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderShippingAddressRequest
     */
    'countryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderShippingAddressRequest
     */
    'shopVersionReferenceId': string;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderShippingAddressRequest
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderShippingAddressRequest
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderShippingAddressRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderShippingAddressRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderShippingAddressRequest
     */
    'orderId': string | null;
}
/**
 * 
 * @export
 * @interface InlineCreateOrderStateHistoryRequest
 */
export interface InlineCreateOrderStateHistoryRequest {
    /**
     * 
     * @type {OrderStateContext}
     * @memberof InlineCreateOrderStateHistoryRequest
     */
    'orderStateContext': OrderStateContext;
    /**
     * 
     * @type {OrderStateTechnicalName}
     * @memberof InlineCreateOrderStateHistoryRequest
     */
    'orderStateTechnicalName': OrderStateTechnicalName;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderStateHistoryRequest
     */
    'orderStateId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateOrderStateHistoryRequest
     */
    'orderId': string | null;
}
/**
 * 
 * @export
 * @interface InlineCreateProductAssetRequest
 */
export interface InlineCreateProductAssetRequest {
    /**
     * 
     * @type {number}
     * @memberof InlineCreateProductAssetRequest
     */
    'position': number;
    /**
     * 
     * @type {TranslatedTypeRequestOfGuid}
     * @memberof InlineCreateProductAssetRequest
     */
    'assetId': TranslatedTypeRequestOfGuid;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof InlineCreateProductAssetRequest
     */
    'description'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof InlineCreateProductAssetRequest
     */
    'alternative'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateProductAssetRequest
     */
    'productId': string | null;
}
/**
 * 
 * @export
 * @interface InlineCreateProductPriceRequest
 */
export interface InlineCreateProductPriceRequest {
    /**
     * 
     * @type {number}
     * @memberof InlineCreateProductPriceRequest
     */
    'gross'?: number;
    /**
     * 
     * @type {number}
     * @memberof InlineCreateProductPriceRequest
     */
    'listPriceGross'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InlineCreateProductPriceRequest
     */
    'quantityStart'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InlineCreateProductPriceRequest
     */
    'quantityEnd'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateProductPriceRequest
     */
    'currencyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateProductPriceRequest
     */
    'countryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateProductPriceRequest
     */
    'productId'?: string | null;
}
/**
 * 
 * @export
 * @interface InlineCreateProductStockRequest
 */
export interface InlineCreateProductStockRequest {
    /**
     * 
     * @type {number}
     * @memberof InlineCreateProductStockRequest
     */
    'stock': number;
    /**
     * 
     * @type {boolean}
     * @memberof InlineCreateProductStockRequest
     */
    'available': boolean;
    /**
     * 
     * @type {number}
     * @memberof InlineCreateProductStockRequest
     */
    'priority': number;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateProductStockRequest
     */
    'warehouseId': string;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateProductStockRequest
     */
    'productId': string | null;
}
/**
 * 
 * @export
 * @interface InlineCreateVariantProductRequest
 */
export interface InlineCreateVariantProductRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineCreateVariantProductRequest
     */
    'variantOptionIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateVariantProductRequest
     */
    'productId': string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineCreateVariantProductRequest
     */
    'ean': string;
}
/**
 * 
 * @export
 * @interface InlineCreateVariantProductRequestAllOf
 */
export interface InlineCreateVariantProductRequestAllOf {
    /**
     * 
     * @type {string}
     * @memberof InlineCreateVariantProductRequestAllOf
     */
    'productId'?: string | null;
}
/**
 * 
 * @export
 * @interface InlineOrderPositionUnitPrice
 */
export interface InlineOrderPositionUnitPrice {
    /**
     * 
     * @type {string}
     * @memberof InlineOrderPositionUnitPrice
     */
    'id': string;
    /**
     * 
     * @type {Price}
     * @memberof InlineOrderPositionUnitPrice
     */
    'unitPrice': Price;
    /**
     * 
     * @type {Price}
     * @memberof InlineOrderPositionUnitPrice
     */
    'discountedUnitPrice'?: Price | null;
    /**
     * 
     * @type {number}
     * @memberof InlineOrderPositionUnitPrice
     */
    'maxRefundAmount': number;
    /**
     * 
     * @type {boolean}
     * @memberof InlineOrderPositionUnitPrice
     */
    'isRefundable': boolean;
}
/**
 * 
 * @export
 * @interface InlineOrderPositionUnitPriceAllOf
 */
export interface InlineOrderPositionUnitPriceAllOf {
    /**
     * 
     * @type {number}
     * @memberof InlineOrderPositionUnitPriceAllOf
     */
    'maxRefundAmount': number;
    /**
     * 
     * @type {boolean}
     * @memberof InlineOrderPositionUnitPriceAllOf
     */
    'isRefundable': boolean;
}
/**
 * 
 * @export
 * @interface InlineUpdateProductAssetRequest
 */
export interface InlineUpdateProductAssetRequest {
    /**
     * 
     * @type {number}
     * @memberof InlineUpdateProductAssetRequest
     */
    'position': number;
    /**
     * 
     * @type {TranslatedTypeRequestOfGuid}
     * @memberof InlineUpdateProductAssetRequest
     */
    'assetId': TranslatedTypeRequestOfGuid;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof InlineUpdateProductAssetRequest
     */
    'description'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof InlineUpdateProductAssetRequest
     */
    'alternative'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {string}
     * @memberof InlineUpdateProductAssetRequest
     */
    'productId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineUpdateProductAssetRequest
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface InlineUpdateProductAssetRequestAllOf
 */
export interface InlineUpdateProductAssetRequestAllOf {
    /**
     * 
     * @type {string}
     * @memberof InlineUpdateProductAssetRequestAllOf
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface IntegrationConfigResponse
 */
export interface IntegrationConfigResponse {
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigResponse
     */
    'integrationId': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationConfigResponse
     */
    'enabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationConfigResponse
     */
    'installed': boolean;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigResponse
     */
    'credentialId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigResponse
     */
    'config'?: string | null;
}
/**
 * 
 * @export
 * @interface IntegrationConfigResponseAllOf
 */
export interface IntegrationConfigResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigResponseAllOf
     */
    'integrationId': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigResponseAllOf
     */
    'manufacturerId': string;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationConfigResponseAllOf
     */
    'enabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationConfigResponseAllOf
     */
    'installed': boolean;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigResponseAllOf
     */
    'credentialId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntegrationConfigResponseAllOf
     */
    'config'?: string | null;
}
/**
 * 
 * @export
 * @interface IntegrationResponse
 */
export interface IntegrationResponse {
    /**
     * 
     * @type {string}
     * @memberof IntegrationResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {IntegrationType}
     * @memberof IntegrationResponse
     */
    'type': IntegrationType;
    /**
     * 
     * @type {string}
     * @memberof IntegrationResponse
     */
    'identifier': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationResponse
     */
    'name': string;
    /**
     * 
     * @type {Array<AuthenticationMode>}
     * @memberof IntegrationResponse
     */
    'authenticationMode': Array<AuthenticationMode>;
    /**
     * 
     * @type {string}
     * @memberof IntegrationResponse
     */
    'installationUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntegrationResponse
     */
    'redirectUrl'?: string | null;
    /**
     * 
     * @type {IntegrationConfigResponse}
     * @memberof IntegrationResponse
     */
    'config'?: IntegrationConfigResponse | null;
}
/**
 * 
 * @export
 * @interface IntegrationResponseAllOf
 */
export interface IntegrationResponseAllOf {
    /**
     * 
     * @type {IntegrationType}
     * @memberof IntegrationResponseAllOf
     */
    'type': IntegrationType;
    /**
     * 
     * @type {string}
     * @memberof IntegrationResponseAllOf
     */
    'identifier': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationResponseAllOf
     */
    'name': string;
    /**
     * 
     * @type {Array<AuthenticationMode>}
     * @memberof IntegrationResponseAllOf
     */
    'authenticationMode': Array<AuthenticationMode>;
    /**
     * 
     * @type {string}
     * @memberof IntegrationResponseAllOf
     */
    'installationUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntegrationResponseAllOf
     */
    'redirectUrl'?: string | null;
    /**
     * 
     * @type {IntegrationConfigResponse}
     * @memberof IntegrationResponseAllOf
     */
    'config'?: IntegrationConfigResponse | null;
}
/**
 * 
 * @export
 * @interface IntegrationSubModuleConfigResponse
 */
export interface IntegrationSubModuleConfigResponse {
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleConfigResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleConfigResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleConfigResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleConfigResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleConfigResponse
     */
    'integrationSubModuleId': string;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationSubModuleConfigResponse
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleConfigResponse
     */
    'config'?: string | null;
}
/**
 * 
 * @export
 * @interface IntegrationSubModuleConfigResponseAllOf
 */
export interface IntegrationSubModuleConfigResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleConfigResponseAllOf
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleConfigResponseAllOf
     */
    'integrationSubModuleId': string;
    /**
     * 
     * @type {boolean}
     * @memberof IntegrationSubModuleConfigResponseAllOf
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleConfigResponseAllOf
     */
    'config'?: string | null;
}
/**
 * 
 * @export
 * @interface IntegrationSubModuleResponse
 */
export interface IntegrationSubModuleResponse {
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleResponse
     */
    'integrationId': string;
    /**
     * 
     * @type {IntegrationSubModuleType}
     * @memberof IntegrationSubModuleResponse
     */
    'type': IntegrationSubModuleType;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleResponse
     */
    'name': string;
    /**
     * 
     * @type {IntegrationSubModuleConfigResponse}
     * @memberof IntegrationSubModuleResponse
     */
    'config'?: IntegrationSubModuleConfigResponse | null;
}
/**
 * 
 * @export
 * @interface IntegrationSubModuleResponseAllOf
 */
export interface IntegrationSubModuleResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleResponseAllOf
     */
    'integrationId': string;
    /**
     * 
     * @type {IntegrationSubModuleType}
     * @memberof IntegrationSubModuleResponseAllOf
     */
    'type': IntegrationSubModuleType;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSubModuleResponseAllOf
     */
    'name': string;
    /**
     * 
     * @type {IntegrationSubModuleConfigResponse}
     * @memberof IntegrationSubModuleResponseAllOf
     */
    'config'?: IntegrationSubModuleConfigResponse | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum IntegrationSubModuleType {
    Base = 'Base',
    OrderExport = 'OrderExport',
    InvoiceDocumentExport = 'InvoiceDocumentExport',
    CreditNoteDocumentExport = 'CreditNoteDocumentExport',
    OrderCancellationInvoiceExport = 'OrderCancellationInvoiceExport',
    CustomerExport = 'CustomerExport',
    OrderDeliveryInformationExport = 'OrderDeliveryInformationExport',
    ProductImport = 'ProductImport',
    InvoiceDocumentImport = 'InvoiceDocumentImport',
    ProductStockImport = 'ProductStockImport',
    OrderDeliveryInformationImport = 'OrderDeliveryInformationImport',
    WebhookRegistrar = 'WebhookRegistrar'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum IntegrationType {
    Ecommerce = 'Ecommerce',
    Erp = 'Erp',
    Pim = 'Pim'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum IntervalEnum {
    Daily = 'daily',
    Monthly = 'monthly',
    Weekly = 'weekly'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum IntervalEnum2 {
    Daily = 'daily',
    Monthly = 'monthly',
    Weekly = 'weekly'
}

/**
 * 
 * @export
 * @interface LanguageResponse
 */
export interface LanguageResponse {
    /**
     * 
     * @type {string}
     * @memberof LanguageResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LanguageResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof LanguageResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LanguageResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof LanguageResponse
     */
    'localeId': string;
    /**
     * 
     * @type {LocaleResponse}
     * @memberof LanguageResponse
     */
    'locale'?: LocaleResponse | null;
    /**
     * 
     * @type {string}
     * @memberof LanguageResponse
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LanguageResponse
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface LanguageResponseAllOf
 */
export interface LanguageResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof LanguageResponseAllOf
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof LanguageResponseAllOf
     */
    'localeId': string;
    /**
     * 
     * @type {LocaleResponse}
     * @memberof LanguageResponseAllOf
     */
    'locale'?: LocaleResponse | null;
    /**
     * 
     * @type {string}
     * @memberof LanguageResponseAllOf
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LanguageResponseAllOf
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface LegalEntity
 */
export interface LegalEntity {
    /**
     * 
     * @type {TypeEnum16}
     * @memberof LegalEntity
     */
    'type'?: TypeEnum16 | null;
    /**
     * 
     * @type {{ [key: string]: LegalEntityCapability; }}
     * @memberof LegalEntity
     */
    'capabilities'?: { [key: string]: LegalEntityCapability; } | null;
    /**
     * 
     * @type {Array<DocumentReference>}
     * @memberof LegalEntity
     */
    'documentDetails'?: Array<DocumentReference> | null;
    /**
     * 
     * @type {Array<EntityReference>}
     * @memberof LegalEntity
     * @deprecated
     */
    'documents'?: Array<EntityReference> | null;
    /**
     * 
     * @type {Array<LegalEntityAssociation>}
     * @memberof LegalEntity
     */
    'entityAssociations'?: Array<LegalEntityAssociation> | null;
    /**
     * 
     * @type {string}
     * @memberof LegalEntity
     */
    'id'?: string | null;
    /**
     * 
     * @type {Individual}
     * @memberof LegalEntity
     */
    'individual'?: Individual | null;
    /**
     * 
     * @type {Organization}
     * @memberof LegalEntity
     */
    'organization'?: Organization | null;
    /**
     * 
     * @type {Array<CapabilityProblem>}
     * @memberof LegalEntity
     */
    'problems'?: Array<CapabilityProblem> | null;
    /**
     * 
     * @type {string}
     * @memberof LegalEntity
     */
    'reference'?: string | null;
    /**
     * 
     * @type {SoleProprietorship}
     * @memberof LegalEntity
     */
    'soleProprietorship'?: SoleProprietorship | null;
    /**
     * 
     * @type {Array<TransferInstrumentReference>}
     * @memberof LegalEntity
     */
    'transferInstruments'?: Array<TransferInstrumentReference> | null;
    /**
     * 
     * @type {Trust}
     * @memberof LegalEntity
     */
    'trust'?: Trust | null;
    /**
     * 
     * @type {UnincorporatedPartnership}
     * @memberof LegalEntity
     */
    'unincorporatedPartnership'?: UnincorporatedPartnership | null;
    /**
     * 
     * @type {Array<VerificationDeadline2>}
     * @memberof LegalEntity
     */
    'verificationDeadlines'?: Array<VerificationDeadline2> | null;
    /**
     * 
     * @type {string}
     * @memberof LegalEntity
     */
    'verificationPlan'?: string | null;
}
/**
 * 
 * @export
 * @interface LegalEntityAssociation
 */
export interface LegalEntityAssociation {
    /**
     * 
     * @type {TypeEnum17}
     * @memberof LegalEntityAssociation
     */
    'type'?: TypeEnum17;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityAssociation
     */
    'associatorId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityAssociation
     */
    'entityType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityAssociation
     */
    'jobTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityAssociation
     */
    'legalEntityId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityAssociation
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityAssociation
     */
    'relationship'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof LegalEntityAssociation
     */
    'settlorExemptionReason'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface LegalEntityCapability
 */
export interface LegalEntityCapability {
    /**
     * 
     * @type {AllowedLevelEnum3}
     * @memberof LegalEntityCapability
     */
    'allowedLevel'?: AllowedLevelEnum3 | null;
    /**
     * 
     * @type {RequestedLevelEnum3}
     * @memberof LegalEntityCapability
     */
    'requestedLevel'?: RequestedLevelEnum3 | null;
    /**
     * 
     * @type {boolean}
     * @memberof LegalEntityCapability
     */
    'allowed'?: boolean | null;
    /**
     * 
     * @type {CapabilitySettings2}
     * @memberof LegalEntityCapability
     */
    'allowedSettings'?: CapabilitySettings2 | null;
    /**
     * 
     * @type {boolean}
     * @memberof LegalEntityCapability
     */
    'requested'?: boolean | null;
    /**
     * 
     * @type {CapabilitySettings2}
     * @memberof LegalEntityCapability
     */
    'requestedSettings'?: CapabilitySettings2 | null;
    /**
     * 
     * @type {Array<SupportingEntityCapability>}
     * @memberof LegalEntityCapability
     */
    'transferInstruments'?: Array<SupportingEntityCapability> | null;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityCapability
     */
    'verificationStatus'?: string | null;
}
/**
 * 
 * @export
 * @interface LegalEntityInfo
 */
export interface LegalEntityInfo {
    /**
     * 
     * @type {TypeEnum23}
     * @memberof LegalEntityInfo
     */
    'type'?: TypeEnum23 | null;
    /**
     * 
     * @type {{ [key: string]: LegalEntityCapability; }}
     * @memberof LegalEntityInfo
     */
    'capabilities'?: { [key: string]: LegalEntityCapability; } | null;
    /**
     * 
     * @type {Array<LegalEntityAssociation>}
     * @memberof LegalEntityInfo
     */
    'entityAssociations'?: Array<LegalEntityAssociation> | null;
    /**
     * 
     * @type {Individual}
     * @memberof LegalEntityInfo
     */
    'individual'?: Individual | null;
    /**
     * 
     * @type {Organization}
     * @memberof LegalEntityInfo
     */
    'organization'?: Organization | null;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityInfo
     */
    'reference'?: string | null;
    /**
     * 
     * @type {SoleProprietorship}
     * @memberof LegalEntityInfo
     */
    'soleProprietorship'?: SoleProprietorship | null;
    /**
     * 
     * @type {Trust}
     * @memberof LegalEntityInfo
     */
    'trust'?: Trust | null;
    /**
     * 
     * @type {UnincorporatedPartnership}
     * @memberof LegalEntityInfo
     */
    'unincorporatedPartnership'?: UnincorporatedPartnership | null;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityInfo
     */
    'verificationPlan'?: string | null;
}
/**
 * 
 * @export
 * @interface LegalEntityInfoRequiredType
 */
export interface LegalEntityInfoRequiredType {
    /**
     * 
     * @type {TypeEnum10}
     * @memberof LegalEntityInfoRequiredType
     */
    'type'?: TypeEnum10;
    /**
     * 
     * @type {{ [key: string]: LegalEntityCapability; }}
     * @memberof LegalEntityInfoRequiredType
     */
    'capabilities'?: { [key: string]: LegalEntityCapability; } | null;
    /**
     * 
     * @type {Array<LegalEntityAssociation>}
     * @memberof LegalEntityInfoRequiredType
     */
    'entityAssociations'?: Array<LegalEntityAssociation> | null;
    /**
     * 
     * @type {Individual}
     * @memberof LegalEntityInfoRequiredType
     */
    'individual'?: Individual | null;
    /**
     * 
     * @type {Organization}
     * @memberof LegalEntityInfoRequiredType
     */
    'organization'?: Organization | null;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityInfoRequiredType
     */
    'reference'?: string | null;
    /**
     * 
     * @type {SoleProprietorship}
     * @memberof LegalEntityInfoRequiredType
     */
    'soleProprietorship'?: SoleProprietorship | null;
    /**
     * 
     * @type {Trust}
     * @memberof LegalEntityInfoRequiredType
     */
    'trust'?: Trust | null;
    /**
     * 
     * @type {UnincorporatedPartnership}
     * @memberof LegalEntityInfoRequiredType
     */
    'unincorporatedPartnership'?: UnincorporatedPartnership | null;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityInfoRequiredType
     */
    'verificationPlan'?: string | null;
}
/**
 * 
 * @export
 * @interface LocaleResponse
 */
export interface LocaleResponse {
    /**
     * 
     * @type {string}
     * @memberof LocaleResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LocaleResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof LocaleResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocaleResponse
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface LocaleResponseAllOf
 */
export interface LocaleResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof LocaleResponseAllOf
     */
    'code': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum LogLevel {
    Trace = 'Trace',
    Debug = 'Debug',
    Information = 'Information',
    Warning = 'Warning',
    Error = 'Error',
    Critical = 'Critical',
    None = 'None'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum LogisticsReturnReason {
    Standard = 'Standard',
    ReturnLabelNotUsed = 'ReturnLabelNotUsed',
    AcceptanceRefused = 'AcceptanceRefused',
    ParcelNotPickedUp = 'ParcelNotPickedUp',
    ParcelStopOnRecipientRequest = 'ParcelStopOnRecipientRequest',
    BadShippingConditions = 'BadShippingConditions',
    RecipientNotIdentifiable = 'RecipientNotIdentifiable',
    ParcelDamaged = 'ParcelDamaged',
    Other = 'Other'
}

/**
 * 
 * @export
 * @interface LoyaltyPointAggregationResponse
 */
export interface LoyaltyPointAggregationResponse {
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointAggregationResponse
     */
    'customerId': string;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyPointAggregationResponse
     */
    'overallTotalPoints': number;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyPointAggregationResponse
     */
    'spentPoints': number;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyPointAggregationResponse
     */
    'currentBalance': number;
}
/**
 * 
 * @export
 * @interface LoyaltyPointHandlerConfig
 */
export interface LoyaltyPointHandlerConfig {
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointHandlerConfig
     */
    'handlerName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyPointHandlerConfig
     */
    'parameterCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointHandlerConfig
     */
    'parameterDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface LoyaltyPointProcessingConfigResponse
 */
export interface LoyaltyPointProcessingConfigResponse {
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointProcessingConfigResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointProcessingConfigResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointProcessingConfigResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointProcessingConfigResponse
     */
    'handlerName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LoyaltyPointProcessingConfigResponse
     */
    'handlerArguments'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof LoyaltyPointProcessingConfigResponse
     */
    'isEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoyaltyPointProcessingConfigResponse
     */
    'isInitialHandler': boolean;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointProcessingConfigResponse
     */
    'nextHandlerId'?: string | null;
}
/**
 * 
 * @export
 * @interface LoyaltyPointProcessingConfigResponseAllOf
 */
export interface LoyaltyPointProcessingConfigResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointProcessingConfigResponseAllOf
     */
    'handlerName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LoyaltyPointProcessingConfigResponseAllOf
     */
    'handlerArguments'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof LoyaltyPointProcessingConfigResponseAllOf
     */
    'isEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoyaltyPointProcessingConfigResponseAllOf
     */
    'isInitialHandler': boolean;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointProcessingConfigResponseAllOf
     */
    'nextHandlerId'?: string | null;
}
/**
 * 
 * @export
 * @interface LoyaltyPointTransactionResponse
 */
export interface LoyaltyPointTransactionResponse {
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointTransactionResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointTransactionResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointTransactionResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyPointTransactionResponse
     */
    'points': number;
    /**
     * 
     * @type {LoyaltyPointTransactionType}
     * @memberof LoyaltyPointTransactionResponse
     */
    'type': LoyaltyPointTransactionType;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointTransactionResponse
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointTransactionResponse
     */
    'orderId'?: string | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof LoyaltyPointTransactionResponse
     */
    'description'?: TranslatedTypeResponseOfString | null;
}
/**
 * 
 * @export
 * @interface LoyaltyPointTransactionResponseAllOf
 */
export interface LoyaltyPointTransactionResponseAllOf {
    /**
     * 
     * @type {number}
     * @memberof LoyaltyPointTransactionResponseAllOf
     */
    'points': number;
    /**
     * 
     * @type {LoyaltyPointTransactionType}
     * @memberof LoyaltyPointTransactionResponseAllOf
     */
    'type': LoyaltyPointTransactionType;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointTransactionResponseAllOf
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointTransactionResponseAllOf
     */
    'orderId'?: string | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof LoyaltyPointTransactionResponseAllOf
     */
    'description'?: TranslatedTypeResponseOfString | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum LoyaltyPointTransactionType {
    Credit = 'Credit',
    Redemption = 'Redemption'
}

/**
 * 
 * @export
 * @interface LoyaltyRankCustomerHistoryResponse
 */
export interface LoyaltyRankCustomerHistoryResponse {
    /**
     * 
     * @type {string}
     * @memberof LoyaltyRankCustomerHistoryResponse
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyRankCustomerHistoryResponse
     */
    'loyaltyRankId': string;
    /**
     * 
     * @type {LoyaltyRankResponse}
     * @memberof LoyaltyRankCustomerHistoryResponse
     */
    'loyaltyRank'?: LoyaltyRankResponse | null;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyRankCustomerHistoryResponse
     */
    'achievedAt': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyRankCustomerHistoryResponse
     */
    'validTo'?: string | null;
}
/**
 * 
 * @export
 * @interface LoyaltyRankResponse
 */
export interface LoyaltyRankResponse {
    /**
     * 
     * @type {string}
     * @memberof LoyaltyRankResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyRankResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyRankResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyRankResponse
     */
    'minimumPoints': number;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyRankResponse
     */
    'discountPercentage': number;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyRankResponse
     */
    'validFrom': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyRankResponse
     */
    'validTo'?: string | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof LoyaltyRankResponse
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof LoyaltyRankResponse
     */
    'description'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfAssetResponse}
     * @memberof LoyaltyRankResponse
     */
    'asset'?: TranslatedTypeResponseOfAssetResponse | null;
}
/**
 * 
 * @export
 * @interface LoyaltyRankResponseAllOf
 */
export interface LoyaltyRankResponseAllOf {
    /**
     * 
     * @type {number}
     * @memberof LoyaltyRankResponseAllOf
     */
    'minimumPoints': number;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyRankResponseAllOf
     */
    'discountPercentage': number;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyRankResponseAllOf
     */
    'validFrom': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyRankResponseAllOf
     */
    'validTo'?: string | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof LoyaltyRankResponseAllOf
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof LoyaltyRankResponseAllOf
     */
    'description'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfAssetResponse}
     * @memberof LoyaltyRankResponseAllOf
     */
    'asset'?: TranslatedTypeResponseOfAssetResponse | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum MainSourceOfIncomeEnum {
    BusinessOperation = 'businessOperation',
    RealEstateSales = 'realEstateSales',
    InvestmentInterestOrRoyalty = 'investmentInterestOrRoyalty',
    PropertyRental = 'propertyRental',
    Other = 'other'
}

/**
 * 
 * @export
 * @interface ManufacturerAddressResponse
 */
export interface ManufacturerAddressResponse {
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAddressResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAddressResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAddressResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAddressResponse
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAddressResponse
     */
    'streetNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAddressResponse
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAddressResponse
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAddressResponse
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAddressResponse
     */
    'additionalAddressLine1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAddressResponse
     */
    'additionalAddressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAddressResponse
     */
    'countryId': string;
    /**
     * 
     * @type {CountryResponse}
     * @memberof ManufacturerAddressResponse
     */
    'country': CountryResponse;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAddressResponse
     */
    'manufacturerId': string;
}
/**
 * 
 * @export
 * @interface ManufacturerAddressResponseAllOf
 */
export interface ManufacturerAddressResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAddressResponseAllOf
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAddressResponseAllOf
     */
    'streetNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAddressResponseAllOf
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAddressResponseAllOf
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAddressResponseAllOf
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAddressResponseAllOf
     */
    'additionalAddressLine1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAddressResponseAllOf
     */
    'additionalAddressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAddressResponseAllOf
     */
    'countryId': string;
    /**
     * 
     * @type {CountryResponse}
     * @memberof ManufacturerAddressResponseAllOf
     */
    'country': CountryResponse;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerAddressResponseAllOf
     */
    'manufacturerId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ManufacturerConditionKey {
    PaymentCommission = 'PaymentCommission',
    NumberRangeAware = 'NumberRangeAware',
    WarehouseUsage = 'WarehouseUsage',
    PromotionAware = 'PromotionAware',
    OrderReturnAnnouncementAware = 'OrderReturnAnnouncementAware',
    ProductGroupMappingRuleAware = 'ProductGroupMappingRuleAware',
    BulkProductImportAware = 'BulkProductImportAware',
    ProductBundleAware = 'ProductBundleAware',
    ManufacturerDataExchangeAware = 'ManufacturerDataExchangeAware',
    ExportOrderCreditNoteDataAware = 'ExportOrderCreditNoteDataAware',
    ExportOrderInvoiceDataAware = 'ExportOrderInvoiceDataAware',
    ExportOrderReceiptAware = 'ExportOrderReceiptAware',
    ExportOrderReturnAnnouncementAware = 'ExportOrderReturnAnnouncementAware',
    ExportOrderAware = 'ExportOrderAware'
}

/**
 * 
 * @export
 * @interface ManufacturerConditionResponse
 */
export interface ManufacturerConditionResponse {
    /**
     * 
     * @type {string}
     * @memberof ManufacturerConditionResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerConditionResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerConditionResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerConditionResponse
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerConditionResponse
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerConditionResponse
     */
    'manufacturerId': string;
}
/**
 * 
 * @export
 * @interface ManufacturerConditionResponseAllOf
 */
export interface ManufacturerConditionResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof ManufacturerConditionResponseAllOf
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerConditionResponseAllOf
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerConditionResponseAllOf
     */
    'manufacturerId': string;
}
/**
 * 
 * @export
 * @interface ManufacturerContactResponse
 */
export interface ManufacturerContactResponse {
    /**
     * 
     * @type {string}
     * @memberof ManufacturerContactResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerContactResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerContactResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerContactResponse
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerContactResponse
     */
    'streetNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerContactResponse
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerContactResponse
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerContactResponse
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerContactResponse
     */
    'additionalAddressLine1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerContactResponse
     */
    'additionalAddressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerContactResponse
     */
    'countryId'?: string;
    /**
     * 
     * @type {CountryResponse}
     * @memberof ManufacturerContactResponse
     */
    'country'?: CountryResponse | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerContactResponse
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerContactResponse
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerContactResponse
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerContactResponse
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerContactResponse
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerContactResponse
     */
    'phoneNumber': string;
    /**
     * 
     * @type {Department}
     * @memberof ManufacturerContactResponse
     */
    'department': Department;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerContactResponse
     */
    'manufacturerId': string;
}
/**
 * 
 * @export
 * @interface ManufacturerContactResponseAllOf
 */
export interface ManufacturerContactResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof ManufacturerContactResponseAllOf
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerContactResponseAllOf
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerContactResponseAllOf
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerContactResponseAllOf
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerContactResponseAllOf
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerContactResponseAllOf
     */
    'phoneNumber': string;
    /**
     * 
     * @type {Department}
     * @memberof ManufacturerContactResponseAllOf
     */
    'department': Department;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerContactResponseAllOf
     */
    'manufacturerId': string;
}
/**
 * 
 * @export
 * @interface ManufacturerDataExchangeDetailResponse
 */
export interface ManufacturerDataExchangeDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeDetailResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeDetailResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeDetailResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeDetailResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {ExchangeDirection}
     * @memberof ManufacturerDataExchangeDetailResponse
     */
    'direction': ExchangeDirection;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeDetailResponse
     */
    'entity': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeDetailResponse
     */
    'action': string;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerDataExchangeDetailResponse
     */
    'itemCount': number;
    /**
     * 
     * @type {boolean}
     * @memberof ManufacturerDataExchangeDetailResponse
     */
    'hasErrors': boolean;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerDataExchangeDetailResponse
     */
    'errorCount': number;
    /**
     * 
     * @type {Array<ManufacturerDataExchangeLogResponse>}
     * @memberof ManufacturerDataExchangeDetailResponse
     */
    'manufacturerDataExchangeLogs': Array<ManufacturerDataExchangeLogResponse>;
}
/**
 * 
 * @export
 * @interface ManufacturerDataExchangeDetailResponseAllOf
 */
export interface ManufacturerDataExchangeDetailResponseAllOf {
    /**
     * 
     * @type {Array<ManufacturerDataExchangeLogResponse>}
     * @memberof ManufacturerDataExchangeDetailResponseAllOf
     */
    'manufacturerDataExchangeLogs': Array<ManufacturerDataExchangeLogResponse>;
}
/**
 * 
 * @export
 * @interface ManufacturerDataExchangeLogResponse
 */
export interface ManufacturerDataExchangeLogResponse {
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeLogResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeLogResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeLogResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeLogResponse
     */
    'manufacturerDataExchangeId': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeLogResponse
     */
    'messageId': string;
    /**
     * 
     * @type {LogLevel}
     * @memberof ManufacturerDataExchangeLogResponse
     */
    'logLevel': LogLevel;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeLogResponse
     */
    'payload': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeLogResponse
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeLogResponse
     */
    'sender': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeLogResponse
     */
    'stackTrace'?: string | null;
}
/**
 * 
 * @export
 * @interface ManufacturerDataExchangeLogResponseAllOf
 */
export interface ManufacturerDataExchangeLogResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeLogResponseAllOf
     */
    'manufacturerDataExchangeId': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeLogResponseAllOf
     */
    'messageId': string;
    /**
     * 
     * @type {LogLevel}
     * @memberof ManufacturerDataExchangeLogResponseAllOf
     */
    'logLevel': LogLevel;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeLogResponseAllOf
     */
    'payload': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeLogResponseAllOf
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeLogResponseAllOf
     */
    'sender': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeLogResponseAllOf
     */
    'stackTrace'?: string | null;
}
/**
 * 
 * @export
 * @interface ManufacturerDataExchangeResponse
 */
export interface ManufacturerDataExchangeResponse {
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {ExchangeDirection}
     * @memberof ManufacturerDataExchangeResponse
     */
    'direction': ExchangeDirection;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeResponse
     */
    'entity': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeResponse
     */
    'action': string;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerDataExchangeResponse
     */
    'itemCount': number;
    /**
     * 
     * @type {boolean}
     * @memberof ManufacturerDataExchangeResponse
     */
    'hasErrors': boolean;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerDataExchangeResponse
     */
    'errorCount': number;
}
/**
 * 
 * @export
 * @interface ManufacturerDataExchangeResponseAllOf
 */
export interface ManufacturerDataExchangeResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeResponseAllOf
     */
    'manufacturerId': string;
    /**
     * 
     * @type {ExchangeDirection}
     * @memberof ManufacturerDataExchangeResponseAllOf
     */
    'direction': ExchangeDirection;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeResponseAllOf
     */
    'entity': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerDataExchangeResponseAllOf
     */
    'action': string;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerDataExchangeResponseAllOf
     */
    'itemCount': number;
    /**
     * 
     * @type {boolean}
     * @memberof ManufacturerDataExchangeResponseAllOf
     */
    'hasErrors': boolean;
    /**
     * 
     * @type {number}
     * @memberof ManufacturerDataExchangeResponseAllOf
     */
    'errorCount': number;
}
/**
 * 
 * @export
 * @interface ManufacturerResponse
 */
export interface ManufacturerResponse {
    /**
     * 
     * @type {string}
     * @memberof ManufacturerResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ManufacturerResponse
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerResponse
     */
    'customerNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerResponse
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerResponse
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerResponse
     */
    'vatId': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerResponse
     */
    'registrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerResponse
     */
    'shortCode': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerResponse
     */
    'dataPrivacyUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerResponse
     */
    'termsAndConditionsUrl'?: string | null;
    /**
     * 
     * @type {AssetResponse}
     * @memberof ManufacturerResponse
     */
    'logoAsset'?: AssetResponse | null;
    /**
     * 
     * @type {ManufacturerAddressResponse}
     * @memberof ManufacturerResponse
     */
    'companyAddress'?: ManufacturerAddressResponse | null;
    /**
     * 
     * @type {Array<ManufacturerContactResponse>}
     * @memberof ManufacturerResponse
     */
    'manufacturerContacts'?: Array<ManufacturerContactResponse> | null;
    /**
     * 
     * @type {Array<ManufacturerSettingResponse>}
     * @memberof ManufacturerResponse
     */
    'manufacturerSettings'?: Array<ManufacturerSettingResponse> | null;
    /**
     * 
     * @type {Array<UserResponse>}
     * @memberof ManufacturerResponse
     */
    'users'?: Array<UserResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerResponse
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerResponse
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface ManufacturerResponseAllOf
 */
export interface ManufacturerResponseAllOf {
    /**
     * 
     * @type {boolean}
     * @memberof ManufacturerResponseAllOf
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerResponseAllOf
     */
    'customerNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerResponseAllOf
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerResponseAllOf
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerResponseAllOf
     */
    'vatId': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerResponseAllOf
     */
    'registrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerResponseAllOf
     */
    'shortCode': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerResponseAllOf
     */
    'dataPrivacyUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerResponseAllOf
     */
    'termsAndConditionsUrl'?: string | null;
    /**
     * 
     * @type {AssetResponse}
     * @memberof ManufacturerResponseAllOf
     */
    'logoAsset'?: AssetResponse | null;
    /**
     * 
     * @type {ManufacturerAddressResponse}
     * @memberof ManufacturerResponseAllOf
     */
    'companyAddress'?: ManufacturerAddressResponse | null;
    /**
     * 
     * @type {Array<ManufacturerContactResponse>}
     * @memberof ManufacturerResponseAllOf
     */
    'manufacturerContacts'?: Array<ManufacturerContactResponse> | null;
    /**
     * 
     * @type {Array<ManufacturerSettingResponse>}
     * @memberof ManufacturerResponseAllOf
     */
    'manufacturerSettings'?: Array<ManufacturerSettingResponse> | null;
    /**
     * 
     * @type {Array<UserResponse>}
     * @memberof ManufacturerResponseAllOf
     */
    'users'?: Array<UserResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerResponseAllOf
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerResponseAllOf
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ManufacturerSettingKey {
    BulkEditorImportDataMappings = 'BulkEditorImportDataMappings'
}

/**
 * 
 * @export
 * @interface ManufacturerSettingResponse
 */
export interface ManufacturerSettingResponse {
    /**
     * 
     * @type {string}
     * @memberof ManufacturerSettingResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerSettingResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerSettingResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {ManufacturerSettingKey}
     * @memberof ManufacturerSettingResponse
     */
    'key': ManufacturerSettingKey;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerSettingResponse
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerSettingResponse
     */
    'manufacturerId': string;
}
/**
 * 
 * @export
 * @interface ManufacturerSettingResponseAllOf
 */
export interface ManufacturerSettingResponseAllOf {
    /**
     * 
     * @type {ManufacturerSettingKey}
     * @memberof ManufacturerSettingResponseAllOf
     */
    'key': ManufacturerSettingKey;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerSettingResponseAllOf
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof ManufacturerSettingResponseAllOf
     */
    'manufacturerId': string;
}
/**
 * 
 * @export
 * @interface Name
 */
export interface Name {
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'infix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'lastName'?: string | null;
}
/**
 * 
 * @export
 * @interface NewMessageCallbackRequest
 */
export interface NewMessageCallbackRequest {
    /**
     * 
     * @type {string}
     * @memberof NewMessageCallbackRequest
     */
    'messageId': string;
    /**
     * 
     * @type {string}
     * @memberof NewMessageCallbackRequest
     */
    'messageType': string;
    /**
     * 
     * @type {string}
     * @memberof NewMessageCallbackRequest
     */
    'filePath': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum Operation {
    Create = 'Create',
    Read = 'Read',
    Update = 'Update',
    Delete = 'Delete',
    Invoke = 'Invoke',
    Export = 'Export'
}

/**
 * 
 * @export
 * @interface OrderIdNumberMappingResponse
 */
export interface OrderIdNumberMappingResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderIdNumberMappingResponse
     */
    'orderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderIdNumberMappingResponse
     */
    'orderNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderInvoiceAddressResponse
 */
export interface OrderInvoiceAddressResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAddressResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAddressResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAddressResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAddressResponse
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAddressResponse
     */
    'streetNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAddressResponse
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAddressResponse
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAddressResponse
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAddressResponse
     */
    'additionalAddressLine1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAddressResponse
     */
    'additionalAddressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAddressResponse
     */
    'countryId'?: string;
    /**
     * 
     * @type {CountryResponse}
     * @memberof OrderInvoiceAddressResponse
     */
    'country'?: CountryResponse | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAddressResponse
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAddressResponse
     */
    'department'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAddressResponse
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAddressResponse
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAddressResponse
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAddressResponse
     */
    'lastName': string;
    /**
     * 
     * @type {any}
     * @memberof OrderInvoiceAddressResponse
     */
    'shopCustomFields'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAddressResponse
     */
    'orderId': string;
}
/**
 * 
 * @export
 * @interface OrderInvoiceAddressResponseAllOf
 */
export interface OrderInvoiceAddressResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAddressResponseAllOf
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAddressResponseAllOf
     */
    'department'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAddressResponseAllOf
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAddressResponseAllOf
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAddressResponseAllOf
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAddressResponseAllOf
     */
    'lastName': string;
    /**
     * 
     * @type {any}
     * @memberof OrderInvoiceAddressResponseAllOf
     */
    'shopCustomFields'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceAddressResponseAllOf
     */
    'orderId': string;
}
/**
 * 
 * @export
 * @interface OrderInvoiceItemResponse
 */
export interface OrderInvoiceItemResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceItemResponse
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderInvoiceItemResponse
     */
    'content'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderInvoiceResponse
 */
export interface OrderInvoiceResponse {
    /**
     * 
     * @type {Array<OrderInvoiceItemResponse>}
     * @memberof OrderInvoiceResponse
     */
    'invoices'?: Array<OrderInvoiceItemResponse> | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderInvoiceResponse
     */
    'didSendMail'?: boolean;
}
/**
 * 
 * @export
 * @interface OrderPaymentTransactionResponse
 */
export interface OrderPaymentTransactionResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentTransactionResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentTransactionResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentTransactionResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentTransactionResponse
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentTransactionResponse
     */
    'paymentProviderId': string;
    /**
     * 
     * @type {PaymentProviderResponse}
     * @memberof OrderPaymentTransactionResponse
     */
    'paymentProvider'?: PaymentProviderResponse | null;
}
/**
 * 
 * @export
 * @interface OrderPaymentTransactionResponseAllOf
 */
export interface OrderPaymentTransactionResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentTransactionResponseAllOf
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentTransactionResponseAllOf
     */
    'paymentProviderId': string;
    /**
     * 
     * @type {PaymentProviderResponse}
     * @memberof OrderPaymentTransactionResponseAllOf
     */
    'paymentProvider'?: PaymentProviderResponse | null;
}
/**
 * 
 * @export
 * @interface OrderPositionPayloadResponse
 */
export interface OrderPositionPayloadResponse {
    /**
     * 
     * @type {boolean}
     * @memberof OrderPositionPayloadResponse
     */
    'isCloseout'?: boolean;
    /**
     * 
     * @type {Array<any>}
     * @memberof OrderPositionPayloadResponse
     */
    'customFields'?: Array<any> | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPositionPayloadResponse
     */
    'createdAt'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof OrderPositionPayloadResponse
     */
    'releaseDate'?: any | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPositionPayloadResponse
     */
    'isNew'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPositionPayloadResponse
     */
    'markAsTopseller'?: boolean | null;
    /**
     * 
     * @type {any}
     * @memberof OrderPositionPayloadResponse
     */
    'purchasePrices'?: any | null;
    /**
     * 
     * @type {OrderPurchasePriceResponse}
     * @memberof OrderPositionPayloadResponse
     */
    'purchasePrice'?: OrderPurchasePriceResponse | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPositionPayloadResponse
     */
    'productNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPositionPayloadResponse
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPositionPayloadResponse
     */
    'taxId'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof OrderPositionPayloadResponse
     */
    'tagIds'?: any | null;
    /**
     * 
     * @type {Array<any>}
     * @memberof OrderPositionPayloadResponse
     */
    'categoryIds'?: Array<any> | null;
    /**
     * 
     * @type {any}
     * @memberof OrderPositionPayloadResponse
     */
    'propertyIds'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof OrderPositionPayloadResponse
     */
    'optionIds'?: any | null;
    /**
     * 
     * @type {Array<any>}
     * @memberof OrderPositionPayloadResponse
     */
    'options'?: Array<any> | null;
    /**
     * 
     * @type {any}
     * @memberof OrderPositionPayloadResponse
     */
    'streamIds'?: any | null;
    /**
     * 
     * @type {Array<any>}
     * @memberof OrderPositionPayloadResponse
     */
    'features'?: Array<any> | null;
}
/**
 * 
 * @export
 * @interface OrderPositionPriceResponse
 */
export interface OrderPositionPriceResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderPositionPriceResponse
     */
    'unitPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderPositionPriceResponse
     */
    'totalPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderPositionPriceResponse
     */
    'quantity'?: number;
    /**
     * 
     * @type {Array<CalculatedTaxResponse>}
     * @memberof OrderPositionPriceResponse
     */
    'calculatedTaxes'?: Array<CalculatedTaxResponse> | null;
    /**
     * 
     * @type {Array<TaxRuleResponse>}
     * @memberof OrderPositionPriceResponse
     */
    'taxRules'?: Array<TaxRuleResponse> | null;
    /**
     * 
     * @type {any}
     * @memberof OrderPositionPriceResponse
     */
    'referencePrice'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof OrderPositionPriceResponse
     */
    'listPrice'?: any | null;
}
/**
 * 
 * @export
 * @interface OrderPositionPromotionDiscountCalculatedPriceResponse
 */
export interface OrderPositionPromotionDiscountCalculatedPriceResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderPositionPromotionDiscountCalculatedPriceResponse
     */
    'id'?: string;
    /**
     * 
     * @type {OrderPositionPromotionDiscountResponse}
     * @memberof OrderPositionPromotionDiscountCalculatedPriceResponse
     */
    'discount'?: OrderPositionPromotionDiscountResponse | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof OrderPositionPromotionDiscountCalculatedPriceResponse
     */
    'name'?: TranslatedTypeResponseOfString | null;
}
/**
 * 
 * @export
 * @interface OrderPositionPromotionDiscountResponse
 */
export interface OrderPositionPromotionDiscountResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderPositionPromotionDiscountResponse
     */
    'quantity'?: number;
    /**
     * 
     * @type {Array<TaxRuleResponse>}
     * @memberof OrderPositionPromotionDiscountResponse
     */
    'taxRules'?: Array<TaxRuleResponse> | null;
    /**
     * 
     * @type {number}
     * @memberof OrderPositionPromotionDiscountResponse
     */
    'unitPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderPositionPromotionDiscountResponse
     */
    'totalPrice'?: number;
    /**
     * 
     * @type {Array<CalculatedTaxResponse>}
     * @memberof OrderPositionPromotionDiscountResponse
     */
    'calculatedTaxes'?: Array<CalculatedTaxResponse> | null;
}
/**
 * 
 * @export
 * @interface OrderPositionResponse
 */
export interface OrderPositionResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderPositionResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPositionResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPositionResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPositionResponse
     */
    'parentOrderPositionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPositionResponse
     */
    'productId': string;
    /**
     * 
     * @type {SimpleProductResponse}
     * @memberof OrderPositionResponse
     */
    'product'?: SimpleProductResponse | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPositionResponse
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPositionResponse
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPositionResponse
     */
    'coverId'?: string | null;
    /**
     * 
     * @type {ProductAssetResponse}
     * @memberof OrderPositionResponse
     */
    'cover'?: ProductAssetResponse | null;
    /**
     * 
     * @type {number}
     * @memberof OrderPositionResponse
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPositionResponse
     */
    'unitPrice': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPositionResponse
     */
    'totalPrice': number;
    /**
     * 
     * @type {OrderPositionType}
     * @memberof OrderPositionResponse
     */
    'positionType': OrderPositionType;
    /**
     * 
     * @type {OrderPositionPayloadResponse}
     * @memberof OrderPositionResponse
     */
    'payload': OrderPositionPayloadResponse;
    /**
     * 
     * @type {OrderPositionPriceResponse}
     * @memberof OrderPositionResponse
     */
    'price': OrderPositionPriceResponse;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPositionResponse
     */
    'good': boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderPositionResponse
     */
    'position': number;
    /**
     * 
     * @type {string}
     * @memberof OrderPositionResponse
     */
    'orderReturnId'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof OrderPositionResponse
     */
    'shopCustomFields'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPositionResponse
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPositionResponse
     */
    'promotionId'?: string | null;
    /**
     * 
     * @type {PromotionResponse}
     * @memberof OrderPositionResponse
     */
    'promotion'?: PromotionResponse | null;
    /**
     * 
     * @type {PromotionCodeResponse}
     * @memberof OrderPositionResponse
     */
    'promotionCode'?: PromotionCodeResponse | null;
    /**
     * 
     * @type {Array<OrderPositionPromotionDiscountCalculatedPriceResponse>}
     * @memberof OrderPositionResponse
     */
    'promotionDiscountCalculatedPrices'?: Array<OrderPositionPromotionDiscountCalculatedPriceResponse> | null;
    /**
     * 
     * @type {Array<InlineOrderPositionUnitPrice>}
     * @memberof OrderPositionResponse
     */
    'orderPositionUnitPrices'?: Array<InlineOrderPositionUnitPrice> | null;
    /**
     * 
     * @type {ProductBundleDefinition}
     * @memberof OrderPositionResponse
     */
    'productBundleDefinition'?: ProductBundleDefinition | null;
}
/**
 * 
 * @export
 * @interface OrderPositionResponseAllOf
 */
export interface OrderPositionResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof OrderPositionResponseAllOf
     */
    'parentOrderPositionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPositionResponseAllOf
     */
    'productId': string;
    /**
     * 
     * @type {SimpleProductResponse}
     * @memberof OrderPositionResponseAllOf
     */
    'product'?: SimpleProductResponse | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPositionResponseAllOf
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPositionResponseAllOf
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPositionResponseAllOf
     */
    'coverId'?: string | null;
    /**
     * 
     * @type {ProductAssetResponse}
     * @memberof OrderPositionResponseAllOf
     */
    'cover'?: ProductAssetResponse | null;
    /**
     * 
     * @type {number}
     * @memberof OrderPositionResponseAllOf
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPositionResponseAllOf
     */
    'unitPrice': number;
    /**
     * 
     * @type {number}
     * @memberof OrderPositionResponseAllOf
     */
    'totalPrice': number;
    /**
     * 
     * @type {OrderPositionType}
     * @memberof OrderPositionResponseAllOf
     */
    'positionType': OrderPositionType;
    /**
     * 
     * @type {OrderPositionPayloadResponse}
     * @memberof OrderPositionResponseAllOf
     */
    'payload': OrderPositionPayloadResponse;
    /**
     * 
     * @type {OrderPositionPriceResponse}
     * @memberof OrderPositionResponseAllOf
     */
    'price': OrderPositionPriceResponse;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPositionResponseAllOf
     */
    'good': boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderPositionResponseAllOf
     */
    'position': number;
    /**
     * 
     * @type {string}
     * @memberof OrderPositionResponseAllOf
     */
    'orderReturnId'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof OrderPositionResponseAllOf
     */
    'shopCustomFields'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof OrderPositionResponseAllOf
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPositionResponseAllOf
     */
    'promotionId'?: string | null;
    /**
     * 
     * @type {PromotionResponse}
     * @memberof OrderPositionResponseAllOf
     */
    'promotion'?: PromotionResponse | null;
    /**
     * 
     * @type {PromotionCodeResponse}
     * @memberof OrderPositionResponseAllOf
     */
    'promotionCode'?: PromotionCodeResponse | null;
    /**
     * 
     * @type {Array<OrderPositionPromotionDiscountCalculatedPriceResponse>}
     * @memberof OrderPositionResponseAllOf
     */
    'promotionDiscountCalculatedPrices'?: Array<OrderPositionPromotionDiscountCalculatedPriceResponse> | null;
    /**
     * 
     * @type {Array<InlineOrderPositionUnitPrice>}
     * @memberof OrderPositionResponseAllOf
     */
    'orderPositionUnitPrices'?: Array<InlineOrderPositionUnitPrice> | null;
    /**
     * 
     * @type {ProductBundleDefinition}
     * @memberof OrderPositionResponseAllOf
     */
    'productBundleDefinition'?: ProductBundleDefinition | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum OrderPositionType {
    Credit = 'Credit',
    Product = 'Product',
    Custom = 'Custom',
    Promotion = 'Promotion',
    LoyaltyDiscount = 'LoyaltyDiscount'
}

/**
 * 
 * @export
 * @interface OrderPositionUnitPrice
 */
export interface OrderPositionUnitPrice {
    /**
     * 
     * @type {string}
     * @memberof OrderPositionUnitPrice
     */
    'id': string;
    /**
     * 
     * @type {Price}
     * @memberof OrderPositionUnitPrice
     */
    'unitPrice': Price;
    /**
     * 
     * @type {Price}
     * @memberof OrderPositionUnitPrice
     */
    'discountedUnitPrice'?: Price | null;
}
/**
 * 
 * @export
 * @interface OrderPositionUnitRefundAmount
 */
export interface OrderPositionUnitRefundAmount {
    /**
     * 
     * @type {string}
     * @memberof OrderPositionUnitRefundAmount
     */
    'id': string;
    /**
     * 
     * @type {Price}
     * @memberof OrderPositionUnitRefundAmount
     */
    'unitPrice': Price;
}
/**
 * 
 * @export
 * @interface OrderPositionUnitRefundAmountResponse
 */
export interface OrderPositionUnitRefundAmountResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderPositionUnitRefundAmountResponse
     */
    'id': string;
    /**
     * 
     * @type {Price}
     * @memberof OrderPositionUnitRefundAmountResponse
     */
    'unitPrice': Price;
}
/**
 * 
 * @export
 * @interface OrderPriceResponse
 */
export interface OrderPriceResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderPriceResponse
     */
    'netPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderPriceResponse
     */
    'totalPrice'?: number;
    /**
     * 
     * @type {Array<CalculatedTaxResponse>}
     * @memberof OrderPriceResponse
     */
    'calculatedTaxes'?: Array<CalculatedTaxResponse> | null;
    /**
     * 
     * @type {Array<TaxRuleResponse>}
     * @memberof OrderPriceResponse
     */
    'taxRules'?: Array<TaxRuleResponse> | null;
    /**
     * 
     * @type {number}
     * @memberof OrderPriceResponse
     */
    'positionPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderPriceResponse
     */
    'rawTotal'?: number;
    /**
     * 
     * @type {any}
     * @memberof OrderPriceResponse
     */
    'taxStatus'?: any | null;
}
/**
 * 
 * @export
 * @interface OrderPurchasePriceResponse
 */
export interface OrderPurchasePriceResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderPurchasePriceResponse
     */
    'currencyId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderPurchasePriceResponse
     */
    'net'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderPurchasePriceResponse
     */
    'gross'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderPurchasePriceResponse
     */
    'linked'?: boolean;
    /**
     * 
     * @type {any}
     * @memberof OrderPurchasePriceResponse
     */
    'listPrice'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof OrderPurchasePriceResponse
     */
    'percentage'?: any | null;
    /**
     * 
     * @type {Array<any>}
     * @memberof OrderPurchasePriceResponse
     */
    'extensions'?: Array<any> | null;
}
/**
 * 
 * @export
 * @interface OrderReceiptDocumentLayoutData
 */
export interface OrderReceiptDocumentLayoutData {
    /**
     * 
     * @type {string}
     * @memberof OrderReceiptDocumentLayoutData
     */
    'logo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReceiptDocumentLayoutData
     */
    'logoMimeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReceiptDocumentLayoutData
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReceiptDocumentLayoutData
     */
    'companySenderAddressLine'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReceiptDocumentLayoutData
     */
    'companyAddressStreet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReceiptDocumentLayoutData
     */
    'companyAddressZipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReceiptDocumentLayoutData
     */
    'companyAddressCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReceiptDocumentLayoutData
     */
    'companyAddressCountry'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderReceiptDocumentLayoutData
     */
    'additionalContactLines'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderReceiptDocumentLayoutData
     */
    'additionalBottomTextLines'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof OrderReceiptDocumentLayoutData
     */
    'footerColumnCount'?: number;
    /**
     * 
     * @type {Array<FooterColumnWidth>}
     * @memberof OrderReceiptDocumentLayoutData
     */
    'footerColumnsWidths'?: Array<FooterColumnWidth> | null;
    /**
     * 
     * @type {Array<FooterContent>}
     * @memberof OrderReceiptDocumentLayoutData
     */
    'footerContents'?: Array<FooterContent> | null;
}
/**
 * 
 * @export
 * @interface OrderReceiptDocumentLayoutDataResponse
 */
export interface OrderReceiptDocumentLayoutDataResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderReceiptDocumentLayoutDataResponse
     */
    'logo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReceiptDocumentLayoutDataResponse
     */
    'logoMimeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReceiptDocumentLayoutDataResponse
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReceiptDocumentLayoutDataResponse
     */
    'companySenderAddressLine'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReceiptDocumentLayoutDataResponse
     */
    'companyAddressStreet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReceiptDocumentLayoutDataResponse
     */
    'companyAddressZipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReceiptDocumentLayoutDataResponse
     */
    'companyAddressCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReceiptDocumentLayoutDataResponse
     */
    'companyAddressCountry'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderReceiptDocumentLayoutDataResponse
     */
    'additionalContactLines'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderReceiptDocumentLayoutDataResponse
     */
    'additionalBottomTextLines'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof OrderReceiptDocumentLayoutDataResponse
     */
    'footerColumnCount'?: number;
    /**
     * 
     * @type {Array<FooterColumnWidth>}
     * @memberof OrderReceiptDocumentLayoutDataResponse
     */
    'footerColumnsWidths'?: Array<FooterColumnWidth> | null;
    /**
     * 
     * @type {Array<FooterContent>}
     * @memberof OrderReceiptDocumentLayoutDataResponse
     */
    'footerContents'?: Array<FooterContent> | null;
}
/**
 * 
 * @export
 * @interface OrderReceiptResponse
 */
export interface OrderReceiptResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderReceiptResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderReceiptResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof OrderReceiptResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {DocumentType}
     * @memberof OrderReceiptResponse
     */
    'type': DocumentType;
    /**
     * 
     * @type {string}
     * @memberof OrderReceiptResponse
     */
    'documentDataId'?: string | null;
    /**
     * 
     * @type {DocumentDataResponse}
     * @memberof OrderReceiptResponse
     */
    'documentData'?: DocumentDataResponse | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReceiptResponse
     */
    'orderId': string;
}
/**
 * 
 * @export
 * @interface OrderReceiptResponseAllOf
 */
export interface OrderReceiptResponseAllOf {
    /**
     * 
     * @type {DocumentType}
     * @memberof OrderReceiptResponseAllOf
     */
    'type': DocumentType;
    /**
     * 
     * @type {string}
     * @memberof OrderReceiptResponseAllOf
     */
    'documentDataId'?: string | null;
    /**
     * 
     * @type {DocumentDataResponse}
     * @memberof OrderReceiptResponseAllOf
     */
    'documentData'?: DocumentDataResponse | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReceiptResponseAllOf
     */
    'orderId': string;
}
/**
 * 
 * @export
 * @interface OrderResponse
 */
export interface OrderResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'shopReferenceId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'customerId': string;
    /**
     * 
     * @type {CustomerResponse}
     * @memberof OrderResponse
     */
    'customer'?: CustomerResponse | null;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'orderNumber': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'currencyId': string;
    /**
     * 
     * @type {CurrencyResponse}
     * @memberof OrderResponse
     */
    'currency'?: CurrencyResponse | null;
    /**
     * 
     * @type {number}
     * @memberof OrderResponse
     */
    'currencyFactor': number;
    /**
     * 
     * @type {OrderInvoiceAddressResponse}
     * @memberof OrderResponse
     */
    'orderInvoiceAddress': OrderInvoiceAddressResponse;
    /**
     * 
     * @type {OrderShippingAddressResponse}
     * @memberof OrderResponse
     */
    'orderShippingAddress': OrderShippingAddressResponse;
    /**
     * 
     * @type {OrderPriceResponse}
     * @memberof OrderResponse
     */
    'price': OrderPriceResponse;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'orderDateTime': string;
    /**
     * 
     * @type {number}
     * @memberof OrderResponse
     */
    'amountTotal': number;
    /**
     * 
     * @type {number}
     * @memberof OrderResponse
     */
    'amountNet': number;
    /**
     * 
     * @type {number}
     * @memberof OrderResponse
     */
    'positionPrice': number;
    /**
     * 
     * @type {OrderShippingCostsResponse}
     * @memberof OrderResponse
     */
    'shippingCosts': OrderShippingCostsResponse;
    /**
     * 
     * @type {number}
     * @memberof OrderResponse
     */
    'shippingTotal': number;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'deepLinkCode': string;
    /**
     * 
     * @type {any}
     * @memberof OrderResponse
     */
    'shopCustomFields'?: any | null;
    /**
     * 
     * @type {Array<OrderPositionResponse>}
     * @memberof OrderResponse
     */
    'orderPositions': Array<OrderPositionResponse>;
    /**
     * 
     * @type {Array<OrderReturnResponse>}
     * @memberof OrderResponse
     */
    'orderReturns'?: Array<OrderReturnResponse> | null;
    /**
     * 
     * @type {Array<OrderStateHistoryResponse>}
     * @memberof OrderResponse
     */
    'orderStateHistories'?: Array<OrderStateHistoryResponse> | null;
    /**
     * 
     * @type {Array<OrderShippingDetailResponse>}
     * @memberof OrderResponse
     */
    'orderShippingDetails'?: Array<OrderShippingDetailResponse> | null;
    /**
     * 
     * @type {Array<OrderReceiptResponse>}
     * @memberof OrderResponse
     */
    'orderReceipts'?: Array<OrderReceiptResponse> | null;
    /**
     * 
     * @type {Array<AdyenPaymentRefundResponse>}
     * @memberof OrderResponse
     */
    'adyenPaymentRefunds'?: Array<AdyenPaymentRefundResponse> | null;
    /**
     * 
     * @type {number}
     * @memberof OrderResponse
     */
    'refundedShippingCosts'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderResponse
     */
    'commission': number;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {SimpleManufacturerResponse}
     * @memberof OrderResponse
     */
    'manufacturer'?: SimpleManufacturerResponse | null;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'warehouseId': string;
    /**
     * 
     * @type {WarehouseResponse}
     * @memberof OrderResponse
     */
    'warehouse'?: WarehouseResponse | null;
    /**
     * 
     * @type {number}
     * @memberof OrderResponse
     */
    'sequenceNumber': number;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'originOrderId'?: string | null;
    /**
     * 
     * @type {Array<OrderIdNumberMappingResponse>}
     * @memberof OrderResponse
     */
    'splitOrders'?: Array<OrderIdNumberMappingResponse> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderResponse
     */
    'splitOrderIds'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof OrderResponse
     */
    'fiegeSalesOrderNumber'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderResponse
     */
    'initialPaymentMethodBrand'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderResponse
     */
    'overallSplitsTotalPrice'?: number | null;
    /**
     * 
     * @type {OrderStateHistoryResponse}
     * @memberof OrderResponse
     */
    'currentOrderState': OrderStateHistoryResponse;
    /**
     * 
     * @type {OrderStateHistoryResponse}
     * @memberof OrderResponse
     */
    'currentTransactionState': OrderStateHistoryResponse;
    /**
     * 
     * @type {OrderStateHistoryResponse}
     * @memberof OrderResponse
     */
    'currentShippingState': OrderStateHistoryResponse;
}
/**
 * 
 * @export
 * @interface OrderResponseAllOf
 */
export interface OrderResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAllOf
     */
    'shopReferenceId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAllOf
     */
    'customerId': string;
    /**
     * 
     * @type {CustomerResponse}
     * @memberof OrderResponseAllOf
     */
    'customer'?: CustomerResponse | null;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAllOf
     */
    'orderNumber': string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAllOf
     */
    'currencyId': string;
    /**
     * 
     * @type {CurrencyResponse}
     * @memberof OrderResponseAllOf
     */
    'currency'?: CurrencyResponse | null;
    /**
     * 
     * @type {number}
     * @memberof OrderResponseAllOf
     */
    'currencyFactor': number;
    /**
     * 
     * @type {OrderInvoiceAddressResponse}
     * @memberof OrderResponseAllOf
     */
    'orderInvoiceAddress': OrderInvoiceAddressResponse;
    /**
     * 
     * @type {OrderShippingAddressResponse}
     * @memberof OrderResponseAllOf
     */
    'orderShippingAddress': OrderShippingAddressResponse;
    /**
     * 
     * @type {OrderPriceResponse}
     * @memberof OrderResponseAllOf
     */
    'price': OrderPriceResponse;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAllOf
     */
    'orderDateTime': string;
    /**
     * 
     * @type {number}
     * @memberof OrderResponseAllOf
     */
    'amountTotal': number;
    /**
     * 
     * @type {number}
     * @memberof OrderResponseAllOf
     */
    'amountNet': number;
    /**
     * 
     * @type {number}
     * @memberof OrderResponseAllOf
     */
    'positionPrice': number;
    /**
     * 
     * @type {OrderShippingCostsResponse}
     * @memberof OrderResponseAllOf
     */
    'shippingCosts': OrderShippingCostsResponse;
    /**
     * 
     * @type {number}
     * @memberof OrderResponseAllOf
     */
    'shippingTotal': number;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAllOf
     */
    'deepLinkCode': string;
    /**
     * 
     * @type {any}
     * @memberof OrderResponseAllOf
     */
    'shopCustomFields'?: any | null;
    /**
     * 
     * @type {Array<OrderPositionResponse>}
     * @memberof OrderResponseAllOf
     */
    'orderPositions': Array<OrderPositionResponse>;
    /**
     * 
     * @type {Array<OrderReturnResponse>}
     * @memberof OrderResponseAllOf
     */
    'orderReturns'?: Array<OrderReturnResponse> | null;
    /**
     * 
     * @type {Array<OrderStateHistoryResponse>}
     * @memberof OrderResponseAllOf
     */
    'orderStateHistories'?: Array<OrderStateHistoryResponse> | null;
    /**
     * 
     * @type {Array<OrderShippingDetailResponse>}
     * @memberof OrderResponseAllOf
     */
    'orderShippingDetails'?: Array<OrderShippingDetailResponse> | null;
    /**
     * 
     * @type {Array<OrderReceiptResponse>}
     * @memberof OrderResponseAllOf
     */
    'orderReceipts'?: Array<OrderReceiptResponse> | null;
    /**
     * 
     * @type {Array<AdyenPaymentRefundResponse>}
     * @memberof OrderResponseAllOf
     */
    'adyenPaymentRefunds'?: Array<AdyenPaymentRefundResponse> | null;
    /**
     * 
     * @type {number}
     * @memberof OrderResponseAllOf
     */
    'refundedShippingCosts'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderResponseAllOf
     */
    'commission': number;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAllOf
     */
    'manufacturerId': string;
    /**
     * 
     * @type {SimpleManufacturerResponse}
     * @memberof OrderResponseAllOf
     */
    'manufacturer'?: SimpleManufacturerResponse | null;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAllOf
     */
    'warehouseId': string;
    /**
     * 
     * @type {WarehouseResponse}
     * @memberof OrderResponseAllOf
     */
    'warehouse'?: WarehouseResponse | null;
    /**
     * 
     * @type {number}
     * @memberof OrderResponseAllOf
     */
    'sequenceNumber': number;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAllOf
     */
    'originOrderId'?: string | null;
    /**
     * 
     * @type {Array<OrderIdNumberMappingResponse>}
     * @memberof OrderResponseAllOf
     */
    'splitOrders'?: Array<OrderIdNumberMappingResponse> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderResponseAllOf
     */
    'splitOrderIds'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof OrderResponseAllOf
     */
    'fiegeSalesOrderNumber'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseAllOf
     */
    'initialPaymentMethodBrand'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderResponseAllOf
     */
    'overallSplitsTotalPrice'?: number | null;
    /**
     * 
     * @type {OrderStateHistoryResponse}
     * @memberof OrderResponseAllOf
     */
    'currentOrderState': OrderStateHistoryResponse;
    /**
     * 
     * @type {OrderStateHistoryResponse}
     * @memberof OrderResponseAllOf
     */
    'currentTransactionState': OrderStateHistoryResponse;
    /**
     * 
     * @type {OrderStateHistoryResponse}
     * @memberof OrderResponseAllOf
     */
    'currentShippingState': OrderStateHistoryResponse;
}
/**
 * 
 * @export
 * @interface OrderReturnAnnouncementPositionResponse
 */
export interface OrderReturnAnnouncementPositionResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderReturnAnnouncementPositionResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnAnnouncementPositionResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnAnnouncementPositionResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderReturnAnnouncementPositionResponse
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnAnnouncementPositionResponse
     */
    'orderShippingDetailPositionId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnAnnouncementPositionResponse
     */
    'orderReturnAnnouncementId': string;
    /**
     * 
     * @type {Array<OrderReturnReasonResponse>}
     * @memberof OrderReturnAnnouncementPositionResponse
     */
    'orderReturnReasons'?: Array<OrderReturnReasonResponse> | null;
}
/**
 * 
 * @export
 * @interface OrderReturnAnnouncementPositionResponseAllOf
 */
export interface OrderReturnAnnouncementPositionResponseAllOf {
    /**
     * 
     * @type {number}
     * @memberof OrderReturnAnnouncementPositionResponseAllOf
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnAnnouncementPositionResponseAllOf
     */
    'orderShippingDetailPositionId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnAnnouncementPositionResponseAllOf
     */
    'orderReturnAnnouncementId': string;
    /**
     * 
     * @type {Array<OrderReturnReasonResponse>}
     * @memberof OrderReturnAnnouncementPositionResponseAllOf
     */
    'orderReturnReasons'?: Array<OrderReturnReasonResponse> | null;
}
/**
 * 
 * @export
 * @interface OrderReturnAnnouncementResponse
 */
export interface OrderReturnAnnouncementResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderReturnAnnouncementResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnAnnouncementResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnAnnouncementResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {Array<OrderReturnAnnouncementPositionResponse>}
     * @memberof OrderReturnAnnouncementResponse
     */
    'orderReturnAnnouncementPositions'?: Array<OrderReturnAnnouncementPositionResponse> | null;
    /**
     * 
     * @type {ReturnLabelData}
     * @memberof OrderReturnAnnouncementResponse
     */
    'labelData': ReturnLabelData;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnAnnouncementResponse
     */
    'customFields'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnAnnouncementResponse
     */
    'orderShippingDetailId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnAnnouncementResponse
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnAnnouncementResponse
     */
    'shippingProviderId': string;
    /**
     * 
     * @type {ShippingProviderResponse}
     * @memberof OrderReturnAnnouncementResponse
     */
    'shippingProvider'?: ShippingProviderResponse | null;
}
/**
 * 
 * @export
 * @interface OrderReturnAnnouncementResponseAllOf
 */
export interface OrderReturnAnnouncementResponseAllOf {
    /**
     * 
     * @type {Array<OrderReturnAnnouncementPositionResponse>}
     * @memberof OrderReturnAnnouncementResponseAllOf
     */
    'orderReturnAnnouncementPositions'?: Array<OrderReturnAnnouncementPositionResponse> | null;
    /**
     * 
     * @type {ReturnLabelData}
     * @memberof OrderReturnAnnouncementResponseAllOf
     */
    'labelData': ReturnLabelData;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnAnnouncementResponseAllOf
     */
    'customFields'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnAnnouncementResponseAllOf
     */
    'orderShippingDetailId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnAnnouncementResponseAllOf
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnAnnouncementResponseAllOf
     */
    'shippingProviderId': string;
    /**
     * 
     * @type {ShippingProviderResponse}
     * @memberof OrderReturnAnnouncementResponseAllOf
     */
    'shippingProvider'?: ShippingProviderResponse | null;
}
/**
 * 
 * @export
 * @interface OrderReturnPositionResponse
 */
export interface OrderReturnPositionResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderReturnPositionResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnPositionResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnPositionResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderReturnPositionResponse
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnPositionResponse
     */
    'orderPositionId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnPositionResponse
     */
    'orderReturnId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnPositionResponse
     */
    'batchNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnPositionResponse
     */
    'bestBefore'?: string | null;
    /**
     * 
     * @type {LogisticsReturnReason}
     * @memberof OrderReturnPositionResponse
     */
    'logisticsReturnReason'?: LogisticsReturnReason | null;
    /**
     * 
     * @type {ProductStockQuality}
     * @memberof OrderReturnPositionResponse
     */
    'quality'?: ProductStockQuality | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnPositionResponse
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnPositionResponse
     */
    'returnDate'?: string | null;
    /**
     * 
     * @type {Array<AdyenPaymentRefundDetailResponse>}
     * @memberof OrderReturnPositionResponse
     */
    'adyenPaymentRefundDetails'?: Array<AdyenPaymentRefundDetailResponse> | null;
}
/**
 * 
 * @export
 * @interface OrderReturnPositionResponseAllOf
 */
export interface OrderReturnPositionResponseAllOf {
    /**
     * 
     * @type {number}
     * @memberof OrderReturnPositionResponseAllOf
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnPositionResponseAllOf
     */
    'orderPositionId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnPositionResponseAllOf
     */
    'orderReturnId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnPositionResponseAllOf
     */
    'batchNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnPositionResponseAllOf
     */
    'bestBefore'?: string | null;
    /**
     * 
     * @type {LogisticsReturnReason}
     * @memberof OrderReturnPositionResponseAllOf
     */
    'logisticsReturnReason'?: LogisticsReturnReason | null;
    /**
     * 
     * @type {ProductStockQuality}
     * @memberof OrderReturnPositionResponseAllOf
     */
    'quality'?: ProductStockQuality | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnPositionResponseAllOf
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnPositionResponseAllOf
     */
    'returnDate'?: string | null;
    /**
     * 
     * @type {Array<AdyenPaymentRefundDetailResponse>}
     * @memberof OrderReturnPositionResponseAllOf
     */
    'adyenPaymentRefundDetails'?: Array<AdyenPaymentRefundDetailResponse> | null;
}
/**
 * 
 * @export
 * @interface OrderReturnReasonResponse
 */
export interface OrderReturnReasonResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderReturnReasonResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnReasonResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnReasonResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnReasonResponse
     */
    'reasonText'?: string | null;
    /**
     * 
     * @type {ReturnReason}
     * @memberof OrderReturnReasonResponse
     */
    'returnReason'?: ReturnReason;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnReasonResponse
     */
    'orderReturnAnnouncementPositionId'?: string;
}
/**
 * 
 * @export
 * @interface OrderReturnReasonResponseAllOf
 */
export interface OrderReturnReasonResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof OrderReturnReasonResponseAllOf
     */
    'reasonText'?: string | null;
    /**
     * 
     * @type {ReturnReason}
     * @memberof OrderReturnReasonResponseAllOf
     */
    'returnReason'?: ReturnReason;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnReasonResponseAllOf
     */
    'orderReturnAnnouncementPositionId'?: string;
}
/**
 * 
 * @export
 * @interface OrderReturnResponse
 */
export interface OrderReturnResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderReturnResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {Array<OrderReturnPositionResponse>}
     * @memberof OrderReturnResponse
     */
    'orderReturnPositions': Array<OrderReturnPositionResponse>;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnResponse
     */
    'orderId': string;
    /**
     * 
     * @type {OrderReturnState}
     * @memberof OrderReturnResponse
     */
    'state': OrderReturnState;
    /**
     * 
     * @type {boolean}
     * @memberof OrderReturnResponse
     */
    'locked'?: boolean;
}
/**
 * 
 * @export
 * @interface OrderReturnResponseAllOf
 */
export interface OrderReturnResponseAllOf {
    /**
     * 
     * @type {Array<OrderReturnPositionResponse>}
     * @memberof OrderReturnResponseAllOf
     */
    'orderReturnPositions': Array<OrderReturnPositionResponse>;
    /**
     * 
     * @type {string}
     * @memberof OrderReturnResponseAllOf
     */
    'orderId': string;
    /**
     * 
     * @type {OrderReturnState}
     * @memberof OrderReturnResponseAllOf
     */
    'state': OrderReturnState;
    /**
     * 
     * @type {boolean}
     * @memberof OrderReturnResponseAllOf
     */
    'locked'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum OrderReturnState {
    Open = 'Open',
    PartiallyReturned = 'PartiallyReturned',
    PartiallyReturnedWithDamages = 'PartiallyReturnedWithDamages',
    Returned = 'Returned',
    ReturnedWithDamages = 'ReturnedWithDamages',
    Closed = 'Closed'
}

/**
 * 
 * @export
 * @interface OrderShippingAddressResponse
 */
export interface OrderShippingAddressResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderShippingAddressResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingAddressResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingAddressResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingAddressResponse
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingAddressResponse
     */
    'streetNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingAddressResponse
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingAddressResponse
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingAddressResponse
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingAddressResponse
     */
    'additionalAddressLine1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingAddressResponse
     */
    'additionalAddressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingAddressResponse
     */
    'countryId'?: string;
    /**
     * 
     * @type {CountryResponse}
     * @memberof OrderShippingAddressResponse
     */
    'country'?: CountryResponse | null;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingAddressResponse
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingAddressResponse
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingAddressResponse
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingAddressResponse
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingAddressResponse
     */
    'orderId'?: string;
}
/**
 * 
 * @export
 * @interface OrderShippingAddressResponseAllOf
 */
export interface OrderShippingAddressResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof OrderShippingAddressResponseAllOf
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingAddressResponseAllOf
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingAddressResponseAllOf
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingAddressResponseAllOf
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingAddressResponseAllOf
     */
    'orderId'?: string;
}
/**
 * 
 * @export
 * @interface OrderShippingCostsResponse
 */
export interface OrderShippingCostsResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderShippingCostsResponse
     */
    'unitPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderShippingCostsResponse
     */
    'totalPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderShippingCostsResponse
     */
    'quantity'?: number;
    /**
     * 
     * @type {Array<CalculatedTaxResponse>}
     * @memberof OrderShippingCostsResponse
     */
    'calculatedTaxes'?: Array<CalculatedTaxResponse> | null;
    /**
     * 
     * @type {Array<TaxRuleResponse>}
     * @memberof OrderShippingCostsResponse
     */
    'taxRules'?: Array<TaxRuleResponse> | null;
    /**
     * 
     * @type {any}
     * @memberof OrderShippingCostsResponse
     */
    'referencePrice'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof OrderShippingCostsResponse
     */
    'listPrice'?: any | null;
}
/**
 * 
 * @export
 * @interface OrderShippingDetailPositionResponse
 */
export interface OrderShippingDetailPositionResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailPositionResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailPositionResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailPositionResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderShippingDetailPositionResponse
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailPositionResponse
     */
    'batchNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailPositionResponse
     */
    'bestBefore'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailPositionResponse
     */
    'orderPositionId'?: string;
    /**
     * 
     * @type {OrderPositionResponse}
     * @memberof OrderShippingDetailPositionResponse
     */
    'orderPosition'?: OrderPositionResponse | null;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailPositionResponse
     */
    'orderShippingDetailId'?: string;
}
/**
 * 
 * @export
 * @interface OrderShippingDetailPositionResponseAllOf
 */
export interface OrderShippingDetailPositionResponseAllOf {
    /**
     * 
     * @type {number}
     * @memberof OrderShippingDetailPositionResponseAllOf
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailPositionResponseAllOf
     */
    'batchNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailPositionResponseAllOf
     */
    'bestBefore'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailPositionResponseAllOf
     */
    'orderPositionId'?: string;
    /**
     * 
     * @type {OrderPositionResponse}
     * @memberof OrderShippingDetailPositionResponseAllOf
     */
    'orderPosition'?: OrderPositionResponse | null;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailPositionResponseAllOf
     */
    'orderShippingDetailId'?: string;
}
/**
 * 
 * @export
 * @interface OrderShippingDetailResponse
 */
export interface OrderShippingDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailResponse
     */
    'trackingCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderShippingDetailResponse
     */
    'weight'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailResponse
     */
    'shippingDateEarliest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailResponse
     */
    'shippingDateLatest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailResponse
     */
    'transmissionDate'?: string | null;
    /**
     * 
     * @type {Array<OrderShippingDetailPositionResponse>}
     * @memberof OrderShippingDetailResponse
     */
    'positions'?: Array<OrderShippingDetailPositionResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailResponse
     */
    'shippingProviderId': string;
    /**
     * 
     * @type {ShippingProviderResponse}
     * @memberof OrderShippingDetailResponse
     */
    'shippingProvider'?: ShippingProviderResponse | null;
    /**
     * 
     * @type {Array<OrderReturnAnnouncementResponse>}
     * @memberof OrderShippingDetailResponse
     */
    'orderReturnAnnouncements'?: Array<OrderReturnAnnouncementResponse> | null;
}
/**
 * 
 * @export
 * @interface OrderShippingDetailResponseAllOf
 */
export interface OrderShippingDetailResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailResponseAllOf
     */
    'trackingCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderShippingDetailResponseAllOf
     */
    'weight'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailResponseAllOf
     */
    'shippingDateEarliest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailResponseAllOf
     */
    'shippingDateLatest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailResponseAllOf
     */
    'transmissionDate'?: string | null;
    /**
     * 
     * @type {Array<OrderShippingDetailPositionResponse>}
     * @memberof OrderShippingDetailResponseAllOf
     */
    'positions'?: Array<OrderShippingDetailPositionResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof OrderShippingDetailResponseAllOf
     */
    'shippingProviderId': string;
    /**
     * 
     * @type {ShippingProviderResponse}
     * @memberof OrderShippingDetailResponseAllOf
     */
    'shippingProvider'?: ShippingProviderResponse | null;
    /**
     * 
     * @type {Array<OrderReturnAnnouncementResponse>}
     * @memberof OrderShippingDetailResponseAllOf
     */
    'orderReturnAnnouncements'?: Array<OrderReturnAnnouncementResponse> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum OrderStateContext {
    Order = 'Order',
    Shipping = 'Shipping',
    Transaction = 'Transaction'
}

/**
 * 
 * @export
 * @interface OrderStateHistoryResponse
 */
export interface OrderStateHistoryResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderStateHistoryResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderStateHistoryResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof OrderStateHistoryResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {OrderStateContext}
     * @memberof OrderStateHistoryResponse
     */
    'orderStateContext': OrderStateContext;
    /**
     * 
     * @type {string}
     * @memberof OrderStateHistoryResponse
     */
    'orderStateId': string;
    /**
     * 
     * @type {OrderStateResponse}
     * @memberof OrderStateHistoryResponse
     */
    'orderState'?: OrderStateResponse | null;
    /**
     * 
     * @type {string}
     * @memberof OrderStateHistoryResponse
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderStateHistoryResponse
     */
    'userId'?: string | null;
    /**
     * 
     * @type {Array<OrderStateResponse>}
     * @memberof OrderStateHistoryResponse
     */
    'transitions': Array<OrderStateResponse>;
}
/**
 * 
 * @export
 * @interface OrderStateHistoryResponseAllOf
 */
export interface OrderStateHistoryResponseAllOf {
    /**
     * 
     * @type {OrderStateContext}
     * @memberof OrderStateHistoryResponseAllOf
     */
    'orderStateContext': OrderStateContext;
    /**
     * 
     * @type {string}
     * @memberof OrderStateHistoryResponseAllOf
     */
    'orderStateId': string;
    /**
     * 
     * @type {OrderStateResponse}
     * @memberof OrderStateHistoryResponseAllOf
     */
    'orderState'?: OrderStateResponse | null;
    /**
     * 
     * @type {string}
     * @memberof OrderStateHistoryResponseAllOf
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderStateHistoryResponseAllOf
     */
    'userId'?: string | null;
    /**
     * 
     * @type {Array<OrderStateResponse>}
     * @memberof OrderStateHistoryResponseAllOf
     */
    'transitions': Array<OrderStateResponse>;
}
/**
 * 
 * @export
 * @interface OrderStateResponse
 */
export interface OrderStateResponse {
    /**
     * 
     * @type {string}
     * @memberof OrderStateResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderStateResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof OrderStateResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {OrderStateTechnicalName}
     * @memberof OrderStateResponse
     */
    'technicalName': OrderStateTechnicalName;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof OrderStateResponse
     */
    'name': TranslatedTypeResponseOfString;
}
/**
 * 
 * @export
 * @interface OrderStateResponseAllOf
 */
export interface OrderStateResponseAllOf {
    /**
     * 
     * @type {OrderStateTechnicalName}
     * @memberof OrderStateResponseAllOf
     */
    'technicalName': OrderStateTechnicalName;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof OrderStateResponseAllOf
     */
    'name': TranslatedTypeResponseOfString;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum OrderStateTechnicalName {
    Authorized = 'Authorized',
    Cancelled = 'Cancelled',
    Chargeback = 'Chargeback',
    Completed = 'Completed',
    Failed = 'Failed',
    InProgress = 'InProgress',
    Open = 'Open',
    Paid = 'Paid',
    PaidPartially = 'PaidPartially',
    Pending = 'Pending',
    Refunded = 'Refunded',
    RefundedPartially = 'RefundedPartially',
    Reminded = 'Reminded',
    Returned = 'Returned',
    ReturnedPartially = 'ReturnedPartially',
    Shipped = 'Shipped',
    ShippedPartially = 'ShippedPartially',
    Unconfirmed = 'Unconfirmed'
}

/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * 
     * @type {TypeEnum19}
     * @memberof Organization
     */
    'type'?: TypeEnum19 | null;
    /**
     * 
     * @type {VatAbsenceReasonEnum}
     * @memberof Organization
     */
    'vatAbsenceReason'?: VatAbsenceReasonEnum | null;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'countryOfGoverningLaw'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'dateOfIncorporation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'doingBusinessAs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'legalName'?: string | null;
    /**
     * 
     * @type {PhoneNumber}
     * @memberof Organization
     */
    'phone'?: PhoneNumber | null;
    /**
     * 
     * @type {Address2}
     * @memberof Organization
     */
    'principalPlaceOfBusiness'?: Address2 | null;
    /**
     * 
     * @type {Address2}
     * @memberof Organization
     */
    'registeredAddress'?: Address2 | null;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'registrationNumber'?: string | null;
    /**
     * 
     * @type {StockData}
     * @memberof Organization
     */
    'stockData'?: StockData | null;
    /**
     * 
     * @type {Array<TaxInformation>}
     * @memberof Organization
     */
    'taxInformation'?: Array<TaxInformation> | null;
    /**
     * 
     * @type {TaxReportingClassification}
     * @memberof Organization
     */
    'taxReportingClassification'?: TaxReportingClassification | null;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'vatNumber'?: string | null;
    /**
     * 
     * @type {WebData}
     * @memberof Organization
     */
    'webData'?: WebData | null;
}
/**
 * 
 * @export
 * @interface OwnerEntity
 */
export interface OwnerEntity {
    /**
     * 
     * @type {string}
     * @memberof OwnerEntity
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OwnerEntity
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface PaginatedAccountHoldersResponse
 */
export interface PaginatedAccountHoldersResponse {
    /**
     * 
     * @type {Array<AccountHolder>}
     * @memberof PaginatedAccountHoldersResponse
     */
    'accountHolders'?: Array<AccountHolder> | null;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedAccountHoldersResponse
     */
    'hasNext'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedAccountHoldersResponse
     */
    'hasPrevious'?: boolean | null;
}
/**
 * 
 * @export
 * @interface PaginatedBalanceAccountsResponse
 */
export interface PaginatedBalanceAccountsResponse {
    /**
     * 
     * @type {Array<BalanceAccountBase>}
     * @memberof PaginatedBalanceAccountsResponse
     */
    'balanceAccounts'?: Array<BalanceAccountBase> | null;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedBalanceAccountsResponse
     */
    'hasNext'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedBalanceAccountsResponse
     */
    'hasPrevious'?: boolean | null;
}
/**
 * 
 * @export
 * @interface PaymentInstrument
 */
export interface PaymentInstrument {
    /**
     * 
     * @type {string}
     * @memberof PaymentInstrument
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentInstrument
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentInstrument
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentInstrument
     */
    'tokenType'?: string | null;
}
/**
 * 
 * @export
 * @interface PaymentProviderResponse
 */
export interface PaymentProviderResponse {
    /**
     * 
     * @type {string}
     * @memberof PaymentProviderResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentProviderResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentProviderResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentProviderResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentProviderResponse
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentProviderResponse
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface PaymentProviderResponseAllOf
 */
export interface PaymentProviderResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof PaymentProviderResponseAllOf
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentProviderResponseAllOf
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentProviderResponseAllOf
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface Phone
 */
export interface Phone {
    /**
     * 
     * @type {TypeEnum15}
     * @memberof Phone
     */
    'type'?: TypeEnum15;
    /**
     * 
     * @type {string}
     * @memberof Phone
     */
    'number'?: string | null;
}
/**
 * 
 * @export
 * @interface PhoneNumber
 */
export interface PhoneNumber {
    /**
     * 
     * @type {string}
     * @memberof PhoneNumber
     */
    'number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhoneNumber
     */
    'phoneCountryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhoneNumber
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface PlatformPaymentConfiguration
 */
export interface PlatformPaymentConfiguration {
    /**
     * 
     * @type {string}
     * @memberof PlatformPaymentConfiguration
     */
    'salesDayClosingTime'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PlatformPaymentConfiguration
     */
    'settlementDelayDays'?: number | null;
}
/**
 * 
 * @export
 * @interface PolicyResponse
 */
export interface PolicyResponse {
    /**
     * 
     * @type {string}
     * @memberof PolicyResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PolicyResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PolicyResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolicyResponse
     */
    'permission': string;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof PolicyResponse
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof PolicyResponse
     */
    'group': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {Operation}
     * @memberof PolicyResponse
     */
    'operation': Operation;
}
/**
 * 
 * @export
 * @interface PolicyResponseAllOf
 */
export interface PolicyResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof PolicyResponseAllOf
     */
    'permission': string;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof PolicyResponseAllOf
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof PolicyResponseAllOf
     */
    'group': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {Operation}
     * @memberof PolicyResponseAllOf
     */
    'operation': Operation;
}
/**
 * 
 * @export
 * @interface Price
 */
export interface Price {
    /**
     * 
     * @type {TaxTypeIdentifier}
     * @memberof Price
     */
    'taxTypeIdentifier': TaxTypeIdentifier;
    /**
     * 
     * @type {number}
     * @memberof Price
     */
    'taxRate': number;
    /**
     * 
     * @type {number}
     * @memberof Price
     */
    'tax': number;
    /**
     * 
     * @type {number}
     * @memberof Price
     */
    'taxPriceGross': number;
    /**
     * 
     * @type {number}
     * @memberof Price
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof Price
     */
    'totalNet': number;
    /**
     * 
     * @type {number}
     * @memberof Price
     */
    'totalGross': number;
    /**
     * 
     * @type {number}
     * @memberof Price
     */
    'unitPriceGross': number;
    /**
     * 
     * @type {number}
     * @memberof Price
     */
    'unitPriceNet': number;
    /**
     * 
     * @type {number}
     * @memberof Price
     */
    'discountedTotalGross': number;
    /**
     * 
     * @type {number}
     * @memberof Price
     */
    'discountedTotalNet': number;
}
/**
 * 
 * @export
 * @interface PriceAllOf
 */
export interface PriceAllOf {
    /**
     * 
     * @type {number}
     * @memberof PriceAllOf
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof PriceAllOf
     */
    'totalNet': number;
    /**
     * 
     * @type {number}
     * @memberof PriceAllOf
     */
    'totalGross': number;
    /**
     * 
     * @type {number}
     * @memberof PriceAllOf
     */
    'unitPriceGross': number;
    /**
     * 
     * @type {number}
     * @memberof PriceAllOf
     */
    'unitPriceNet': number;
    /**
     * 
     * @type {number}
     * @memberof PriceAllOf
     */
    'discountedTotalGross': number;
    /**
     * 
     * @type {number}
     * @memberof PriceAllOf
     */
    'discountedTotalNet': number;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductAdditionalInformationResponse
 */
export interface ProductAdditionalInformationResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductAdditionalInformationResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAdditionalInformationResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAdditionalInformationResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {ProductInformationContentType}
     * @memberof ProductAdditionalInformationResponse
     */
    'contentType': ProductInformationContentType;
    /**
     * 
     * @type {number}
     * @memberof ProductAdditionalInformationResponse
     */
    'position': number;
    /**
     * 
     * @type {string}
     * @memberof ProductAdditionalInformationResponse
     */
    'informationGroupId': string;
    /**
     * 
     * @type {InformationGroupResponse}
     * @memberof ProductAdditionalInformationResponse
     */
    'informationGroup': InformationGroupResponse;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductAdditionalInformationResponse
     */
    'value': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {string}
     * @memberof ProductAdditionalInformationResponse
     */
    'productId': string;
}
/**
 * 
 * @export
 * @interface ProductAdditionalInformationResponseAllOf
 */
export interface ProductAdditionalInformationResponseAllOf {
    /**
     * 
     * @type {ProductInformationContentType}
     * @memberof ProductAdditionalInformationResponseAllOf
     */
    'contentType': ProductInformationContentType;
    /**
     * 
     * @type {number}
     * @memberof ProductAdditionalInformationResponseAllOf
     */
    'position': number;
    /**
     * 
     * @type {string}
     * @memberof ProductAdditionalInformationResponseAllOf
     */
    'informationGroupId': string;
    /**
     * 
     * @type {InformationGroupResponse}
     * @memberof ProductAdditionalInformationResponseAllOf
     */
    'informationGroup': InformationGroupResponse;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductAdditionalInformationResponseAllOf
     */
    'value': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {string}
     * @memberof ProductAdditionalInformationResponseAllOf
     */
    'productId': string;
}
/**
 * 
 * @export
 * @interface ProductAnalyticConstituentResponse
 */
export interface ProductAnalyticConstituentResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductAnalyticConstituentResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAnalyticConstituentResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAnalyticConstituentResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductAnalyticConstituentResponse
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof ProductAnalyticConstituentResponse
     */
    'position': number;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductAnalyticConstituentResponse
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {string}
     * @memberof ProductAnalyticConstituentResponse
     */
    'productId': string;
}
/**
 * 
 * @export
 * @interface ProductAnalyticConstituentResponseAllOf
 */
export interface ProductAnalyticConstituentResponseAllOf {
    /**
     * 
     * @type {number}
     * @memberof ProductAnalyticConstituentResponseAllOf
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof ProductAnalyticConstituentResponseAllOf
     */
    'position': number;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductAnalyticConstituentResponseAllOf
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {string}
     * @memberof ProductAnalyticConstituentResponseAllOf
     */
    'productId': string;
}
/**
 * 
 * @export
 * @interface ProductAssetResponse
 */
export interface ProductAssetResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductAssetResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAssetResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAssetResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductAssetResponse
     */
    'position': number;
    /**
     * 
     * @type {TranslatedTypeResponseOfAssetResponse}
     * @memberof ProductAssetResponse
     */
    'asset': TranslatedTypeResponseOfAssetResponse;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductAssetResponse
     */
    'description': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductAssetResponse
     */
    'alternative': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {string}
     * @memberof ProductAssetResponse
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAssetResponse
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAssetResponse
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductAssetResponseAllOf
 */
export interface ProductAssetResponseAllOf {
    /**
     * 
     * @type {number}
     * @memberof ProductAssetResponseAllOf
     */
    'position': number;
    /**
     * 
     * @type {TranslatedTypeResponseOfAssetResponse}
     * @memberof ProductAssetResponseAllOf
     */
    'asset': TranslatedTypeResponseOfAssetResponse;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductAssetResponseAllOf
     */
    'description': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductAssetResponseAllOf
     */
    'alternative': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {string}
     * @memberof ProductAssetResponseAllOf
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductAssetResponseAllOf
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAssetResponseAllOf
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductBundleAssetResponse
 */
export interface ProductBundleAssetResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAssetResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAssetResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAssetResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleAssetResponse
     */
    'position': number;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAssetResponse
     */
    'productBundleId': string;
    /**
     * 
     * @type {TranslatedTypeResponseOfAssetResponse}
     * @memberof ProductBundleAssetResponse
     */
    'asset': TranslatedTypeResponseOfAssetResponse;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductBundleAssetResponse
     */
    'description'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductBundleAssetResponse
     */
    'alternative'?: TranslatedTypeResponseOfString | null;
}
/**
 * 
 * @export
 * @interface ProductBundleAssetResponseAllOf
 */
export interface ProductBundleAssetResponseAllOf {
    /**
     * 
     * @type {number}
     * @memberof ProductBundleAssetResponseAllOf
     */
    'position': number;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleAssetResponseAllOf
     */
    'productBundleId': string;
    /**
     * 
     * @type {TranslatedTypeResponseOfAssetResponse}
     * @memberof ProductBundleAssetResponseAllOf
     */
    'asset': TranslatedTypeResponseOfAssetResponse;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductBundleAssetResponseAllOf
     */
    'description'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductBundleAssetResponseAllOf
     */
    'alternative'?: TranslatedTypeResponseOfString | null;
}
/**
 * 
 * @export
 * @interface ProductBundleDefinition
 */
export interface ProductBundleDefinition {
    /**
     * 
     * @type {string}
     * @memberof ProductBundleDefinition
     */
    'productBundleId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleDefinition
     */
    'productBundleQuantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleDefinition
     */
    'productBundleName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleDefinition
     */
    'productBundlePosition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductBundleDefinition
     */
    'bundleProductOriginPrice'?: number | null;
}
/**
 * 
 * @export
 * @interface ProductBundlePositionResponse
 */
export interface ProductBundlePositionResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductBundlePositionResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundlePositionResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundlePositionResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBundlePositionResponse
     */
    'productBundleId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundlePositionResponse
     */
    'productId': string;
    /**
     * 
     * @type {SimpleProductResponse}
     * @memberof ProductBundlePositionResponse
     */
    'product'?: SimpleProductResponse | null;
    /**
     * 
     * @type {number}
     * @memberof ProductBundlePositionResponse
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundlePositionResponse
     */
    'quantity': number;
    /**
     * 
     * @type {Array<ProductBundlePriceResponse>}
     * @memberof ProductBundlePositionResponse
     */
    'prices'?: Array<ProductBundlePriceResponse> | null;
}
/**
 * 
 * @export
 * @interface ProductBundlePositionResponseAllOf
 */
export interface ProductBundlePositionResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof ProductBundlePositionResponseAllOf
     */
    'productBundleId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundlePositionResponseAllOf
     */
    'productId': string;
    /**
     * 
     * @type {SimpleProductResponse}
     * @memberof ProductBundlePositionResponseAllOf
     */
    'product'?: SimpleProductResponse | null;
    /**
     * 
     * @type {number}
     * @memberof ProductBundlePositionResponseAllOf
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundlePositionResponseAllOf
     */
    'quantity': number;
    /**
     * 
     * @type {Array<ProductBundlePriceResponse>}
     * @memberof ProductBundlePositionResponseAllOf
     */
    'prices'?: Array<ProductBundlePriceResponse> | null;
}
/**
 * 
 * @export
 * @interface ProductBundlePriceResponse
 */
export interface ProductBundlePriceResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductBundlePriceResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundlePriceResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundlePriceResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {TaxTypeIdentifier}
     * @memberof ProductBundlePriceResponse
     */
    'taxTypeIdentifier': TaxTypeIdentifier;
    /**
     * 
     * @type {string}
     * @memberof ProductBundlePriceResponse
     */
    'taxTypeId': string;
    /**
     * 
     * @type {TaxTypeResponse}
     * @memberof ProductBundlePriceResponse
     */
    'taxType'?: TaxTypeResponse | null;
    /**
     * 
     * @type {number}
     * @memberof ProductBundlePriceResponse
     */
    'gross': number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundlePriceResponse
     */
    'listPriceGross'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBundlePriceResponse
     */
    'currencyId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundlePriceResponse
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundlePriceResponse
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundlePriceResponse
     */
    'productBundlePositionId': string;
}
/**
 * 
 * @export
 * @interface ProductBundlePriceResponseAllOf
 */
export interface ProductBundlePriceResponseAllOf {
    /**
     * 
     * @type {TaxTypeIdentifier}
     * @memberof ProductBundlePriceResponseAllOf
     */
    'taxTypeIdentifier': TaxTypeIdentifier;
    /**
     * 
     * @type {string}
     * @memberof ProductBundlePriceResponseAllOf
     */
    'taxTypeId': string;
    /**
     * 
     * @type {TaxTypeResponse}
     * @memberof ProductBundlePriceResponseAllOf
     */
    'taxType'?: TaxTypeResponse | null;
    /**
     * 
     * @type {number}
     * @memberof ProductBundlePriceResponseAllOf
     */
    'gross': number;
    /**
     * 
     * @type {number}
     * @memberof ProductBundlePriceResponseAllOf
     */
    'listPriceGross'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBundlePriceResponseAllOf
     */
    'currencyId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundlePriceResponseAllOf
     */
    'countryId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundlePriceResponseAllOf
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundlePriceResponseAllOf
     */
    'productBundlePositionId': string;
}
/**
 * 
 * @export
 * @interface ProductBundleResponse
 */
export interface ProductBundleResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductBundleResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleResponse
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleResponse
     */
    'productNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleResponse
     */
    'coverId'?: string | null;
    /**
     * 
     * @type {ProductBundleAssetResponse}
     * @memberof ProductBundleResponse
     */
    'cover'?: ProductBundleAssetResponse | null;
    /**
     * 
     * @type {Array<ProductBundleAssetResponse>}
     * @memberof ProductBundleResponse
     */
    'assets'?: Array<ProductBundleAssetResponse> | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductBundleResponse
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductBundleResponse
     */
    'description': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {Array<ProductBundlePositionResponse>}
     * @memberof ProductBundleResponse
     */
    'positions'?: Array<ProductBundlePositionResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleResponse
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleResponse
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductBundleResponseAllOf
 */
export interface ProductBundleResponseAllOf {
    /**
     * 
     * @type {boolean}
     * @memberof ProductBundleResponseAllOf
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleResponseAllOf
     */
    'productNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleResponseAllOf
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleResponseAllOf
     */
    'coverId'?: string | null;
    /**
     * 
     * @type {ProductBundleAssetResponse}
     * @memberof ProductBundleResponseAllOf
     */
    'cover'?: ProductBundleAssetResponse | null;
    /**
     * 
     * @type {Array<ProductBundleAssetResponse>}
     * @memberof ProductBundleResponseAllOf
     */
    'assets'?: Array<ProductBundleAssetResponse> | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductBundleResponseAllOf
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductBundleResponseAllOf
     */
    'description': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {Array<ProductBundlePositionResponse>}
     * @memberof ProductBundleResponseAllOf
     */
    'positions'?: Array<ProductBundlePositionResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleResponseAllOf
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductBundleResponseAllOf
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductCategoryResponse
 */
export interface ProductCategoryResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryResponse
     */
    'identifier': string;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductCategoryResponse
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductCategoryResponse
     */
    'description'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCategoryResponse
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryResponse
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {Array<ProductCategoryResponse>}
     * @memberof ProductCategoryResponse
     */
    'children'?: Array<ProductCategoryResponse> | null;
    /**
     * 
     * @type {Array<ProductGroupResponse>}
     * @memberof ProductCategoryResponse
     */
    'productGroups'?: Array<ProductGroupResponse> | null;
    /**
     * 
     * @type {Array<AnimalSpeciesResponse>}
     * @memberof ProductCategoryResponse
     */
    'animalSpecies'?: Array<AnimalSpeciesResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryResponse
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryResponse
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductCategoryResponseAllOf
 */
export interface ProductCategoryResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryResponseAllOf
     */
    'identifier': string;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductCategoryResponseAllOf
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductCategoryResponseAllOf
     */
    'description'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCategoryResponseAllOf
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryResponseAllOf
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {Array<ProductCategoryResponse>}
     * @memberof ProductCategoryResponseAllOf
     */
    'children'?: Array<ProductCategoryResponse> | null;
    /**
     * 
     * @type {Array<ProductGroupResponse>}
     * @memberof ProductCategoryResponseAllOf
     */
    'productGroups'?: Array<ProductGroupResponse> | null;
    /**
     * 
     * @type {Array<AnimalSpeciesResponse>}
     * @memberof ProductCategoryResponseAllOf
     */
    'animalSpecies'?: Array<AnimalSpeciesResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryResponseAllOf
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryResponseAllOf
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductDataImportItemRequest
 */
export interface ProductDataImportItemRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductDataImportItemRequest
     */
    'dataImportItemId': string;
    /**
     * 
     * @type {TransformedProductRequest}
     * @memberof ProductDataImportItemRequest
     */
    'clientValidatedData': TransformedProductRequest;
}
/**
 * 
 * @export
 * @interface ProductDataImportItemResponse
 */
export interface ProductDataImportItemResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductDataImportItemResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDataImportItemResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDataImportItemResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {Array<TransformedProductResponse>}
     * @memberof ProductDataImportItemResponse
     */
    'processedData': Array<TransformedProductResponse>;
    /**
     * 
     * @type {string}
     * @memberof ProductDataImportItemResponse
     */
    'dataImportId': string;
}
/**
 * 
 * @export
 * @interface ProductDataImportItemResponseAllOf
 */
export interface ProductDataImportItemResponseAllOf {
    /**
     * 
     * @type {Array<TransformedProductResponse>}
     * @memberof ProductDataImportItemResponseAllOf
     */
    'processedData': Array<TransformedProductResponse>;
    /**
     * 
     * @type {string}
     * @memberof ProductDataImportItemResponseAllOf
     */
    'dataImportId': string;
}
/**
 * 
 * @export
 * @interface ProductDataImportResponse
 */
export interface ProductDataImportResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductDataImportResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDataImportResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDataImportResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {ImportState}
     * @memberof ProductDataImportResponse
     */
    'importState': ImportState;
    /**
     * 
     * @type {string}
     * @memberof ProductDataImportResponse
     */
    'importFileId'?: string | null;
    /**
     * 
     * @type {AssetResponse}
     * @memberof ProductDataImportResponse
     */
    'importFile'?: AssetResponse | null;
    /**
     * 
     * @type {ImportFileType}
     * @memberof ProductDataImportResponse
     */
    'importFileType': ImportFileType;
    /**
     * 
     * @type {string}
     * @memberof ProductDataImportResponse
     */
    'delimiter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDataImportResponse
     */
    'qualifier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDataImportResponse
     */
    'itemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDataImportResponse
     */
    'processedItems': number;
    /**
     * 
     * @type {number}
     * @memberof ProductDataImportResponse
     */
    'unprocessableItems': number;
    /**
     * 
     * @type {number}
     * @memberof ProductDataImportResponse
     */
    'validItems': number;
    /**
     * 
     * @type {number}
     * @memberof ProductDataImportResponse
     */
    'invalidItems': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductDataImportResponse
     */
    'errorMessages'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDataImportResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDataImportResponse
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDataImportResponse
     */
    'dataImportConfigId': string;
    /**
     * 
     * @type {DataImportConfigResponse}
     * @memberof ProductDataImportResponse
     */
    'dataImportConfig'?: DataImportConfigResponse | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDataImportResponse
     */
    'lastlyUpdatedByUserId'?: string | null;
    /**
     * 
     * @type {Array<ProductDataImportItemResponse>}
     * @memberof ProductDataImportResponse
     */
    'dataImportItems': Array<ProductDataImportItemResponse>;
}
/**
 * 
 * @export
 * @interface ProductDataImportResponseAllOf
 */
export interface ProductDataImportResponseAllOf {
    /**
     * 
     * @type {Array<ProductDataImportItemResponse>}
     * @memberof ProductDataImportResponseAllOf
     */
    'dataImportItems': Array<ProductDataImportItemResponse>;
}
/**
 * 
 * @export
 * @interface ProductGroupMappingResponse
 */
export interface ProductGroupMappingResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductGroupMappingResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductGroupMappingResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductGroupMappingResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductGroupMappingResponse
     */
    'name': string;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductGroupMappingResponse
     */
    'description'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {string}
     * @memberof ProductGroupMappingResponse
     */
    'propertyGroupId': string;
    /**
     * 
     * @type {PropertyGroupResponse}
     * @memberof ProductGroupMappingResponse
     */
    'propertyGroup'?: PropertyGroupResponse | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductGroupMappingResponse
     */
    'isOptional': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductGroupMappingResponse
     */
    'isUserInputAllowed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductGroupMappingResponse
     */
    'hasPropertyOptionListing': boolean;
    /**
     * 
     * @type {Array<PropertyOptionResponse>}
     * @memberof ProductGroupMappingResponse
     */
    'propertyOptions'?: Array<PropertyOptionResponse> | null;
    /**
     * 
     * @type {Array<ProductGroupResponse>}
     * @memberof ProductGroupMappingResponse
     */
    'productGroups'?: Array<ProductGroupResponse> | null;
    /**
     * 
     * @type {Array<PropertyValidationRuleResponse>}
     * @memberof ProductGroupMappingResponse
     */
    'propertyValidationRules'?: Array<PropertyValidationRuleResponse> | null;
}
/**
 * 
 * @export
 * @interface ProductGroupMappingResponseAllOf
 */
export interface ProductGroupMappingResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof ProductGroupMappingResponseAllOf
     */
    'name': string;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductGroupMappingResponseAllOf
     */
    'description'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {string}
     * @memberof ProductGroupMappingResponseAllOf
     */
    'propertyGroupId': string;
    /**
     * 
     * @type {PropertyGroupResponse}
     * @memberof ProductGroupMappingResponseAllOf
     */
    'propertyGroup'?: PropertyGroupResponse | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductGroupMappingResponseAllOf
     */
    'isOptional': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductGroupMappingResponseAllOf
     */
    'isUserInputAllowed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductGroupMappingResponseAllOf
     */
    'hasPropertyOptionListing': boolean;
    /**
     * 
     * @type {Array<PropertyOptionResponse>}
     * @memberof ProductGroupMappingResponseAllOf
     */
    'propertyOptions'?: Array<PropertyOptionResponse> | null;
    /**
     * 
     * @type {Array<ProductGroupResponse>}
     * @memberof ProductGroupMappingResponseAllOf
     */
    'productGroups'?: Array<ProductGroupResponse> | null;
    /**
     * 
     * @type {Array<PropertyValidationRuleResponse>}
     * @memberof ProductGroupMappingResponseAllOf
     */
    'propertyValidationRules'?: Array<PropertyValidationRuleResponse> | null;
}
/**
 * 
 * @export
 * @interface ProductGroupResponse
 */
export interface ProductGroupResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductGroupResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductGroupResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductGroupResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductGroupResponse
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductGroupResponse
     */
    'description'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {string}
     * @memberof ProductGroupResponse
     */
    'identifier': string;
    /**
     * 
     * @type {Array<AnimalSpeciesResponse>}
     * @memberof ProductGroupResponse
     */
    'animalSpecies'?: Array<AnimalSpeciesResponse> | null;
    /**
     * 
     * @type {Array<PropertyValidationRuleResponse>}
     * @memberof ProductGroupResponse
     */
    'additionalPropertyValidationRules'?: Array<PropertyValidationRuleResponse> | null;
    /**
     * 
     * @type {Array<PropertyGroupResponse>}
     * @memberof ProductGroupResponse
     */
    'propertyGroups'?: Array<PropertyGroupResponse> | null;
}
/**
 * 
 * @export
 * @interface ProductGroupResponseAllOf
 */
export interface ProductGroupResponseAllOf {
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductGroupResponseAllOf
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductGroupResponseAllOf
     */
    'description'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {string}
     * @memberof ProductGroupResponseAllOf
     */
    'identifier': string;
    /**
     * 
     * @type {Array<AnimalSpeciesResponse>}
     * @memberof ProductGroupResponseAllOf
     */
    'animalSpecies'?: Array<AnimalSpeciesResponse> | null;
    /**
     * 
     * @type {Array<PropertyValidationRuleResponse>}
     * @memberof ProductGroupResponseAllOf
     */
    'additionalPropertyValidationRules'?: Array<PropertyValidationRuleResponse> | null;
    /**
     * 
     * @type {Array<PropertyGroupResponse>}
     * @memberof ProductGroupResponseAllOf
     */
    'propertyGroups'?: Array<PropertyGroupResponse> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ProductIdentifierType {
    Id = 'Id',
    Ean = 'Ean',
    ManufacturerProductNumber = 'ManufacturerProductNumber',
    ProductNumber = 'ProductNumber'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum ProductInformationContentType {
    RichText = 'RichText',
    BulletPoints = 'BulletPoints',
    Table = 'Table',
    Downloads = 'Downloads',
    MediaGallery = 'MediaGallery'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum ProductListFilterMode {
    ExcludeNonBuyable = 'ExcludeNonBuyable',
    ParentProductsOnly = 'ParentProductsOnly'
}

/**
 * 
 * @export
 * @interface ProductPriceResponse
 */
export interface ProductPriceResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductPriceResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductPriceResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductPriceResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceResponse
     */
    'gross': number;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceResponse
     */
    'net': number;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceResponse
     */
    'listPriceGross'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceResponse
     */
    'listPriceNet'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceResponse
     */
    'quantityStart'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceResponse
     */
    'quantityEnd'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductPriceResponse
     */
    'currencyId': string;
    /**
     * 
     * @type {CurrencyResponse}
     * @memberof ProductPriceResponse
     */
    'currency': CurrencyResponse;
    /**
     * 
     * @type {string}
     * @memberof ProductPriceResponse
     */
    'countryId': string;
    /**
     * 
     * @type {CountryResponse}
     * @memberof ProductPriceResponse
     */
    'country': CountryResponse;
    /**
     * 
     * @type {string}
     * @memberof ProductPriceResponse
     */
    'productId': string;
}
/**
 * 
 * @export
 * @interface ProductPriceResponseAllOf
 */
export interface ProductPriceResponseAllOf {
    /**
     * 
     * @type {number}
     * @memberof ProductPriceResponseAllOf
     */
    'gross': number;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceResponseAllOf
     */
    'net': number;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceResponseAllOf
     */
    'listPriceGross'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceResponseAllOf
     */
    'listPriceNet'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceResponseAllOf
     */
    'quantityStart'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceResponseAllOf
     */
    'quantityEnd'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductPriceResponseAllOf
     */
    'currencyId': string;
    /**
     * 
     * @type {CurrencyResponse}
     * @memberof ProductPriceResponseAllOf
     */
    'currency': CurrencyResponse;
    /**
     * 
     * @type {string}
     * @memberof ProductPriceResponseAllOf
     */
    'countryId': string;
    /**
     * 
     * @type {CountryResponse}
     * @memberof ProductPriceResponseAllOf
     */
    'country': CountryResponse;
    /**
     * 
     * @type {string}
     * @memberof ProductPriceResponseAllOf
     */
    'productId': string;
}
/**
 * 
 * @export
 * @interface ProductResponse
 */
export interface ProductResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {ProductState}
     * @memberof ProductResponse
     */
    'state': ProductState;
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'ean': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductResponse
     */
    'isBatchControlled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductResponse
     */
    'isBestBeforeControlled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductResponse
     */
    'isDangerousGoods': boolean;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductResponse
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductResponse
     */
    'productLine'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {Array<ProductPriceResponse>}
     * @memberof ProductResponse
     */
    'productPrices'?: Array<ProductPriceResponse> | null;
    /**
     * 
     * @type {Array<ProductStockResponse>}
     * @memberof ProductResponse
     */
    'productStocks'?: Array<ProductStockResponse> | null;
    /**
     * 
     * @type {Array<ProductAssetResponse>}
     * @memberof ProductResponse
     */
    'productAssets'?: Array<ProductAssetResponse> | null;
    /**
     * 
     * @type {Array<ProductAdditionalInformationResponse>}
     * @memberof ProductResponse
     */
    'additionalInformation'?: Array<ProductAdditionalInformationResponse> | null;
    /**
     * 
     * @type {Array<ProductAnalyticConstituentResponse>}
     * @memberof ProductResponse
     */
    'analyticConstituents'?: Array<ProductAnalyticConstituentResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'productNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'activeProductVersionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'brandId': string;
    /**
     * 
     * @type {BrandResponse}
     * @memberof ProductResponse
     */
    'brand'?: BrandResponse | null;
    /**
     * 
     * @type {Array<AnimalSpeciesResponse>}
     * @memberof ProductResponse
     */
    'animalSpecies': Array<AnimalSpeciesResponse>;
    /**
     * 
     * @type {ProductResponse}
     * @memberof ProductResponse
     */
    'parent': ProductResponse;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductResponse
     */
    'description': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductResponse
     */
    'marketingText'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'releaseDate': string;
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'mainVariantId'?: string | null;
    /**
     * 
     * @type {Array<PropertyOptionResponse>}
     * @memberof ProductResponse
     */
    'variantOptions'?: Array<PropertyOptionResponse> | null;
    /**
     * 
     * @type {Array<ProductVariantResponse>}
     * @memberof ProductResponse
     */
    'children'?: Array<ProductVariantResponse> | null;
    /**
     * 
     * @type {Array<PropertyOptionResponse>}
     * @memberof ProductResponse
     */
    'properties'?: Array<PropertyOptionResponse> | null;
    /**
     * 
     * @type {Array<PropertyGroupResponse>}
     * @memberof ProductResponse
     */
    'propertyGroups'?: Array<PropertyGroupResponse> | null;
    /**
     * 
     * @type {number}
     * @memberof ProductResponse
     */
    'restockTimeDays'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductResponse
     */
    'isCloseout'?: boolean;
    /**
     * 
     * @type {TaxTypeIdentifier}
     * @memberof ProductResponse
     */
    'taxTypeIdentifier': TaxTypeIdentifier;
    /**
     * 
     * @type {number}
     * @memberof ProductResponse
     */
    'purchaseSteps'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductResponse
     */
    'minPurchase'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductResponse
     */
    'maxPurchase'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductResponse
     */
    'purchaseUnit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductResponse
     */
    'referenceUnit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductResponse
     */
    'weight'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductResponse
     */
    'width'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductResponse
     */
    'height'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductResponse
     */
    'length'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'productUnitId'?: string | null;
    /**
     * 
     * @type {ProductUnitResponse}
     * @memberof ProductResponse
     */
    'productUnit'?: ProductUnitResponse | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductResponse
     */
    'packUnit'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductResponse
     */
    'packUnitPlural'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {Array<DeliveryTimeResponse>}
     * @memberof ProductResponse
     */
    'deliveryTimes'?: Array<DeliveryTimeResponse> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductResponse
     */
    'isShippingFree'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'manufacturerProductNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'coverId'?: string | null;
    /**
     * 
     * @type {ProductAssetResponse}
     * @memberof ProductResponse
     */
    'cover'?: ProductAssetResponse | null;
    /**
     * 
     * @type {Array<TagResponse>}
     * @memberof ProductResponse
     */
    'tags'?: Array<TagResponse> | null;
    /**
     * 
     * @type {Array<ProductCategoryResponse>}
     * @memberof ProductResponse
     */
    'categories'?: Array<ProductCategoryResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'productGroupId': string;
    /**
     * 
     * @type {ProductGroupResponse}
     * @memberof ProductResponse
     */
    'productGroup'?: ProductGroupResponse | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductResponse
     */
    'hasVariants'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ProductResponse
     */
    'mainCategoryId'?: string | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductResponse
     */
    'seoProductTitle'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {Array<ValidationFailureResponse>}
     * @memberof ProductResponse
     */
    'errors'?: Array<ValidationFailureResponse> | null;
}
/**
 * 
 * @export
 * @interface ProductResponseAllOf
 */
export interface ProductResponseAllOf {
    /**
     * 
     * @type {ProductResponse}
     * @memberof ProductResponseAllOf
     */
    'parent': ProductResponse;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductResponseAllOf
     */
    'description': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductResponseAllOf
     */
    'marketingText'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {string}
     * @memberof ProductResponseAllOf
     */
    'releaseDate': string;
    /**
     * 
     * @type {string}
     * @memberof ProductResponseAllOf
     */
    'mainVariantId'?: string | null;
    /**
     * 
     * @type {Array<PropertyOptionResponse>}
     * @memberof ProductResponseAllOf
     */
    'variantOptions'?: Array<PropertyOptionResponse> | null;
    /**
     * 
     * @type {Array<ProductVariantResponse>}
     * @memberof ProductResponseAllOf
     */
    'children'?: Array<ProductVariantResponse> | null;
    /**
     * 
     * @type {Array<PropertyOptionResponse>}
     * @memberof ProductResponseAllOf
     */
    'properties'?: Array<PropertyOptionResponse> | null;
    /**
     * 
     * @type {Array<PropertyGroupResponse>}
     * @memberof ProductResponseAllOf
     */
    'propertyGroups'?: Array<PropertyGroupResponse> | null;
    /**
     * 
     * @type {number}
     * @memberof ProductResponseAllOf
     */
    'restockTimeDays'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductResponseAllOf
     */
    'isCloseout'?: boolean;
    /**
     * 
     * @type {TaxTypeIdentifier}
     * @memberof ProductResponseAllOf
     */
    'taxTypeIdentifier': TaxTypeIdentifier;
    /**
     * 
     * @type {number}
     * @memberof ProductResponseAllOf
     */
    'purchaseSteps'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductResponseAllOf
     */
    'minPurchase'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductResponseAllOf
     */
    'maxPurchase'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductResponseAllOf
     */
    'purchaseUnit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductResponseAllOf
     */
    'referenceUnit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductResponseAllOf
     */
    'weight'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductResponseAllOf
     */
    'width'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductResponseAllOf
     */
    'height'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductResponseAllOf
     */
    'length'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductResponseAllOf
     */
    'productUnitId'?: string | null;
    /**
     * 
     * @type {ProductUnitResponse}
     * @memberof ProductResponseAllOf
     */
    'productUnit'?: ProductUnitResponse | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductResponseAllOf
     */
    'packUnit'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductResponseAllOf
     */
    'packUnitPlural'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {Array<DeliveryTimeResponse>}
     * @memberof ProductResponseAllOf
     */
    'deliveryTimes'?: Array<DeliveryTimeResponse> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductResponseAllOf
     */
    'isShippingFree'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductResponseAllOf
     */
    'manufacturerProductNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductResponseAllOf
     */
    'coverId'?: string | null;
    /**
     * 
     * @type {ProductAssetResponse}
     * @memberof ProductResponseAllOf
     */
    'cover'?: ProductAssetResponse | null;
    /**
     * 
     * @type {Array<TagResponse>}
     * @memberof ProductResponseAllOf
     */
    'tags'?: Array<TagResponse> | null;
    /**
     * 
     * @type {Array<ProductCategoryResponse>}
     * @memberof ProductResponseAllOf
     */
    'categories'?: Array<ProductCategoryResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof ProductResponseAllOf
     */
    'productGroupId': string;
    /**
     * 
     * @type {ProductGroupResponse}
     * @memberof ProductResponseAllOf
     */
    'productGroup'?: ProductGroupResponse | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductResponseAllOf
     */
    'hasVariants'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ProductResponseAllOf
     */
    'mainCategoryId'?: string | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductResponseAllOf
     */
    'seoProductTitle'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {Array<ValidationFailureResponse>}
     * @memberof ProductResponseAllOf
     */
    'errors'?: Array<ValidationFailureResponse> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ProductState {
    Draft = 'Draft',
    Active = 'Active',
    Inactive = 'Inactive',
    Archived = 'Archived',
    ForApproval = 'ForApproval'
}

/**
 * 
 * @export
 * @interface ProductStockDetailResponse
 */
export interface ProductStockDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductStockDetailResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductStockDetailResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductStockDetailResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductStockDetailResponse
     */
    'stock': number;
    /**
     * 
     * @type {string}
     * @memberof ProductStockDetailResponse
     */
    'bestBefore'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductStockDetailResponse
     */
    'batchNumber'?: string | null;
    /**
     * 
     * @type {ProductStockQuality}
     * @memberof ProductStockDetailResponse
     */
    'quality'?: ProductStockQuality | null;
    /**
     * 
     * @type {string}
     * @memberof ProductStockDetailResponse
     */
    'productStockId': string;
}
/**
 * 
 * @export
 * @interface ProductStockDetailResponseAllOf
 */
export interface ProductStockDetailResponseAllOf {
    /**
     * 
     * @type {number}
     * @memberof ProductStockDetailResponseAllOf
     */
    'stock': number;
    /**
     * 
     * @type {string}
     * @memberof ProductStockDetailResponseAllOf
     */
    'bestBefore'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductStockDetailResponseAllOf
     */
    'batchNumber'?: string | null;
    /**
     * 
     * @type {ProductStockQuality}
     * @memberof ProductStockDetailResponseAllOf
     */
    'quality'?: ProductStockQuality | null;
    /**
     * 
     * @type {string}
     * @memberof ProductStockDetailResponseAllOf
     */
    'productStockId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ProductStockQuality {
    Defective = 'Defective',
    Dirty = 'Dirty',
    BestBeforeDataExpired = 'BestBeforeDataExpired'
}

/**
 * 
 * @export
 * @interface ProductStockResponse
 */
export interface ProductStockResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductStockResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductStockResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductStockResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductStockResponse
     */
    'stock': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductStockResponse
     */
    'available': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductStockResponse
     */
    'priority': number;
    /**
     * 
     * @type {string}
     * @memberof ProductStockResponse
     */
    'warehouseId': string;
    /**
     * 
     * @type {WarehouseResponse}
     * @memberof ProductStockResponse
     */
    'warehouse': WarehouseResponse;
    /**
     * 
     * @type {string}
     * @memberof ProductStockResponse
     */
    'productId': string;
    /**
     * 
     * @type {SimpleProductResponse}
     * @memberof ProductStockResponse
     */
    'product': SimpleProductResponse;
    /**
     * 
     * @type {string}
     * @memberof ProductStockResponse
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductStockResponse
     */
    'syncedAt'?: string | null;
    /**
     * 
     * @type {Array<ProductStockDetailResponse>}
     * @memberof ProductStockResponse
     */
    'productStockDetails'?: Array<ProductStockDetailResponse> | null;
}
/**
 * 
 * @export
 * @interface ProductStockResponseAllOf
 */
export interface ProductStockResponseAllOf {
    /**
     * 
     * @type {number}
     * @memberof ProductStockResponseAllOf
     */
    'stock': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductStockResponseAllOf
     */
    'available': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductStockResponseAllOf
     */
    'priority': number;
    /**
     * 
     * @type {string}
     * @memberof ProductStockResponseAllOf
     */
    'warehouseId': string;
    /**
     * 
     * @type {WarehouseResponse}
     * @memberof ProductStockResponseAllOf
     */
    'warehouse': WarehouseResponse;
    /**
     * 
     * @type {string}
     * @memberof ProductStockResponseAllOf
     */
    'productId': string;
    /**
     * 
     * @type {SimpleProductResponse}
     * @memberof ProductStockResponseAllOf
     */
    'product': SimpleProductResponse;
    /**
     * 
     * @type {string}
     * @memberof ProductStockResponseAllOf
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductStockResponseAllOf
     */
    'syncedAt'?: string | null;
    /**
     * 
     * @type {Array<ProductStockDetailResponse>}
     * @memberof ProductStockResponseAllOf
     */
    'productStockDetails'?: Array<ProductStockDetailResponse> | null;
}
/**
 * 
 * @export
 * @interface ProductUnitResponse
 */
export interface ProductUnitResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductUnitResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductUnitResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductUnitResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductUnitResponse
     */
    'isBasePriceUnit': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductUnitResponse
     */
    'formatPadding'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductUnitResponse
     */
    'formatPrecision'?: number | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductUnitResponse
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductUnitResponse
     */
    'shortCode': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {string}
     * @memberof ProductUnitResponse
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductUnitResponse
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductUnitResponseAllOf
 */
export interface ProductUnitResponseAllOf {
    /**
     * 
     * @type {boolean}
     * @memberof ProductUnitResponseAllOf
     */
    'isBasePriceUnit': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductUnitResponseAllOf
     */
    'formatPadding'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductUnitResponseAllOf
     */
    'formatPrecision'?: number | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductUnitResponseAllOf
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductUnitResponseAllOf
     */
    'shortCode': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {string}
     * @memberof ProductUnitResponseAllOf
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductUnitResponseAllOf
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductValidationResultResponse
 */
export interface ProductValidationResultResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductValidationResultResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductValidationResultResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductValidationResultResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductValidationResultResponse
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {string}
     * @memberof ProductValidationResultResponse
     */
    'ean': string;
    /**
     * 
     * @type {ProductState}
     * @memberof ProductValidationResultResponse
     */
    'state': ProductState;
    /**
     * 
     * @type {string}
     * @memberof ProductValidationResultResponse
     */
    'activeProductVersionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductValidationResultResponse
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductValidationResultResponse
     */
    'productNumber': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductValidationResultResponse
     */
    'hasVariants'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ProductValidationResultResponse
     */
    'manufacturerProductNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductValidationResultResponse
     */
    'coverId'?: string | null;
    /**
     * 
     * @type {ProductAssetResponse}
     * @memberof ProductValidationResultResponse
     */
    'cover'?: ProductAssetResponse | null;
    /**
     * 
     * @type {Array<PropertyOptionResponse>}
     * @memberof ProductValidationResultResponse
     */
    'variantOptions'?: Array<PropertyOptionResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof ProductValidationResultResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {ProductGroupResponse}
     * @memberof ProductValidationResultResponse
     */
    'productGroup'?: ProductGroupResponse | null;
    /**
     * 
     * @type {number}
     * @memberof ProductValidationResultResponse
     */
    'inStockQuantity'?: number;
    /**
     * 
     * @type {Array<ValidationFailureResponse>}
     * @memberof ProductValidationResultResponse
     */
    'errors': Array<ValidationFailureResponse>;
}
/**
 * 
 * @export
 * @interface ProductValidationResultResponseAllOf
 */
export interface ProductValidationResultResponseAllOf {
    /**
     * 
     * @type {Array<ValidationFailureResponse>}
     * @memberof ProductValidationResultResponseAllOf
     */
    'errors': Array<ValidationFailureResponse>;
}
/**
 * 
 * @export
 * @interface ProductVariantResponse
 */
export interface ProductVariantResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductVariantResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariantResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariantResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductVariantResponse
     */
    'parentId': string;
    /**
     * 
     * @type {ProductState}
     * @memberof ProductVariantResponse
     */
    'state': ProductState;
    /**
     * 
     * @type {string}
     * @memberof ProductVariantResponse
     */
    'ean': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductVariantResponse
     */
    'isBatchControlled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductVariantResponse
     */
    'isBestBeforeControlled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductVariantResponse
     */
    'isDangerousGoods': boolean;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductVariantResponse
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof ProductVariantResponse
     */
    'productLine'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {Array<ProductPriceResponse>}
     * @memberof ProductVariantResponse
     */
    'productPrices'?: Array<ProductPriceResponse> | null;
    /**
     * 
     * @type {Array<ProductStockResponse>}
     * @memberof ProductVariantResponse
     */
    'productStocks'?: Array<ProductStockResponse> | null;
    /**
     * 
     * @type {Array<ProductAssetResponse>}
     * @memberof ProductVariantResponse
     */
    'productAssets'?: Array<ProductAssetResponse> | null;
    /**
     * 
     * @type {Array<ProductAdditionalInformationResponse>}
     * @memberof ProductVariantResponse
     */
    'additionalInformation'?: Array<ProductAdditionalInformationResponse> | null;
    /**
     * 
     * @type {Array<ProductAnalyticConstituentResponse>}
     * @memberof ProductVariantResponse
     */
    'analyticConstituents'?: Array<ProductAnalyticConstituentResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof ProductVariantResponse
     */
    'productNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariantResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariantResponse
     */
    'activeProductVersionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductVariantResponse
     */
    'brandId': string;
    /**
     * 
     * @type {BrandResponse}
     * @memberof ProductVariantResponse
     */
    'brand'?: BrandResponse | null;
    /**
     * 
     * @type {Array<AnimalSpeciesResponse>}
     * @memberof ProductVariantResponse
     */
    'animalSpecies': Array<AnimalSpeciesResponse>;
    /**
     * 
     * @type {string}
     * @memberof ProductVariantResponse
     */
    'manufacturerProductNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductVariantResponseAllOf
 */
export interface ProductVariantResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof ProductVariantResponseAllOf
     */
    'parentId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariantResponseAllOf
     */
    'manufacturerProductNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductVersionResponse
 */
export interface ProductVersionResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductVersionResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductVersionResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductVersionResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductVersionResponse
     */
    'productId': string;
    /**
     * 
     * @type {number}
     * @memberof ProductVersionResponse
     */
    'versionNumber': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductVersionResponse
     */
    'isActive': boolean;
    /**
     * 
     * @type {ProductResponse}
     * @memberof ProductVersionResponse
     */
    'productData'?: ProductResponse | null;
    /**
     * 
     * @type {string}
     * @memberof ProductVersionResponse
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductVersionResponse
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductVersionResponseAllOf
 */
export interface ProductVersionResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof ProductVersionResponseAllOf
     */
    'productId': string;
    /**
     * 
     * @type {number}
     * @memberof ProductVersionResponseAllOf
     */
    'versionNumber': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductVersionResponseAllOf
     */
    'isActive': boolean;
    /**
     * 
     * @type {ProductResponse}
     * @memberof ProductVersionResponseAllOf
     */
    'productData'?: ProductResponse | null;
    /**
     * 
     * @type {string}
     * @memberof ProductVersionResponseAllOf
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductVersionResponseAllOf
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductsQueuedForCreationResponse
 */
export interface ProductsQueuedForCreationResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductsQueuedForCreationResponse
     */
    'manufacturerDataExchangeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductsQueuedForCreationResponse
     */
    'manufacturerId'?: string;
    /**
     * 
     * @type {ExchangeDirection}
     * @memberof ProductsQueuedForCreationResponse
     */
    'direction'?: ExchangeDirection;
    /**
     * 
     * @type {string}
     * @memberof ProductsQueuedForCreationResponse
     */
    'entity'?: string;
    /**
     * 
     * @type {Operation}
     * @memberof ProductsQueuedForCreationResponse
     */
    'action'?: Operation;
    /**
     * 
     * @type {number}
     * @memberof ProductsQueuedForCreationResponse
     */
    'itemCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductsQueuedForCreationResponse
     */
    'createdAt'?: string;
}
/**
 * 
 * @export
 * @interface PromotionCodeResponse
 */
export interface PromotionCodeResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PromotionCodeResponse
     */
    'redeemed': boolean;
    /**
     * 
     * @type {string}
     * @memberof PromotionCodeResponse
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface PromotionDiscountResponse
 */
export interface PromotionDiscountResponse {
    /**
     * 
     * @type {string}
     * @memberof PromotionDiscountResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PromotionDiscountResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PromotionDiscountResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {DiscountType}
     * @memberof PromotionDiscountResponse
     */
    'type': DiscountType;
    /**
     * 
     * @type {number}
     * @memberof PromotionDiscountResponse
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof PromotionDiscountResponse
     */
    'maximumDiscountAmount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PromotionDiscountResponse
     */
    'promotionId': string;
    /**
     * 
     * @type {Array<SimpleProductResponse>}
     * @memberof PromotionDiscountResponse
     */
    'promotedProducts'?: Array<SimpleProductResponse> | null;
}
/**
 * 
 * @export
 * @interface PromotionDiscountResponseAllOf
 */
export interface PromotionDiscountResponseAllOf {
    /**
     * 
     * @type {DiscountType}
     * @memberof PromotionDiscountResponseAllOf
     */
    'type': DiscountType;
    /**
     * 
     * @type {number}
     * @memberof PromotionDiscountResponseAllOf
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof PromotionDiscountResponseAllOf
     */
    'maximumDiscountAmount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PromotionDiscountResponseAllOf
     */
    'promotionId': string;
    /**
     * 
     * @type {Array<SimpleProductResponse>}
     * @memberof PromotionDiscountResponseAllOf
     */
    'promotedProducts'?: Array<SimpleProductResponse> | null;
}
/**
 * 
 * @export
 * @interface PromotionResponse
 */
export interface PromotionResponse {
    /**
     * 
     * @type {string}
     * @memberof PromotionResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PromotionResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PromotionResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PromotionResponse
     */
    'archived': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PromotionResponse
     */
    'active': boolean;
    /**
     * 
     * @type {number}
     * @memberof PromotionResponse
     */
    'priority': number;
    /**
     * 
     * @type {string}
     * @memberof PromotionResponse
     */
    'validFrom': string;
    /**
     * 
     * @type {string}
     * @memberof PromotionResponse
     */
    'validTo': string;
    /**
     * 
     * @type {number}
     * @memberof PromotionResponse
     */
    'maxRedemptionsGlobal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PromotionResponse
     */
    'maxRedemptionsPerCustomer'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PromotionResponse
     */
    'preventCombination'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PromotionResponse
     */
    'isValidForWholeAssortment'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PromotionResponse
     */
    'code'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PromotionResponse
     */
    'useIndividualCodes'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PromotionResponse
     */
    'individualCodePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PromotionResponse
     */
    'individualCodeSuffix'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PromotionResponse
     */
    'individualCodeLength'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PromotionResponse
     */
    'numberOfPromotionCodes'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PromotionResponse
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {SimpleManufacturerResponse}
     * @memberof PromotionResponse
     */
    'manufacturer'?: SimpleManufacturerResponse | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof PromotionResponse
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {Array<PromotionDiscountResponse>}
     * @memberof PromotionResponse
     */
    'discounts'?: Array<PromotionDiscountResponse> | null;
    /**
     * 
     * @type {Array<PromotionCodeResponse>}
     * @memberof PromotionResponse
     */
    'individualCodes'?: Array<PromotionCodeResponse> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PromotionResponse
     */
    'excludedFromPromotionIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface PromotionResponseAllOf
 */
export interface PromotionResponseAllOf {
    /**
     * 
     * @type {boolean}
     * @memberof PromotionResponseAllOf
     */
    'archived': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PromotionResponseAllOf
     */
    'active': boolean;
    /**
     * 
     * @type {number}
     * @memberof PromotionResponseAllOf
     */
    'priority': number;
    /**
     * 
     * @type {string}
     * @memberof PromotionResponseAllOf
     */
    'validFrom': string;
    /**
     * 
     * @type {string}
     * @memberof PromotionResponseAllOf
     */
    'validTo': string;
    /**
     * 
     * @type {number}
     * @memberof PromotionResponseAllOf
     */
    'maxRedemptionsGlobal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PromotionResponseAllOf
     */
    'maxRedemptionsPerCustomer'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PromotionResponseAllOf
     */
    'preventCombination'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PromotionResponseAllOf
     */
    'isValidForWholeAssortment'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PromotionResponseAllOf
     */
    'code'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PromotionResponseAllOf
     */
    'useIndividualCodes'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PromotionResponseAllOf
     */
    'individualCodePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PromotionResponseAllOf
     */
    'individualCodeSuffix'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PromotionResponseAllOf
     */
    'individualCodeLength'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PromotionResponseAllOf
     */
    'numberOfPromotionCodes'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PromotionResponseAllOf
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {SimpleManufacturerResponse}
     * @memberof PromotionResponseAllOf
     */
    'manufacturer'?: SimpleManufacturerResponse | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof PromotionResponseAllOf
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {Array<PromotionDiscountResponse>}
     * @memberof PromotionResponseAllOf
     */
    'discounts'?: Array<PromotionDiscountResponse> | null;
    /**
     * 
     * @type {Array<PromotionCodeResponse>}
     * @memberof PromotionResponseAllOf
     */
    'individualCodes'?: Array<PromotionCodeResponse> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PromotionResponseAllOf
     */
    'excludedFromPromotionIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface PromotionTermsRequest
 */
export interface PromotionTermsRequest {
    /**
     * 
     * @type {string}
     * @memberof PromotionTermsRequest
     */
    'promotionId': string;
    /**
     * 
     * @type {string}
     * @memberof PromotionTermsRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface PromotionTermsResponse
 */
export interface PromotionTermsResponse {
    /**
     * 
     * @type {string}
     * @memberof PromotionTermsResponse
     */
    'promotionId': string;
    /**
     * 
     * @type {string}
     * @memberof PromotionTermsResponse
     */
    'terms': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PromotionTermsResponse
     */
    'productNumbers': Array<string>;
}
/**
 * 
 * @export
 * @interface PropertyGroupResponse
 */
export interface PropertyGroupResponse {
    /**
     * 
     * @type {string}
     * @memberof PropertyGroupResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyGroupResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyGroupResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PropertyGroupResponse
     */
    'position': number;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof PropertyGroupResponse
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof PropertyGroupResponse
     */
    'description'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {Array<PropertyOptionResponse>}
     * @memberof PropertyGroupResponse
     */
    'options': Array<PropertyOptionResponse>;
    /**
     * 
     * @type {string}
     * @memberof PropertyGroupResponse
     */
    'identifier': string;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyGroupResponse
     */
    'isLanguageNeutral': boolean;
    /**
     * 
     * @type {Array<AnimalSpeciesResponse>}
     * @memberof PropertyGroupResponse
     */
    'animalSpecies'?: Array<AnimalSpeciesResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyGroupResponse
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyGroupResponse
     */
    'productUnitId'?: string | null;
    /**
     * 
     * @type {ProductUnitResponse}
     * @memberof PropertyGroupResponse
     */
    'productUnit'?: ProductUnitResponse | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyGroupResponse
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface PropertyGroupResponseAllOf
 */
export interface PropertyGroupResponseAllOf {
    /**
     * 
     * @type {number}
     * @memberof PropertyGroupResponseAllOf
     */
    'position': number;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof PropertyGroupResponseAllOf
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof PropertyGroupResponseAllOf
     */
    'description'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {Array<PropertyOptionResponse>}
     * @memberof PropertyGroupResponseAllOf
     */
    'options': Array<PropertyOptionResponse>;
    /**
     * 
     * @type {string}
     * @memberof PropertyGroupResponseAllOf
     */
    'identifier': string;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyGroupResponseAllOf
     */
    'isLanguageNeutral': boolean;
    /**
     * 
     * @type {Array<AnimalSpeciesResponse>}
     * @memberof PropertyGroupResponseAllOf
     */
    'animalSpecies'?: Array<AnimalSpeciesResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyGroupResponseAllOf
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyGroupResponseAllOf
     */
    'productUnitId'?: string | null;
    /**
     * 
     * @type {ProductUnitResponse}
     * @memberof PropertyGroupResponseAllOf
     */
    'productUnit'?: ProductUnitResponse | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyGroupResponseAllOf
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface PropertyOptionResponse
 */
export interface PropertyOptionResponse {
    /**
     * 
     * @type {string}
     * @memberof PropertyOptionResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyOptionResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyOptionResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof PropertyOptionResponse
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof PropertyOptionResponse
     */
    'description'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {number}
     * @memberof PropertyOptionResponse
     */
    'position': number;
    /**
     * 
     * @type {string}
     * @memberof PropertyOptionResponse
     */
    'colorHexCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyOptionResponse
     */
    'assetId'?: string | null;
    /**
     * 
     * @type {AssetResponse}
     * @memberof PropertyOptionResponse
     */
    'asset'?: AssetResponse | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyOptionResponse
     */
    'propertyGroupId': string;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyOptionResponse
     */
    'isVariantOption': boolean;
    /**
     * 
     * @type {string}
     * @memberof PropertyOptionResponse
     */
    'identifier': string;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyOptionResponse
     */
    'isLocked': boolean;
    /**
     * 
     * @type {Array<AnimalSpeciesResponse>}
     * @memberof PropertyOptionResponse
     */
    'animalSpecies'?: Array<AnimalSpeciesResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyOptionResponse
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyOptionResponse
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface PropertyOptionResponseAllOf
 */
export interface PropertyOptionResponseAllOf {
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof PropertyOptionResponseAllOf
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof PropertyOptionResponseAllOf
     */
    'description'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {number}
     * @memberof PropertyOptionResponseAllOf
     */
    'position': number;
    /**
     * 
     * @type {string}
     * @memberof PropertyOptionResponseAllOf
     */
    'colorHexCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyOptionResponseAllOf
     */
    'assetId'?: string | null;
    /**
     * 
     * @type {AssetResponse}
     * @memberof PropertyOptionResponseAllOf
     */
    'asset'?: AssetResponse | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyOptionResponseAllOf
     */
    'propertyGroupId': string;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyOptionResponseAllOf
     */
    'isVariantOption': boolean;
    /**
     * 
     * @type {string}
     * @memberof PropertyOptionResponseAllOf
     */
    'identifier': string;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyOptionResponseAllOf
     */
    'isLocked': boolean;
    /**
     * 
     * @type {Array<AnimalSpeciesResponse>}
     * @memberof PropertyOptionResponseAllOf
     */
    'animalSpecies'?: Array<AnimalSpeciesResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyOptionResponseAllOf
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyOptionResponseAllOf
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PropertyValidationRuleCallable {
    IsIntegerBetween = 'IsIntegerBetween',
    IsDecimal = 'IsDecimal',
    IsValueOrSplitValue = 'IsValueOrSplitValue',
    IsInteger = 'IsInteger'
}

/**
 * 
 * @export
 * @interface PropertyValidationRuleResponse
 */
export interface PropertyValidationRuleResponse {
    /**
     * 
     * @type {string}
     * @memberof PropertyValidationRuleResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyValidationRuleResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PropertyValidationRuleResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyValidationRuleResponse
     */
    'name': string;
    /**
     * 
     * @type {PropertyValidationRuleCallable}
     * @memberof PropertyValidationRuleResponse
     */
    'callable'?: PropertyValidationRuleCallable | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PropertyValidationRuleResponse
     */
    'arguments'?: Array<string> | null;
    /**
     * 
     * @type {Array<ValidationConstraint>}
     * @memberof PropertyValidationRuleResponse
     */
    'constraints'?: Array<ValidationConstraint> | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyValidationRuleResponse
     */
    'objectPath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyValidationRuleResponse
     */
    'identifier'?: string | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof PropertyValidationRuleResponse
     */
    'message'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof PropertyValidationRuleResponse
     */
    'description'?: TranslatedTypeResponseOfString | null;
}
/**
 * 
 * @export
 * @interface PropertyValidationRuleResponseAllOf
 */
export interface PropertyValidationRuleResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof PropertyValidationRuleResponseAllOf
     */
    'name': string;
    /**
     * 
     * @type {PropertyValidationRuleCallable}
     * @memberof PropertyValidationRuleResponseAllOf
     */
    'callable'?: PropertyValidationRuleCallable | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PropertyValidationRuleResponseAllOf
     */
    'arguments'?: Array<string> | null;
    /**
     * 
     * @type {Array<ValidationConstraint>}
     * @memberof PropertyValidationRuleResponseAllOf
     */
    'constraints'?: Array<ValidationConstraint> | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyValidationRuleResponseAllOf
     */
    'objectPath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertyValidationRuleResponseAllOf
     */
    'identifier'?: string | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof PropertyValidationRuleResponseAllOf
     */
    'message'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof PropertyValidationRuleResponseAllOf
     */
    'description'?: TranslatedTypeResponseOfString | null;
}
/**
 * 
 * @export
 * @interface PublicAdyenPaymentRefundDetailResponse
 */
export interface PublicAdyenPaymentRefundDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof PublicAdyenPaymentRefundDetailResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PublicAdyenPaymentRefundDetailResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PublicAdyenPaymentRefundDetailResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PublicAdyenPaymentRefundDetailResponse
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof PublicAdyenPaymentRefundDetailResponse
     */
    'orderPositionId': string;
    /**
     * 
     * @type {number}
     * @memberof PublicAdyenPaymentRefundDetailResponse
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof PublicAdyenPaymentRefundDetailResponse
     */
    'orderReturnPositionId'?: string | null;
}
/**
 * 
 * @export
 * @interface PublicAdyenPaymentRefundDetailResponseAllOf
 */
export interface PublicAdyenPaymentRefundDetailResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof PublicAdyenPaymentRefundDetailResponseAllOf
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof PublicAdyenPaymentRefundDetailResponseAllOf
     */
    'orderPositionId': string;
    /**
     * 
     * @type {number}
     * @memberof PublicAdyenPaymentRefundDetailResponseAllOf
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof PublicAdyenPaymentRefundDetailResponseAllOf
     */
    'orderReturnPositionId'?: string | null;
}
/**
 * 
 * @export
 * @interface PublicManufacturerLegalDocument
 */
export interface PublicManufacturerLegalDocument {
    /**
     * 
     * @type {string}
     * @memberof PublicManufacturerLegalDocument
     */
    'manufacturerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicManufacturerLegalDocument
     */
    'slug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PublicManufacturerLegalDocument
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PublicManufacturerLegalDocument
     */
    'dataPrivacyUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PublicManufacturerLegalDocument
     */
    'termsAndConditionsUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface PublicOrderReturnPositionResponse
 */
export interface PublicOrderReturnPositionResponse {
    /**
     * 
     * @type {string}
     * @memberof PublicOrderReturnPositionResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PublicOrderReturnPositionResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PublicOrderReturnPositionResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PublicOrderReturnPositionResponse
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof PublicOrderReturnPositionResponse
     */
    'orderPositionId': string;
    /**
     * 
     * @type {string}
     * @memberof PublicOrderReturnPositionResponse
     */
    'orderReturnId': string;
    /**
     * 
     * @type {Array<PublicAdyenPaymentRefundDetailResponse>}
     * @memberof PublicOrderReturnPositionResponse
     */
    'adyenPaymentRefundDetails'?: Array<PublicAdyenPaymentRefundDetailResponse> | null;
}
/**
 * 
 * @export
 * @interface PublicOrderReturnPositionResponseAllOf
 */
export interface PublicOrderReturnPositionResponseAllOf {
    /**
     * 
     * @type {number}
     * @memberof PublicOrderReturnPositionResponseAllOf
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof PublicOrderReturnPositionResponseAllOf
     */
    'orderPositionId': string;
    /**
     * 
     * @type {string}
     * @memberof PublicOrderReturnPositionResponseAllOf
     */
    'orderReturnId': string;
    /**
     * 
     * @type {Array<PublicAdyenPaymentRefundDetailResponse>}
     * @memberof PublicOrderReturnPositionResponseAllOf
     */
    'adyenPaymentRefundDetails'?: Array<PublicAdyenPaymentRefundDetailResponse> | null;
}
/**
 * 
 * @export
 * @interface PublicOrderReturnResponse
 */
export interface PublicOrderReturnResponse {
    /**
     * 
     * @type {string}
     * @memberof PublicOrderReturnResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PublicOrderReturnResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PublicOrderReturnResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {Array<PublicOrderReturnPositionResponse>}
     * @memberof PublicOrderReturnResponse
     */
    'orderReturnPositions': Array<PublicOrderReturnPositionResponse>;
    /**
     * 
     * @type {string}
     * @memberof PublicOrderReturnResponse
     */
    'orderId': string;
    /**
     * 
     * @type {OrderReturnState}
     * @memberof PublicOrderReturnResponse
     */
    'state': OrderReturnState;
}
/**
 * 
 * @export
 * @interface PublicOrderReturnResponseAllOf
 */
export interface PublicOrderReturnResponseAllOf {
    /**
     * 
     * @type {Array<PublicOrderReturnPositionResponse>}
     * @memberof PublicOrderReturnResponseAllOf
     */
    'orderReturnPositions': Array<PublicOrderReturnPositionResponse>;
    /**
     * 
     * @type {string}
     * @memberof PublicOrderReturnResponseAllOf
     */
    'orderId': string;
    /**
     * 
     * @type {OrderReturnState}
     * @memberof PublicOrderReturnResponseAllOf
     */
    'state': OrderReturnState;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PurchaseOrderState {
    Open = 'Open',
    Review = 'Review',
    Closed = 'Closed'
}

/**
 * 
 * @export
 * @interface RemediatingAction
 */
export interface RemediatingAction {
    /**
     * 
     * @type {string}
     * @memberof RemediatingAction
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RemediatingAction
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface RemediatingAction2
 */
export interface RemediatingAction2 {
    /**
     * 
     * @type {string}
     * @memberof RemediatingAction2
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RemediatingAction2
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface RemoveUserRolePoliciesRequest
 */
export interface RemoveUserRolePoliciesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof RemoveUserRolePoliciesRequest
     */
    'policies': Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum RequestedLevelEnum {
    High = 'high',
    Low = 'low',
    Medium = 'medium',
    NotApplicable = 'notApplicable'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum RequestedLevelEnum2 {
    High = 'high',
    Low = 'low',
    Medium = 'medium',
    NotApplicable = 'notApplicable'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum RequestedLevelEnum3 {
    High = 'high',
    Low = 'low',
    Medium = 'medium',
    NotApplicable = 'notApplicable'
}

/**
 * 
 * @export
 * @interface ResourceReference
 */
export interface ResourceReference {
    /**
     * 
     * @type {string}
     * @memberof ResourceReference
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceReference
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResourceReference
     */
    'reference'?: string | null;
}
/**
 * 
 * @export
 * @interface ReturnLabelData
 */
export interface ReturnLabelData {
    /**
     * 
     * @type {string}
     * @memberof ReturnLabelData
     */
    'labelData'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnLabelData
     */
    'qrLabelData'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnLabelData
     */
    'routingCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReturnLabelData
     */
    'shipmentNumber'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ReturnReason {
    TooLarge = 'TooLarge',
    TooSmall = 'TooSmall',
    BadQuality = 'BadQuality',
    Dislike = 'Dislike',
    Defective = 'Defective',
    DoesNotFitDescription = 'DoesNotFitDescription',
    TooLate = 'TooLate',
    FoundCheaper = 'FoundCheaper',
    Other = 'Other'
}

/**
 * 
 * @export
 * @interface ReturningCustomersStatisticsResponse
 */
export interface ReturningCustomersStatisticsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ReturningCustomersStatisticsResponse
     */
    'guestAccount'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReturningCustomersStatisticsResponse
     */
    'numberOfOrders'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturningCustomersStatisticsResponse
     */
    'numberOfCustomers'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturningCustomersStatisticsResponse
     */
    'totalRevenue'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReturningCustomersStatisticsResponse
     */
    'totalCustomersPercentage'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReturningCustomersStatisticsResponse
     */
    'emails'?: Array<string> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum Severity {
    Error = 'Error',
    Warning = 'Warning',
    Info = 'Info'
}

/**
 * 
 * @export
 * @interface ShippingProviderResponse
 */
export interface ShippingProviderResponse {
    /**
     * 
     * @type {string}
     * @memberof ShippingProviderResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingProviderResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ShippingProviderResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShippingProviderResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ShippingProviderResponseAllOf
 */
export interface ShippingProviderResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof ShippingProviderResponseAllOf
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface SimpleAssetResponse
 */
export interface SimpleAssetResponse {
    /**
     * 
     * @type {string}
     * @memberof SimpleAssetResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleAssetResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleAssetResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleAssetResponse
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleAssetResponse
     */
    'uri': string;
}
/**
 * 
 * @export
 * @interface SimpleCustomerResponse
 */
export interface SimpleCustomerResponse {
    /**
     * 
     * @type {string}
     * @memberof SimpleCustomerResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleCustomerResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleCustomerResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleCustomerResponse
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleCustomerResponse
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleCustomerResponse
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleCustomerResponse
     */
    'lastName': string;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleCustomerResponse
     */
    'isGuest': boolean;
    /**
     * 
     * @type {CustomerAttributes}
     * @memberof SimpleCustomerResponse
     */
    'attributes'?: CustomerAttributes | null;
}
/**
 * 
 * @export
 * @interface SimpleCustomerResponseAllOf
 */
export interface SimpleCustomerResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof SimpleCustomerResponseAllOf
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleCustomerResponseAllOf
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleCustomerResponseAllOf
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleCustomerResponseAllOf
     */
    'lastName': string;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleCustomerResponseAllOf
     */
    'isGuest': boolean;
    /**
     * 
     * @type {CustomerAttributes}
     * @memberof SimpleCustomerResponseAllOf
     */
    'attributes'?: CustomerAttributes | null;
}
/**
 * 
 * @export
 * @interface SimpleManufacturerResponse
 */
export interface SimpleManufacturerResponse {
    /**
     * 
     * @type {string}
     * @memberof SimpleManufacturerResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleManufacturerResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleManufacturerResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleManufacturerResponse
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof SimpleManufacturerResponse
     */
    'customerNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleManufacturerResponse
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleManufacturerResponse
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleManufacturerResponse
     */
    'shortCode': string;
    /**
     * 
     * @type {SimpleAssetResponse}
     * @memberof SimpleManufacturerResponse
     */
    'logoAsset'?: SimpleAssetResponse | null;
}
/**
 * 
 * @export
 * @interface SimpleManufacturerResponseAllOf
 */
export interface SimpleManufacturerResponseAllOf {
    /**
     * 
     * @type {boolean}
     * @memberof SimpleManufacturerResponseAllOf
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof SimpleManufacturerResponseAllOf
     */
    'customerNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleManufacturerResponseAllOf
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleManufacturerResponseAllOf
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleManufacturerResponseAllOf
     */
    'shortCode': string;
    /**
     * 
     * @type {SimpleAssetResponse}
     * @memberof SimpleManufacturerResponseAllOf
     */
    'logoAsset'?: SimpleAssetResponse | null;
}
/**
 * 
 * @export
 * @interface SimpleOrderResponse
 */
export interface SimpleOrderResponse {
    /**
     * 
     * @type {string}
     * @memberof SimpleOrderResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleOrderResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleOrderResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleOrderResponse
     */
    'orderNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleOrderResponse
     */
    'orderDateTime': string;
    /**
     * 
     * @type {number}
     * @memberof SimpleOrderResponse
     */
    'amountTotal': number;
    /**
     * 
     * @type {string}
     * @memberof SimpleOrderResponse
     */
    'originOrderId'?: string | null;
    /**
     * 
     * @type {Array<OrderIdNumberMappingResponse>}
     * @memberof SimpleOrderResponse
     */
    'splitOrders'?: Array<OrderIdNumberMappingResponse> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SimpleOrderResponse
     */
    'splitOrderIds'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof SimpleOrderResponse
     */
    'fiegeSalesOrderNumber'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleOrderResponse
     */
    'initialPaymentMethodBrand'?: string | null;
    /**
     * 
     * @type {SimpleCustomerResponse}
     * @memberof SimpleOrderResponse
     */
    'customer': SimpleCustomerResponse;
    /**
     * 
     * @type {SimpleManufacturerResponse}
     * @memberof SimpleOrderResponse
     */
    'manufacturer': SimpleManufacturerResponse;
    /**
     * 
     * @type {SimpleWarehouseResponse}
     * @memberof SimpleOrderResponse
     */
    'warehouse': SimpleWarehouseResponse;
    /**
     * 
     * @type {OrderStateTechnicalName}
     * @memberof SimpleOrderResponse
     */
    'currentOrderStateTechnicalName'?: OrderStateTechnicalName | null;
    /**
     * 
     * @type {OrderStateTechnicalName}
     * @memberof SimpleOrderResponse
     */
    'currentTransactionStateTechnicalName'?: OrderStateTechnicalName | null;
    /**
     * 
     * @type {OrderStateTechnicalName}
     * @memberof SimpleOrderResponse
     */
    'currentShippingStateTechnicalName'?: OrderStateTechnicalName | null;
}
/**
 * 
 * @export
 * @interface SimpleOrderResponseAllOf
 */
export interface SimpleOrderResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof SimpleOrderResponseAllOf
     */
    'orderNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleOrderResponseAllOf
     */
    'orderDateTime': string;
    /**
     * 
     * @type {number}
     * @memberof SimpleOrderResponseAllOf
     */
    'amountTotal': number;
    /**
     * 
     * @type {string}
     * @memberof SimpleOrderResponseAllOf
     */
    'originOrderId'?: string | null;
    /**
     * 
     * @type {Array<OrderIdNumberMappingResponse>}
     * @memberof SimpleOrderResponseAllOf
     */
    'splitOrders'?: Array<OrderIdNumberMappingResponse> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SimpleOrderResponseAllOf
     */
    'splitOrderIds'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof SimpleOrderResponseAllOf
     */
    'fiegeSalesOrderNumber'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleOrderResponseAllOf
     */
    'initialPaymentMethodBrand'?: string | null;
    /**
     * 
     * @type {SimpleCustomerResponse}
     * @memberof SimpleOrderResponseAllOf
     */
    'customer': SimpleCustomerResponse;
    /**
     * 
     * @type {SimpleManufacturerResponse}
     * @memberof SimpleOrderResponseAllOf
     */
    'manufacturer': SimpleManufacturerResponse;
    /**
     * 
     * @type {SimpleWarehouseResponse}
     * @memberof SimpleOrderResponseAllOf
     */
    'warehouse': SimpleWarehouseResponse;
    /**
     * 
     * @type {OrderStateTechnicalName}
     * @memberof SimpleOrderResponseAllOf
     */
    'currentOrderStateTechnicalName'?: OrderStateTechnicalName | null;
    /**
     * 
     * @type {OrderStateTechnicalName}
     * @memberof SimpleOrderResponseAllOf
     */
    'currentTransactionStateTechnicalName'?: OrderStateTechnicalName | null;
    /**
     * 
     * @type {OrderStateTechnicalName}
     * @memberof SimpleOrderResponseAllOf
     */
    'currentShippingStateTechnicalName'?: OrderStateTechnicalName | null;
}
/**
 * 
 * @export
 * @interface SimpleProductResponse
 */
export interface SimpleProductResponse {
    /**
     * 
     * @type {string}
     * @memberof SimpleProductResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleProductResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleProductResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof SimpleProductResponse
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {string}
     * @memberof SimpleProductResponse
     */
    'ean': string;
    /**
     * 
     * @type {ProductState}
     * @memberof SimpleProductResponse
     */
    'state': ProductState;
    /**
     * 
     * @type {string}
     * @memberof SimpleProductResponse
     */
    'activeProductVersionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleProductResponse
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleProductResponse
     */
    'productNumber': string;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleProductResponse
     */
    'hasVariants'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleProductResponse
     */
    'manufacturerProductNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleProductResponse
     */
    'coverId'?: string | null;
    /**
     * 
     * @type {ProductAssetResponse}
     * @memberof SimpleProductResponse
     */
    'cover'?: ProductAssetResponse | null;
    /**
     * 
     * @type {Array<PropertyOptionResponse>}
     * @memberof SimpleProductResponse
     */
    'variantOptions'?: Array<PropertyOptionResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleProductResponse
     */
    'manufacturerId': string;
    /**
     * 
     * @type {ProductGroupResponse}
     * @memberof SimpleProductResponse
     */
    'productGroup'?: ProductGroupResponse | null;
    /**
     * 
     * @type {number}
     * @memberof SimpleProductResponse
     */
    'inStockQuantity'?: number;
}
/**
 * 
 * @export
 * @interface SimpleProductResponseAllOf
 */
export interface SimpleProductResponseAllOf {
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof SimpleProductResponseAllOf
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {string}
     * @memberof SimpleProductResponseAllOf
     */
    'ean': string;
    /**
     * 
     * @type {ProductState}
     * @memberof SimpleProductResponseAllOf
     */
    'state': ProductState;
    /**
     * 
     * @type {string}
     * @memberof SimpleProductResponseAllOf
     */
    'activeProductVersionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleProductResponseAllOf
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleProductResponseAllOf
     */
    'productNumber': string;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleProductResponseAllOf
     */
    'hasVariants'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleProductResponseAllOf
     */
    'manufacturerProductNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleProductResponseAllOf
     */
    'coverId'?: string | null;
    /**
     * 
     * @type {ProductAssetResponse}
     * @memberof SimpleProductResponseAllOf
     */
    'cover'?: ProductAssetResponse | null;
    /**
     * 
     * @type {Array<PropertyOptionResponse>}
     * @memberof SimpleProductResponseAllOf
     */
    'variantOptions'?: Array<PropertyOptionResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleProductResponseAllOf
     */
    'manufacturerId': string;
    /**
     * 
     * @type {ProductGroupResponse}
     * @memberof SimpleProductResponseAllOf
     */
    'productGroup'?: ProductGroupResponse | null;
    /**
     * 
     * @type {number}
     * @memberof SimpleProductResponseAllOf
     */
    'inStockQuantity'?: number;
}
/**
 * 
 * @export
 * @interface SimpleWarehouseResponse
 */
export interface SimpleWarehouseResponse {
    /**
     * 
     * @type {string}
     * @memberof SimpleWarehouseResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleWarehouseResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleWarehouseResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleWarehouseResponse
     */
    'name': string;
    /**
     * 
     * @type {WarehouseType}
     * @memberof SimpleWarehouseResponse
     */
    'type': WarehouseType;
    /**
     * 
     * @type {string}
     * @memberof SimpleWarehouseResponse
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface SoldProductResponse
 */
export interface SoldProductResponse {
    /**
     * 
     * @type {string}
     * @memberof SoldProductResponse
     */
    'productId': string;
    /**
     * 
     * @type {SimpleProductResponse}
     * @memberof SoldProductResponse
     */
    'product'?: SimpleProductResponse | null;
    /**
     * 
     * @type {number}
     * @memberof SoldProductResponse
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof SoldProductResponse
     */
    'totalRevenue': number;
}
/**
 * 
 * @export
 * @interface SoleProprietorship
 */
export interface SoleProprietorship {
    /**
     * 
     * @type {VatAbsenceReasonEnum2}
     * @memberof SoleProprietorship
     */
    'vatAbsenceReason'?: VatAbsenceReasonEnum2 | null;
    /**
     * 
     * @type {string}
     * @memberof SoleProprietorship
     */
    'countryOfGoverningLaw'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SoleProprietorship
     */
    'dateOfIncorporation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SoleProprietorship
     */
    'doingBusinessAs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SoleProprietorship
     */
    'name'?: string | null;
    /**
     * 
     * @type {Address2}
     * @memberof SoleProprietorship
     */
    'principalPlaceOfBusiness'?: Address2 | null;
    /**
     * 
     * @type {Address2}
     * @memberof SoleProprietorship
     */
    'registeredAddress'?: Address2 | null;
    /**
     * 
     * @type {string}
     * @memberof SoleProprietorship
     */
    'registrationNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SoleProprietorship
     */
    'taxAbsent'?: boolean | null;
    /**
     * 
     * @type {Array<TaxInformation>}
     * @memberof SoleProprietorship
     */
    'taxInformation'?: Array<TaxInformation> | null;
    /**
     * 
     * @type {string}
     * @memberof SoleProprietorship
     */
    'vatNumber'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum StatusEnum {
    Booked = 'booked',
    Pending = 'pending'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum StatusEnum2 {
    Active = 'active',
    Closed = 'closed',
    Inactive = 'inactive',
    Suspended = 'suspended'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum StatusEnum3 {
    Active = 'active',
    Closed = 'closed',
    Suspended = 'suspended'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum StatusEnum4 {
    Active = 'active',
    Closed = 'closed',
    Inactive = 'inactive',
    Suspended = 'suspended'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum StatusEnum5 {
    Active = 'active',
    Closed = 'closed',
    Suspended = 'suspended'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum StatusEnum6 {
    Active = 'active',
    Closed = 'closed',
    Inactive = 'inactive',
    Suspended = 'suspended'
}

/**
 * 
 * @export
 * @interface StockData
 */
export interface StockData {
    /**
     * 
     * @type {string}
     * @memberof StockData
     */
    'marketIdentifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockData
     */
    'stockNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockData
     */
    'tickerSymbol'?: string | null;
}
/**
 * 
 * @export
 * @interface SupportingEntityCapability
 */
export interface SupportingEntityCapability {
    /**
     * 
     * @type {boolean}
     * @memberof SupportingEntityCapability
     */
    'allowed'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SupportingEntityCapability
     */
    'id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SupportingEntityCapability
     */
    'requested'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SupportingEntityCapability
     */
    'verificationStatus'?: string | null;
}
/**
 * 
 * @export
 * @interface SynchronizationActionResponse
 */
export interface SynchronizationActionResponse {
    /**
     * 
     * @type {object}
     * @memberof SynchronizationActionResponse
     */
    'created'?: object | null;
    /**
     * 
     * @type {object}
     * @memberof SynchronizationActionResponse
     */
    'updated'?: object | null;
    /**
     * 
     * @type {object}
     * @memberof SynchronizationActionResponse
     */
    'deleted'?: object | null;
}
/**
 * 
 * @export
 * @interface TagResponse
 */
export interface TagResponse {
    /**
     * 
     * @type {string}
     * @memberof TagResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TagResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TagResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof TagResponse
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {string}
     * @memberof TagResponse
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TagResponse
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TagResponse
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface TagResponseAllOf
 */
export interface TagResponseAllOf {
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof TagResponseAllOf
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {string}
     * @memberof TagResponseAllOf
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TagResponseAllOf
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TagResponseAllOf
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface TaxInformation
 */
export interface TaxInformation {
    /**
     * 
     * @type {string}
     * @memberof TaxInformation
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxInformation
     */
    'number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxInformation
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface TaxReportingClassification
 */
export interface TaxReportingClassification {
    /**
     * 
     * @type {BusinessTypeEnum}
     * @memberof TaxReportingClassification
     */
    'businessType'?: BusinessTypeEnum | null;
    /**
     * 
     * @type {MainSourceOfIncomeEnum}
     * @memberof TaxReportingClassification
     */
    'mainSourceOfIncome'?: MainSourceOfIncomeEnum | null;
    /**
     * 
     * @type {TypeEnum20}
     * @memberof TaxReportingClassification
     */
    'type'?: TypeEnum20 | null;
    /**
     * 
     * @type {string}
     * @memberof TaxReportingClassification
     */
    'financialInstitutionNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface TaxResponse
 */
export interface TaxResponse {
    /**
     * 
     * @type {string}
     * @memberof TaxResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TaxResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TaxResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TaxResponse
     */
    'taxRate': number;
    /**
     * 
     * @type {string}
     * @memberof TaxResponse
     */
    'countryId': string;
    /**
     * 
     * @type {CountryResponse}
     * @memberof TaxResponse
     */
    'country': CountryResponse;
    /**
     * 
     * @type {string}
     * @memberof TaxResponse
     */
    'taxTypeId': string;
    /**
     * 
     * @type {TaxTypeResponse}
     * @memberof TaxResponse
     */
    'taxType': TaxTypeResponse;
    /**
     * 
     * @type {string}
     * @memberof TaxResponse
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxResponse
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface TaxResponseAllOf
 */
export interface TaxResponseAllOf {
    /**
     * 
     * @type {number}
     * @memberof TaxResponseAllOf
     */
    'taxRate': number;
    /**
     * 
     * @type {string}
     * @memberof TaxResponseAllOf
     */
    'countryId': string;
    /**
     * 
     * @type {CountryResponse}
     * @memberof TaxResponseAllOf
     */
    'country': CountryResponse;
    /**
     * 
     * @type {string}
     * @memberof TaxResponseAllOf
     */
    'taxTypeId': string;
    /**
     * 
     * @type {TaxTypeResponse}
     * @memberof TaxResponseAllOf
     */
    'taxType': TaxTypeResponse;
    /**
     * 
     * @type {string}
     * @memberof TaxResponseAllOf
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxResponseAllOf
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface TaxRuleResponse
 */
export interface TaxRuleResponse {
    /**
     * 
     * @type {number}
     * @memberof TaxRuleResponse
     */
    'taxRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof TaxRuleResponse
     */
    'percentage'?: number;
    /**
     * 
     * @type {Array<any>}
     * @memberof TaxRuleResponse
     */
    'extensions'?: Array<any> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum TaxTypeIdentifier {
    Free = 'Free',
    Reduced = 'Reduced',
    Full = 'Full'
}

/**
 * 
 * @export
 * @interface TaxTypeResponse
 */
export interface TaxTypeResponse {
    /**
     * 
     * @type {string}
     * @memberof TaxTypeResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TaxTypeResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TaxTypeResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof TaxTypeResponse
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TaxTypeIdentifier}
     * @memberof TaxTypeResponse
     */
    'identifier': TaxTypeIdentifier;
    /**
     * 
     * @type {string}
     * @memberof TaxTypeResponse
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxTypeResponse
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface TaxTypeResponseAllOf
 */
export interface TaxTypeResponseAllOf {
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof TaxTypeResponseAllOf
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TaxTypeIdentifier}
     * @memberof TaxTypeResponseAllOf
     */
    'identifier': TaxTypeIdentifier;
    /**
     * 
     * @type {string}
     * @memberof TaxTypeResponseAllOf
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxTypeResponseAllOf
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * 
     * @type {StatusEnum}
     * @memberof Transaction
     */
    'status'?: StatusEnum;
    /**
     * 
     * @type {ResourceReference}
     * @memberof Transaction
     */
    'accountHolder'?: ResourceReference | null;
    /**
     * 
     * @type {Amount}
     * @memberof Transaction
     */
    'amount'?: Amount | null;
    /**
     * 
     * @type {ResourceReference}
     * @memberof Transaction
     */
    'balanceAccount'?: ResourceReference | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'balancePlatform'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'bookingDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'id'?: string | null;
    /**
     * 
     * @type {PaymentInstrument}
     * @memberof Transaction
     */
    'paymentInstrument'?: PaymentInstrument | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'referenceForBeneficiary'?: string | null;
    /**
     * 
     * @type {TransferView}
     * @memberof Transaction
     */
    'transfer'?: TransferView | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'valueDate'?: string;
}
/**
 * 
 * @export
 * @interface TransferCategoryData
 */
export interface TransferCategoryData {
    /**
     * 
     * @type {any}
     * @memberof TransferCategoryData
     */
    'actualInstance'?: any | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransferCategoryData
     */
    'isNullable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransferCategoryData
     */
    'schemaType'?: string | null;
}
/**
 * 
 * @export
 * @interface TransferCategoryDataAllOf
 */
export interface TransferCategoryDataAllOf {
    /**
     * 
     * @type {any}
     * @memberof TransferCategoryDataAllOf
     */
    'actualInstance'?: any | null;
}
/**
 * 
 * @export
 * @interface TransferInstrument
 */
export interface TransferInstrument {
    /**
     * 
     * @type {TypeEnum3}
     * @memberof TransferInstrument
     */
    'type'?: TypeEnum3;
    /**
     * 
     * @type {BankAccountInfo}
     * @memberof TransferInstrument
     */
    'bankAccount'?: BankAccountInfo | null;
    /**
     * 
     * @type {{ [key: string]: SupportingEntityCapability; }}
     * @memberof TransferInstrument
     */
    'capabilities'?: { [key: string]: SupportingEntityCapability; } | null;
    /**
     * 
     * @type {Array<DocumentReference>}
     * @memberof TransferInstrument
     */
    'documentDetails'?: Array<DocumentReference> | null;
    /**
     * 
     * @type {string}
     * @memberof TransferInstrument
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransferInstrument
     */
    'legalEntityId'?: string | null;
    /**
     * 
     * @type {Array<CapabilityProblem>}
     * @memberof TransferInstrument
     */
    'problems'?: Array<CapabilityProblem> | null;
}
/**
 * 
 * @export
 * @interface TransferInstrumentInfo
 */
export interface TransferInstrumentInfo {
    /**
     * 
     * @type {TypeEnum9}
     * @memberof TransferInstrumentInfo
     */
    'type'?: TypeEnum9;
    /**
     * 
     * @type {BankAccountInfo}
     * @memberof TransferInstrumentInfo
     */
    'bankAccount'?: BankAccountInfo | null;
    /**
     * 
     * @type {string}
     * @memberof TransferInstrumentInfo
     */
    'legalEntityId'?: string | null;
}
/**
 * 
 * @export
 * @interface TransferInstrumentReference
 */
export interface TransferInstrumentReference {
    /**
     * 
     * @type {string}
     * @memberof TransferInstrumentReference
     */
    'accountIdentifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransferInstrumentReference
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransferInstrumentReference
     */
    'realLastFour'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransferInstrumentReference
     */
    'trustedSource'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TransferView
 */
export interface TransferView {
    /**
     * 
     * @type {TransferCategoryData}
     * @memberof TransferView
     */
    'categoryData'?: TransferCategoryData | null;
    /**
     * 
     * @type {string}
     * @memberof TransferView
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransferView
     */
    'reference'?: string | null;
}
/**
 * 
 * @export
 * @interface TransformedProductRequest
 */
export interface TransformedProductRequest {
    /**
     * 
     * @type {string}
     * @memberof TransformedProductRequest
     */
    'id'?: string;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof TransformedProductRequest
     */
    'name'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof TransformedProductRequest
     */
    'description'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {string}
     * @memberof TransformedProductRequest
     */
    'ean'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransformedProductRequest
     */
    'releaseDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransformedProductRequest
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {Array<TransformedProductRequest>}
     * @memberof TransformedProductRequest
     */
    'variants'?: Array<TransformedProductRequest> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TransformedProductRequest
     */
    'propertyIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof TransformedProductRequest
     */
    'manufacturerProductNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransformedProductRequest
     */
    'restockTimeDays'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransformedProductRequest
     */
    'isCloseout'?: boolean | null;
    /**
     * 
     * @type {TaxTypeIdentifier}
     * @memberof TransformedProductRequest
     */
    'taxTypeIdentifier'?: TaxTypeIdentifier | null;
    /**
     * 
     * @type {number}
     * @memberof TransformedProductRequest
     */
    'purchaseSteps'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransformedProductRequest
     */
    'minPurchase'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransformedProductRequest
     */
    'maxPurchase'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransformedProductRequest
     */
    'purchaseUnit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransformedProductRequest
     */
    'referenceUnit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransformedProductRequest
     */
    'weight'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransformedProductRequest
     */
    'width'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransformedProductRequest
     */
    'height'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransformedProductRequest
     */
    'length'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransformedProductRequest
     */
    'productUnitId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransformedProductRequest
     */
    'isShippingFree'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TransformedProductRequest
     */
    'tagIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TransformedProductRequest
     */
    'categoryIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof TransformedProductRequest
     */
    'productGroupId'?: string | null;
}
/**
 * 
 * @export
 * @interface TransformedProductRequiredFieldsResponse
 */
export interface TransformedProductRequiredFieldsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof TransformedProductRequiredFieldsResponse
     */
    'requiredFields'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface TransformedProductResponse
 */
export interface TransformedProductResponse {
    /**
     * 
     * @type {string}
     * @memberof TransformedProductResponse
     */
    'id'?: string;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof TransformedProductResponse
     */
    'name'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof TransformedProductResponse
     */
    'description'?: TranslatedTypeResponseOfString | null;
    /**
     * 
     * @type {string}
     * @memberof TransformedProductResponse
     */
    'ean'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransformedProductResponse
     */
    'releaseDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransformedProductResponse
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TransformedProductResponse
     */
    'propertyIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof TransformedProductResponse
     */
    'manufacturerProductNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransformedProductResponse
     */
    'restockTimeDays'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransformedProductResponse
     */
    'isCloseout'?: boolean | null;
    /**
     * 
     * @type {TaxTypeIdentifier}
     * @memberof TransformedProductResponse
     */
    'taxTypeIdentifier'?: TaxTypeIdentifier | null;
    /**
     * 
     * @type {number}
     * @memberof TransformedProductResponse
     */
    'purchaseSteps'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransformedProductResponse
     */
    'minPurchase'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransformedProductResponse
     */
    'maxPurchase'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransformedProductResponse
     */
    'purchaseUnit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransformedProductResponse
     */
    'referenceUnit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransformedProductResponse
     */
    'weight'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransformedProductResponse
     */
    'width'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransformedProductResponse
     */
    'height'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TransformedProductResponse
     */
    'length'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransformedProductResponse
     */
    'productUnitId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransformedProductResponse
     */
    'isShippingFree'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TransformedProductResponse
     */
    'tagIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TransformedProductResponse
     */
    'categoryIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof TransformedProductResponse
     */
    'productGroupId'?: string | null;
    /**
     * 
     * @type {ValidationResultResponse}
     * @memberof TransformedProductResponse
     */
    'validationResult'?: ValidationResultResponse | null;
}
/**
 * 
 * @export
 * @interface TranslatedTypeRequestOfDictionaryOfStringAndString
 */
export interface TranslatedTypeRequestOfDictionaryOfStringAndString {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TranslatedTypeRequestOfDictionaryOfStringAndString
     */
    'de-DE'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TranslatedTypeRequestOfDictionaryOfStringAndString
     */
    'en-GB'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface TranslatedTypeRequestOfGuid
 */
export interface TranslatedTypeRequestOfGuid {
    /**
     * 
     * @type {string}
     * @memberof TranslatedTypeRequestOfGuid
     */
    'de-DE'?: string;
    /**
     * 
     * @type {string}
     * @memberof TranslatedTypeRequestOfGuid
     */
    'en-GB'?: string;
}
/**
 * 
 * @export
 * @interface TranslatedTypeRequestOfIEnumerableOfGuid
 */
export interface TranslatedTypeRequestOfIEnumerableOfGuid {
    /**
     * 
     * @type {Array<string>}
     * @memberof TranslatedTypeRequestOfIEnumerableOfGuid
     */
    'de-DE'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TranslatedTypeRequestOfIEnumerableOfGuid
     */
    'en-GB'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface TranslatedTypeRequestOfListOfGuid
 */
export interface TranslatedTypeRequestOfListOfGuid {
    /**
     * 
     * @type {Array<string>}
     * @memberof TranslatedTypeRequestOfListOfGuid
     */
    'de-DE'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TranslatedTypeRequestOfListOfGuid
     */
    'en-GB'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface TranslatedTypeRequestOfNullableGuid
 */
export interface TranslatedTypeRequestOfNullableGuid {
    /**
     * 
     * @type {string}
     * @memberof TranslatedTypeRequestOfNullableGuid
     */
    'de-DE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TranslatedTypeRequestOfNullableGuid
     */
    'en-GB'?: string | null;
}
/**
 * 
 * @export
 * @interface TranslatedTypeRequestOfString
 */
export interface TranslatedTypeRequestOfString {
    /**
     * 
     * @type {string}
     * @memberof TranslatedTypeRequestOfString
     */
    'de-DE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TranslatedTypeRequestOfString
     */
    'en-GB'?: string | null;
}
/**
 * 
 * @export
 * @interface TranslatedTypeRequestOfStringOf
 */
export interface TranslatedTypeRequestOfStringOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof TranslatedTypeRequestOfStringOf
     */
    'de-DE'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TranslatedTypeRequestOfStringOf
     */
    'en-GB'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface TranslatedTypeRequestOfStringOfOf
 */
export interface TranslatedTypeRequestOfStringOfOf {
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof TranslatedTypeRequestOfStringOfOf
     */
    'de-DE'?: Array<Array<string>> | null;
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof TranslatedTypeRequestOfStringOfOf
     */
    'en-GB'?: Array<Array<string>> | null;
}
/**
 * 
 * @export
 * @interface TranslatedTypeResponseOfAssetResponse
 */
export interface TranslatedTypeResponseOfAssetResponse {
    /**
     * 
     * @type {AssetResponse}
     * @memberof TranslatedTypeResponseOfAssetResponse
     */
    'de-DE'?: AssetResponse | null;
    /**
     * 
     * @type {AssetResponse}
     * @memberof TranslatedTypeResponseOfAssetResponse
     */
    'en-GB'?: AssetResponse | null;
}
/**
 * 
 * @export
 * @interface TranslatedTypeResponseOfDictionaryOfStringAndString
 */
export interface TranslatedTypeResponseOfDictionaryOfStringAndString {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TranslatedTypeResponseOfDictionaryOfStringAndString
     */
    'de-DE'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TranslatedTypeResponseOfDictionaryOfStringAndString
     */
    'en-GB'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface TranslatedTypeResponseOfNullableGuid
 */
export interface TranslatedTypeResponseOfNullableGuid {
    /**
     * 
     * @type {string}
     * @memberof TranslatedTypeResponseOfNullableGuid
     */
    'de-DE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TranslatedTypeResponseOfNullableGuid
     */
    'en-GB'?: string | null;
}
/**
 * 
 * @export
 * @interface TranslatedTypeResponseOfString
 */
export interface TranslatedTypeResponseOfString {
    /**
     * 
     * @type {string}
     * @memberof TranslatedTypeResponseOfString
     */
    'de-DE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TranslatedTypeResponseOfString
     */
    'en-GB'?: string | null;
}
/**
 * 
 * @export
 * @interface Trust
 */
export interface Trust {
    /**
     * 
     * @type {TypeEnum21}
     * @memberof Trust
     */
    'type'?: TypeEnum21;
    /**
     * 
     * @type {VatAbsenceReasonEnum3}
     * @memberof Trust
     */
    'vatAbsenceReason'?: VatAbsenceReasonEnum3 | null;
    /**
     * 
     * @type {string}
     * @memberof Trust
     */
    'countryOfGoverningLaw'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Trust
     */
    'dateOfIncorporation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Trust
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Trust
     */
    'doingBusinessAs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Trust
     */
    'name'?: string | null;
    /**
     * 
     * @type {Address2}
     * @memberof Trust
     */
    'principalPlaceOfBusiness'?: Address2 | null;
    /**
     * 
     * @type {Address2}
     * @memberof Trust
     */
    'registeredAddress'?: Address2 | null;
    /**
     * 
     * @type {string}
     * @memberof Trust
     */
    'registrationNumber'?: string | null;
    /**
     * 
     * @type {Array<TaxInformation>}
     * @memberof Trust
     */
    'taxInformation'?: Array<TaxInformation> | null;
    /**
     * 
     * @type {Array<UndefinedBeneficiary>}
     * @memberof Trust
     */
    'undefinedBeneficiaryInfo'?: Array<UndefinedBeneficiary> | null;
    /**
     * 
     * @type {string}
     * @memberof Trust
     */
    'vatNumber'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum TypeEnum {
    BalanceAccount = 'BalanceAccount',
    Commission = 'Commission',
    Default = 'Default',
    MarketPlace = 'MarketPlace',
    PaymentFee = 'PaymentFee',
    Vat = 'VAT',
    Verification = 'Verification'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TypeEnum10 {
    Individual = 'individual',
    Organization = 'organization',
    SoleProprietorship = 'soleProprietorship',
    Trust = 'trust',
    UnincorporatedPartnership = 'unincorporatedPartnership'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TypeEnum11 {
    BankAccount = 'BankAccount',
    Document = 'Document',
    LegalEntity = 'LegalEntity'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TypeEnum12 {
    BankAccount = 'BankAccount',
    Document = 'Document',
    LegalEntity = 'LegalEntity'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TypeEnum13 {
    DataMissing = 'dataMissing',
    InvalidInput = 'invalidInput',
    PendingStatus = 'pendingStatus'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TypeEnum14 {
    DataMissing = 'dataMissing',
    InvalidInput = 'invalidInput',
    PendingStatus = 'pendingStatus'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TypeEnum15 {
    Landline = 'landline',
    Mobile = 'mobile'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TypeEnum16 {
    Individual = 'individual',
    Organization = 'organization',
    SoleProprietorship = 'soleProprietorship',
    Trust = 'trust',
    UnincorporatedPartnership = 'unincorporatedPartnership'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TypeEnum17 {
    DefinedBeneficiary = 'definedBeneficiary',
    Director = 'director',
    ImmediateParentCompany = 'immediateParentCompany',
    LegalRepresentative = 'legalRepresentative',
    PciSignatory = 'pciSignatory',
    Protector = 'protector',
    SecondaryPartner = 'secondaryPartner',
    SecondaryTrustee = 'secondaryTrustee',
    Settlor = 'settlor',
    Signatory = 'signatory',
    SoleProprietorship = 'soleProprietorship',
    Trust = 'trust',
    TrustOwnership = 'trustOwnership',
    UboThroughControl = 'uboThroughControl',
    UboThroughOwnership = 'uboThroughOwnership',
    UltimateParentCompany = 'ultimateParentCompany',
    UndefinedBeneficiary = 'undefinedBeneficiary',
    UnincorporatedPartnership = 'unincorporatedPartnership'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TypeEnum18 {
    NationalIdNumber = 'nationalIdNumber',
    Passport = 'passport',
    DriversLicense = 'driversLicense',
    IdentityCard = 'identityCard'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TypeEnum19 {
    AssociationIncorporated = 'associationIncorporated',
    GovernmentalOrganization = 'governmentalOrganization',
    ListedPublicCompany = 'listedPublicCompany',
    NonProfit = 'nonProfit',
    PartnershipIncorporated = 'partnershipIncorporated',
    PrivateCompany = 'privateCompany'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TypeEnum2 {
    Iban = 'iban'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TypeEnum20 {
    NonFinancialNonReportable = 'nonFinancialNonReportable',
    FinancialNonReportable = 'financialNonReportable',
    NonFinancialActive = 'nonFinancialActive',
    NonFinancialPassive = 'nonFinancialPassive'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TypeEnum21 {
    BusinessTrust = 'businessTrust',
    CashManagementTrust = 'cashManagementTrust',
    CharitableTrust = 'charitableTrust',
    CorporateUnitTrust = 'corporateUnitTrust',
    DeceasedEstate = 'deceasedEstate',
    DiscretionaryTrust = 'discretionaryTrust',
    DiscretionaryInvestmentTrust = 'discretionaryInvestmentTrust',
    DiscretionaryServicesManagementTrust = 'discretionaryServicesManagementTrust',
    DiscretionaryTradingTrust = 'discretionaryTradingTrust',
    FamilyTrust = 'familyTrust',
    FirstHomeSaverAccountsTrust = 'firstHomeSaverAccountsTrust',
    FixedTrust = 'fixedTrust',
    FixedUnitTrust = 'fixedUnitTrust',
    HybridTrust = 'hybridTrust',
    ListedPublicUnitTrust = 'listedPublicUnitTrust',
    OtherTrust = 'otherTrust',
    PooledSuperannuationTrust = 'pooledSuperannuationTrust',
    PublicTradingTrust = 'publicTradingTrust',
    UnlistedPublicUnitTrust = 'unlistedPublicUnitTrust'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TypeEnum22 {
    LimitedPartnership = 'limitedPartnership',
    GeneralPartnership = 'generalPartnership',
    FamilyPartnership = 'familyPartnership',
    CommercialPartnership = 'commercialPartnership',
    PublicPartnership = 'publicPartnership',
    OtherPartnership = 'otherPartnership',
    Gbr = 'gbr',
    Gmbh = 'gmbh',
    Kgaa = 'kgaa',
    Cv = 'cv',
    Vof = 'vof',
    Maatschap = 'maatschap',
    PrivateFundLimitedPartnership = 'privateFundLimitedPartnership',
    BusinessTrustEntity = 'businessTrustEntity',
    BusinessPartnership = 'businessPartnership',
    LimitedLiabilityPartnership = 'limitedLiabilityPartnership',
    Eg = 'eg',
    Cooperative = 'cooperative',
    Vos = 'vos',
    ComunidadDeBienes = 'comunidadDeBienes',
    HerenciaYacente = 'herenciaYacente',
    ComunidadDePropietarios = 'comunidadDePropietarios',
    Sep = 'sep',
    Sca = 'sca',
    Bt = 'bt',
    Kkt = 'kkt',
    Scs = 'scs',
    Snc = 'snc'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TypeEnum23 {
    Individual = 'individual',
    Organization = 'organization',
    SoleProprietorship = 'soleProprietorship',
    Trust = 'trust',
    UnincorporatedPartnership = 'unincorporatedPartnership'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TypeEnum24 {
    BankStatement = 'bankStatement',
    DriversLicense = 'driversLicense',
    IdentityCard = 'identityCard',
    NationalIdNumber = 'nationalIdNumber',
    Passport = 'passport',
    ProofOfAddress = 'proofOfAddress',
    ProofOfNationalIdNumber = 'proofOfNationalIdNumber',
    ProofOfResidency = 'proofOfResidency',
    RegistrationDocument = 'registrationDocument',
    VatDocument = 'vatDocument',
    ProofOfOrganizationTaxInfo = 'proofOfOrganizationTaxInfo',
    ProofOfIndividualTaxId = 'proofOfIndividualTaxId',
    ProofOfOwnership = 'proofOfOwnership',
    ProofOfSignatory = 'proofOfSignatory',
    LiveSelfie = 'liveSelfie',
    ProofOfIndustry = 'proofOfIndustry',
    ConstitutionalDocument = 'constitutionalDocument',
    ProofOfFundingOrWealthSource = 'proofOfFundingOrWealthSource',
    ProofOfRelationship = 'proofOfRelationship'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TypeEnum3 {
    BankAccount = 'bankAccount',
    RecurringDetail = 'recurringDetail'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TypeEnum4 {
    Back = 'BACK',
    Front = 'FRONT',
    Undefined = 'UNDEFINED'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TypeEnum5 {
    BankAccount = 'BankAccount',
    Document = 'Document',
    LegalEntity = 'LegalEntity',
    Product = 'product'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TypeEnum6 {
    BankAccount = 'BankAccount',
    Document = 'Document',
    LegalEntity = 'LegalEntity',
    Product = 'product'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TypeEnum7 {
    DataMissing = 'dataMissing',
    DataReview = 'dataReview',
    InvalidInput = 'invalidInput',
    PendingStatus = 'pendingStatus',
    Rejected = 'rejected'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TypeEnum8 {
    DataMissing = 'dataMissing',
    DataReview = 'dataReview',
    InvalidInput = 'invalidInput',
    PendingStatus = 'pendingStatus',
    Rejected = 'rejected'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum TypeEnum9 {
    BankAccount = 'bankAccount',
    RecurringDetail = 'recurringDetail'
}

/**
 * 
 * @export
 * @interface UndefinedBeneficiary
 */
export interface UndefinedBeneficiary {
    /**
     * 
     * @type {string}
     * @memberof UndefinedBeneficiary
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UndefinedBeneficiary
     */
    'reference'?: string | null;
}
/**
 * 
 * @export
 * @interface UnfulfilledOrdersResponse
 */
export interface UnfulfilledOrdersResponse {
    /**
     * 
     * @type {string}
     * @memberof UnfulfilledOrdersResponse
     */
    'orderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UnfulfilledOrdersResponse
     */
    'orderNumber'?: string | null;
    /**
     * 
     * @type {OrderStateTechnicalName}
     * @memberof UnfulfilledOrdersResponse
     */
    'orderState'?: OrderStateTechnicalName;
    /**
     * 
     * @type {OrderStateTechnicalName}
     * @memberof UnfulfilledOrdersResponse
     */
    'transactionState'?: OrderStateTechnicalName;
    /**
     * 
     * @type {OrderStateTechnicalName}
     * @memberof UnfulfilledOrdersResponse
     */
    'shippingState'?: OrderStateTechnicalName;
    /**
     * 
     * @type {string}
     * @memberof UnfulfilledOrdersResponse
     */
    'orderDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof UnfulfilledOrdersResponse
     */
    'manufacturerId'?: string;
    /**
     * 
     * @type {ManufacturerResponse}
     * @memberof UnfulfilledOrdersResponse
     */
    'manufacturer'?: ManufacturerResponse | null;
}
/**
 * 
 * @export
 * @interface UnincorporatedPartnership
 */
export interface UnincorporatedPartnership {
    /**
     * 
     * @type {TypeEnum22}
     * @memberof UnincorporatedPartnership
     */
    'type'?: TypeEnum22 | null;
    /**
     * 
     * @type {VatAbsenceReasonEnum4}
     * @memberof UnincorporatedPartnership
     */
    'vatAbsenceReason'?: VatAbsenceReasonEnum4 | null;
    /**
     * 
     * @type {string}
     * @memberof UnincorporatedPartnership
     */
    'countryOfGoverningLaw'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UnincorporatedPartnership
     */
    'dateOfIncorporation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UnincorporatedPartnership
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UnincorporatedPartnership
     */
    'doingBusinessAs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UnincorporatedPartnership
     */
    'name'?: string | null;
    /**
     * 
     * @type {Address2}
     * @memberof UnincorporatedPartnership
     */
    'principalPlaceOfBusiness'?: Address2 | null;
    /**
     * 
     * @type {Address2}
     * @memberof UnincorporatedPartnership
     */
    'registeredAddress'?: Address2 | null;
    /**
     * 
     * @type {string}
     * @memberof UnincorporatedPartnership
     */
    'registrationNumber'?: string | null;
    /**
     * 
     * @type {Array<TaxInformation>}
     * @memberof UnincorporatedPartnership
     */
    'taxInformation'?: Array<TaxInformation> | null;
    /**
     * 
     * @type {string}
     * @memberof UnincorporatedPartnership
     */
    'vatNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateAdyenAccountHolderRequest
 */
export interface UpdateAdyenAccountHolderRequest {
    /**
     * 
     * @type {StatusEnum5}
     * @memberof UpdateAdyenAccountHolderRequest
     */
    'status'?: StatusEnum5 | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdyenAccountHolderRequest
     */
    'balancePlatform'?: string | null;
    /**
     * 
     * @type {{ [key: string]: AccountHolderCapability; }}
     * @memberof UpdateAdyenAccountHolderRequest
     */
    'capabilities'?: { [key: string]: AccountHolderCapability; } | null;
    /**
     * 
     * @type {ContactDetails}
     * @memberof UpdateAdyenAccountHolderRequest
     * @deprecated
     */
    'contactDetails'?: ContactDetails | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdyenAccountHolderRequest
     */
    'description'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateAdyenAccountHolderRequest
     */
    'metadata'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdyenAccountHolderRequest
     */
    'migratedAccountHolderCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdyenAccountHolderRequest
     */
    'primaryBalanceAccount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdyenAccountHolderRequest
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdyenAccountHolderRequest
     */
    'timeZone'?: string | null;
    /**
     * 
     * @type {Array<VerificationDeadline>}
     * @memberof UpdateAdyenAccountHolderRequest
     */
    'verificationDeadlines'?: Array<VerificationDeadline> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdyenAccountHolderRequest
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateAdyenAccountHolderRequestAllOf
 */
export interface UpdateAdyenAccountHolderRequestAllOf {
    /**
     * 
     * @type {string}
     * @memberof UpdateAdyenAccountHolderRequestAllOf
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateAdyenBalanceAccountRequest
 */
export interface UpdateAdyenBalanceAccountRequest {
    /**
     * 
     * @type {StatusEnum4}
     * @memberof UpdateAdyenBalanceAccountRequest
     */
    'status'?: StatusEnum4 | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdyenBalanceAccountRequest
     */
    'accountHolderId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdyenBalanceAccountRequest
     */
    'description'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateAdyenBalanceAccountRequest
     */
    'metadata'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {PlatformPaymentConfiguration}
     * @memberof UpdateAdyenBalanceAccountRequest
     */
    'platformPaymentConfiguration'?: PlatformPaymentConfiguration | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdyenBalanceAccountRequest
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdyenBalanceAccountRequest
     */
    'timeZone'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateAdyenBalanceAccountRequestAllOf
 */
export interface UpdateAdyenBalanceAccountRequestAllOf {
    /**
     * 
     * @type {string}
     * @memberof UpdateAdyenBalanceAccountRequestAllOf
     */
    'reference'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateAdyenLegalEntityRequest
 */
export interface UpdateAdyenLegalEntityRequest {
    /**
     * 
     * @type {TypeEnum23}
     * @memberof UpdateAdyenLegalEntityRequest
     */
    'type'?: TypeEnum23 | null;
    /**
     * 
     * @type {{ [key: string]: LegalEntityCapability; }}
     * @memberof UpdateAdyenLegalEntityRequest
     */
    'capabilities'?: { [key: string]: LegalEntityCapability; } | null;
    /**
     * 
     * @type {Array<LegalEntityAssociation>}
     * @memberof UpdateAdyenLegalEntityRequest
     */
    'entityAssociations'?: Array<LegalEntityAssociation> | null;
    /**
     * 
     * @type {Individual}
     * @memberof UpdateAdyenLegalEntityRequest
     */
    'individual'?: Individual | null;
    /**
     * 
     * @type {Organization}
     * @memberof UpdateAdyenLegalEntityRequest
     */
    'organization'?: Organization | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdyenLegalEntityRequest
     */
    'reference'?: string | null;
    /**
     * 
     * @type {SoleProprietorship}
     * @memberof UpdateAdyenLegalEntityRequest
     */
    'soleProprietorship'?: SoleProprietorship | null;
    /**
     * 
     * @type {Trust}
     * @memberof UpdateAdyenLegalEntityRequest
     */
    'trust'?: Trust | null;
    /**
     * 
     * @type {UnincorporatedPartnership}
     * @memberof UpdateAdyenLegalEntityRequest
     */
    'unincorporatedPartnership'?: UnincorporatedPartnership | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdyenLegalEntityRequest
     */
    'verificationPlan'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateAdyenTransferInstrumentRequest
 */
export interface UpdateAdyenTransferInstrumentRequest {
    /**
     * 
     * @type {TypeEnum9}
     * @memberof UpdateAdyenTransferInstrumentRequest
     */
    'type'?: TypeEnum9;
    /**
     * 
     * @type {UpdateAdyenTransferInstrumentRequestBankAccountInfo}
     * @memberof UpdateAdyenTransferInstrumentRequest
     */
    'bankAccount'?: UpdateAdyenTransferInstrumentRequestBankAccountInfo | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdyenTransferInstrumentRequest
     */
    'legalEntityId'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateAdyenTransferInstrumentRequestAllOf
 */
export interface UpdateAdyenTransferInstrumentRequestAllOf {
    /**
     * 
     * @type {UpdateAdyenTransferInstrumentRequestBankAccountInfo}
     * @memberof UpdateAdyenTransferInstrumentRequestAllOf
     */
    'bankAccount'?: UpdateAdyenTransferInstrumentRequestBankAccountInfo | null;
}
/**
 * 
 * @export
 * @interface UpdateAdyenTransferInstrumentRequestBankAccountInfo
 */
export interface UpdateAdyenTransferInstrumentRequestBankAccountInfo {
    /**
     * 
     * @type {IbanAccountIdentification}
     * @memberof UpdateAdyenTransferInstrumentRequestBankAccountInfo
     */
    'accountIdentification'?: IbanAccountIdentification | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdyenTransferInstrumentRequestBankAccountInfo
     * @deprecated
     */
    'accountType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdyenTransferInstrumentRequestBankAccountInfo
     */
    'bankName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdyenTransferInstrumentRequestBankAccountInfo
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAdyenTransferInstrumentRequestBankAccountInfo
     */
    'trustedSource'?: boolean | null;
}
/**
 * 
 * @export
 * @interface UpdateAnimalSpeciesRequest
 */
export interface UpdateAnimalSpeciesRequest {
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateAnimalSpeciesRequest
     */
    'name': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateAnimalSpeciesRequest
     */
    'slug': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnimalSpeciesRequest
     */
    'identifier': string;
}
/**
 * 
 * @export
 * @interface UpdateAssetFolderRequest
 */
export interface UpdateAssetFolderRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateAssetFolderRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAssetFolderRequest
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAssetFolderRequest
     */
    'parentId'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateAssetRequest
 */
export interface UpdateAssetRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateAssetRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAssetRequest
     */
    'metaData'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAssetRequest
     */
    'description'?: string | null;
    /**
     * 
     * @type {AssetType}
     * @memberof UpdateAssetRequest
     */
    'assetType': AssetType;
    /**
     * 
     * @type {string}
     * @memberof UpdateAssetRequest
     */
    'assetFolderId'?: string | null;
    /**
     * 
     * @type {Array<CreateTagRequest>}
     * @memberof UpdateAssetRequest
     */
    'tags'?: Array<CreateTagRequest> | null;
}
/**
 * 
 * @export
 * @interface UpdateBrandContactAddressRequest
 */
export interface UpdateBrandContactAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandContactAddressRequest
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandContactAddressRequest
     */
    'streetNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandContactAddressRequest
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandContactAddressRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandContactAddressRequest
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandContactAddressRequest
     */
    'additionalAddressLine1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandContactAddressRequest
     */
    'additionalAddressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandContactAddressRequest
     */
    'countryId'?: string;
    /**
     * 
     * @type {Department}
     * @memberof UpdateBrandContactAddressRequest
     */
    'department': Department;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandContactAddressRequest
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandContactAddressRequest
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandContactAddressRequest
     */
    'salutation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandContactAddressRequest
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandContactAddressRequest
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandContactAddressRequest
     */
    'brandId': string;
}
/**
 * 
 * @export
 * @interface UpdateBrandRequest
 */
export interface UpdateBrandRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBrandRequest
     */
    'isActiveInStorefront': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandRequest
     */
    'slug': string;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateBrandRequest
     */
    'name': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateBrandRequest
     */
    'claim'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfNullableGuid}
     * @memberof UpdateBrandRequest
     */
    'logoId'?: TranslatedTypeRequestOfNullableGuid | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandRequest
     */
    'deliveryPausedFrom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandRequest
     */
    'deliveryPausedUntil'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateBrandRequest
     */
    'earliestDeliveryTimeInDays'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateBrandRequest
     */
    'latestDeliveryTimeInDays'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBrandRequest
     */
    'overrideProductDeliveryTime'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateBrandStoreRequest
 */
export interface UpdateBrandStoreRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandStoreRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandStoreRequest
     */
    'primaryColorHex': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandStoreRequest
     */
    'secondaryColorHex'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandStoreRequest
     */
    'primaryContrastColorHex': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandStoreRequest
     */
    'secondaryContrastColorHex'?: string | null;
    /**
     * 
     * @type {BrandStoreState}
     * @memberof UpdateBrandStoreRequest
     */
    'state': BrandStoreState;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateBrandStoreRequest
     */
    'claim'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateBrandStoreRequest
     */
    'description': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {TranslatedTypeRequestOfDictionaryOfStringAndString}
     * @memberof UpdateBrandStoreRequest
     */
    'faq'?: TranslatedTypeRequestOfDictionaryOfStringAndString | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfNullableGuid}
     * @memberof UpdateBrandStoreRequest
     */
    'heroBannerAssetId': TranslatedTypeRequestOfNullableGuid;
    /**
     * 
     * @type {string}
     * @memberof UpdateBrandStoreRequest
     */
    'brandId': string;
}
/**
 * 
 * @export
 * @interface UpdateBulkEditorHistoryRequest
 */
export interface UpdateBulkEditorHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateBulkEditorHistoryRequest
     */
    'data': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBulkEditorHistoryRequest
     */
    'comment'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateBulkEditorSessionRequest
 */
export interface UpdateBulkEditorSessionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateBulkEditorSessionRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateCredentialRequest
 */
export interface UpdateCredentialRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCredentialRequest
     */
    'data': string;
    /**
     * 
     * @type {CredentialContext}
     * @memberof UpdateCredentialRequest
     */
    'credentialContext': CredentialContext;
    /**
     * 
     * @type {CredentialType}
     * @memberof UpdateCredentialRequest
     */
    'credentialType': CredentialType;
    /**
     * 
     * @type {string}
     * @memberof UpdateCredentialRequest
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateCustomerRequest
 */
export interface UpdateCustomerRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerRequest
     */
    'shopReferenceId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerRequest
     */
    'shopCustomerReferenceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerRequest
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerRequest
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerRequest
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerRequest
     */
    'customerNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerRequest
     */
    'languageId': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCustomerRequest
     */
    'isGuest': boolean;
    /**
     * 
     * @type {any}
     * @memberof UpdateCustomerRequest
     */
    'shopCustomFields'?: any | null;
}
/**
 * 
 * @export
 * @interface UpdateDataImportConfigRequest
 */
export interface UpdateDataImportConfigRequest {
    /**
     * 
     * @type {DataImportTargetEntity}
     * @memberof UpdateDataImportConfigRequest
     */
    'dataImportTargetEntity'?: DataImportTargetEntity | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataImportConfigRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataImportConfigRequest
     */
    'apiVersion': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof UpdateDataImportConfigRequest
     */
    'config'?: Array<any> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataImportConfigRequest
     */
    'handler': string;
}
/**
 * 
 * @export
 * @interface UpdateDeliveryTimeRequest
 */
export interface UpdateDeliveryTimeRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateDeliveryTimeRequest
     */
    'min': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDeliveryTimeRequest
     */
    'max': number;
    /**
     * 
     * @type {DeliveryTimeUnit}
     * @memberof UpdateDeliveryTimeRequest
     */
    'unit': DeliveryTimeUnit;
    /**
     * 
     * @type {DeliveryTimeType}
     * @memberof UpdateDeliveryTimeRequest
     */
    'type': DeliveryTimeType;
}
/**
 * 
 * @export
 * @interface UpdateDocumentLayoutRequest
 */
export interface UpdateDocumentLayoutRequest {
    /**
     * 
     * @type {DocumentState}
     * @memberof UpdateDocumentLayoutRequest
     */
    'state': DocumentState;
    /**
     * 
     * @type {DocumentType}
     * @memberof UpdateDocumentLayoutRequest
     */
    'type': DocumentType;
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentLayoutRequest
     */
    'documentHandler': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateDocumentLayoutRequest
     */
    'config'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentLayoutRequest
     */
    'fileNamePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentLayoutRequest
     */
    'fileNameSuffix'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateFiegePurchaseOrderRequest
 */
export interface UpdateFiegePurchaseOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateFiegePurchaseOrderRequest
     */
    'deliveryDate': string;
    /**
     * 
     * @type {PurchaseOrderState}
     * @memberof UpdateFiegePurchaseOrderRequest
     */
    'state': PurchaseOrderState;
    /**
     * 
     * @type {Array<CreateOrUpdateFiegePurchaseOrderPositionRequest>}
     * @memberof UpdateFiegePurchaseOrderRequest
     */
    'positions': Array<CreateOrUpdateFiegePurchaseOrderPositionRequest>;
}
/**
 * 
 * @export
 * @interface UpdateLoyaltyPointProcessingConfigRequest
 */
export interface UpdateLoyaltyPointProcessingConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateLoyaltyPointProcessingConfigRequest
     */
    'handlerName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateLoyaltyPointProcessingConfigRequest
     */
    'handlerArguments'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLoyaltyPointProcessingConfigRequest
     */
    'isEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLoyaltyPointProcessingConfigRequest
     */
    'isInitialHandler': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateLoyaltyPointProcessingConfigRequest
     */
    'nextHandlerId'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateLoyaltyPointTransactionRequest
 */
export interface UpdateLoyaltyPointTransactionRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateLoyaltyPointTransactionRequest
     */
    'points': number;
    /**
     * 
     * @type {LoyaltyPointTransactionType}
     * @memberof UpdateLoyaltyPointTransactionRequest
     */
    'type': LoyaltyPointTransactionType;
    /**
     * 
     * @type {string}
     * @memberof UpdateLoyaltyPointTransactionRequest
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLoyaltyPointTransactionRequest
     */
    'orderId'?: string | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateLoyaltyPointTransactionRequest
     */
    'description'?: TranslatedTypeRequestOfString | null;
}
/**
 * 
 * @export
 * @interface UpdateLoyaltyRankRequest
 */
export interface UpdateLoyaltyRankRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateLoyaltyRankRequest
     */
    'minimumPoints': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLoyaltyRankRequest
     */
    'discountPercentage': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateLoyaltyRankRequest
     */
    'validFrom': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLoyaltyRankRequest
     */
    'validTo'?: string | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateLoyaltyRankRequest
     */
    'name': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateLoyaltyRankRequest
     */
    'description'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfGuid}
     * @memberof UpdateLoyaltyRankRequest
     */
    'assetId'?: TranslatedTypeRequestOfGuid | null;
}
/**
 * 
 * @export
 * @interface UpdateManufacturerAddressRequest
 */
export interface UpdateManufacturerAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerAddressRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerAddressRequest
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerAddressRequest
     */
    'streetNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerAddressRequest
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerAddressRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerAddressRequest
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerAddressRequest
     */
    'additionalAddressLine1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerAddressRequest
     */
    'additionalAddressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerAddressRequest
     */
    'countryId': string;
}
/**
 * 
 * @export
 * @interface UpdateManufacturerConditionRequest
 */
export interface UpdateManufacturerConditionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerConditionRequest
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface UpdateManufacturerConditionsRequest
 */
export interface UpdateManufacturerConditionsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerConditionsRequest
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerConditionsRequest
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface UpdateManufacturerContactRequest
 */
export interface UpdateManufacturerContactRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerContactRequest
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerContactRequest
     */
    'streetNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerContactRequest
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerContactRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerContactRequest
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerContactRequest
     */
    'additionalAddressLine1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerContactRequest
     */
    'additionalAddressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerContactRequest
     */
    'countryId'?: string;
    /**
     * 
     * @type {Department}
     * @memberof UpdateManufacturerContactRequest
     */
    'department': Department;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerContactRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerContactRequest
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerContactRequest
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerContactRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerContactRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerContactRequest
     */
    'phoneNumber': string;
}
/**
 * 
 * @export
 * @interface UpdateManufacturerContactRequestAllOf
 */
export interface UpdateManufacturerContactRequestAllOf {
    /**
     * 
     * @type {Department}
     * @memberof UpdateManufacturerContactRequestAllOf
     */
    'department': Department;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerContactRequestAllOf
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerContactRequestAllOf
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerContactRequestAllOf
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerContactRequestAllOf
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerContactRequestAllOf
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerContactRequestAllOf
     */
    'phoneNumber': string;
}
/**
 * 
 * @export
 * @interface UpdateManufacturerRequest
 */
export interface UpdateManufacturerRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateManufacturerRequest
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerRequest
     */
    'customerNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerRequest
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerRequest
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerRequest
     */
    'vatId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerRequest
     */
    'registrationNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerRequest
     */
    'shortCode': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerRequest
     */
    'dataPrivacyUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerRequest
     */
    'termsAndConditionsUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerRequest
     */
    'logoAssetId'?: string | null;
    /**
     * 
     * @type {UpdateManufacturerAddressRequest}
     * @memberof UpdateManufacturerRequest
     */
    'companyAddress'?: UpdateManufacturerAddressRequest | null;
}
/**
 * 
 * @export
 * @interface UpdateManufacturerSettingRequest
 */
export interface UpdateManufacturerSettingRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerSettingRequest
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface UpdateManufacturerSettingsRequest
 */
export interface UpdateManufacturerSettingsRequest {
    /**
     * 
     * @type {ManufacturerSettingKey}
     * @memberof UpdateManufacturerSettingsRequest
     */
    'key': ManufacturerSettingKey;
    /**
     * 
     * @type {string}
     * @memberof UpdateManufacturerSettingsRequest
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface UpdateOrderInvoiceAddressRequest
 */
export interface UpdateOrderInvoiceAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderInvoiceAddressRequest
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderInvoiceAddressRequest
     */
    'streetNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderInvoiceAddressRequest
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderInvoiceAddressRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderInvoiceAddressRequest
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderInvoiceAddressRequest
     */
    'additionalAddressLine1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderInvoiceAddressRequest
     */
    'additionalAddressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderInvoiceAddressRequest
     */
    'countryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderInvoiceAddressRequest
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderInvoiceAddressRequest
     */
    'department'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderInvoiceAddressRequest
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderInvoiceAddressRequest
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderInvoiceAddressRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderInvoiceAddressRequest
     */
    'lastName': string;
    /**
     * 
     * @type {any}
     * @memberof UpdateOrderInvoiceAddressRequest
     */
    'shopCustomFields'?: any | null;
}
/**
 * 
 * @export
 * @interface UpdateOrderInvoiceAddressRequestAllOf
 */
export interface UpdateOrderInvoiceAddressRequestAllOf {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderInvoiceAddressRequestAllOf
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderInvoiceAddressRequestAllOf
     */
    'department'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderInvoiceAddressRequestAllOf
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderInvoiceAddressRequestAllOf
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderInvoiceAddressRequestAllOf
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderInvoiceAddressRequestAllOf
     */
    'lastName': string;
    /**
     * 
     * @type {any}
     * @memberof UpdateOrderInvoiceAddressRequestAllOf
     */
    'shopCustomFields'?: any | null;
}
/**
 * 
 * @export
 * @interface UpdateOrderPaymentTransactionRequest
 */
export interface UpdateOrderPaymentTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderPaymentTransactionRequest
     */
    'paymentProviderId': string;
}
/**
 * 
 * @export
 * @interface UpdateOrderPositionRequest
 */
export interface UpdateOrderPositionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderPositionRequest
     */
    'shopReferenceId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderPositionRequest
     */
    'parentOrderPositionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderPositionRequest
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderPositionRequest
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderPositionRequest
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderPositionRequest
     */
    'coverId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderPositionRequest
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderPositionRequest
     */
    'unitPrice': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderPositionRequest
     */
    'totalPrice': number;
    /**
     * 
     * @type {OrderPositionType}
     * @memberof UpdateOrderPositionRequest
     */
    'positionType': OrderPositionType;
    /**
     * 
     * @type {any}
     * @memberof UpdateOrderPositionRequest
     */
    'payload': any;
    /**
     * 
     * @type {any}
     * @memberof UpdateOrderPositionRequest
     */
    'price': any;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOrderPositionRequest
     */
    'good': boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderPositionRequest
     */
    'position': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderPositionRequest
     */
    'orderReturnId'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof UpdateOrderPositionRequest
     */
    'shopCustomFields'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderPositionRequest
     */
    'orderId': string;
}
/**
 * 
 * @export
 * @interface UpdateOrderRequest
 */
export interface UpdateOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderRequest
     */
    'shopReferenceId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderRequest
     */
    'orderNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderRequest
     */
    'currencyId': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderRequest
     */
    'currencyFactor': number;
    /**
     * 
     * @type {any}
     * @memberof UpdateOrderRequest
     */
    'price': any;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderRequest
     */
    'orderDateTime': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderRequest
     */
    'amountTotal': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderRequest
     */
    'amountNet': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderRequest
     */
    'positionPrice': number;
    /**
     * 
     * @type {any}
     * @memberof UpdateOrderRequest
     */
    'shippingCosts': any;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderRequest
     */
    'shippingTotal': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderRequest
     */
    'deepLinkCode': string;
    /**
     * 
     * @type {any}
     * @memberof UpdateOrderRequest
     */
    'shopCustomFields'?: any | null;
}
/**
 * 
 * @export
 * @interface UpdateOrderReturnRequest
 */
export interface UpdateOrderReturnRequest {
    /**
     * 
     * @type {OrderReturnState}
     * @memberof UpdateOrderReturnRequest
     */
    'state': OrderReturnState;
}
/**
 * 
 * @export
 * @interface UpdateOrderShippingAddressRequest
 */
export interface UpdateOrderShippingAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderShippingAddressRequest
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderShippingAddressRequest
     */
    'streetNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderShippingAddressRequest
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderShippingAddressRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderShippingAddressRequest
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderShippingAddressRequest
     */
    'additionalAddressLine1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderShippingAddressRequest
     */
    'additionalAddressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderShippingAddressRequest
     */
    'countryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderShippingAddressRequest
     */
    'shopVersionReferenceId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderShippingAddressRequest
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderShippingAddressRequest
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderShippingAddressRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderShippingAddressRequest
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface UpdateOrderShippingAddressRequestAllOf
 */
export interface UpdateOrderShippingAddressRequestAllOf {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderShippingAddressRequestAllOf
     */
    'shopVersionReferenceId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderShippingAddressRequestAllOf
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderShippingAddressRequestAllOf
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderShippingAddressRequestAllOf
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderShippingAddressRequestAllOf
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface UpdateOrderShippingDetailRequest
 */
export interface UpdateOrderShippingDetailRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderShippingDetailRequest
     */
    'shippingProviderId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderShippingDetailRequest
     */
    'trackingCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderShippingDetailRequest
     */
    'weight'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderShippingDetailRequest
     */
    'transmissionDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderShippingDetailRequest
     */
    'shippingDateEarliest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderShippingDetailRequest
     */
    'shippingDateLatest'?: string | null;
    /**
     * 
     * @type {Array<UpsertOrderShippingDetailPositionRequest>}
     * @memberof UpdateOrderShippingDetailRequest
     */
    'positions': Array<UpsertOrderShippingDetailPositionRequest>;
}
/**
 * 
 * @export
 * @interface UpdateOrderStateHistoryRequest
 */
export interface UpdateOrderStateHistoryRequest {
    /**
     * 
     * @type {OrderStateContext}
     * @memberof UpdateOrderStateHistoryRequest
     */
    'orderStateContext': OrderStateContext;
    /**
     * 
     * @type {OrderStateTechnicalName}
     * @memberof UpdateOrderStateHistoryRequest
     */
    'orderStateTechnicalName': OrderStateTechnicalName;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderStateHistoryRequest
     */
    'orderStateId'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdatePaymentProviderRequest
 */
export interface UpdatePaymentProviderRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePaymentProviderRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateProductAnalyticConstituentRequest
 */
export interface UpdateProductAnalyticConstituentRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateProductAnalyticConstituentRequest
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductAnalyticConstituentRequest
     */
    'position': number;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateProductAnalyticConstituentRequest
     */
    'name': TranslatedTypeRequestOfString;
}
/**
 * 
 * @export
 * @interface UpdateProductAnalyticConstituentsRequest
 */
export interface UpdateProductAnalyticConstituentsRequest {
    /**
     * 
     * @type {Array<UpdateProductAnalyticConstituentRequest>}
     * @memberof UpdateProductAnalyticConstituentsRequest
     */
    'productAnalyticConstituents'?: Array<UpdateProductAnalyticConstituentRequest> | null;
}
/**
 * 
 * @export
 * @interface UpdateProductAssetRequest
 */
export interface UpdateProductAssetRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateProductAssetRequest
     */
    'position': number;
    /**
     * 
     * @type {TranslatedTypeRequestOfGuid}
     * @memberof UpdateProductAssetRequest
     */
    'assetId': TranslatedTypeRequestOfGuid;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateProductAssetRequest
     */
    'description'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateProductAssetRequest
     */
    'alternative'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductAssetRequest
     */
    'productId'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateProductAssetsRequest
 */
export interface UpdateProductAssetsRequest {
    /**
     * 
     * @type {Array<CreateProductAssetRequest>}
     * @memberof UpdateProductAssetsRequest
     */
    'createProductAssetRequests': Array<CreateProductAssetRequest>;
    /**
     * 
     * @type {Array<InlineUpdateProductAssetRequest>}
     * @memberof UpdateProductAssetsRequest
     */
    'updateProductAssetRequests': Array<InlineUpdateProductAssetRequest>;
}
/**
 * 
 * @export
 * @interface UpdateProductBundleRequest
 */
export interface UpdateProductBundleRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProductBundleRequest
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductBundleRequest
     */
    'manufacturerId': string;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof UpdateProductBundleRequest
     */
    'name': TranslatedTypeResponseOfString;
    /**
     * 
     * @type {TranslatedTypeResponseOfString}
     * @memberof UpdateProductBundleRequest
     */
    'description': TranslatedTypeResponseOfString;
}
/**
 * 
 * @export
 * @interface UpdateProductCategoryRequest
 */
export interface UpdateProductCategoryRequest {
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateProductCategoryRequest
     */
    'name': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateProductCategoryRequest
     */
    'description'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProductCategoryRequest
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductCategoryRequest
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductCategoryRequest
     */
    'identifier': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProductCategoryRequest
     */
    'productGroupIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProductCategoryRequest
     */
    'animalSpeciesIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UpdateProductDataImportItemsRequest
 */
export interface UpdateProductDataImportItemsRequest {
    /**
     * 
     * @type {Array<ProductDataImportItemRequest>}
     * @memberof UpdateProductDataImportItemsRequest
     */
    'productDataImportItems': Array<ProductDataImportItemRequest>;
}
/**
 * 
 * @export
 * @interface UpdateProductGroupMappingRequest
 */
export interface UpdateProductGroupMappingRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateProductGroupMappingRequest
     */
    'name': string;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateProductGroupMappingRequest
     */
    'description'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProductGroupMappingRequest
     */
    'isOptional': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProductGroupMappingRequest
     */
    'isUserInputAllowed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProductGroupMappingRequest
     */
    'hasPropertyOptionListing': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductGroupMappingRequest
     */
    'propertyGroupId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProductGroupMappingRequest
     */
    'propertyOptionIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProductGroupMappingRequest
     */
    'productGroupIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProductGroupMappingRequest
     */
    'propertyValidationRuleIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UpdateProductGroupRequest
 */
export interface UpdateProductGroupRequest {
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateProductGroupRequest
     */
    'name': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateProductGroupRequest
     */
    'description'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductGroupRequest
     */
    'identifier': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProductGroupRequest
     */
    'animalSpeciesIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProductGroupRequest
     */
    'propertyValidationRuleIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProductGroupRequest
     */
    'propertyGroupIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UpdateProductPriceRequest
 */
export interface UpdateProductPriceRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateProductPriceRequest
     */
    'gross'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductPriceRequest
     */
    'listPriceGross'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductPriceRequest
     */
    'quantityStart'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductPriceRequest
     */
    'quantityEnd'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductPriceRequest
     */
    'currencyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductPriceRequest
     */
    'countryId'?: string;
}
/**
 * 
 * @export
 * @interface UpdateProductProductCategoriesRequest
 */
export interface UpdateProductProductCategoriesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProductProductCategoriesRequest
     */
    'productCategoryIds': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateProductPropertiesRequest
 */
export interface UpdateProductPropertiesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProductPropertiesRequest
     */
    'propertyOptionIds': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateProductRequest
 */
export interface UpdateProductRequest {
    /**
     * 
     * @type {ProductState}
     * @memberof UpdateProductRequest
     */
    'state': ProductState;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateProductRequest
     */
    'name': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateProductRequest
     */
    'description': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateProductRequest
     */
    'seoProductTitle'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateProductRequest
     */
    'marketingText'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateProductRequest
     */
    'productLine'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductRequest
     */
    'ean'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductRequest
     */
    'releaseDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProductRequest
     */
    'isBatchControlled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProductRequest
     */
    'isBestBeforeControlled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProductRequest
     */
    'isDangerousGoods'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductRequest
     */
    'mainVariantId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductRequest
     */
    'restockTimeDays'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProductRequest
     */
    'isCloseout'?: boolean;
    /**
     * 
     * @type {TaxTypeIdentifier}
     * @memberof UpdateProductRequest
     */
    'taxTypeIdentifier': TaxTypeIdentifier;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductRequest
     */
    'purchaseSteps'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductRequest
     */
    'minPurchase'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductRequest
     */
    'maxPurchase'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductRequest
     */
    'purchaseUnit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductRequest
     */
    'referenceUnit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductRequest
     */
    'weight'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductRequest
     */
    'width'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductRequest
     */
    'height'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductRequest
     */
    'length'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductRequest
     */
    'productUnitId'?: string | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateProductRequest
     */
    'packUnit'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateProductRequest
     */
    'packUnitPlural'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProductRequest
     */
    'isShippingFree'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductRequest
     */
    'manufacturerProductNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductRequest
     */
    'coverId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductRequest
     */
    'productGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductRequest
     */
    'brandId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProductRequest
     */
    'animalSpeciesIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductRequest
     */
    'mainCategoryId'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateProductStateRequest
 */
export interface UpdateProductStateRequest {
    /**
     * 
     * @type {ProductState}
     * @memberof UpdateProductStateRequest
     */
    'state': ProductState;
}
/**
 * 
 * @export
 * @interface UpdateProductStockRequest
 */
export interface UpdateProductStockRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateProductStockRequest
     */
    'stock': number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProductStockRequest
     */
    'available': boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductStockRequest
     */
    'priority': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductStockRequest
     */
    'warehouseId'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateProductTagsRequest
 */
export interface UpdateProductTagsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProductTagsRequest
     */
    'tagIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UpdateProductUnitRequest
 */
export interface UpdateProductUnitRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProductUnitRequest
     */
    'isBasePriceUnit': boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductUnitRequest
     */
    'formatPadding'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductUnitRequest
     */
    'formatPrecision'?: number | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateProductUnitRequest
     */
    'name': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateProductUnitRequest
     */
    'shortCode': TranslatedTypeRequestOfString;
}
/**
 * 
 * @export
 * @interface UpdatePropertyGroupRequest
 */
export interface UpdatePropertyGroupRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdatePropertyGroupRequest
     */
    'position': number;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdatePropertyGroupRequest
     */
    'name': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdatePropertyGroupRequest
     */
    'description'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePropertyGroupRequest
     */
    'identifier': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePropertyGroupRequest
     */
    'isLanguageNeutral': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdatePropertyGroupRequest
     */
    'animalSpeciesIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePropertyGroupRequest
     */
    'productUnitId'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdatePropertyOptionRequest
 */
export interface UpdatePropertyOptionRequest {
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdatePropertyOptionRequest
     */
    'name': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdatePropertyOptionRequest
     */
    'description'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {number}
     * @memberof UpdatePropertyOptionRequest
     */
    'position': number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePropertyOptionRequest
     */
    'isVariantOption': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdatePropertyOptionRequest
     */
    'assetId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePropertyOptionRequest
     */
    'colorHexCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePropertyOptionRequest
     */
    'propertyGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePropertyOptionRequest
     */
    'identifier': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePropertyOptionRequest
     */
    'isLocked': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdatePropertyOptionRequest
     */
    'animalSpeciesIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UpdatePropertyValidationRuleRequest
 */
export interface UpdatePropertyValidationRuleRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePropertyValidationRuleRequest
     */
    'name': string;
    /**
     * 
     * @type {PropertyValidationRuleCallable}
     * @memberof UpdatePropertyValidationRuleRequest
     */
    'callable'?: PropertyValidationRuleCallable | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdatePropertyValidationRuleRequest
     */
    'arguments'?: Array<string> | null;
    /**
     * 
     * @type {Array<ValidationConstraint>}
     * @memberof UpdatePropertyValidationRuleRequest
     */
    'constraints'?: Array<ValidationConstraint> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePropertyValidationRuleRequest
     */
    'objectPath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePropertyValidationRuleRequest
     */
    'identifier'?: string | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdatePropertyValidationRuleRequest
     */
    'message'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdatePropertyValidationRuleRequest
     */
    'description'?: TranslatedTypeRequestOfString | null;
}
/**
 * 
 * @export
 * @interface UpdateShippingProviderRequest
 */
export interface UpdateShippingProviderRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateShippingProviderRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateSubscribedNotificationsRequest
 */
export interface UpdateSubscribedNotificationsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateSubscribedNotificationsRequest
     */
    'subscribedNotifications': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateTagRequest
 */
export interface UpdateTagRequest {
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpdateTagRequest
     */
    'name': TranslatedTypeRequestOfString;
}
/**
 * 
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateUserRoleRequest
 */
export interface UpdateUserRoleRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRoleRequest
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserRoleRequest
     */
    'policies': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRoleRequest
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateUserSettingRequest
 */
export interface UpdateUserSettingRequest {
    /**
     * 
     * @type {UserSettingKey}
     * @memberof UpdateUserSettingRequest
     */
    'key': UserSettingKey;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserSettingRequest
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface UpdateWarehouseRequest
 */
export interface UpdateWarehouseRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateWarehouseRequest
     */
    'name': string;
    /**
     * 
     * @type {WarehouseType}
     * @memberof UpdateWarehouseRequest
     */
    'type': WarehouseType;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateWarehouseRequest
     */
    'encryptedProperties'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface UpsertManufacturerSettingRequest
 */
export interface UpsertManufacturerSettingRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertManufacturerSettingRequest
     */
    'manufacturerId': string;
    /**
     * 
     * @type {ManufacturerSettingKey}
     * @memberof UpsertManufacturerSettingRequest
     */
    'key': ManufacturerSettingKey;
    /**
     * 
     * @type {string}
     * @memberof UpsertManufacturerSettingRequest
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface UpsertOrderReceiptDocumentLayoutDataRequest
 */
export interface UpsertOrderReceiptDocumentLayoutDataRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderReceiptDocumentLayoutDataRequest
     */
    'logo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderReceiptDocumentLayoutDataRequest
     */
    'logoMimeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderReceiptDocumentLayoutDataRequest
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderReceiptDocumentLayoutDataRequest
     */
    'companySenderAddressLine'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderReceiptDocumentLayoutDataRequest
     */
    'companyAddressStreet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderReceiptDocumentLayoutDataRequest
     */
    'companyAddressZipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderReceiptDocumentLayoutDataRequest
     */
    'companyAddressCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderReceiptDocumentLayoutDataRequest
     */
    'companyAddressCountry'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpsertOrderReceiptDocumentLayoutDataRequest
     */
    'additionalContactLines'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpsertOrderReceiptDocumentLayoutDataRequest
     */
    'additionalBottomTextLines'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertOrderReceiptDocumentLayoutDataRequest
     */
    'footerColumnCount'?: number;
    /**
     * 
     * @type {Array<FooterColumnWidth>}
     * @memberof UpsertOrderReceiptDocumentLayoutDataRequest
     */
    'footerColumnsWidths'?: Array<FooterColumnWidth> | null;
    /**
     * 
     * @type {Array<FooterContent>}
     * @memberof UpsertOrderReceiptDocumentLayoutDataRequest
     */
    'footerContents'?: Array<FooterContent> | null;
}
/**
 * 
 * @export
 * @interface UpsertOrderReturnPositionRequest
 */
export interface UpsertOrderReturnPositionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderReturnPositionRequest
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertOrderReturnPositionRequest
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderReturnPositionRequest
     */
    'orderPositionId': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderReturnPositionRequest
     */
    'batchNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderReturnPositionRequest
     */
    'bestBefore'?: string | null;
    /**
     * 
     * @type {LogisticsReturnReason}
     * @memberof UpsertOrderReturnPositionRequest
     */
    'logisticsReturnReason'?: LogisticsReturnReason | null;
    /**
     * 
     * @type {ProductStockQuality}
     * @memberof UpsertOrderReturnPositionRequest
     */
    'quality'?: ProductStockQuality | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderReturnPositionRequest
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderReturnPositionRequest
     */
    'returnDate'?: string | null;
}
/**
 * 
 * @export
 * @interface UpsertOrderShippingDetailPositionRequest
 */
export interface UpsertOrderShippingDetailPositionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderShippingDetailPositionRequest
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertOrderShippingDetailPositionRequest
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderShippingDetailPositionRequest
     */
    'batchNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderShippingDetailPositionRequest
     */
    'bestBefore'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderShippingDetailPositionRequest
     */
    'orderPositionId': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertOrderShippingDetailPositionRequest
     */
    'orderId': string;
}
/**
 * 
 * @export
 * @interface UpsertProductAdditionalInformationBulletPointsRequest
 */
export interface UpsertProductAdditionalInformationBulletPointsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertProductAdditionalInformationBulletPointsRequest
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertProductAdditionalInformationBulletPointsRequest
     */
    'informationGroupId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertProductAdditionalInformationBulletPointsRequest
     */
    'position'?: number | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfStringOf}
     * @memberof UpsertProductAdditionalInformationBulletPointsRequest
     */
    'items': TranslatedTypeRequestOfStringOf;
}
/**
 * 
 * @export
 * @interface UpsertProductAdditionalInformationBulletPointsRequestAllOf
 */
export interface UpsertProductAdditionalInformationBulletPointsRequestAllOf {
    /**
     * 
     * @type {TranslatedTypeRequestOfStringOf}
     * @memberof UpsertProductAdditionalInformationBulletPointsRequestAllOf
     */
    'items': TranslatedTypeRequestOfStringOf;
}
/**
 * 
 * @export
 * @interface UpsertProductAdditionalInformationDownloadRequest
 */
export interface UpsertProductAdditionalInformationDownloadRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertProductAdditionalInformationDownloadRequest
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertProductAdditionalInformationDownloadRequest
     */
    'informationGroupId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertProductAdditionalInformationDownloadRequest
     */
    'position'?: number | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfIEnumerableOfGuid}
     * @memberof UpsertProductAdditionalInformationDownloadRequest
     */
    'assetIds': TranslatedTypeRequestOfIEnumerableOfGuid;
}
/**
 * 
 * @export
 * @interface UpsertProductAdditionalInformationDownloadRequestAllOf
 */
export interface UpsertProductAdditionalInformationDownloadRequestAllOf {
    /**
     * 
     * @type {TranslatedTypeRequestOfIEnumerableOfGuid}
     * @memberof UpsertProductAdditionalInformationDownloadRequestAllOf
     */
    'assetIds': TranslatedTypeRequestOfIEnumerableOfGuid;
}
/**
 * 
 * @export
 * @interface UpsertProductAdditionalInformationMediaGalleryRequest
 */
export interface UpsertProductAdditionalInformationMediaGalleryRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertProductAdditionalInformationMediaGalleryRequest
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertProductAdditionalInformationMediaGalleryRequest
     */
    'informationGroupId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertProductAdditionalInformationMediaGalleryRequest
     */
    'position'?: number | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfListOfGuid}
     * @memberof UpsertProductAdditionalInformationMediaGalleryRequest
     */
    'assetIds': TranslatedTypeRequestOfListOfGuid;
}
/**
 * 
 * @export
 * @interface UpsertProductAdditionalInformationMediaGalleryRequestAllOf
 */
export interface UpsertProductAdditionalInformationMediaGalleryRequestAllOf {
    /**
     * 
     * @type {TranslatedTypeRequestOfListOfGuid}
     * @memberof UpsertProductAdditionalInformationMediaGalleryRequestAllOf
     */
    'assetIds': TranslatedTypeRequestOfListOfGuid;
}
/**
 * 
 * @export
 * @interface UpsertProductAdditionalInformationRichTextRequest
 */
export interface UpsertProductAdditionalInformationRichTextRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertProductAdditionalInformationRichTextRequest
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertProductAdditionalInformationRichTextRequest
     */
    'informationGroupId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertProductAdditionalInformationRichTextRequest
     */
    'position'?: number | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpsertProductAdditionalInformationRichTextRequest
     */
    'value': TranslatedTypeRequestOfString;
}
/**
 * 
 * @export
 * @interface UpsertProductAdditionalInformationRichTextRequestAllOf
 */
export interface UpsertProductAdditionalInformationRichTextRequestAllOf {
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpsertProductAdditionalInformationRichTextRequestAllOf
     */
    'value': TranslatedTypeRequestOfString;
}
/**
 * 
 * @export
 * @interface UpsertProductAdditionalInformationTableRequest
 */
export interface UpsertProductAdditionalInformationTableRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertProductAdditionalInformationTableRequest
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertProductAdditionalInformationTableRequest
     */
    'informationGroupId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertProductAdditionalInformationTableRequest
     */
    'position'?: number | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfStringOf}
     * @memberof UpsertProductAdditionalInformationTableRequest
     */
    'header': TranslatedTypeRequestOfStringOf;
    /**
     * 
     * @type {TranslatedTypeRequestOfStringOfOf}
     * @memberof UpsertProductAdditionalInformationTableRequest
     */
    'rows': TranslatedTypeRequestOfStringOfOf;
}
/**
 * 
 * @export
 * @interface UpsertProductAdditionalInformationTableRequestAllOf
 */
export interface UpsertProductAdditionalInformationTableRequestAllOf {
    /**
     * 
     * @type {TranslatedTypeRequestOfStringOf}
     * @memberof UpsertProductAdditionalInformationTableRequestAllOf
     */
    'header': TranslatedTypeRequestOfStringOf;
    /**
     * 
     * @type {TranslatedTypeRequestOfStringOfOf}
     * @memberof UpsertProductAdditionalInformationTableRequestAllOf
     */
    'rows': TranslatedTypeRequestOfStringOfOf;
}
/**
 * 
 * @export
 * @interface UpsertProductBundleAssetRequest
 */
export interface UpsertProductBundleAssetRequest {
    /**
     * 
     * @type {number}
     * @memberof UpsertProductBundleAssetRequest
     */
    'position': number;
    /**
     * 
     * @type {TranslatedTypeRequestOfGuid}
     * @memberof UpsertProductBundleAssetRequest
     */
    'assetId': TranslatedTypeRequestOfGuid;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpsertProductBundleAssetRequest
     */
    'description'?: TranslatedTypeRequestOfString | null;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpsertProductBundleAssetRequest
     */
    'alternative'?: TranslatedTypeRequestOfString | null;
}
/**
 * 
 * @export
 * @interface UpsertProductBundlePositionRequest
 */
export interface UpsertProductBundlePositionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertProductBundlePositionRequest
     */
    'productBundleId': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertProductBundlePositionRequest
     */
    'productId': string;
    /**
     * 
     * @type {number}
     * @memberof UpsertProductBundlePositionRequest
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof UpsertProductBundlePositionRequest
     */
    'quantity': number;
    /**
     * 
     * @type {Array<CreateProductBundlePriceRequest>}
     * @memberof UpsertProductBundlePositionRequest
     */
    'prices'?: Array<CreateProductBundlePriceRequest> | null;
}
/**
 * 
 * @export
 * @interface UpsertPromotionDiscountRequest
 */
export interface UpsertPromotionDiscountRequest {
    /**
     * 
     * @type {DiscountType}
     * @memberof UpsertPromotionDiscountRequest
     */
    'type': DiscountType;
    /**
     * 
     * @type {number}
     * @memberof UpsertPromotionDiscountRequest
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof UpsertPromotionDiscountRequest
     */
    'maximumDiscountAmount'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpsertPromotionDiscountRequest
     */
    'promotedProducts'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UpsertPromotionRequest
 */
export interface UpsertPromotionRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpsertPromotionRequest
     */
    'active': boolean;
    /**
     * 
     * @type {number}
     * @memberof UpsertPromotionRequest
     */
    'priority': number;
    /**
     * 
     * @type {TranslatedTypeRequestOfString}
     * @memberof UpsertPromotionRequest
     */
    'name': TranslatedTypeRequestOfString;
    /**
     * 
     * @type {string}
     * @memberof UpsertPromotionRequest
     */
    'validFrom': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertPromotionRequest
     */
    'validTo': string;
    /**
     * 
     * @type {number}
     * @memberof UpsertPromotionRequest
     */
    'maxRedemptionsGlobal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertPromotionRequest
     */
    'maxRedemptionsPerCustomer'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertPromotionRequest
     */
    'preventCombination'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertPromotionRequest
     */
    'isValidForWholeAssortment'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpsertPromotionRequest
     */
    'code'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertPromotionRequest
     */
    'useIndividualCodes'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpsertPromotionRequest
     */
    'individualCodePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertPromotionRequest
     */
    'individualCodeSuffix'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertPromotionRequest
     */
    'individualCodeLength'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertPromotionRequest
     */
    'numberOfPromotionCodes'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertPromotionRequest
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {Array<UpsertPromotionDiscountRequest>}
     * @memberof UpsertPromotionRequest
     */
    'discounts': Array<UpsertPromotionDiscountRequest>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpsertPromotionRequest
     */
    'excludedFromPromotionIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'lastName': string;
    /**
     * 
     * @type {Array<UserRoleResponse>}
     * @memberof UserResponse
     */
    'userRoles': Array<UserRoleResponse>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserResponse
     */
    'subscribedNotifications': Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UserResponse
     */
    'settings'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    'isManufacturerAdmin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    'isProductOwner'?: boolean;
    /**
     * 
     * @type {Array<ManufacturerConditionResponse>}
     * @memberof UserResponse
     */
    'manufacturerConditions'?: Array<ManufacturerConditionResponse> | null;
}
/**
 * 
 * @export
 * @interface UserResponseAllOf
 */
export interface UserResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof UserResponseAllOf
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponseAllOf
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponseAllOf
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserResponseAllOf
     */
    'salutation': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponseAllOf
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponseAllOf
     */
    'lastName': string;
    /**
     * 
     * @type {Array<UserRoleResponse>}
     * @memberof UserResponseAllOf
     */
    'userRoles': Array<UserRoleResponse>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserResponseAllOf
     */
    'subscribedNotifications': Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UserResponseAllOf
     */
    'settings'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof UserResponseAllOf
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponseAllOf
     */
    'isManufacturerAdmin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponseAllOf
     */
    'isProductOwner'?: boolean;
    /**
     * 
     * @type {Array<ManufacturerConditionResponse>}
     * @memberof UserResponseAllOf
     */
    'manufacturerConditions'?: Array<ManufacturerConditionResponse> | null;
}
/**
 * 
 * @export
 * @interface UserRoleResponse
 */
export interface UserRoleResponse {
    /**
     * 
     * @type {string}
     * @memberof UserRoleResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserRoleResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserRoleResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {UserRoleType}
     * @memberof UserRoleResponse
     */
    'roleType': UserRoleType;
    /**
     * 
     * @type {string}
     * @memberof UserRoleResponse
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserRoleResponse
     */
    'policies': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserRoleResponse
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserRoleResponse
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @interface UserRoleResponseAllOf
 */
export interface UserRoleResponseAllOf {
    /**
     * 
     * @type {UserRoleType}
     * @memberof UserRoleResponseAllOf
     */
    'roleType': UserRoleType;
    /**
     * 
     * @type {string}
     * @memberof UserRoleResponseAllOf
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserRoleResponseAllOf
     */
    'policies': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserRoleResponseAllOf
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserRoleResponseAllOf
     */
    'manufacturerId'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum UserRoleType {
    ProductOwnerDefault = 'ProductOwnerDefault',
    ManufacturerDefault = 'ManufacturerDefault',
    Custom = 'Custom'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum UserSettingKey {
    PortalSettings = 'PortalSettings'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum ValidationConstraint {
    NotNull = 'NotNull',
    NotEmpty = 'NotEmpty',
    IsInteger = 'IsInteger',
    IsNull = 'IsNull'
}

/**
 * 
 * @export
 * @interface ValidationFailureResponse
 */
export interface ValidationFailureResponse {
    /**
     * 
     * @type {string}
     * @memberof ValidationFailureResponse
     */
    'propertyName': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationFailureResponse
     */
    'errorMessage': string;
    /**
     * 
     * @type {any}
     * @memberof ValidationFailureResponse
     */
    'attemptedValue': any;
    /**
     * 
     * @type {any}
     * @memberof ValidationFailureResponse
     */
    'customState': any;
    /**
     * 
     * @type {Severity}
     * @memberof ValidationFailureResponse
     */
    'severity': Severity;
    /**
     * 
     * @type {string}
     * @memberof ValidationFailureResponse
     */
    'errorCode': string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ValidationFailureResponse
     */
    'formattedMessagePlaceholderValues': { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface ValidationResultResponse
 */
export interface ValidationResultResponse {
    /**
     * 
     * @type {Array<ValidationFailureResponse>}
     * @memberof ValidationResultResponse
     */
    'errors': Array<ValidationFailureResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof ValidationResultResponse
     */
    'isValid': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ValidationResultResponse
     */
    'ruleSetsExecuted': Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum VatAbsenceReasonEnum {
    IndustryExemption = 'industryExemption',
    BelowTaxThreshold = 'belowTaxThreshold'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum VatAbsenceReasonEnum2 {
    IndustryExemption = 'industryExemption',
    BelowTaxThreshold = 'belowTaxThreshold'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum VatAbsenceReasonEnum3 {
    IndustryExemption = 'industryExemption',
    BelowTaxThreshold = 'belowTaxThreshold'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum VatAbsenceReasonEnum4 {
    IndustryExemption = 'industryExemption',
    BelowTaxThreshold = 'belowTaxThreshold'
}

/**
 * 
 * @export
 * @interface VerificationDeadline
 */
export interface VerificationDeadline {
    /**
     * 
     * @type {Array<CapabilitiesEnum5>}
     * @memberof VerificationDeadline
     */
    'capabilities'?: Array<CapabilitiesEnum5> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationDeadline
     */
    'entityIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VerificationDeadline
     */
    'expiresAt'?: string;
}
/**
 * 
 * @export
 * @interface VerificationDeadline2
 */
export interface VerificationDeadline2 {
    /**
     * 
     * @type {Array<CapabilitiesEnum6>}
     * @memberof VerificationDeadline2
     */
    'capabilities'?: Array<CapabilitiesEnum6> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VerificationDeadline2
     */
    'entityIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VerificationDeadline2
     */
    'expiresAt'?: string;
}
/**
 * 
 * @export
 * @interface VerificationError
 */
export interface VerificationError {
    /**
     * 
     * @type {Array<CapabilitiesEnum>}
     * @memberof VerificationError
     */
    'capabilities'?: Array<CapabilitiesEnum> | null;
    /**
     * 
     * @type {TypeEnum7}
     * @memberof VerificationError
     */
    'type'?: TypeEnum7 | null;
    /**
     * 
     * @type {string}
     * @memberof VerificationError
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerificationError
     */
    'message'?: string | null;
    /**
     * 
     * @type {Array<RemediatingAction>}
     * @memberof VerificationError
     */
    'remediatingActions'?: Array<RemediatingAction> | null;
    /**
     * 
     * @type {Array<VerificationErrorRecursive>}
     * @memberof VerificationError
     */
    'subErrors'?: Array<VerificationErrorRecursive> | null;
}
/**
 * 
 * @export
 * @interface VerificationError2
 */
export interface VerificationError2 {
    /**
     * 
     * @type {Array<CapabilitiesEnum3>}
     * @memberof VerificationError2
     */
    'capabilities'?: Array<CapabilitiesEnum3> | null;
    /**
     * 
     * @type {TypeEnum13}
     * @memberof VerificationError2
     */
    'type'?: TypeEnum13 | null;
    /**
     * 
     * @type {string}
     * @memberof VerificationError2
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerificationError2
     */
    'message'?: string | null;
    /**
     * 
     * @type {Array<RemediatingAction2>}
     * @memberof VerificationError2
     */
    'remediatingActions'?: Array<RemediatingAction2> | null;
    /**
     * 
     * @type {Array<VerificationErrorRecursive2>}
     * @memberof VerificationError2
     */
    'subErrors'?: Array<VerificationErrorRecursive2> | null;
}
/**
 * 
 * @export
 * @interface VerificationErrorRecursive
 */
export interface VerificationErrorRecursive {
    /**
     * 
     * @type {Array<CapabilitiesEnum2>}
     * @memberof VerificationErrorRecursive
     */
    'capabilities'?: Array<CapabilitiesEnum2> | null;
    /**
     * 
     * @type {TypeEnum8}
     * @memberof VerificationErrorRecursive
     */
    'type'?: TypeEnum8 | null;
    /**
     * 
     * @type {string}
     * @memberof VerificationErrorRecursive
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerificationErrorRecursive
     */
    'message'?: string | null;
    /**
     * 
     * @type {Array<RemediatingAction>}
     * @memberof VerificationErrorRecursive
     */
    'remediatingActions'?: Array<RemediatingAction> | null;
}
/**
 * 
 * @export
 * @interface VerificationErrorRecursive2
 */
export interface VerificationErrorRecursive2 {
    /**
     * 
     * @type {Array<CapabilitiesEnum4>}
     * @memberof VerificationErrorRecursive2
     */
    'capabilities'?: Array<CapabilitiesEnum4> | null;
    /**
     * 
     * @type {TypeEnum14}
     * @memberof VerificationErrorRecursive2
     */
    'type'?: TypeEnum14 | null;
    /**
     * 
     * @type {string}
     * @memberof VerificationErrorRecursive2
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerificationErrorRecursive2
     */
    'message'?: string | null;
    /**
     * 
     * @type {Array<RemediatingAction2>}
     * @memberof VerificationErrorRecursive2
     */
    'remediatingActions'?: Array<RemediatingAction2> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum VerificationStatusEnum {
    Invalid = 'invalid',
    Pending = 'pending',
    Rejected = 'rejected',
    Valid = 'valid'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum VerificationStatusEnum2 {
    Invalid = 'invalid',
    Pending = 'pending',
    Rejected = 'rejected',
    Valid = 'valid'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum WarehouseEncryptedPropertyKey {
    DhlReturnsApiUsername = 'DhlReturnsApiUsername',
    DhlReturnsApiPassword = 'DhlReturnsApiPassword',
    DhlReturnsApiKey = 'DhlReturnsApiKey',
    DhlReturnsApiBasePath = 'DhlReturnsApiBasePath',
    DhlReturnsApiReceiverId = 'DhlReturnsApiReceiverId'
}

/**
 * 
 * @export
 * @interface WarehouseResponse
 */
export interface WarehouseResponse {
    /**
     * 
     * @type {string}
     * @memberof WarehouseResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WarehouseResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof WarehouseResponse
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WarehouseResponse
     */
    'name': string;
    /**
     * 
     * @type {WarehouseType}
     * @memberof WarehouseResponse
     */
    'type': WarehouseType;
    /**
     * 
     * @type {string}
     * @memberof WarehouseResponse
     */
    'manufacturerId'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof WarehouseResponse
     */
    'encryptedProperties'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof WarehouseResponse
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WarehouseResponse
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface WarehouseResponseAllOf
 */
export interface WarehouseResponseAllOf {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof WarehouseResponseAllOf
     */
    'encryptedProperties'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof WarehouseResponseAllOf
     */
    'shopReferenceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WarehouseResponseAllOf
     */
    'syncedAt'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum WarehouseType {
    CentralWarehouse = 'CentralWarehouse',
    ManufacturerWarehouse = 'ManufacturerWarehouse'
}

/**
 * 
 * @export
 * @interface WebData
 */
export interface WebData {
    /**
     * 
     * @type {string}
     * @memberof WebData
     */
    'webAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebData
     */
    'webAddressId'?: string | null;
}

/**
 * AdyenAccountHolderApi - axios parameter creator
 * @export
 */
export const AdyenAccountHolderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAdyenAccountHolderRequest} createAdyenAccountHolderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenAccountHolderCreateAccountHolder: async (createAdyenAccountHolderRequest: CreateAdyenAccountHolderRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAdyenAccountHolderRequest' is not null or undefined
            assertParamExists('adyenAccountHolderCreateAccountHolder', 'createAdyenAccountHolderRequest', createAdyenAccountHolderRequest)
            const localVarPath = `/api/AdyenAccountHolder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAdyenAccountHolderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountHolderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenAccountHolderGetAccountHolderById: async (accountHolderId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountHolderId' is not null or undefined
            assertParamExists('adyenAccountHolderGetAccountHolderById', 'accountHolderId', accountHolderId)
            const localVarPath = `/api/AdyenAccountHolder/{accountHolderId}`
                .replace(`{${"accountHolderId"}}`, encodeURIComponent(String(accountHolderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountHolderId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenAccountHolderGetBalanceAccounts: async (accountHolderId: string, offset?: number, limit?: number, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountHolderId' is not null or undefined
            assertParamExists('adyenAccountHolderGetBalanceAccounts', 'accountHolderId', accountHolderId)
            const localVarPath = `/api/AdyenAccountHolder/{accountHolderId}/balanceAccounts`
                .replace(`{${"accountHolderId"}}`, encodeURIComponent(String(accountHolderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountHolderId 
         * @param {UpdateAdyenAccountHolderRequest} updateAdyenAccountHolderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenAccountHolderUpdateAccountHolder: async (accountHolderId: string, updateAdyenAccountHolderRequest: UpdateAdyenAccountHolderRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountHolderId' is not null or undefined
            assertParamExists('adyenAccountHolderUpdateAccountHolder', 'accountHolderId', accountHolderId)
            // verify required parameter 'updateAdyenAccountHolderRequest' is not null or undefined
            assertParamExists('adyenAccountHolderUpdateAccountHolder', 'updateAdyenAccountHolderRequest', updateAdyenAccountHolderRequest)
            const localVarPath = `/api/AdyenAccountHolder/{accountHolderId}`
                .replace(`{${"accountHolderId"}}`, encodeURIComponent(String(accountHolderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAdyenAccountHolderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdyenAccountHolderApi - functional programming interface
 * @export
 */
export const AdyenAccountHolderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdyenAccountHolderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAdyenAccountHolderRequest} createAdyenAccountHolderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adyenAccountHolderCreateAccountHolder(createAdyenAccountHolderRequest: CreateAdyenAccountHolderRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdyenAccountHolderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adyenAccountHolderCreateAccountHolder(createAdyenAccountHolderRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accountHolderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adyenAccountHolderGetAccountHolderById(accountHolderId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdyenAccountHolderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adyenAccountHolderGetAccountHolderById(accountHolderId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accountHolderId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adyenAccountHolderGetBalanceAccounts(accountHolderId: string, offset?: number, limit?: number, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedBalanceAccountsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adyenAccountHolderGetBalanceAccounts(accountHolderId, offset, limit, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accountHolderId 
         * @param {UpdateAdyenAccountHolderRequest} updateAdyenAccountHolderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adyenAccountHolderUpdateAccountHolder(accountHolderId: string, updateAdyenAccountHolderRequest: UpdateAdyenAccountHolderRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adyenAccountHolderUpdateAccountHolder(accountHolderId, updateAdyenAccountHolderRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdyenAccountHolderApi - factory interface
 * @export
 */
export const AdyenAccountHolderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdyenAccountHolderApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateAdyenAccountHolderRequest} createAdyenAccountHolderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenAccountHolderCreateAccountHolder(createAdyenAccountHolderRequest: CreateAdyenAccountHolderRequest, xVersion?: string, options?: any): AxiosPromise<AdyenAccountHolderResponse> {
            return localVarFp.adyenAccountHolderCreateAccountHolder(createAdyenAccountHolderRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountHolderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenAccountHolderGetAccountHolderById(accountHolderId: string, xVersion?: string, options?: any): AxiosPromise<AdyenAccountHolderResponse> {
            return localVarFp.adyenAccountHolderGetAccountHolderById(accountHolderId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountHolderId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenAccountHolderGetBalanceAccounts(accountHolderId: string, offset?: number, limit?: number, xVersion?: string, options?: any): AxiosPromise<PaginatedBalanceAccountsResponse> {
            return localVarFp.adyenAccountHolderGetBalanceAccounts(accountHolderId, offset, limit, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountHolderId 
         * @param {UpdateAdyenAccountHolderRequest} updateAdyenAccountHolderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenAccountHolderUpdateAccountHolder(accountHolderId: string, updateAdyenAccountHolderRequest: UpdateAdyenAccountHolderRequest, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.adyenAccountHolderUpdateAccountHolder(accountHolderId, updateAdyenAccountHolderRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdyenAccountHolderApi - object-oriented interface
 * @export
 * @class AdyenAccountHolderApi
 * @extends {BaseAPI}
 */
export class AdyenAccountHolderApi extends BaseAPI {
    /**
     * 
     * @param {CreateAdyenAccountHolderRequest} createAdyenAccountHolderRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenAccountHolderApi
     */
    public adyenAccountHolderCreateAccountHolder(createAdyenAccountHolderRequest: CreateAdyenAccountHolderRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return AdyenAccountHolderApiFp(this.configuration).adyenAccountHolderCreateAccountHolder(createAdyenAccountHolderRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountHolderId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenAccountHolderApi
     */
    public adyenAccountHolderGetAccountHolderById(accountHolderId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return AdyenAccountHolderApiFp(this.configuration).adyenAccountHolderGetAccountHolderById(accountHolderId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountHolderId 
     * @param {number} [offset] 
     * @param {number} [limit] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenAccountHolderApi
     */
    public adyenAccountHolderGetBalanceAccounts(accountHolderId: string, offset?: number, limit?: number, xVersion?: string, options?: AxiosRequestConfig) {
        return AdyenAccountHolderApiFp(this.configuration).adyenAccountHolderGetBalanceAccounts(accountHolderId, offset, limit, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountHolderId 
     * @param {UpdateAdyenAccountHolderRequest} updateAdyenAccountHolderRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenAccountHolderApi
     */
    public adyenAccountHolderUpdateAccountHolder(accountHolderId: string, updateAdyenAccountHolderRequest: UpdateAdyenAccountHolderRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return AdyenAccountHolderApiFp(this.configuration).adyenAccountHolderUpdateAccountHolder(accountHolderId, updateAdyenAccountHolderRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdyenBalanceAccountsApi - axios parameter creator
 * @export
 */
export const AdyenBalanceAccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAdyenBalanceAccountRequest} createAdyenBalanceAccountRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenBalanceAccountsCreateBalanceAccount: async (createAdyenBalanceAccountRequest: CreateAdyenBalanceAccountRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAdyenBalanceAccountRequest' is not null or undefined
            assertParamExists('adyenBalanceAccountsCreateBalanceAccount', 'createAdyenBalanceAccountRequest', createAdyenBalanceAccountRequest)
            const localVarPath = `/api/AdyenBalanceAccounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAdyenBalanceAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} balanceAccountId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenBalanceAccountsGetBalanceAccountById: async (balanceAccountId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'balanceAccountId' is not null or undefined
            assertParamExists('adyenBalanceAccountsGetBalanceAccountById', 'balanceAccountId', balanceAccountId)
            const localVarPath = `/api/AdyenBalanceAccounts/{balanceAccountId}`
                .replace(`{${"balanceAccountId"}}`, encodeURIComponent(String(balanceAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} balanceAccountId 
         * @param {UpdateAdyenBalanceAccountRequest} updateAdyenBalanceAccountRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenBalanceAccountsUpdateBalanceAccount: async (balanceAccountId: string, updateAdyenBalanceAccountRequest: UpdateAdyenBalanceAccountRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'balanceAccountId' is not null or undefined
            assertParamExists('adyenBalanceAccountsUpdateBalanceAccount', 'balanceAccountId', balanceAccountId)
            // verify required parameter 'updateAdyenBalanceAccountRequest' is not null or undefined
            assertParamExists('adyenBalanceAccountsUpdateBalanceAccount', 'updateAdyenBalanceAccountRequest', updateAdyenBalanceAccountRequest)
            const localVarPath = `/api/AdyenBalanceAccounts/{balanceAccountId}`
                .replace(`{${"balanceAccountId"}}`, encodeURIComponent(String(balanceAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAdyenBalanceAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdyenBalanceAccountsApi - functional programming interface
 * @export
 */
export const AdyenBalanceAccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdyenBalanceAccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAdyenBalanceAccountRequest} createAdyenBalanceAccountRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adyenBalanceAccountsCreateBalanceAccount(createAdyenBalanceAccountRequest: CreateAdyenBalanceAccountRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdyenBalanceAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adyenBalanceAccountsCreateBalanceAccount(createAdyenBalanceAccountRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} balanceAccountId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adyenBalanceAccountsGetBalanceAccountById(balanceAccountId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdyenBalanceAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adyenBalanceAccountsGetBalanceAccountById(balanceAccountId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} balanceAccountId 
         * @param {UpdateAdyenBalanceAccountRequest} updateAdyenBalanceAccountRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adyenBalanceAccountsUpdateBalanceAccount(balanceAccountId: string, updateAdyenBalanceAccountRequest: UpdateAdyenBalanceAccountRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adyenBalanceAccountsUpdateBalanceAccount(balanceAccountId, updateAdyenBalanceAccountRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdyenBalanceAccountsApi - factory interface
 * @export
 */
export const AdyenBalanceAccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdyenBalanceAccountsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateAdyenBalanceAccountRequest} createAdyenBalanceAccountRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenBalanceAccountsCreateBalanceAccount(createAdyenBalanceAccountRequest: CreateAdyenBalanceAccountRequest, xVersion?: string, options?: any): AxiosPromise<AdyenBalanceAccountResponse> {
            return localVarFp.adyenBalanceAccountsCreateBalanceAccount(createAdyenBalanceAccountRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} balanceAccountId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenBalanceAccountsGetBalanceAccountById(balanceAccountId: string, xVersion?: string, options?: any): AxiosPromise<AdyenBalanceAccountResponse> {
            return localVarFp.adyenBalanceAccountsGetBalanceAccountById(balanceAccountId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} balanceAccountId 
         * @param {UpdateAdyenBalanceAccountRequest} updateAdyenBalanceAccountRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenBalanceAccountsUpdateBalanceAccount(balanceAccountId: string, updateAdyenBalanceAccountRequest: UpdateAdyenBalanceAccountRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.adyenBalanceAccountsUpdateBalanceAccount(balanceAccountId, updateAdyenBalanceAccountRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdyenBalanceAccountsApi - object-oriented interface
 * @export
 * @class AdyenBalanceAccountsApi
 * @extends {BaseAPI}
 */
export class AdyenBalanceAccountsApi extends BaseAPI {
    /**
     * 
     * @param {CreateAdyenBalanceAccountRequest} createAdyenBalanceAccountRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenBalanceAccountsApi
     */
    public adyenBalanceAccountsCreateBalanceAccount(createAdyenBalanceAccountRequest: CreateAdyenBalanceAccountRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return AdyenBalanceAccountsApiFp(this.configuration).adyenBalanceAccountsCreateBalanceAccount(createAdyenBalanceAccountRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} balanceAccountId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenBalanceAccountsApi
     */
    public adyenBalanceAccountsGetBalanceAccountById(balanceAccountId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return AdyenBalanceAccountsApiFp(this.configuration).adyenBalanceAccountsGetBalanceAccountById(balanceAccountId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} balanceAccountId 
     * @param {UpdateAdyenBalanceAccountRequest} updateAdyenBalanceAccountRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenBalanceAccountsApi
     */
    public adyenBalanceAccountsUpdateBalanceAccount(balanceAccountId: string, updateAdyenBalanceAccountRequest: UpdateAdyenBalanceAccountRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return AdyenBalanceAccountsApiFp(this.configuration).adyenBalanceAccountsUpdateBalanceAccount(balanceAccountId, updateAdyenBalanceAccountRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdyenDocumentsApi - axios parameter creator
 * @export
 */
export const AdyenDocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [xVersion] 
         * @param {string} [ownerType] 
         * @param {string} [ownerId] 
         * @param {string} [type] 
         * @param {Array<any>} [attachments] 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenDocumentsCreateAdyenDocument: async (xVersion?: string, ownerType?: string, ownerId?: string, type?: string, attachments?: Array<any>, description?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AdyenDocuments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


            if (ownerType !== undefined) { 
                localVarFormParams.append('OwnerType', ownerType as any);
            }
    
            if (ownerId !== undefined) { 
                localVarFormParams.append('OwnerId', ownerId as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('Type', type as any);
            }
                if (attachments) {
                attachments.forEach((element) => {
                    localVarFormParams.append('Attachments', element as any);
                })
            }

    
            if (description !== undefined) { 
                localVarFormParams.append('Description', description as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenDocumentsDeleteAdyenDocument: async (documentId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('adyenDocumentsDeleteAdyenDocument', 'documentId', documentId)
            const localVarPath = `/api/AdyenDocuments/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenDocumentsGetAdyenDocumentById: async (documentId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('adyenDocumentsGetAdyenDocumentById', 'documentId', documentId)
            const localVarPath = `/api/AdyenDocuments/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdyenDocumentsApi - functional programming interface
 * @export
 */
export const AdyenDocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdyenDocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [xVersion] 
         * @param {string} [ownerType] 
         * @param {string} [ownerId] 
         * @param {string} [type] 
         * @param {Array<any>} [attachments] 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adyenDocumentsCreateAdyenDocument(xVersion?: string, ownerType?: string, ownerId?: string, type?: string, attachments?: Array<any>, description?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdyenDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adyenDocumentsCreateAdyenDocument(xVersion, ownerType, ownerId, type, attachments, description, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adyenDocumentsDeleteAdyenDocument(documentId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adyenDocumentsDeleteAdyenDocument(documentId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adyenDocumentsGetAdyenDocumentById(documentId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adyenDocumentsGetAdyenDocumentById(documentId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdyenDocumentsApi - factory interface
 * @export
 */
export const AdyenDocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdyenDocumentsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [xVersion] 
         * @param {string} [ownerType] 
         * @param {string} [ownerId] 
         * @param {string} [type] 
         * @param {Array<any>} [attachments] 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenDocumentsCreateAdyenDocument(xVersion?: string, ownerType?: string, ownerId?: string, type?: string, attachments?: Array<any>, description?: string, options?: any): AxiosPromise<AdyenDocumentResponse> {
            return localVarFp.adyenDocumentsCreateAdyenDocument(xVersion, ownerType, ownerId, type, attachments, description, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenDocumentsDeleteAdyenDocument(documentId: string, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.adyenDocumentsDeleteAdyenDocument(documentId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenDocumentsGetAdyenDocumentById(documentId: string, xVersion?: string, options?: any): AxiosPromise<Document> {
            return localVarFp.adyenDocumentsGetAdyenDocumentById(documentId, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdyenDocumentsApi - object-oriented interface
 * @export
 * @class AdyenDocumentsApi
 * @extends {BaseAPI}
 */
export class AdyenDocumentsApi extends BaseAPI {
    /**
     * 
     * @param {string} [xVersion] 
     * @param {string} [ownerType] 
     * @param {string} [ownerId] 
     * @param {string} [type] 
     * @param {Array<any>} [attachments] 
     * @param {string} [description] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenDocumentsApi
     */
    public adyenDocumentsCreateAdyenDocument(xVersion?: string, ownerType?: string, ownerId?: string, type?: string, attachments?: Array<any>, description?: string, options?: AxiosRequestConfig) {
        return AdyenDocumentsApiFp(this.configuration).adyenDocumentsCreateAdyenDocument(xVersion, ownerType, ownerId, type, attachments, description, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} documentId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenDocumentsApi
     */
    public adyenDocumentsDeleteAdyenDocument(documentId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return AdyenDocumentsApiFp(this.configuration).adyenDocumentsDeleteAdyenDocument(documentId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} documentId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenDocumentsApi
     */
    public adyenDocumentsGetAdyenDocumentById(documentId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return AdyenDocumentsApiFp(this.configuration).adyenDocumentsGetAdyenDocumentById(documentId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdyenGeneralApi - axios parameter creator
 * @export
 */
export const AdyenGeneralApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} balancePlatformId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenGeneralGetBalancePlatformAccountHolders: async (balancePlatformId: string, offset?: number, limit?: number, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'balancePlatformId' is not null or undefined
            assertParamExists('adyenGeneralGetBalancePlatformAccountHolders', 'balancePlatformId', balancePlatformId)
            const localVarPath = `/api/AdyenGeneral/{balancePlatformId}/accountHolders`
                .replace(`{${"balancePlatformId"}}`, encodeURIComponent(String(balancePlatformId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['Offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} balancePlatformId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenGeneralGetBalancePlatformById: async (balancePlatformId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'balancePlatformId' is not null or undefined
            assertParamExists('adyenGeneralGetBalancePlatformById', 'balancePlatformId', balancePlatformId)
            const localVarPath = `/api/AdyenGeneral/{balancePlatformId}`
                .replace(`{${"balancePlatformId"}}`, encodeURIComponent(String(balancePlatformId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdyenGeneralApi - functional programming interface
 * @export
 */
export const AdyenGeneralApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdyenGeneralApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} balancePlatformId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adyenGeneralGetBalancePlatformAccountHolders(balancePlatformId: string, offset?: number, limit?: number, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedAccountHoldersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adyenGeneralGetBalancePlatformAccountHolders(balancePlatformId, offset, limit, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} balancePlatformId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adyenGeneralGetBalancePlatformById(balancePlatformId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdyenBalancePlatformResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adyenGeneralGetBalancePlatformById(balancePlatformId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdyenGeneralApi - factory interface
 * @export
 */
export const AdyenGeneralApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdyenGeneralApiFp(configuration)
    return {
        /**
         * 
         * @param {string} balancePlatformId 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenGeneralGetBalancePlatformAccountHolders(balancePlatformId: string, offset?: number, limit?: number, xVersion?: string, options?: any): AxiosPromise<PaginatedAccountHoldersResponse> {
            return localVarFp.adyenGeneralGetBalancePlatformAccountHolders(balancePlatformId, offset, limit, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} balancePlatformId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenGeneralGetBalancePlatformById(balancePlatformId: string, xVersion?: string, options?: any): AxiosPromise<AdyenBalancePlatformResponse> {
            return localVarFp.adyenGeneralGetBalancePlatformById(balancePlatformId, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdyenGeneralApi - object-oriented interface
 * @export
 * @class AdyenGeneralApi
 * @extends {BaseAPI}
 */
export class AdyenGeneralApi extends BaseAPI {
    /**
     * 
     * @param {string} balancePlatformId 
     * @param {number} [offset] 
     * @param {number} [limit] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenGeneralApi
     */
    public adyenGeneralGetBalancePlatformAccountHolders(balancePlatformId: string, offset?: number, limit?: number, xVersion?: string, options?: AxiosRequestConfig) {
        return AdyenGeneralApiFp(this.configuration).adyenGeneralGetBalancePlatformAccountHolders(balancePlatformId, offset, limit, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} balancePlatformId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenGeneralApi
     */
    public adyenGeneralGetBalancePlatformById(balancePlatformId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return AdyenGeneralApiFp(this.configuration).adyenGeneralGetBalancePlatformById(balancePlatformId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdyenLegalEntitiesApi - axios parameter creator
 * @export
 */
export const AdyenLegalEntitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAdyenLegalEntityRequest} createAdyenLegalEntityRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenLegalEntitiesCreateLegalEntity: async (createAdyenLegalEntityRequest: CreateAdyenLegalEntityRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAdyenLegalEntityRequest' is not null or undefined
            assertParamExists('adyenLegalEntitiesCreateLegalEntity', 'createAdyenLegalEntityRequest', createAdyenLegalEntityRequest)
            const localVarPath = `/api/AdyenLegalEntities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAdyenLegalEntityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} legalEntityId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenLegalEntitiesDeleteLegalEntity: async (legalEntityId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'legalEntityId' is not null or undefined
            assertParamExists('adyenLegalEntitiesDeleteLegalEntity', 'legalEntityId', legalEntityId)
            const localVarPath = `/api/AdyenLegalEntities/{legalEntityId}`
                .replace(`{${"legalEntityId"}}`, encodeURIComponent(String(legalEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenLegalEntitiesGetLegalEntities: async (manufacturerId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AdyenLegalEntities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} legalEntityId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenLegalEntitiesGetLegalEntityById: async (legalEntityId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'legalEntityId' is not null or undefined
            assertParamExists('adyenLegalEntitiesGetLegalEntityById', 'legalEntityId', legalEntityId)
            const localVarPath = `/api/AdyenLegalEntities/{legalEntityId}`
                .replace(`{${"legalEntityId"}}`, encodeURIComponent(String(legalEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} legalEntityId 
         * @param {UpdateAdyenLegalEntityRequest} updateAdyenLegalEntityRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenLegalEntitiesUpdateLegalEntity: async (legalEntityId: string, updateAdyenLegalEntityRequest: UpdateAdyenLegalEntityRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'legalEntityId' is not null or undefined
            assertParamExists('adyenLegalEntitiesUpdateLegalEntity', 'legalEntityId', legalEntityId)
            // verify required parameter 'updateAdyenLegalEntityRequest' is not null or undefined
            assertParamExists('adyenLegalEntitiesUpdateLegalEntity', 'updateAdyenLegalEntityRequest', updateAdyenLegalEntityRequest)
            const localVarPath = `/api/AdyenLegalEntities/{legalEntityId}`
                .replace(`{${"legalEntityId"}}`, encodeURIComponent(String(legalEntityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAdyenLegalEntityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdyenLegalEntitiesApi - functional programming interface
 * @export
 */
export const AdyenLegalEntitiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdyenLegalEntitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAdyenLegalEntityRequest} createAdyenLegalEntityRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adyenLegalEntitiesCreateLegalEntity(createAdyenLegalEntityRequest: CreateAdyenLegalEntityRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdyenLegalEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adyenLegalEntitiesCreateLegalEntity(createAdyenLegalEntityRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} legalEntityId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adyenLegalEntitiesDeleteLegalEntity(legalEntityId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adyenLegalEntitiesDeleteLegalEntity(legalEntityId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adyenLegalEntitiesGetLegalEntities(manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdyenLegalEntityResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adyenLegalEntitiesGetLegalEntities(manufacturerId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} legalEntityId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adyenLegalEntitiesGetLegalEntityById(legalEntityId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdyenLegalEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adyenLegalEntitiesGetLegalEntityById(legalEntityId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} legalEntityId 
         * @param {UpdateAdyenLegalEntityRequest} updateAdyenLegalEntityRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adyenLegalEntitiesUpdateLegalEntity(legalEntityId: string, updateAdyenLegalEntityRequest: UpdateAdyenLegalEntityRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adyenLegalEntitiesUpdateLegalEntity(legalEntityId, updateAdyenLegalEntityRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdyenLegalEntitiesApi - factory interface
 * @export
 */
export const AdyenLegalEntitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdyenLegalEntitiesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateAdyenLegalEntityRequest} createAdyenLegalEntityRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenLegalEntitiesCreateLegalEntity(createAdyenLegalEntityRequest: CreateAdyenLegalEntityRequest, xVersion?: string, options?: any): AxiosPromise<AdyenLegalEntityResponse> {
            return localVarFp.adyenLegalEntitiesCreateLegalEntity(createAdyenLegalEntityRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} legalEntityId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenLegalEntitiesDeleteLegalEntity(legalEntityId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.adyenLegalEntitiesDeleteLegalEntity(legalEntityId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenLegalEntitiesGetLegalEntities(manufacturerId?: string, xVersion?: string, options?: any): AxiosPromise<Array<AdyenLegalEntityResponse>> {
            return localVarFp.adyenLegalEntitiesGetLegalEntities(manufacturerId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} legalEntityId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenLegalEntitiesGetLegalEntityById(legalEntityId: string, xVersion?: string, options?: any): AxiosPromise<AdyenLegalEntityResponse> {
            return localVarFp.adyenLegalEntitiesGetLegalEntityById(legalEntityId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} legalEntityId 
         * @param {UpdateAdyenLegalEntityRequest} updateAdyenLegalEntityRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenLegalEntitiesUpdateLegalEntity(legalEntityId: string, updateAdyenLegalEntityRequest: UpdateAdyenLegalEntityRequest, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.adyenLegalEntitiesUpdateLegalEntity(legalEntityId, updateAdyenLegalEntityRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdyenLegalEntitiesApi - object-oriented interface
 * @export
 * @class AdyenLegalEntitiesApi
 * @extends {BaseAPI}
 */
export class AdyenLegalEntitiesApi extends BaseAPI {
    /**
     * 
     * @param {CreateAdyenLegalEntityRequest} createAdyenLegalEntityRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenLegalEntitiesApi
     */
    public adyenLegalEntitiesCreateLegalEntity(createAdyenLegalEntityRequest: CreateAdyenLegalEntityRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return AdyenLegalEntitiesApiFp(this.configuration).adyenLegalEntitiesCreateLegalEntity(createAdyenLegalEntityRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} legalEntityId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenLegalEntitiesApi
     */
    public adyenLegalEntitiesDeleteLegalEntity(legalEntityId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return AdyenLegalEntitiesApiFp(this.configuration).adyenLegalEntitiesDeleteLegalEntity(legalEntityId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenLegalEntitiesApi
     */
    public adyenLegalEntitiesGetLegalEntities(manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return AdyenLegalEntitiesApiFp(this.configuration).adyenLegalEntitiesGetLegalEntities(manufacturerId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} legalEntityId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenLegalEntitiesApi
     */
    public adyenLegalEntitiesGetLegalEntityById(legalEntityId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return AdyenLegalEntitiesApiFp(this.configuration).adyenLegalEntitiesGetLegalEntityById(legalEntityId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} legalEntityId 
     * @param {UpdateAdyenLegalEntityRequest} updateAdyenLegalEntityRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenLegalEntitiesApi
     */
    public adyenLegalEntitiesUpdateLegalEntity(legalEntityId: string, updateAdyenLegalEntityRequest: UpdateAdyenLegalEntityRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return AdyenLegalEntitiesApiFp(this.configuration).adyenLegalEntitiesUpdateLegalEntity(legalEntityId, updateAdyenLegalEntityRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdyenNotificationsApi - axios parameter creator
 * @export
 */
export const AdyenNotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AdyenBalancePlatformNotificationRequest} adyenBalancePlatformNotificationRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenNotificationsBalancePlatformNotification: async (adyenBalancePlatformNotificationRequest: AdyenBalancePlatformNotificationRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adyenBalancePlatformNotificationRequest' is not null or undefined
            assertParamExists('adyenNotificationsBalancePlatformNotification', 'adyenBalancePlatformNotificationRequest', adyenBalancePlatformNotificationRequest)
            const localVarPath = `/api/AdyenNotifications/balancePlatform`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adyenBalancePlatformNotificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {} UNKNOWN_PARAM_NAME 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenNotificationsStandardNotification: async (UNKNOWN_PARAM_NAME: any, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'UNKNOWN_PARAM_NAME' is not null or undefined
            assertParamExists('adyenNotificationsStandardNotification', 'UNKNOWN_PARAM_NAME', UNKNOWN_PARAM_NAME)
            const localVarPath = `/api/AdyenNotifications/standard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UNKNOWN_PARAM_NAME, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdyenNotificationsApi - functional programming interface
 * @export
 */
export const AdyenNotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdyenNotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AdyenBalancePlatformNotificationRequest} adyenBalancePlatformNotificationRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adyenNotificationsBalancePlatformNotification(adyenBalancePlatformNotificationRequest: AdyenBalancePlatformNotificationRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adyenNotificationsBalancePlatformNotification(adyenBalancePlatformNotificationRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {} UNKNOWN_PARAM_NAME 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adyenNotificationsStandardNotification(UNKNOWN_PARAM_NAME: any, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adyenNotificationsStandardNotification(UNKNOWN_PARAM_NAME, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdyenNotificationsApi - factory interface
 * @export
 */
export const AdyenNotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdyenNotificationsApiFp(configuration)
    return {
        /**
         * 
         * @param {AdyenBalancePlatformNotificationRequest} adyenBalancePlatformNotificationRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenNotificationsBalancePlatformNotification(adyenBalancePlatformNotificationRequest: AdyenBalancePlatformNotificationRequest, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.adyenNotificationsBalancePlatformNotification(adyenBalancePlatformNotificationRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {} UNKNOWN_PARAM_NAME 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenNotificationsStandardNotification(UNKNOWN_PARAM_NAME: any, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.adyenNotificationsStandardNotification(UNKNOWN_PARAM_NAME, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdyenNotificationsApi - object-oriented interface
 * @export
 * @class AdyenNotificationsApi
 * @extends {BaseAPI}
 */
export class AdyenNotificationsApi extends BaseAPI {
    /**
     * 
     * @param {AdyenBalancePlatformNotificationRequest} adyenBalancePlatformNotificationRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenNotificationsApi
     */
    public adyenNotificationsBalancePlatformNotification(adyenBalancePlatformNotificationRequest: AdyenBalancePlatformNotificationRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return AdyenNotificationsApiFp(this.configuration).adyenNotificationsBalancePlatformNotification(adyenBalancePlatformNotificationRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {} UNKNOWN_PARAM_NAME 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenNotificationsApi
     */
    public adyenNotificationsStandardNotification(UNKNOWN_PARAM_NAME: any, xVersion?: string, options?: AxiosRequestConfig) {
        return AdyenNotificationsApiFp(this.configuration).adyenNotificationsStandardNotification(UNKNOWN_PARAM_NAME, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdyenPaymentRefundsApi - axios parameter creator
 * @export
 */
export const AdyenPaymentRefundsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} orderId 
         * @param {CreateAdyenPaymentRefundRequest} createAdyenPaymentRefundRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenPaymentRefundsCreateRefund: async (orderId: string, createAdyenPaymentRefundRequest: CreateAdyenPaymentRefundRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('adyenPaymentRefundsCreateRefund', 'orderId', orderId)
            // verify required parameter 'createAdyenPaymentRefundRequest' is not null or undefined
            assertParamExists('adyenPaymentRefundsCreateRefund', 'createAdyenPaymentRefundRequest', createAdyenPaymentRefundRequest)
            const localVarPath = `/api/AdyenPaymentRefunds/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAdyenPaymentRefundRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdyenPaymentRefundsApi - functional programming interface
 * @export
 */
export const AdyenPaymentRefundsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdyenPaymentRefundsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} orderId 
         * @param {CreateAdyenPaymentRefundRequest} createAdyenPaymentRefundRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adyenPaymentRefundsCreateRefund(orderId: string, createAdyenPaymentRefundRequest: CreateAdyenPaymentRefundRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdyenPaymentRefundResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adyenPaymentRefundsCreateRefund(orderId, createAdyenPaymentRefundRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdyenPaymentRefundsApi - factory interface
 * @export
 */
export const AdyenPaymentRefundsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdyenPaymentRefundsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} orderId 
         * @param {CreateAdyenPaymentRefundRequest} createAdyenPaymentRefundRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenPaymentRefundsCreateRefund(orderId: string, createAdyenPaymentRefundRequest: CreateAdyenPaymentRefundRequest, xVersion?: string, options?: any): AxiosPromise<AdyenPaymentRefundResponse> {
            return localVarFp.adyenPaymentRefundsCreateRefund(orderId, createAdyenPaymentRefundRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdyenPaymentRefundsApi - object-oriented interface
 * @export
 * @class AdyenPaymentRefundsApi
 * @extends {BaseAPI}
 */
export class AdyenPaymentRefundsApi extends BaseAPI {
    /**
     * 
     * @param {string} orderId 
     * @param {CreateAdyenPaymentRefundRequest} createAdyenPaymentRefundRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenPaymentRefundsApi
     */
    public adyenPaymentRefundsCreateRefund(orderId: string, createAdyenPaymentRefundRequest: CreateAdyenPaymentRefundRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return AdyenPaymentRefundsApiFp(this.configuration).adyenPaymentRefundsCreateRefund(orderId, createAdyenPaymentRefundRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdyenPaymentSplitsApi - axios parameter creator
 * @export
 */
export const AdyenPaymentSplitsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} adyenPaymentSplitId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenPaymentSplitsGetPaymentSplitById: async (adyenPaymentSplitId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adyenPaymentSplitId' is not null or undefined
            assertParamExists('adyenPaymentSplitsGetPaymentSplitById', 'adyenPaymentSplitId', adyenPaymentSplitId)
            const localVarPath = `/api/AdyenPaymentSplits/{adyenPaymentSplitId}`
                .replace(`{${"adyenPaymentSplitId"}}`, encodeURIComponent(String(adyenPaymentSplitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenPaymentSplitsGetPaymentSplits: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AdyenPaymentSplits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdyenPaymentSplitsApi - functional programming interface
 * @export
 */
export const AdyenPaymentSplitsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdyenPaymentSplitsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} adyenPaymentSplitId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adyenPaymentSplitsGetPaymentSplitById(adyenPaymentSplitId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdyenPaymentSplitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adyenPaymentSplitsGetPaymentSplitById(adyenPaymentSplitId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adyenPaymentSplitsGetPaymentSplits(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdyenPaymentSplitResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adyenPaymentSplitsGetPaymentSplits(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdyenPaymentSplitsApi - factory interface
 * @export
 */
export const AdyenPaymentSplitsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdyenPaymentSplitsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} adyenPaymentSplitId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenPaymentSplitsGetPaymentSplitById(adyenPaymentSplitId: string, xVersion?: string, options?: any): AxiosPromise<AdyenPaymentSplitResponse> {
            return localVarFp.adyenPaymentSplitsGetPaymentSplitById(adyenPaymentSplitId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenPaymentSplitsGetPaymentSplits(xVersion?: string, options?: any): AxiosPromise<Array<AdyenPaymentSplitResponse>> {
            return localVarFp.adyenPaymentSplitsGetPaymentSplits(xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdyenPaymentSplitsApi - object-oriented interface
 * @export
 * @class AdyenPaymentSplitsApi
 * @extends {BaseAPI}
 */
export class AdyenPaymentSplitsApi extends BaseAPI {
    /**
     * 
     * @param {string} adyenPaymentSplitId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenPaymentSplitsApi
     */
    public adyenPaymentSplitsGetPaymentSplitById(adyenPaymentSplitId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return AdyenPaymentSplitsApiFp(this.configuration).adyenPaymentSplitsGetPaymentSplitById(adyenPaymentSplitId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenPaymentSplitsApi
     */
    public adyenPaymentSplitsGetPaymentSplits(xVersion?: string, options?: AxiosRequestConfig) {
        return AdyenPaymentSplitsApiFp(this.configuration).adyenPaymentSplitsGetPaymentSplits(xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdyenTransferInstrumentsApi - axios parameter creator
 * @export
 */
export const AdyenTransferInstrumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAdyenTransferInstrumentRequest} createAdyenTransferInstrumentRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenTransferInstrumentsCreateTransferInstrument: async (createAdyenTransferInstrumentRequest: CreateAdyenTransferInstrumentRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAdyenTransferInstrumentRequest' is not null or undefined
            assertParamExists('adyenTransferInstrumentsCreateTransferInstrument', 'createAdyenTransferInstrumentRequest', createAdyenTransferInstrumentRequest)
            const localVarPath = `/api/AdyenTransferInstruments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAdyenTransferInstrumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} transferInstrumentId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenTransferInstrumentsDeleteTransferInstrumentById: async (transferInstrumentId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transferInstrumentId' is not null or undefined
            assertParamExists('adyenTransferInstrumentsDeleteTransferInstrumentById', 'transferInstrumentId', transferInstrumentId)
            const localVarPath = `/api/AdyenTransferInstruments/{transferInstrumentId}`
                .replace(`{${"transferInstrumentId"}}`, encodeURIComponent(String(transferInstrumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} transferInstrumentId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenTransferInstrumentsGetTransferInstrumentById: async (transferInstrumentId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transferInstrumentId' is not null or undefined
            assertParamExists('adyenTransferInstrumentsGetTransferInstrumentById', 'transferInstrumentId', transferInstrumentId)
            const localVarPath = `/api/AdyenTransferInstruments/{transferInstrumentId}`
                .replace(`{${"transferInstrumentId"}}`, encodeURIComponent(String(transferInstrumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} transferInstrumentId 
         * @param {UpdateAdyenTransferInstrumentRequest} updateAdyenTransferInstrumentRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenTransferInstrumentsUpdateTransferInstrumentById: async (transferInstrumentId: string, updateAdyenTransferInstrumentRequest: UpdateAdyenTransferInstrumentRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transferInstrumentId' is not null or undefined
            assertParamExists('adyenTransferInstrumentsUpdateTransferInstrumentById', 'transferInstrumentId', transferInstrumentId)
            // verify required parameter 'updateAdyenTransferInstrumentRequest' is not null or undefined
            assertParamExists('adyenTransferInstrumentsUpdateTransferInstrumentById', 'updateAdyenTransferInstrumentRequest', updateAdyenTransferInstrumentRequest)
            const localVarPath = `/api/AdyenTransferInstruments/{transferInstrumentId}`
                .replace(`{${"transferInstrumentId"}}`, encodeURIComponent(String(transferInstrumentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAdyenTransferInstrumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdyenTransferInstrumentsApi - functional programming interface
 * @export
 */
export const AdyenTransferInstrumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdyenTransferInstrumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAdyenTransferInstrumentRequest} createAdyenTransferInstrumentRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adyenTransferInstrumentsCreateTransferInstrument(createAdyenTransferInstrumentRequest: CreateAdyenTransferInstrumentRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdyenTransferInstrumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adyenTransferInstrumentsCreateTransferInstrument(createAdyenTransferInstrumentRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} transferInstrumentId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adyenTransferInstrumentsDeleteTransferInstrumentById(transferInstrumentId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adyenTransferInstrumentsDeleteTransferInstrumentById(transferInstrumentId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} transferInstrumentId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adyenTransferInstrumentsGetTransferInstrumentById(transferInstrumentId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdyenTransferInstrumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adyenTransferInstrumentsGetTransferInstrumentById(transferInstrumentId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} transferInstrumentId 
         * @param {UpdateAdyenTransferInstrumentRequest} updateAdyenTransferInstrumentRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adyenTransferInstrumentsUpdateTransferInstrumentById(transferInstrumentId: string, updateAdyenTransferInstrumentRequest: UpdateAdyenTransferInstrumentRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adyenTransferInstrumentsUpdateTransferInstrumentById(transferInstrumentId, updateAdyenTransferInstrumentRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdyenTransferInstrumentsApi - factory interface
 * @export
 */
export const AdyenTransferInstrumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdyenTransferInstrumentsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateAdyenTransferInstrumentRequest} createAdyenTransferInstrumentRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenTransferInstrumentsCreateTransferInstrument(createAdyenTransferInstrumentRequest: CreateAdyenTransferInstrumentRequest, xVersion?: string, options?: any): AxiosPromise<AdyenTransferInstrumentResponse> {
            return localVarFp.adyenTransferInstrumentsCreateTransferInstrument(createAdyenTransferInstrumentRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} transferInstrumentId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenTransferInstrumentsDeleteTransferInstrumentById(transferInstrumentId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.adyenTransferInstrumentsDeleteTransferInstrumentById(transferInstrumentId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} transferInstrumentId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenTransferInstrumentsGetTransferInstrumentById(transferInstrumentId: string, xVersion?: string, options?: any): AxiosPromise<AdyenTransferInstrumentResponse> {
            return localVarFp.adyenTransferInstrumentsGetTransferInstrumentById(transferInstrumentId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} transferInstrumentId 
         * @param {UpdateAdyenTransferInstrumentRequest} updateAdyenTransferInstrumentRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenTransferInstrumentsUpdateTransferInstrumentById(transferInstrumentId: string, updateAdyenTransferInstrumentRequest: UpdateAdyenTransferInstrumentRequest, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.adyenTransferInstrumentsUpdateTransferInstrumentById(transferInstrumentId, updateAdyenTransferInstrumentRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdyenTransferInstrumentsApi - object-oriented interface
 * @export
 * @class AdyenTransferInstrumentsApi
 * @extends {BaseAPI}
 */
export class AdyenTransferInstrumentsApi extends BaseAPI {
    /**
     * 
     * @param {CreateAdyenTransferInstrumentRequest} createAdyenTransferInstrumentRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenTransferInstrumentsApi
     */
    public adyenTransferInstrumentsCreateTransferInstrument(createAdyenTransferInstrumentRequest: CreateAdyenTransferInstrumentRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return AdyenTransferInstrumentsApiFp(this.configuration).adyenTransferInstrumentsCreateTransferInstrument(createAdyenTransferInstrumentRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} transferInstrumentId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenTransferInstrumentsApi
     */
    public adyenTransferInstrumentsDeleteTransferInstrumentById(transferInstrumentId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return AdyenTransferInstrumentsApiFp(this.configuration).adyenTransferInstrumentsDeleteTransferInstrumentById(transferInstrumentId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} transferInstrumentId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenTransferInstrumentsApi
     */
    public adyenTransferInstrumentsGetTransferInstrumentById(transferInstrumentId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return AdyenTransferInstrumentsApiFp(this.configuration).adyenTransferInstrumentsGetTransferInstrumentById(transferInstrumentId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} transferInstrumentId 
     * @param {UpdateAdyenTransferInstrumentRequest} updateAdyenTransferInstrumentRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenTransferInstrumentsApi
     */
    public adyenTransferInstrumentsUpdateTransferInstrumentById(transferInstrumentId: string, updateAdyenTransferInstrumentRequest: UpdateAdyenTransferInstrumentRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return AdyenTransferInstrumentsApiFp(this.configuration).adyenTransferInstrumentsUpdateTransferInstrumentById(transferInstrumentId, updateAdyenTransferInstrumentRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnimalSpeciesApi - axios parameter creator
 * @export
 */
export const AnimalSpeciesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAnimalSpeciesRequest} createAnimalSpeciesRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalSpeciesCreateAnimalSpecies: async (createAnimalSpeciesRequest: CreateAnimalSpeciesRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAnimalSpeciesRequest' is not null or undefined
            assertParamExists('animalSpeciesCreateAnimalSpecies', 'createAnimalSpeciesRequest', createAnimalSpeciesRequest)
            const localVarPath = `/api/AnimalSpecies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAnimalSpeciesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} animalSpeciesId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalSpeciesDeleteAnimalSpecies: async (animalSpeciesId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'animalSpeciesId' is not null or undefined
            assertParamExists('animalSpeciesDeleteAnimalSpecies', 'animalSpeciesId', animalSpeciesId)
            const localVarPath = `/api/AnimalSpecies/{animalSpeciesId}`
                .replace(`{${"animalSpeciesId"}}`, encodeURIComponent(String(animalSpeciesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalSpeciesGetAnimalSpecies: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AnimalSpecies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} animalSpeciesId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalSpeciesGetAnimalSpeciesById: async (animalSpeciesId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'animalSpeciesId' is not null or undefined
            assertParamExists('animalSpeciesGetAnimalSpeciesById', 'animalSpeciesId', animalSpeciesId)
            const localVarPath = `/api/AnimalSpecies/{animalSpeciesId}`
                .replace(`{${"animalSpeciesId"}}`, encodeURIComponent(String(animalSpeciesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} animalSpeciesId 
         * @param {UpdateAnimalSpeciesRequest} updateAnimalSpeciesRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalSpeciesUpdateAnimalSpecies: async (animalSpeciesId: string, updateAnimalSpeciesRequest: UpdateAnimalSpeciesRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'animalSpeciesId' is not null or undefined
            assertParamExists('animalSpeciesUpdateAnimalSpecies', 'animalSpeciesId', animalSpeciesId)
            // verify required parameter 'updateAnimalSpeciesRequest' is not null or undefined
            assertParamExists('animalSpeciesUpdateAnimalSpecies', 'updateAnimalSpeciesRequest', updateAnimalSpeciesRequest)
            const localVarPath = `/api/AnimalSpecies/{animalSpeciesId}`
                .replace(`{${"animalSpeciesId"}}`, encodeURIComponent(String(animalSpeciesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAnimalSpeciesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnimalSpeciesApi - functional programming interface
 * @export
 */
export const AnimalSpeciesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnimalSpeciesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAnimalSpeciesRequest} createAnimalSpeciesRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalSpeciesCreateAnimalSpecies(createAnimalSpeciesRequest: CreateAnimalSpeciesRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnimalSpeciesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalSpeciesCreateAnimalSpecies(createAnimalSpeciesRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} animalSpeciesId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalSpeciesDeleteAnimalSpecies(animalSpeciesId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalSpeciesDeleteAnimalSpecies(animalSpeciesId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalSpeciesGetAnimalSpecies(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnimalSpeciesResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalSpeciesGetAnimalSpecies(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} animalSpeciesId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalSpeciesGetAnimalSpeciesById(animalSpeciesId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnimalSpeciesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalSpeciesGetAnimalSpeciesById(animalSpeciesId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} animalSpeciesId 
         * @param {UpdateAnimalSpeciesRequest} updateAnimalSpeciesRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalSpeciesUpdateAnimalSpecies(animalSpeciesId: string, updateAnimalSpeciesRequest: UpdateAnimalSpeciesRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalSpeciesUpdateAnimalSpecies(animalSpeciesId, updateAnimalSpeciesRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnimalSpeciesApi - factory interface
 * @export
 */
export const AnimalSpeciesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnimalSpeciesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateAnimalSpeciesRequest} createAnimalSpeciesRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalSpeciesCreateAnimalSpecies(createAnimalSpeciesRequest: CreateAnimalSpeciesRequest, xVersion?: string, options?: any): AxiosPromise<AnimalSpeciesResponse> {
            return localVarFp.animalSpeciesCreateAnimalSpecies(createAnimalSpeciesRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} animalSpeciesId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalSpeciesDeleteAnimalSpecies(animalSpeciesId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.animalSpeciesDeleteAnimalSpecies(animalSpeciesId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalSpeciesGetAnimalSpecies(xVersion?: string, options?: any): AxiosPromise<Array<AnimalSpeciesResponse>> {
            return localVarFp.animalSpeciesGetAnimalSpecies(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} animalSpeciesId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalSpeciesGetAnimalSpeciesById(animalSpeciesId: string, xVersion?: string, options?: any): AxiosPromise<AnimalSpeciesResponse> {
            return localVarFp.animalSpeciesGetAnimalSpeciesById(animalSpeciesId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} animalSpeciesId 
         * @param {UpdateAnimalSpeciesRequest} updateAnimalSpeciesRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalSpeciesUpdateAnimalSpecies(animalSpeciesId: string, updateAnimalSpeciesRequest: UpdateAnimalSpeciesRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.animalSpeciesUpdateAnimalSpecies(animalSpeciesId, updateAnimalSpeciesRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnimalSpeciesApi - object-oriented interface
 * @export
 * @class AnimalSpeciesApi
 * @extends {BaseAPI}
 */
export class AnimalSpeciesApi extends BaseAPI {
    /**
     * 
     * @param {CreateAnimalSpeciesRequest} createAnimalSpeciesRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalSpeciesApi
     */
    public animalSpeciesCreateAnimalSpecies(createAnimalSpeciesRequest: CreateAnimalSpeciesRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return AnimalSpeciesApiFp(this.configuration).animalSpeciesCreateAnimalSpecies(createAnimalSpeciesRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} animalSpeciesId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalSpeciesApi
     */
    public animalSpeciesDeleteAnimalSpecies(animalSpeciesId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return AnimalSpeciesApiFp(this.configuration).animalSpeciesDeleteAnimalSpecies(animalSpeciesId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalSpeciesApi
     */
    public animalSpeciesGetAnimalSpecies(xVersion?: string, options?: AxiosRequestConfig) {
        return AnimalSpeciesApiFp(this.configuration).animalSpeciesGetAnimalSpecies(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} animalSpeciesId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalSpeciesApi
     */
    public animalSpeciesGetAnimalSpeciesById(animalSpeciesId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return AnimalSpeciesApiFp(this.configuration).animalSpeciesGetAnimalSpeciesById(animalSpeciesId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} animalSpeciesId 
     * @param {UpdateAnimalSpeciesRequest} updateAnimalSpeciesRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalSpeciesApi
     */
    public animalSpeciesUpdateAnimalSpecies(animalSpeciesId: string, updateAnimalSpeciesRequest: UpdateAnimalSpeciesRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return AnimalSpeciesApiFp(this.configuration).animalSpeciesUpdateAnimalSpecies(animalSpeciesId, updateAnimalSpeciesRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AssetFoldersApi - axios parameter creator
 * @export
 */
export const AssetFoldersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAssetFolderRequest} createAssetFolderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetFoldersCreateAssetFolder: async (createAssetFolderRequest: CreateAssetFolderRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAssetFolderRequest' is not null or undefined
            assertParamExists('assetFoldersCreateAssetFolder', 'createAssetFolderRequest', createAssetFolderRequest)
            const localVarPath = `/api/AssetFolders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAssetFolderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} assetFolderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetFoldersDeleteAssetFolder: async (assetFolderId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetFolderId' is not null or undefined
            assertParamExists('assetFoldersDeleteAssetFolder', 'assetFolderId', assetFolderId)
            const localVarPath = `/api/AssetFolders/{assetFolderId}`
                .replace(`{${"assetFolderId"}}`, encodeURIComponent(String(assetFolderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} assetFolderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetFoldersGetAssetFolderById: async (assetFolderId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetFolderId' is not null or undefined
            assertParamExists('assetFoldersGetAssetFolderById', 'assetFolderId', assetFolderId)
            const localVarPath = `/api/AssetFolders/{assetFolderId}`
                .replace(`{${"assetFolderId"}}`, encodeURIComponent(String(assetFolderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetFoldersGetAssetFolders: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AssetFolders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} assetFolderId 
         * @param {UpdateAssetFolderRequest} updateAssetFolderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetFoldersUpdateAssetFolder: async (assetFolderId: string, updateAssetFolderRequest: UpdateAssetFolderRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetFolderId' is not null or undefined
            assertParamExists('assetFoldersUpdateAssetFolder', 'assetFolderId', assetFolderId)
            // verify required parameter 'updateAssetFolderRequest' is not null or undefined
            assertParamExists('assetFoldersUpdateAssetFolder', 'updateAssetFolderRequest', updateAssetFolderRequest)
            const localVarPath = `/api/AssetFolders/{assetFolderId}`
                .replace(`{${"assetFolderId"}}`, encodeURIComponent(String(assetFolderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAssetFolderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetFoldersApi - functional programming interface
 * @export
 */
export const AssetFoldersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetFoldersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAssetFolderRequest} createAssetFolderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetFoldersCreateAssetFolder(createAssetFolderRequest: CreateAssetFolderRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetFolderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetFoldersCreateAssetFolder(createAssetFolderRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} assetFolderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetFoldersDeleteAssetFolder(assetFolderId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetFoldersDeleteAssetFolder(assetFolderId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} assetFolderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetFoldersGetAssetFolderById(assetFolderId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetFolderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetFoldersGetAssetFolderById(assetFolderId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetFoldersGetAssetFolders(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssetFolderResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetFoldersGetAssetFolders(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} assetFolderId 
         * @param {UpdateAssetFolderRequest} updateAssetFolderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetFoldersUpdateAssetFolder(assetFolderId: string, updateAssetFolderRequest: UpdateAssetFolderRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetFoldersUpdateAssetFolder(assetFolderId, updateAssetFolderRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetFoldersApi - factory interface
 * @export
 */
export const AssetFoldersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetFoldersApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateAssetFolderRequest} createAssetFolderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetFoldersCreateAssetFolder(createAssetFolderRequest: CreateAssetFolderRequest, xVersion?: string, options?: any): AxiosPromise<AssetFolderResponse> {
            return localVarFp.assetFoldersCreateAssetFolder(createAssetFolderRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} assetFolderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetFoldersDeleteAssetFolder(assetFolderId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.assetFoldersDeleteAssetFolder(assetFolderId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} assetFolderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetFoldersGetAssetFolderById(assetFolderId: string, xVersion?: string, options?: any): AxiosPromise<AssetFolderResponse> {
            return localVarFp.assetFoldersGetAssetFolderById(assetFolderId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetFoldersGetAssetFolders(xVersion?: string, options?: any): AxiosPromise<Array<AssetFolderResponse>> {
            return localVarFp.assetFoldersGetAssetFolders(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} assetFolderId 
         * @param {UpdateAssetFolderRequest} updateAssetFolderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetFoldersUpdateAssetFolder(assetFolderId: string, updateAssetFolderRequest: UpdateAssetFolderRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.assetFoldersUpdateAssetFolder(assetFolderId, updateAssetFolderRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetFoldersApi - object-oriented interface
 * @export
 * @class AssetFoldersApi
 * @extends {BaseAPI}
 */
export class AssetFoldersApi extends BaseAPI {
    /**
     * 
     * @param {CreateAssetFolderRequest} createAssetFolderRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetFoldersApi
     */
    public assetFoldersCreateAssetFolder(createAssetFolderRequest: CreateAssetFolderRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return AssetFoldersApiFp(this.configuration).assetFoldersCreateAssetFolder(createAssetFolderRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} assetFolderId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetFoldersApi
     */
    public assetFoldersDeleteAssetFolder(assetFolderId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return AssetFoldersApiFp(this.configuration).assetFoldersDeleteAssetFolder(assetFolderId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} assetFolderId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetFoldersApi
     */
    public assetFoldersGetAssetFolderById(assetFolderId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return AssetFoldersApiFp(this.configuration).assetFoldersGetAssetFolderById(assetFolderId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetFoldersApi
     */
    public assetFoldersGetAssetFolders(xVersion?: string, options?: AxiosRequestConfig) {
        return AssetFoldersApiFp(this.configuration).assetFoldersGetAssetFolders(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} assetFolderId 
     * @param {UpdateAssetFolderRequest} updateAssetFolderRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetFoldersApi
     */
    public assetFoldersUpdateAssetFolder(assetFolderId: string, updateAssetFolderRequest: UpdateAssetFolderRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return AssetFoldersApiFp(this.configuration).assetFoldersUpdateAssetFolder(assetFolderId, updateAssetFolderRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AssetsApi - axios parameter creator
 * @export
 */
export const AssetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [xVersion] 
         * @param {any} [file] 
         * @param {string} [assetContext] 
         * @param {string} [assetType] 
         * @param {string} [assetFolderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsCreateAsset: async (xVersion?: string, file?: any, assetContext?: string, assetType?: string, assetFolderId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (assetContext !== undefined) { 
                localVarFormParams.append('AssetContext', assetContext as any);
            }
    
            if (assetType !== undefined) { 
                localVarFormParams.append('AssetType', assetType as any);
            }
    
            if (assetFolderId !== undefined) { 
                localVarFormParams.append('AssetFolderId', assetFolderId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} assetId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsDeleteAsset: async (assetId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('assetsDeleteAsset', 'assetId', assetId)
            const localVarPath = `/api/Assets/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} assetId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsGetAssetById: async (assetId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('assetsGetAssetById', 'assetId', assetId)
            const localVarPath = `/api/Assets/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [assetFolderId] 
         * @param {AssetContext} [assetContext] 
         * @param {AssetType} [assetType] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsGetAssets: async (assetFolderId?: string, assetContext?: AssetContext, assetType?: AssetType, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (assetFolderId !== undefined) {
                localVarQueryParameter['AssetFolderId'] = assetFolderId;
            }

            if (assetContext !== undefined) {
                localVarQueryParameter['AssetContext'] = assetContext;
            }

            if (assetType !== undefined) {
                localVarQueryParameter['AssetType'] = assetType;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} assetId 
         * @param {UpdateAssetRequest} updateAssetRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsUpdateAsset: async (assetId: string, updateAssetRequest: UpdateAssetRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('assetsUpdateAsset', 'assetId', assetId)
            // verify required parameter 'updateAssetRequest' is not null or undefined
            assertParamExists('assetsUpdateAsset', 'updateAssetRequest', updateAssetRequest)
            const localVarPath = `/api/Assets/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAssetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetsApi - functional programming interface
 * @export
 */
export const AssetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [xVersion] 
         * @param {any} [file] 
         * @param {string} [assetContext] 
         * @param {string} [assetType] 
         * @param {string} [assetFolderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsCreateAsset(xVersion?: string, file?: any, assetContext?: string, assetType?: string, assetFolderId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsCreateAsset(xVersion, file, assetContext, assetType, assetFolderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} assetId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsDeleteAsset(assetId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsDeleteAsset(assetId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} assetId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsGetAssetById(assetId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsGetAssetById(assetId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [assetFolderId] 
         * @param {AssetContext} [assetContext] 
         * @param {AssetType} [assetType] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsGetAssets(assetFolderId?: string, assetContext?: AssetContext, assetType?: AssetType, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssetResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsGetAssets(assetFolderId, assetContext, assetType, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} assetId 
         * @param {UpdateAssetRequest} updateAssetRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsUpdateAsset(assetId: string, updateAssetRequest: UpdateAssetRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsUpdateAsset(assetId, updateAssetRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetsApi - factory interface
 * @export
 */
export const AssetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [xVersion] 
         * @param {any} [file] 
         * @param {string} [assetContext] 
         * @param {string} [assetType] 
         * @param {string} [assetFolderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsCreateAsset(xVersion?: string, file?: any, assetContext?: string, assetType?: string, assetFolderId?: string, options?: any): AxiosPromise<AssetResponse> {
            return localVarFp.assetsCreateAsset(xVersion, file, assetContext, assetType, assetFolderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} assetId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsDeleteAsset(assetId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.assetsDeleteAsset(assetId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} assetId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsGetAssetById(assetId: string, xVersion?: string, options?: any): AxiosPromise<AssetResponse> {
            return localVarFp.assetsGetAssetById(assetId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [assetFolderId] 
         * @param {AssetContext} [assetContext] 
         * @param {AssetType} [assetType] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsGetAssets(assetFolderId?: string, assetContext?: AssetContext, assetType?: AssetType, xVersion?: string, options?: any): AxiosPromise<Array<AssetResponse>> {
            return localVarFp.assetsGetAssets(assetFolderId, assetContext, assetType, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} assetId 
         * @param {UpdateAssetRequest} updateAssetRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsUpdateAsset(assetId: string, updateAssetRequest: UpdateAssetRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.assetsUpdateAsset(assetId, updateAssetRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetsApi - object-oriented interface
 * @export
 * @class AssetsApi
 * @extends {BaseAPI}
 */
export class AssetsApi extends BaseAPI {
    /**
     * 
     * @param {string} [xVersion] 
     * @param {any} [file] 
     * @param {string} [assetContext] 
     * @param {string} [assetType] 
     * @param {string} [assetFolderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public assetsCreateAsset(xVersion?: string, file?: any, assetContext?: string, assetType?: string, assetFolderId?: string, options?: AxiosRequestConfig) {
        return AssetsApiFp(this.configuration).assetsCreateAsset(xVersion, file, assetContext, assetType, assetFolderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} assetId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public assetsDeleteAsset(assetId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return AssetsApiFp(this.configuration).assetsDeleteAsset(assetId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} assetId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public assetsGetAssetById(assetId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return AssetsApiFp(this.configuration).assetsGetAssetById(assetId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [assetFolderId] 
     * @param {AssetContext} [assetContext] 
     * @param {AssetType} [assetType] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public assetsGetAssets(assetFolderId?: string, assetContext?: AssetContext, assetType?: AssetType, xVersion?: string, options?: AxiosRequestConfig) {
        return AssetsApiFp(this.configuration).assetsGetAssets(assetFolderId, assetContext, assetType, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} assetId 
     * @param {UpdateAssetRequest} updateAssetRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public assetsUpdateAsset(assetId: string, updateAssetRequest: UpdateAssetRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return AssetsApiFp(this.configuration).assetsUpdateAsset(assetId, updateAssetRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BrandContactAddressesApi - axios parameter creator
 * @export
 */
export const BrandContactAddressesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateBrandContactAddressRequest} createBrandContactAddressRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandContactAddressesCreateBrandContactAddress: async (createBrandContactAddressRequest: CreateBrandContactAddressRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBrandContactAddressRequest' is not null or undefined
            assertParamExists('brandContactAddressesCreateBrandContactAddress', 'createBrandContactAddressRequest', createBrandContactAddressRequest)
            const localVarPath = `/api/BrandContactAddresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBrandContactAddressRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} brandContactAddressId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandContactAddressesDeleteBrandContactAddress: async (brandContactAddressId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandContactAddressId' is not null or undefined
            assertParamExists('brandContactAddressesDeleteBrandContactAddress', 'brandContactAddressId', brandContactAddressId)
            const localVarPath = `/api/BrandContactAddresses/{brandContactAddressId}`
                .replace(`{${"brandContactAddressId"}}`, encodeURIComponent(String(brandContactAddressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} brandContactAddressId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandContactAddressesGetBrandContactAddress: async (brandContactAddressId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandContactAddressId' is not null or undefined
            assertParamExists('brandContactAddressesGetBrandContactAddress', 'brandContactAddressId', brandContactAddressId)
            const localVarPath = `/api/BrandContactAddresses/{brandContactAddressId}`
                .replace(`{${"brandContactAddressId"}}`, encodeURIComponent(String(brandContactAddressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [brandId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandContactAddressesGetBrandContactAddresses: async (brandId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BrandContactAddresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (brandId !== undefined) {
                localVarQueryParameter['brandId'] = brandId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} brandContactAddressId 
         * @param {UpdateBrandContactAddressRequest} updateBrandContactAddressRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandContactAddressesUpdateBrandContactAddress: async (brandContactAddressId: string, updateBrandContactAddressRequest: UpdateBrandContactAddressRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandContactAddressId' is not null or undefined
            assertParamExists('brandContactAddressesUpdateBrandContactAddress', 'brandContactAddressId', brandContactAddressId)
            // verify required parameter 'updateBrandContactAddressRequest' is not null or undefined
            assertParamExists('brandContactAddressesUpdateBrandContactAddress', 'updateBrandContactAddressRequest', updateBrandContactAddressRequest)
            const localVarPath = `/api/BrandContactAddresses/{brandContactAddressId}`
                .replace(`{${"brandContactAddressId"}}`, encodeURIComponent(String(brandContactAddressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBrandContactAddressRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrandContactAddressesApi - functional programming interface
 * @export
 */
export const BrandContactAddressesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrandContactAddressesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateBrandContactAddressRequest} createBrandContactAddressRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandContactAddressesCreateBrandContactAddress(createBrandContactAddressRequest: CreateBrandContactAddressRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandContactAddressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandContactAddressesCreateBrandContactAddress(createBrandContactAddressRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} brandContactAddressId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandContactAddressesDeleteBrandContactAddress(brandContactAddressId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandContactAddressesDeleteBrandContactAddress(brandContactAddressId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} brandContactAddressId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandContactAddressesGetBrandContactAddress(brandContactAddressId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandContactAddressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandContactAddressesGetBrandContactAddress(brandContactAddressId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [brandId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandContactAddressesGetBrandContactAddresses(brandId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BrandContactAddressResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandContactAddressesGetBrandContactAddresses(brandId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} brandContactAddressId 
         * @param {UpdateBrandContactAddressRequest} updateBrandContactAddressRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandContactAddressesUpdateBrandContactAddress(brandContactAddressId: string, updateBrandContactAddressRequest: UpdateBrandContactAddressRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandContactAddressesUpdateBrandContactAddress(brandContactAddressId, updateBrandContactAddressRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BrandContactAddressesApi - factory interface
 * @export
 */
export const BrandContactAddressesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrandContactAddressesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateBrandContactAddressRequest} createBrandContactAddressRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandContactAddressesCreateBrandContactAddress(createBrandContactAddressRequest: CreateBrandContactAddressRequest, xVersion?: string, options?: any): AxiosPromise<BrandContactAddressResponse> {
            return localVarFp.brandContactAddressesCreateBrandContactAddress(createBrandContactAddressRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} brandContactAddressId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandContactAddressesDeleteBrandContactAddress(brandContactAddressId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.brandContactAddressesDeleteBrandContactAddress(brandContactAddressId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} brandContactAddressId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandContactAddressesGetBrandContactAddress(brandContactAddressId: string, xVersion?: string, options?: any): AxiosPromise<BrandContactAddressResponse> {
            return localVarFp.brandContactAddressesGetBrandContactAddress(brandContactAddressId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [brandId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandContactAddressesGetBrandContactAddresses(brandId?: string, xVersion?: string, options?: any): AxiosPromise<Array<BrandContactAddressResponse>> {
            return localVarFp.brandContactAddressesGetBrandContactAddresses(brandId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} brandContactAddressId 
         * @param {UpdateBrandContactAddressRequest} updateBrandContactAddressRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandContactAddressesUpdateBrandContactAddress(brandContactAddressId: string, updateBrandContactAddressRequest: UpdateBrandContactAddressRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.brandContactAddressesUpdateBrandContactAddress(brandContactAddressId, updateBrandContactAddressRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BrandContactAddressesApi - object-oriented interface
 * @export
 * @class BrandContactAddressesApi
 * @extends {BaseAPI}
 */
export class BrandContactAddressesApi extends BaseAPI {
    /**
     * 
     * @param {CreateBrandContactAddressRequest} createBrandContactAddressRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandContactAddressesApi
     */
    public brandContactAddressesCreateBrandContactAddress(createBrandContactAddressRequest: CreateBrandContactAddressRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return BrandContactAddressesApiFp(this.configuration).brandContactAddressesCreateBrandContactAddress(createBrandContactAddressRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} brandContactAddressId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandContactAddressesApi
     */
    public brandContactAddressesDeleteBrandContactAddress(brandContactAddressId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return BrandContactAddressesApiFp(this.configuration).brandContactAddressesDeleteBrandContactAddress(brandContactAddressId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} brandContactAddressId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandContactAddressesApi
     */
    public brandContactAddressesGetBrandContactAddress(brandContactAddressId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return BrandContactAddressesApiFp(this.configuration).brandContactAddressesGetBrandContactAddress(brandContactAddressId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [brandId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandContactAddressesApi
     */
    public brandContactAddressesGetBrandContactAddresses(brandId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return BrandContactAddressesApiFp(this.configuration).brandContactAddressesGetBrandContactAddresses(brandId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} brandContactAddressId 
     * @param {UpdateBrandContactAddressRequest} updateBrandContactAddressRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandContactAddressesApi
     */
    public brandContactAddressesUpdateBrandContactAddress(brandContactAddressId: string, updateBrandContactAddressRequest: UpdateBrandContactAddressRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return BrandContactAddressesApiFp(this.configuration).brandContactAddressesUpdateBrandContactAddress(brandContactAddressId, updateBrandContactAddressRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BrandStoresApi - axios parameter creator
 * @export
 */
export const BrandStoresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateBrandStoreRequest} createBrandStoreRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandStoresCreateBrandStore: async (createBrandStoreRequest: CreateBrandStoreRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBrandStoreRequest' is not null or undefined
            assertParamExists('brandStoresCreateBrandStore', 'createBrandStoreRequest', createBrandStoreRequest)
            const localVarPath = `/api/BrandStores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBrandStoreRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} brandStoreId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandStoresDeleteBrandStore: async (brandStoreId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandStoreId' is not null or undefined
            assertParamExists('brandStoresDeleteBrandStore', 'brandStoreId', brandStoreId)
            const localVarPath = `/api/BrandStores/{brandStoreId}`
                .replace(`{${"brandStoreId"}}`, encodeURIComponent(String(brandStoreId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} brandStoreId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandStoresGetBrandStoreById: async (brandStoreId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandStoreId' is not null or undefined
            assertParamExists('brandStoresGetBrandStoreById', 'brandStoreId', brandStoreId)
            const localVarPath = `/api/BrandStores/{brandStoreId}`
                .replace(`{${"brandStoreId"}}`, encodeURIComponent(String(brandStoreId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandStoresGetBrandStores: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BrandStores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} brandStoreId 
         * @param {UpdateBrandStoreRequest} updateBrandStoreRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandStoresUpdateBrandStore: async (brandStoreId: string, updateBrandStoreRequest: UpdateBrandStoreRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandStoreId' is not null or undefined
            assertParamExists('brandStoresUpdateBrandStore', 'brandStoreId', brandStoreId)
            // verify required parameter 'updateBrandStoreRequest' is not null or undefined
            assertParamExists('brandStoresUpdateBrandStore', 'updateBrandStoreRequest', updateBrandStoreRequest)
            const localVarPath = `/api/BrandStores/{brandStoreId}`
                .replace(`{${"brandStoreId"}}`, encodeURIComponent(String(brandStoreId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBrandStoreRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrandStoresApi - functional programming interface
 * @export
 */
export const BrandStoresApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrandStoresApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateBrandStoreRequest} createBrandStoreRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandStoresCreateBrandStore(createBrandStoreRequest: CreateBrandStoreRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandStoreResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandStoresCreateBrandStore(createBrandStoreRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} brandStoreId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandStoresDeleteBrandStore(brandStoreId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandStoresDeleteBrandStore(brandStoreId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} brandStoreId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandStoresGetBrandStoreById(brandStoreId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandStoreResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandStoresGetBrandStoreById(brandStoreId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandStoresGetBrandStores(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BrandStoreResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandStoresGetBrandStores(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} brandStoreId 
         * @param {UpdateBrandStoreRequest} updateBrandStoreRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandStoresUpdateBrandStore(brandStoreId: string, updateBrandStoreRequest: UpdateBrandStoreRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandStoresUpdateBrandStore(brandStoreId, updateBrandStoreRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BrandStoresApi - factory interface
 * @export
 */
export const BrandStoresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrandStoresApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateBrandStoreRequest} createBrandStoreRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandStoresCreateBrandStore(createBrandStoreRequest: CreateBrandStoreRequest, xVersion?: string, options?: any): AxiosPromise<BrandStoreResponse> {
            return localVarFp.brandStoresCreateBrandStore(createBrandStoreRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} brandStoreId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandStoresDeleteBrandStore(brandStoreId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.brandStoresDeleteBrandStore(brandStoreId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} brandStoreId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandStoresGetBrandStoreById(brandStoreId: string, xVersion?: string, options?: any): AxiosPromise<BrandStoreResponse> {
            return localVarFp.brandStoresGetBrandStoreById(brandStoreId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandStoresGetBrandStores(xVersion?: string, options?: any): AxiosPromise<Array<BrandStoreResponse>> {
            return localVarFp.brandStoresGetBrandStores(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} brandStoreId 
         * @param {UpdateBrandStoreRequest} updateBrandStoreRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandStoresUpdateBrandStore(brandStoreId: string, updateBrandStoreRequest: UpdateBrandStoreRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.brandStoresUpdateBrandStore(brandStoreId, updateBrandStoreRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BrandStoresApi - object-oriented interface
 * @export
 * @class BrandStoresApi
 * @extends {BaseAPI}
 */
export class BrandStoresApi extends BaseAPI {
    /**
     * 
     * @param {CreateBrandStoreRequest} createBrandStoreRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandStoresApi
     */
    public brandStoresCreateBrandStore(createBrandStoreRequest: CreateBrandStoreRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return BrandStoresApiFp(this.configuration).brandStoresCreateBrandStore(createBrandStoreRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} brandStoreId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandStoresApi
     */
    public brandStoresDeleteBrandStore(brandStoreId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return BrandStoresApiFp(this.configuration).brandStoresDeleteBrandStore(brandStoreId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} brandStoreId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandStoresApi
     */
    public brandStoresGetBrandStoreById(brandStoreId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return BrandStoresApiFp(this.configuration).brandStoresGetBrandStoreById(brandStoreId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandStoresApi
     */
    public brandStoresGetBrandStores(xVersion?: string, options?: AxiosRequestConfig) {
        return BrandStoresApiFp(this.configuration).brandStoresGetBrandStores(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} brandStoreId 
     * @param {UpdateBrandStoreRequest} updateBrandStoreRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandStoresApi
     */
    public brandStoresUpdateBrandStore(brandStoreId: string, updateBrandStoreRequest: UpdateBrandStoreRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return BrandStoresApiFp(this.configuration).brandStoresUpdateBrandStore(brandStoreId, updateBrandStoreRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BrandsApi - axios parameter creator
 * @export
 */
export const BrandsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateBrandRequest} createBrandRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandsCreateBrand: async (createBrandRequest: CreateBrandRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBrandRequest' is not null or undefined
            assertParamExists('brandsCreateBrand', 'createBrandRequest', createBrandRequest)
            const localVarPath = `/api/Brands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBrandRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} brandId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandsDeleteBrand: async (brandId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('brandsDeleteBrand', 'brandId', brandId)
            const localVarPath = `/api/Brands/{brandId}`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} brandId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandsGetBrandById: async (brandId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('brandsGetBrandById', 'brandId', brandId)
            const localVarPath = `/api/Brands/{brandId}`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandsGetBrands: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Brands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} brandId 
         * @param {UpdateBrandRequest} updateBrandRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandsUpdateBrand: async (brandId: string, updateBrandRequest: UpdateBrandRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('brandsUpdateBrand', 'brandId', brandId)
            // verify required parameter 'updateBrandRequest' is not null or undefined
            assertParamExists('brandsUpdateBrand', 'updateBrandRequest', updateBrandRequest)
            const localVarPath = `/api/Brands/{brandId}`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBrandRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrandsApi - functional programming interface
 * @export
 */
export const BrandsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrandsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateBrandRequest} createBrandRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandsCreateBrand(createBrandRequest: CreateBrandRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandsCreateBrand(createBrandRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} brandId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandsDeleteBrand(brandId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandsDeleteBrand(brandId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} brandId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandsGetBrandById(brandId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandsGetBrandById(brandId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandsGetBrands(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BrandResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandsGetBrands(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} brandId 
         * @param {UpdateBrandRequest} updateBrandRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandsUpdateBrand(brandId: string, updateBrandRequest: UpdateBrandRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandsUpdateBrand(brandId, updateBrandRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BrandsApi - factory interface
 * @export
 */
export const BrandsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrandsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateBrandRequest} createBrandRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandsCreateBrand(createBrandRequest: CreateBrandRequest, xVersion?: string, options?: any): AxiosPromise<BrandResponse> {
            return localVarFp.brandsCreateBrand(createBrandRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} brandId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandsDeleteBrand(brandId: string, xVersion?: string, options?: any): AxiosPromise<BrandResponse> {
            return localVarFp.brandsDeleteBrand(brandId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} brandId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandsGetBrandById(brandId: string, xVersion?: string, options?: any): AxiosPromise<BrandResponse> {
            return localVarFp.brandsGetBrandById(brandId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandsGetBrands(xVersion?: string, options?: any): AxiosPromise<Array<BrandResponse>> {
            return localVarFp.brandsGetBrands(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} brandId 
         * @param {UpdateBrandRequest} updateBrandRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandsUpdateBrand(brandId: string, updateBrandRequest: UpdateBrandRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.brandsUpdateBrand(brandId, updateBrandRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BrandsApi - object-oriented interface
 * @export
 * @class BrandsApi
 * @extends {BaseAPI}
 */
export class BrandsApi extends BaseAPI {
    /**
     * 
     * @param {CreateBrandRequest} createBrandRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApi
     */
    public brandsCreateBrand(createBrandRequest: CreateBrandRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return BrandsApiFp(this.configuration).brandsCreateBrand(createBrandRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} brandId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApi
     */
    public brandsDeleteBrand(brandId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return BrandsApiFp(this.configuration).brandsDeleteBrand(brandId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} brandId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApi
     */
    public brandsGetBrandById(brandId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return BrandsApiFp(this.configuration).brandsGetBrandById(brandId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApi
     */
    public brandsGetBrands(xVersion?: string, options?: AxiosRequestConfig) {
        return BrandsApiFp(this.configuration).brandsGetBrands(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} brandId 
     * @param {UpdateBrandRequest} updateBrandRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApi
     */
    public brandsUpdateBrand(brandId: string, updateBrandRequest: UpdateBrandRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return BrandsApiFp(this.configuration).brandsUpdateBrand(brandId, updateBrandRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BulkEditorHistoryApi - axios parameter creator
 * @export
 */
export const BulkEditorHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {CreateBulkEditorHistoryRequest} createBulkEditorHistoryRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditorHistoryCreateBulkEditorHistory: async (id: string, createBulkEditorHistoryRequest: CreateBulkEditorHistoryRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bulkEditorHistoryCreateBulkEditorHistory', 'id', id)
            // verify required parameter 'createBulkEditorHistoryRequest' is not null or undefined
            assertParamExists('bulkEditorHistoryCreateBulkEditorHistory', 'createBulkEditorHistoryRequest', createBulkEditorHistoryRequest)
            const localVarPath = `/api/BulkEditorHistory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBulkEditorHistoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditorHistoryDeleteBulkEditorHistoryById: async (id: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bulkEditorHistoryDeleteBulkEditorHistoryById', 'id', id)
            const localVarPath = `/api/BulkEditorHistory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [bulkEditorSessionId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditorHistoryGetBulkEditorHistories: async (bulkEditorSessionId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BulkEditorHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (bulkEditorSessionId !== undefined) {
                localVarQueryParameter['bulkEditorSessionId'] = bulkEditorSessionId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditorHistoryGetBulkEditorHistoryById: async (id: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bulkEditorHistoryGetBulkEditorHistoryById', 'id', id)
            const localVarPath = `/api/BulkEditorHistory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bulkEditorSessionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditorHistoryGetRecentBulkEditorHistoryBySessionId: async (bulkEditorSessionId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkEditorSessionId' is not null or undefined
            assertParamExists('bulkEditorHistoryGetRecentBulkEditorHistoryBySessionId', 'bulkEditorSessionId', bulkEditorSessionId)
            const localVarPath = `/api/BulkEditorHistory/{bulkEditorSessionId}/recent`
                .replace(`{${"bulkEditorSessionId"}}`, encodeURIComponent(String(bulkEditorSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateBulkEditorHistoryRequest} updateBulkEditorHistoryRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditorHistoryUpdateBulkEditorHistoryById: async (id: string, updateBulkEditorHistoryRequest: UpdateBulkEditorHistoryRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bulkEditorHistoryUpdateBulkEditorHistoryById', 'id', id)
            // verify required parameter 'updateBulkEditorHistoryRequest' is not null or undefined
            assertParamExists('bulkEditorHistoryUpdateBulkEditorHistoryById', 'updateBulkEditorHistoryRequest', updateBulkEditorHistoryRequest)
            const localVarPath = `/api/BulkEditorHistory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBulkEditorHistoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BulkEditorHistoryApi - functional programming interface
 * @export
 */
export const BulkEditorHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BulkEditorHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {CreateBulkEditorHistoryRequest} createBulkEditorHistoryRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkEditorHistoryCreateBulkEditorHistory(id: string, createBulkEditorHistoryRequest: CreateBulkEditorHistoryRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkEditorHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkEditorHistoryCreateBulkEditorHistory(id, createBulkEditorHistoryRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkEditorHistoryDeleteBulkEditorHistoryById(id: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkEditorHistoryDeleteBulkEditorHistoryById(id, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [bulkEditorSessionId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkEditorHistoryGetBulkEditorHistories(bulkEditorSessionId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BulkEditorHistoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkEditorHistoryGetBulkEditorHistories(bulkEditorSessionId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkEditorHistoryGetBulkEditorHistoryById(id: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkEditorHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkEditorHistoryGetBulkEditorHistoryById(id, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} bulkEditorSessionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkEditorHistoryGetRecentBulkEditorHistoryBySessionId(bulkEditorSessionId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkEditorHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkEditorHistoryGetRecentBulkEditorHistoryBySessionId(bulkEditorSessionId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateBulkEditorHistoryRequest} updateBulkEditorHistoryRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkEditorHistoryUpdateBulkEditorHistoryById(id: string, updateBulkEditorHistoryRequest: UpdateBulkEditorHistoryRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkEditorHistoryUpdateBulkEditorHistoryById(id, updateBulkEditorHistoryRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BulkEditorHistoryApi - factory interface
 * @export
 */
export const BulkEditorHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BulkEditorHistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {CreateBulkEditorHistoryRequest} createBulkEditorHistoryRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditorHistoryCreateBulkEditorHistory(id: string, createBulkEditorHistoryRequest: CreateBulkEditorHistoryRequest, xVersion?: string, options?: any): AxiosPromise<BulkEditorHistoryResponse> {
            return localVarFp.bulkEditorHistoryCreateBulkEditorHistory(id, createBulkEditorHistoryRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditorHistoryDeleteBulkEditorHistoryById(id: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.bulkEditorHistoryDeleteBulkEditorHistoryById(id, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [bulkEditorSessionId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditorHistoryGetBulkEditorHistories(bulkEditorSessionId?: string, xVersion?: string, options?: any): AxiosPromise<Array<BulkEditorHistoryResponse>> {
            return localVarFp.bulkEditorHistoryGetBulkEditorHistories(bulkEditorSessionId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditorHistoryGetBulkEditorHistoryById(id: string, xVersion?: string, options?: any): AxiosPromise<BulkEditorHistoryResponse> {
            return localVarFp.bulkEditorHistoryGetBulkEditorHistoryById(id, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} bulkEditorSessionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditorHistoryGetRecentBulkEditorHistoryBySessionId(bulkEditorSessionId: string, xVersion?: string, options?: any): AxiosPromise<BulkEditorHistoryResponse> {
            return localVarFp.bulkEditorHistoryGetRecentBulkEditorHistoryBySessionId(bulkEditorSessionId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateBulkEditorHistoryRequest} updateBulkEditorHistoryRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditorHistoryUpdateBulkEditorHistoryById(id: string, updateBulkEditorHistoryRequest: UpdateBulkEditorHistoryRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.bulkEditorHistoryUpdateBulkEditorHistoryById(id, updateBulkEditorHistoryRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BulkEditorHistoryApi - object-oriented interface
 * @export
 * @class BulkEditorHistoryApi
 * @extends {BaseAPI}
 */
export class BulkEditorHistoryApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {CreateBulkEditorHistoryRequest} createBulkEditorHistoryRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkEditorHistoryApi
     */
    public bulkEditorHistoryCreateBulkEditorHistory(id: string, createBulkEditorHistoryRequest: CreateBulkEditorHistoryRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return BulkEditorHistoryApiFp(this.configuration).bulkEditorHistoryCreateBulkEditorHistory(id, createBulkEditorHistoryRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkEditorHistoryApi
     */
    public bulkEditorHistoryDeleteBulkEditorHistoryById(id: string, xVersion?: string, options?: AxiosRequestConfig) {
        return BulkEditorHistoryApiFp(this.configuration).bulkEditorHistoryDeleteBulkEditorHistoryById(id, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [bulkEditorSessionId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkEditorHistoryApi
     */
    public bulkEditorHistoryGetBulkEditorHistories(bulkEditorSessionId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return BulkEditorHistoryApiFp(this.configuration).bulkEditorHistoryGetBulkEditorHistories(bulkEditorSessionId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkEditorHistoryApi
     */
    public bulkEditorHistoryGetBulkEditorHistoryById(id: string, xVersion?: string, options?: AxiosRequestConfig) {
        return BulkEditorHistoryApiFp(this.configuration).bulkEditorHistoryGetBulkEditorHistoryById(id, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} bulkEditorSessionId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkEditorHistoryApi
     */
    public bulkEditorHistoryGetRecentBulkEditorHistoryBySessionId(bulkEditorSessionId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return BulkEditorHistoryApiFp(this.configuration).bulkEditorHistoryGetRecentBulkEditorHistoryBySessionId(bulkEditorSessionId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateBulkEditorHistoryRequest} updateBulkEditorHistoryRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkEditorHistoryApi
     */
    public bulkEditorHistoryUpdateBulkEditorHistoryById(id: string, updateBulkEditorHistoryRequest: UpdateBulkEditorHistoryRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return BulkEditorHistoryApiFp(this.configuration).bulkEditorHistoryUpdateBulkEditorHistoryById(id, updateBulkEditorHistoryRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BulkEditorSessionApi - axios parameter creator
 * @export
 */
export const BulkEditorSessionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateBulkEditorSessionRequest} createBulkEditorSessionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditorSessionCreateBulkEditorSession: async (createBulkEditorSessionRequest: CreateBulkEditorSessionRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBulkEditorSessionRequest' is not null or undefined
            assertParamExists('bulkEditorSessionCreateBulkEditorSession', 'createBulkEditorSessionRequest', createBulkEditorSessionRequest)
            const localVarPath = `/api/BulkEditorSession`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBulkEditorSessionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditorSessionDeleteBulkEditorSessionById: async (id: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bulkEditorSessionDeleteBulkEditorSessionById', 'id', id)
            const localVarPath = `/api/BulkEditorSession/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditorSessionGetBulkEditorSessionById: async (id: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bulkEditorSessionGetBulkEditorSessionById', 'id', id)
            const localVarPath = `/api/BulkEditorSession/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditorSessionGetBulkEditorSessions: async (manufacturerId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BulkEditorSession`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateBulkEditorSessionRequest} updateBulkEditorSessionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditorSessionUpdateBulkEditorSessionById: async (id: string, updateBulkEditorSessionRequest: UpdateBulkEditorSessionRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bulkEditorSessionUpdateBulkEditorSessionById', 'id', id)
            // verify required parameter 'updateBulkEditorSessionRequest' is not null or undefined
            assertParamExists('bulkEditorSessionUpdateBulkEditorSessionById', 'updateBulkEditorSessionRequest', updateBulkEditorSessionRequest)
            const localVarPath = `/api/BulkEditorSession/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBulkEditorSessionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BulkEditorSessionApi - functional programming interface
 * @export
 */
export const BulkEditorSessionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BulkEditorSessionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateBulkEditorSessionRequest} createBulkEditorSessionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkEditorSessionCreateBulkEditorSession(createBulkEditorSessionRequest: CreateBulkEditorSessionRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkEditorSessionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkEditorSessionCreateBulkEditorSession(createBulkEditorSessionRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkEditorSessionDeleteBulkEditorSessionById(id: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkEditorSessionDeleteBulkEditorSessionById(id, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkEditorSessionGetBulkEditorSessionById(id: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkEditorSessionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkEditorSessionGetBulkEditorSessionById(id, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkEditorSessionGetBulkEditorSessions(manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BulkEditorSessionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkEditorSessionGetBulkEditorSessions(manufacturerId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateBulkEditorSessionRequest} updateBulkEditorSessionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkEditorSessionUpdateBulkEditorSessionById(id: string, updateBulkEditorSessionRequest: UpdateBulkEditorSessionRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkEditorSessionUpdateBulkEditorSessionById(id, updateBulkEditorSessionRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BulkEditorSessionApi - factory interface
 * @export
 */
export const BulkEditorSessionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BulkEditorSessionApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateBulkEditorSessionRequest} createBulkEditorSessionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditorSessionCreateBulkEditorSession(createBulkEditorSessionRequest: CreateBulkEditorSessionRequest, xVersion?: string, options?: any): AxiosPromise<BulkEditorSessionResponse> {
            return localVarFp.bulkEditorSessionCreateBulkEditorSession(createBulkEditorSessionRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditorSessionDeleteBulkEditorSessionById(id: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.bulkEditorSessionDeleteBulkEditorSessionById(id, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditorSessionGetBulkEditorSessionById(id: string, xVersion?: string, options?: any): AxiosPromise<BulkEditorSessionResponse> {
            return localVarFp.bulkEditorSessionGetBulkEditorSessionById(id, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditorSessionGetBulkEditorSessions(manufacturerId?: string, xVersion?: string, options?: any): AxiosPromise<Array<BulkEditorSessionResponse>> {
            return localVarFp.bulkEditorSessionGetBulkEditorSessions(manufacturerId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateBulkEditorSessionRequest} updateBulkEditorSessionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditorSessionUpdateBulkEditorSessionById(id: string, updateBulkEditorSessionRequest: UpdateBulkEditorSessionRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.bulkEditorSessionUpdateBulkEditorSessionById(id, updateBulkEditorSessionRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BulkEditorSessionApi - object-oriented interface
 * @export
 * @class BulkEditorSessionApi
 * @extends {BaseAPI}
 */
export class BulkEditorSessionApi extends BaseAPI {
    /**
     * 
     * @param {CreateBulkEditorSessionRequest} createBulkEditorSessionRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkEditorSessionApi
     */
    public bulkEditorSessionCreateBulkEditorSession(createBulkEditorSessionRequest: CreateBulkEditorSessionRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return BulkEditorSessionApiFp(this.configuration).bulkEditorSessionCreateBulkEditorSession(createBulkEditorSessionRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkEditorSessionApi
     */
    public bulkEditorSessionDeleteBulkEditorSessionById(id: string, xVersion?: string, options?: AxiosRequestConfig) {
        return BulkEditorSessionApiFp(this.configuration).bulkEditorSessionDeleteBulkEditorSessionById(id, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkEditorSessionApi
     */
    public bulkEditorSessionGetBulkEditorSessionById(id: string, xVersion?: string, options?: AxiosRequestConfig) {
        return BulkEditorSessionApiFp(this.configuration).bulkEditorSessionGetBulkEditorSessionById(id, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkEditorSessionApi
     */
    public bulkEditorSessionGetBulkEditorSessions(manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return BulkEditorSessionApiFp(this.configuration).bulkEditorSessionGetBulkEditorSessions(manufacturerId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateBulkEditorSessionRequest} updateBulkEditorSessionRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkEditorSessionApi
     */
    public bulkEditorSessionUpdateBulkEditorSessionById(id: string, updateBulkEditorSessionRequest: UpdateBulkEditorSessionRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return BulkEditorSessionApiFp(this.configuration).bulkEditorSessionUpdateBulkEditorSessionById(id, updateBulkEditorSessionRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CountriesApi - axios parameter creator
 * @export
 */
export const CountriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countriesGetCountries: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} countryId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countriesGetCountryById: async (countryId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryId' is not null or undefined
            assertParamExists('countriesGetCountryById', 'countryId', countryId)
            const localVarPath = `/api/Countries/{countryId}`
                .replace(`{${"countryId"}}`, encodeURIComponent(String(countryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountriesApi - functional programming interface
 * @export
 */
export const CountriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CountriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countriesGetCountries(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CountryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countriesGetCountries(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} countryId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countriesGetCountryById(countryId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countriesGetCountryById(countryId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CountriesApi - factory interface
 * @export
 */
export const CountriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CountriesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countriesGetCountries(xVersion?: string, options?: any): AxiosPromise<Array<CountryResponse>> {
            return localVarFp.countriesGetCountries(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} countryId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countriesGetCountryById(countryId: string, xVersion?: string, options?: any): AxiosPromise<CountryResponse> {
            return localVarFp.countriesGetCountryById(countryId, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CountriesApi - object-oriented interface
 * @export
 * @class CountriesApi
 * @extends {BaseAPI}
 */
export class CountriesApi extends BaseAPI {
    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesApi
     */
    public countriesGetCountries(xVersion?: string, options?: AxiosRequestConfig) {
        return CountriesApiFp(this.configuration).countriesGetCountries(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} countryId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesApi
     */
    public countriesGetCountryById(countryId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return CountriesApiFp(this.configuration).countriesGetCountryById(countryId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CredentialsApi - axios parameter creator
 * @export
 */
export const CredentialsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCredentialRequest} createCredentialRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsCreateCredential: async (createCredentialRequest: CreateCredentialRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCredentialRequest' is not null or undefined
            assertParamExists('credentialsCreateCredential', 'createCredentialRequest', createCredentialRequest)
            const localVarPath = `/api/Credentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCredentialRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} credentialId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsDeleteCredential: async (credentialId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'credentialId' is not null or undefined
            assertParamExists('credentialsDeleteCredential', 'credentialId', credentialId)
            const localVarPath = `/api/Credentials/{credentialId}`
                .replace(`{${"credentialId"}}`, encodeURIComponent(String(credentialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} credentialId 
         * @param {boolean} [decrypt] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsGetCredentialById: async (credentialId: string, decrypt?: boolean, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'credentialId' is not null or undefined
            assertParamExists('credentialsGetCredentialById', 'credentialId', credentialId)
            const localVarPath = `/api/Credentials/{credentialId}`
                .replace(`{${"credentialId"}}`, encodeURIComponent(String(credentialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (decrypt !== undefined) {
                localVarQueryParameter['decrypt'] = decrypt;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [decrypt] 
         * @param {CredentialContext} [credentialContext] 
         * @param {CredentialType} [credentialType] 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsGetCredentials: async (decrypt?: boolean, credentialContext?: CredentialContext, credentialType?: CredentialType, manufacturerId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Credentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (decrypt !== undefined) {
                localVarQueryParameter['Decrypt'] = decrypt;
            }

            if (credentialContext !== undefined) {
                localVarQueryParameter['CredentialContext'] = credentialContext;
            }

            if (credentialType !== undefined) {
                localVarQueryParameter['CredentialType'] = credentialType;
            }

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} credentialId 
         * @param {UpdateCredentialRequest} updateCredentialRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsUpdateCredential: async (credentialId: string, updateCredentialRequest: UpdateCredentialRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'credentialId' is not null or undefined
            assertParamExists('credentialsUpdateCredential', 'credentialId', credentialId)
            // verify required parameter 'updateCredentialRequest' is not null or undefined
            assertParamExists('credentialsUpdateCredential', 'updateCredentialRequest', updateCredentialRequest)
            const localVarPath = `/api/Credentials/{credentialId}`
                .replace(`{${"credentialId"}}`, encodeURIComponent(String(credentialId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCredentialRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CredentialsApi - functional programming interface
 * @export
 */
export const CredentialsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CredentialsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateCredentialRequest} createCredentialRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async credentialsCreateCredential(createCredentialRequest: CreateCredentialRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CredentialResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.credentialsCreateCredential(createCredentialRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} credentialId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async credentialsDeleteCredential(credentialId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.credentialsDeleteCredential(credentialId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} credentialId 
         * @param {boolean} [decrypt] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async credentialsGetCredentialById(credentialId: string, decrypt?: boolean, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CredentialResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.credentialsGetCredentialById(credentialId, decrypt, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [decrypt] 
         * @param {CredentialContext} [credentialContext] 
         * @param {CredentialType} [credentialType] 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async credentialsGetCredentials(decrypt?: boolean, credentialContext?: CredentialContext, credentialType?: CredentialType, manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CredentialResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.credentialsGetCredentials(decrypt, credentialContext, credentialType, manufacturerId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} credentialId 
         * @param {UpdateCredentialRequest} updateCredentialRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async credentialsUpdateCredential(credentialId: string, updateCredentialRequest: UpdateCredentialRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.credentialsUpdateCredential(credentialId, updateCredentialRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CredentialsApi - factory interface
 * @export
 */
export const CredentialsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CredentialsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateCredentialRequest} createCredentialRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsCreateCredential(createCredentialRequest: CreateCredentialRequest, xVersion?: string, options?: any): AxiosPromise<CredentialResponse> {
            return localVarFp.credentialsCreateCredential(createCredentialRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} credentialId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsDeleteCredential(credentialId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.credentialsDeleteCredential(credentialId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} credentialId 
         * @param {boolean} [decrypt] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsGetCredentialById(credentialId: string, decrypt?: boolean, xVersion?: string, options?: any): AxiosPromise<CredentialResponse> {
            return localVarFp.credentialsGetCredentialById(credentialId, decrypt, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [decrypt] 
         * @param {CredentialContext} [credentialContext] 
         * @param {CredentialType} [credentialType] 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsGetCredentials(decrypt?: boolean, credentialContext?: CredentialContext, credentialType?: CredentialType, manufacturerId?: string, xVersion?: string, options?: any): AxiosPromise<Array<CredentialResponse>> {
            return localVarFp.credentialsGetCredentials(decrypt, credentialContext, credentialType, manufacturerId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} credentialId 
         * @param {UpdateCredentialRequest} updateCredentialRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialsUpdateCredential(credentialId: string, updateCredentialRequest: UpdateCredentialRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.credentialsUpdateCredential(credentialId, updateCredentialRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CredentialsApi - object-oriented interface
 * @export
 * @class CredentialsApi
 * @extends {BaseAPI}
 */
export class CredentialsApi extends BaseAPI {
    /**
     * 
     * @param {CreateCredentialRequest} createCredentialRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public credentialsCreateCredential(createCredentialRequest: CreateCredentialRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).credentialsCreateCredential(createCredentialRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} credentialId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public credentialsDeleteCredential(credentialId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).credentialsDeleteCredential(credentialId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} credentialId 
     * @param {boolean} [decrypt] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public credentialsGetCredentialById(credentialId: string, decrypt?: boolean, xVersion?: string, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).credentialsGetCredentialById(credentialId, decrypt, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [decrypt] 
     * @param {CredentialContext} [credentialContext] 
     * @param {CredentialType} [credentialType] 
     * @param {string} [manufacturerId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public credentialsGetCredentials(decrypt?: boolean, credentialContext?: CredentialContext, credentialType?: CredentialType, manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).credentialsGetCredentials(decrypt, credentialContext, credentialType, manufacturerId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} credentialId 
     * @param {UpdateCredentialRequest} updateCredentialRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public credentialsUpdateCredential(credentialId: string, updateCredentialRequest: UpdateCredentialRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).credentialsUpdateCredential(credentialId, updateCredentialRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CurrenciesApi - axios parameter creator
 * @export
 */
export const CurrenciesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [countryId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currenciesGetCurrencies: async (countryId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Currencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (countryId !== undefined) {
                localVarQueryParameter['CountryId'] = countryId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} currencyId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currenciesGetCurrencyById: async (currencyId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'currencyId' is not null or undefined
            assertParamExists('currenciesGetCurrencyById', 'currencyId', currencyId)
            const localVarPath = `/api/Currencies/{currencyId}`
                .replace(`{${"currencyId"}}`, encodeURIComponent(String(currencyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CurrenciesApi - functional programming interface
 * @export
 */
export const CurrenciesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CurrenciesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [countryId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currenciesGetCurrencies(countryId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CurrencyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currenciesGetCurrencies(countryId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} currencyId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async currenciesGetCurrencyById(currencyId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrencyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.currenciesGetCurrencyById(currencyId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CurrenciesApi - factory interface
 * @export
 */
export const CurrenciesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CurrenciesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [countryId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currenciesGetCurrencies(countryId?: string, xVersion?: string, options?: any): AxiosPromise<Array<CurrencyResponse>> {
            return localVarFp.currenciesGetCurrencies(countryId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} currencyId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currenciesGetCurrencyById(currencyId: string, xVersion?: string, options?: any): AxiosPromise<CurrencyResponse> {
            return localVarFp.currenciesGetCurrencyById(currencyId, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CurrenciesApi - object-oriented interface
 * @export
 * @class CurrenciesApi
 * @extends {BaseAPI}
 */
export class CurrenciesApi extends BaseAPI {
    /**
     * 
     * @param {string} [countryId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrenciesApi
     */
    public currenciesGetCurrencies(countryId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return CurrenciesApiFp(this.configuration).currenciesGetCurrencies(countryId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} currencyId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrenciesApi
     */
    public currenciesGetCurrencyById(currencyId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return CurrenciesApiFp(this.configuration).currenciesGetCurrencyById(currencyId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomersApi - axios parameter creator
 * @export
 */
export const CustomersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCustomerRequest} createCustomerRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersCreateCustomer: async (createCustomerRequest: CreateCustomerRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCustomerRequest' is not null or undefined
            assertParamExists('customersCreateCustomer', 'createCustomerRequest', createCustomerRequest)
            const localVarPath = `/api/Customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCustomerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersDeleteCustomer: async (customerId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('customersDeleteCustomer', 'customerId', customerId)
            const localVarPath = `/api/Customers/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersGetCustomerById: async (customerId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('customersGetCustomerById', 'customerId', customerId)
            const localVarPath = `/api/Customers/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersGetCustomers: async (manufacturerId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerId 
         * @param {UpdateCustomerRequest} updateCustomerRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersUpdateCustomer: async (customerId: string, updateCustomerRequest: UpdateCustomerRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('customersUpdateCustomer', 'customerId', customerId)
            // verify required parameter 'updateCustomerRequest' is not null or undefined
            assertParamExists('customersUpdateCustomer', 'updateCustomerRequest', updateCustomerRequest)
            const localVarPath = `/api/Customers/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomersApi - functional programming interface
 * @export
 */
export const CustomersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateCustomerRequest} createCustomerRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersCreateCustomer(createCustomerRequest: CreateCustomerRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersCreateCustomer(createCustomerRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersDeleteCustomer(customerId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersDeleteCustomer(customerId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersGetCustomerById(customerId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersGetCustomerById(customerId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersGetCustomers(manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersGetCustomers(manufacturerId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customerId 
         * @param {UpdateCustomerRequest} updateCustomerRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersUpdateCustomer(customerId: string, updateCustomerRequest: UpdateCustomerRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersUpdateCustomer(customerId, updateCustomerRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomersApi - factory interface
 * @export
 */
export const CustomersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomersApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateCustomerRequest} createCustomerRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersCreateCustomer(createCustomerRequest: CreateCustomerRequest, xVersion?: string, options?: any): AxiosPromise<CustomerResponse> {
            return localVarFp.customersCreateCustomer(createCustomerRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersDeleteCustomer(customerId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.customersDeleteCustomer(customerId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersGetCustomerById(customerId: string, xVersion?: string, options?: any): AxiosPromise<CustomerResponse> {
            return localVarFp.customersGetCustomerById(customerId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersGetCustomers(manufacturerId?: string, xVersion?: string, options?: any): AxiosPromise<Array<CustomerResponse>> {
            return localVarFp.customersGetCustomers(manufacturerId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customerId 
         * @param {UpdateCustomerRequest} updateCustomerRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersUpdateCustomer(customerId: string, updateCustomerRequest: UpdateCustomerRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.customersUpdateCustomer(customerId, updateCustomerRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomersApi - object-oriented interface
 * @export
 * @class CustomersApi
 * @extends {BaseAPI}
 */
export class CustomersApi extends BaseAPI {
    /**
     * 
     * @param {CreateCustomerRequest} createCustomerRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customersCreateCustomer(createCustomerRequest: CreateCustomerRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customersCreateCustomer(createCustomerRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} customerId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customersDeleteCustomer(customerId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customersDeleteCustomer(customerId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} customerId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customersGetCustomerById(customerId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customersGetCustomerById(customerId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customersGetCustomers(manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customersGetCustomers(manufacturerId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} customerId 
     * @param {UpdateCustomerRequest} updateCustomerRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customersUpdateCustomer(customerId: string, updateCustomerRequest: UpdateCustomerRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customersUpdateCustomer(customerId, updateCustomerRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DashboardsApi - axios parameter creator
 * @export
 */
export const DashboardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves the best selling products based on the given request parameters.
         * @param {string} [manufacturerId] 
         * @param {string} [rangeFrom] 
         * @param {string} [rangeTo] 
         * @param {number} [productsLimit] 
         * @param {BestSellingProductsOrderBy} [orderBy] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardsGetBestSellingProducts: async (manufacturerId?: string, rangeFrom?: string, rangeTo?: string, productsLimit?: number, orderBy?: BestSellingProductsOrderBy, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Dashboards/bestSellingProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }

            if (rangeFrom !== undefined) {
                localVarQueryParameter['RangeFrom'] = (rangeFrom as any instanceof Date) ?
                    (rangeFrom as any).toISOString() :
                    rangeFrom;
            }

            if (rangeTo !== undefined) {
                localVarQueryParameter['RangeTo'] = (rangeTo as any instanceof Date) ?
                    (rangeTo as any).toISOString() :
                    rangeTo;
            }

            if (productsLimit !== undefined) {
                localVarQueryParameter['ProductsLimit'] = productsLimit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the daily best-selling products.
         * @param {string} [manufacturerId] 
         * @param {string} [rangeFrom] 
         * @param {string} [rangeTo] 
         * @param {number} [productsLimitPerDay] 
         * @param {BestSellingProductsOrderBy} [orderBy] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardsGetDailyBestSellingProducts: async (manufacturerId?: string, rangeFrom?: string, rangeTo?: string, productsLimitPerDay?: number, orderBy?: BestSellingProductsOrderBy, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Dashboards/dailyBestSellingProducts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }

            if (rangeFrom !== undefined) {
                localVarQueryParameter['RangeFrom'] = (rangeFrom as any instanceof Date) ?
                    (rangeFrom as any).toISOString() :
                    rangeFrom;
            }

            if (rangeTo !== undefined) {
                localVarQueryParameter['RangeTo'] = (rangeTo as any instanceof Date) ?
                    (rangeTo as any).toISOString() :
                    rangeTo;
            }

            if (productsLimitPerDay !== undefined) {
                localVarQueryParameter['ProductsLimitPerDay'] = productsLimitPerDay;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the daily refunds based on the given request.
         * @param {string} [manufacturerId] 
         * @param {string} [rangeFrom] 
         * @param {string} [rangeTo] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardsGetDailyRefunds: async (manufacturerId?: string, rangeFrom?: string, rangeTo?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Dashboards/dailyRefunds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }

            if (rangeFrom !== undefined) {
                localVarQueryParameter['RangeFrom'] = (rangeFrom as any instanceof Date) ?
                    (rangeFrom as any).toISOString() :
                    rangeFrom;
            }

            if (rangeTo !== undefined) {
                localVarQueryParameter['RangeTo'] = (rangeTo as any instanceof Date) ?
                    (rangeTo as any).toISOString() :
                    rangeTo;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the daily revenue based on the given request.
         * @param {string} [manufacturerId] 
         * @param {string} [rangeFrom] 
         * @param {string} [rangeTo] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardsGetDailyRevenue: async (manufacturerId?: string, rangeFrom?: string, rangeTo?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Dashboards/dailyRevenue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }

            if (rangeFrom !== undefined) {
                localVarQueryParameter['RangeFrom'] = (rangeFrom as any instanceof Date) ?
                    (rangeFrom as any).toISOString() :
                    rangeFrom;
            }

            if (rangeTo !== undefined) {
                localVarQueryParameter['RangeTo'] = (rangeTo as any instanceof Date) ?
                    (rangeTo as any).toISOString() :
                    rangeTo;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the count of product validation results.
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardsGetProductValidationResultsCount: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Dashboards/productValidationResultsCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves returning customers statistics based on the provided query parameters.
         * @param {string} [manufacturerId] 
         * @param {string} [rangeFrom] 
         * @param {string} [rangeTo] 
         * @param {boolean} [showEmails] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardsGetReturningCustomersStatistics: async (manufacturerId?: string, rangeFrom?: string, rangeTo?: string, showEmails?: boolean, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Dashboards/returningCustomersStatistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }

            if (rangeFrom !== undefined) {
                localVarQueryParameter['RangeFrom'] = (rangeFrom as any instanceof Date) ?
                    (rangeFrom as any).toISOString() :
                    rangeFrom;
            }

            if (rangeTo !== undefined) {
                localVarQueryParameter['RangeTo'] = (rangeTo as any instanceof Date) ?
                    (rangeTo as any).toISOString() :
                    rangeTo;
            }

            if (showEmails !== undefined) {
                localVarQueryParameter['ShowEmails'] = showEmails;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardsGetUnfulfilledOrders: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Dashboards/unfulfilledOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardsApi - functional programming interface
 * @export
 */
export const DashboardsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves the best selling products based on the given request parameters.
         * @param {string} [manufacturerId] 
         * @param {string} [rangeFrom] 
         * @param {string} [rangeTo] 
         * @param {number} [productsLimit] 
         * @param {BestSellingProductsOrderBy} [orderBy] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardsGetBestSellingProducts(manufacturerId?: string, rangeFrom?: string, rangeTo?: string, productsLimit?: number, orderBy?: BestSellingProductsOrderBy, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SoldProductResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardsGetBestSellingProducts(manufacturerId, rangeFrom, rangeTo, productsLimit, orderBy, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves the daily best-selling products.
         * @param {string} [manufacturerId] 
         * @param {string} [rangeFrom] 
         * @param {string} [rangeTo] 
         * @param {number} [productsLimitPerDay] 
         * @param {BestSellingProductsOrderBy} [orderBy] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardsGetDailyBestSellingProducts(manufacturerId?: string, rangeFrom?: string, rangeTo?: string, productsLimitPerDay?: number, orderBy?: BestSellingProductsOrderBy, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DailyBestSellingProductsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardsGetDailyBestSellingProducts(manufacturerId, rangeFrom, rangeTo, productsLimitPerDay, orderBy, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves the daily refunds based on the given request.
         * @param {string} [manufacturerId] 
         * @param {string} [rangeFrom] 
         * @param {string} [rangeTo] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardsGetDailyRefunds(manufacturerId?: string, rangeFrom?: string, rangeTo?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DailyRefundsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardsGetDailyRefunds(manufacturerId, rangeFrom, rangeTo, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves the daily revenue based on the given request.
         * @param {string} [manufacturerId] 
         * @param {string} [rangeFrom] 
         * @param {string} [rangeTo] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardsGetDailyRevenue(manufacturerId?: string, rangeFrom?: string, rangeTo?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DailyRevenueResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardsGetDailyRevenue(manufacturerId, rangeFrom, rangeTo, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves the count of product validation results.
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardsGetProductValidationResultsCount(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardsGetProductValidationResultsCount(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves returning customers statistics based on the provided query parameters.
         * @param {string} [manufacturerId] 
         * @param {string} [rangeFrom] 
         * @param {string} [rangeTo] 
         * @param {boolean} [showEmails] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardsGetReturningCustomersStatistics(manufacturerId?: string, rangeFrom?: string, rangeTo?: string, showEmails?: boolean, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReturningCustomersStatisticsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardsGetReturningCustomersStatistics(manufacturerId, rangeFrom, rangeTo, showEmails, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardsGetUnfulfilledOrders(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UnfulfilledOrdersResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardsGetUnfulfilledOrders(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardsApi - factory interface
 * @export
 */
export const DashboardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardsApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves the best selling products based on the given request parameters.
         * @param {string} [manufacturerId] 
         * @param {string} [rangeFrom] 
         * @param {string} [rangeTo] 
         * @param {number} [productsLimit] 
         * @param {BestSellingProductsOrderBy} [orderBy] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardsGetBestSellingProducts(manufacturerId?: string, rangeFrom?: string, rangeTo?: string, productsLimit?: number, orderBy?: BestSellingProductsOrderBy, xVersion?: string, options?: any): AxiosPromise<Array<SoldProductResponse>> {
            return localVarFp.dashboardsGetBestSellingProducts(manufacturerId, rangeFrom, rangeTo, productsLimit, orderBy, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the daily best-selling products.
         * @param {string} [manufacturerId] 
         * @param {string} [rangeFrom] 
         * @param {string} [rangeTo] 
         * @param {number} [productsLimitPerDay] 
         * @param {BestSellingProductsOrderBy} [orderBy] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardsGetDailyBestSellingProducts(manufacturerId?: string, rangeFrom?: string, rangeTo?: string, productsLimitPerDay?: number, orderBy?: BestSellingProductsOrderBy, xVersion?: string, options?: any): AxiosPromise<Array<DailyBestSellingProductsResponse>> {
            return localVarFp.dashboardsGetDailyBestSellingProducts(manufacturerId, rangeFrom, rangeTo, productsLimitPerDay, orderBy, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the daily refunds based on the given request.
         * @param {string} [manufacturerId] 
         * @param {string} [rangeFrom] 
         * @param {string} [rangeTo] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardsGetDailyRefunds(manufacturerId?: string, rangeFrom?: string, rangeTo?: string, xVersion?: string, options?: any): AxiosPromise<Array<DailyRefundsResponse>> {
            return localVarFp.dashboardsGetDailyRefunds(manufacturerId, rangeFrom, rangeTo, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the daily revenue based on the given request.
         * @param {string} [manufacturerId] 
         * @param {string} [rangeFrom] 
         * @param {string} [rangeTo] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardsGetDailyRevenue(manufacturerId?: string, rangeFrom?: string, rangeTo?: string, xVersion?: string, options?: any): AxiosPromise<Array<DailyRevenueResponse>> {
            return localVarFp.dashboardsGetDailyRevenue(manufacturerId, rangeFrom, rangeTo, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the count of product validation results.
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardsGetProductValidationResultsCount(xVersion?: string, options?: any): AxiosPromise<number> {
            return localVarFp.dashboardsGetProductValidationResultsCount(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves returning customers statistics based on the provided query parameters.
         * @param {string} [manufacturerId] 
         * @param {string} [rangeFrom] 
         * @param {string} [rangeTo] 
         * @param {boolean} [showEmails] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardsGetReturningCustomersStatistics(manufacturerId?: string, rangeFrom?: string, rangeTo?: string, showEmails?: boolean, xVersion?: string, options?: any): AxiosPromise<Array<ReturningCustomersStatisticsResponse>> {
            return localVarFp.dashboardsGetReturningCustomersStatistics(manufacturerId, rangeFrom, rangeTo, showEmails, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardsGetUnfulfilledOrders(xVersion?: string, options?: any): AxiosPromise<Array<UnfulfilledOrdersResponse>> {
            return localVarFp.dashboardsGetUnfulfilledOrders(xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardsApi - object-oriented interface
 * @export
 * @class DashboardsApi
 * @extends {BaseAPI}
 */
export class DashboardsApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves the best selling products based on the given request parameters.
     * @param {string} [manufacturerId] 
     * @param {string} [rangeFrom] 
     * @param {string} [rangeTo] 
     * @param {number} [productsLimit] 
     * @param {BestSellingProductsOrderBy} [orderBy] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardsApi
     */
    public dashboardsGetBestSellingProducts(manufacturerId?: string, rangeFrom?: string, rangeTo?: string, productsLimit?: number, orderBy?: BestSellingProductsOrderBy, xVersion?: string, options?: AxiosRequestConfig) {
        return DashboardsApiFp(this.configuration).dashboardsGetBestSellingProducts(manufacturerId, rangeFrom, rangeTo, productsLimit, orderBy, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the daily best-selling products.
     * @param {string} [manufacturerId] 
     * @param {string} [rangeFrom] 
     * @param {string} [rangeTo] 
     * @param {number} [productsLimitPerDay] 
     * @param {BestSellingProductsOrderBy} [orderBy] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardsApi
     */
    public dashboardsGetDailyBestSellingProducts(manufacturerId?: string, rangeFrom?: string, rangeTo?: string, productsLimitPerDay?: number, orderBy?: BestSellingProductsOrderBy, xVersion?: string, options?: AxiosRequestConfig) {
        return DashboardsApiFp(this.configuration).dashboardsGetDailyBestSellingProducts(manufacturerId, rangeFrom, rangeTo, productsLimitPerDay, orderBy, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the daily refunds based on the given request.
     * @param {string} [manufacturerId] 
     * @param {string} [rangeFrom] 
     * @param {string} [rangeTo] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardsApi
     */
    public dashboardsGetDailyRefunds(manufacturerId?: string, rangeFrom?: string, rangeTo?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return DashboardsApiFp(this.configuration).dashboardsGetDailyRefunds(manufacturerId, rangeFrom, rangeTo, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the daily revenue based on the given request.
     * @param {string} [manufacturerId] 
     * @param {string} [rangeFrom] 
     * @param {string} [rangeTo] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardsApi
     */
    public dashboardsGetDailyRevenue(manufacturerId?: string, rangeFrom?: string, rangeTo?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return DashboardsApiFp(this.configuration).dashboardsGetDailyRevenue(manufacturerId, rangeFrom, rangeTo, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the count of product validation results.
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardsApi
     */
    public dashboardsGetProductValidationResultsCount(xVersion?: string, options?: AxiosRequestConfig) {
        return DashboardsApiFp(this.configuration).dashboardsGetProductValidationResultsCount(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves returning customers statistics based on the provided query parameters.
     * @param {string} [manufacturerId] 
     * @param {string} [rangeFrom] 
     * @param {string} [rangeTo] 
     * @param {boolean} [showEmails] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardsApi
     */
    public dashboardsGetReturningCustomersStatistics(manufacturerId?: string, rangeFrom?: string, rangeTo?: string, showEmails?: boolean, xVersion?: string, options?: AxiosRequestConfig) {
        return DashboardsApiFp(this.configuration).dashboardsGetReturningCustomersStatistics(manufacturerId, rangeFrom, rangeTo, showEmails, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardsApi
     */
    public dashboardsGetUnfulfilledOrders(xVersion?: string, options?: AxiosRequestConfig) {
        return DashboardsApiFp(this.configuration).dashboardsGetUnfulfilledOrders(xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DataImportConfigsApi - axios parameter creator
 * @export
 */
export const DataImportConfigsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateDataImportConfigRequest} createDataImportConfigRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataImportConfigsCreateDataImportConfig: async (createDataImportConfigRequest: CreateDataImportConfigRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDataImportConfigRequest' is not null or undefined
            assertParamExists('dataImportConfigsCreateDataImportConfig', 'createDataImportConfigRequest', createDataImportConfigRequest)
            const localVarPath = `/api/DataImportConfigs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDataImportConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dataImportConfigId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataImportConfigsDeleteDataImportConfig: async (dataImportConfigId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataImportConfigId' is not null or undefined
            assertParamExists('dataImportConfigsDeleteDataImportConfig', 'dataImportConfigId', dataImportConfigId)
            const localVarPath = `/api/DataImportConfigs/{dataImportConfigId}`
                .replace(`{${"dataImportConfigId"}}`, encodeURIComponent(String(dataImportConfigId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dataImportConfigId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataImportConfigsGetDataImportConfigId: async (dataImportConfigId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataImportConfigId' is not null or undefined
            assertParamExists('dataImportConfigsGetDataImportConfigId', 'dataImportConfigId', dataImportConfigId)
            const localVarPath = `/api/DataImportConfigs/{dataImportConfigId}`
                .replace(`{${"dataImportConfigId"}}`, encodeURIComponent(String(dataImportConfigId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataImportConfigsGetDataImportConfigs: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DataImportConfigs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dataImportConfigId 
         * @param {UpdateDataImportConfigRequest} updateDataImportConfigRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataImportConfigsUpdateDataImportConfig: async (dataImportConfigId: string, updateDataImportConfigRequest: UpdateDataImportConfigRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataImportConfigId' is not null or undefined
            assertParamExists('dataImportConfigsUpdateDataImportConfig', 'dataImportConfigId', dataImportConfigId)
            // verify required parameter 'updateDataImportConfigRequest' is not null or undefined
            assertParamExists('dataImportConfigsUpdateDataImportConfig', 'updateDataImportConfigRequest', updateDataImportConfigRequest)
            const localVarPath = `/api/DataImportConfigs/{dataImportConfigId}`
                .replace(`{${"dataImportConfigId"}}`, encodeURIComponent(String(dataImportConfigId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDataImportConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataImportConfigsApi - functional programming interface
 * @export
 */
export const DataImportConfigsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataImportConfigsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateDataImportConfigRequest} createDataImportConfigRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataImportConfigsCreateDataImportConfig(createDataImportConfigRequest: CreateDataImportConfigRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataImportConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataImportConfigsCreateDataImportConfig(createDataImportConfigRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dataImportConfigId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataImportConfigsDeleteDataImportConfig(dataImportConfigId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataImportConfigsDeleteDataImportConfig(dataImportConfigId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dataImportConfigId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataImportConfigsGetDataImportConfigId(dataImportConfigId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataImportConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataImportConfigsGetDataImportConfigId(dataImportConfigId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataImportConfigsGetDataImportConfigs(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataImportConfigResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataImportConfigsGetDataImportConfigs(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dataImportConfigId 
         * @param {UpdateDataImportConfigRequest} updateDataImportConfigRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataImportConfigsUpdateDataImportConfig(dataImportConfigId: string, updateDataImportConfigRequest: UpdateDataImportConfigRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataImportConfigsUpdateDataImportConfig(dataImportConfigId, updateDataImportConfigRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataImportConfigsApi - factory interface
 * @export
 */
export const DataImportConfigsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataImportConfigsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateDataImportConfigRequest} createDataImportConfigRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataImportConfigsCreateDataImportConfig(createDataImportConfigRequest: CreateDataImportConfigRequest, xVersion?: string, options?: any): AxiosPromise<DataImportConfigResponse> {
            return localVarFp.dataImportConfigsCreateDataImportConfig(createDataImportConfigRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dataImportConfigId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataImportConfigsDeleteDataImportConfig(dataImportConfigId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.dataImportConfigsDeleteDataImportConfig(dataImportConfigId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dataImportConfigId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataImportConfigsGetDataImportConfigId(dataImportConfigId: string, xVersion?: string, options?: any): AxiosPromise<DataImportConfigResponse> {
            return localVarFp.dataImportConfigsGetDataImportConfigId(dataImportConfigId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataImportConfigsGetDataImportConfigs(xVersion?: string, options?: any): AxiosPromise<Array<DataImportConfigResponse>> {
            return localVarFp.dataImportConfigsGetDataImportConfigs(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dataImportConfigId 
         * @param {UpdateDataImportConfigRequest} updateDataImportConfigRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataImportConfigsUpdateDataImportConfig(dataImportConfigId: string, updateDataImportConfigRequest: UpdateDataImportConfigRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.dataImportConfigsUpdateDataImportConfig(dataImportConfigId, updateDataImportConfigRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataImportConfigsApi - object-oriented interface
 * @export
 * @class DataImportConfigsApi
 * @extends {BaseAPI}
 */
export class DataImportConfigsApi extends BaseAPI {
    /**
     * 
     * @param {CreateDataImportConfigRequest} createDataImportConfigRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportConfigsApi
     */
    public dataImportConfigsCreateDataImportConfig(createDataImportConfigRequest: CreateDataImportConfigRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return DataImportConfigsApiFp(this.configuration).dataImportConfigsCreateDataImportConfig(createDataImportConfigRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dataImportConfigId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportConfigsApi
     */
    public dataImportConfigsDeleteDataImportConfig(dataImportConfigId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return DataImportConfigsApiFp(this.configuration).dataImportConfigsDeleteDataImportConfig(dataImportConfigId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dataImportConfigId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportConfigsApi
     */
    public dataImportConfigsGetDataImportConfigId(dataImportConfigId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return DataImportConfigsApiFp(this.configuration).dataImportConfigsGetDataImportConfigId(dataImportConfigId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportConfigsApi
     */
    public dataImportConfigsGetDataImportConfigs(xVersion?: string, options?: AxiosRequestConfig) {
        return DataImportConfigsApiFp(this.configuration).dataImportConfigsGetDataImportConfigs(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dataImportConfigId 
     * @param {UpdateDataImportConfigRequest} updateDataImportConfigRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportConfigsApi
     */
    public dataImportConfigsUpdateDataImportConfig(dataImportConfigId: string, updateDataImportConfigRequest: UpdateDataImportConfigRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return DataImportConfigsApiFp(this.configuration).dataImportConfigsUpdateDataImportConfig(dataImportConfigId, updateDataImportConfigRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DataImportsApi - axios parameter creator
 * @export
 */
export const DataImportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateDataImportRequest} createDataImportRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataImportsCreateDataImport: async (createDataImportRequest: CreateDataImportRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDataImportRequest' is not null or undefined
            assertParamExists('dataImportsCreateDataImport', 'createDataImportRequest', createDataImportRequest)
            const localVarPath = `/api/DataImports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDataImportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dataImportId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataImportsCreateProductsFromDataImport: async (dataImportId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataImportId' is not null or undefined
            assertParamExists('dataImportsCreateProductsFromDataImport', 'dataImportId', dataImportId)
            const localVarPath = `/api/DataImports/{dataImportId}/createProducts`
                .replace(`{${"dataImportId"}}`, encodeURIComponent(String(dataImportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dataImportId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataImportsDeleteDataImport: async (dataImportId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataImportId' is not null or undefined
            assertParamExists('dataImportsDeleteDataImport', 'dataImportId', dataImportId)
            const localVarPath = `/api/DataImports/{dataImportId}`
                .replace(`{${"dataImportId"}}`, encodeURIComponent(String(dataImportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dataImportId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataImportsGetDataImportById: async (dataImportId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataImportId' is not null or undefined
            assertParamExists('dataImportsGetDataImportById', 'dataImportId', dataImportId)
            const localVarPath = `/api/DataImports/{dataImportId}`
                .replace(`{${"dataImportId"}}`, encodeURIComponent(String(dataImportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataImportsGetDataImports: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DataImports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dataImportId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataImportsGetProductDataImportByDataImportId: async (dataImportId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataImportId' is not null or undefined
            assertParamExists('dataImportsGetProductDataImportByDataImportId', 'dataImportId', dataImportId)
            const localVarPath = `/api/DataImports/{dataImportId}/items/products`
                .replace(`{${"dataImportId"}}`, encodeURIComponent(String(dataImportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataImportsGetRequiredFields: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DataImports/validation/requiredFields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dataImportId 
         * @param {UpdateProductDataImportItemsRequest} updateProductDataImportItemsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataImportsUpdateProductDataImportItemsByDataImportId: async (dataImportId: string, updateProductDataImportItemsRequest: UpdateProductDataImportItemsRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataImportId' is not null or undefined
            assertParamExists('dataImportsUpdateProductDataImportItemsByDataImportId', 'dataImportId', dataImportId)
            // verify required parameter 'updateProductDataImportItemsRequest' is not null or undefined
            assertParamExists('dataImportsUpdateProductDataImportItemsByDataImportId', 'updateProductDataImportItemsRequest', updateProductDataImportItemsRequest)
            const localVarPath = `/api/DataImports/{dataImportId}/items/products`
                .replace(`{${"dataImportId"}}`, encodeURIComponent(String(dataImportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductDataImportItemsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataImportsApi - functional programming interface
 * @export
 */
export const DataImportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataImportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateDataImportRequest} createDataImportRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataImportsCreateDataImport(createDataImportRequest: CreateDataImportRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataImportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataImportsCreateDataImport(createDataImportRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dataImportId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataImportsCreateProductsFromDataImport(dataImportId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateProductsFromDataImportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataImportsCreateProductsFromDataImport(dataImportId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dataImportId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataImportsDeleteDataImport(dataImportId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataImportsDeleteDataImport(dataImportId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dataImportId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataImportsGetDataImportById(dataImportId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataImportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataImportsGetDataImportById(dataImportId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataImportsGetDataImports(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataImportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataImportsGetDataImports(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dataImportId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataImportsGetProductDataImportByDataImportId(dataImportId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDataImportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataImportsGetProductDataImportByDataImportId(dataImportId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataImportsGetRequiredFields(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransformedProductRequiredFieldsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataImportsGetRequiredFields(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dataImportId 
         * @param {UpdateProductDataImportItemsRequest} updateProductDataImportItemsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataImportsUpdateProductDataImportItemsByDataImportId(dataImportId: string, updateProductDataImportItemsRequest: UpdateProductDataImportItemsRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataImportsUpdateProductDataImportItemsByDataImportId(dataImportId, updateProductDataImportItemsRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataImportsApi - factory interface
 * @export
 */
export const DataImportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataImportsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateDataImportRequest} createDataImportRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataImportsCreateDataImport(createDataImportRequest: CreateDataImportRequest, xVersion?: string, options?: any): AxiosPromise<DataImportResponse> {
            return localVarFp.dataImportsCreateDataImport(createDataImportRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dataImportId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataImportsCreateProductsFromDataImport(dataImportId: string, xVersion?: string, options?: any): AxiosPromise<CreateProductsFromDataImportResponse> {
            return localVarFp.dataImportsCreateProductsFromDataImport(dataImportId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dataImportId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataImportsDeleteDataImport(dataImportId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.dataImportsDeleteDataImport(dataImportId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dataImportId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataImportsGetDataImportById(dataImportId: string, xVersion?: string, options?: any): AxiosPromise<DataImportResponse> {
            return localVarFp.dataImportsGetDataImportById(dataImportId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataImportsGetDataImports(xVersion?: string, options?: any): AxiosPromise<Array<DataImportResponse>> {
            return localVarFp.dataImportsGetDataImports(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dataImportId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataImportsGetProductDataImportByDataImportId(dataImportId: string, xVersion?: string, options?: any): AxiosPromise<ProductDataImportResponse> {
            return localVarFp.dataImportsGetProductDataImportByDataImportId(dataImportId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataImportsGetRequiredFields(xVersion?: string, options?: any): AxiosPromise<TransformedProductRequiredFieldsResponse> {
            return localVarFp.dataImportsGetRequiredFields(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dataImportId 
         * @param {UpdateProductDataImportItemsRequest} updateProductDataImportItemsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataImportsUpdateProductDataImportItemsByDataImportId(dataImportId: string, updateProductDataImportItemsRequest: UpdateProductDataImportItemsRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.dataImportsUpdateProductDataImportItemsByDataImportId(dataImportId, updateProductDataImportItemsRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataImportsApi - object-oriented interface
 * @export
 * @class DataImportsApi
 * @extends {BaseAPI}
 */
export class DataImportsApi extends BaseAPI {
    /**
     * 
     * @param {CreateDataImportRequest} createDataImportRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportsApi
     */
    public dataImportsCreateDataImport(createDataImportRequest: CreateDataImportRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return DataImportsApiFp(this.configuration).dataImportsCreateDataImport(createDataImportRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dataImportId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportsApi
     */
    public dataImportsCreateProductsFromDataImport(dataImportId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return DataImportsApiFp(this.configuration).dataImportsCreateProductsFromDataImport(dataImportId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dataImportId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportsApi
     */
    public dataImportsDeleteDataImport(dataImportId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return DataImportsApiFp(this.configuration).dataImportsDeleteDataImport(dataImportId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dataImportId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportsApi
     */
    public dataImportsGetDataImportById(dataImportId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return DataImportsApiFp(this.configuration).dataImportsGetDataImportById(dataImportId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportsApi
     */
    public dataImportsGetDataImports(xVersion?: string, options?: AxiosRequestConfig) {
        return DataImportsApiFp(this.configuration).dataImportsGetDataImports(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dataImportId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportsApi
     */
    public dataImportsGetProductDataImportByDataImportId(dataImportId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return DataImportsApiFp(this.configuration).dataImportsGetProductDataImportByDataImportId(dataImportId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportsApi
     */
    public dataImportsGetRequiredFields(xVersion?: string, options?: AxiosRequestConfig) {
        return DataImportsApiFp(this.configuration).dataImportsGetRequiredFields(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dataImportId 
     * @param {UpdateProductDataImportItemsRequest} updateProductDataImportItemsRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportsApi
     */
    public dataImportsUpdateProductDataImportItemsByDataImportId(dataImportId: string, updateProductDataImportItemsRequest: UpdateProductDataImportItemsRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return DataImportsApiFp(this.configuration).dataImportsUpdateProductDataImportItemsByDataImportId(dataImportId, updateProductDataImportItemsRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeliveryTimesApi - axios parameter creator
 * @export
 */
export const DeliveryTimesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateDeliveryTimeRequest} createDeliveryTimeRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryTimesCreateDeliveryTime: async (createDeliveryTimeRequest: CreateDeliveryTimeRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDeliveryTimeRequest' is not null or undefined
            assertParamExists('deliveryTimesCreateDeliveryTime', 'createDeliveryTimeRequest', createDeliveryTimeRequest)
            const localVarPath = `/api/DeliveryTimes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDeliveryTimeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deliveryTimeId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryTimesDeleteDeliveryTime: async (deliveryTimeId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryTimeId' is not null or undefined
            assertParamExists('deliveryTimesDeleteDeliveryTime', 'deliveryTimeId', deliveryTimeId)
            const localVarPath = `/api/DeliveryTimes/{deliveryTimeId}`
                .replace(`{${"deliveryTimeId"}}`, encodeURIComponent(String(deliveryTimeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deliveryTimeId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryTimesGetDeliveryTimeById: async (deliveryTimeId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryTimeId' is not null or undefined
            assertParamExists('deliveryTimesGetDeliveryTimeById', 'deliveryTimeId', deliveryTimeId)
            const localVarPath = `/api/DeliveryTimes/{deliveryTimeId}`
                .replace(`{${"deliveryTimeId"}}`, encodeURIComponent(String(deliveryTimeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {string} [productVersionId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryTimesGetDeliveryTimes: async (productId?: string, productVersionId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DeliveryTimes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productId !== undefined) {
                localVarQueryParameter['ProductId'] = productId;
            }

            if (productVersionId !== undefined) {
                localVarQueryParameter['ProductVersionId'] = productVersionId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deliveryTimeId 
         * @param {UpdateDeliveryTimeRequest} updateDeliveryTimeRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryTimesUpdateDeliveryTime: async (deliveryTimeId: string, updateDeliveryTimeRequest: UpdateDeliveryTimeRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryTimeId' is not null or undefined
            assertParamExists('deliveryTimesUpdateDeliveryTime', 'deliveryTimeId', deliveryTimeId)
            // verify required parameter 'updateDeliveryTimeRequest' is not null or undefined
            assertParamExists('deliveryTimesUpdateDeliveryTime', 'updateDeliveryTimeRequest', updateDeliveryTimeRequest)
            const localVarPath = `/api/DeliveryTimes/{deliveryTimeId}`
                .replace(`{${"deliveryTimeId"}}`, encodeURIComponent(String(deliveryTimeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeliveryTimeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeliveryTimesApi - functional programming interface
 * @export
 */
export const DeliveryTimesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeliveryTimesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateDeliveryTimeRequest} createDeliveryTimeRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliveryTimesCreateDeliveryTime(createDeliveryTimeRequest: CreateDeliveryTimeRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryTimeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliveryTimesCreateDeliveryTime(createDeliveryTimeRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} deliveryTimeId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliveryTimesDeleteDeliveryTime(deliveryTimeId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliveryTimesDeleteDeliveryTime(deliveryTimeId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} deliveryTimeId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliveryTimesGetDeliveryTimeById(deliveryTimeId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryTimeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliveryTimesGetDeliveryTimeById(deliveryTimeId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {string} [productVersionId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliveryTimesGetDeliveryTimes(productId?: string, productVersionId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeliveryTimeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliveryTimesGetDeliveryTimes(productId, productVersionId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} deliveryTimeId 
         * @param {UpdateDeliveryTimeRequest} updateDeliveryTimeRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliveryTimesUpdateDeliveryTime(deliveryTimeId: string, updateDeliveryTimeRequest: UpdateDeliveryTimeRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliveryTimesUpdateDeliveryTime(deliveryTimeId, updateDeliveryTimeRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeliveryTimesApi - factory interface
 * @export
 */
export const DeliveryTimesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeliveryTimesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateDeliveryTimeRequest} createDeliveryTimeRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryTimesCreateDeliveryTime(createDeliveryTimeRequest: CreateDeliveryTimeRequest, xVersion?: string, options?: any): AxiosPromise<DeliveryTimeResponse> {
            return localVarFp.deliveryTimesCreateDeliveryTime(createDeliveryTimeRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deliveryTimeId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryTimesDeleteDeliveryTime(deliveryTimeId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deliveryTimesDeleteDeliveryTime(deliveryTimeId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deliveryTimeId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryTimesGetDeliveryTimeById(deliveryTimeId: string, xVersion?: string, options?: any): AxiosPromise<DeliveryTimeResponse> {
            return localVarFp.deliveryTimesGetDeliveryTimeById(deliveryTimeId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {string} [productVersionId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryTimesGetDeliveryTimes(productId?: string, productVersionId?: string, xVersion?: string, options?: any): AxiosPromise<Array<DeliveryTimeResponse>> {
            return localVarFp.deliveryTimesGetDeliveryTimes(productId, productVersionId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deliveryTimeId 
         * @param {UpdateDeliveryTimeRequest} updateDeliveryTimeRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryTimesUpdateDeliveryTime(deliveryTimeId: string, updateDeliveryTimeRequest: UpdateDeliveryTimeRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deliveryTimesUpdateDeliveryTime(deliveryTimeId, updateDeliveryTimeRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeliveryTimesApi - object-oriented interface
 * @export
 * @class DeliveryTimesApi
 * @extends {BaseAPI}
 */
export class DeliveryTimesApi extends BaseAPI {
    /**
     * 
     * @param {CreateDeliveryTimeRequest} createDeliveryTimeRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryTimesApi
     */
    public deliveryTimesCreateDeliveryTime(createDeliveryTimeRequest: CreateDeliveryTimeRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return DeliveryTimesApiFp(this.configuration).deliveryTimesCreateDeliveryTime(createDeliveryTimeRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deliveryTimeId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryTimesApi
     */
    public deliveryTimesDeleteDeliveryTime(deliveryTimeId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return DeliveryTimesApiFp(this.configuration).deliveryTimesDeleteDeliveryTime(deliveryTimeId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deliveryTimeId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryTimesApi
     */
    public deliveryTimesGetDeliveryTimeById(deliveryTimeId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return DeliveryTimesApiFp(this.configuration).deliveryTimesGetDeliveryTimeById(deliveryTimeId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [productId] 
     * @param {string} [productVersionId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryTimesApi
     */
    public deliveryTimesGetDeliveryTimes(productId?: string, productVersionId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return DeliveryTimesApiFp(this.configuration).deliveryTimesGetDeliveryTimes(productId, productVersionId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deliveryTimeId 
     * @param {UpdateDeliveryTimeRequest} updateDeliveryTimeRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryTimesApi
     */
    public deliveryTimesUpdateDeliveryTime(deliveryTimeId: string, updateDeliveryTimeRequest: UpdateDeliveryTimeRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return DeliveryTimesApiFp(this.configuration).deliveryTimesUpdateDeliveryTime(deliveryTimeId, updateDeliveryTimeRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentLayoutsApi - axios parameter creator
 * @export
 */
export const DocumentLayoutsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateDocumentLayoutRequest} createDocumentLayoutRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentLayoutsCreateDocumentLayout: async (createDocumentLayoutRequest: CreateDocumentLayoutRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDocumentLayoutRequest' is not null or undefined
            assertParamExists('documentLayoutsCreateDocumentLayout', 'createDocumentLayoutRequest', createDocumentLayoutRequest)
            const localVarPath = `/api/DocumentLayouts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDocumentLayoutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentLayoutId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentLayoutsDeleteDocumentLayout: async (documentLayoutId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentLayoutId' is not null or undefined
            assertParamExists('documentLayoutsDeleteDocumentLayout', 'documentLayoutId', documentLayoutId)
            const localVarPath = `/api/DocumentLayouts/{documentLayoutId}`
                .replace(`{${"documentLayoutId"}}`, encodeURIComponent(String(documentLayoutId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentLayoutId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentLayoutsGetDocumentLayoutById: async (documentLayoutId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentLayoutId' is not null or undefined
            assertParamExists('documentLayoutsGetDocumentLayoutById', 'documentLayoutId', documentLayoutId)
            const localVarPath = `/api/DocumentLayouts/{documentLayoutId}`
                .replace(`{${"documentLayoutId"}}`, encodeURIComponent(String(documentLayoutId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentLayoutsGetDocumentLayouts: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DocumentLayouts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentLayoutId 
         * @param {UpdateDocumentLayoutRequest} updateDocumentLayoutRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentLayoutsUpdateDocumentLayout: async (documentLayoutId: string, updateDocumentLayoutRequest: UpdateDocumentLayoutRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentLayoutId' is not null or undefined
            assertParamExists('documentLayoutsUpdateDocumentLayout', 'documentLayoutId', documentLayoutId)
            // verify required parameter 'updateDocumentLayoutRequest' is not null or undefined
            assertParamExists('documentLayoutsUpdateDocumentLayout', 'updateDocumentLayoutRequest', updateDocumentLayoutRequest)
            const localVarPath = `/api/DocumentLayouts/{documentLayoutId}`
                .replace(`{${"documentLayoutId"}}`, encodeURIComponent(String(documentLayoutId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDocumentLayoutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentLayoutsApi - functional programming interface
 * @export
 */
export const DocumentLayoutsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentLayoutsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateDocumentLayoutRequest} createDocumentLayoutRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentLayoutsCreateDocumentLayout(createDocumentLayoutRequest: CreateDocumentLayoutRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentLayoutResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentLayoutsCreateDocumentLayout(createDocumentLayoutRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} documentLayoutId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentLayoutsDeleteDocumentLayout(documentLayoutId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentLayoutsDeleteDocumentLayout(documentLayoutId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} documentLayoutId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentLayoutsGetDocumentLayoutById(documentLayoutId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentLayoutResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentLayoutsGetDocumentLayoutById(documentLayoutId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentLayoutsGetDocumentLayouts(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentLayoutResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentLayoutsGetDocumentLayouts(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} documentLayoutId 
         * @param {UpdateDocumentLayoutRequest} updateDocumentLayoutRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentLayoutsUpdateDocumentLayout(documentLayoutId: string, updateDocumentLayoutRequest: UpdateDocumentLayoutRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentLayoutsUpdateDocumentLayout(documentLayoutId, updateDocumentLayoutRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentLayoutsApi - factory interface
 * @export
 */
export const DocumentLayoutsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentLayoutsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateDocumentLayoutRequest} createDocumentLayoutRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentLayoutsCreateDocumentLayout(createDocumentLayoutRequest: CreateDocumentLayoutRequest, xVersion?: string, options?: any): AxiosPromise<DocumentLayoutResponse> {
            return localVarFp.documentLayoutsCreateDocumentLayout(createDocumentLayoutRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentLayoutId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentLayoutsDeleteDocumentLayout(documentLayoutId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.documentLayoutsDeleteDocumentLayout(documentLayoutId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentLayoutId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentLayoutsGetDocumentLayoutById(documentLayoutId: string, xVersion?: string, options?: any): AxiosPromise<DocumentLayoutResponse> {
            return localVarFp.documentLayoutsGetDocumentLayoutById(documentLayoutId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentLayoutsGetDocumentLayouts(xVersion?: string, options?: any): AxiosPromise<Array<DocumentLayoutResponse>> {
            return localVarFp.documentLayoutsGetDocumentLayouts(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentLayoutId 
         * @param {UpdateDocumentLayoutRequest} updateDocumentLayoutRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentLayoutsUpdateDocumentLayout(documentLayoutId: string, updateDocumentLayoutRequest: UpdateDocumentLayoutRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.documentLayoutsUpdateDocumentLayout(documentLayoutId, updateDocumentLayoutRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentLayoutsApi - object-oriented interface
 * @export
 * @class DocumentLayoutsApi
 * @extends {BaseAPI}
 */
export class DocumentLayoutsApi extends BaseAPI {
    /**
     * 
     * @param {CreateDocumentLayoutRequest} createDocumentLayoutRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentLayoutsApi
     */
    public documentLayoutsCreateDocumentLayout(createDocumentLayoutRequest: CreateDocumentLayoutRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return DocumentLayoutsApiFp(this.configuration).documentLayoutsCreateDocumentLayout(createDocumentLayoutRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} documentLayoutId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentLayoutsApi
     */
    public documentLayoutsDeleteDocumentLayout(documentLayoutId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return DocumentLayoutsApiFp(this.configuration).documentLayoutsDeleteDocumentLayout(documentLayoutId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} documentLayoutId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentLayoutsApi
     */
    public documentLayoutsGetDocumentLayoutById(documentLayoutId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return DocumentLayoutsApiFp(this.configuration).documentLayoutsGetDocumentLayoutById(documentLayoutId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentLayoutsApi
     */
    public documentLayoutsGetDocumentLayouts(xVersion?: string, options?: AxiosRequestConfig) {
        return DocumentLayoutsApiFp(this.configuration).documentLayoutsGetDocumentLayouts(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} documentLayoutId 
     * @param {UpdateDocumentLayoutRequest} updateDocumentLayoutRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentLayoutsApi
     */
    public documentLayoutsUpdateDocumentLayout(documentLayoutId: string, updateDocumentLayoutRequest: UpdateDocumentLayoutRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return DocumentLayoutsApiFp(this.configuration).documentLayoutsUpdateDocumentLayout(documentLayoutId, updateDocumentLayoutRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FiegePurchaseOrdersApi - axios parameter creator
 * @export
 */
export const FiegePurchaseOrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateFiegePurchaseOrderRequest} createFiegePurchaseOrderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fiegePurchaseOrdersCreateFiegePurchaseOrder: async (createFiegePurchaseOrderRequest: CreateFiegePurchaseOrderRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFiegePurchaseOrderRequest' is not null or undefined
            assertParamExists('fiegePurchaseOrdersCreateFiegePurchaseOrder', 'createFiegePurchaseOrderRequest', createFiegePurchaseOrderRequest)
            const localVarPath = `/api/FiegePurchaseOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFiegePurchaseOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fiegePurchaseOrderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fiegePurchaseOrdersGetFiegePurchaseOrderById: async (fiegePurchaseOrderId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fiegePurchaseOrderId' is not null or undefined
            assertParamExists('fiegePurchaseOrdersGetFiegePurchaseOrderById', 'fiegePurchaseOrderId', fiegePurchaseOrderId)
            const localVarPath = `/api/FiegePurchaseOrders/{fiegePurchaseOrderId}`
                .replace(`{${"fiegePurchaseOrderId"}}`, encodeURIComponent(String(fiegePurchaseOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fiegePurchaseOrdersGetFiegePurchaseOrders: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FiegePurchaseOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fiegePurchaseOrderId 
         * @param {UpdateFiegePurchaseOrderRequest} updateFiegePurchaseOrderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fiegePurchaseOrdersUpdateFiegePurchaseOrder: async (fiegePurchaseOrderId: string, updateFiegePurchaseOrderRequest: UpdateFiegePurchaseOrderRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fiegePurchaseOrderId' is not null or undefined
            assertParamExists('fiegePurchaseOrdersUpdateFiegePurchaseOrder', 'fiegePurchaseOrderId', fiegePurchaseOrderId)
            // verify required parameter 'updateFiegePurchaseOrderRequest' is not null or undefined
            assertParamExists('fiegePurchaseOrdersUpdateFiegePurchaseOrder', 'updateFiegePurchaseOrderRequest', updateFiegePurchaseOrderRequest)
            const localVarPath = `/api/FiegePurchaseOrders/{fiegePurchaseOrderId}`
                .replace(`{${"fiegePurchaseOrderId"}}`, encodeURIComponent(String(fiegePurchaseOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFiegePurchaseOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FiegePurchaseOrdersApi - functional programming interface
 * @export
 */
export const FiegePurchaseOrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FiegePurchaseOrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateFiegePurchaseOrderRequest} createFiegePurchaseOrderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fiegePurchaseOrdersCreateFiegePurchaseOrder(createFiegePurchaseOrderRequest: CreateFiegePurchaseOrderRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FiegePurchaseOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fiegePurchaseOrdersCreateFiegePurchaseOrder(createFiegePurchaseOrderRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fiegePurchaseOrderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fiegePurchaseOrdersGetFiegePurchaseOrderById(fiegePurchaseOrderId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FiegePurchaseOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fiegePurchaseOrdersGetFiegePurchaseOrderById(fiegePurchaseOrderId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fiegePurchaseOrdersGetFiegePurchaseOrders(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FiegePurchaseOrderResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fiegePurchaseOrdersGetFiegePurchaseOrders(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fiegePurchaseOrderId 
         * @param {UpdateFiegePurchaseOrderRequest} updateFiegePurchaseOrderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fiegePurchaseOrdersUpdateFiegePurchaseOrder(fiegePurchaseOrderId: string, updateFiegePurchaseOrderRequest: UpdateFiegePurchaseOrderRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fiegePurchaseOrdersUpdateFiegePurchaseOrder(fiegePurchaseOrderId, updateFiegePurchaseOrderRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FiegePurchaseOrdersApi - factory interface
 * @export
 */
export const FiegePurchaseOrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FiegePurchaseOrdersApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateFiegePurchaseOrderRequest} createFiegePurchaseOrderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fiegePurchaseOrdersCreateFiegePurchaseOrder(createFiegePurchaseOrderRequest: CreateFiegePurchaseOrderRequest, xVersion?: string, options?: any): AxiosPromise<FiegePurchaseOrderResponse> {
            return localVarFp.fiegePurchaseOrdersCreateFiegePurchaseOrder(createFiegePurchaseOrderRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fiegePurchaseOrderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fiegePurchaseOrdersGetFiegePurchaseOrderById(fiegePurchaseOrderId: string, xVersion?: string, options?: any): AxiosPromise<FiegePurchaseOrderResponse> {
            return localVarFp.fiegePurchaseOrdersGetFiegePurchaseOrderById(fiegePurchaseOrderId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fiegePurchaseOrdersGetFiegePurchaseOrders(xVersion?: string, options?: any): AxiosPromise<Array<FiegePurchaseOrderResponse>> {
            return localVarFp.fiegePurchaseOrdersGetFiegePurchaseOrders(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fiegePurchaseOrderId 
         * @param {UpdateFiegePurchaseOrderRequest} updateFiegePurchaseOrderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fiegePurchaseOrdersUpdateFiegePurchaseOrder(fiegePurchaseOrderId: string, updateFiegePurchaseOrderRequest: UpdateFiegePurchaseOrderRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.fiegePurchaseOrdersUpdateFiegePurchaseOrder(fiegePurchaseOrderId, updateFiegePurchaseOrderRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FiegePurchaseOrdersApi - object-oriented interface
 * @export
 * @class FiegePurchaseOrdersApi
 * @extends {BaseAPI}
 */
export class FiegePurchaseOrdersApi extends BaseAPI {
    /**
     * 
     * @param {CreateFiegePurchaseOrderRequest} createFiegePurchaseOrderRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiegePurchaseOrdersApi
     */
    public fiegePurchaseOrdersCreateFiegePurchaseOrder(createFiegePurchaseOrderRequest: CreateFiegePurchaseOrderRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return FiegePurchaseOrdersApiFp(this.configuration).fiegePurchaseOrdersCreateFiegePurchaseOrder(createFiegePurchaseOrderRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fiegePurchaseOrderId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiegePurchaseOrdersApi
     */
    public fiegePurchaseOrdersGetFiegePurchaseOrderById(fiegePurchaseOrderId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return FiegePurchaseOrdersApiFp(this.configuration).fiegePurchaseOrdersGetFiegePurchaseOrderById(fiegePurchaseOrderId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiegePurchaseOrdersApi
     */
    public fiegePurchaseOrdersGetFiegePurchaseOrders(xVersion?: string, options?: AxiosRequestConfig) {
        return FiegePurchaseOrdersApiFp(this.configuration).fiegePurchaseOrdersGetFiegePurchaseOrders(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fiegePurchaseOrderId 
     * @param {UpdateFiegePurchaseOrderRequest} updateFiegePurchaseOrderRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiegePurchaseOrdersApi
     */
    public fiegePurchaseOrdersUpdateFiegePurchaseOrder(fiegePurchaseOrderId: string, updateFiegePurchaseOrderRequest: UpdateFiegePurchaseOrderRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return FiegePurchaseOrdersApiFp(this.configuration).fiegePurchaseOrdersUpdateFiegePurchaseOrder(fiegePurchaseOrderId, updateFiegePurchaseOrderRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FiegeWebhookListenerApi - axios parameter creator
 * @export
 */
export const FiegeWebhookListenerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {NewMessageCallbackRequest} newMessageCallbackRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fiegeWebhookListenerNewMessageCallback: async (newMessageCallbackRequest: NewMessageCallbackRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newMessageCallbackRequest' is not null or undefined
            assertParamExists('fiegeWebhookListenerNewMessageCallback', 'newMessageCallbackRequest', newMessageCallbackRequest)
            const localVarPath = `/api/FiegeWebhookListener/message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newMessageCallbackRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FiegeWebhookListenerApi - functional programming interface
 * @export
 */
export const FiegeWebhookListenerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FiegeWebhookListenerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {NewMessageCallbackRequest} newMessageCallbackRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fiegeWebhookListenerNewMessageCallback(newMessageCallbackRequest: NewMessageCallbackRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fiegeWebhookListenerNewMessageCallback(newMessageCallbackRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FiegeWebhookListenerApi - factory interface
 * @export
 */
export const FiegeWebhookListenerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FiegeWebhookListenerApiFp(configuration)
    return {
        /**
         * 
         * @param {NewMessageCallbackRequest} newMessageCallbackRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fiegeWebhookListenerNewMessageCallback(newMessageCallbackRequest: NewMessageCallbackRequest, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.fiegeWebhookListenerNewMessageCallback(newMessageCallbackRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FiegeWebhookListenerApi - object-oriented interface
 * @export
 * @class FiegeWebhookListenerApi
 * @extends {BaseAPI}
 */
export class FiegeWebhookListenerApi extends BaseAPI {
    /**
     * 
     * @param {NewMessageCallbackRequest} newMessageCallbackRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiegeWebhookListenerApi
     */
    public fiegeWebhookListenerNewMessageCallback(newMessageCallbackRequest: NewMessageCallbackRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return FiegeWebhookListenerApiFp(this.configuration).fiegeWebhookListenerNewMessageCallback(newMessageCallbackRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InformationGroupsApi - axios parameter creator
 * @export
 */
export const InformationGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} informationGroupId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        informationGroupsGetInformationGroupById: async (informationGroupId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'informationGroupId' is not null or undefined
            assertParamExists('informationGroupsGetInformationGroupById', 'informationGroupId', informationGroupId)
            const localVarPath = `/api/InformationGroups/{informationGroupId}`
                .replace(`{${"informationGroupId"}}`, encodeURIComponent(String(informationGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InformationEntityType} [informationEntityType] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        informationGroupsGetInformationGroups: async (informationEntityType?: InformationEntityType, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InformationGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (informationEntityType !== undefined) {
                localVarQueryParameter['InformationEntityType'] = informationEntityType;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InformationGroupsApi - functional programming interface
 * @export
 */
export const InformationGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InformationGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} informationGroupId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async informationGroupsGetInformationGroupById(informationGroupId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InformationGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.informationGroupsGetInformationGroupById(informationGroupId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InformationEntityType} [informationEntityType] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async informationGroupsGetInformationGroups(informationEntityType?: InformationEntityType, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InformationGroupResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.informationGroupsGetInformationGroups(informationEntityType, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InformationGroupsApi - factory interface
 * @export
 */
export const InformationGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InformationGroupsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} informationGroupId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        informationGroupsGetInformationGroupById(informationGroupId: string, xVersion?: string, options?: any): AxiosPromise<InformationGroupResponse> {
            return localVarFp.informationGroupsGetInformationGroupById(informationGroupId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InformationEntityType} [informationEntityType] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        informationGroupsGetInformationGroups(informationEntityType?: InformationEntityType, xVersion?: string, options?: any): AxiosPromise<Array<InformationGroupResponse>> {
            return localVarFp.informationGroupsGetInformationGroups(informationEntityType, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InformationGroupsApi - object-oriented interface
 * @export
 * @class InformationGroupsApi
 * @extends {BaseAPI}
 */
export class InformationGroupsApi extends BaseAPI {
    /**
     * 
     * @param {string} informationGroupId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InformationGroupsApi
     */
    public informationGroupsGetInformationGroupById(informationGroupId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return InformationGroupsApiFp(this.configuration).informationGroupsGetInformationGroupById(informationGroupId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InformationEntityType} [informationEntityType] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InformationGroupsApi
     */
    public informationGroupsGetInformationGroups(informationEntityType?: InformationEntityType, xVersion?: string, options?: AxiosRequestConfig) {
        return InformationGroupsApiFp(this.configuration).informationGroupsGetInformationGroups(informationEntityType, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IntegrationsApi - axios parameter creator
 * @export
 */
export const IntegrationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} integrationId 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsGetIntegrationById: async (integrationId: string, manufacturerId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('integrationsGetIntegrationById', 'integrationId', integrationId)
            const localVarPath = `/api/Integrations/{integrationId}`
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['manufacturerId'] = manufacturerId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} integrationId 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsGetIntegrationSubModules: async (integrationId: string, manufacturerId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('integrationsGetIntegrationSubModules', 'integrationId', integrationId)
            const localVarPath = `/api/Integrations/{integrationId}/sub-modules`
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['manufacturerId'] = manufacturerId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsGetIntegrations: async (manufacturerId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Integrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['manufacturerId'] = manufacturerId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationsApi - functional programming interface
 * @export
 */
export const IntegrationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} integrationId 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async integrationsGetIntegrationById(integrationId: string, manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.integrationsGetIntegrationById(integrationId, manufacturerId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} integrationId 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async integrationsGetIntegrationSubModules(integrationId: string, manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IntegrationSubModuleResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.integrationsGetIntegrationSubModules(integrationId, manufacturerId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async integrationsGetIntegrations(manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IntegrationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.integrationsGetIntegrations(manufacturerId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntegrationsApi - factory interface
 * @export
 */
export const IntegrationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} integrationId 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsGetIntegrationById(integrationId: string, manufacturerId?: string, xVersion?: string, options?: any): AxiosPromise<IntegrationResponse> {
            return localVarFp.integrationsGetIntegrationById(integrationId, manufacturerId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} integrationId 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsGetIntegrationSubModules(integrationId: string, manufacturerId?: string, xVersion?: string, options?: any): AxiosPromise<Array<IntegrationSubModuleResponse>> {
            return localVarFp.integrationsGetIntegrationSubModules(integrationId, manufacturerId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsGetIntegrations(manufacturerId?: string, xVersion?: string, options?: any): AxiosPromise<Array<IntegrationResponse>> {
            return localVarFp.integrationsGetIntegrations(manufacturerId, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntegrationsApi - object-oriented interface
 * @export
 * @class IntegrationsApi
 * @extends {BaseAPI}
 */
export class IntegrationsApi extends BaseAPI {
    /**
     * 
     * @param {string} integrationId 
     * @param {string} [manufacturerId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public integrationsGetIntegrationById(integrationId: string, manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).integrationsGetIntegrationById(integrationId, manufacturerId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} integrationId 
     * @param {string} [manufacturerId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public integrationsGetIntegrationSubModules(integrationId: string, manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).integrationsGetIntegrationSubModules(integrationId, manufacturerId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public integrationsGetIntegrations(manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).integrationsGetIntegrations(manufacturerId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LegalDocumentsApi - axios parameter creator
 * @export
 */
export const LegalDocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        legalDocumentsGetManufacturerLegalDocuments: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-public/LegalDocuments/manufacturers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LegalDocumentsApi - functional programming interface
 * @export
 */
export const LegalDocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LegalDocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async legalDocumentsGetManufacturerLegalDocuments(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicManufacturerLegalDocument>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.legalDocumentsGetManufacturerLegalDocuments(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LegalDocumentsApi - factory interface
 * @export
 */
export const LegalDocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LegalDocumentsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        legalDocumentsGetManufacturerLegalDocuments(xVersion?: string, options?: any): AxiosPromise<Array<PublicManufacturerLegalDocument>> {
            return localVarFp.legalDocumentsGetManufacturerLegalDocuments(xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LegalDocumentsApi - object-oriented interface
 * @export
 * @class LegalDocumentsApi
 * @extends {BaseAPI}
 */
export class LegalDocumentsApi extends BaseAPI {
    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalDocumentsApi
     */
    public legalDocumentsGetManufacturerLegalDocuments(xVersion?: string, options?: AxiosRequestConfig) {
        return LegalDocumentsApiFp(this.configuration).legalDocumentsGetManufacturerLegalDocuments(xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoyaltyPointProcessingConfigsApi - axios parameter creator
 * @export
 */
export const LoyaltyPointProcessingConfigsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateLoyaltyPointProcessingConfigRequest} createLoyaltyPointProcessingConfigRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPointProcessingConfigsCreateLoyaltyPointProcessingConfig: async (createLoyaltyPointProcessingConfigRequest: CreateLoyaltyPointProcessingConfigRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLoyaltyPointProcessingConfigRequest' is not null or undefined
            assertParamExists('loyaltyPointProcessingConfigsCreateLoyaltyPointProcessingConfig', 'createLoyaltyPointProcessingConfigRequest', createLoyaltyPointProcessingConfigRequest)
            const localVarPath = `/api/LoyaltyPointProcessingConfigs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLoyaltyPointProcessingConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loyaltyPointProcessingConfigId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPointProcessingConfigsDeleteLoyaltyPointProcessingConfig: async (loyaltyPointProcessingConfigId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loyaltyPointProcessingConfigId' is not null or undefined
            assertParamExists('loyaltyPointProcessingConfigsDeleteLoyaltyPointProcessingConfig', 'loyaltyPointProcessingConfigId', loyaltyPointProcessingConfigId)
            const localVarPath = `/api/LoyaltyPointProcessingConfigs/{loyaltyPointProcessingConfigId}`
                .replace(`{${"loyaltyPointProcessingConfigId"}}`, encodeURIComponent(String(loyaltyPointProcessingConfigId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPointProcessingConfigsGetLoyaltyPointHandlerConfigs: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LoyaltyPointProcessingConfigs/handlerConfigs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loyaltyPointProcessingConfigId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPointProcessingConfigsGetLoyaltyPointProcessingConfigById: async (loyaltyPointProcessingConfigId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loyaltyPointProcessingConfigId' is not null or undefined
            assertParamExists('loyaltyPointProcessingConfigsGetLoyaltyPointProcessingConfigById', 'loyaltyPointProcessingConfigId', loyaltyPointProcessingConfigId)
            const localVarPath = `/api/LoyaltyPointProcessingConfigs/{loyaltyPointProcessingConfigId}`
                .replace(`{${"loyaltyPointProcessingConfigId"}}`, encodeURIComponent(String(loyaltyPointProcessingConfigId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPointProcessingConfigsGetLoyaltyPointProcessingConfigs: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LoyaltyPointProcessingConfigs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loyaltyPointProcessingConfigId 
         * @param {UpdateLoyaltyPointProcessingConfigRequest} updateLoyaltyPointProcessingConfigRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPointProcessingConfigsUpdateLoyaltyPointProcessingConfig: async (loyaltyPointProcessingConfigId: string, updateLoyaltyPointProcessingConfigRequest: UpdateLoyaltyPointProcessingConfigRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loyaltyPointProcessingConfigId' is not null or undefined
            assertParamExists('loyaltyPointProcessingConfigsUpdateLoyaltyPointProcessingConfig', 'loyaltyPointProcessingConfigId', loyaltyPointProcessingConfigId)
            // verify required parameter 'updateLoyaltyPointProcessingConfigRequest' is not null or undefined
            assertParamExists('loyaltyPointProcessingConfigsUpdateLoyaltyPointProcessingConfig', 'updateLoyaltyPointProcessingConfigRequest', updateLoyaltyPointProcessingConfigRequest)
            const localVarPath = `/api/LoyaltyPointProcessingConfigs/{loyaltyPointProcessingConfigId}`
                .replace(`{${"loyaltyPointProcessingConfigId"}}`, encodeURIComponent(String(loyaltyPointProcessingConfigId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLoyaltyPointProcessingConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoyaltyPointProcessingConfigsApi - functional programming interface
 * @export
 */
export const LoyaltyPointProcessingConfigsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoyaltyPointProcessingConfigsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateLoyaltyPointProcessingConfigRequest} createLoyaltyPointProcessingConfigRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loyaltyPointProcessingConfigsCreateLoyaltyPointProcessingConfig(createLoyaltyPointProcessingConfigRequest: CreateLoyaltyPointProcessingConfigRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoyaltyPointProcessingConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loyaltyPointProcessingConfigsCreateLoyaltyPointProcessingConfig(createLoyaltyPointProcessingConfigRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} loyaltyPointProcessingConfigId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loyaltyPointProcessingConfigsDeleteLoyaltyPointProcessingConfig(loyaltyPointProcessingConfigId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loyaltyPointProcessingConfigsDeleteLoyaltyPointProcessingConfig(loyaltyPointProcessingConfigId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loyaltyPointProcessingConfigsGetLoyaltyPointHandlerConfigs(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LoyaltyPointHandlerConfig>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loyaltyPointProcessingConfigsGetLoyaltyPointHandlerConfigs(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} loyaltyPointProcessingConfigId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loyaltyPointProcessingConfigsGetLoyaltyPointProcessingConfigById(loyaltyPointProcessingConfigId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoyaltyPointProcessingConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loyaltyPointProcessingConfigsGetLoyaltyPointProcessingConfigById(loyaltyPointProcessingConfigId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loyaltyPointProcessingConfigsGetLoyaltyPointProcessingConfigs(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LoyaltyPointProcessingConfigResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loyaltyPointProcessingConfigsGetLoyaltyPointProcessingConfigs(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} loyaltyPointProcessingConfigId 
         * @param {UpdateLoyaltyPointProcessingConfigRequest} updateLoyaltyPointProcessingConfigRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loyaltyPointProcessingConfigsUpdateLoyaltyPointProcessingConfig(loyaltyPointProcessingConfigId: string, updateLoyaltyPointProcessingConfigRequest: UpdateLoyaltyPointProcessingConfigRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loyaltyPointProcessingConfigsUpdateLoyaltyPointProcessingConfig(loyaltyPointProcessingConfigId, updateLoyaltyPointProcessingConfigRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoyaltyPointProcessingConfigsApi - factory interface
 * @export
 */
export const LoyaltyPointProcessingConfigsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoyaltyPointProcessingConfigsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateLoyaltyPointProcessingConfigRequest} createLoyaltyPointProcessingConfigRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPointProcessingConfigsCreateLoyaltyPointProcessingConfig(createLoyaltyPointProcessingConfigRequest: CreateLoyaltyPointProcessingConfigRequest, xVersion?: string, options?: any): AxiosPromise<LoyaltyPointProcessingConfigResponse> {
            return localVarFp.loyaltyPointProcessingConfigsCreateLoyaltyPointProcessingConfig(createLoyaltyPointProcessingConfigRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} loyaltyPointProcessingConfigId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPointProcessingConfigsDeleteLoyaltyPointProcessingConfig(loyaltyPointProcessingConfigId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.loyaltyPointProcessingConfigsDeleteLoyaltyPointProcessingConfig(loyaltyPointProcessingConfigId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPointProcessingConfigsGetLoyaltyPointHandlerConfigs(xVersion?: string, options?: any): AxiosPromise<Array<LoyaltyPointHandlerConfig>> {
            return localVarFp.loyaltyPointProcessingConfigsGetLoyaltyPointHandlerConfigs(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} loyaltyPointProcessingConfigId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPointProcessingConfigsGetLoyaltyPointProcessingConfigById(loyaltyPointProcessingConfigId: string, xVersion?: string, options?: any): AxiosPromise<LoyaltyPointProcessingConfigResponse> {
            return localVarFp.loyaltyPointProcessingConfigsGetLoyaltyPointProcessingConfigById(loyaltyPointProcessingConfigId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPointProcessingConfigsGetLoyaltyPointProcessingConfigs(xVersion?: string, options?: any): AxiosPromise<Array<LoyaltyPointProcessingConfigResponse>> {
            return localVarFp.loyaltyPointProcessingConfigsGetLoyaltyPointProcessingConfigs(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} loyaltyPointProcessingConfigId 
         * @param {UpdateLoyaltyPointProcessingConfigRequest} updateLoyaltyPointProcessingConfigRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPointProcessingConfigsUpdateLoyaltyPointProcessingConfig(loyaltyPointProcessingConfigId: string, updateLoyaltyPointProcessingConfigRequest: UpdateLoyaltyPointProcessingConfigRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.loyaltyPointProcessingConfigsUpdateLoyaltyPointProcessingConfig(loyaltyPointProcessingConfigId, updateLoyaltyPointProcessingConfigRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoyaltyPointProcessingConfigsApi - object-oriented interface
 * @export
 * @class LoyaltyPointProcessingConfigsApi
 * @extends {BaseAPI}
 */
export class LoyaltyPointProcessingConfigsApi extends BaseAPI {
    /**
     * 
     * @param {CreateLoyaltyPointProcessingConfigRequest} createLoyaltyPointProcessingConfigRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointProcessingConfigsApi
     */
    public loyaltyPointProcessingConfigsCreateLoyaltyPointProcessingConfig(createLoyaltyPointProcessingConfigRequest: CreateLoyaltyPointProcessingConfigRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return LoyaltyPointProcessingConfigsApiFp(this.configuration).loyaltyPointProcessingConfigsCreateLoyaltyPointProcessingConfig(createLoyaltyPointProcessingConfigRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} loyaltyPointProcessingConfigId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointProcessingConfigsApi
     */
    public loyaltyPointProcessingConfigsDeleteLoyaltyPointProcessingConfig(loyaltyPointProcessingConfigId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return LoyaltyPointProcessingConfigsApiFp(this.configuration).loyaltyPointProcessingConfigsDeleteLoyaltyPointProcessingConfig(loyaltyPointProcessingConfigId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointProcessingConfigsApi
     */
    public loyaltyPointProcessingConfigsGetLoyaltyPointHandlerConfigs(xVersion?: string, options?: AxiosRequestConfig) {
        return LoyaltyPointProcessingConfigsApiFp(this.configuration).loyaltyPointProcessingConfigsGetLoyaltyPointHandlerConfigs(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} loyaltyPointProcessingConfigId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointProcessingConfigsApi
     */
    public loyaltyPointProcessingConfigsGetLoyaltyPointProcessingConfigById(loyaltyPointProcessingConfigId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return LoyaltyPointProcessingConfigsApiFp(this.configuration).loyaltyPointProcessingConfigsGetLoyaltyPointProcessingConfigById(loyaltyPointProcessingConfigId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointProcessingConfigsApi
     */
    public loyaltyPointProcessingConfigsGetLoyaltyPointProcessingConfigs(xVersion?: string, options?: AxiosRequestConfig) {
        return LoyaltyPointProcessingConfigsApiFp(this.configuration).loyaltyPointProcessingConfigsGetLoyaltyPointProcessingConfigs(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} loyaltyPointProcessingConfigId 
     * @param {UpdateLoyaltyPointProcessingConfigRequest} updateLoyaltyPointProcessingConfigRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointProcessingConfigsApi
     */
    public loyaltyPointProcessingConfigsUpdateLoyaltyPointProcessingConfig(loyaltyPointProcessingConfigId: string, updateLoyaltyPointProcessingConfigRequest: UpdateLoyaltyPointProcessingConfigRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return LoyaltyPointProcessingConfigsApiFp(this.configuration).loyaltyPointProcessingConfigsUpdateLoyaltyPointProcessingConfig(loyaltyPointProcessingConfigId, updateLoyaltyPointProcessingConfigRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoyaltyPointTransactionsApi - axios parameter creator
 * @export
 */
export const LoyaltyPointTransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateLoyaltyPointTransactionRequest} createLoyaltyPointTransactionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPointTransactionsCreateLoyaltyPointTransaction: async (createLoyaltyPointTransactionRequest: CreateLoyaltyPointTransactionRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLoyaltyPointTransactionRequest' is not null or undefined
            assertParamExists('loyaltyPointTransactionsCreateLoyaltyPointTransaction', 'createLoyaltyPointTransactionRequest', createLoyaltyPointTransactionRequest)
            const localVarPath = `/api/LoyaltyPointTransactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLoyaltyPointTransactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loyaltyPointTransactionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPointTransactionsDeleteLoyaltyPointTransaction: async (loyaltyPointTransactionId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loyaltyPointTransactionId' is not null or undefined
            assertParamExists('loyaltyPointTransactionsDeleteLoyaltyPointTransaction', 'loyaltyPointTransactionId', loyaltyPointTransactionId)
            const localVarPath = `/api/LoyaltyPointTransactions/{loyaltyPointTransactionId}`
                .replace(`{${"loyaltyPointTransactionId"}}`, encodeURIComponent(String(loyaltyPointTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [customerId] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPointTransactionsGetLoyaltyPointAggregation: async (customerId?: string, dateFrom?: string, dateTo?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LoyaltyPointTransactions/aggregation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['CustomerId'] = customerId;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['DateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['DateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loyaltyPointTransactionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPointTransactionsGetLoyaltyPointTransactionById: async (loyaltyPointTransactionId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loyaltyPointTransactionId' is not null or undefined
            assertParamExists('loyaltyPointTransactionsGetLoyaltyPointTransactionById', 'loyaltyPointTransactionId', loyaltyPointTransactionId)
            const localVarPath = `/api/LoyaltyPointTransactions/{loyaltyPointTransactionId}`
                .replace(`{${"loyaltyPointTransactionId"}}`, encodeURIComponent(String(loyaltyPointTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [customerId] 
         * @param {string} [orderId] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPointTransactionsGetLoyaltyPointTransactions: async (customerId?: string, orderId?: string, dateFrom?: string, dateTo?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LoyaltyPointTransactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['CustomerId'] = customerId;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['OrderId'] = orderId;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['DateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['DateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loyaltyPointTransactionId 
         * @param {UpdateLoyaltyPointTransactionRequest} updateLoyaltyPointTransactionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPointTransactionsUpdateLoyaltyPointTransaction: async (loyaltyPointTransactionId: string, updateLoyaltyPointTransactionRequest: UpdateLoyaltyPointTransactionRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loyaltyPointTransactionId' is not null or undefined
            assertParamExists('loyaltyPointTransactionsUpdateLoyaltyPointTransaction', 'loyaltyPointTransactionId', loyaltyPointTransactionId)
            // verify required parameter 'updateLoyaltyPointTransactionRequest' is not null or undefined
            assertParamExists('loyaltyPointTransactionsUpdateLoyaltyPointTransaction', 'updateLoyaltyPointTransactionRequest', updateLoyaltyPointTransactionRequest)
            const localVarPath = `/api/LoyaltyPointTransactions/{loyaltyPointTransactionId}`
                .replace(`{${"loyaltyPointTransactionId"}}`, encodeURIComponent(String(loyaltyPointTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLoyaltyPointTransactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoyaltyPointTransactionsApi - functional programming interface
 * @export
 */
export const LoyaltyPointTransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoyaltyPointTransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateLoyaltyPointTransactionRequest} createLoyaltyPointTransactionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loyaltyPointTransactionsCreateLoyaltyPointTransaction(createLoyaltyPointTransactionRequest: CreateLoyaltyPointTransactionRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoyaltyPointTransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loyaltyPointTransactionsCreateLoyaltyPointTransaction(createLoyaltyPointTransactionRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} loyaltyPointTransactionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loyaltyPointTransactionsDeleteLoyaltyPointTransaction(loyaltyPointTransactionId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loyaltyPointTransactionsDeleteLoyaltyPointTransaction(loyaltyPointTransactionId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [customerId] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loyaltyPointTransactionsGetLoyaltyPointAggregation(customerId?: string, dateFrom?: string, dateTo?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoyaltyPointAggregationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loyaltyPointTransactionsGetLoyaltyPointAggregation(customerId, dateFrom, dateTo, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} loyaltyPointTransactionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loyaltyPointTransactionsGetLoyaltyPointTransactionById(loyaltyPointTransactionId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoyaltyPointTransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loyaltyPointTransactionsGetLoyaltyPointTransactionById(loyaltyPointTransactionId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [customerId] 
         * @param {string} [orderId] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loyaltyPointTransactionsGetLoyaltyPointTransactions(customerId?: string, orderId?: string, dateFrom?: string, dateTo?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LoyaltyPointTransactionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loyaltyPointTransactionsGetLoyaltyPointTransactions(customerId, orderId, dateFrom, dateTo, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} loyaltyPointTransactionId 
         * @param {UpdateLoyaltyPointTransactionRequest} updateLoyaltyPointTransactionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loyaltyPointTransactionsUpdateLoyaltyPointTransaction(loyaltyPointTransactionId: string, updateLoyaltyPointTransactionRequest: UpdateLoyaltyPointTransactionRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loyaltyPointTransactionsUpdateLoyaltyPointTransaction(loyaltyPointTransactionId, updateLoyaltyPointTransactionRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoyaltyPointTransactionsApi - factory interface
 * @export
 */
export const LoyaltyPointTransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoyaltyPointTransactionsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateLoyaltyPointTransactionRequest} createLoyaltyPointTransactionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPointTransactionsCreateLoyaltyPointTransaction(createLoyaltyPointTransactionRequest: CreateLoyaltyPointTransactionRequest, xVersion?: string, options?: any): AxiosPromise<LoyaltyPointTransactionResponse> {
            return localVarFp.loyaltyPointTransactionsCreateLoyaltyPointTransaction(createLoyaltyPointTransactionRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} loyaltyPointTransactionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPointTransactionsDeleteLoyaltyPointTransaction(loyaltyPointTransactionId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.loyaltyPointTransactionsDeleteLoyaltyPointTransaction(loyaltyPointTransactionId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [customerId] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPointTransactionsGetLoyaltyPointAggregation(customerId?: string, dateFrom?: string, dateTo?: string, xVersion?: string, options?: any): AxiosPromise<LoyaltyPointAggregationResponse> {
            return localVarFp.loyaltyPointTransactionsGetLoyaltyPointAggregation(customerId, dateFrom, dateTo, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} loyaltyPointTransactionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPointTransactionsGetLoyaltyPointTransactionById(loyaltyPointTransactionId: string, xVersion?: string, options?: any): AxiosPromise<LoyaltyPointTransactionResponse> {
            return localVarFp.loyaltyPointTransactionsGetLoyaltyPointTransactionById(loyaltyPointTransactionId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [customerId] 
         * @param {string} [orderId] 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPointTransactionsGetLoyaltyPointTransactions(customerId?: string, orderId?: string, dateFrom?: string, dateTo?: string, xVersion?: string, options?: any): AxiosPromise<Array<LoyaltyPointTransactionResponse>> {
            return localVarFp.loyaltyPointTransactionsGetLoyaltyPointTransactions(customerId, orderId, dateFrom, dateTo, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} loyaltyPointTransactionId 
         * @param {UpdateLoyaltyPointTransactionRequest} updateLoyaltyPointTransactionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPointTransactionsUpdateLoyaltyPointTransaction(loyaltyPointTransactionId: string, updateLoyaltyPointTransactionRequest: UpdateLoyaltyPointTransactionRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.loyaltyPointTransactionsUpdateLoyaltyPointTransaction(loyaltyPointTransactionId, updateLoyaltyPointTransactionRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoyaltyPointTransactionsApi - object-oriented interface
 * @export
 * @class LoyaltyPointTransactionsApi
 * @extends {BaseAPI}
 */
export class LoyaltyPointTransactionsApi extends BaseAPI {
    /**
     * 
     * @param {CreateLoyaltyPointTransactionRequest} createLoyaltyPointTransactionRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointTransactionsApi
     */
    public loyaltyPointTransactionsCreateLoyaltyPointTransaction(createLoyaltyPointTransactionRequest: CreateLoyaltyPointTransactionRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return LoyaltyPointTransactionsApiFp(this.configuration).loyaltyPointTransactionsCreateLoyaltyPointTransaction(createLoyaltyPointTransactionRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} loyaltyPointTransactionId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointTransactionsApi
     */
    public loyaltyPointTransactionsDeleteLoyaltyPointTransaction(loyaltyPointTransactionId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return LoyaltyPointTransactionsApiFp(this.configuration).loyaltyPointTransactionsDeleteLoyaltyPointTransaction(loyaltyPointTransactionId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [customerId] 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointTransactionsApi
     */
    public loyaltyPointTransactionsGetLoyaltyPointAggregation(customerId?: string, dateFrom?: string, dateTo?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return LoyaltyPointTransactionsApiFp(this.configuration).loyaltyPointTransactionsGetLoyaltyPointAggregation(customerId, dateFrom, dateTo, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} loyaltyPointTransactionId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointTransactionsApi
     */
    public loyaltyPointTransactionsGetLoyaltyPointTransactionById(loyaltyPointTransactionId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return LoyaltyPointTransactionsApiFp(this.configuration).loyaltyPointTransactionsGetLoyaltyPointTransactionById(loyaltyPointTransactionId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [customerId] 
     * @param {string} [orderId] 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointTransactionsApi
     */
    public loyaltyPointTransactionsGetLoyaltyPointTransactions(customerId?: string, orderId?: string, dateFrom?: string, dateTo?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return LoyaltyPointTransactionsApiFp(this.configuration).loyaltyPointTransactionsGetLoyaltyPointTransactions(customerId, orderId, dateFrom, dateTo, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} loyaltyPointTransactionId 
     * @param {UpdateLoyaltyPointTransactionRequest} updateLoyaltyPointTransactionRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointTransactionsApi
     */
    public loyaltyPointTransactionsUpdateLoyaltyPointTransaction(loyaltyPointTransactionId: string, updateLoyaltyPointTransactionRequest: UpdateLoyaltyPointTransactionRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return LoyaltyPointTransactionsApiFp(this.configuration).loyaltyPointTransactionsUpdateLoyaltyPointTransaction(loyaltyPointTransactionId, updateLoyaltyPointTransactionRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoyaltyPointsApi - axios parameter creator
 * @export
 */
export const LoyaltyPointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [orderTotalAmount] 
         * @param {boolean} [calculateProductPrice] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPointsCalculateExpectedOrderLoyaltyPoints: async (orderTotalAmount?: number, calculateProductPrice?: boolean, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-public/LoyaltyPoints/expectedOrderPoints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderTotalAmount !== undefined) {
                localVarQueryParameter['orderTotalAmount'] = orderTotalAmount;
            }

            if (calculateProductPrice !== undefined) {
                localVarQueryParameter['calculateProductPrice'] = calculateProductPrice;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoyaltyPointsApi - functional programming interface
 * @export
 */
export const LoyaltyPointsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoyaltyPointsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [orderTotalAmount] 
         * @param {boolean} [calculateProductPrice] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loyaltyPointsCalculateExpectedOrderLoyaltyPoints(orderTotalAmount?: number, calculateProductPrice?: boolean, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loyaltyPointsCalculateExpectedOrderLoyaltyPoints(orderTotalAmount, calculateProductPrice, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoyaltyPointsApi - factory interface
 * @export
 */
export const LoyaltyPointsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoyaltyPointsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [orderTotalAmount] 
         * @param {boolean} [calculateProductPrice] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyPointsCalculateExpectedOrderLoyaltyPoints(orderTotalAmount?: number, calculateProductPrice?: boolean, xVersion?: string, options?: any): AxiosPromise<number> {
            return localVarFp.loyaltyPointsCalculateExpectedOrderLoyaltyPoints(orderTotalAmount, calculateProductPrice, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoyaltyPointsApi - object-oriented interface
 * @export
 * @class LoyaltyPointsApi
 * @extends {BaseAPI}
 */
export class LoyaltyPointsApi extends BaseAPI {
    /**
     * 
     * @param {number} [orderTotalAmount] 
     * @param {boolean} [calculateProductPrice] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointsApi
     */
    public loyaltyPointsCalculateExpectedOrderLoyaltyPoints(orderTotalAmount?: number, calculateProductPrice?: boolean, xVersion?: string, options?: AxiosRequestConfig) {
        return LoyaltyPointsApiFp(this.configuration).loyaltyPointsCalculateExpectedOrderLoyaltyPoints(orderTotalAmount, calculateProductPrice, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoyaltyRanksApi - axios parameter creator
 * @export
 */
export const LoyaltyRanksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateLoyaltyRankRequest} createLoyaltyRankRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyRanksCreateLoyaltyRank: async (createLoyaltyRankRequest: CreateLoyaltyRankRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLoyaltyRankRequest' is not null or undefined
            assertParamExists('loyaltyRanksCreateLoyaltyRank', 'createLoyaltyRankRequest', createLoyaltyRankRequest)
            const localVarPath = `/api/LoyaltyRanks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLoyaltyRankRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loyaltyRankId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyRanksDeleteLoyaltyRank: async (loyaltyRankId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loyaltyRankId' is not null or undefined
            assertParamExists('loyaltyRanksDeleteLoyaltyRank', 'loyaltyRankId', loyaltyRankId)
            const localVarPath = `/api/LoyaltyRanks/{loyaltyRankId}`
                .replace(`{${"loyaltyRankId"}}`, encodeURIComponent(String(loyaltyRankId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loyaltyRankId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyRanksGetLoyaltyRankById: async (loyaltyRankId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loyaltyRankId' is not null or undefined
            assertParamExists('loyaltyRanksGetLoyaltyRankById', 'loyaltyRankId', loyaltyRankId)
            const localVarPath = `/api/LoyaltyRanks/{loyaltyRankId}`
                .replace(`{${"loyaltyRankId"}}`, encodeURIComponent(String(loyaltyRankId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyRanksGetLoyaltyRanks: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LoyaltyRanks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loyaltyRankId 
         * @param {UpdateLoyaltyRankRequest} updateLoyaltyRankRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyRanksUpdateLoyaltyRank: async (loyaltyRankId: string, updateLoyaltyRankRequest: UpdateLoyaltyRankRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loyaltyRankId' is not null or undefined
            assertParamExists('loyaltyRanksUpdateLoyaltyRank', 'loyaltyRankId', loyaltyRankId)
            // verify required parameter 'updateLoyaltyRankRequest' is not null or undefined
            assertParamExists('loyaltyRanksUpdateLoyaltyRank', 'updateLoyaltyRankRequest', updateLoyaltyRankRequest)
            const localVarPath = `/api/LoyaltyRanks/{loyaltyRankId}`
                .replace(`{${"loyaltyRankId"}}`, encodeURIComponent(String(loyaltyRankId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLoyaltyRankRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoyaltyRanksApi - functional programming interface
 * @export
 */
export const LoyaltyRanksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoyaltyRanksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateLoyaltyRankRequest} createLoyaltyRankRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loyaltyRanksCreateLoyaltyRank(createLoyaltyRankRequest: CreateLoyaltyRankRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoyaltyRankResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loyaltyRanksCreateLoyaltyRank(createLoyaltyRankRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} loyaltyRankId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loyaltyRanksDeleteLoyaltyRank(loyaltyRankId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loyaltyRanksDeleteLoyaltyRank(loyaltyRankId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} loyaltyRankId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loyaltyRanksGetLoyaltyRankById(loyaltyRankId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoyaltyRankResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loyaltyRanksGetLoyaltyRankById(loyaltyRankId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loyaltyRanksGetLoyaltyRanks(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LoyaltyRankResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loyaltyRanksGetLoyaltyRanks(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} loyaltyRankId 
         * @param {UpdateLoyaltyRankRequest} updateLoyaltyRankRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loyaltyRanksUpdateLoyaltyRank(loyaltyRankId: string, updateLoyaltyRankRequest: UpdateLoyaltyRankRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loyaltyRanksUpdateLoyaltyRank(loyaltyRankId, updateLoyaltyRankRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoyaltyRanksApi - factory interface
 * @export
 */
export const LoyaltyRanksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoyaltyRanksApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateLoyaltyRankRequest} createLoyaltyRankRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyRanksCreateLoyaltyRank(createLoyaltyRankRequest: CreateLoyaltyRankRequest, xVersion?: string, options?: any): AxiosPromise<LoyaltyRankResponse> {
            return localVarFp.loyaltyRanksCreateLoyaltyRank(createLoyaltyRankRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} loyaltyRankId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyRanksDeleteLoyaltyRank(loyaltyRankId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.loyaltyRanksDeleteLoyaltyRank(loyaltyRankId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} loyaltyRankId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyRanksGetLoyaltyRankById(loyaltyRankId: string, xVersion?: string, options?: any): AxiosPromise<LoyaltyRankResponse> {
            return localVarFp.loyaltyRanksGetLoyaltyRankById(loyaltyRankId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyRanksGetLoyaltyRanks(xVersion?: string, options?: any): AxiosPromise<Array<LoyaltyRankResponse>> {
            return localVarFp.loyaltyRanksGetLoyaltyRanks(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} loyaltyRankId 
         * @param {UpdateLoyaltyRankRequest} updateLoyaltyRankRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loyaltyRanksUpdateLoyaltyRank(loyaltyRankId: string, updateLoyaltyRankRequest: UpdateLoyaltyRankRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.loyaltyRanksUpdateLoyaltyRank(loyaltyRankId, updateLoyaltyRankRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoyaltyRanksApi - object-oriented interface
 * @export
 * @class LoyaltyRanksApi
 * @extends {BaseAPI}
 */
export class LoyaltyRanksApi extends BaseAPI {
    /**
     * 
     * @param {CreateLoyaltyRankRequest} createLoyaltyRankRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyRanksApi
     */
    public loyaltyRanksCreateLoyaltyRank(createLoyaltyRankRequest: CreateLoyaltyRankRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return LoyaltyRanksApiFp(this.configuration).loyaltyRanksCreateLoyaltyRank(createLoyaltyRankRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} loyaltyRankId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyRanksApi
     */
    public loyaltyRanksDeleteLoyaltyRank(loyaltyRankId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return LoyaltyRanksApiFp(this.configuration).loyaltyRanksDeleteLoyaltyRank(loyaltyRankId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} loyaltyRankId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyRanksApi
     */
    public loyaltyRanksGetLoyaltyRankById(loyaltyRankId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return LoyaltyRanksApiFp(this.configuration).loyaltyRanksGetLoyaltyRankById(loyaltyRankId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyRanksApi
     */
    public loyaltyRanksGetLoyaltyRanks(xVersion?: string, options?: AxiosRequestConfig) {
        return LoyaltyRanksApiFp(this.configuration).loyaltyRanksGetLoyaltyRanks(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} loyaltyRankId 
     * @param {UpdateLoyaltyRankRequest} updateLoyaltyRankRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyRanksApi
     */
    public loyaltyRanksUpdateLoyaltyRank(loyaltyRankId: string, updateLoyaltyRankRequest: UpdateLoyaltyRankRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return LoyaltyRanksApiFp(this.configuration).loyaltyRanksUpdateLoyaltyRank(loyaltyRankId, updateLoyaltyRankRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ManufacturerConditionsApi - axios parameter creator
 * @export
 */
export const ManufacturerConditionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateManufacturerConditionRequest} createManufacturerConditionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerConditionsCreateManufacturerCondition: async (createManufacturerConditionRequest: CreateManufacturerConditionRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createManufacturerConditionRequest' is not null or undefined
            assertParamExists('manufacturerConditionsCreateManufacturerCondition', 'createManufacturerConditionRequest', createManufacturerConditionRequest)
            const localVarPath = `/api/ManufacturerConditions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createManufacturerConditionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manufacturerConditionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerConditionsDeleteManufacturerCondition: async (manufacturerConditionId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerConditionId' is not null or undefined
            assertParamExists('manufacturerConditionsDeleteManufacturerCondition', 'manufacturerConditionId', manufacturerConditionId)
            const localVarPath = `/api/ManufacturerConditions/{manufacturerConditionId}`
                .replace(`{${"manufacturerConditionId"}}`, encodeURIComponent(String(manufacturerConditionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manufacturerConditionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerConditionsGetManufacturerConditionById: async (manufacturerConditionId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerConditionId' is not null or undefined
            assertParamExists('manufacturerConditionsGetManufacturerConditionById', 'manufacturerConditionId', manufacturerConditionId)
            const localVarPath = `/api/ManufacturerConditions/{manufacturerConditionId}`
                .replace(`{${"manufacturerConditionId"}}`, encodeURIComponent(String(manufacturerConditionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerConditionsGetManufacturerConditions: async (manufacturerId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ManufacturerConditions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manufacturerConditionId 
         * @param {UpdateManufacturerConditionRequest} updateManufacturerConditionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerConditionsUpdateManufacturerCondition: async (manufacturerConditionId: string, updateManufacturerConditionRequest: UpdateManufacturerConditionRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerConditionId' is not null or undefined
            assertParamExists('manufacturerConditionsUpdateManufacturerCondition', 'manufacturerConditionId', manufacturerConditionId)
            // verify required parameter 'updateManufacturerConditionRequest' is not null or undefined
            assertParamExists('manufacturerConditionsUpdateManufacturerCondition', 'updateManufacturerConditionRequest', updateManufacturerConditionRequest)
            const localVarPath = `/api/ManufacturerConditions/{manufacturerConditionId}`
                .replace(`{${"manufacturerConditionId"}}`, encodeURIComponent(String(manufacturerConditionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateManufacturerConditionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManufacturerConditionsApi - functional programming interface
 * @export
 */
export const ManufacturerConditionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManufacturerConditionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateManufacturerConditionRequest} createManufacturerConditionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerConditionsCreateManufacturerCondition(createManufacturerConditionRequest: CreateManufacturerConditionRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerConditionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturerConditionsCreateManufacturerCondition(createManufacturerConditionRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manufacturerConditionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerConditionsDeleteManufacturerCondition(manufacturerConditionId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturerConditionsDeleteManufacturerCondition(manufacturerConditionId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manufacturerConditionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerConditionsGetManufacturerConditionById(manufacturerConditionId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerConditionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturerConditionsGetManufacturerConditionById(manufacturerConditionId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerConditionsGetManufacturerConditions(manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ManufacturerConditionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturerConditionsGetManufacturerConditions(manufacturerId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manufacturerConditionId 
         * @param {UpdateManufacturerConditionRequest} updateManufacturerConditionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerConditionsUpdateManufacturerCondition(manufacturerConditionId: string, updateManufacturerConditionRequest: UpdateManufacturerConditionRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturerConditionsUpdateManufacturerCondition(manufacturerConditionId, updateManufacturerConditionRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManufacturerConditionsApi - factory interface
 * @export
 */
export const ManufacturerConditionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManufacturerConditionsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateManufacturerConditionRequest} createManufacturerConditionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerConditionsCreateManufacturerCondition(createManufacturerConditionRequest: CreateManufacturerConditionRequest, xVersion?: string, options?: any): AxiosPromise<ManufacturerConditionResponse> {
            return localVarFp.manufacturerConditionsCreateManufacturerCondition(createManufacturerConditionRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manufacturerConditionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerConditionsDeleteManufacturerCondition(manufacturerConditionId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.manufacturerConditionsDeleteManufacturerCondition(manufacturerConditionId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manufacturerConditionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerConditionsGetManufacturerConditionById(manufacturerConditionId: string, xVersion?: string, options?: any): AxiosPromise<ManufacturerConditionResponse> {
            return localVarFp.manufacturerConditionsGetManufacturerConditionById(manufacturerConditionId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerConditionsGetManufacturerConditions(manufacturerId?: string, xVersion?: string, options?: any): AxiosPromise<Array<ManufacturerConditionResponse>> {
            return localVarFp.manufacturerConditionsGetManufacturerConditions(manufacturerId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manufacturerConditionId 
         * @param {UpdateManufacturerConditionRequest} updateManufacturerConditionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerConditionsUpdateManufacturerCondition(manufacturerConditionId: string, updateManufacturerConditionRequest: UpdateManufacturerConditionRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.manufacturerConditionsUpdateManufacturerCondition(manufacturerConditionId, updateManufacturerConditionRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManufacturerConditionsApi - object-oriented interface
 * @export
 * @class ManufacturerConditionsApi
 * @extends {BaseAPI}
 */
export class ManufacturerConditionsApi extends BaseAPI {
    /**
     * 
     * @param {CreateManufacturerConditionRequest} createManufacturerConditionRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerConditionsApi
     */
    public manufacturerConditionsCreateManufacturerCondition(createManufacturerConditionRequest: CreateManufacturerConditionRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturerConditionsApiFp(this.configuration).manufacturerConditionsCreateManufacturerCondition(createManufacturerConditionRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manufacturerConditionId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerConditionsApi
     */
    public manufacturerConditionsDeleteManufacturerCondition(manufacturerConditionId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturerConditionsApiFp(this.configuration).manufacturerConditionsDeleteManufacturerCondition(manufacturerConditionId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manufacturerConditionId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerConditionsApi
     */
    public manufacturerConditionsGetManufacturerConditionById(manufacturerConditionId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturerConditionsApiFp(this.configuration).manufacturerConditionsGetManufacturerConditionById(manufacturerConditionId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerConditionsApi
     */
    public manufacturerConditionsGetManufacturerConditions(manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturerConditionsApiFp(this.configuration).manufacturerConditionsGetManufacturerConditions(manufacturerId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manufacturerConditionId 
     * @param {UpdateManufacturerConditionRequest} updateManufacturerConditionRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerConditionsApi
     */
    public manufacturerConditionsUpdateManufacturerCondition(manufacturerConditionId: string, updateManufacturerConditionRequest: UpdateManufacturerConditionRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturerConditionsApiFp(this.configuration).manufacturerConditionsUpdateManufacturerCondition(manufacturerConditionId, updateManufacturerConditionRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ManufacturerContactsApi - axios parameter creator
 * @export
 */
export const ManufacturerContactsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateManufacturerContactRequest} createManufacturerContactRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerContactsCreateManufacturerContact: async (createManufacturerContactRequest: CreateManufacturerContactRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createManufacturerContactRequest' is not null or undefined
            assertParamExists('manufacturerContactsCreateManufacturerContact', 'createManufacturerContactRequest', createManufacturerContactRequest)
            const localVarPath = `/api/ManufacturerContacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createManufacturerContactRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manufacturerContactId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerContactsDeleteManufacturerContact: async (manufacturerContactId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerContactId' is not null or undefined
            assertParamExists('manufacturerContactsDeleteManufacturerContact', 'manufacturerContactId', manufacturerContactId)
            const localVarPath = `/api/ManufacturerContacts/{manufacturerContactId}`
                .replace(`{${"manufacturerContactId"}}`, encodeURIComponent(String(manufacturerContactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manufacturerContactId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerContactsGetManufacturerContact: async (manufacturerContactId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerContactId' is not null or undefined
            assertParamExists('manufacturerContactsGetManufacturerContact', 'manufacturerContactId', manufacturerContactId)
            const localVarPath = `/api/ManufacturerContacts/{manufacturerContactId}`
                .replace(`{${"manufacturerContactId"}}`, encodeURIComponent(String(manufacturerContactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerContactsGetManufacturerContacts: async (manufacturerId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ManufacturerContacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['manufacturerId'] = manufacturerId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manufacturerContactId 
         * @param {UpdateManufacturerContactRequest} updateManufacturerContactRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerContactsUpdateManufacturerContact: async (manufacturerContactId: string, updateManufacturerContactRequest: UpdateManufacturerContactRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerContactId' is not null or undefined
            assertParamExists('manufacturerContactsUpdateManufacturerContact', 'manufacturerContactId', manufacturerContactId)
            // verify required parameter 'updateManufacturerContactRequest' is not null or undefined
            assertParamExists('manufacturerContactsUpdateManufacturerContact', 'updateManufacturerContactRequest', updateManufacturerContactRequest)
            const localVarPath = `/api/ManufacturerContacts/{manufacturerContactId}`
                .replace(`{${"manufacturerContactId"}}`, encodeURIComponent(String(manufacturerContactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateManufacturerContactRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManufacturerContactsApi - functional programming interface
 * @export
 */
export const ManufacturerContactsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManufacturerContactsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateManufacturerContactRequest} createManufacturerContactRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerContactsCreateManufacturerContact(createManufacturerContactRequest: CreateManufacturerContactRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerContactResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturerContactsCreateManufacturerContact(createManufacturerContactRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manufacturerContactId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerContactsDeleteManufacturerContact(manufacturerContactId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturerContactsDeleteManufacturerContact(manufacturerContactId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manufacturerContactId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerContactsGetManufacturerContact(manufacturerContactId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerContactResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturerContactsGetManufacturerContact(manufacturerContactId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerContactsGetManufacturerContacts(manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ManufacturerContactResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturerContactsGetManufacturerContacts(manufacturerId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manufacturerContactId 
         * @param {UpdateManufacturerContactRequest} updateManufacturerContactRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerContactsUpdateManufacturerContact(manufacturerContactId: string, updateManufacturerContactRequest: UpdateManufacturerContactRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturerContactsUpdateManufacturerContact(manufacturerContactId, updateManufacturerContactRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManufacturerContactsApi - factory interface
 * @export
 */
export const ManufacturerContactsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManufacturerContactsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateManufacturerContactRequest} createManufacturerContactRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerContactsCreateManufacturerContact(createManufacturerContactRequest: CreateManufacturerContactRequest, xVersion?: string, options?: any): AxiosPromise<ManufacturerContactResponse> {
            return localVarFp.manufacturerContactsCreateManufacturerContact(createManufacturerContactRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manufacturerContactId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerContactsDeleteManufacturerContact(manufacturerContactId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.manufacturerContactsDeleteManufacturerContact(manufacturerContactId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manufacturerContactId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerContactsGetManufacturerContact(manufacturerContactId: string, xVersion?: string, options?: any): AxiosPromise<ManufacturerContactResponse> {
            return localVarFp.manufacturerContactsGetManufacturerContact(manufacturerContactId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerContactsGetManufacturerContacts(manufacturerId?: string, xVersion?: string, options?: any): AxiosPromise<Array<ManufacturerContactResponse>> {
            return localVarFp.manufacturerContactsGetManufacturerContacts(manufacturerId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manufacturerContactId 
         * @param {UpdateManufacturerContactRequest} updateManufacturerContactRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerContactsUpdateManufacturerContact(manufacturerContactId: string, updateManufacturerContactRequest: UpdateManufacturerContactRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.manufacturerContactsUpdateManufacturerContact(manufacturerContactId, updateManufacturerContactRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManufacturerContactsApi - object-oriented interface
 * @export
 * @class ManufacturerContactsApi
 * @extends {BaseAPI}
 */
export class ManufacturerContactsApi extends BaseAPI {
    /**
     * 
     * @param {CreateManufacturerContactRequest} createManufacturerContactRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerContactsApi
     */
    public manufacturerContactsCreateManufacturerContact(createManufacturerContactRequest: CreateManufacturerContactRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturerContactsApiFp(this.configuration).manufacturerContactsCreateManufacturerContact(createManufacturerContactRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manufacturerContactId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerContactsApi
     */
    public manufacturerContactsDeleteManufacturerContact(manufacturerContactId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturerContactsApiFp(this.configuration).manufacturerContactsDeleteManufacturerContact(manufacturerContactId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manufacturerContactId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerContactsApi
     */
    public manufacturerContactsGetManufacturerContact(manufacturerContactId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturerContactsApiFp(this.configuration).manufacturerContactsGetManufacturerContact(manufacturerContactId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerContactsApi
     */
    public manufacturerContactsGetManufacturerContacts(manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturerContactsApiFp(this.configuration).manufacturerContactsGetManufacturerContacts(manufacturerId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manufacturerContactId 
     * @param {UpdateManufacturerContactRequest} updateManufacturerContactRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerContactsApi
     */
    public manufacturerContactsUpdateManufacturerContact(manufacturerContactId: string, updateManufacturerContactRequest: UpdateManufacturerContactRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturerContactsApiFp(this.configuration).manufacturerContactsUpdateManufacturerContact(manufacturerContactId, updateManufacturerContactRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ManufacturerDataExchangesApi - axios parameter creator
 * @export
 */
export const ManufacturerDataExchangesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} manufacturerDataExchangeId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerDataExchangesGetManufacturerDataExchangeById: async (manufacturerDataExchangeId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerDataExchangeId' is not null or undefined
            assertParamExists('manufacturerDataExchangesGetManufacturerDataExchangeById', 'manufacturerDataExchangeId', manufacturerDataExchangeId)
            const localVarPath = `/api/ManufacturerDataExchanges/{manufacturerDataExchangeId}`
                .replace(`{${"manufacturerDataExchangeId"}}`, encodeURIComponent(String(manufacturerDataExchangeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {boolean} [hasErrors] 
         * @param {number} [limit] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerDataExchangesGetManufacturerDataExchanges: async (manufacturerId?: string, hasErrors?: boolean, limit?: number, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ManufacturerDataExchanges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }

            if (hasErrors !== undefined) {
                localVarQueryParameter['HasErrors'] = hasErrors;
            }

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManufacturerDataExchangesApi - functional programming interface
 * @export
 */
export const ManufacturerDataExchangesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManufacturerDataExchangesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} manufacturerDataExchangeId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerDataExchangesGetManufacturerDataExchangeById(manufacturerDataExchangeId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerDataExchangeDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturerDataExchangesGetManufacturerDataExchangeById(manufacturerDataExchangeId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {boolean} [hasErrors] 
         * @param {number} [limit] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerDataExchangesGetManufacturerDataExchanges(manufacturerId?: string, hasErrors?: boolean, limit?: number, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ManufacturerDataExchangeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturerDataExchangesGetManufacturerDataExchanges(manufacturerId, hasErrors, limit, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManufacturerDataExchangesApi - factory interface
 * @export
 */
export const ManufacturerDataExchangesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManufacturerDataExchangesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} manufacturerDataExchangeId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerDataExchangesGetManufacturerDataExchangeById(manufacturerDataExchangeId: string, xVersion?: string, options?: any): AxiosPromise<ManufacturerDataExchangeDetailResponse> {
            return localVarFp.manufacturerDataExchangesGetManufacturerDataExchangeById(manufacturerDataExchangeId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {boolean} [hasErrors] 
         * @param {number} [limit] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerDataExchangesGetManufacturerDataExchanges(manufacturerId?: string, hasErrors?: boolean, limit?: number, xVersion?: string, options?: any): AxiosPromise<Array<ManufacturerDataExchangeResponse>> {
            return localVarFp.manufacturerDataExchangesGetManufacturerDataExchanges(manufacturerId, hasErrors, limit, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManufacturerDataExchangesApi - object-oriented interface
 * @export
 * @class ManufacturerDataExchangesApi
 * @extends {BaseAPI}
 */
export class ManufacturerDataExchangesApi extends BaseAPI {
    /**
     * 
     * @param {string} manufacturerDataExchangeId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerDataExchangesApi
     */
    public manufacturerDataExchangesGetManufacturerDataExchangeById(manufacturerDataExchangeId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturerDataExchangesApiFp(this.configuration).manufacturerDataExchangesGetManufacturerDataExchangeById(manufacturerDataExchangeId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {boolean} [hasErrors] 
     * @param {number} [limit] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerDataExchangesApi
     */
    public manufacturerDataExchangesGetManufacturerDataExchanges(manufacturerId?: string, hasErrors?: boolean, limit?: number, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturerDataExchangesApiFp(this.configuration).manufacturerDataExchangesGetManufacturerDataExchanges(manufacturerId, hasErrors, limit, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ManufacturerMonthlyInvoicesApi - axios parameter creator
 * @export
 */
export const ManufacturerMonthlyInvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerMonthlyInvoicesGetManufacturerCommissionInvoices: async (month?: number, year?: number, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ManufacturerMonthlyInvoices/commission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (month !== undefined) {
                localVarQueryParameter['Month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        manufacturerMonthlyInvoicesGetManufacturerReportCsv: async (month?: number, year?: number, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ManufacturerMonthlyInvoices/reportCsv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (month !== undefined) {
                localVarQueryParameter['Month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerMonthlyInvoicesGetManufacturerReportXls: async (manufacturerId?: string, month?: number, year?: number, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ManufacturerMonthlyInvoices/reportXls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }

            if (month !== undefined) {
                localVarQueryParameter['Month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManufacturerMonthlyInvoicesApi - functional programming interface
 * @export
 */
export const ManufacturerMonthlyInvoicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManufacturerMonthlyInvoicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerMonthlyInvoicesGetManufacturerCommissionInvoices(month?: number, year?: number, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturerMonthlyInvoicesGetManufacturerCommissionInvoices(month, year, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async manufacturerMonthlyInvoicesGetManufacturerReportCsv(month?: number, year?: number, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturerMonthlyInvoicesGetManufacturerReportCsv(month, year, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerMonthlyInvoicesGetManufacturerReportXls(manufacturerId?: string, month?: number, year?: number, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturerMonthlyInvoicesGetManufacturerReportXls(manufacturerId, month, year, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManufacturerMonthlyInvoicesApi - factory interface
 * @export
 */
export const ManufacturerMonthlyInvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManufacturerMonthlyInvoicesApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerMonthlyInvoicesGetManufacturerCommissionInvoices(month?: number, year?: number, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.manufacturerMonthlyInvoicesGetManufacturerCommissionInvoices(month, year, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        manufacturerMonthlyInvoicesGetManufacturerReportCsv(month?: number, year?: number, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.manufacturerMonthlyInvoicesGetManufacturerReportCsv(month, year, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerMonthlyInvoicesGetManufacturerReportXls(manufacturerId?: string, month?: number, year?: number, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.manufacturerMonthlyInvoicesGetManufacturerReportXls(manufacturerId, month, year, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManufacturerMonthlyInvoicesApi - object-oriented interface
 * @export
 * @class ManufacturerMonthlyInvoicesApi
 * @extends {BaseAPI}
 */
export class ManufacturerMonthlyInvoicesApi extends BaseAPI {
    /**
     * 
     * @param {number} [month] 
     * @param {number} [year] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerMonthlyInvoicesApi
     */
    public manufacturerMonthlyInvoicesGetManufacturerCommissionInvoices(month?: number, year?: number, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturerMonthlyInvoicesApiFp(this.configuration).manufacturerMonthlyInvoicesGetManufacturerCommissionInvoices(month, year, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [month] 
     * @param {number} [year] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ManufacturerMonthlyInvoicesApi
     */
    public manufacturerMonthlyInvoicesGetManufacturerReportCsv(month?: number, year?: number, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturerMonthlyInvoicesApiFp(this.configuration).manufacturerMonthlyInvoicesGetManufacturerReportCsv(month, year, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {number} [month] 
     * @param {number} [year] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerMonthlyInvoicesApi
     */
    public manufacturerMonthlyInvoicesGetManufacturerReportXls(manufacturerId?: string, month?: number, year?: number, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturerMonthlyInvoicesApiFp(this.configuration).manufacturerMonthlyInvoicesGetManufacturerReportXls(manufacturerId, month, year, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ManufacturerSettingsApi - axios parameter creator
 * @export
 */
export const ManufacturerSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} manufacturerSettingId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerSettingsDeleteManufacturerSetting: async (manufacturerSettingId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerSettingId' is not null or undefined
            assertParamExists('manufacturerSettingsDeleteManufacturerSetting', 'manufacturerSettingId', manufacturerSettingId)
            const localVarPath = `/api/ManufacturerSettings/{manufacturerSettingId}`
                .replace(`{${"manufacturerSettingId"}}`, encodeURIComponent(String(manufacturerSettingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manufacturerSettingId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerSettingsGetManufacturerSettingById: async (manufacturerSettingId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerSettingId' is not null or undefined
            assertParamExists('manufacturerSettingsGetManufacturerSettingById', 'manufacturerSettingId', manufacturerSettingId)
            const localVarPath = `/api/ManufacturerSettings/{manufacturerSettingId}`
                .replace(`{${"manufacturerSettingId"}}`, encodeURIComponent(String(manufacturerSettingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ManufacturerSettingKey} manufacturerSettingKey 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerSettingsGetManufacturerSettingByKey: async (manufacturerSettingKey: ManufacturerSettingKey, manufacturerId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerSettingKey' is not null or undefined
            assertParamExists('manufacturerSettingsGetManufacturerSettingByKey', 'manufacturerSettingKey', manufacturerSettingKey)
            const localVarPath = `/api/ManufacturerSettings/key/{manufacturerSettingKey}`
                .replace(`{${"manufacturerSettingKey"}}`, encodeURIComponent(String(manufacturerSettingKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['manufacturerId'] = manufacturerId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerSettingsGetManufacturerSettings: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ManufacturerSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manufacturerSettingId 
         * @param {UpdateManufacturerSettingRequest} updateManufacturerSettingRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerSettingsUpdateManufacturerSetting: async (manufacturerSettingId: string, updateManufacturerSettingRequest: UpdateManufacturerSettingRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerSettingId' is not null or undefined
            assertParamExists('manufacturerSettingsUpdateManufacturerSetting', 'manufacturerSettingId', manufacturerSettingId)
            // verify required parameter 'updateManufacturerSettingRequest' is not null or undefined
            assertParamExists('manufacturerSettingsUpdateManufacturerSetting', 'updateManufacturerSettingRequest', updateManufacturerSettingRequest)
            const localVarPath = `/api/ManufacturerSettings/{manufacturerSettingId}`
                .replace(`{${"manufacturerSettingId"}}`, encodeURIComponent(String(manufacturerSettingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateManufacturerSettingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertManufacturerSettingRequest} upsertManufacturerSettingRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerSettingsUpsertManufacturerSetting: async (upsertManufacturerSettingRequest: UpsertManufacturerSettingRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'upsertManufacturerSettingRequest' is not null or undefined
            assertParamExists('manufacturerSettingsUpsertManufacturerSetting', 'upsertManufacturerSettingRequest', upsertManufacturerSettingRequest)
            const localVarPath = `/api/ManufacturerSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertManufacturerSettingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManufacturerSettingsApi - functional programming interface
 * @export
 */
export const ManufacturerSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManufacturerSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} manufacturerSettingId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerSettingsDeleteManufacturerSetting(manufacturerSettingId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturerSettingsDeleteManufacturerSetting(manufacturerSettingId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manufacturerSettingId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerSettingsGetManufacturerSettingById(manufacturerSettingId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerSettingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturerSettingsGetManufacturerSettingById(manufacturerSettingId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ManufacturerSettingKey} manufacturerSettingKey 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerSettingsGetManufacturerSettingByKey(manufacturerSettingKey: ManufacturerSettingKey, manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerSettingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturerSettingsGetManufacturerSettingByKey(manufacturerSettingKey, manufacturerId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerSettingsGetManufacturerSettings(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ManufacturerSettingResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturerSettingsGetManufacturerSettings(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manufacturerSettingId 
         * @param {UpdateManufacturerSettingRequest} updateManufacturerSettingRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerSettingsUpdateManufacturerSetting(manufacturerSettingId: string, updateManufacturerSettingRequest: UpdateManufacturerSettingRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturerSettingsUpdateManufacturerSetting(manufacturerSettingId, updateManufacturerSettingRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertManufacturerSettingRequest} upsertManufacturerSettingRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturerSettingsUpsertManufacturerSetting(upsertManufacturerSettingRequest: UpsertManufacturerSettingRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerSettingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturerSettingsUpsertManufacturerSetting(upsertManufacturerSettingRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManufacturerSettingsApi - factory interface
 * @export
 */
export const ManufacturerSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManufacturerSettingsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} manufacturerSettingId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerSettingsDeleteManufacturerSetting(manufacturerSettingId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.manufacturerSettingsDeleteManufacturerSetting(manufacturerSettingId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manufacturerSettingId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerSettingsGetManufacturerSettingById(manufacturerSettingId: string, xVersion?: string, options?: any): AxiosPromise<ManufacturerSettingResponse> {
            return localVarFp.manufacturerSettingsGetManufacturerSettingById(manufacturerSettingId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ManufacturerSettingKey} manufacturerSettingKey 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerSettingsGetManufacturerSettingByKey(manufacturerSettingKey: ManufacturerSettingKey, manufacturerId?: string, xVersion?: string, options?: any): AxiosPromise<ManufacturerSettingResponse> {
            return localVarFp.manufacturerSettingsGetManufacturerSettingByKey(manufacturerSettingKey, manufacturerId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerSettingsGetManufacturerSettings(xVersion?: string, options?: any): AxiosPromise<Array<ManufacturerSettingResponse>> {
            return localVarFp.manufacturerSettingsGetManufacturerSettings(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manufacturerSettingId 
         * @param {UpdateManufacturerSettingRequest} updateManufacturerSettingRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerSettingsUpdateManufacturerSetting(manufacturerSettingId: string, updateManufacturerSettingRequest: UpdateManufacturerSettingRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.manufacturerSettingsUpdateManufacturerSetting(manufacturerSettingId, updateManufacturerSettingRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertManufacturerSettingRequest} upsertManufacturerSettingRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturerSettingsUpsertManufacturerSetting(upsertManufacturerSettingRequest: UpsertManufacturerSettingRequest, xVersion?: string, options?: any): AxiosPromise<ManufacturerSettingResponse> {
            return localVarFp.manufacturerSettingsUpsertManufacturerSetting(upsertManufacturerSettingRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManufacturerSettingsApi - object-oriented interface
 * @export
 * @class ManufacturerSettingsApi
 * @extends {BaseAPI}
 */
export class ManufacturerSettingsApi extends BaseAPI {
    /**
     * 
     * @param {string} manufacturerSettingId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerSettingsApi
     */
    public manufacturerSettingsDeleteManufacturerSetting(manufacturerSettingId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturerSettingsApiFp(this.configuration).manufacturerSettingsDeleteManufacturerSetting(manufacturerSettingId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manufacturerSettingId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerSettingsApi
     */
    public manufacturerSettingsGetManufacturerSettingById(manufacturerSettingId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturerSettingsApiFp(this.configuration).manufacturerSettingsGetManufacturerSettingById(manufacturerSettingId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ManufacturerSettingKey} manufacturerSettingKey 
     * @param {string} [manufacturerId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerSettingsApi
     */
    public manufacturerSettingsGetManufacturerSettingByKey(manufacturerSettingKey: ManufacturerSettingKey, manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturerSettingsApiFp(this.configuration).manufacturerSettingsGetManufacturerSettingByKey(manufacturerSettingKey, manufacturerId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerSettingsApi
     */
    public manufacturerSettingsGetManufacturerSettings(xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturerSettingsApiFp(this.configuration).manufacturerSettingsGetManufacturerSettings(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manufacturerSettingId 
     * @param {UpdateManufacturerSettingRequest} updateManufacturerSettingRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerSettingsApi
     */
    public manufacturerSettingsUpdateManufacturerSetting(manufacturerSettingId: string, updateManufacturerSettingRequest: UpdateManufacturerSettingRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturerSettingsApiFp(this.configuration).manufacturerSettingsUpdateManufacturerSetting(manufacturerSettingId, updateManufacturerSettingRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertManufacturerSettingRequest} upsertManufacturerSettingRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerSettingsApi
     */
    public manufacturerSettingsUpsertManufacturerSetting(upsertManufacturerSettingRequest: UpsertManufacturerSettingRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturerSettingsApiFp(this.configuration).manufacturerSettingsUpsertManufacturerSetting(upsertManufacturerSettingRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ManufacturersApi - axios parameter creator
 * @export
 */
export const ManufacturersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateManufacturerRequest} createManufacturerRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersCreateManufacturer: async (createManufacturerRequest: CreateManufacturerRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createManufacturerRequest' is not null or undefined
            assertParamExists('manufacturersCreateManufacturer', 'createManufacturerRequest', createManufacturerRequest)
            const localVarPath = `/api/Manufacturers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createManufacturerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersDeleteManufacturer: async (manufacturerId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('manufacturersDeleteManufacturer', 'manufacturerId', manufacturerId)
            const localVarPath = `/api/Manufacturers/{manufacturerId}`
                .replace(`{${"manufacturerId"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersGetManufacturerById: async (manufacturerId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('manufacturersGetManufacturerById', 'manufacturerId', manufacturerId)
            const localVarPath = `/api/Manufacturers/{manufacturerId}`
                .replace(`{${"manufacturerId"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersGetManufacturerConditions: async (manufacturerId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('manufacturersGetManufacturerConditions', 'manufacturerId', manufacturerId)
            const localVarPath = `/api/Manufacturers/{manufacturerId}/conditions`
                .replace(`{${"manufacturerId"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersGetManufacturerContacts: async (manufacturerId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('manufacturersGetManufacturerContacts', 'manufacturerId', manufacturerId)
            const localVarPath = `/api/Manufacturers/{manufacturerId}/contacts`
                .replace(`{${"manufacturerId"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersGetManufacturerSettings: async (manufacturerId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('manufacturersGetManufacturerSettings', 'manufacturerId', manufacturerId)
            const localVarPath = `/api/Manufacturers/{manufacturerId}/settings`
                .replace(`{${"manufacturerId"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersGetManufacturers: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Manufacturers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersGetOrderReceiptLayoutData: async (manufacturerId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('manufacturersGetOrderReceiptLayoutData', 'manufacturerId', manufacturerId)
            const localVarPath = `/api/Manufacturers/{manufacturerId}/orderReceiptLayoutData`
                .replace(`{${"manufacturerId"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersGetSimpleManufacturers: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Manufacturers/simpleManufacturersList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {DocumentType} [documentType] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersPreviewOrderReceiptLayoutData: async (manufacturerId: string, documentType?: DocumentType, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('manufacturersPreviewOrderReceiptLayoutData', 'manufacturerId', manufacturerId)
            const localVarPath = `/api/Manufacturers/{manufacturerId}/orderReceiptLayoutData/preview`
                .replace(`{${"manufacturerId"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DataSynchronizationRequest} dataSynchronizationRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersSync: async (dataSynchronizationRequest: DataSynchronizationRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataSynchronizationRequest' is not null or undefined
            assertParamExists('manufacturersSync', 'dataSynchronizationRequest', dataSynchronizationRequest)
            const localVarPath = `/api/Manufacturers/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataSynchronizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {UpdateManufacturerRequest} updateManufacturerRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersUpdateManufacturer: async (manufacturerId: string, updateManufacturerRequest: UpdateManufacturerRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('manufacturersUpdateManufacturer', 'manufacturerId', manufacturerId)
            // verify required parameter 'updateManufacturerRequest' is not null or undefined
            assertParamExists('manufacturersUpdateManufacturer', 'updateManufacturerRequest', updateManufacturerRequest)
            const localVarPath = `/api/Manufacturers/{manufacturerId}`
                .replace(`{${"manufacturerId"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateManufacturerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {Array<UpdateManufacturerConditionsRequest>} updateManufacturerConditionsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersUpdateManufacturerConditions: async (manufacturerId: string, updateManufacturerConditionsRequest: Array<UpdateManufacturerConditionsRequest>, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('manufacturersUpdateManufacturerConditions', 'manufacturerId', manufacturerId)
            // verify required parameter 'updateManufacturerConditionsRequest' is not null or undefined
            assertParamExists('manufacturersUpdateManufacturerConditions', 'updateManufacturerConditionsRequest', updateManufacturerConditionsRequest)
            const localVarPath = `/api/Manufacturers/{manufacturerId}/conditions`
                .replace(`{${"manufacturerId"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateManufacturerConditionsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {Array<UpdateManufacturerContactRequest>} updateManufacturerContactRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersUpdateManufacturerContacts: async (manufacturerId: string, updateManufacturerContactRequest: Array<UpdateManufacturerContactRequest>, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('manufacturersUpdateManufacturerContacts', 'manufacturerId', manufacturerId)
            // verify required parameter 'updateManufacturerContactRequest' is not null or undefined
            assertParamExists('manufacturersUpdateManufacturerContacts', 'updateManufacturerContactRequest', updateManufacturerContactRequest)
            const localVarPath = `/api/Manufacturers/{manufacturerId}/contacts`
                .replace(`{${"manufacturerId"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateManufacturerContactRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {Array<UpdateManufacturerSettingsRequest>} updateManufacturerSettingsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersUpdateManufacturerSettings: async (manufacturerId: string, updateManufacturerSettingsRequest: Array<UpdateManufacturerSettingsRequest>, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('manufacturersUpdateManufacturerSettings', 'manufacturerId', manufacturerId)
            // verify required parameter 'updateManufacturerSettingsRequest' is not null or undefined
            assertParamExists('manufacturersUpdateManufacturerSettings', 'updateManufacturerSettingsRequest', updateManufacturerSettingsRequest)
            const localVarPath = `/api/Manufacturers/{manufacturerId}/settings`
                .replace(`{${"manufacturerId"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateManufacturerSettingsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {UpsertOrderReceiptDocumentLayoutDataRequest} upsertOrderReceiptDocumentLayoutDataRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersUpsertOrderReceiptLayoutData: async (manufacturerId: string, upsertOrderReceiptDocumentLayoutDataRequest: UpsertOrderReceiptDocumentLayoutDataRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('manufacturersUpsertOrderReceiptLayoutData', 'manufacturerId', manufacturerId)
            // verify required parameter 'upsertOrderReceiptDocumentLayoutDataRequest' is not null or undefined
            assertParamExists('manufacturersUpsertOrderReceiptLayoutData', 'upsertOrderReceiptDocumentLayoutDataRequest', upsertOrderReceiptDocumentLayoutDataRequest)
            const localVarPath = `/api/Manufacturers/{manufacturerId}/orderReceiptLayoutData`
                .replace(`{${"manufacturerId"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertOrderReceiptDocumentLayoutDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManufacturersApi - functional programming interface
 * @export
 */
export const ManufacturersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManufacturersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateManufacturerRequest} createManufacturerRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturersCreateManufacturer(createManufacturerRequest: CreateManufacturerRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturersCreateManufacturer(createManufacturerRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturersDeleteManufacturer(manufacturerId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturersDeleteManufacturer(manufacturerId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturersGetManufacturerById(manufacturerId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManufacturerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturersGetManufacturerById(manufacturerId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturersGetManufacturerConditions(manufacturerId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ManufacturerConditionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturersGetManufacturerConditions(manufacturerId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturersGetManufacturerContacts(manufacturerId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ManufacturerContactResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturersGetManufacturerContacts(manufacturerId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturersGetManufacturerSettings(manufacturerId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ManufacturerSettingResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturersGetManufacturerSettings(manufacturerId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturersGetManufacturers(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ManufacturerResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturersGetManufacturers(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturersGetOrderReceiptLayoutData(manufacturerId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderReceiptDocumentLayoutDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturersGetOrderReceiptLayoutData(manufacturerId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturersGetSimpleManufacturers(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SimpleManufacturerResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturersGetSimpleManufacturers(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {DocumentType} [documentType] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturersPreviewOrderReceiptLayoutData(manufacturerId: string, documentType?: DocumentType, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturersPreviewOrderReceiptLayoutData(manufacturerId, documentType, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DataSynchronizationRequest} dataSynchronizationRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturersSync(dataSynchronizationRequest: DataSynchronizationRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturersSync(dataSynchronizationRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {UpdateManufacturerRequest} updateManufacturerRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturersUpdateManufacturer(manufacturerId: string, updateManufacturerRequest: UpdateManufacturerRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturersUpdateManufacturer(manufacturerId, updateManufacturerRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {Array<UpdateManufacturerConditionsRequest>} updateManufacturerConditionsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturersUpdateManufacturerConditions(manufacturerId: string, updateManufacturerConditionsRequest: Array<UpdateManufacturerConditionsRequest>, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturersUpdateManufacturerConditions(manufacturerId, updateManufacturerConditionsRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {Array<UpdateManufacturerContactRequest>} updateManufacturerContactRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturersUpdateManufacturerContacts(manufacturerId: string, updateManufacturerContactRequest: Array<UpdateManufacturerContactRequest>, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturersUpdateManufacturerContacts(manufacturerId, updateManufacturerContactRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {Array<UpdateManufacturerSettingsRequest>} updateManufacturerSettingsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturersUpdateManufacturerSettings(manufacturerId: string, updateManufacturerSettingsRequest: Array<UpdateManufacturerSettingsRequest>, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturersUpdateManufacturerSettings(manufacturerId, updateManufacturerSettingsRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {UpsertOrderReceiptDocumentLayoutDataRequest} upsertOrderReceiptDocumentLayoutDataRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturersUpsertOrderReceiptLayoutData(manufacturerId: string, upsertOrderReceiptDocumentLayoutDataRequest: UpsertOrderReceiptDocumentLayoutDataRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderReceiptDocumentLayoutDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturersUpsertOrderReceiptLayoutData(manufacturerId, upsertOrderReceiptDocumentLayoutDataRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManufacturersApi - factory interface
 * @export
 */
export const ManufacturersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManufacturersApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateManufacturerRequest} createManufacturerRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersCreateManufacturer(createManufacturerRequest: CreateManufacturerRequest, xVersion?: string, options?: any): AxiosPromise<ManufacturerResponse> {
            return localVarFp.manufacturersCreateManufacturer(createManufacturerRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersDeleteManufacturer(manufacturerId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.manufacturersDeleteManufacturer(manufacturerId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersGetManufacturerById(manufacturerId: string, xVersion?: string, options?: any): AxiosPromise<ManufacturerResponse> {
            return localVarFp.manufacturersGetManufacturerById(manufacturerId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersGetManufacturerConditions(manufacturerId: string, xVersion?: string, options?: any): AxiosPromise<Array<ManufacturerConditionResponse>> {
            return localVarFp.manufacturersGetManufacturerConditions(manufacturerId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersGetManufacturerContacts(manufacturerId: string, xVersion?: string, options?: any): AxiosPromise<Array<ManufacturerContactResponse>> {
            return localVarFp.manufacturersGetManufacturerContacts(manufacturerId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersGetManufacturerSettings(manufacturerId: string, xVersion?: string, options?: any): AxiosPromise<Array<ManufacturerSettingResponse>> {
            return localVarFp.manufacturersGetManufacturerSettings(manufacturerId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersGetManufacturers(xVersion?: string, options?: any): AxiosPromise<Array<ManufacturerResponse>> {
            return localVarFp.manufacturersGetManufacturers(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersGetOrderReceiptLayoutData(manufacturerId: string, xVersion?: string, options?: any): AxiosPromise<OrderReceiptDocumentLayoutDataResponse> {
            return localVarFp.manufacturersGetOrderReceiptLayoutData(manufacturerId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersGetSimpleManufacturers(xVersion?: string, options?: any): AxiosPromise<Array<SimpleManufacturerResponse>> {
            return localVarFp.manufacturersGetSimpleManufacturers(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {DocumentType} [documentType] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersPreviewOrderReceiptLayoutData(manufacturerId: string, documentType?: DocumentType, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.manufacturersPreviewOrderReceiptLayoutData(manufacturerId, documentType, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DataSynchronizationRequest} dataSynchronizationRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersSync(dataSynchronizationRequest: DataSynchronizationRequest, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.manufacturersSync(dataSynchronizationRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {UpdateManufacturerRequest} updateManufacturerRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersUpdateManufacturer(manufacturerId: string, updateManufacturerRequest: UpdateManufacturerRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.manufacturersUpdateManufacturer(manufacturerId, updateManufacturerRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {Array<UpdateManufacturerConditionsRequest>} updateManufacturerConditionsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersUpdateManufacturerConditions(manufacturerId: string, updateManufacturerConditionsRequest: Array<UpdateManufacturerConditionsRequest>, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.manufacturersUpdateManufacturerConditions(manufacturerId, updateManufacturerConditionsRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {Array<UpdateManufacturerContactRequest>} updateManufacturerContactRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersUpdateManufacturerContacts(manufacturerId: string, updateManufacturerContactRequest: Array<UpdateManufacturerContactRequest>, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.manufacturersUpdateManufacturerContacts(manufacturerId, updateManufacturerContactRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {Array<UpdateManufacturerSettingsRequest>} updateManufacturerSettingsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersUpdateManufacturerSettings(manufacturerId: string, updateManufacturerSettingsRequest: Array<UpdateManufacturerSettingsRequest>, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.manufacturersUpdateManufacturerSettings(manufacturerId, updateManufacturerSettingsRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {UpsertOrderReceiptDocumentLayoutDataRequest} upsertOrderReceiptDocumentLayoutDataRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersUpsertOrderReceiptLayoutData(manufacturerId: string, upsertOrderReceiptDocumentLayoutDataRequest: UpsertOrderReceiptDocumentLayoutDataRequest, xVersion?: string, options?: any): AxiosPromise<OrderReceiptDocumentLayoutDataResponse> {
            return localVarFp.manufacturersUpsertOrderReceiptLayoutData(manufacturerId, upsertOrderReceiptDocumentLayoutDataRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManufacturersApi - object-oriented interface
 * @export
 * @class ManufacturersApi
 * @extends {BaseAPI}
 */
export class ManufacturersApi extends BaseAPI {
    /**
     * 
     * @param {CreateManufacturerRequest} createManufacturerRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public manufacturersCreateManufacturer(createManufacturerRequest: CreateManufacturerRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).manufacturersCreateManufacturer(createManufacturerRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manufacturerId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public manufacturersDeleteManufacturer(manufacturerId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).manufacturersDeleteManufacturer(manufacturerId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manufacturerId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public manufacturersGetManufacturerById(manufacturerId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).manufacturersGetManufacturerById(manufacturerId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manufacturerId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public manufacturersGetManufacturerConditions(manufacturerId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).manufacturersGetManufacturerConditions(manufacturerId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manufacturerId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public manufacturersGetManufacturerContacts(manufacturerId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).manufacturersGetManufacturerContacts(manufacturerId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manufacturerId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public manufacturersGetManufacturerSettings(manufacturerId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).manufacturersGetManufacturerSettings(manufacturerId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public manufacturersGetManufacturers(xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).manufacturersGetManufacturers(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manufacturerId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public manufacturersGetOrderReceiptLayoutData(manufacturerId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).manufacturersGetOrderReceiptLayoutData(manufacturerId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public manufacturersGetSimpleManufacturers(xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).manufacturersGetSimpleManufacturers(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manufacturerId 
     * @param {DocumentType} [documentType] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public manufacturersPreviewOrderReceiptLayoutData(manufacturerId: string, documentType?: DocumentType, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).manufacturersPreviewOrderReceiptLayoutData(manufacturerId, documentType, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataSynchronizationRequest} dataSynchronizationRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public manufacturersSync(dataSynchronizationRequest: DataSynchronizationRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).manufacturersSync(dataSynchronizationRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manufacturerId 
     * @param {UpdateManufacturerRequest} updateManufacturerRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public manufacturersUpdateManufacturer(manufacturerId: string, updateManufacturerRequest: UpdateManufacturerRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).manufacturersUpdateManufacturer(manufacturerId, updateManufacturerRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manufacturerId 
     * @param {Array<UpdateManufacturerConditionsRequest>} updateManufacturerConditionsRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public manufacturersUpdateManufacturerConditions(manufacturerId: string, updateManufacturerConditionsRequest: Array<UpdateManufacturerConditionsRequest>, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).manufacturersUpdateManufacturerConditions(manufacturerId, updateManufacturerConditionsRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manufacturerId 
     * @param {Array<UpdateManufacturerContactRequest>} updateManufacturerContactRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public manufacturersUpdateManufacturerContacts(manufacturerId: string, updateManufacturerContactRequest: Array<UpdateManufacturerContactRequest>, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).manufacturersUpdateManufacturerContacts(manufacturerId, updateManufacturerContactRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manufacturerId 
     * @param {Array<UpdateManufacturerSettingsRequest>} updateManufacturerSettingsRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public manufacturersUpdateManufacturerSettings(manufacturerId: string, updateManufacturerSettingsRequest: Array<UpdateManufacturerSettingsRequest>, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).manufacturersUpdateManufacturerSettings(manufacturerId, updateManufacturerSettingsRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manufacturerId 
     * @param {UpsertOrderReceiptDocumentLayoutDataRequest} upsertOrderReceiptDocumentLayoutDataRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturersApi
     */
    public manufacturersUpsertOrderReceiptLayoutData(manufacturerId: string, upsertOrderReceiptDocumentLayoutDataRequest: UpsertOrderReceiptDocumentLayoutDataRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ManufacturersApiFp(this.configuration).manufacturersUpsertOrderReceiptLayoutData(manufacturerId, upsertOrderReceiptDocumentLayoutDataRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderDocumentsApi - axios parameter creator
 * @export
 */
export const OrderDocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} orderNumber 
         * @param {boolean} [sendAsMail] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderDocumentsGetOrderInvoice: async (orderNumber: string, sendAsMail?: boolean, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderNumber' is not null or undefined
            assertParamExists('orderDocumentsGetOrderInvoice', 'orderNumber', orderNumber)
            const localVarPath = `/api-public/OrderDocuments/{orderNumber}`
                .replace(`{${"orderNumber"}}`, encodeURIComponent(String(orderNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sendAsMail !== undefined) {
                localVarQueryParameter['SendAsMail'] = sendAsMail;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderDocumentsApi - functional programming interface
 * @export
 */
export const OrderDocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderDocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} orderNumber 
         * @param {boolean} [sendAsMail] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderDocumentsGetOrderInvoice(orderNumber: string, sendAsMail?: boolean, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderInvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderDocumentsGetOrderInvoice(orderNumber, sendAsMail, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderDocumentsApi - factory interface
 * @export
 */
export const OrderDocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderDocumentsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} orderNumber 
         * @param {boolean} [sendAsMail] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderDocumentsGetOrderInvoice(orderNumber: string, sendAsMail?: boolean, xVersion?: string, options?: any): AxiosPromise<OrderInvoiceResponse> {
            return localVarFp.orderDocumentsGetOrderInvoice(orderNumber, sendAsMail, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderDocumentsApi - object-oriented interface
 * @export
 * @class OrderDocumentsApi
 * @extends {BaseAPI}
 */
export class OrderDocumentsApi extends BaseAPI {
    /**
     * 
     * @param {string} orderNumber 
     * @param {boolean} [sendAsMail] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderDocumentsApi
     */
    public orderDocumentsGetOrderInvoice(orderNumber: string, sendAsMail?: boolean, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderDocumentsApiFp(this.configuration).orderDocumentsGetOrderInvoice(orderNumber, sendAsMail, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderInvoiceAddressesApi - axios parameter creator
 * @export
 */
export const OrderInvoiceAddressesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOrderInvoiceAddressRequest} createOrderInvoiceAddressRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderInvoiceAddressesCreateOrderInvoiceAddress: async (createOrderInvoiceAddressRequest: CreateOrderInvoiceAddressRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderInvoiceAddressRequest' is not null or undefined
            assertParamExists('orderInvoiceAddressesCreateOrderInvoiceAddress', 'createOrderInvoiceAddressRequest', createOrderInvoiceAddressRequest)
            const localVarPath = `/api/OrderInvoiceAddresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderInvoiceAddressRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderInvoiceAddressId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderInvoiceAddressesDeleteOrderInvoiceAddress: async (orderInvoiceAddressId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderInvoiceAddressId' is not null or undefined
            assertParamExists('orderInvoiceAddressesDeleteOrderInvoiceAddress', 'orderInvoiceAddressId', orderInvoiceAddressId)
            const localVarPath = `/api/OrderInvoiceAddresses/{orderInvoiceAddressId}`
                .replace(`{${"orderInvoiceAddressId"}}`, encodeURIComponent(String(orderInvoiceAddressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderInvoiceAddressId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderInvoiceAddressesGetOrderInvoiceAddressById: async (orderInvoiceAddressId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderInvoiceAddressId' is not null or undefined
            assertParamExists('orderInvoiceAddressesGetOrderInvoiceAddressById', 'orderInvoiceAddressId', orderInvoiceAddressId)
            const localVarPath = `/api/OrderInvoiceAddresses/{orderInvoiceAddressId}`
                .replace(`{${"orderInvoiceAddressId"}}`, encodeURIComponent(String(orderInvoiceAddressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderInvoiceAddressesGetOrderInvoiceAddresses: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrderInvoiceAddresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderInvoiceAddressId 
         * @param {UpdateOrderInvoiceAddressRequest} updateOrderInvoiceAddressRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderInvoiceAddressesUpdateOrderInvoiceAddress: async (orderInvoiceAddressId: string, updateOrderInvoiceAddressRequest: UpdateOrderInvoiceAddressRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderInvoiceAddressId' is not null or undefined
            assertParamExists('orderInvoiceAddressesUpdateOrderInvoiceAddress', 'orderInvoiceAddressId', orderInvoiceAddressId)
            // verify required parameter 'updateOrderInvoiceAddressRequest' is not null or undefined
            assertParamExists('orderInvoiceAddressesUpdateOrderInvoiceAddress', 'updateOrderInvoiceAddressRequest', updateOrderInvoiceAddressRequest)
            const localVarPath = `/api/OrderInvoiceAddresses/{orderInvoiceAddressId}`
                .replace(`{${"orderInvoiceAddressId"}}`, encodeURIComponent(String(orderInvoiceAddressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderInvoiceAddressRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderInvoiceAddressesApi - functional programming interface
 * @export
 */
export const OrderInvoiceAddressesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderInvoiceAddressesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateOrderInvoiceAddressRequest} createOrderInvoiceAddressRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderInvoiceAddressesCreateOrderInvoiceAddress(createOrderInvoiceAddressRequest: CreateOrderInvoiceAddressRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderInvoiceAddressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderInvoiceAddressesCreateOrderInvoiceAddress(createOrderInvoiceAddressRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderInvoiceAddressId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderInvoiceAddressesDeleteOrderInvoiceAddress(orderInvoiceAddressId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderInvoiceAddressesDeleteOrderInvoiceAddress(orderInvoiceAddressId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderInvoiceAddressId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderInvoiceAddressesGetOrderInvoiceAddressById(orderInvoiceAddressId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderInvoiceAddressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderInvoiceAddressesGetOrderInvoiceAddressById(orderInvoiceAddressId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderInvoiceAddressesGetOrderInvoiceAddresses(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderInvoiceAddressResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderInvoiceAddressesGetOrderInvoiceAddresses(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderInvoiceAddressId 
         * @param {UpdateOrderInvoiceAddressRequest} updateOrderInvoiceAddressRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderInvoiceAddressesUpdateOrderInvoiceAddress(orderInvoiceAddressId: string, updateOrderInvoiceAddressRequest: UpdateOrderInvoiceAddressRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderInvoiceAddressesUpdateOrderInvoiceAddress(orderInvoiceAddressId, updateOrderInvoiceAddressRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderInvoiceAddressesApi - factory interface
 * @export
 */
export const OrderInvoiceAddressesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderInvoiceAddressesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateOrderInvoiceAddressRequest} createOrderInvoiceAddressRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderInvoiceAddressesCreateOrderInvoiceAddress(createOrderInvoiceAddressRequest: CreateOrderInvoiceAddressRequest, xVersion?: string, options?: any): AxiosPromise<OrderInvoiceAddressResponse> {
            return localVarFp.orderInvoiceAddressesCreateOrderInvoiceAddress(createOrderInvoiceAddressRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderInvoiceAddressId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderInvoiceAddressesDeleteOrderInvoiceAddress(orderInvoiceAddressId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.orderInvoiceAddressesDeleteOrderInvoiceAddress(orderInvoiceAddressId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderInvoiceAddressId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderInvoiceAddressesGetOrderInvoiceAddressById(orderInvoiceAddressId: string, xVersion?: string, options?: any): AxiosPromise<OrderInvoiceAddressResponse> {
            return localVarFp.orderInvoiceAddressesGetOrderInvoiceAddressById(orderInvoiceAddressId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderInvoiceAddressesGetOrderInvoiceAddresses(xVersion?: string, options?: any): AxiosPromise<Array<OrderInvoiceAddressResponse>> {
            return localVarFp.orderInvoiceAddressesGetOrderInvoiceAddresses(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderInvoiceAddressId 
         * @param {UpdateOrderInvoiceAddressRequest} updateOrderInvoiceAddressRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderInvoiceAddressesUpdateOrderInvoiceAddress(orderInvoiceAddressId: string, updateOrderInvoiceAddressRequest: UpdateOrderInvoiceAddressRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.orderInvoiceAddressesUpdateOrderInvoiceAddress(orderInvoiceAddressId, updateOrderInvoiceAddressRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderInvoiceAddressesApi - object-oriented interface
 * @export
 * @class OrderInvoiceAddressesApi
 * @extends {BaseAPI}
 */
export class OrderInvoiceAddressesApi extends BaseAPI {
    /**
     * 
     * @param {CreateOrderInvoiceAddressRequest} createOrderInvoiceAddressRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderInvoiceAddressesApi
     */
    public orderInvoiceAddressesCreateOrderInvoiceAddress(createOrderInvoiceAddressRequest: CreateOrderInvoiceAddressRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderInvoiceAddressesApiFp(this.configuration).orderInvoiceAddressesCreateOrderInvoiceAddress(createOrderInvoiceAddressRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderInvoiceAddressId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderInvoiceAddressesApi
     */
    public orderInvoiceAddressesDeleteOrderInvoiceAddress(orderInvoiceAddressId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderInvoiceAddressesApiFp(this.configuration).orderInvoiceAddressesDeleteOrderInvoiceAddress(orderInvoiceAddressId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderInvoiceAddressId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderInvoiceAddressesApi
     */
    public orderInvoiceAddressesGetOrderInvoiceAddressById(orderInvoiceAddressId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderInvoiceAddressesApiFp(this.configuration).orderInvoiceAddressesGetOrderInvoiceAddressById(orderInvoiceAddressId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderInvoiceAddressesApi
     */
    public orderInvoiceAddressesGetOrderInvoiceAddresses(xVersion?: string, options?: AxiosRequestConfig) {
        return OrderInvoiceAddressesApiFp(this.configuration).orderInvoiceAddressesGetOrderInvoiceAddresses(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderInvoiceAddressId 
     * @param {UpdateOrderInvoiceAddressRequest} updateOrderInvoiceAddressRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderInvoiceAddressesApi
     */
    public orderInvoiceAddressesUpdateOrderInvoiceAddress(orderInvoiceAddressId: string, updateOrderInvoiceAddressRequest: UpdateOrderInvoiceAddressRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderInvoiceAddressesApiFp(this.configuration).orderInvoiceAddressesUpdateOrderInvoiceAddress(orderInvoiceAddressId, updateOrderInvoiceAddressRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderPaymentTransactionsApi - axios parameter creator
 * @export
 */
export const OrderPaymentTransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOrderPaymentTransactionRequest} createOrderPaymentTransactionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderPaymentTransactionsCreateOrderPaymentTransaction: async (createOrderPaymentTransactionRequest: CreateOrderPaymentTransactionRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderPaymentTransactionRequest' is not null or undefined
            assertParamExists('orderPaymentTransactionsCreateOrderPaymentTransaction', 'createOrderPaymentTransactionRequest', createOrderPaymentTransactionRequest)
            const localVarPath = `/api/OrderPaymentTransactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderPaymentTransactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderPaymentTransactionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderPaymentTransactionsDeleteOrderPaymentTransaction: async (orderPaymentTransactionId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderPaymentTransactionId' is not null or undefined
            assertParamExists('orderPaymentTransactionsDeleteOrderPaymentTransaction', 'orderPaymentTransactionId', orderPaymentTransactionId)
            const localVarPath = `/api/OrderPaymentTransactions/{orderPaymentTransactionId}`
                .replace(`{${"orderPaymentTransactionId"}}`, encodeURIComponent(String(orderPaymentTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderPaymentTransactionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderPaymentTransactionsGetOrderPaymentTransactionById: async (orderPaymentTransactionId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderPaymentTransactionId' is not null or undefined
            assertParamExists('orderPaymentTransactionsGetOrderPaymentTransactionById', 'orderPaymentTransactionId', orderPaymentTransactionId)
            const localVarPath = `/api/OrderPaymentTransactions/{orderPaymentTransactionId}`
                .replace(`{${"orderPaymentTransactionId"}}`, encodeURIComponent(String(orderPaymentTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderPaymentTransactionsGetOrderPaymentTransactions: async (orderId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrderPaymentTransactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['OrderId'] = orderId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderPaymentTransactionId 
         * @param {UpdateOrderPaymentTransactionRequest} updateOrderPaymentTransactionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderPaymentTransactionsUpdateOrderPaymentTransaction: async (orderPaymentTransactionId: string, updateOrderPaymentTransactionRequest: UpdateOrderPaymentTransactionRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderPaymentTransactionId' is not null or undefined
            assertParamExists('orderPaymentTransactionsUpdateOrderPaymentTransaction', 'orderPaymentTransactionId', orderPaymentTransactionId)
            // verify required parameter 'updateOrderPaymentTransactionRequest' is not null or undefined
            assertParamExists('orderPaymentTransactionsUpdateOrderPaymentTransaction', 'updateOrderPaymentTransactionRequest', updateOrderPaymentTransactionRequest)
            const localVarPath = `/api/OrderPaymentTransactions/{orderPaymentTransactionId}`
                .replace(`{${"orderPaymentTransactionId"}}`, encodeURIComponent(String(orderPaymentTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderPaymentTransactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderPaymentTransactionsApi - functional programming interface
 * @export
 */
export const OrderPaymentTransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderPaymentTransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateOrderPaymentTransactionRequest} createOrderPaymentTransactionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderPaymentTransactionsCreateOrderPaymentTransaction(createOrderPaymentTransactionRequest: CreateOrderPaymentTransactionRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderPaymentTransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderPaymentTransactionsCreateOrderPaymentTransaction(createOrderPaymentTransactionRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderPaymentTransactionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderPaymentTransactionsDeleteOrderPaymentTransaction(orderPaymentTransactionId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderPaymentTransactionsDeleteOrderPaymentTransaction(orderPaymentTransactionId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderPaymentTransactionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderPaymentTransactionsGetOrderPaymentTransactionById(orderPaymentTransactionId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderPaymentTransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderPaymentTransactionsGetOrderPaymentTransactionById(orderPaymentTransactionId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [orderId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderPaymentTransactionsGetOrderPaymentTransactions(orderId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderPaymentTransactionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderPaymentTransactionsGetOrderPaymentTransactions(orderId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderPaymentTransactionId 
         * @param {UpdateOrderPaymentTransactionRequest} updateOrderPaymentTransactionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderPaymentTransactionsUpdateOrderPaymentTransaction(orderPaymentTransactionId: string, updateOrderPaymentTransactionRequest: UpdateOrderPaymentTransactionRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderPaymentTransactionsUpdateOrderPaymentTransaction(orderPaymentTransactionId, updateOrderPaymentTransactionRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderPaymentTransactionsApi - factory interface
 * @export
 */
export const OrderPaymentTransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderPaymentTransactionsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateOrderPaymentTransactionRequest} createOrderPaymentTransactionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderPaymentTransactionsCreateOrderPaymentTransaction(createOrderPaymentTransactionRequest: CreateOrderPaymentTransactionRequest, xVersion?: string, options?: any): AxiosPromise<OrderPaymentTransactionResponse> {
            return localVarFp.orderPaymentTransactionsCreateOrderPaymentTransaction(createOrderPaymentTransactionRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderPaymentTransactionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderPaymentTransactionsDeleteOrderPaymentTransaction(orderPaymentTransactionId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.orderPaymentTransactionsDeleteOrderPaymentTransaction(orderPaymentTransactionId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderPaymentTransactionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderPaymentTransactionsGetOrderPaymentTransactionById(orderPaymentTransactionId: string, xVersion?: string, options?: any): AxiosPromise<OrderPaymentTransactionResponse> {
            return localVarFp.orderPaymentTransactionsGetOrderPaymentTransactionById(orderPaymentTransactionId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderPaymentTransactionsGetOrderPaymentTransactions(orderId?: string, xVersion?: string, options?: any): AxiosPromise<Array<OrderPaymentTransactionResponse>> {
            return localVarFp.orderPaymentTransactionsGetOrderPaymentTransactions(orderId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderPaymentTransactionId 
         * @param {UpdateOrderPaymentTransactionRequest} updateOrderPaymentTransactionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderPaymentTransactionsUpdateOrderPaymentTransaction(orderPaymentTransactionId: string, updateOrderPaymentTransactionRequest: UpdateOrderPaymentTransactionRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.orderPaymentTransactionsUpdateOrderPaymentTransaction(orderPaymentTransactionId, updateOrderPaymentTransactionRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderPaymentTransactionsApi - object-oriented interface
 * @export
 * @class OrderPaymentTransactionsApi
 * @extends {BaseAPI}
 */
export class OrderPaymentTransactionsApi extends BaseAPI {
    /**
     * 
     * @param {CreateOrderPaymentTransactionRequest} createOrderPaymentTransactionRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderPaymentTransactionsApi
     */
    public orderPaymentTransactionsCreateOrderPaymentTransaction(createOrderPaymentTransactionRequest: CreateOrderPaymentTransactionRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderPaymentTransactionsApiFp(this.configuration).orderPaymentTransactionsCreateOrderPaymentTransaction(createOrderPaymentTransactionRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderPaymentTransactionId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderPaymentTransactionsApi
     */
    public orderPaymentTransactionsDeleteOrderPaymentTransaction(orderPaymentTransactionId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderPaymentTransactionsApiFp(this.configuration).orderPaymentTransactionsDeleteOrderPaymentTransaction(orderPaymentTransactionId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderPaymentTransactionId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderPaymentTransactionsApi
     */
    public orderPaymentTransactionsGetOrderPaymentTransactionById(orderPaymentTransactionId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderPaymentTransactionsApiFp(this.configuration).orderPaymentTransactionsGetOrderPaymentTransactionById(orderPaymentTransactionId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [orderId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderPaymentTransactionsApi
     */
    public orderPaymentTransactionsGetOrderPaymentTransactions(orderId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderPaymentTransactionsApiFp(this.configuration).orderPaymentTransactionsGetOrderPaymentTransactions(orderId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderPaymentTransactionId 
     * @param {UpdateOrderPaymentTransactionRequest} updateOrderPaymentTransactionRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderPaymentTransactionsApi
     */
    public orderPaymentTransactionsUpdateOrderPaymentTransaction(orderPaymentTransactionId: string, updateOrderPaymentTransactionRequest: UpdateOrderPaymentTransactionRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderPaymentTransactionsApiFp(this.configuration).orderPaymentTransactionsUpdateOrderPaymentTransaction(orderPaymentTransactionId, updateOrderPaymentTransactionRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderPositionsApi - axios parameter creator
 * @export
 */
export const OrderPositionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOrderPositionRequest} createOrderPositionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderPositionsCreateOrderPosition: async (createOrderPositionRequest: CreateOrderPositionRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderPositionRequest' is not null or undefined
            assertParamExists('orderPositionsCreateOrderPosition', 'createOrderPositionRequest', createOrderPositionRequest)
            const localVarPath = `/api/OrderPositions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderPositionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderPositionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderPositionsDeleteOrderPosition: async (orderPositionId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderPositionId' is not null or undefined
            assertParamExists('orderPositionsDeleteOrderPosition', 'orderPositionId', orderPositionId)
            const localVarPath = `/api/OrderPositions/{orderPositionId}`
                .replace(`{${"orderPositionId"}}`, encodeURIComponent(String(orderPositionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderPositionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderPositionsGetOrderPositionById: async (orderPositionId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderPositionId' is not null or undefined
            assertParamExists('orderPositionsGetOrderPositionById', 'orderPositionId', orderPositionId)
            const localVarPath = `/api/OrderPositions/{orderPositionId}`
                .replace(`{${"orderPositionId"}}`, encodeURIComponent(String(orderPositionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderPositionsGetOrderPositions: async (orderId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrderPositions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['OrderId'] = orderId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderPositionId 
         * @param {UpdateOrderPositionRequest} updateOrderPositionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderPositionsUpdateOrderPosition: async (orderPositionId: string, updateOrderPositionRequest: UpdateOrderPositionRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderPositionId' is not null or undefined
            assertParamExists('orderPositionsUpdateOrderPosition', 'orderPositionId', orderPositionId)
            // verify required parameter 'updateOrderPositionRequest' is not null or undefined
            assertParamExists('orderPositionsUpdateOrderPosition', 'updateOrderPositionRequest', updateOrderPositionRequest)
            const localVarPath = `/api/OrderPositions/{orderPositionId}`
                .replace(`{${"orderPositionId"}}`, encodeURIComponent(String(orderPositionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderPositionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderPositionsApi - functional programming interface
 * @export
 */
export const OrderPositionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderPositionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateOrderPositionRequest} createOrderPositionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderPositionsCreateOrderPosition(createOrderPositionRequest: CreateOrderPositionRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderPositionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderPositionsCreateOrderPosition(createOrderPositionRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderPositionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderPositionsDeleteOrderPosition(orderPositionId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderPositionsDeleteOrderPosition(orderPositionId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderPositionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderPositionsGetOrderPositionById(orderPositionId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderPositionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderPositionsGetOrderPositionById(orderPositionId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [orderId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderPositionsGetOrderPositions(orderId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderPositionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderPositionsGetOrderPositions(orderId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderPositionId 
         * @param {UpdateOrderPositionRequest} updateOrderPositionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderPositionsUpdateOrderPosition(orderPositionId: string, updateOrderPositionRequest: UpdateOrderPositionRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderPositionsUpdateOrderPosition(orderPositionId, updateOrderPositionRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderPositionsApi - factory interface
 * @export
 */
export const OrderPositionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderPositionsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateOrderPositionRequest} createOrderPositionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderPositionsCreateOrderPosition(createOrderPositionRequest: CreateOrderPositionRequest, xVersion?: string, options?: any): AxiosPromise<OrderPositionResponse> {
            return localVarFp.orderPositionsCreateOrderPosition(createOrderPositionRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderPositionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderPositionsDeleteOrderPosition(orderPositionId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.orderPositionsDeleteOrderPosition(orderPositionId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderPositionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderPositionsGetOrderPositionById(orderPositionId: string, xVersion?: string, options?: any): AxiosPromise<OrderPositionResponse> {
            return localVarFp.orderPositionsGetOrderPositionById(orderPositionId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderPositionsGetOrderPositions(orderId?: string, xVersion?: string, options?: any): AxiosPromise<Array<OrderPositionResponse>> {
            return localVarFp.orderPositionsGetOrderPositions(orderId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderPositionId 
         * @param {UpdateOrderPositionRequest} updateOrderPositionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderPositionsUpdateOrderPosition(orderPositionId: string, updateOrderPositionRequest: UpdateOrderPositionRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.orderPositionsUpdateOrderPosition(orderPositionId, updateOrderPositionRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderPositionsApi - object-oriented interface
 * @export
 * @class OrderPositionsApi
 * @extends {BaseAPI}
 */
export class OrderPositionsApi extends BaseAPI {
    /**
     * 
     * @param {CreateOrderPositionRequest} createOrderPositionRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderPositionsApi
     */
    public orderPositionsCreateOrderPosition(createOrderPositionRequest: CreateOrderPositionRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderPositionsApiFp(this.configuration).orderPositionsCreateOrderPosition(createOrderPositionRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderPositionId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderPositionsApi
     */
    public orderPositionsDeleteOrderPosition(orderPositionId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderPositionsApiFp(this.configuration).orderPositionsDeleteOrderPosition(orderPositionId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderPositionId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderPositionsApi
     */
    public orderPositionsGetOrderPositionById(orderPositionId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderPositionsApiFp(this.configuration).orderPositionsGetOrderPositionById(orderPositionId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [orderId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderPositionsApi
     */
    public orderPositionsGetOrderPositions(orderId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderPositionsApiFp(this.configuration).orderPositionsGetOrderPositions(orderId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderPositionId 
     * @param {UpdateOrderPositionRequest} updateOrderPositionRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderPositionsApi
     */
    public orderPositionsUpdateOrderPosition(orderPositionId: string, updateOrderPositionRequest: UpdateOrderPositionRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderPositionsApiFp(this.configuration).orderPositionsUpdateOrderPosition(orderPositionId, updateOrderPositionRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderReceiptsApi - axios parameter creator
 * @export
 */
export const OrderReceiptsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReceiptsGetOrderReceiptsDownload: async (manufacturerId?: string, year?: number, month?: number, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrderReceipts/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['Month'] = month;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderReceiptsApi - functional programming interface
 * @export
 */
export const OrderReceiptsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderReceiptsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderReceiptsGetOrderReceiptsDownload(manufacturerId?: string, year?: number, month?: number, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderReceiptsGetOrderReceiptsDownload(manufacturerId, year, month, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderReceiptsApi - factory interface
 * @export
 */
export const OrderReceiptsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderReceiptsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReceiptsGetOrderReceiptsDownload(manufacturerId?: string, year?: number, month?: number, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.orderReceiptsGetOrderReceiptsDownload(manufacturerId, year, month, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderReceiptsApi - object-oriented interface
 * @export
 * @class OrderReceiptsApi
 * @extends {BaseAPI}
 */
export class OrderReceiptsApi extends BaseAPI {
    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {number} [year] 
     * @param {number} [month] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderReceiptsApi
     */
    public orderReceiptsGetOrderReceiptsDownload(manufacturerId?: string, year?: number, month?: number, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderReceiptsApiFp(this.configuration).orderReceiptsGetOrderReceiptsDownload(manufacturerId, year, month, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderRefundsApi - axios parameter creator
 * @export
 */
export const OrderRefundsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [originOrderId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderRefundsGetOrderRefunds: async (originOrderId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-public/OrderRefunds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (originOrderId !== undefined) {
                localVarQueryParameter['originOrderId'] = originOrderId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderRefundsApi - functional programming interface
 * @export
 */
export const OrderRefundsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderRefundsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [originOrderId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderRefundsGetOrderRefunds(originOrderId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicAdyenPaymentRefundDetailResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderRefundsGetOrderRefunds(originOrderId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderRefundsApi - factory interface
 * @export
 */
export const OrderRefundsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderRefundsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [originOrderId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderRefundsGetOrderRefunds(originOrderId?: string, xVersion?: string, options?: any): AxiosPromise<Array<PublicAdyenPaymentRefundDetailResponse>> {
            return localVarFp.orderRefundsGetOrderRefunds(originOrderId, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderRefundsApi - object-oriented interface
 * @export
 * @class OrderRefundsApi
 * @extends {BaseAPI}
 */
export class OrderRefundsApi extends BaseAPI {
    /**
     * 
     * @param {string} [originOrderId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderRefundsApi
     */
    public orderRefundsGetOrderRefunds(originOrderId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderRefundsApiFp(this.configuration).orderRefundsGetOrderRefunds(originOrderId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderReturnAnnouncementsApi - axios parameter creator
 * @export
 */
export const OrderReturnAnnouncementsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOrderReturnAnnouncementRequest} createOrderReturnAnnouncementRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnAnnouncementsCreateOrderReturnAnnouncement: async (createOrderReturnAnnouncementRequest: CreateOrderReturnAnnouncementRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderReturnAnnouncementRequest' is not null or undefined
            assertParamExists('orderReturnAnnouncementsCreateOrderReturnAnnouncement', 'createOrderReturnAnnouncementRequest', createOrderReturnAnnouncementRequest)
            const localVarPath = `/api/OrderReturnAnnouncements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderReturnAnnouncementRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderReturnAnnouncementId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnAnnouncementsCreateReturnLabelEmail: async (orderReturnAnnouncementId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderReturnAnnouncementId' is not null or undefined
            assertParamExists('orderReturnAnnouncementsCreateReturnLabelEmail', 'orderReturnAnnouncementId', orderReturnAnnouncementId)
            const localVarPath = `/api/OrderReturnAnnouncements/{orderReturnAnnouncementId}/sendReturnLabelEmail`
                .replace(`{${"orderReturnAnnouncementId"}}`, encodeURIComponent(String(orderReturnAnnouncementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderReturnAnnouncementId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnAnnouncementsGetOrderReturnAnnouncementById: async (orderReturnAnnouncementId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderReturnAnnouncementId' is not null or undefined
            assertParamExists('orderReturnAnnouncementsGetOrderReturnAnnouncementById', 'orderReturnAnnouncementId', orderReturnAnnouncementId)
            const localVarPath = `/api/OrderReturnAnnouncements/{orderReturnAnnouncementId}`
                .replace(`{${"orderReturnAnnouncementId"}}`, encodeURIComponent(String(orderReturnAnnouncementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnAnnouncementsGetOrderReturnAnnouncements: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrderReturnAnnouncements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderReturnAnnouncementsApi - functional programming interface
 * @export
 */
export const OrderReturnAnnouncementsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderReturnAnnouncementsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateOrderReturnAnnouncementRequest} createOrderReturnAnnouncementRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderReturnAnnouncementsCreateOrderReturnAnnouncement(createOrderReturnAnnouncementRequest: CreateOrderReturnAnnouncementRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderReturnAnnouncementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderReturnAnnouncementsCreateOrderReturnAnnouncement(createOrderReturnAnnouncementRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderReturnAnnouncementId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderReturnAnnouncementsCreateReturnLabelEmail(orderReturnAnnouncementId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderReturnAnnouncementsCreateReturnLabelEmail(orderReturnAnnouncementId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderReturnAnnouncementId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderReturnAnnouncementsGetOrderReturnAnnouncementById(orderReturnAnnouncementId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderReturnAnnouncementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderReturnAnnouncementsGetOrderReturnAnnouncementById(orderReturnAnnouncementId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderReturnAnnouncementsGetOrderReturnAnnouncements(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderReturnAnnouncementResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderReturnAnnouncementsGetOrderReturnAnnouncements(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderReturnAnnouncementsApi - factory interface
 * @export
 */
export const OrderReturnAnnouncementsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderReturnAnnouncementsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateOrderReturnAnnouncementRequest} createOrderReturnAnnouncementRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnAnnouncementsCreateOrderReturnAnnouncement(createOrderReturnAnnouncementRequest: CreateOrderReturnAnnouncementRequest, xVersion?: string, options?: any): AxiosPromise<OrderReturnAnnouncementResponse> {
            return localVarFp.orderReturnAnnouncementsCreateOrderReturnAnnouncement(createOrderReturnAnnouncementRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderReturnAnnouncementId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnAnnouncementsCreateReturnLabelEmail(orderReturnAnnouncementId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.orderReturnAnnouncementsCreateReturnLabelEmail(orderReturnAnnouncementId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderReturnAnnouncementId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnAnnouncementsGetOrderReturnAnnouncementById(orderReturnAnnouncementId: string, xVersion?: string, options?: any): AxiosPromise<OrderReturnAnnouncementResponse> {
            return localVarFp.orderReturnAnnouncementsGetOrderReturnAnnouncementById(orderReturnAnnouncementId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnAnnouncementsGetOrderReturnAnnouncements(xVersion?: string, options?: any): AxiosPromise<Array<OrderReturnAnnouncementResponse>> {
            return localVarFp.orderReturnAnnouncementsGetOrderReturnAnnouncements(xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderReturnAnnouncementsApi - object-oriented interface
 * @export
 * @class OrderReturnAnnouncementsApi
 * @extends {BaseAPI}
 */
export class OrderReturnAnnouncementsApi extends BaseAPI {
    /**
     * 
     * @param {CreateOrderReturnAnnouncementRequest} createOrderReturnAnnouncementRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderReturnAnnouncementsApi
     */
    public orderReturnAnnouncementsCreateOrderReturnAnnouncement(createOrderReturnAnnouncementRequest: CreateOrderReturnAnnouncementRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderReturnAnnouncementsApiFp(this.configuration).orderReturnAnnouncementsCreateOrderReturnAnnouncement(createOrderReturnAnnouncementRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderReturnAnnouncementId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderReturnAnnouncementsApi
     */
    public orderReturnAnnouncementsCreateReturnLabelEmail(orderReturnAnnouncementId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderReturnAnnouncementsApiFp(this.configuration).orderReturnAnnouncementsCreateReturnLabelEmail(orderReturnAnnouncementId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderReturnAnnouncementId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderReturnAnnouncementsApi
     */
    public orderReturnAnnouncementsGetOrderReturnAnnouncementById(orderReturnAnnouncementId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderReturnAnnouncementsApiFp(this.configuration).orderReturnAnnouncementsGetOrderReturnAnnouncementById(orderReturnAnnouncementId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderReturnAnnouncementsApi
     */
    public orderReturnAnnouncementsGetOrderReturnAnnouncements(xVersion?: string, options?: AxiosRequestConfig) {
        return OrderReturnAnnouncementsApiFp(this.configuration).orderReturnAnnouncementsGetOrderReturnAnnouncements(xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderReturnsApi - axios parameter creator
 * @export
 */
export const OrderReturnsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOrderReturnRequest} createOrderReturnRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnsCreateOrderReturn: async (createOrderReturnRequest: CreateOrderReturnRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderReturnRequest' is not null or undefined
            assertParamExists('orderReturnsCreateOrderReturn', 'createOrderReturnRequest', createOrderReturnRequest)
            const localVarPath = `/api/OrderReturns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderReturnRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrderReturnAnnouncementRequest} createOrderReturnAnnouncementRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnsCreateOrderReturnAnnouncement: async (createOrderReturnAnnouncementRequest: CreateOrderReturnAnnouncementRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderReturnAnnouncementRequest' is not null or undefined
            assertParamExists('orderReturnsCreateOrderReturnAnnouncement', 'createOrderReturnAnnouncementRequest', createOrderReturnAnnouncementRequest)
            const localVarPath = `/api-public/OrderReturns/announcements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderReturnAnnouncementRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderReturnId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnsDeleteOrderReturn: async (orderReturnId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderReturnId' is not null or undefined
            assertParamExists('orderReturnsDeleteOrderReturn', 'orderReturnId', orderReturnId)
            const localVarPath = `/api/OrderReturns/{orderReturnId}`
                .replace(`{${"orderReturnId"}}`, encodeURIComponent(String(orderReturnId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderShippingDetailId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnsGetOrderReturnAnnouncements: async (orderShippingDetailId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-public/OrderReturns/announcements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderShippingDetailId !== undefined) {
                localVarQueryParameter['orderShippingDetailId'] = orderShippingDetailId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderReturnId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnsGetOrderReturnById: async (orderReturnId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderReturnId' is not null or undefined
            assertParamExists('orderReturnsGetOrderReturnById', 'orderReturnId', orderReturnId)
            const localVarPath = `/api/OrderReturns/{orderReturnId}`
                .replace(`{${"orderReturnId"}}`, encodeURIComponent(String(orderReturnId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [originOrderId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnsGetOrderReturns: async (originOrderId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-public/OrderReturns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (originOrderId !== undefined) {
                localVarQueryParameter['originOrderId'] = originOrderId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnsGetOrderReturns2: async (orderId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrderReturns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['OrderId'] = orderId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderReturnId 
         * @param {UpdateOrderReturnRequest} updateOrderReturnRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnsUpdateOrderReturn: async (orderReturnId: string, updateOrderReturnRequest: UpdateOrderReturnRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderReturnId' is not null or undefined
            assertParamExists('orderReturnsUpdateOrderReturn', 'orderReturnId', orderReturnId)
            // verify required parameter 'updateOrderReturnRequest' is not null or undefined
            assertParamExists('orderReturnsUpdateOrderReturn', 'updateOrderReturnRequest', updateOrderReturnRequest)
            const localVarPath = `/api/OrderReturns/{orderReturnId}`
                .replace(`{${"orderReturnId"}}`, encodeURIComponent(String(orderReturnId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderReturnRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderReturnId 
         * @param {string} orderReturnPositionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnsUpsertOrderReturnPositionDELETE: async (orderReturnId: string, orderReturnPositionId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderReturnId' is not null or undefined
            assertParamExists('orderReturnsUpsertOrderReturnPositionDELETE', 'orderReturnId', orderReturnId)
            // verify required parameter 'orderReturnPositionId' is not null or undefined
            assertParamExists('orderReturnsUpsertOrderReturnPositionDELETE', 'orderReturnPositionId', orderReturnPositionId)
            const localVarPath = `/api/OrderReturns/{orderReturnId}/positions/{orderReturnPositionId}`
                .replace(`{${"orderReturnId"}}`, encodeURIComponent(String(orderReturnId)))
                .replace(`{${"orderReturnPositionId"}}`, encodeURIComponent(String(orderReturnPositionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderReturnId 
         * @param {UpsertOrderReturnPositionRequest} upsertOrderReturnPositionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnsUpsertOrderReturnPositionPUT: async (orderReturnId: string, upsertOrderReturnPositionRequest: UpsertOrderReturnPositionRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderReturnId' is not null or undefined
            assertParamExists('orderReturnsUpsertOrderReturnPositionPUT', 'orderReturnId', orderReturnId)
            // verify required parameter 'upsertOrderReturnPositionRequest' is not null or undefined
            assertParamExists('orderReturnsUpsertOrderReturnPositionPUT', 'upsertOrderReturnPositionRequest', upsertOrderReturnPositionRequest)
            const localVarPath = `/api/OrderReturns/{orderReturnId}/positions`
                .replace(`{${"orderReturnId"}}`, encodeURIComponent(String(orderReturnId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertOrderReturnPositionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderReturnsApi - functional programming interface
 * @export
 */
export const OrderReturnsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderReturnsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateOrderReturnRequest} createOrderReturnRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderReturnsCreateOrderReturn(createOrderReturnRequest: CreateOrderReturnRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderReturnResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderReturnsCreateOrderReturn(createOrderReturnRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateOrderReturnAnnouncementRequest} createOrderReturnAnnouncementRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderReturnsCreateOrderReturnAnnouncement(createOrderReturnAnnouncementRequest: CreateOrderReturnAnnouncementRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderReturnAnnouncementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderReturnsCreateOrderReturnAnnouncement(createOrderReturnAnnouncementRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderReturnId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderReturnsDeleteOrderReturn(orderReturnId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderReturnsDeleteOrderReturn(orderReturnId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [orderShippingDetailId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderReturnsGetOrderReturnAnnouncements(orderShippingDetailId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderReturnAnnouncementResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderReturnsGetOrderReturnAnnouncements(orderShippingDetailId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderReturnId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderReturnsGetOrderReturnById(orderReturnId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderReturnResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderReturnsGetOrderReturnById(orderReturnId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [originOrderId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderReturnsGetOrderReturns(originOrderId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicOrderReturnResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderReturnsGetOrderReturns(originOrderId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [orderId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderReturnsGetOrderReturns2(orderId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderReturnResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderReturnsGetOrderReturns2(orderId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderReturnId 
         * @param {UpdateOrderReturnRequest} updateOrderReturnRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderReturnsUpdateOrderReturn(orderReturnId: string, updateOrderReturnRequest: UpdateOrderReturnRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderReturnsUpdateOrderReturn(orderReturnId, updateOrderReturnRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderReturnId 
         * @param {string} orderReturnPositionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderReturnsUpsertOrderReturnPositionDELETE(orderReturnId: string, orderReturnPositionId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderReturnsUpsertOrderReturnPositionDELETE(orderReturnId, orderReturnPositionId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderReturnId 
         * @param {UpsertOrderReturnPositionRequest} upsertOrderReturnPositionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderReturnsUpsertOrderReturnPositionPUT(orderReturnId: string, upsertOrderReturnPositionRequest: UpsertOrderReturnPositionRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderReturnsUpsertOrderReturnPositionPUT(orderReturnId, upsertOrderReturnPositionRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderReturnsApi - factory interface
 * @export
 */
export const OrderReturnsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderReturnsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateOrderReturnRequest} createOrderReturnRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnsCreateOrderReturn(createOrderReturnRequest: CreateOrderReturnRequest, xVersion?: string, options?: any): AxiosPromise<OrderReturnResponse> {
            return localVarFp.orderReturnsCreateOrderReturn(createOrderReturnRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateOrderReturnAnnouncementRequest} createOrderReturnAnnouncementRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnsCreateOrderReturnAnnouncement(createOrderReturnAnnouncementRequest: CreateOrderReturnAnnouncementRequest, xVersion?: string, options?: any): AxiosPromise<OrderReturnAnnouncementResponse> {
            return localVarFp.orderReturnsCreateOrderReturnAnnouncement(createOrderReturnAnnouncementRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderReturnId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnsDeleteOrderReturn(orderReturnId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.orderReturnsDeleteOrderReturn(orderReturnId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderShippingDetailId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnsGetOrderReturnAnnouncements(orderShippingDetailId?: string, xVersion?: string, options?: any): AxiosPromise<Array<OrderReturnAnnouncementResponse>> {
            return localVarFp.orderReturnsGetOrderReturnAnnouncements(orderShippingDetailId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderReturnId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnsGetOrderReturnById(orderReturnId: string, xVersion?: string, options?: any): AxiosPromise<OrderReturnResponse> {
            return localVarFp.orderReturnsGetOrderReturnById(orderReturnId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [originOrderId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnsGetOrderReturns(originOrderId?: string, xVersion?: string, options?: any): AxiosPromise<Array<PublicOrderReturnResponse>> {
            return localVarFp.orderReturnsGetOrderReturns(originOrderId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnsGetOrderReturns2(orderId?: string, xVersion?: string, options?: any): AxiosPromise<Array<OrderReturnResponse>> {
            return localVarFp.orderReturnsGetOrderReturns2(orderId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderReturnId 
         * @param {UpdateOrderReturnRequest} updateOrderReturnRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnsUpdateOrderReturn(orderReturnId: string, updateOrderReturnRequest: UpdateOrderReturnRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.orderReturnsUpdateOrderReturn(orderReturnId, updateOrderReturnRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderReturnId 
         * @param {string} orderReturnPositionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnsUpsertOrderReturnPositionDELETE(orderReturnId: string, orderReturnPositionId: string, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.orderReturnsUpsertOrderReturnPositionDELETE(orderReturnId, orderReturnPositionId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderReturnId 
         * @param {UpsertOrderReturnPositionRequest} upsertOrderReturnPositionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderReturnsUpsertOrderReturnPositionPUT(orderReturnId: string, upsertOrderReturnPositionRequest: UpsertOrderReturnPositionRequest, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.orderReturnsUpsertOrderReturnPositionPUT(orderReturnId, upsertOrderReturnPositionRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderReturnsApi - object-oriented interface
 * @export
 * @class OrderReturnsApi
 * @extends {BaseAPI}
 */
export class OrderReturnsApi extends BaseAPI {
    /**
     * 
     * @param {CreateOrderReturnRequest} createOrderReturnRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderReturnsApi
     */
    public orderReturnsCreateOrderReturn(createOrderReturnRequest: CreateOrderReturnRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderReturnsApiFp(this.configuration).orderReturnsCreateOrderReturn(createOrderReturnRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateOrderReturnAnnouncementRequest} createOrderReturnAnnouncementRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderReturnsApi
     */
    public orderReturnsCreateOrderReturnAnnouncement(createOrderReturnAnnouncementRequest: CreateOrderReturnAnnouncementRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderReturnsApiFp(this.configuration).orderReturnsCreateOrderReturnAnnouncement(createOrderReturnAnnouncementRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderReturnId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderReturnsApi
     */
    public orderReturnsDeleteOrderReturn(orderReturnId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderReturnsApiFp(this.configuration).orderReturnsDeleteOrderReturn(orderReturnId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [orderShippingDetailId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderReturnsApi
     */
    public orderReturnsGetOrderReturnAnnouncements(orderShippingDetailId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderReturnsApiFp(this.configuration).orderReturnsGetOrderReturnAnnouncements(orderShippingDetailId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderReturnId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderReturnsApi
     */
    public orderReturnsGetOrderReturnById(orderReturnId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderReturnsApiFp(this.configuration).orderReturnsGetOrderReturnById(orderReturnId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [originOrderId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderReturnsApi
     */
    public orderReturnsGetOrderReturns(originOrderId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderReturnsApiFp(this.configuration).orderReturnsGetOrderReturns(originOrderId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [orderId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderReturnsApi
     */
    public orderReturnsGetOrderReturns2(orderId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderReturnsApiFp(this.configuration).orderReturnsGetOrderReturns2(orderId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderReturnId 
     * @param {UpdateOrderReturnRequest} updateOrderReturnRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderReturnsApi
     */
    public orderReturnsUpdateOrderReturn(orderReturnId: string, updateOrderReturnRequest: UpdateOrderReturnRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderReturnsApiFp(this.configuration).orderReturnsUpdateOrderReturn(orderReturnId, updateOrderReturnRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderReturnId 
     * @param {string} orderReturnPositionId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderReturnsApi
     */
    public orderReturnsUpsertOrderReturnPositionDELETE(orderReturnId: string, orderReturnPositionId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderReturnsApiFp(this.configuration).orderReturnsUpsertOrderReturnPositionDELETE(orderReturnId, orderReturnPositionId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderReturnId 
     * @param {UpsertOrderReturnPositionRequest} upsertOrderReturnPositionRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderReturnsApi
     */
    public orderReturnsUpsertOrderReturnPositionPUT(orderReturnId: string, upsertOrderReturnPositionRequest: UpsertOrderReturnPositionRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderReturnsApiFp(this.configuration).orderReturnsUpsertOrderReturnPositionPUT(orderReturnId, upsertOrderReturnPositionRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderShippingAddressesApi - axios parameter creator
 * @export
 */
export const OrderShippingAddressesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOrderShippingAddressRequest} createOrderShippingAddressRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderShippingAddressesCreateOrderShippingAddress: async (createOrderShippingAddressRequest: CreateOrderShippingAddressRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderShippingAddressRequest' is not null or undefined
            assertParamExists('orderShippingAddressesCreateOrderShippingAddress', 'createOrderShippingAddressRequest', createOrderShippingAddressRequest)
            const localVarPath = `/api/OrderShippingAddresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderShippingAddressRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderShippingAddressId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderShippingAddressesDeleteOrderShippingAddress: async (orderShippingAddressId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderShippingAddressId' is not null or undefined
            assertParamExists('orderShippingAddressesDeleteOrderShippingAddress', 'orderShippingAddressId', orderShippingAddressId)
            const localVarPath = `/api/OrderShippingAddresses/{orderShippingAddressId}`
                .replace(`{${"orderShippingAddressId"}}`, encodeURIComponent(String(orderShippingAddressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderShippingAddressId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderShippingAddressesGetOrderShippingAddressById: async (orderShippingAddressId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderShippingAddressId' is not null or undefined
            assertParamExists('orderShippingAddressesGetOrderShippingAddressById', 'orderShippingAddressId', orderShippingAddressId)
            const localVarPath = `/api/OrderShippingAddresses/{orderShippingAddressId}`
                .replace(`{${"orderShippingAddressId"}}`, encodeURIComponent(String(orderShippingAddressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderShippingAddressesGetOrderShippingAddresses: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrderShippingAddresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderShippingAddressId 
         * @param {UpdateOrderShippingAddressRequest} updateOrderShippingAddressRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderShippingAddressesUpdateOrderShippingAddress: async (orderShippingAddressId: string, updateOrderShippingAddressRequest: UpdateOrderShippingAddressRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderShippingAddressId' is not null or undefined
            assertParamExists('orderShippingAddressesUpdateOrderShippingAddress', 'orderShippingAddressId', orderShippingAddressId)
            // verify required parameter 'updateOrderShippingAddressRequest' is not null or undefined
            assertParamExists('orderShippingAddressesUpdateOrderShippingAddress', 'updateOrderShippingAddressRequest', updateOrderShippingAddressRequest)
            const localVarPath = `/api/OrderShippingAddresses/{orderShippingAddressId}`
                .replace(`{${"orderShippingAddressId"}}`, encodeURIComponent(String(orderShippingAddressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderShippingAddressRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderShippingAddressesApi - functional programming interface
 * @export
 */
export const OrderShippingAddressesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderShippingAddressesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateOrderShippingAddressRequest} createOrderShippingAddressRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderShippingAddressesCreateOrderShippingAddress(createOrderShippingAddressRequest: CreateOrderShippingAddressRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderShippingAddressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderShippingAddressesCreateOrderShippingAddress(createOrderShippingAddressRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderShippingAddressId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderShippingAddressesDeleteOrderShippingAddress(orderShippingAddressId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderShippingAddressesDeleteOrderShippingAddress(orderShippingAddressId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderShippingAddressId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderShippingAddressesGetOrderShippingAddressById(orderShippingAddressId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderShippingAddressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderShippingAddressesGetOrderShippingAddressById(orderShippingAddressId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderShippingAddressesGetOrderShippingAddresses(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderShippingAddressResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderShippingAddressesGetOrderShippingAddresses(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderShippingAddressId 
         * @param {UpdateOrderShippingAddressRequest} updateOrderShippingAddressRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderShippingAddressesUpdateOrderShippingAddress(orderShippingAddressId: string, updateOrderShippingAddressRequest: UpdateOrderShippingAddressRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderShippingAddressesUpdateOrderShippingAddress(orderShippingAddressId, updateOrderShippingAddressRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderShippingAddressesApi - factory interface
 * @export
 */
export const OrderShippingAddressesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderShippingAddressesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateOrderShippingAddressRequest} createOrderShippingAddressRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderShippingAddressesCreateOrderShippingAddress(createOrderShippingAddressRequest: CreateOrderShippingAddressRequest, xVersion?: string, options?: any): AxiosPromise<OrderShippingAddressResponse> {
            return localVarFp.orderShippingAddressesCreateOrderShippingAddress(createOrderShippingAddressRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderShippingAddressId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderShippingAddressesDeleteOrderShippingAddress(orderShippingAddressId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.orderShippingAddressesDeleteOrderShippingAddress(orderShippingAddressId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderShippingAddressId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderShippingAddressesGetOrderShippingAddressById(orderShippingAddressId: string, xVersion?: string, options?: any): AxiosPromise<OrderShippingAddressResponse> {
            return localVarFp.orderShippingAddressesGetOrderShippingAddressById(orderShippingAddressId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderShippingAddressesGetOrderShippingAddresses(xVersion?: string, options?: any): AxiosPromise<Array<OrderShippingAddressResponse>> {
            return localVarFp.orderShippingAddressesGetOrderShippingAddresses(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderShippingAddressId 
         * @param {UpdateOrderShippingAddressRequest} updateOrderShippingAddressRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderShippingAddressesUpdateOrderShippingAddress(orderShippingAddressId: string, updateOrderShippingAddressRequest: UpdateOrderShippingAddressRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.orderShippingAddressesUpdateOrderShippingAddress(orderShippingAddressId, updateOrderShippingAddressRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderShippingAddressesApi - object-oriented interface
 * @export
 * @class OrderShippingAddressesApi
 * @extends {BaseAPI}
 */
export class OrderShippingAddressesApi extends BaseAPI {
    /**
     * 
     * @param {CreateOrderShippingAddressRequest} createOrderShippingAddressRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderShippingAddressesApi
     */
    public orderShippingAddressesCreateOrderShippingAddress(createOrderShippingAddressRequest: CreateOrderShippingAddressRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderShippingAddressesApiFp(this.configuration).orderShippingAddressesCreateOrderShippingAddress(createOrderShippingAddressRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderShippingAddressId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderShippingAddressesApi
     */
    public orderShippingAddressesDeleteOrderShippingAddress(orderShippingAddressId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderShippingAddressesApiFp(this.configuration).orderShippingAddressesDeleteOrderShippingAddress(orderShippingAddressId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderShippingAddressId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderShippingAddressesApi
     */
    public orderShippingAddressesGetOrderShippingAddressById(orderShippingAddressId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderShippingAddressesApiFp(this.configuration).orderShippingAddressesGetOrderShippingAddressById(orderShippingAddressId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderShippingAddressesApi
     */
    public orderShippingAddressesGetOrderShippingAddresses(xVersion?: string, options?: AxiosRequestConfig) {
        return OrderShippingAddressesApiFp(this.configuration).orderShippingAddressesGetOrderShippingAddresses(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderShippingAddressId 
     * @param {UpdateOrderShippingAddressRequest} updateOrderShippingAddressRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderShippingAddressesApi
     */
    public orderShippingAddressesUpdateOrderShippingAddress(orderShippingAddressId: string, updateOrderShippingAddressRequest: UpdateOrderShippingAddressRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderShippingAddressesApiFp(this.configuration).orderShippingAddressesUpdateOrderShippingAddress(orderShippingAddressId, updateOrderShippingAddressRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderShippingDetailsApi - axios parameter creator
 * @export
 */
export const OrderShippingDetailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOrderShippingDetailRequest} createOrderShippingDetailRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderShippingDetailsCreateOrderShippingDetail: async (createOrderShippingDetailRequest: CreateOrderShippingDetailRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderShippingDetailRequest' is not null or undefined
            assertParamExists('orderShippingDetailsCreateOrderShippingDetail', 'createOrderShippingDetailRequest', createOrderShippingDetailRequest)
            const localVarPath = `/api/OrderShippingDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderShippingDetailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderShippingDetailId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderShippingDetailsGetOrderShippingDetailById: async (orderShippingDetailId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderShippingDetailId' is not null or undefined
            assertParamExists('orderShippingDetailsGetOrderShippingDetailById', 'orderShippingDetailId', orderShippingDetailId)
            const localVarPath = `/api/OrderShippingDetails/{orderShippingDetailId}`
                .replace(`{${"orderShippingDetailId"}}`, encodeURIComponent(String(orderShippingDetailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderShippingDetailId 
         * @param {UpdateOrderShippingDetailRequest} updateOrderShippingDetailRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderShippingDetailsUpdateOrderShippingDetail: async (orderShippingDetailId: string, updateOrderShippingDetailRequest: UpdateOrderShippingDetailRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderShippingDetailId' is not null or undefined
            assertParamExists('orderShippingDetailsUpdateOrderShippingDetail', 'orderShippingDetailId', orderShippingDetailId)
            // verify required parameter 'updateOrderShippingDetailRequest' is not null or undefined
            assertParamExists('orderShippingDetailsUpdateOrderShippingDetail', 'updateOrderShippingDetailRequest', updateOrderShippingDetailRequest)
            const localVarPath = `/api/OrderShippingDetails/{orderShippingDetailId}`
                .replace(`{${"orderShippingDetailId"}}`, encodeURIComponent(String(orderShippingDetailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderShippingDetailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderShippingDetailsApi - functional programming interface
 * @export
 */
export const OrderShippingDetailsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderShippingDetailsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateOrderShippingDetailRequest} createOrderShippingDetailRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderShippingDetailsCreateOrderShippingDetail(createOrderShippingDetailRequest: CreateOrderShippingDetailRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderShippingDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderShippingDetailsCreateOrderShippingDetail(createOrderShippingDetailRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderShippingDetailId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderShippingDetailsGetOrderShippingDetailById(orderShippingDetailId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderShippingDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderShippingDetailsGetOrderShippingDetailById(orderShippingDetailId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderShippingDetailId 
         * @param {UpdateOrderShippingDetailRequest} updateOrderShippingDetailRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderShippingDetailsUpdateOrderShippingDetail(orderShippingDetailId: string, updateOrderShippingDetailRequest: UpdateOrderShippingDetailRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderShippingDetailsUpdateOrderShippingDetail(orderShippingDetailId, updateOrderShippingDetailRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderShippingDetailsApi - factory interface
 * @export
 */
export const OrderShippingDetailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderShippingDetailsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateOrderShippingDetailRequest} createOrderShippingDetailRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderShippingDetailsCreateOrderShippingDetail(createOrderShippingDetailRequest: CreateOrderShippingDetailRequest, xVersion?: string, options?: any): AxiosPromise<OrderShippingDetailResponse> {
            return localVarFp.orderShippingDetailsCreateOrderShippingDetail(createOrderShippingDetailRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderShippingDetailId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderShippingDetailsGetOrderShippingDetailById(orderShippingDetailId: string, xVersion?: string, options?: any): AxiosPromise<OrderShippingDetailResponse> {
            return localVarFp.orderShippingDetailsGetOrderShippingDetailById(orderShippingDetailId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderShippingDetailId 
         * @param {UpdateOrderShippingDetailRequest} updateOrderShippingDetailRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderShippingDetailsUpdateOrderShippingDetail(orderShippingDetailId: string, updateOrderShippingDetailRequest: UpdateOrderShippingDetailRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.orderShippingDetailsUpdateOrderShippingDetail(orderShippingDetailId, updateOrderShippingDetailRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderShippingDetailsApi - object-oriented interface
 * @export
 * @class OrderShippingDetailsApi
 * @extends {BaseAPI}
 */
export class OrderShippingDetailsApi extends BaseAPI {
    /**
     * 
     * @param {CreateOrderShippingDetailRequest} createOrderShippingDetailRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderShippingDetailsApi
     */
    public orderShippingDetailsCreateOrderShippingDetail(createOrderShippingDetailRequest: CreateOrderShippingDetailRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderShippingDetailsApiFp(this.configuration).orderShippingDetailsCreateOrderShippingDetail(createOrderShippingDetailRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderShippingDetailId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderShippingDetailsApi
     */
    public orderShippingDetailsGetOrderShippingDetailById(orderShippingDetailId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderShippingDetailsApiFp(this.configuration).orderShippingDetailsGetOrderShippingDetailById(orderShippingDetailId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderShippingDetailId 
     * @param {UpdateOrderShippingDetailRequest} updateOrderShippingDetailRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderShippingDetailsApi
     */
    public orderShippingDetailsUpdateOrderShippingDetail(orderShippingDetailId: string, updateOrderShippingDetailRequest: UpdateOrderShippingDetailRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderShippingDetailsApiFp(this.configuration).orderShippingDetailsUpdateOrderShippingDetail(orderShippingDetailId, updateOrderShippingDetailRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderStateHistoriesApi - axios parameter creator
 * @export
 */
export const OrderStateHistoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOrderStateHistoryRequest} createOrderStateHistoryRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderStateHistoriesCreateOrderStateHistory: async (createOrderStateHistoryRequest: CreateOrderStateHistoryRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderStateHistoryRequest' is not null or undefined
            assertParamExists('orderStateHistoriesCreateOrderStateHistory', 'createOrderStateHistoryRequest', createOrderStateHistoryRequest)
            const localVarPath = `/api/OrderStateHistories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderStateHistoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderStateHistoryId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderStateHistoriesDeleteOrderStateHistory: async (orderStateHistoryId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderStateHistoryId' is not null or undefined
            assertParamExists('orderStateHistoriesDeleteOrderStateHistory', 'orderStateHistoryId', orderStateHistoryId)
            const localVarPath = `/api/OrderStateHistories/{orderStateHistoryId}`
                .replace(`{${"orderStateHistoryId"}}`, encodeURIComponent(String(orderStateHistoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderId] 
         * @param {string} [orderStateId] 
         * @param {OrderStateContext} [orderStateContext] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderStateHistoriesGetOrderStateHistories: async (orderId?: string, orderStateId?: string, orderStateContext?: OrderStateContext, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrderStateHistories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['OrderId'] = orderId;
            }

            if (orderStateId !== undefined) {
                localVarQueryParameter['OrderStateId'] = orderStateId;
            }

            if (orderStateContext !== undefined) {
                localVarQueryParameter['OrderStateContext'] = orderStateContext;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderStateHistoryId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderStateHistoriesGetOrderStateHistoryById: async (orderStateHistoryId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderStateHistoryId' is not null or undefined
            assertParamExists('orderStateHistoriesGetOrderStateHistoryById', 'orderStateHistoryId', orderStateHistoryId)
            const localVarPath = `/api/OrderStateHistories/{orderStateHistoryId}`
                .replace(`{${"orderStateHistoryId"}}`, encodeURIComponent(String(orderStateHistoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderStateHistoryId 
         * @param {UpdateOrderStateHistoryRequest} updateOrderStateHistoryRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderStateHistoriesUpdateOrderStateHistory: async (orderStateHistoryId: string, updateOrderStateHistoryRequest: UpdateOrderStateHistoryRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderStateHistoryId' is not null or undefined
            assertParamExists('orderStateHistoriesUpdateOrderStateHistory', 'orderStateHistoryId', orderStateHistoryId)
            // verify required parameter 'updateOrderStateHistoryRequest' is not null or undefined
            assertParamExists('orderStateHistoriesUpdateOrderStateHistory', 'updateOrderStateHistoryRequest', updateOrderStateHistoryRequest)
            const localVarPath = `/api/OrderStateHistories/{orderStateHistoryId}`
                .replace(`{${"orderStateHistoryId"}}`, encodeURIComponent(String(orderStateHistoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderStateHistoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderStateHistoriesApi - functional programming interface
 * @export
 */
export const OrderStateHistoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderStateHistoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateOrderStateHistoryRequest} createOrderStateHistoryRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderStateHistoriesCreateOrderStateHistory(createOrderStateHistoryRequest: CreateOrderStateHistoryRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderStateHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderStateHistoriesCreateOrderStateHistory(createOrderStateHistoryRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderStateHistoryId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderStateHistoriesDeleteOrderStateHistory(orderStateHistoryId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderStateHistoriesDeleteOrderStateHistory(orderStateHistoryId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [orderId] 
         * @param {string} [orderStateId] 
         * @param {OrderStateContext} [orderStateContext] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderStateHistoriesGetOrderStateHistories(orderId?: string, orderStateId?: string, orderStateContext?: OrderStateContext, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderStateHistoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderStateHistoriesGetOrderStateHistories(orderId, orderStateId, orderStateContext, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderStateHistoryId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderStateHistoriesGetOrderStateHistoryById(orderStateHistoryId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderStateHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderStateHistoriesGetOrderStateHistoryById(orderStateHistoryId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderStateHistoryId 
         * @param {UpdateOrderStateHistoryRequest} updateOrderStateHistoryRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderStateHistoriesUpdateOrderStateHistory(orderStateHistoryId: string, updateOrderStateHistoryRequest: UpdateOrderStateHistoryRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderStateHistoriesUpdateOrderStateHistory(orderStateHistoryId, updateOrderStateHistoryRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderStateHistoriesApi - factory interface
 * @export
 */
export const OrderStateHistoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderStateHistoriesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateOrderStateHistoryRequest} createOrderStateHistoryRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderStateHistoriesCreateOrderStateHistory(createOrderStateHistoryRequest: CreateOrderStateHistoryRequest, xVersion?: string, options?: any): AxiosPromise<OrderStateHistoryResponse> {
            return localVarFp.orderStateHistoriesCreateOrderStateHistory(createOrderStateHistoryRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderStateHistoryId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderStateHistoriesDeleteOrderStateHistory(orderStateHistoryId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.orderStateHistoriesDeleteOrderStateHistory(orderStateHistoryId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderId] 
         * @param {string} [orderStateId] 
         * @param {OrderStateContext} [orderStateContext] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderStateHistoriesGetOrderStateHistories(orderId?: string, orderStateId?: string, orderStateContext?: OrderStateContext, xVersion?: string, options?: any): AxiosPromise<Array<OrderStateHistoryResponse>> {
            return localVarFp.orderStateHistoriesGetOrderStateHistories(orderId, orderStateId, orderStateContext, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderStateHistoryId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderStateHistoriesGetOrderStateHistoryById(orderStateHistoryId: string, xVersion?: string, options?: any): AxiosPromise<OrderStateHistoryResponse> {
            return localVarFp.orderStateHistoriesGetOrderStateHistoryById(orderStateHistoryId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderStateHistoryId 
         * @param {UpdateOrderStateHistoryRequest} updateOrderStateHistoryRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderStateHistoriesUpdateOrderStateHistory(orderStateHistoryId: string, updateOrderStateHistoryRequest: UpdateOrderStateHistoryRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.orderStateHistoriesUpdateOrderStateHistory(orderStateHistoryId, updateOrderStateHistoryRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderStateHistoriesApi - object-oriented interface
 * @export
 * @class OrderStateHistoriesApi
 * @extends {BaseAPI}
 */
export class OrderStateHistoriesApi extends BaseAPI {
    /**
     * 
     * @param {CreateOrderStateHistoryRequest} createOrderStateHistoryRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderStateHistoriesApi
     */
    public orderStateHistoriesCreateOrderStateHistory(createOrderStateHistoryRequest: CreateOrderStateHistoryRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderStateHistoriesApiFp(this.configuration).orderStateHistoriesCreateOrderStateHistory(createOrderStateHistoryRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderStateHistoryId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderStateHistoriesApi
     */
    public orderStateHistoriesDeleteOrderStateHistory(orderStateHistoryId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderStateHistoriesApiFp(this.configuration).orderStateHistoriesDeleteOrderStateHistory(orderStateHistoryId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [orderId] 
     * @param {string} [orderStateId] 
     * @param {OrderStateContext} [orderStateContext] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderStateHistoriesApi
     */
    public orderStateHistoriesGetOrderStateHistories(orderId?: string, orderStateId?: string, orderStateContext?: OrderStateContext, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderStateHistoriesApiFp(this.configuration).orderStateHistoriesGetOrderStateHistories(orderId, orderStateId, orderStateContext, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderStateHistoryId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderStateHistoriesApi
     */
    public orderStateHistoriesGetOrderStateHistoryById(orderStateHistoryId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderStateHistoriesApiFp(this.configuration).orderStateHistoriesGetOrderStateHistoryById(orderStateHistoryId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderStateHistoryId 
     * @param {UpdateOrderStateHistoryRequest} updateOrderStateHistoryRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderStateHistoriesApi
     */
    public orderStateHistoriesUpdateOrderStateHistory(orderStateHistoryId: string, updateOrderStateHistoryRequest: UpdateOrderStateHistoryRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return OrderStateHistoriesApiFp(this.configuration).orderStateHistoriesUpdateOrderStateHistory(orderStateHistoryId, updateOrderStateHistoryRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} orderId 
         * @param {string} orderReceiptId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersCancelOrderReceipt: async (orderId: string, orderReceiptId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('ordersCancelOrderReceipt', 'orderId', orderId)
            // verify required parameter 'orderReceiptId' is not null or undefined
            assertParamExists('ordersCancelOrderReceipt', 'orderReceiptId', orderReceiptId)
            const localVarPath = `/api/Orders/{orderId}/receipt/{orderReceiptId}/cancel`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"orderReceiptId"}}`, encodeURIComponent(String(orderReceiptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrderRequest} createOrderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersCreateOrder: async (createOrderRequest: CreateOrderRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderRequest' is not null or undefined
            assertParamExists('ordersCreateOrder', 'createOrderRequest', createOrderRequest)
            const localVarPath = `/api/Orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersDeleteOrder: async (orderId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('ordersDeleteOrder', 'orderId', orderId)
            const localVarPath = `/api/Orders/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {boolean} [asPopulated] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGetOrderById: async (orderId: string, asPopulated?: boolean, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('ordersGetOrderById', 'orderId', orderId)
            const localVarPath = `/api/Orders/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (asPopulated !== undefined) {
                localVarQueryParameter['AsPopulated'] = asPopulated;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} orderReceiptId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGetOrderReceiptDownload: async (orderId: string, orderReceiptId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('ordersGetOrderReceiptDownload', 'orderId', orderId)
            // verify required parameter 'orderReceiptId' is not null or undefined
            assertParamExists('ordersGetOrderReceiptDownload', 'orderReceiptId', orderReceiptId)
            const localVarPath = `/api/Orders/{orderId}/receipt/{orderReceiptId}/download`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"orderReceiptId"}}`, encodeURIComponent(String(orderReceiptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [customerId] 
         * @param {string} [orderDateTimeFrom] 
         * @param {string} [orderDateTimeTo] 
         * @param {number} [limit] 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGetOrders: async (customerId?: string, orderDateTimeFrom?: string, orderDateTimeTo?: string, limit?: number, manufacturerId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['CustomerId'] = customerId;
            }

            if (orderDateTimeFrom !== undefined) {
                localVarQueryParameter['OrderDateTimeFrom'] = (orderDateTimeFrom as any instanceof Date) ?
                    (orderDateTimeFrom as any).toISOString() :
                    orderDateTimeFrom;
            }

            if (orderDateTimeTo !== undefined) {
                localVarQueryParameter['OrderDateTimeTo'] = (orderDateTimeTo as any instanceof Date) ?
                    (orderDateTimeTo as any).toISOString() :
                    orderDateTimeTo;
            }

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [customerId] 
         * @param {string} [orderDateTimeFrom] 
         * @param {string} [orderDateTimeTo] 
         * @param {number} [limit] 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGetSimpleOrdersList: async (customerId?: string, orderDateTimeFrom?: string, orderDateTimeTo?: string, limit?: number, manufacturerId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Orders/simpleOrdersList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['CustomerId'] = customerId;
            }

            if (orderDateTimeFrom !== undefined) {
                localVarQueryParameter['OrderDateTimeFrom'] = (orderDateTimeFrom as any instanceof Date) ?
                    (orderDateTimeFrom as any).toISOString() :
                    orderDateTimeFrom;
            }

            if (orderDateTimeTo !== undefined) {
                localVarQueryParameter['OrderDateTimeTo'] = (orderDateTimeTo as any instanceof Date) ?
                    (orderDateTimeTo as any).toISOString() :
                    orderDateTimeTo;
            }

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {UpdateOrderRequest} updateOrderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersUpdateOrder: async (orderId: string, updateOrderRequest: UpdateOrderRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('ordersUpdateOrder', 'orderId', orderId)
            // verify required parameter 'updateOrderRequest' is not null or undefined
            assertParamExists('ordersUpdateOrder', 'updateOrderRequest', updateOrderRequest)
            const localVarPath = `/api/Orders/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} orderId 
         * @param {string} orderReceiptId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersCancelOrderReceipt(orderId: string, orderReceiptId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersCancelOrderReceipt(orderId, orderReceiptId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateOrderRequest} createOrderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersCreateOrder(createOrderRequest: CreateOrderRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersCreateOrder(createOrderRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersDeleteOrder(orderId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersDeleteOrder(orderId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {boolean} [asPopulated] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersGetOrderById(orderId: string, asPopulated?: boolean, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersGetOrderById(orderId, asPopulated, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} orderReceiptId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersGetOrderReceiptDownload(orderId: string, orderReceiptId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersGetOrderReceiptDownload(orderId, orderReceiptId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [customerId] 
         * @param {string} [orderDateTimeFrom] 
         * @param {string} [orderDateTimeTo] 
         * @param {number} [limit] 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersGetOrders(customerId?: string, orderDateTimeFrom?: string, orderDateTimeTo?: string, limit?: number, manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersGetOrders(customerId, orderDateTimeFrom, orderDateTimeTo, limit, manufacturerId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [customerId] 
         * @param {string} [orderDateTimeFrom] 
         * @param {string} [orderDateTimeTo] 
         * @param {number} [limit] 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersGetSimpleOrdersList(customerId?: string, orderDateTimeFrom?: string, orderDateTimeTo?: string, limit?: number, manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SimpleOrderResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersGetSimpleOrdersList(customerId, orderDateTimeFrom, orderDateTimeTo, limit, manufacturerId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {UpdateOrderRequest} updateOrderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersUpdateOrder(orderId: string, updateOrderRequest: UpdateOrderRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersUpdateOrder(orderId, updateOrderRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrdersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} orderId 
         * @param {string} orderReceiptId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersCancelOrderReceipt(orderId: string, orderReceiptId: string, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.ordersCancelOrderReceipt(orderId, orderReceiptId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateOrderRequest} createOrderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersCreateOrder(createOrderRequest: CreateOrderRequest, xVersion?: string, options?: any): AxiosPromise<OrderResponse> {
            return localVarFp.ordersCreateOrder(createOrderRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersDeleteOrder(orderId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ordersDeleteOrder(orderId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {boolean} [asPopulated] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGetOrderById(orderId: string, asPopulated?: boolean, xVersion?: string, options?: any): AxiosPromise<OrderResponse> {
            return localVarFp.ordersGetOrderById(orderId, asPopulated, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} orderReceiptId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGetOrderReceiptDownload(orderId: string, orderReceiptId: string, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.ordersGetOrderReceiptDownload(orderId, orderReceiptId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [customerId] 
         * @param {string} [orderDateTimeFrom] 
         * @param {string} [orderDateTimeTo] 
         * @param {number} [limit] 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGetOrders(customerId?: string, orderDateTimeFrom?: string, orderDateTimeTo?: string, limit?: number, manufacturerId?: string, xVersion?: string, options?: any): AxiosPromise<Array<OrderResponse>> {
            return localVarFp.ordersGetOrders(customerId, orderDateTimeFrom, orderDateTimeTo, limit, manufacturerId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [customerId] 
         * @param {string} [orderDateTimeFrom] 
         * @param {string} [orderDateTimeTo] 
         * @param {number} [limit] 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGetSimpleOrdersList(customerId?: string, orderDateTimeFrom?: string, orderDateTimeTo?: string, limit?: number, manufacturerId?: string, xVersion?: string, options?: any): AxiosPromise<Array<SimpleOrderResponse>> {
            return localVarFp.ordersGetSimpleOrdersList(customerId, orderDateTimeFrom, orderDateTimeTo, limit, manufacturerId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {UpdateOrderRequest} updateOrderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersUpdateOrder(orderId: string, updateOrderRequest: UpdateOrderRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ordersUpdateOrder(orderId, updateOrderRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI {
    /**
     * 
     * @param {string} orderId 
     * @param {string} orderReceiptId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersCancelOrderReceipt(orderId: string, orderReceiptId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersCancelOrderReceipt(orderId, orderReceiptId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateOrderRequest} createOrderRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersCreateOrder(createOrderRequest: CreateOrderRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersCreateOrder(createOrderRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersDeleteOrder(orderId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersDeleteOrder(orderId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {boolean} [asPopulated] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersGetOrderById(orderId: string, asPopulated?: boolean, xVersion?: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersGetOrderById(orderId, asPopulated, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {string} orderReceiptId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersGetOrderReceiptDownload(orderId: string, orderReceiptId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersGetOrderReceiptDownload(orderId, orderReceiptId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [customerId] 
     * @param {string} [orderDateTimeFrom] 
     * @param {string} [orderDateTimeTo] 
     * @param {number} [limit] 
     * @param {string} [manufacturerId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersGetOrders(customerId?: string, orderDateTimeFrom?: string, orderDateTimeTo?: string, limit?: number, manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersGetOrders(customerId, orderDateTimeFrom, orderDateTimeTo, limit, manufacturerId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [customerId] 
     * @param {string} [orderDateTimeFrom] 
     * @param {string} [orderDateTimeTo] 
     * @param {number} [limit] 
     * @param {string} [manufacturerId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersGetSimpleOrdersList(customerId?: string, orderDateTimeFrom?: string, orderDateTimeTo?: string, limit?: number, manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersGetSimpleOrdersList(customerId, orderDateTimeFrom, orderDateTimeTo, limit, manufacturerId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {UpdateOrderRequest} updateOrderRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersUpdateOrder(orderId: string, updateOrderRequest: UpdateOrderRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).ordersUpdateOrder(orderId, updateOrderRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentProvidersApi - axios parameter creator
 * @export
 */
export const PaymentProvidersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePaymentProviderRequest} createPaymentProviderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentProvidersCreatePaymentProvider: async (createPaymentProviderRequest: CreatePaymentProviderRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPaymentProviderRequest' is not null or undefined
            assertParamExists('paymentProvidersCreatePaymentProvider', 'createPaymentProviderRequest', createPaymentProviderRequest)
            const localVarPath = `/api/PaymentProviders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPaymentProviderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} paymentProviderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentProvidersDeletePaymentProvider: async (paymentProviderId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentProviderId' is not null or undefined
            assertParamExists('paymentProvidersDeletePaymentProvider', 'paymentProviderId', paymentProviderId)
            const localVarPath = `/api/PaymentProviders/{paymentProviderId}`
                .replace(`{${"paymentProviderId"}}`, encodeURIComponent(String(paymentProviderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} paymentProviderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentProvidersGetPaymentProviderById: async (paymentProviderId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentProviderId' is not null or undefined
            assertParamExists('paymentProvidersGetPaymentProviderById', 'paymentProviderId', paymentProviderId)
            const localVarPath = `/api/PaymentProviders/{paymentProviderId}`
                .replace(`{${"paymentProviderId"}}`, encodeURIComponent(String(paymentProviderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentProvidersGetPaymentProviders: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PaymentProviders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} paymentProviderId 
         * @param {UpdatePaymentProviderRequest} updatePaymentProviderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentProvidersUpdatePaymentProvider: async (paymentProviderId: string, updatePaymentProviderRequest: UpdatePaymentProviderRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentProviderId' is not null or undefined
            assertParamExists('paymentProvidersUpdatePaymentProvider', 'paymentProviderId', paymentProviderId)
            // verify required parameter 'updatePaymentProviderRequest' is not null or undefined
            assertParamExists('paymentProvidersUpdatePaymentProvider', 'updatePaymentProviderRequest', updatePaymentProviderRequest)
            const localVarPath = `/api/PaymentProviders/{paymentProviderId}`
                .replace(`{${"paymentProviderId"}}`, encodeURIComponent(String(paymentProviderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePaymentProviderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentProvidersApi - functional programming interface
 * @export
 */
export const PaymentProvidersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentProvidersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePaymentProviderRequest} createPaymentProviderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentProvidersCreatePaymentProvider(createPaymentProviderRequest: CreatePaymentProviderRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentProviderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentProvidersCreatePaymentProvider(createPaymentProviderRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} paymentProviderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentProvidersDeletePaymentProvider(paymentProviderId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentProvidersDeletePaymentProvider(paymentProviderId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} paymentProviderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentProvidersGetPaymentProviderById(paymentProviderId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentProviderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentProvidersGetPaymentProviderById(paymentProviderId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentProvidersGetPaymentProviders(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaymentProviderResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentProvidersGetPaymentProviders(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} paymentProviderId 
         * @param {UpdatePaymentProviderRequest} updatePaymentProviderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentProvidersUpdatePaymentProvider(paymentProviderId: string, updatePaymentProviderRequest: UpdatePaymentProviderRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentProvidersUpdatePaymentProvider(paymentProviderId, updatePaymentProviderRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentProvidersApi - factory interface
 * @export
 */
export const PaymentProvidersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentProvidersApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePaymentProviderRequest} createPaymentProviderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentProvidersCreatePaymentProvider(createPaymentProviderRequest: CreatePaymentProviderRequest, xVersion?: string, options?: any): AxiosPromise<PaymentProviderResponse> {
            return localVarFp.paymentProvidersCreatePaymentProvider(createPaymentProviderRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} paymentProviderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentProvidersDeletePaymentProvider(paymentProviderId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.paymentProvidersDeletePaymentProvider(paymentProviderId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} paymentProviderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentProvidersGetPaymentProviderById(paymentProviderId: string, xVersion?: string, options?: any): AxiosPromise<PaymentProviderResponse> {
            return localVarFp.paymentProvidersGetPaymentProviderById(paymentProviderId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentProvidersGetPaymentProviders(xVersion?: string, options?: any): AxiosPromise<Array<PaymentProviderResponse>> {
            return localVarFp.paymentProvidersGetPaymentProviders(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} paymentProviderId 
         * @param {UpdatePaymentProviderRequest} updatePaymentProviderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentProvidersUpdatePaymentProvider(paymentProviderId: string, updatePaymentProviderRequest: UpdatePaymentProviderRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.paymentProvidersUpdatePaymentProvider(paymentProviderId, updatePaymentProviderRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentProvidersApi - object-oriented interface
 * @export
 * @class PaymentProvidersApi
 * @extends {BaseAPI}
 */
export class PaymentProvidersApi extends BaseAPI {
    /**
     * 
     * @param {CreatePaymentProviderRequest} createPaymentProviderRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentProvidersApi
     */
    public paymentProvidersCreatePaymentProvider(createPaymentProviderRequest: CreatePaymentProviderRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return PaymentProvidersApiFp(this.configuration).paymentProvidersCreatePaymentProvider(createPaymentProviderRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} paymentProviderId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentProvidersApi
     */
    public paymentProvidersDeletePaymentProvider(paymentProviderId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return PaymentProvidersApiFp(this.configuration).paymentProvidersDeletePaymentProvider(paymentProviderId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} paymentProviderId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentProvidersApi
     */
    public paymentProvidersGetPaymentProviderById(paymentProviderId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return PaymentProvidersApiFp(this.configuration).paymentProvidersGetPaymentProviderById(paymentProviderId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentProvidersApi
     */
    public paymentProvidersGetPaymentProviders(xVersion?: string, options?: AxiosRequestConfig) {
        return PaymentProvidersApiFp(this.configuration).paymentProvidersGetPaymentProviders(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} paymentProviderId 
     * @param {UpdatePaymentProviderRequest} updatePaymentProviderRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentProvidersApi
     */
    public paymentProvidersUpdatePaymentProvider(paymentProviderId: string, updatePaymentProviderRequest: UpdatePaymentProviderRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return PaymentProvidersApiFp(this.configuration).paymentProvidersUpdatePaymentProvider(paymentProviderId, updatePaymentProviderRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentTransactionsApi - axios parameter creator
 * @export
 */
export const PaymentTransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} pspTransactionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentTransactionsGetTransactionById: async (pspTransactionId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pspTransactionId' is not null or undefined
            assertParamExists('paymentTransactionsGetTransactionById', 'pspTransactionId', pspTransactionId)
            const localVarPath = `/api/PaymentTransactions/{pspTransactionId}`
                .replace(`{${"pspTransactionId"}}`, encodeURIComponent(String(pspTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetPaymentTransactionsRequest} getPaymentTransactionsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentTransactionsGetTransactions: async (getPaymentTransactionsRequest: GetPaymentTransactionsRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getPaymentTransactionsRequest' is not null or undefined
            assertParamExists('paymentTransactionsGetTransactions', 'getPaymentTransactionsRequest', getPaymentTransactionsRequest)
            const localVarPath = `/api/PaymentTransactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getPaymentTransactionsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentTransactionsApi - functional programming interface
 * @export
 */
export const PaymentTransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentTransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} pspTransactionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentTransactionsGetTransactionById(pspTransactionId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdyenPaymentTransactionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentTransactionsGetTransactionById(pspTransactionId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetPaymentTransactionsRequest} getPaymentTransactionsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentTransactionsGetTransactions(getPaymentTransactionsRequest: GetPaymentTransactionsRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdyenPaymentTransactionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentTransactionsGetTransactions(getPaymentTransactionsRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentTransactionsApi - factory interface
 * @export
 */
export const PaymentTransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentTransactionsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} pspTransactionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentTransactionsGetTransactionById(pspTransactionId: string, xVersion?: string, options?: any): AxiosPromise<AdyenPaymentTransactionResponse> {
            return localVarFp.paymentTransactionsGetTransactionById(pspTransactionId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetPaymentTransactionsRequest} getPaymentTransactionsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentTransactionsGetTransactions(getPaymentTransactionsRequest: GetPaymentTransactionsRequest, xVersion?: string, options?: any): AxiosPromise<AdyenPaymentTransactionsResponse> {
            return localVarFp.paymentTransactionsGetTransactions(getPaymentTransactionsRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentTransactionsApi - object-oriented interface
 * @export
 * @class PaymentTransactionsApi
 * @extends {BaseAPI}
 */
export class PaymentTransactionsApi extends BaseAPI {
    /**
     * 
     * @param {string} pspTransactionId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentTransactionsApi
     */
    public paymentTransactionsGetTransactionById(pspTransactionId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return PaymentTransactionsApiFp(this.configuration).paymentTransactionsGetTransactionById(pspTransactionId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetPaymentTransactionsRequest} getPaymentTransactionsRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentTransactionsApi
     */
    public paymentTransactionsGetTransactions(getPaymentTransactionsRequest: GetPaymentTransactionsRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return PaymentTransactionsApiFp(this.configuration).paymentTransactionsGetTransactions(getPaymentTransactionsRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PoliciesApi - axios parameter creator
 * @export
 */
export const PoliciesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesGetPolicies: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PoliciesApi - functional programming interface
 * @export
 */
export const PoliciesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PoliciesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policiesGetPolicies(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PolicyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policiesGetPolicies(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PoliciesApi - factory interface
 * @export
 */
export const PoliciesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PoliciesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesGetPolicies(xVersion?: string, options?: any): AxiosPromise<Array<PolicyResponse>> {
            return localVarFp.policiesGetPolicies(xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PoliciesApi - object-oriented interface
 * @export
 * @class PoliciesApi
 * @extends {BaseAPI}
 */
export class PoliciesApi extends BaseAPI {
    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoliciesApi
     */
    public policiesGetPolicies(xVersion?: string, options?: AxiosRequestConfig) {
        return PoliciesApiFp(this.configuration).policiesGetPolicies(xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductAdditionalInformationApi - axios parameter creator
 * @export
 */
export const ProductAdditionalInformationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} productAdditionalInformationId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAdditionalInformationDeleteProductAdditionalInformation: async (productAdditionalInformationId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productAdditionalInformationId' is not null or undefined
            assertParamExists('productAdditionalInformationDeleteProductAdditionalInformation', 'productAdditionalInformationId', productAdditionalInformationId)
            const localVarPath = `/api/ProductAdditionalInformation/{productAdditionalInformationId}`
                .replace(`{${"productAdditionalInformationId"}}`, encodeURIComponent(String(productAdditionalInformationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductAdditionalInformationApi - functional programming interface
 * @export
 */
export const ProductAdditionalInformationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductAdditionalInformationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} productAdditionalInformationId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productAdditionalInformationDeleteProductAdditionalInformation(productAdditionalInformationId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productAdditionalInformationDeleteProductAdditionalInformation(productAdditionalInformationId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductAdditionalInformationApi - factory interface
 * @export
 */
export const ProductAdditionalInformationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductAdditionalInformationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} productAdditionalInformationId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAdditionalInformationDeleteProductAdditionalInformation(productAdditionalInformationId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productAdditionalInformationDeleteProductAdditionalInformation(productAdditionalInformationId, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductAdditionalInformationApi - object-oriented interface
 * @export
 * @class ProductAdditionalInformationApi
 * @extends {BaseAPI}
 */
export class ProductAdditionalInformationApi extends BaseAPI {
    /**
     * 
     * @param {string} productAdditionalInformationId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAdditionalInformationApi
     */
    public productAdditionalInformationDeleteProductAdditionalInformation(productAdditionalInformationId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductAdditionalInformationApiFp(this.configuration).productAdditionalInformationDeleteProductAdditionalInformation(productAdditionalInformationId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductAssetsApi - axios parameter creator
 * @export
 */
export const ProductAssetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateProductAssetRequest} createProductAssetRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAssetsCreateProductAsset: async (createProductAssetRequest: CreateProductAssetRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProductAssetRequest' is not null or undefined
            assertParamExists('productAssetsCreateProductAsset', 'createProductAssetRequest', createProductAssetRequest)
            const localVarPath = `/api/ProductAssets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProductAssetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productAssetId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAssetsDeleteProductAsset: async (productAssetId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productAssetId' is not null or undefined
            assertParamExists('productAssetsDeleteProductAsset', 'productAssetId', productAssetId)
            const localVarPath = `/api/ProductAssets/{productAssetId}`
                .replace(`{${"productAssetId"}}`, encodeURIComponent(String(productAssetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productAssetId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAssetsGetProductAssetById: async (productAssetId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productAssetId' is not null or undefined
            assertParamExists('productAssetsGetProductAssetById', 'productAssetId', productAssetId)
            const localVarPath = `/api/ProductAssets/{productAssetId}`
                .replace(`{${"productAssetId"}}`, encodeURIComponent(String(productAssetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {boolean} [asNoTracking] 
         * @param {string} [productVersionId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAssetsGetProductAssets: async (productId?: string, asNoTracking?: boolean, productVersionId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProductAssets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productId !== undefined) {
                localVarQueryParameter['ProductId'] = productId;
            }

            if (asNoTracking !== undefined) {
                localVarQueryParameter['AsNoTracking'] = asNoTracking;
            }

            if (productVersionId !== undefined) {
                localVarQueryParameter['ProductVersionId'] = productVersionId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productAssetId 
         * @param {UpdateProductAssetRequest} updateProductAssetRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAssetsUpdateProductAsset: async (productAssetId: string, updateProductAssetRequest: UpdateProductAssetRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productAssetId' is not null or undefined
            assertParamExists('productAssetsUpdateProductAsset', 'productAssetId', productAssetId)
            // verify required parameter 'updateProductAssetRequest' is not null or undefined
            assertParamExists('productAssetsUpdateProductAsset', 'updateProductAssetRequest', updateProductAssetRequest)
            const localVarPath = `/api/ProductAssets/{productAssetId}`
                .replace(`{${"productAssetId"}}`, encodeURIComponent(String(productAssetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductAssetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductAssetsApi - functional programming interface
 * @export
 */
export const ProductAssetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductAssetsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateProductAssetRequest} createProductAssetRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productAssetsCreateProductAsset(createProductAssetRequest: CreateProductAssetRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductAssetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productAssetsCreateProductAsset(createProductAssetRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productAssetId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productAssetsDeleteProductAsset(productAssetId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productAssetsDeleteProductAsset(productAssetId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productAssetId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productAssetsGetProductAssetById(productAssetId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductAssetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productAssetsGetProductAssetById(productAssetId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {boolean} [asNoTracking] 
         * @param {string} [productVersionId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productAssetsGetProductAssets(productId?: string, asNoTracking?: boolean, productVersionId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductAssetResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productAssetsGetProductAssets(productId, asNoTracking, productVersionId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productAssetId 
         * @param {UpdateProductAssetRequest} updateProductAssetRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productAssetsUpdateProductAsset(productAssetId: string, updateProductAssetRequest: UpdateProductAssetRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productAssetsUpdateProductAsset(productAssetId, updateProductAssetRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductAssetsApi - factory interface
 * @export
 */
export const ProductAssetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductAssetsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateProductAssetRequest} createProductAssetRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAssetsCreateProductAsset(createProductAssetRequest: CreateProductAssetRequest, xVersion?: string, options?: any): AxiosPromise<ProductAssetResponse> {
            return localVarFp.productAssetsCreateProductAsset(createProductAssetRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productAssetId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAssetsDeleteProductAsset(productAssetId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productAssetsDeleteProductAsset(productAssetId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productAssetId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAssetsGetProductAssetById(productAssetId: string, xVersion?: string, options?: any): AxiosPromise<ProductAssetResponse> {
            return localVarFp.productAssetsGetProductAssetById(productAssetId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {boolean} [asNoTracking] 
         * @param {string} [productVersionId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAssetsGetProductAssets(productId?: string, asNoTracking?: boolean, productVersionId?: string, xVersion?: string, options?: any): AxiosPromise<Array<ProductAssetResponse>> {
            return localVarFp.productAssetsGetProductAssets(productId, asNoTracking, productVersionId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productAssetId 
         * @param {UpdateProductAssetRequest} updateProductAssetRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAssetsUpdateProductAsset(productAssetId: string, updateProductAssetRequest: UpdateProductAssetRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productAssetsUpdateProductAsset(productAssetId, updateProductAssetRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductAssetsApi - object-oriented interface
 * @export
 * @class ProductAssetsApi
 * @extends {BaseAPI}
 */
export class ProductAssetsApi extends BaseAPI {
    /**
     * 
     * @param {CreateProductAssetRequest} createProductAssetRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAssetsApi
     */
    public productAssetsCreateProductAsset(createProductAssetRequest: CreateProductAssetRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductAssetsApiFp(this.configuration).productAssetsCreateProductAsset(createProductAssetRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productAssetId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAssetsApi
     */
    public productAssetsDeleteProductAsset(productAssetId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductAssetsApiFp(this.configuration).productAssetsDeleteProductAsset(productAssetId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productAssetId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAssetsApi
     */
    public productAssetsGetProductAssetById(productAssetId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductAssetsApiFp(this.configuration).productAssetsGetProductAssetById(productAssetId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [productId] 
     * @param {boolean} [asNoTracking] 
     * @param {string} [productVersionId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAssetsApi
     */
    public productAssetsGetProductAssets(productId?: string, asNoTracking?: boolean, productVersionId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductAssetsApiFp(this.configuration).productAssetsGetProductAssets(productId, asNoTracking, productVersionId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productAssetId 
     * @param {UpdateProductAssetRequest} updateProductAssetRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAssetsApi
     */
    public productAssetsUpdateProductAsset(productAssetId: string, updateProductAssetRequest: UpdateProductAssetRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductAssetsApiFp(this.configuration).productAssetsUpdateProductAsset(productAssetId, updateProductAssetRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductBundlesApi - axios parameter creator
 * @export
 */
export const ProductBundlesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateProductBundleRequest} createProductBundleRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBundlesCreateProductBundle: async (createProductBundleRequest: CreateProductBundleRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProductBundleRequest' is not null or undefined
            assertParamExists('productBundlesCreateProductBundle', 'createProductBundleRequest', createProductBundleRequest)
            const localVarPath = `/api/ProductBundles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProductBundleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productBundleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBundlesDeleteProductBundle: async (productBundleId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productBundleId' is not null or undefined
            assertParamExists('productBundlesDeleteProductBundle', 'productBundleId', productBundleId)
            const localVarPath = `/api/ProductBundles/{productBundleId}`
                .replace(`{${"productBundleId"}}`, encodeURIComponent(String(productBundleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productBundleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBundlesGetProductBundle: async (productBundleId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productBundleId' is not null or undefined
            assertParamExists('productBundlesGetProductBundle', 'productBundleId', productBundleId)
            const localVarPath = `/api/ProductBundles/{productBundleId}`
                .replace(`{${"productBundleId"}}`, encodeURIComponent(String(productBundleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productBundleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBundlesGetProductBundleAssets: async (productBundleId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productBundleId' is not null or undefined
            assertParamExists('productBundlesGetProductBundleAssets', 'productBundleId', productBundleId)
            const localVarPath = `/api/ProductBundles/{productBundleId}/assets`
                .replace(`{${"productBundleId"}}`, encodeURIComponent(String(productBundleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productBundleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBundlesGetProductBundlePositions: async (productBundleId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productBundleId' is not null or undefined
            assertParamExists('productBundlesGetProductBundlePositions', 'productBundleId', productBundleId)
            const localVarPath = `/api/ProductBundles/{productBundleId}/positions`
                .replace(`{${"productBundleId"}}`, encodeURIComponent(String(productBundleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBundlesGetProductBundles: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProductBundles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productBundleId 
         * @param {UpdateProductBundleRequest} updateProductBundleRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBundlesUpdateProductBundle: async (productBundleId: string, updateProductBundleRequest: UpdateProductBundleRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productBundleId' is not null or undefined
            assertParamExists('productBundlesUpdateProductBundle', 'productBundleId', productBundleId)
            // verify required parameter 'updateProductBundleRequest' is not null or undefined
            assertParamExists('productBundlesUpdateProductBundle', 'updateProductBundleRequest', updateProductBundleRequest)
            const localVarPath = `/api/ProductBundles/{productBundleId}`
                .replace(`{${"productBundleId"}}`, encodeURIComponent(String(productBundleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductBundleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productBundleId 
         * @param {Array<UpsertProductBundleAssetRequest>} upsertProductBundleAssetRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBundlesUpsertProductBundleAssets: async (productBundleId: string, upsertProductBundleAssetRequest: Array<UpsertProductBundleAssetRequest>, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productBundleId' is not null or undefined
            assertParamExists('productBundlesUpsertProductBundleAssets', 'productBundleId', productBundleId)
            // verify required parameter 'upsertProductBundleAssetRequest' is not null or undefined
            assertParamExists('productBundlesUpsertProductBundleAssets', 'upsertProductBundleAssetRequest', upsertProductBundleAssetRequest)
            const localVarPath = `/api/ProductBundles/{productBundleId}/assets`
                .replace(`{${"productBundleId"}}`, encodeURIComponent(String(productBundleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertProductBundleAssetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productBundleId 
         * @param {Array<UpsertProductBundlePositionRequest>} upsertProductBundlePositionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBundlesUpsertProductBundlePositions: async (productBundleId: string, upsertProductBundlePositionRequest: Array<UpsertProductBundlePositionRequest>, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productBundleId' is not null or undefined
            assertParamExists('productBundlesUpsertProductBundlePositions', 'productBundleId', productBundleId)
            // verify required parameter 'upsertProductBundlePositionRequest' is not null or undefined
            assertParamExists('productBundlesUpsertProductBundlePositions', 'upsertProductBundlePositionRequest', upsertProductBundlePositionRequest)
            const localVarPath = `/api/ProductBundles/{productBundleId}/positions`
                .replace(`{${"productBundleId"}}`, encodeURIComponent(String(productBundleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertProductBundlePositionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductBundlesApi - functional programming interface
 * @export
 */
export const ProductBundlesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductBundlesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateProductBundleRequest} createProductBundleRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productBundlesCreateProductBundle(createProductBundleRequest: CreateProductBundleRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductBundleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productBundlesCreateProductBundle(createProductBundleRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productBundleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productBundlesDeleteProductBundle(productBundleId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productBundlesDeleteProductBundle(productBundleId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productBundleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productBundlesGetProductBundle(productBundleId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductBundleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productBundlesGetProductBundle(productBundleId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productBundleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productBundlesGetProductBundleAssets(productBundleId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductBundleAssetResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productBundlesGetProductBundleAssets(productBundleId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productBundleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productBundlesGetProductBundlePositions(productBundleId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductBundlePositionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productBundlesGetProductBundlePositions(productBundleId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productBundlesGetProductBundles(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductBundleResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productBundlesGetProductBundles(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productBundleId 
         * @param {UpdateProductBundleRequest} updateProductBundleRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productBundlesUpdateProductBundle(productBundleId: string, updateProductBundleRequest: UpdateProductBundleRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productBundlesUpdateProductBundle(productBundleId, updateProductBundleRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productBundleId 
         * @param {Array<UpsertProductBundleAssetRequest>} upsertProductBundleAssetRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productBundlesUpsertProductBundleAssets(productBundleId: string, upsertProductBundleAssetRequest: Array<UpsertProductBundleAssetRequest>, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productBundlesUpsertProductBundleAssets(productBundleId, upsertProductBundleAssetRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productBundleId 
         * @param {Array<UpsertProductBundlePositionRequest>} upsertProductBundlePositionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productBundlesUpsertProductBundlePositions(productBundleId: string, upsertProductBundlePositionRequest: Array<UpsertProductBundlePositionRequest>, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productBundlesUpsertProductBundlePositions(productBundleId, upsertProductBundlePositionRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductBundlesApi - factory interface
 * @export
 */
export const ProductBundlesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductBundlesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateProductBundleRequest} createProductBundleRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBundlesCreateProductBundle(createProductBundleRequest: CreateProductBundleRequest, xVersion?: string, options?: any): AxiosPromise<ProductBundleResponse> {
            return localVarFp.productBundlesCreateProductBundle(createProductBundleRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productBundleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBundlesDeleteProductBundle(productBundleId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productBundlesDeleteProductBundle(productBundleId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productBundleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBundlesGetProductBundle(productBundleId: string, xVersion?: string, options?: any): AxiosPromise<ProductBundleResponse> {
            return localVarFp.productBundlesGetProductBundle(productBundleId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productBundleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBundlesGetProductBundleAssets(productBundleId: string, xVersion?: string, options?: any): AxiosPromise<Array<ProductBundleAssetResponse>> {
            return localVarFp.productBundlesGetProductBundleAssets(productBundleId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productBundleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBundlesGetProductBundlePositions(productBundleId: string, xVersion?: string, options?: any): AxiosPromise<Array<ProductBundlePositionResponse>> {
            return localVarFp.productBundlesGetProductBundlePositions(productBundleId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBundlesGetProductBundles(xVersion?: string, options?: any): AxiosPromise<Array<ProductBundleResponse>> {
            return localVarFp.productBundlesGetProductBundles(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productBundleId 
         * @param {UpdateProductBundleRequest} updateProductBundleRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBundlesUpdateProductBundle(productBundleId: string, updateProductBundleRequest: UpdateProductBundleRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productBundlesUpdateProductBundle(productBundleId, updateProductBundleRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productBundleId 
         * @param {Array<UpsertProductBundleAssetRequest>} upsertProductBundleAssetRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBundlesUpsertProductBundleAssets(productBundleId: string, upsertProductBundleAssetRequest: Array<UpsertProductBundleAssetRequest>, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.productBundlesUpsertProductBundleAssets(productBundleId, upsertProductBundleAssetRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productBundleId 
         * @param {Array<UpsertProductBundlePositionRequest>} upsertProductBundlePositionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productBundlesUpsertProductBundlePositions(productBundleId: string, upsertProductBundlePositionRequest: Array<UpsertProductBundlePositionRequest>, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.productBundlesUpsertProductBundlePositions(productBundleId, upsertProductBundlePositionRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductBundlesApi - object-oriented interface
 * @export
 * @class ProductBundlesApi
 * @extends {BaseAPI}
 */
export class ProductBundlesApi extends BaseAPI {
    /**
     * 
     * @param {CreateProductBundleRequest} createProductBundleRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductBundlesApi
     */
    public productBundlesCreateProductBundle(createProductBundleRequest: CreateProductBundleRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductBundlesApiFp(this.configuration).productBundlesCreateProductBundle(createProductBundleRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productBundleId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductBundlesApi
     */
    public productBundlesDeleteProductBundle(productBundleId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductBundlesApiFp(this.configuration).productBundlesDeleteProductBundle(productBundleId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productBundleId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductBundlesApi
     */
    public productBundlesGetProductBundle(productBundleId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductBundlesApiFp(this.configuration).productBundlesGetProductBundle(productBundleId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productBundleId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductBundlesApi
     */
    public productBundlesGetProductBundleAssets(productBundleId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductBundlesApiFp(this.configuration).productBundlesGetProductBundleAssets(productBundleId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productBundleId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductBundlesApi
     */
    public productBundlesGetProductBundlePositions(productBundleId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductBundlesApiFp(this.configuration).productBundlesGetProductBundlePositions(productBundleId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductBundlesApi
     */
    public productBundlesGetProductBundles(xVersion?: string, options?: AxiosRequestConfig) {
        return ProductBundlesApiFp(this.configuration).productBundlesGetProductBundles(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productBundleId 
     * @param {UpdateProductBundleRequest} updateProductBundleRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductBundlesApi
     */
    public productBundlesUpdateProductBundle(productBundleId: string, updateProductBundleRequest: UpdateProductBundleRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductBundlesApiFp(this.configuration).productBundlesUpdateProductBundle(productBundleId, updateProductBundleRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productBundleId 
     * @param {Array<UpsertProductBundleAssetRequest>} upsertProductBundleAssetRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductBundlesApi
     */
    public productBundlesUpsertProductBundleAssets(productBundleId: string, upsertProductBundleAssetRequest: Array<UpsertProductBundleAssetRequest>, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductBundlesApiFp(this.configuration).productBundlesUpsertProductBundleAssets(productBundleId, upsertProductBundleAssetRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productBundleId 
     * @param {Array<UpsertProductBundlePositionRequest>} upsertProductBundlePositionRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductBundlesApi
     */
    public productBundlesUpsertProductBundlePositions(productBundleId: string, upsertProductBundlePositionRequest: Array<UpsertProductBundlePositionRequest>, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductBundlesApiFp(this.configuration).productBundlesUpsertProductBundlePositions(productBundleId, upsertProductBundlePositionRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductCategoriesApi - axios parameter creator
 * @export
 */
export const ProductCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateProductCategoryRequest} createProductCategoryRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCategoriesCreateProductCategory: async (createProductCategoryRequest: CreateProductCategoryRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProductCategoryRequest' is not null or undefined
            assertParamExists('productCategoriesCreateProductCategory', 'createProductCategoryRequest', createProductCategoryRequest)
            const localVarPath = `/api/ProductCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProductCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productCategoryId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCategoriesDeleteProductCategory: async (productCategoryId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productCategoryId' is not null or undefined
            assertParamExists('productCategoriesDeleteProductCategory', 'productCategoryId', productCategoryId)
            const localVarPath = `/api/ProductCategories/{productCategoryId}`
                .replace(`{${"productCategoryId"}}`, encodeURIComponent(String(productCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [asTree] 
         * @param {string} [productVersionId] 
         * @param {Array<string>} [productGroupIds] 
         * @param {Array<string>} [animalSpeciesIds] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCategoriesGetProductCategories: async (asTree?: boolean, productVersionId?: string, productGroupIds?: Array<string>, animalSpeciesIds?: Array<string>, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProductCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (asTree !== undefined) {
                localVarQueryParameter['AsTree'] = asTree;
            }

            if (productVersionId !== undefined) {
                localVarQueryParameter['ProductVersionId'] = productVersionId;
            }

            if (productGroupIds) {
                localVarQueryParameter['ProductGroupIds'] = productGroupIds;
            }

            if (animalSpeciesIds) {
                localVarQueryParameter['AnimalSpeciesIds'] = animalSpeciesIds;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productCategoryId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCategoriesGetProductCategoryById: async (productCategoryId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productCategoryId' is not null or undefined
            assertParamExists('productCategoriesGetProductCategoryById', 'productCategoryId', productCategoryId)
            const localVarPath = `/api/ProductCategories/{productCategoryId}`
                .replace(`{${"productCategoryId"}}`, encodeURIComponent(String(productCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DataSynchronizationRequest} dataSynchronizationRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCategoriesSync: async (dataSynchronizationRequest: DataSynchronizationRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataSynchronizationRequest' is not null or undefined
            assertParamExists('productCategoriesSync', 'dataSynchronizationRequest', dataSynchronizationRequest)
            const localVarPath = `/api/ProductCategories/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataSynchronizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productCategoryId 
         * @param {UpdateProductCategoryRequest} updateProductCategoryRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCategoriesUpdateProductCategory: async (productCategoryId: string, updateProductCategoryRequest: UpdateProductCategoryRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productCategoryId' is not null or undefined
            assertParamExists('productCategoriesUpdateProductCategory', 'productCategoryId', productCategoryId)
            // verify required parameter 'updateProductCategoryRequest' is not null or undefined
            assertParamExists('productCategoriesUpdateProductCategory', 'updateProductCategoryRequest', updateProductCategoryRequest)
            const localVarPath = `/api/ProductCategories/{productCategoryId}`
                .replace(`{${"productCategoryId"}}`, encodeURIComponent(String(productCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductCategoriesApi - functional programming interface
 * @export
 */
export const ProductCategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductCategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateProductCategoryRequest} createProductCategoryRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productCategoriesCreateProductCategory(createProductCategoryRequest: CreateProductCategoryRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productCategoriesCreateProductCategory(createProductCategoryRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productCategoryId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productCategoriesDeleteProductCategory(productCategoryId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productCategoriesDeleteProductCategory(productCategoryId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [asTree] 
         * @param {string} [productVersionId] 
         * @param {Array<string>} [productGroupIds] 
         * @param {Array<string>} [animalSpeciesIds] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productCategoriesGetProductCategories(asTree?: boolean, productVersionId?: string, productGroupIds?: Array<string>, animalSpeciesIds?: Array<string>, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productCategoriesGetProductCategories(asTree, productVersionId, productGroupIds, animalSpeciesIds, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productCategoryId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productCategoriesGetProductCategoryById(productCategoryId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productCategoriesGetProductCategoryById(productCategoryId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DataSynchronizationRequest} dataSynchronizationRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productCategoriesSync(dataSynchronizationRequest: DataSynchronizationRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productCategoriesSync(dataSynchronizationRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productCategoryId 
         * @param {UpdateProductCategoryRequest} updateProductCategoryRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productCategoriesUpdateProductCategory(productCategoryId: string, updateProductCategoryRequest: UpdateProductCategoryRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productCategoriesUpdateProductCategory(productCategoryId, updateProductCategoryRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductCategoriesApi - factory interface
 * @export
 */
export const ProductCategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductCategoriesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateProductCategoryRequest} createProductCategoryRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCategoriesCreateProductCategory(createProductCategoryRequest: CreateProductCategoryRequest, xVersion?: string, options?: any): AxiosPromise<ProductCategoryResponse> {
            return localVarFp.productCategoriesCreateProductCategory(createProductCategoryRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productCategoryId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCategoriesDeleteProductCategory(productCategoryId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productCategoriesDeleteProductCategory(productCategoryId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [asTree] 
         * @param {string} [productVersionId] 
         * @param {Array<string>} [productGroupIds] 
         * @param {Array<string>} [animalSpeciesIds] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCategoriesGetProductCategories(asTree?: boolean, productVersionId?: string, productGroupIds?: Array<string>, animalSpeciesIds?: Array<string>, xVersion?: string, options?: any): AxiosPromise<Array<ProductCategoryResponse>> {
            return localVarFp.productCategoriesGetProductCategories(asTree, productVersionId, productGroupIds, animalSpeciesIds, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productCategoryId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCategoriesGetProductCategoryById(productCategoryId: string, xVersion?: string, options?: any): AxiosPromise<ProductCategoryResponse> {
            return localVarFp.productCategoriesGetProductCategoryById(productCategoryId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DataSynchronizationRequest} dataSynchronizationRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCategoriesSync(dataSynchronizationRequest: DataSynchronizationRequest, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.productCategoriesSync(dataSynchronizationRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productCategoryId 
         * @param {UpdateProductCategoryRequest} updateProductCategoryRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCategoriesUpdateProductCategory(productCategoryId: string, updateProductCategoryRequest: UpdateProductCategoryRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productCategoriesUpdateProductCategory(productCategoryId, updateProductCategoryRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductCategoriesApi - object-oriented interface
 * @export
 * @class ProductCategoriesApi
 * @extends {BaseAPI}
 */
export class ProductCategoriesApi extends BaseAPI {
    /**
     * 
     * @param {CreateProductCategoryRequest} createProductCategoryRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoriesApi
     */
    public productCategoriesCreateProductCategory(createProductCategoryRequest: CreateProductCategoryRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductCategoriesApiFp(this.configuration).productCategoriesCreateProductCategory(createProductCategoryRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productCategoryId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoriesApi
     */
    public productCategoriesDeleteProductCategory(productCategoryId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductCategoriesApiFp(this.configuration).productCategoriesDeleteProductCategory(productCategoryId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [asTree] 
     * @param {string} [productVersionId] 
     * @param {Array<string>} [productGroupIds] 
     * @param {Array<string>} [animalSpeciesIds] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoriesApi
     */
    public productCategoriesGetProductCategories(asTree?: boolean, productVersionId?: string, productGroupIds?: Array<string>, animalSpeciesIds?: Array<string>, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductCategoriesApiFp(this.configuration).productCategoriesGetProductCategories(asTree, productVersionId, productGroupIds, animalSpeciesIds, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productCategoryId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoriesApi
     */
    public productCategoriesGetProductCategoryById(productCategoryId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductCategoriesApiFp(this.configuration).productCategoriesGetProductCategoryById(productCategoryId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataSynchronizationRequest} dataSynchronizationRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoriesApi
     */
    public productCategoriesSync(dataSynchronizationRequest: DataSynchronizationRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductCategoriesApiFp(this.configuration).productCategoriesSync(dataSynchronizationRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productCategoryId 
     * @param {UpdateProductCategoryRequest} updateProductCategoryRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoriesApi
     */
    public productCategoriesUpdateProductCategory(productCategoryId: string, updateProductCategoryRequest: UpdateProductCategoryRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductCategoriesApiFp(this.configuration).productCategoriesUpdateProductCategory(productCategoryId, updateProductCategoryRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductGroupMappingsApi - axios parameter creator
 * @export
 */
export const ProductGroupMappingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateProductGroupMappingRequest} createProductGroupMappingRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGroupMappingsCreateProductGroupMapping: async (createProductGroupMappingRequest: CreateProductGroupMappingRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProductGroupMappingRequest' is not null or undefined
            assertParamExists('productGroupMappingsCreateProductGroupMapping', 'createProductGroupMappingRequest', createProductGroupMappingRequest)
            const localVarPath = `/api/ProductGroupMappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProductGroupMappingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productGroupMappingId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGroupMappingsDeleteProductGroupMapping: async (productGroupMappingId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productGroupMappingId' is not null or undefined
            assertParamExists('productGroupMappingsDeleteProductGroupMapping', 'productGroupMappingId', productGroupMappingId)
            const localVarPath = `/api/ProductGroupMappings/{productGroupMappingId}`
                .replace(`{${"productGroupMappingId"}}`, encodeURIComponent(String(productGroupMappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productGroupMappingId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGroupMappingsGetProductGroupMappingById: async (productGroupMappingId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productGroupMappingId' is not null or undefined
            assertParamExists('productGroupMappingsGetProductGroupMappingById', 'productGroupMappingId', productGroupMappingId)
            const localVarPath = `/api/ProductGroupMappings/{productGroupMappingId}`
                .replace(`{${"productGroupMappingId"}}`, encodeURIComponent(String(productGroupMappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [productGroupIds] 
         * @param {Array<string>} [animalSpeciesIds] 
         * @param {boolean} [excludeUnlockedOptions] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGroupMappingsGetProductGroupMappings: async (productGroupIds?: Array<string>, animalSpeciesIds?: Array<string>, excludeUnlockedOptions?: boolean, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProductGroupMappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productGroupIds) {
                localVarQueryParameter['ProductGroupIds'] = productGroupIds;
            }

            if (animalSpeciesIds) {
                localVarQueryParameter['AnimalSpeciesIds'] = animalSpeciesIds;
            }

            if (excludeUnlockedOptions !== undefined) {
                localVarQueryParameter['ExcludeUnlockedOptions'] = excludeUnlockedOptions;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productGroupMappingId 
         * @param {UpdateProductGroupMappingRequest} updateProductGroupMappingRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGroupMappingsUpdateProductGroupMapping: async (productGroupMappingId: string, updateProductGroupMappingRequest: UpdateProductGroupMappingRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productGroupMappingId' is not null or undefined
            assertParamExists('productGroupMappingsUpdateProductGroupMapping', 'productGroupMappingId', productGroupMappingId)
            // verify required parameter 'updateProductGroupMappingRequest' is not null or undefined
            assertParamExists('productGroupMappingsUpdateProductGroupMapping', 'updateProductGroupMappingRequest', updateProductGroupMappingRequest)
            const localVarPath = `/api/ProductGroupMappings/{productGroupMappingId}`
                .replace(`{${"productGroupMappingId"}}`, encodeURIComponent(String(productGroupMappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductGroupMappingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productGroupMappingId 
         * @param {CreateUserInputPropertyOptionRequest} createUserInputPropertyOptionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGroupMappingsValidateAndCreateUserInput: async (productGroupMappingId: string, createUserInputPropertyOptionRequest: CreateUserInputPropertyOptionRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productGroupMappingId' is not null or undefined
            assertParamExists('productGroupMappingsValidateAndCreateUserInput', 'productGroupMappingId', productGroupMappingId)
            // verify required parameter 'createUserInputPropertyOptionRequest' is not null or undefined
            assertParamExists('productGroupMappingsValidateAndCreateUserInput', 'createUserInputPropertyOptionRequest', createUserInputPropertyOptionRequest)
            const localVarPath = `/api/ProductGroupMappings/{productGroupMappingId}/validateAndCreateUserInput`
                .replace(`{${"productGroupMappingId"}}`, encodeURIComponent(String(productGroupMappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserInputPropertyOptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductGroupMappingsApi - functional programming interface
 * @export
 */
export const ProductGroupMappingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductGroupMappingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateProductGroupMappingRequest} createProductGroupMappingRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productGroupMappingsCreateProductGroupMapping(createProductGroupMappingRequest: CreateProductGroupMappingRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductGroupMappingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productGroupMappingsCreateProductGroupMapping(createProductGroupMappingRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productGroupMappingId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productGroupMappingsDeleteProductGroupMapping(productGroupMappingId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productGroupMappingsDeleteProductGroupMapping(productGroupMappingId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productGroupMappingId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productGroupMappingsGetProductGroupMappingById(productGroupMappingId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductGroupMappingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productGroupMappingsGetProductGroupMappingById(productGroupMappingId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [productGroupIds] 
         * @param {Array<string>} [animalSpeciesIds] 
         * @param {boolean} [excludeUnlockedOptions] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productGroupMappingsGetProductGroupMappings(productGroupIds?: Array<string>, animalSpeciesIds?: Array<string>, excludeUnlockedOptions?: boolean, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductGroupMappingResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productGroupMappingsGetProductGroupMappings(productGroupIds, animalSpeciesIds, excludeUnlockedOptions, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productGroupMappingId 
         * @param {UpdateProductGroupMappingRequest} updateProductGroupMappingRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productGroupMappingsUpdateProductGroupMapping(productGroupMappingId: string, updateProductGroupMappingRequest: UpdateProductGroupMappingRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productGroupMappingsUpdateProductGroupMapping(productGroupMappingId, updateProductGroupMappingRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productGroupMappingId 
         * @param {CreateUserInputPropertyOptionRequest} createUserInputPropertyOptionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productGroupMappingsValidateAndCreateUserInput(productGroupMappingId: string, createUserInputPropertyOptionRequest: CreateUserInputPropertyOptionRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyOptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productGroupMappingsValidateAndCreateUserInput(productGroupMappingId, createUserInputPropertyOptionRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductGroupMappingsApi - factory interface
 * @export
 */
export const ProductGroupMappingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductGroupMappingsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateProductGroupMappingRequest} createProductGroupMappingRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGroupMappingsCreateProductGroupMapping(createProductGroupMappingRequest: CreateProductGroupMappingRequest, xVersion?: string, options?: any): AxiosPromise<ProductGroupMappingResponse> {
            return localVarFp.productGroupMappingsCreateProductGroupMapping(createProductGroupMappingRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productGroupMappingId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGroupMappingsDeleteProductGroupMapping(productGroupMappingId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productGroupMappingsDeleteProductGroupMapping(productGroupMappingId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productGroupMappingId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGroupMappingsGetProductGroupMappingById(productGroupMappingId: string, xVersion?: string, options?: any): AxiosPromise<ProductGroupMappingResponse> {
            return localVarFp.productGroupMappingsGetProductGroupMappingById(productGroupMappingId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [productGroupIds] 
         * @param {Array<string>} [animalSpeciesIds] 
         * @param {boolean} [excludeUnlockedOptions] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGroupMappingsGetProductGroupMappings(productGroupIds?: Array<string>, animalSpeciesIds?: Array<string>, excludeUnlockedOptions?: boolean, xVersion?: string, options?: any): AxiosPromise<Array<ProductGroupMappingResponse>> {
            return localVarFp.productGroupMappingsGetProductGroupMappings(productGroupIds, animalSpeciesIds, excludeUnlockedOptions, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productGroupMappingId 
         * @param {UpdateProductGroupMappingRequest} updateProductGroupMappingRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGroupMappingsUpdateProductGroupMapping(productGroupMappingId: string, updateProductGroupMappingRequest: UpdateProductGroupMappingRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productGroupMappingsUpdateProductGroupMapping(productGroupMappingId, updateProductGroupMappingRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productGroupMappingId 
         * @param {CreateUserInputPropertyOptionRequest} createUserInputPropertyOptionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGroupMappingsValidateAndCreateUserInput(productGroupMappingId: string, createUserInputPropertyOptionRequest: CreateUserInputPropertyOptionRequest, xVersion?: string, options?: any): AxiosPromise<PropertyOptionResponse> {
            return localVarFp.productGroupMappingsValidateAndCreateUserInput(productGroupMappingId, createUserInputPropertyOptionRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductGroupMappingsApi - object-oriented interface
 * @export
 * @class ProductGroupMappingsApi
 * @extends {BaseAPI}
 */
export class ProductGroupMappingsApi extends BaseAPI {
    /**
     * 
     * @param {CreateProductGroupMappingRequest} createProductGroupMappingRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductGroupMappingsApi
     */
    public productGroupMappingsCreateProductGroupMapping(createProductGroupMappingRequest: CreateProductGroupMappingRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductGroupMappingsApiFp(this.configuration).productGroupMappingsCreateProductGroupMapping(createProductGroupMappingRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productGroupMappingId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductGroupMappingsApi
     */
    public productGroupMappingsDeleteProductGroupMapping(productGroupMappingId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductGroupMappingsApiFp(this.configuration).productGroupMappingsDeleteProductGroupMapping(productGroupMappingId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productGroupMappingId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductGroupMappingsApi
     */
    public productGroupMappingsGetProductGroupMappingById(productGroupMappingId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductGroupMappingsApiFp(this.configuration).productGroupMappingsGetProductGroupMappingById(productGroupMappingId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [productGroupIds] 
     * @param {Array<string>} [animalSpeciesIds] 
     * @param {boolean} [excludeUnlockedOptions] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductGroupMappingsApi
     */
    public productGroupMappingsGetProductGroupMappings(productGroupIds?: Array<string>, animalSpeciesIds?: Array<string>, excludeUnlockedOptions?: boolean, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductGroupMappingsApiFp(this.configuration).productGroupMappingsGetProductGroupMappings(productGroupIds, animalSpeciesIds, excludeUnlockedOptions, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productGroupMappingId 
     * @param {UpdateProductGroupMappingRequest} updateProductGroupMappingRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductGroupMappingsApi
     */
    public productGroupMappingsUpdateProductGroupMapping(productGroupMappingId: string, updateProductGroupMappingRequest: UpdateProductGroupMappingRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductGroupMappingsApiFp(this.configuration).productGroupMappingsUpdateProductGroupMapping(productGroupMappingId, updateProductGroupMappingRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productGroupMappingId 
     * @param {CreateUserInputPropertyOptionRequest} createUserInputPropertyOptionRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductGroupMappingsApi
     */
    public productGroupMappingsValidateAndCreateUserInput(productGroupMappingId: string, createUserInputPropertyOptionRequest: CreateUserInputPropertyOptionRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductGroupMappingsApiFp(this.configuration).productGroupMappingsValidateAndCreateUserInput(productGroupMappingId, createUserInputPropertyOptionRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductGroupsApi - axios parameter creator
 * @export
 */
export const ProductGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateProductGroupRequest} createProductGroupRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGroupsCreateProductGroup: async (createProductGroupRequest: CreateProductGroupRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProductGroupRequest' is not null or undefined
            assertParamExists('productGroupsCreateProductGroup', 'createProductGroupRequest', createProductGroupRequest)
            const localVarPath = `/api/ProductGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProductGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productGroupId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGroupsDeleteProductGroup: async (productGroupId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productGroupId' is not null or undefined
            assertParamExists('productGroupsDeleteProductGroup', 'productGroupId', productGroupId)
            const localVarPath = `/api/ProductGroups/{productGroupId}`
                .replace(`{${"productGroupId"}}`, encodeURIComponent(String(productGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productGroupId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGroupsGetProductGroupById: async (productGroupId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productGroupId' is not null or undefined
            assertParamExists('productGroupsGetProductGroupById', 'productGroupId', productGroupId)
            const localVarPath = `/api/ProductGroups/{productGroupId}`
                .replace(`{${"productGroupId"}}`, encodeURIComponent(String(productGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [animalSpeciesId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGroupsGetProductGroups: async (animalSpeciesId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProductGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (animalSpeciesId !== undefined) {
                localVarQueryParameter['AnimalSpeciesId'] = animalSpeciesId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productGroupId 
         * @param {UpdateProductGroupRequest} updateProductGroupRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGroupsUpdateProductGroup: async (productGroupId: string, updateProductGroupRequest: UpdateProductGroupRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productGroupId' is not null or undefined
            assertParamExists('productGroupsUpdateProductGroup', 'productGroupId', productGroupId)
            // verify required parameter 'updateProductGroupRequest' is not null or undefined
            assertParamExists('productGroupsUpdateProductGroup', 'updateProductGroupRequest', updateProductGroupRequest)
            const localVarPath = `/api/ProductGroups/{productGroupId}`
                .replace(`{${"productGroupId"}}`, encodeURIComponent(String(productGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductGroupsApi - functional programming interface
 * @export
 */
export const ProductGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateProductGroupRequest} createProductGroupRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productGroupsCreateProductGroup(createProductGroupRequest: CreateProductGroupRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productGroupsCreateProductGroup(createProductGroupRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productGroupId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productGroupsDeleteProductGroup(productGroupId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productGroupsDeleteProductGroup(productGroupId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productGroupId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productGroupsGetProductGroupById(productGroupId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productGroupsGetProductGroupById(productGroupId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [animalSpeciesId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productGroupsGetProductGroups(animalSpeciesId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductGroupResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productGroupsGetProductGroups(animalSpeciesId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productGroupId 
         * @param {UpdateProductGroupRequest} updateProductGroupRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productGroupsUpdateProductGroup(productGroupId: string, updateProductGroupRequest: UpdateProductGroupRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productGroupsUpdateProductGroup(productGroupId, updateProductGroupRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductGroupsApi - factory interface
 * @export
 */
export const ProductGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductGroupsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateProductGroupRequest} createProductGroupRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGroupsCreateProductGroup(createProductGroupRequest: CreateProductGroupRequest, xVersion?: string, options?: any): AxiosPromise<ProductGroupResponse> {
            return localVarFp.productGroupsCreateProductGroup(createProductGroupRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productGroupId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGroupsDeleteProductGroup(productGroupId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productGroupsDeleteProductGroup(productGroupId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productGroupId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGroupsGetProductGroupById(productGroupId: string, xVersion?: string, options?: any): AxiosPromise<ProductGroupResponse> {
            return localVarFp.productGroupsGetProductGroupById(productGroupId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [animalSpeciesId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGroupsGetProductGroups(animalSpeciesId?: string, xVersion?: string, options?: any): AxiosPromise<Array<ProductGroupResponse>> {
            return localVarFp.productGroupsGetProductGroups(animalSpeciesId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productGroupId 
         * @param {UpdateProductGroupRequest} updateProductGroupRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productGroupsUpdateProductGroup(productGroupId: string, updateProductGroupRequest: UpdateProductGroupRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productGroupsUpdateProductGroup(productGroupId, updateProductGroupRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductGroupsApi - object-oriented interface
 * @export
 * @class ProductGroupsApi
 * @extends {BaseAPI}
 */
export class ProductGroupsApi extends BaseAPI {
    /**
     * 
     * @param {CreateProductGroupRequest} createProductGroupRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductGroupsApi
     */
    public productGroupsCreateProductGroup(createProductGroupRequest: CreateProductGroupRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductGroupsApiFp(this.configuration).productGroupsCreateProductGroup(createProductGroupRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productGroupId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductGroupsApi
     */
    public productGroupsDeleteProductGroup(productGroupId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductGroupsApiFp(this.configuration).productGroupsDeleteProductGroup(productGroupId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productGroupId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductGroupsApi
     */
    public productGroupsGetProductGroupById(productGroupId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductGroupsApiFp(this.configuration).productGroupsGetProductGroupById(productGroupId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [animalSpeciesId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductGroupsApi
     */
    public productGroupsGetProductGroups(animalSpeciesId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductGroupsApiFp(this.configuration).productGroupsGetProductGroups(animalSpeciesId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productGroupId 
     * @param {UpdateProductGroupRequest} updateProductGroupRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductGroupsApi
     */
    public productGroupsUpdateProductGroup(productGroupId: string, updateProductGroupRequest: UpdateProductGroupRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductGroupsApiFp(this.configuration).productGroupsUpdateProductGroup(productGroupId, updateProductGroupRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductPricesApi - axios parameter creator
 * @export
 */
export const ProductPricesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateProductPriceRequest} createProductPriceRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPricesCreateProductPrice: async (createProductPriceRequest: CreateProductPriceRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProductPriceRequest' is not null or undefined
            assertParamExists('productPricesCreateProductPrice', 'createProductPriceRequest', createProductPriceRequest)
            const localVarPath = `/api/ProductPrices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProductPriceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productPriceId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPricesDeleteProductPrice: async (productPriceId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productPriceId' is not null or undefined
            assertParamExists('productPricesDeleteProductPrice', 'productPriceId', productPriceId)
            const localVarPath = `/api/ProductPrices/{productPriceId}`
                .replace(`{${"productPriceId"}}`, encodeURIComponent(String(productPriceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productPriceId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPricesGetProductPriceById: async (productPriceId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productPriceId' is not null or undefined
            assertParamExists('productPricesGetProductPriceById', 'productPriceId', productPriceId)
            const localVarPath = `/api/ProductPrices/{productPriceId}`
                .replace(`{${"productPriceId"}}`, encodeURIComponent(String(productPriceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {string} [productVersionId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPricesGetProductPrices: async (productId?: string, productVersionId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProductPrices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productId !== undefined) {
                localVarQueryParameter['ProductId'] = productId;
            }

            if (productVersionId !== undefined) {
                localVarQueryParameter['ProductVersionId'] = productVersionId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productPriceId 
         * @param {UpdateProductPriceRequest} updateProductPriceRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPricesUpdateProductPrice: async (productPriceId: string, updateProductPriceRequest: UpdateProductPriceRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productPriceId' is not null or undefined
            assertParamExists('productPricesUpdateProductPrice', 'productPriceId', productPriceId)
            // verify required parameter 'updateProductPriceRequest' is not null or undefined
            assertParamExists('productPricesUpdateProductPrice', 'updateProductPriceRequest', updateProductPriceRequest)
            const localVarPath = `/api/ProductPrices/{productPriceId}`
                .replace(`{${"productPriceId"}}`, encodeURIComponent(String(productPriceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductPriceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductPricesApi - functional programming interface
 * @export
 */
export const ProductPricesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductPricesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateProductPriceRequest} createProductPriceRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productPricesCreateProductPrice(createProductPriceRequest: CreateProductPriceRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPriceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productPricesCreateProductPrice(createProductPriceRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productPriceId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productPricesDeleteProductPrice(productPriceId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productPricesDeleteProductPrice(productPriceId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productPriceId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productPricesGetProductPriceById(productPriceId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPriceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productPricesGetProductPriceById(productPriceId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {string} [productVersionId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productPricesGetProductPrices(productId?: string, productVersionId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductPriceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productPricesGetProductPrices(productId, productVersionId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productPriceId 
         * @param {UpdateProductPriceRequest} updateProductPriceRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productPricesUpdateProductPrice(productPriceId: string, updateProductPriceRequest: UpdateProductPriceRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productPricesUpdateProductPrice(productPriceId, updateProductPriceRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductPricesApi - factory interface
 * @export
 */
export const ProductPricesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductPricesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateProductPriceRequest} createProductPriceRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPricesCreateProductPrice(createProductPriceRequest: CreateProductPriceRequest, xVersion?: string, options?: any): AxiosPromise<ProductPriceResponse> {
            return localVarFp.productPricesCreateProductPrice(createProductPriceRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productPriceId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPricesDeleteProductPrice(productPriceId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productPricesDeleteProductPrice(productPriceId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productPriceId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPricesGetProductPriceById(productPriceId: string, xVersion?: string, options?: any): AxiosPromise<ProductPriceResponse> {
            return localVarFp.productPricesGetProductPriceById(productPriceId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {string} [productVersionId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPricesGetProductPrices(productId?: string, productVersionId?: string, xVersion?: string, options?: any): AxiosPromise<Array<ProductPriceResponse>> {
            return localVarFp.productPricesGetProductPrices(productId, productVersionId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productPriceId 
         * @param {UpdateProductPriceRequest} updateProductPriceRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPricesUpdateProductPrice(productPriceId: string, updateProductPriceRequest: UpdateProductPriceRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productPricesUpdateProductPrice(productPriceId, updateProductPriceRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductPricesApi - object-oriented interface
 * @export
 * @class ProductPricesApi
 * @extends {BaseAPI}
 */
export class ProductPricesApi extends BaseAPI {
    /**
     * 
     * @param {CreateProductPriceRequest} createProductPriceRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPricesApi
     */
    public productPricesCreateProductPrice(createProductPriceRequest: CreateProductPriceRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductPricesApiFp(this.configuration).productPricesCreateProductPrice(createProductPriceRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productPriceId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPricesApi
     */
    public productPricesDeleteProductPrice(productPriceId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductPricesApiFp(this.configuration).productPricesDeleteProductPrice(productPriceId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productPriceId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPricesApi
     */
    public productPricesGetProductPriceById(productPriceId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductPricesApiFp(this.configuration).productPricesGetProductPriceById(productPriceId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [productId] 
     * @param {string} [productVersionId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPricesApi
     */
    public productPricesGetProductPrices(productId?: string, productVersionId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductPricesApiFp(this.configuration).productPricesGetProductPrices(productId, productVersionId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productPriceId 
     * @param {UpdateProductPriceRequest} updateProductPriceRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPricesApi
     */
    public productPricesUpdateProductPrice(productPriceId: string, updateProductPriceRequest: UpdateProductPriceRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductPricesApiFp(this.configuration).productPricesUpdateProductPrice(productPriceId, updateProductPriceRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductStocksApi - axios parameter creator
 * @export
 */
export const ProductStocksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateProductStockRequest} createProductStockRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productStocksCreateProductStock: async (createProductStockRequest: CreateProductStockRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProductStockRequest' is not null or undefined
            assertParamExists('productStocksCreateProductStock', 'createProductStockRequest', createProductStockRequest)
            const localVarPath = `/api/ProductStocks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProductStockRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productStockId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productStocksDeleteProductStock: async (productStockId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productStockId' is not null or undefined
            assertParamExists('productStocksDeleteProductStock', 'productStockId', productStockId)
            const localVarPath = `/api/ProductStocks/{productStockId}`
                .replace(`{${"productStockId"}}`, encodeURIComponent(String(productStockId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productStockId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productStocksGetProductStockById: async (productStockId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productStockId' is not null or undefined
            assertParamExists('productStocksGetProductStockById', 'productStockId', productStockId)
            const localVarPath = `/api/ProductStocks/{productStockId}`
                .replace(`{${"productStockId"}}`, encodeURIComponent(String(productStockId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [warehouseId] 
         * @param {string} [productId] 
         * @param {boolean} [excludeNonBuyable] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productStocksGetProductStocks: async (warehouseId?: string, productId?: string, excludeNonBuyable?: boolean, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProductStocks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (warehouseId !== undefined) {
                localVarQueryParameter['WarehouseId'] = warehouseId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['ProductId'] = productId;
            }

            if (excludeNonBuyable !== undefined) {
                localVarQueryParameter['ExcludeNonBuyable'] = excludeNonBuyable;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DataSynchronizationRequest} dataSynchronizationRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productStocksSync: async (dataSynchronizationRequest: DataSynchronizationRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataSynchronizationRequest' is not null or undefined
            assertParamExists('productStocksSync', 'dataSynchronizationRequest', dataSynchronizationRequest)
            const localVarPath = `/api/ProductStocks/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataSynchronizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productStockId 
         * @param {UpdateProductStockRequest} updateProductStockRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productStocksUpdateProductStock: async (productStockId: string, updateProductStockRequest: UpdateProductStockRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productStockId' is not null or undefined
            assertParamExists('productStocksUpdateProductStock', 'productStockId', productStockId)
            // verify required parameter 'updateProductStockRequest' is not null or undefined
            assertParamExists('productStocksUpdateProductStock', 'updateProductStockRequest', updateProductStockRequest)
            const localVarPath = `/api/ProductStocks/{productStockId}`
                .replace(`{${"productStockId"}}`, encodeURIComponent(String(productStockId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductStockRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductStocksApi - functional programming interface
 * @export
 */
export const ProductStocksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductStocksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateProductStockRequest} createProductStockRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productStocksCreateProductStock(createProductStockRequest: CreateProductStockRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductStockResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productStocksCreateProductStock(createProductStockRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productStockId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productStocksDeleteProductStock(productStockId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productStocksDeleteProductStock(productStockId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productStockId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productStocksGetProductStockById(productStockId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductStockResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productStocksGetProductStockById(productStockId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [warehouseId] 
         * @param {string} [productId] 
         * @param {boolean} [excludeNonBuyable] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productStocksGetProductStocks(warehouseId?: string, productId?: string, excludeNonBuyable?: boolean, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductStockResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productStocksGetProductStocks(warehouseId, productId, excludeNonBuyable, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DataSynchronizationRequest} dataSynchronizationRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productStocksSync(dataSynchronizationRequest: DataSynchronizationRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productStocksSync(dataSynchronizationRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productStockId 
         * @param {UpdateProductStockRequest} updateProductStockRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productStocksUpdateProductStock(productStockId: string, updateProductStockRequest: UpdateProductStockRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productStocksUpdateProductStock(productStockId, updateProductStockRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductStocksApi - factory interface
 * @export
 */
export const ProductStocksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductStocksApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateProductStockRequest} createProductStockRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productStocksCreateProductStock(createProductStockRequest: CreateProductStockRequest, xVersion?: string, options?: any): AxiosPromise<ProductStockResponse> {
            return localVarFp.productStocksCreateProductStock(createProductStockRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productStockId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productStocksDeleteProductStock(productStockId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productStocksDeleteProductStock(productStockId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productStockId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productStocksGetProductStockById(productStockId: string, xVersion?: string, options?: any): AxiosPromise<ProductStockResponse> {
            return localVarFp.productStocksGetProductStockById(productStockId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [warehouseId] 
         * @param {string} [productId] 
         * @param {boolean} [excludeNonBuyable] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productStocksGetProductStocks(warehouseId?: string, productId?: string, excludeNonBuyable?: boolean, xVersion?: string, options?: any): AxiosPromise<Array<ProductStockResponse>> {
            return localVarFp.productStocksGetProductStocks(warehouseId, productId, excludeNonBuyable, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DataSynchronizationRequest} dataSynchronizationRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productStocksSync(dataSynchronizationRequest: DataSynchronizationRequest, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.productStocksSync(dataSynchronizationRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productStockId 
         * @param {UpdateProductStockRequest} updateProductStockRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productStocksUpdateProductStock(productStockId: string, updateProductStockRequest: UpdateProductStockRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productStocksUpdateProductStock(productStockId, updateProductStockRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductStocksApi - object-oriented interface
 * @export
 * @class ProductStocksApi
 * @extends {BaseAPI}
 */
export class ProductStocksApi extends BaseAPI {
    /**
     * 
     * @param {CreateProductStockRequest} createProductStockRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStocksApi
     */
    public productStocksCreateProductStock(createProductStockRequest: CreateProductStockRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductStocksApiFp(this.configuration).productStocksCreateProductStock(createProductStockRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productStockId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStocksApi
     */
    public productStocksDeleteProductStock(productStockId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductStocksApiFp(this.configuration).productStocksDeleteProductStock(productStockId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productStockId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStocksApi
     */
    public productStocksGetProductStockById(productStockId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductStocksApiFp(this.configuration).productStocksGetProductStockById(productStockId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [warehouseId] 
     * @param {string} [productId] 
     * @param {boolean} [excludeNonBuyable] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStocksApi
     */
    public productStocksGetProductStocks(warehouseId?: string, productId?: string, excludeNonBuyable?: boolean, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductStocksApiFp(this.configuration).productStocksGetProductStocks(warehouseId, productId, excludeNonBuyable, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataSynchronizationRequest} dataSynchronizationRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStocksApi
     */
    public productStocksSync(dataSynchronizationRequest: DataSynchronizationRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductStocksApiFp(this.configuration).productStocksSync(dataSynchronizationRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productStockId 
     * @param {UpdateProductStockRequest} updateProductStockRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStocksApi
     */
    public productStocksUpdateProductStock(productStockId: string, updateProductStockRequest: UpdateProductStockRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductStocksApiFp(this.configuration).productStocksUpdateProductStock(productStockId, updateProductStockRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductUnitsApi - axios parameter creator
 * @export
 */
export const ProductUnitsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateProductUnitRequest} createProductUnitRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productUnitsCreateProductUnit: async (createProductUnitRequest: CreateProductUnitRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProductUnitRequest' is not null or undefined
            assertParamExists('productUnitsCreateProductUnit', 'createProductUnitRequest', createProductUnitRequest)
            const localVarPath = `/api/ProductUnits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProductUnitRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productUnitId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productUnitsDeleteProductUnit: async (productUnitId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productUnitId' is not null or undefined
            assertParamExists('productUnitsDeleteProductUnit', 'productUnitId', productUnitId)
            const localVarPath = `/api/ProductUnits/{productUnitId}`
                .replace(`{${"productUnitId"}}`, encodeURIComponent(String(productUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productUnitId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productUnitsGetProductUnitById: async (productUnitId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productUnitId' is not null or undefined
            assertParamExists('productUnitsGetProductUnitById', 'productUnitId', productUnitId)
            const localVarPath = `/api/ProductUnits/{productUnitId}`
                .replace(`{${"productUnitId"}}`, encodeURIComponent(String(productUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [isBasePriceUnit] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productUnitsGetProductUnits: async (isBasePriceUnit?: boolean, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProductUnits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (isBasePriceUnit !== undefined) {
                localVarQueryParameter['IsBasePriceUnit'] = isBasePriceUnit;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productUnitId 
         * @param {UpdateProductUnitRequest} updateProductUnitRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productUnitsUpdateProductUnit: async (productUnitId: string, updateProductUnitRequest: UpdateProductUnitRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productUnitId' is not null or undefined
            assertParamExists('productUnitsUpdateProductUnit', 'productUnitId', productUnitId)
            // verify required parameter 'updateProductUnitRequest' is not null or undefined
            assertParamExists('productUnitsUpdateProductUnit', 'updateProductUnitRequest', updateProductUnitRequest)
            const localVarPath = `/api/ProductUnits/{productUnitId}`
                .replace(`{${"productUnitId"}}`, encodeURIComponent(String(productUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductUnitRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductUnitsApi - functional programming interface
 * @export
 */
export const ProductUnitsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductUnitsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateProductUnitRequest} createProductUnitRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productUnitsCreateProductUnit(createProductUnitRequest: CreateProductUnitRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductUnitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productUnitsCreateProductUnit(createProductUnitRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productUnitId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productUnitsDeleteProductUnit(productUnitId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productUnitsDeleteProductUnit(productUnitId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productUnitId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productUnitsGetProductUnitById(productUnitId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductUnitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productUnitsGetProductUnitById(productUnitId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [isBasePriceUnit] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productUnitsGetProductUnits(isBasePriceUnit?: boolean, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductUnitResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productUnitsGetProductUnits(isBasePriceUnit, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productUnitId 
         * @param {UpdateProductUnitRequest} updateProductUnitRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productUnitsUpdateProductUnit(productUnitId: string, updateProductUnitRequest: UpdateProductUnitRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productUnitsUpdateProductUnit(productUnitId, updateProductUnitRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductUnitsApi - factory interface
 * @export
 */
export const ProductUnitsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductUnitsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateProductUnitRequest} createProductUnitRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productUnitsCreateProductUnit(createProductUnitRequest: CreateProductUnitRequest, xVersion?: string, options?: any): AxiosPromise<ProductUnitResponse> {
            return localVarFp.productUnitsCreateProductUnit(createProductUnitRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productUnitId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productUnitsDeleteProductUnit(productUnitId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productUnitsDeleteProductUnit(productUnitId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productUnitId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productUnitsGetProductUnitById(productUnitId: string, xVersion?: string, options?: any): AxiosPromise<ProductUnitResponse> {
            return localVarFp.productUnitsGetProductUnitById(productUnitId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [isBasePriceUnit] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productUnitsGetProductUnits(isBasePriceUnit?: boolean, xVersion?: string, options?: any): AxiosPromise<Array<ProductUnitResponse>> {
            return localVarFp.productUnitsGetProductUnits(isBasePriceUnit, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productUnitId 
         * @param {UpdateProductUnitRequest} updateProductUnitRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productUnitsUpdateProductUnit(productUnitId: string, updateProductUnitRequest: UpdateProductUnitRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productUnitsUpdateProductUnit(productUnitId, updateProductUnitRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductUnitsApi - object-oriented interface
 * @export
 * @class ProductUnitsApi
 * @extends {BaseAPI}
 */
export class ProductUnitsApi extends BaseAPI {
    /**
     * 
     * @param {CreateProductUnitRequest} createProductUnitRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductUnitsApi
     */
    public productUnitsCreateProductUnit(createProductUnitRequest: CreateProductUnitRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductUnitsApiFp(this.configuration).productUnitsCreateProductUnit(createProductUnitRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productUnitId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductUnitsApi
     */
    public productUnitsDeleteProductUnit(productUnitId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductUnitsApiFp(this.configuration).productUnitsDeleteProductUnit(productUnitId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productUnitId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductUnitsApi
     */
    public productUnitsGetProductUnitById(productUnitId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductUnitsApiFp(this.configuration).productUnitsGetProductUnitById(productUnitId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [isBasePriceUnit] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductUnitsApi
     */
    public productUnitsGetProductUnits(isBasePriceUnit?: boolean, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductUnitsApiFp(this.configuration).productUnitsGetProductUnits(isBasePriceUnit, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productUnitId 
     * @param {UpdateProductUnitRequest} updateProductUnitRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductUnitsApi
     */
    public productUnitsUpdateProductUnit(productUnitId: string, updateProductUnitRequest: UpdateProductUnitRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductUnitsApiFp(this.configuration).productUnitsUpdateProductUnit(productUnitId, updateProductUnitRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductVersionsApi - axios parameter creator
 * @export
 */
export const ProductVersionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} productVersionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productVersionsDeactivateProductVersion: async (productVersionId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productVersionId' is not null or undefined
            assertParamExists('productVersionsDeactivateProductVersion', 'productVersionId', productVersionId)
            const localVarPath = `/api/ProductVersions/{productVersionId}/deactivate`
                .replace(`{${"productVersionId"}}`, encodeURIComponent(String(productVersionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productVersionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productVersionsGetProductVersionById: async (productVersionId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productVersionId' is not null or undefined
            assertParamExists('productVersionsGetProductVersionById', 'productVersionId', productVersionId)
            const localVarPath = `/api/ProductVersions/{productVersionId}`
                .replace(`{${"productVersionId"}}`, encodeURIComponent(String(productVersionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {number} [limit] 
         * @param {number} [versionNumberCursor] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productVersionsGetProductVersions: async (productId?: string, limit?: number, versionNumberCursor?: number, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProductVersions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productId !== undefined) {
                localVarQueryParameter['ProductId'] = productId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (versionNumberCursor !== undefined) {
                localVarQueryParameter['VersionNumberCursor'] = versionNumberCursor;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductVersionsApi - functional programming interface
 * @export
 */
export const ProductVersionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductVersionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} productVersionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productVersionsDeactivateProductVersion(productVersionId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productVersionsDeactivateProductVersion(productVersionId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productVersionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productVersionsGetProductVersionById(productVersionId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductVersionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productVersionsGetProductVersionById(productVersionId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {number} [limit] 
         * @param {number} [versionNumberCursor] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productVersionsGetProductVersions(productId?: string, limit?: number, versionNumberCursor?: number, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductVersionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productVersionsGetProductVersions(productId, limit, versionNumberCursor, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductVersionsApi - factory interface
 * @export
 */
export const ProductVersionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductVersionsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} productVersionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productVersionsDeactivateProductVersion(productVersionId: string, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.productVersionsDeactivateProductVersion(productVersionId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productVersionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productVersionsGetProductVersionById(productVersionId: string, xVersion?: string, options?: any): AxiosPromise<ProductVersionResponse> {
            return localVarFp.productVersionsGetProductVersionById(productVersionId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {number} [limit] 
         * @param {number} [versionNumberCursor] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productVersionsGetProductVersions(productId?: string, limit?: number, versionNumberCursor?: number, xVersion?: string, options?: any): AxiosPromise<Array<ProductVersionResponse>> {
            return localVarFp.productVersionsGetProductVersions(productId, limit, versionNumberCursor, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductVersionsApi - object-oriented interface
 * @export
 * @class ProductVersionsApi
 * @extends {BaseAPI}
 */
export class ProductVersionsApi extends BaseAPI {
    /**
     * 
     * @param {string} productVersionId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductVersionsApi
     */
    public productVersionsDeactivateProductVersion(productVersionId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductVersionsApiFp(this.configuration).productVersionsDeactivateProductVersion(productVersionId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productVersionId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductVersionsApi
     */
    public productVersionsGetProductVersionById(productVersionId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductVersionsApiFp(this.configuration).productVersionsGetProductVersionById(productVersionId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [productId] 
     * @param {number} [limit] 
     * @param {number} [versionNumberCursor] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductVersionsApi
     */
    public productVersionsGetProductVersions(productId?: string, limit?: number, versionNumberCursor?: number, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductVersionsApiFp(this.configuration).productVersionsGetProductVersions(productId, limit, versionNumberCursor, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BulkUpdateProductStatesRequest} bulkUpdateProductStatesRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsBulkUpdateProductStates: async (bulkUpdateProductStatesRequest: BulkUpdateProductStatesRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkUpdateProductStatesRequest' is not null or undefined
            assertParamExists('productsBulkUpdateProductStates', 'bulkUpdateProductStatesRequest', bulkUpdateProductStatesRequest)
            const localVarPath = `/api/Products/bulkUpdateProductStates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkUpdateProductStatesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<BulkUpsertProductRequest>} bulkUpsertProductRequest 
         * @param {boolean} [autoRequestApproval] 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsBulkUpsertProducts: async (bulkUpsertProductRequest: Array<BulkUpsertProductRequest>, autoRequestApproval?: boolean, manufacturerId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkUpsertProductRequest' is not null or undefined
            assertParamExists('productsBulkUpsertProducts', 'bulkUpsertProductRequest', bulkUpsertProductRequest)
            const localVarPath = `/api/Products/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (autoRequestApproval !== undefined) {
                localVarQueryParameter['autoRequestApproval'] = autoRequestApproval;
            }

            if (manufacturerId !== undefined) {
                localVarQueryParameter['manufacturerId'] = manufacturerId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkUpsertProductRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateProductRequest} createProductRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCreateProduct: async (createProductRequest: CreateProductRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProductRequest' is not null or undefined
            assertParamExists('productsCreateProduct', 'createProductRequest', createProductRequest)
            const localVarPath = `/api/Products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProductRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} parentProductId 
         * @param {CreateVariantProductsRequest} createVariantProductsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCreateProductVariants: async (parentProductId: string, createVariantProductsRequest: CreateVariantProductsRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentProductId' is not null or undefined
            assertParamExists('productsCreateProductVariants', 'parentProductId', parentProductId)
            // verify required parameter 'createVariantProductsRequest' is not null or undefined
            assertParamExists('productsCreateProductVariants', 'createVariantProductsRequest', createVariantProductsRequest)
            const localVarPath = `/api/Products/{parentProductId}/variants`
                .replace(`{${"parentProductId"}}`, encodeURIComponent(String(parentProductId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createVariantProductsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsDeleteProduct: async (productId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productsDeleteProduct', 'productId', productId)
            const localVarPath = `/api/Products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} parentProductId 
         * @param {string} variantProductId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsDeleteProductVariant: async (parentProductId: string, variantProductId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentProductId' is not null or undefined
            assertParamExists('productsDeleteProductVariant', 'parentProductId', parentProductId)
            // verify required parameter 'variantProductId' is not null or undefined
            assertParamExists('productsDeleteProductVariant', 'variantProductId', variantProductId)
            const localVarPath = `/api/Products/{parentProductId}/variants/{variantProductId}`
                .replace(`{${"parentProductId"}}`, encodeURIComponent(String(parentProductId)))
                .replace(`{${"variantProductId"}}`, encodeURIComponent(String(variantProductId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsDeleteValidationResults: async (manufacturerId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Products/validationResults`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns calculated calories/100g for given product id.
         * @param {string} productId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGetCalculatedCalories: async (productId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productsGetCalculatedCalories', 'productId', productId)
            const localVarPath = `/api/Products/{productId}/calculateCalories`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {boolean} [asPopulated] 
         * @param {Array<ProductState>} [states] 
         * @param {string} [productVersionId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGetProductById: async (productId: string, asPopulated?: boolean, states?: Array<ProductState>, productVersionId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productsGetProductById', 'productId', productId)
            const localVarPath = `/api/Products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (asPopulated !== undefined) {
                localVarQueryParameter['AsPopulated'] = asPopulated;
            }

            if (states) {
                localVarQueryParameter['States'] = states;
            }

            if (productVersionId !== undefined) {
                localVarQueryParameter['ProductVersionId'] = productVersionId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductIdentifierType} [productIdentifierType] 
         * @param {string} [value] 
         * @param {string} [manufacturerId] 
         * @param {boolean} [includeParentRelations] 
         * @param {boolean} [asPopulated] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGetProductByIdentifier: async (productIdentifierType?: ProductIdentifierType, value?: string, manufacturerId?: string, includeParentRelations?: boolean, asPopulated?: boolean, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Products/productByIdentifier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productIdentifierType !== undefined) {
                localVarQueryParameter['ProductIdentifierType'] = productIdentifierType;
            }

            if (value !== undefined) {
                localVarQueryParameter['Value'] = value;
            }

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }

            if (includeParentRelations !== undefined) {
                localVarQueryParameter['IncludeParentRelations'] = includeParentRelations;
            }

            if (asPopulated !== undefined) {
                localVarQueryParameter['AsPopulated'] = asPopulated;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {boolean} [asTree] 
         * @param {string} [productVersionId] 
         * @param {Array<string>} [productGroupIds] 
         * @param {Array<string>} [animalSpeciesIds] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGetProductCategories: async (productId: string, asTree?: boolean, productVersionId?: string, productGroupIds?: Array<string>, animalSpeciesIds?: Array<string>, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productsGetProductCategories', 'productId', productId)
            const localVarPath = `/api/Products/{productId}/categories`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (asTree !== undefined) {
                localVarQueryParameter['AsTree'] = asTree;
            }

            if (productVersionId !== undefined) {
                localVarQueryParameter['ProductVersionId'] = productVersionId;
            }

            if (productGroupIds) {
                localVarQueryParameter['ProductGroupIds'] = productGroupIds;
            }

            if (animalSpeciesIds) {
                localVarQueryParameter['AnimalSpeciesIds'] = animalSpeciesIds;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} [productVersionId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGetProductTags: async (productId: string, productVersionId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productsGetProductTags', 'productId', productId)
            const localVarPath = `/api/Products/{productId}/tags`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productVersionId !== undefined) {
                localVarQueryParameter['ProductVersionId'] = productVersionId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} parentProductId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGetProductVariants: async (parentProductId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parentProductId' is not null or undefined
            assertParamExists('productsGetProductVariants', 'parentProductId', parentProductId)
            const localVarPath = `/api/Products/{parentProductId}/variants`
                .replace(`{${"parentProductId"}}`, encodeURIComponent(String(parentProductId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [productGroupId] 
         * @param {boolean} [hasVariants] 
         * @param {string} [releasedBeforeDate] 
         * @param {string} [releasedAfterDate] 
         * @param {boolean} [hasNoStock] 
         * @param {string} [manufacturerId] 
         * @param {boolean} [asPopulated] 
         * @param {Array<string>} [categoryIds] 
         * @param {boolean} [excludeNonBuyable] 
         * @param {Array<ProductState>} [states] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGetProducts: async (productGroupId?: string, hasVariants?: boolean, releasedBeforeDate?: string, releasedAfterDate?: string, hasNoStock?: boolean, manufacturerId?: string, asPopulated?: boolean, categoryIds?: Array<string>, excludeNonBuyable?: boolean, states?: Array<ProductState>, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productGroupId !== undefined) {
                localVarQueryParameter['ProductGroupId'] = productGroupId;
            }

            if (hasVariants !== undefined) {
                localVarQueryParameter['HasVariants'] = hasVariants;
            }

            if (releasedBeforeDate !== undefined) {
                localVarQueryParameter['ReleasedBeforeDate'] = (releasedBeforeDate as any instanceof Date) ?
                    (releasedBeforeDate as any).toISOString() :
                    releasedBeforeDate;
            }

            if (releasedAfterDate !== undefined) {
                localVarQueryParameter['ReleasedAfterDate'] = (releasedAfterDate as any instanceof Date) ?
                    (releasedAfterDate as any).toISOString() :
                    releasedAfterDate;
            }

            if (hasNoStock !== undefined) {
                localVarQueryParameter['HasNoStock'] = hasNoStock;
            }

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }

            if (asPopulated !== undefined) {
                localVarQueryParameter['AsPopulated'] = asPopulated;
            }

            if (categoryIds) {
                localVarQueryParameter['CategoryIds'] = categoryIds;
            }

            if (excludeNonBuyable !== undefined) {
                localVarQueryParameter['ExcludeNonBuyable'] = excludeNonBuyable;
            }

            if (states) {
                localVarQueryParameter['States'] = states;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetProductsListByIdentifiersRequest} getProductsListByIdentifiersRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGetProductsListByIdentifiers: async (getProductsListByIdentifiersRequest: GetProductsListByIdentifiersRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getProductsListByIdentifiersRequest' is not null or undefined
            assertParamExists('productsGetProductsListByIdentifiers', 'getProductsListByIdentifiersRequest', getProductsListByIdentifiersRequest)
            const localVarPath = `/api/Products/productsListByIdentifiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getProductsListByIdentifiersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [manufacturerId] 
         * @param {Array<string>} [brandIds] 
         * @param {Array<string>} [animalSpeciesIds] 
         * @param {string} [productGroupId] 
         * @param {ProductState} [state] 
         * @param {ProductListFilterMode} [productListFilterMode] 
         * @param {WarehouseType} [warehouseType] 
         * @param {Array<string>} [propertyOptionIds] 
         * @param {Array<string>} [categoryIds] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGetSimpleProductsList: async (manufacturerId?: Array<string>, brandIds?: Array<string>, animalSpeciesIds?: Array<string>, productGroupId?: string, state?: ProductState, productListFilterMode?: ProductListFilterMode, warehouseType?: WarehouseType, propertyOptionIds?: Array<string>, categoryIds?: Array<string>, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Products/simpleProductsList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (manufacturerId) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }

            if (brandIds) {
                localVarQueryParameter['BrandIds'] = brandIds;
            }

            if (animalSpeciesIds) {
                localVarQueryParameter['AnimalSpeciesIds'] = animalSpeciesIds;
            }

            if (productGroupId !== undefined) {
                localVarQueryParameter['ProductGroupId'] = productGroupId;
            }

            if (state !== undefined) {
                localVarQueryParameter['State'] = state;
            }

            if (productListFilterMode !== undefined) {
                localVarQueryParameter['ProductListFilterMode'] = productListFilterMode;
            }

            if (warehouseType !== undefined) {
                localVarQueryParameter['WarehouseType'] = warehouseType;
            }

            if (propertyOptionIds) {
                localVarQueryParameter['PropertyOptionIds'] = propertyOptionIds;
            }

            if (categoryIds) {
                localVarQueryParameter['CategoryIds'] = categoryIds;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [productGroupId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGetValidationResults: async (manufacturerId?: string, productGroupId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Products/validationResults`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (manufacturerId !== undefined) {
                localVarQueryParameter['ManufacturerId'] = manufacturerId;
            }

            if (productGroupId !== undefined) {
                localVarQueryParameter['ProductGroupId'] = productGroupId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpdateProductRequest} updateProductRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpdateProduct: async (productId: string, updateProductRequest: UpdateProductRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productsUpdateProduct', 'productId', productId)
            // verify required parameter 'updateProductRequest' is not null or undefined
            assertParamExists('productsUpdateProduct', 'updateProductRequest', updateProductRequest)
            const localVarPath = `/api/Products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpdateProductAnalyticConstituentsRequest} updateProductAnalyticConstituentsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpdateProductAnalyticConstituents: async (productId: string, updateProductAnalyticConstituentsRequest: UpdateProductAnalyticConstituentsRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productsUpdateProductAnalyticConstituents', 'productId', productId)
            // verify required parameter 'updateProductAnalyticConstituentsRequest' is not null or undefined
            assertParamExists('productsUpdateProductAnalyticConstituents', 'updateProductAnalyticConstituentsRequest', updateProductAnalyticConstituentsRequest)
            const localVarPath = `/api/Products/{productId}/analyticConstituents`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductAnalyticConstituentsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpdateProductAssetsRequest} updateProductAssetsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpdateProductAssets: async (productId: string, updateProductAssetsRequest: UpdateProductAssetsRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productsUpdateProductAssets', 'productId', productId)
            // verify required parameter 'updateProductAssetsRequest' is not null or undefined
            assertParamExists('productsUpdateProductAssets', 'updateProductAssetsRequest', updateProductAssetsRequest)
            const localVarPath = `/api/Products/{productId}/productAssets`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductAssetsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpdateProductProductCategoriesRequest} updateProductProductCategoriesRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpdateProductCategories: async (productId: string, updateProductProductCategoriesRequest: UpdateProductProductCategoriesRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productsUpdateProductCategories', 'productId', productId)
            // verify required parameter 'updateProductProductCategoriesRequest' is not null or undefined
            assertParamExists('productsUpdateProductCategories', 'updateProductProductCategoriesRequest', updateProductProductCategoriesRequest)
            const localVarPath = `/api/Products/{productId}/categories`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductProductCategoriesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpdateProductPropertiesRequest} updateProductPropertiesRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpdateProductProperties: async (productId: string, updateProductPropertiesRequest: UpdateProductPropertiesRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productsUpdateProductProperties', 'productId', productId)
            // verify required parameter 'updateProductPropertiesRequest' is not null or undefined
            assertParamExists('productsUpdateProductProperties', 'updateProductPropertiesRequest', updateProductPropertiesRequest)
            const localVarPath = `/api/Products/{productId}/properties`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductPropertiesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpdateProductStateRequest} updateProductStateRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpdateProductState: async (productId: string, updateProductStateRequest: UpdateProductStateRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productsUpdateProductState', 'productId', productId)
            // verify required parameter 'updateProductStateRequest' is not null or undefined
            assertParamExists('productsUpdateProductState', 'updateProductStateRequest', updateProductStateRequest)
            const localVarPath = `/api/Products/{productId}/state`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductStateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpdateProductTagsRequest} updateProductTagsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpdateProductTags: async (productId: string, updateProductTagsRequest: UpdateProductTagsRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productsUpdateProductTags', 'productId', productId)
            // verify required parameter 'updateProductTagsRequest' is not null or undefined
            assertParamExists('productsUpdateProductTags', 'updateProductTagsRequest', updateProductTagsRequest)
            const localVarPath = `/api/Products/{productId}/tags`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductTagsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpsertProductAdditionalInformationDownloadRequest} upsertProductAdditionalInformationDownloadRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpsertProductAdditionalInformationDownload: async (productId: string, upsertProductAdditionalInformationDownloadRequest: UpsertProductAdditionalInformationDownloadRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productsUpsertProductAdditionalInformationDownload', 'productId', productId)
            // verify required parameter 'upsertProductAdditionalInformationDownloadRequest' is not null or undefined
            assertParamExists('productsUpsertProductAdditionalInformationDownload', 'upsertProductAdditionalInformationDownloadRequest', upsertProductAdditionalInformationDownloadRequest)
            const localVarPath = `/api/Products/{productId}/additionalInformation/download`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertProductAdditionalInformationDownloadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpsertProductAdditionalInformationMediaGalleryRequest} upsertProductAdditionalInformationMediaGalleryRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpsertProductAdditionalInformationMediaGallery: async (productId: string, upsertProductAdditionalInformationMediaGalleryRequest: UpsertProductAdditionalInformationMediaGalleryRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productsUpsertProductAdditionalInformationMediaGallery', 'productId', productId)
            // verify required parameter 'upsertProductAdditionalInformationMediaGalleryRequest' is not null or undefined
            assertParamExists('productsUpsertProductAdditionalInformationMediaGallery', 'upsertProductAdditionalInformationMediaGalleryRequest', upsertProductAdditionalInformationMediaGalleryRequest)
            const localVarPath = `/api/Products/{productId}/additionalInformation/mediaGallery`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertProductAdditionalInformationMediaGalleryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpsertProductAdditionalInformationRichTextRequest} upsertProductAdditionalInformationRichTextRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpsertProductAdditionalInformationRichText: async (productId: string, upsertProductAdditionalInformationRichTextRequest: UpsertProductAdditionalInformationRichTextRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productsUpsertProductAdditionalInformationRichText', 'productId', productId)
            // verify required parameter 'upsertProductAdditionalInformationRichTextRequest' is not null or undefined
            assertParamExists('productsUpsertProductAdditionalInformationRichText', 'upsertProductAdditionalInformationRichTextRequest', upsertProductAdditionalInformationRichTextRequest)
            const localVarPath = `/api/Products/{productId}/additionalInformation/richText`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertProductAdditionalInformationRichTextRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpsertProductAdditionalInformationBulletPointsRequest} upsertProductAdditionalInformationBulletPointsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpsertProductAdditionalInformationRichText2: async (productId: string, upsertProductAdditionalInformationBulletPointsRequest: UpsertProductAdditionalInformationBulletPointsRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productsUpsertProductAdditionalInformationRichText2', 'productId', productId)
            // verify required parameter 'upsertProductAdditionalInformationBulletPointsRequest' is not null or undefined
            assertParamExists('productsUpsertProductAdditionalInformationRichText2', 'upsertProductAdditionalInformationBulletPointsRequest', upsertProductAdditionalInformationBulletPointsRequest)
            const localVarPath = `/api/Products/{productId}/additionalInformation/bulletPoints`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertProductAdditionalInformationBulletPointsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpsertProductAdditionalInformationTableRequest} upsertProductAdditionalInformationTableRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpsertProductAdditionalInformationTable: async (productId: string, upsertProductAdditionalInformationTableRequest: UpsertProductAdditionalInformationTableRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productsUpsertProductAdditionalInformationTable', 'productId', productId)
            // verify required parameter 'upsertProductAdditionalInformationTableRequest' is not null or undefined
            assertParamExists('productsUpsertProductAdditionalInformationTable', 'upsertProductAdditionalInformationTableRequest', upsertProductAdditionalInformationTableRequest)
            const localVarPath = `/api/Products/{productId}/additionalInformation/table`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertProductAdditionalInformationTableRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validates a product by its ID.
         * @param {string} productId The ID of the product to validate.
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsValidateProductById: async (productId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productsValidateProductById', 'productId', productId)
            const localVarPath = `/api/Products/{productId}/validate`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsValidateProductsById: async (requestBody: Array<string>, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('productsValidateProductsById', 'requestBody', requestBody)
            const localVarPath = `/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BulkUpdateProductStatesRequest} bulkUpdateProductStatesRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsBulkUpdateProductStates(bulkUpdateProductStatesRequest: BulkUpdateProductStatesRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsBulkUpdateProductStates(bulkUpdateProductStatesRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<BulkUpsertProductRequest>} bulkUpsertProductRequest 
         * @param {boolean} [autoRequestApproval] 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsBulkUpsertProducts(bulkUpsertProductRequest: Array<BulkUpsertProductRequest>, autoRequestApproval?: boolean, manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductsQueuedForCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsBulkUpsertProducts(bulkUpsertProductRequest, autoRequestApproval, manufacturerId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateProductRequest} createProductRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsCreateProduct(createProductRequest: CreateProductRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsCreateProduct(createProductRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} parentProductId 
         * @param {CreateVariantProductsRequest} createVariantProductsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsCreateProductVariants(parentProductId: string, createVariantProductsRequest: CreateVariantProductsRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductVariantResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsCreateProductVariants(parentProductId, createVariantProductsRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsDeleteProduct(productId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsDeleteProduct(productId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} parentProductId 
         * @param {string} variantProductId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsDeleteProductVariant(parentProductId: string, variantProductId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsDeleteProductVariant(parentProductId, variantProductId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsDeleteValidationResults(manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsDeleteValidationResults(manufacturerId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns calculated calories/100g for given product id.
         * @param {string} productId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsGetCalculatedCalories(productId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsGetCalculatedCalories(productId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {boolean} [asPopulated] 
         * @param {Array<ProductState>} [states] 
         * @param {string} [productVersionId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsGetProductById(productId: string, asPopulated?: boolean, states?: Array<ProductState>, productVersionId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsGetProductById(productId, asPopulated, states, productVersionId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductIdentifierType} [productIdentifierType] 
         * @param {string} [value] 
         * @param {string} [manufacturerId] 
         * @param {boolean} [includeParentRelations] 
         * @param {boolean} [asPopulated] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsGetProductByIdentifier(productIdentifierType?: ProductIdentifierType, value?: string, manufacturerId?: string, includeParentRelations?: boolean, asPopulated?: boolean, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsGetProductByIdentifier(productIdentifierType, value, manufacturerId, includeParentRelations, asPopulated, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {boolean} [asTree] 
         * @param {string} [productVersionId] 
         * @param {Array<string>} [productGroupIds] 
         * @param {Array<string>} [animalSpeciesIds] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsGetProductCategories(productId: string, asTree?: boolean, productVersionId?: string, productGroupIds?: Array<string>, animalSpeciesIds?: Array<string>, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductCategoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsGetProductCategories(productId, asTree, productVersionId, productGroupIds, animalSpeciesIds, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} [productVersionId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsGetProductTags(productId: string, productVersionId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TagResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsGetProductTags(productId, productVersionId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} parentProductId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsGetProductVariants(parentProductId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductVariantResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsGetProductVariants(parentProductId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [productGroupId] 
         * @param {boolean} [hasVariants] 
         * @param {string} [releasedBeforeDate] 
         * @param {string} [releasedAfterDate] 
         * @param {boolean} [hasNoStock] 
         * @param {string} [manufacturerId] 
         * @param {boolean} [asPopulated] 
         * @param {Array<string>} [categoryIds] 
         * @param {boolean} [excludeNonBuyable] 
         * @param {Array<ProductState>} [states] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsGetProducts(productGroupId?: string, hasVariants?: boolean, releasedBeforeDate?: string, releasedAfterDate?: string, hasNoStock?: boolean, manufacturerId?: string, asPopulated?: boolean, categoryIds?: Array<string>, excludeNonBuyable?: boolean, states?: Array<ProductState>, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsGetProducts(productGroupId, hasVariants, releasedBeforeDate, releasedAfterDate, hasNoStock, manufacturerId, asPopulated, categoryIds, excludeNonBuyable, states, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetProductsListByIdentifiersRequest} getProductsListByIdentifiersRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsGetProductsListByIdentifiers(getProductsListByIdentifiersRequest: GetProductsListByIdentifiersRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SimpleProductResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsGetProductsListByIdentifiers(getProductsListByIdentifiersRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [manufacturerId] 
         * @param {Array<string>} [brandIds] 
         * @param {Array<string>} [animalSpeciesIds] 
         * @param {string} [productGroupId] 
         * @param {ProductState} [state] 
         * @param {ProductListFilterMode} [productListFilterMode] 
         * @param {WarehouseType} [warehouseType] 
         * @param {Array<string>} [propertyOptionIds] 
         * @param {Array<string>} [categoryIds] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsGetSimpleProductsList(manufacturerId?: Array<string>, brandIds?: Array<string>, animalSpeciesIds?: Array<string>, productGroupId?: string, state?: ProductState, productListFilterMode?: ProductListFilterMode, warehouseType?: WarehouseType, propertyOptionIds?: Array<string>, categoryIds?: Array<string>, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SimpleProductResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsGetSimpleProductsList(manufacturerId, brandIds, animalSpeciesIds, productGroupId, state, productListFilterMode, warehouseType, propertyOptionIds, categoryIds, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [productGroupId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsGetValidationResults(manufacturerId?: string, productGroupId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductValidationResultResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsGetValidationResults(manufacturerId, productGroupId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpdateProductRequest} updateProductRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsUpdateProduct(productId: string, updateProductRequest: UpdateProductRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsUpdateProduct(productId, updateProductRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpdateProductAnalyticConstituentsRequest} updateProductAnalyticConstituentsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsUpdateProductAnalyticConstituents(productId: string, updateProductAnalyticConstituentsRequest: UpdateProductAnalyticConstituentsRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsUpdateProductAnalyticConstituents(productId, updateProductAnalyticConstituentsRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpdateProductAssetsRequest} updateProductAssetsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsUpdateProductAssets(productId: string, updateProductAssetsRequest: UpdateProductAssetsRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsUpdateProductAssets(productId, updateProductAssetsRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpdateProductProductCategoriesRequest} updateProductProductCategoriesRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsUpdateProductCategories(productId: string, updateProductProductCategoriesRequest: UpdateProductProductCategoriesRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsUpdateProductCategories(productId, updateProductProductCategoriesRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpdateProductPropertiesRequest} updateProductPropertiesRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsUpdateProductProperties(productId: string, updateProductPropertiesRequest: UpdateProductPropertiesRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsUpdateProductProperties(productId, updateProductPropertiesRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpdateProductStateRequest} updateProductStateRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsUpdateProductState(productId: string, updateProductStateRequest: UpdateProductStateRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsUpdateProductState(productId, updateProductStateRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpdateProductTagsRequest} updateProductTagsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsUpdateProductTags(productId: string, updateProductTagsRequest: UpdateProductTagsRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsUpdateProductTags(productId, updateProductTagsRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpsertProductAdditionalInformationDownloadRequest} upsertProductAdditionalInformationDownloadRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsUpsertProductAdditionalInformationDownload(productId: string, upsertProductAdditionalInformationDownloadRequest: UpsertProductAdditionalInformationDownloadRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsUpsertProductAdditionalInformationDownload(productId, upsertProductAdditionalInformationDownloadRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpsertProductAdditionalInformationMediaGalleryRequest} upsertProductAdditionalInformationMediaGalleryRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsUpsertProductAdditionalInformationMediaGallery(productId: string, upsertProductAdditionalInformationMediaGalleryRequest: UpsertProductAdditionalInformationMediaGalleryRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsUpsertProductAdditionalInformationMediaGallery(productId, upsertProductAdditionalInformationMediaGalleryRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpsertProductAdditionalInformationRichTextRequest} upsertProductAdditionalInformationRichTextRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsUpsertProductAdditionalInformationRichText(productId: string, upsertProductAdditionalInformationRichTextRequest: UpsertProductAdditionalInformationRichTextRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsUpsertProductAdditionalInformationRichText(productId, upsertProductAdditionalInformationRichTextRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpsertProductAdditionalInformationBulletPointsRequest} upsertProductAdditionalInformationBulletPointsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsUpsertProductAdditionalInformationRichText2(productId: string, upsertProductAdditionalInformationBulletPointsRequest: UpsertProductAdditionalInformationBulletPointsRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsUpsertProductAdditionalInformationRichText2(productId, upsertProductAdditionalInformationBulletPointsRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpsertProductAdditionalInformationTableRequest} upsertProductAdditionalInformationTableRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsUpsertProductAdditionalInformationTable(productId: string, upsertProductAdditionalInformationTableRequest: UpsertProductAdditionalInformationTableRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsUpsertProductAdditionalInformationTable(productId, upsertProductAdditionalInformationTableRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validates a product by its ID.
         * @param {string} productId The ID of the product to validate.
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsValidateProductById(productId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidationResultResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsValidateProductById(productId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsValidateProductsById(requestBody: Array<string>, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ValidationResultResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsValidateProductsById(requestBody, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * 
         * @param {BulkUpdateProductStatesRequest} bulkUpdateProductStatesRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsBulkUpdateProductStates(bulkUpdateProductStatesRequest: BulkUpdateProductStatesRequest, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.productsBulkUpdateProductStates(bulkUpdateProductStatesRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<BulkUpsertProductRequest>} bulkUpsertProductRequest 
         * @param {boolean} [autoRequestApproval] 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsBulkUpsertProducts(bulkUpsertProductRequest: Array<BulkUpsertProductRequest>, autoRequestApproval?: boolean, manufacturerId?: string, xVersion?: string, options?: any): AxiosPromise<ProductsQueuedForCreationResponse> {
            return localVarFp.productsBulkUpsertProducts(bulkUpsertProductRequest, autoRequestApproval, manufacturerId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateProductRequest} createProductRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCreateProduct(createProductRequest: CreateProductRequest, xVersion?: string, options?: any): AxiosPromise<ProductResponse> {
            return localVarFp.productsCreateProduct(createProductRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} parentProductId 
         * @param {CreateVariantProductsRequest} createVariantProductsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCreateProductVariants(parentProductId: string, createVariantProductsRequest: CreateVariantProductsRequest, xVersion?: string, options?: any): AxiosPromise<Array<ProductVariantResponse>> {
            return localVarFp.productsCreateProductVariants(parentProductId, createVariantProductsRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsDeleteProduct(productId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productsDeleteProduct(productId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} parentProductId 
         * @param {string} variantProductId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsDeleteProductVariant(parentProductId: string, variantProductId: string, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.productsDeleteProductVariant(parentProductId, variantProductId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsDeleteValidationResults(manufacturerId?: string, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.productsDeleteValidationResults(manufacturerId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns calculated calories/100g for given product id.
         * @param {string} productId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGetCalculatedCalories(productId: string, xVersion?: string, options?: any): AxiosPromise<number> {
            return localVarFp.productsGetCalculatedCalories(productId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {boolean} [asPopulated] 
         * @param {Array<ProductState>} [states] 
         * @param {string} [productVersionId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGetProductById(productId: string, asPopulated?: boolean, states?: Array<ProductState>, productVersionId?: string, xVersion?: string, options?: any): AxiosPromise<ProductResponse> {
            return localVarFp.productsGetProductById(productId, asPopulated, states, productVersionId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductIdentifierType} [productIdentifierType] 
         * @param {string} [value] 
         * @param {string} [manufacturerId] 
         * @param {boolean} [includeParentRelations] 
         * @param {boolean} [asPopulated] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGetProductByIdentifier(productIdentifierType?: ProductIdentifierType, value?: string, manufacturerId?: string, includeParentRelations?: boolean, asPopulated?: boolean, xVersion?: string, options?: any): AxiosPromise<ProductResponse> {
            return localVarFp.productsGetProductByIdentifier(productIdentifierType, value, manufacturerId, includeParentRelations, asPopulated, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {boolean} [asTree] 
         * @param {string} [productVersionId] 
         * @param {Array<string>} [productGroupIds] 
         * @param {Array<string>} [animalSpeciesIds] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGetProductCategories(productId: string, asTree?: boolean, productVersionId?: string, productGroupIds?: Array<string>, animalSpeciesIds?: Array<string>, xVersion?: string, options?: any): AxiosPromise<Array<ProductCategoryResponse>> {
            return localVarFp.productsGetProductCategories(productId, asTree, productVersionId, productGroupIds, animalSpeciesIds, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} [productVersionId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGetProductTags(productId: string, productVersionId?: string, xVersion?: string, options?: any): AxiosPromise<Array<TagResponse>> {
            return localVarFp.productsGetProductTags(productId, productVersionId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} parentProductId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGetProductVariants(parentProductId: string, xVersion?: string, options?: any): AxiosPromise<Array<ProductVariantResponse>> {
            return localVarFp.productsGetProductVariants(parentProductId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [productGroupId] 
         * @param {boolean} [hasVariants] 
         * @param {string} [releasedBeforeDate] 
         * @param {string} [releasedAfterDate] 
         * @param {boolean} [hasNoStock] 
         * @param {string} [manufacturerId] 
         * @param {boolean} [asPopulated] 
         * @param {Array<string>} [categoryIds] 
         * @param {boolean} [excludeNonBuyable] 
         * @param {Array<ProductState>} [states] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGetProducts(productGroupId?: string, hasVariants?: boolean, releasedBeforeDate?: string, releasedAfterDate?: string, hasNoStock?: boolean, manufacturerId?: string, asPopulated?: boolean, categoryIds?: Array<string>, excludeNonBuyable?: boolean, states?: Array<ProductState>, xVersion?: string, options?: any): AxiosPromise<Array<ProductResponse>> {
            return localVarFp.productsGetProducts(productGroupId, hasVariants, releasedBeforeDate, releasedAfterDate, hasNoStock, manufacturerId, asPopulated, categoryIds, excludeNonBuyable, states, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetProductsListByIdentifiersRequest} getProductsListByIdentifiersRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGetProductsListByIdentifiers(getProductsListByIdentifiersRequest: GetProductsListByIdentifiersRequest, xVersion?: string, options?: any): AxiosPromise<Array<SimpleProductResponse>> {
            return localVarFp.productsGetProductsListByIdentifiers(getProductsListByIdentifiersRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [manufacturerId] 
         * @param {Array<string>} [brandIds] 
         * @param {Array<string>} [animalSpeciesIds] 
         * @param {string} [productGroupId] 
         * @param {ProductState} [state] 
         * @param {ProductListFilterMode} [productListFilterMode] 
         * @param {WarehouseType} [warehouseType] 
         * @param {Array<string>} [propertyOptionIds] 
         * @param {Array<string>} [categoryIds] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGetSimpleProductsList(manufacturerId?: Array<string>, brandIds?: Array<string>, animalSpeciesIds?: Array<string>, productGroupId?: string, state?: ProductState, productListFilterMode?: ProductListFilterMode, warehouseType?: WarehouseType, propertyOptionIds?: Array<string>, categoryIds?: Array<string>, xVersion?: string, options?: any): AxiosPromise<Array<SimpleProductResponse>> {
            return localVarFp.productsGetSimpleProductsList(manufacturerId, brandIds, animalSpeciesIds, productGroupId, state, productListFilterMode, warehouseType, propertyOptionIds, categoryIds, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [manufacturerId] 
         * @param {string} [productGroupId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGetValidationResults(manufacturerId?: string, productGroupId?: string, xVersion?: string, options?: any): AxiosPromise<Array<ProductValidationResultResponse>> {
            return localVarFp.productsGetValidationResults(manufacturerId, productGroupId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpdateProductRequest} updateProductRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpdateProduct(productId: string, updateProductRequest: UpdateProductRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productsUpdateProduct(productId, updateProductRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpdateProductAnalyticConstituentsRequest} updateProductAnalyticConstituentsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpdateProductAnalyticConstituents(productId: string, updateProductAnalyticConstituentsRequest: UpdateProductAnalyticConstituentsRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productsUpdateProductAnalyticConstituents(productId, updateProductAnalyticConstituentsRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpdateProductAssetsRequest} updateProductAssetsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpdateProductAssets(productId: string, updateProductAssetsRequest: UpdateProductAssetsRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productsUpdateProductAssets(productId, updateProductAssetsRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpdateProductProductCategoriesRequest} updateProductProductCategoriesRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpdateProductCategories(productId: string, updateProductProductCategoriesRequest: UpdateProductProductCategoriesRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productsUpdateProductCategories(productId, updateProductProductCategoriesRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpdateProductPropertiesRequest} updateProductPropertiesRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpdateProductProperties(productId: string, updateProductPropertiesRequest: UpdateProductPropertiesRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productsUpdateProductProperties(productId, updateProductPropertiesRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpdateProductStateRequest} updateProductStateRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpdateProductState(productId: string, updateProductStateRequest: UpdateProductStateRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productsUpdateProductState(productId, updateProductStateRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpdateProductTagsRequest} updateProductTagsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpdateProductTags(productId: string, updateProductTagsRequest: UpdateProductTagsRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.productsUpdateProductTags(productId, updateProductTagsRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpsertProductAdditionalInformationDownloadRequest} upsertProductAdditionalInformationDownloadRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpsertProductAdditionalInformationDownload(productId: string, upsertProductAdditionalInformationDownloadRequest: UpsertProductAdditionalInformationDownloadRequest, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.productsUpsertProductAdditionalInformationDownload(productId, upsertProductAdditionalInformationDownloadRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpsertProductAdditionalInformationMediaGalleryRequest} upsertProductAdditionalInformationMediaGalleryRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpsertProductAdditionalInformationMediaGallery(productId: string, upsertProductAdditionalInformationMediaGalleryRequest: UpsertProductAdditionalInformationMediaGalleryRequest, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.productsUpsertProductAdditionalInformationMediaGallery(productId, upsertProductAdditionalInformationMediaGalleryRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpsertProductAdditionalInformationRichTextRequest} upsertProductAdditionalInformationRichTextRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpsertProductAdditionalInformationRichText(productId: string, upsertProductAdditionalInformationRichTextRequest: UpsertProductAdditionalInformationRichTextRequest, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.productsUpsertProductAdditionalInformationRichText(productId, upsertProductAdditionalInformationRichTextRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpsertProductAdditionalInformationBulletPointsRequest} upsertProductAdditionalInformationBulletPointsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpsertProductAdditionalInformationRichText2(productId: string, upsertProductAdditionalInformationBulletPointsRequest: UpsertProductAdditionalInformationBulletPointsRequest, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.productsUpsertProductAdditionalInformationRichText2(productId, upsertProductAdditionalInformationBulletPointsRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {UpsertProductAdditionalInformationTableRequest} upsertProductAdditionalInformationTableRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpsertProductAdditionalInformationTable(productId: string, upsertProductAdditionalInformationTableRequest: UpsertProductAdditionalInformationTableRequest, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.productsUpsertProductAdditionalInformationTable(productId, upsertProductAdditionalInformationTableRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validates a product by its ID.
         * @param {string} productId The ID of the product to validate.
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsValidateProductById(productId: string, xVersion?: string, options?: any): AxiosPromise<ValidationResultResponse> {
            return localVarFp.productsValidateProductById(productId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsValidateProductsById(requestBody: Array<string>, xVersion?: string, options?: any): AxiosPromise<Array<ValidationResultResponse>> {
            return localVarFp.productsValidateProductsById(requestBody, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * 
     * @param {BulkUpdateProductStatesRequest} bulkUpdateProductStatesRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsBulkUpdateProductStates(bulkUpdateProductStatesRequest: BulkUpdateProductStatesRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsBulkUpdateProductStates(bulkUpdateProductStatesRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<BulkUpsertProductRequest>} bulkUpsertProductRequest 
     * @param {boolean} [autoRequestApproval] 
     * @param {string} [manufacturerId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsBulkUpsertProducts(bulkUpsertProductRequest: Array<BulkUpsertProductRequest>, autoRequestApproval?: boolean, manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsBulkUpsertProducts(bulkUpsertProductRequest, autoRequestApproval, manufacturerId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateProductRequest} createProductRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsCreateProduct(createProductRequest: CreateProductRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsCreateProduct(createProductRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} parentProductId 
     * @param {CreateVariantProductsRequest} createVariantProductsRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsCreateProductVariants(parentProductId: string, createVariantProductsRequest: CreateVariantProductsRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsCreateProductVariants(parentProductId, createVariantProductsRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsDeleteProduct(productId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsDeleteProduct(productId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} parentProductId 
     * @param {string} variantProductId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsDeleteProductVariant(parentProductId: string, variantProductId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsDeleteProductVariant(parentProductId, variantProductId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsDeleteValidationResults(manufacturerId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsDeleteValidationResults(manufacturerId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns calculated calories/100g for given product id.
     * @param {string} productId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsGetCalculatedCalories(productId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsGetCalculatedCalories(productId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {boolean} [asPopulated] 
     * @param {Array<ProductState>} [states] 
     * @param {string} [productVersionId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsGetProductById(productId: string, asPopulated?: boolean, states?: Array<ProductState>, productVersionId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsGetProductById(productId, asPopulated, states, productVersionId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductIdentifierType} [productIdentifierType] 
     * @param {string} [value] 
     * @param {string} [manufacturerId] 
     * @param {boolean} [includeParentRelations] 
     * @param {boolean} [asPopulated] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsGetProductByIdentifier(productIdentifierType?: ProductIdentifierType, value?: string, manufacturerId?: string, includeParentRelations?: boolean, asPopulated?: boolean, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsGetProductByIdentifier(productIdentifierType, value, manufacturerId, includeParentRelations, asPopulated, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {boolean} [asTree] 
     * @param {string} [productVersionId] 
     * @param {Array<string>} [productGroupIds] 
     * @param {Array<string>} [animalSpeciesIds] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsGetProductCategories(productId: string, asTree?: boolean, productVersionId?: string, productGroupIds?: Array<string>, animalSpeciesIds?: Array<string>, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsGetProductCategories(productId, asTree, productVersionId, productGroupIds, animalSpeciesIds, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {string} [productVersionId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsGetProductTags(productId: string, productVersionId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsGetProductTags(productId, productVersionId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} parentProductId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsGetProductVariants(parentProductId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsGetProductVariants(parentProductId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [productGroupId] 
     * @param {boolean} [hasVariants] 
     * @param {string} [releasedBeforeDate] 
     * @param {string} [releasedAfterDate] 
     * @param {boolean} [hasNoStock] 
     * @param {string} [manufacturerId] 
     * @param {boolean} [asPopulated] 
     * @param {Array<string>} [categoryIds] 
     * @param {boolean} [excludeNonBuyable] 
     * @param {Array<ProductState>} [states] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsGetProducts(productGroupId?: string, hasVariants?: boolean, releasedBeforeDate?: string, releasedAfterDate?: string, hasNoStock?: boolean, manufacturerId?: string, asPopulated?: boolean, categoryIds?: Array<string>, excludeNonBuyable?: boolean, states?: Array<ProductState>, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsGetProducts(productGroupId, hasVariants, releasedBeforeDate, releasedAfterDate, hasNoStock, manufacturerId, asPopulated, categoryIds, excludeNonBuyable, states, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetProductsListByIdentifiersRequest} getProductsListByIdentifiersRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsGetProductsListByIdentifiers(getProductsListByIdentifiersRequest: GetProductsListByIdentifiersRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsGetProductsListByIdentifiers(getProductsListByIdentifiersRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [manufacturerId] 
     * @param {Array<string>} [brandIds] 
     * @param {Array<string>} [animalSpeciesIds] 
     * @param {string} [productGroupId] 
     * @param {ProductState} [state] 
     * @param {ProductListFilterMode} [productListFilterMode] 
     * @param {WarehouseType} [warehouseType] 
     * @param {Array<string>} [propertyOptionIds] 
     * @param {Array<string>} [categoryIds] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsGetSimpleProductsList(manufacturerId?: Array<string>, brandIds?: Array<string>, animalSpeciesIds?: Array<string>, productGroupId?: string, state?: ProductState, productListFilterMode?: ProductListFilterMode, warehouseType?: WarehouseType, propertyOptionIds?: Array<string>, categoryIds?: Array<string>, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsGetSimpleProductsList(manufacturerId, brandIds, animalSpeciesIds, productGroupId, state, productListFilterMode, warehouseType, propertyOptionIds, categoryIds, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [manufacturerId] 
     * @param {string} [productGroupId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsGetValidationResults(manufacturerId?: string, productGroupId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsGetValidationResults(manufacturerId, productGroupId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {UpdateProductRequest} updateProductRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsUpdateProduct(productId: string, updateProductRequest: UpdateProductRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsUpdateProduct(productId, updateProductRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {UpdateProductAnalyticConstituentsRequest} updateProductAnalyticConstituentsRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsUpdateProductAnalyticConstituents(productId: string, updateProductAnalyticConstituentsRequest: UpdateProductAnalyticConstituentsRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsUpdateProductAnalyticConstituents(productId, updateProductAnalyticConstituentsRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {UpdateProductAssetsRequest} updateProductAssetsRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsUpdateProductAssets(productId: string, updateProductAssetsRequest: UpdateProductAssetsRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsUpdateProductAssets(productId, updateProductAssetsRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {UpdateProductProductCategoriesRequest} updateProductProductCategoriesRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsUpdateProductCategories(productId: string, updateProductProductCategoriesRequest: UpdateProductProductCategoriesRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsUpdateProductCategories(productId, updateProductProductCategoriesRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {UpdateProductPropertiesRequest} updateProductPropertiesRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsUpdateProductProperties(productId: string, updateProductPropertiesRequest: UpdateProductPropertiesRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsUpdateProductProperties(productId, updateProductPropertiesRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {UpdateProductStateRequest} updateProductStateRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsUpdateProductState(productId: string, updateProductStateRequest: UpdateProductStateRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsUpdateProductState(productId, updateProductStateRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {UpdateProductTagsRequest} updateProductTagsRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsUpdateProductTags(productId: string, updateProductTagsRequest: UpdateProductTagsRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsUpdateProductTags(productId, updateProductTagsRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {UpsertProductAdditionalInformationDownloadRequest} upsertProductAdditionalInformationDownloadRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsUpsertProductAdditionalInformationDownload(productId: string, upsertProductAdditionalInformationDownloadRequest: UpsertProductAdditionalInformationDownloadRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsUpsertProductAdditionalInformationDownload(productId, upsertProductAdditionalInformationDownloadRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {UpsertProductAdditionalInformationMediaGalleryRequest} upsertProductAdditionalInformationMediaGalleryRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsUpsertProductAdditionalInformationMediaGallery(productId: string, upsertProductAdditionalInformationMediaGalleryRequest: UpsertProductAdditionalInformationMediaGalleryRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsUpsertProductAdditionalInformationMediaGallery(productId, upsertProductAdditionalInformationMediaGalleryRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {UpsertProductAdditionalInformationRichTextRequest} upsertProductAdditionalInformationRichTextRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsUpsertProductAdditionalInformationRichText(productId: string, upsertProductAdditionalInformationRichTextRequest: UpsertProductAdditionalInformationRichTextRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsUpsertProductAdditionalInformationRichText(productId, upsertProductAdditionalInformationRichTextRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {UpsertProductAdditionalInformationBulletPointsRequest} upsertProductAdditionalInformationBulletPointsRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsUpsertProductAdditionalInformationRichText2(productId: string, upsertProductAdditionalInformationBulletPointsRequest: UpsertProductAdditionalInformationBulletPointsRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsUpsertProductAdditionalInformationRichText2(productId, upsertProductAdditionalInformationBulletPointsRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {UpsertProductAdditionalInformationTableRequest} upsertProductAdditionalInformationTableRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsUpsertProductAdditionalInformationTable(productId: string, upsertProductAdditionalInformationTableRequest: UpsertProductAdditionalInformationTableRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsUpsertProductAdditionalInformationTable(productId, upsertProductAdditionalInformationTableRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validates a product by its ID.
     * @param {string} productId The ID of the product to validate.
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsValidateProductById(productId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsValidateProductById(productId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsValidateProductsById(requestBody: Array<string>, xVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsValidateProductsById(requestBody, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PromotionsApi - axios parameter creator
 * @export
 */
export const PromotionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} promotionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsActivatePromotionById: async (promotionId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promotionId' is not null or undefined
            assertParamExists('promotionsActivatePromotionById', 'promotionId', promotionId)
            const localVarPath = `/api/Promotions/{promotionId}/activate`
                .replace(`{${"promotionId"}}`, encodeURIComponent(String(promotionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertPromotionRequest} upsertPromotionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsCreatePromotion: async (upsertPromotionRequest: UpsertPromotionRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'upsertPromotionRequest' is not null or undefined
            assertParamExists('promotionsCreatePromotion', 'upsertPromotionRequest', upsertPromotionRequest)
            const localVarPath = `/api/Promotions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertPromotionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} promotionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsDeactivatePromotionById: async (promotionId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promotionId' is not null or undefined
            assertParamExists('promotionsDeactivatePromotionById', 'promotionId', promotionId)
            const localVarPath = `/api/Promotions/{promotionId}/deactivate`
                .replace(`{${"promotionId"}}`, encodeURIComponent(String(promotionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} promotionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsDeletePromotionById: async (promotionId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promotionId' is not null or undefined
            assertParamExists('promotionsDeletePromotionById', 'promotionId', promotionId)
            const localVarPath = `/api/Promotions/{promotionId}`
                .replace(`{${"promotionId"}}`, encodeURIComponent(String(promotionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} promotionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsGetPromotionById: async (promotionId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promotionId' is not null or undefined
            assertParamExists('promotionsGetPromotionById', 'promotionId', promotionId)
            const localVarPath = `/api/Promotions/{promotionId}`
                .replace(`{${"promotionId"}}`, encodeURIComponent(String(promotionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<PromotionTermsRequest>} promotionTermsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsGetPromotionTermsAndConditions: async (promotionTermsRequest: Array<PromotionTermsRequest>, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promotionTermsRequest' is not null or undefined
            assertParamExists('promotionsGetPromotionTermsAndConditions', 'promotionTermsRequest', promotionTermsRequest)
            const localVarPath = `/api-public/Promotions/terms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promotionTermsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsGetPromotions: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Promotions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} promotionId 
         * @param {UpsertPromotionRequest} upsertPromotionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsUpdatePromotionById: async (promotionId: string, upsertPromotionRequest: UpsertPromotionRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promotionId' is not null or undefined
            assertParamExists('promotionsUpdatePromotionById', 'promotionId', promotionId)
            // verify required parameter 'upsertPromotionRequest' is not null or undefined
            assertParamExists('promotionsUpdatePromotionById', 'upsertPromotionRequest', upsertPromotionRequest)
            const localVarPath = `/api/Promotions/{promotionId}`
                .replace(`{${"promotionId"}}`, encodeURIComponent(String(promotionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertPromotionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromotionsApi - functional programming interface
 * @export
 */
export const PromotionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromotionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} promotionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsActivatePromotionById(promotionId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsActivatePromotionById(promotionId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertPromotionRequest} upsertPromotionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsCreatePromotion(upsertPromotionRequest: UpsertPromotionRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsCreatePromotion(upsertPromotionRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} promotionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsDeactivatePromotionById(promotionId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsDeactivatePromotionById(promotionId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} promotionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsDeletePromotionById(promotionId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsDeletePromotionById(promotionId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} promotionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsGetPromotionById(promotionId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsGetPromotionById(promotionId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<PromotionTermsRequest>} promotionTermsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsGetPromotionTermsAndConditions(promotionTermsRequest: Array<PromotionTermsRequest>, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PromotionTermsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsGetPromotionTermsAndConditions(promotionTermsRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsGetPromotions(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PromotionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsGetPromotions(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} promotionId 
         * @param {UpsertPromotionRequest} upsertPromotionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsUpdatePromotionById(promotionId: string, upsertPromotionRequest: UpsertPromotionRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsUpdatePromotionById(promotionId, upsertPromotionRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PromotionsApi - factory interface
 * @export
 */
export const PromotionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromotionsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} promotionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsActivatePromotionById(promotionId: string, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.promotionsActivatePromotionById(promotionId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertPromotionRequest} upsertPromotionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsCreatePromotion(upsertPromotionRequest: UpsertPromotionRequest, xVersion?: string, options?: any): AxiosPromise<PromotionResponse> {
            return localVarFp.promotionsCreatePromotion(upsertPromotionRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} promotionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsDeactivatePromotionById(promotionId: string, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.promotionsDeactivatePromotionById(promotionId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} promotionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsDeletePromotionById(promotionId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.promotionsDeletePromotionById(promotionId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} promotionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsGetPromotionById(promotionId: string, xVersion?: string, options?: any): AxiosPromise<PromotionResponse> {
            return localVarFp.promotionsGetPromotionById(promotionId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<PromotionTermsRequest>} promotionTermsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsGetPromotionTermsAndConditions(promotionTermsRequest: Array<PromotionTermsRequest>, xVersion?: string, options?: any): AxiosPromise<Array<PromotionTermsResponse>> {
            return localVarFp.promotionsGetPromotionTermsAndConditions(promotionTermsRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsGetPromotions(xVersion?: string, options?: any): AxiosPromise<Array<PromotionResponse>> {
            return localVarFp.promotionsGetPromotions(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} promotionId 
         * @param {UpsertPromotionRequest} upsertPromotionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsUpdatePromotionById(promotionId: string, upsertPromotionRequest: UpsertPromotionRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.promotionsUpdatePromotionById(promotionId, upsertPromotionRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromotionsApi - object-oriented interface
 * @export
 * @class PromotionsApi
 * @extends {BaseAPI}
 */
export class PromotionsApi extends BaseAPI {
    /**
     * 
     * @param {string} promotionId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionsActivatePromotionById(promotionId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionsActivatePromotionById(promotionId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertPromotionRequest} upsertPromotionRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionsCreatePromotion(upsertPromotionRequest: UpsertPromotionRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionsCreatePromotion(upsertPromotionRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} promotionId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionsDeactivatePromotionById(promotionId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionsDeactivatePromotionById(promotionId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} promotionId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionsDeletePromotionById(promotionId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionsDeletePromotionById(promotionId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} promotionId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionsGetPromotionById(promotionId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionsGetPromotionById(promotionId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<PromotionTermsRequest>} promotionTermsRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionsGetPromotionTermsAndConditions(promotionTermsRequest: Array<PromotionTermsRequest>, xVersion?: string, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionsGetPromotionTermsAndConditions(promotionTermsRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionsGetPromotions(xVersion?: string, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionsGetPromotions(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} promotionId 
     * @param {UpsertPromotionRequest} upsertPromotionRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionsUpdatePromotionById(promotionId: string, upsertPromotionRequest: UpsertPromotionRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionsUpdatePromotionById(promotionId, upsertPromotionRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PropertyGroupsApi - axios parameter creator
 * @export
 */
export const PropertyGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePropertyGroupRequest} createPropertyGroupRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyGroupsCreatePropertyGroup: async (createPropertyGroupRequest: CreatePropertyGroupRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPropertyGroupRequest' is not null or undefined
            assertParamExists('propertyGroupsCreatePropertyGroup', 'createPropertyGroupRequest', createPropertyGroupRequest)
            const localVarPath = `/api/PropertyGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPropertyGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} propertyGroupId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyGroupsDeletePropertyGroup: async (propertyGroupId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyGroupId' is not null or undefined
            assertParamExists('propertyGroupsDeletePropertyGroup', 'propertyGroupId', propertyGroupId)
            const localVarPath = `/api/PropertyGroups/{propertyGroupId}`
                .replace(`{${"propertyGroupId"}}`, encodeURIComponent(String(propertyGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} propertyGroupId 
         * @param {boolean} [excludeUnlockedOptions] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyGroupsGetPropertyGroupById: async (propertyGroupId: string, excludeUnlockedOptions?: boolean, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyGroupId' is not null or undefined
            assertParamExists('propertyGroupsGetPropertyGroupById', 'propertyGroupId', propertyGroupId)
            const localVarPath = `/api/PropertyGroups/{propertyGroupId}`
                .replace(`{${"propertyGroupId"}}`, encodeURIComponent(String(propertyGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (excludeUnlockedOptions !== undefined) {
                localVarQueryParameter['ExcludeUnlockedOptions'] = excludeUnlockedOptions;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {boolean} [excludeUnmappedOptions] 
         * @param {boolean} [excludeUnlockedOptions] 
         * @param {string} [productVersionId] 
         * @param {Array<string>} [animalSpeciesIds] 
         * @param {Array<string>} [productGroupIds] 
         * @param {string} [excludeProductGroupId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyGroupsGetPropertyGroups: async (productId?: string, excludeUnmappedOptions?: boolean, excludeUnlockedOptions?: boolean, productVersionId?: string, animalSpeciesIds?: Array<string>, productGroupIds?: Array<string>, excludeProductGroupId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PropertyGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productId !== undefined) {
                localVarQueryParameter['ProductId'] = productId;
            }

            if (excludeUnmappedOptions !== undefined) {
                localVarQueryParameter['ExcludeUnmappedOptions'] = excludeUnmappedOptions;
            }

            if (excludeUnlockedOptions !== undefined) {
                localVarQueryParameter['ExcludeUnlockedOptions'] = excludeUnlockedOptions;
            }

            if (productVersionId !== undefined) {
                localVarQueryParameter['ProductVersionId'] = productVersionId;
            }

            if (animalSpeciesIds) {
                localVarQueryParameter['AnimalSpeciesIds'] = animalSpeciesIds;
            }

            if (productGroupIds) {
                localVarQueryParameter['ProductGroupIds'] = productGroupIds;
            }

            if (excludeProductGroupId !== undefined) {
                localVarQueryParameter['ExcludeProductGroupId'] = excludeProductGroupId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DataSynchronizationRequest} dataSynchronizationRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyGroupsSync: async (dataSynchronizationRequest: DataSynchronizationRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataSynchronizationRequest' is not null or undefined
            assertParamExists('propertyGroupsSync', 'dataSynchronizationRequest', dataSynchronizationRequest)
            const localVarPath = `/api/PropertyGroups/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataSynchronizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} propertyGroupId 
         * @param {UpdatePropertyGroupRequest} updatePropertyGroupRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyGroupsUpdatePropertyGroup: async (propertyGroupId: string, updatePropertyGroupRequest: UpdatePropertyGroupRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyGroupId' is not null or undefined
            assertParamExists('propertyGroupsUpdatePropertyGroup', 'propertyGroupId', propertyGroupId)
            // verify required parameter 'updatePropertyGroupRequest' is not null or undefined
            assertParamExists('propertyGroupsUpdatePropertyGroup', 'updatePropertyGroupRequest', updatePropertyGroupRequest)
            const localVarPath = `/api/PropertyGroups/{propertyGroupId}`
                .replace(`{${"propertyGroupId"}}`, encodeURIComponent(String(propertyGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePropertyGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropertyGroupsApi - functional programming interface
 * @export
 */
export const PropertyGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PropertyGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePropertyGroupRequest} createPropertyGroupRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyGroupsCreatePropertyGroup(createPropertyGroupRequest: CreatePropertyGroupRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertyGroupsCreatePropertyGroup(createPropertyGroupRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} propertyGroupId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyGroupsDeletePropertyGroup(propertyGroupId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertyGroupsDeletePropertyGroup(propertyGroupId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} propertyGroupId 
         * @param {boolean} [excludeUnlockedOptions] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyGroupsGetPropertyGroupById(propertyGroupId: string, excludeUnlockedOptions?: boolean, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertyGroupsGetPropertyGroupById(propertyGroupId, excludeUnlockedOptions, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {boolean} [excludeUnmappedOptions] 
         * @param {boolean} [excludeUnlockedOptions] 
         * @param {string} [productVersionId] 
         * @param {Array<string>} [animalSpeciesIds] 
         * @param {Array<string>} [productGroupIds] 
         * @param {string} [excludeProductGroupId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyGroupsGetPropertyGroups(productId?: string, excludeUnmappedOptions?: boolean, excludeUnlockedOptions?: boolean, productVersionId?: string, animalSpeciesIds?: Array<string>, productGroupIds?: Array<string>, excludeProductGroupId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PropertyGroupResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertyGroupsGetPropertyGroups(productId, excludeUnmappedOptions, excludeUnlockedOptions, productVersionId, animalSpeciesIds, productGroupIds, excludeProductGroupId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DataSynchronizationRequest} dataSynchronizationRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyGroupsSync(dataSynchronizationRequest: DataSynchronizationRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertyGroupsSync(dataSynchronizationRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} propertyGroupId 
         * @param {UpdatePropertyGroupRequest} updatePropertyGroupRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyGroupsUpdatePropertyGroup(propertyGroupId: string, updatePropertyGroupRequest: UpdatePropertyGroupRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertyGroupsUpdatePropertyGroup(propertyGroupId, updatePropertyGroupRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PropertyGroupsApi - factory interface
 * @export
 */
export const PropertyGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropertyGroupsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePropertyGroupRequest} createPropertyGroupRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyGroupsCreatePropertyGroup(createPropertyGroupRequest: CreatePropertyGroupRequest, xVersion?: string, options?: any): AxiosPromise<PropertyGroupResponse> {
            return localVarFp.propertyGroupsCreatePropertyGroup(createPropertyGroupRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} propertyGroupId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyGroupsDeletePropertyGroup(propertyGroupId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.propertyGroupsDeletePropertyGroup(propertyGroupId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} propertyGroupId 
         * @param {boolean} [excludeUnlockedOptions] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyGroupsGetPropertyGroupById(propertyGroupId: string, excludeUnlockedOptions?: boolean, xVersion?: string, options?: any): AxiosPromise<PropertyGroupResponse> {
            return localVarFp.propertyGroupsGetPropertyGroupById(propertyGroupId, excludeUnlockedOptions, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {boolean} [excludeUnmappedOptions] 
         * @param {boolean} [excludeUnlockedOptions] 
         * @param {string} [productVersionId] 
         * @param {Array<string>} [animalSpeciesIds] 
         * @param {Array<string>} [productGroupIds] 
         * @param {string} [excludeProductGroupId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyGroupsGetPropertyGroups(productId?: string, excludeUnmappedOptions?: boolean, excludeUnlockedOptions?: boolean, productVersionId?: string, animalSpeciesIds?: Array<string>, productGroupIds?: Array<string>, excludeProductGroupId?: string, xVersion?: string, options?: any): AxiosPromise<Array<PropertyGroupResponse>> {
            return localVarFp.propertyGroupsGetPropertyGroups(productId, excludeUnmappedOptions, excludeUnlockedOptions, productVersionId, animalSpeciesIds, productGroupIds, excludeProductGroupId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DataSynchronizationRequest} dataSynchronizationRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyGroupsSync(dataSynchronizationRequest: DataSynchronizationRequest, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.propertyGroupsSync(dataSynchronizationRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} propertyGroupId 
         * @param {UpdatePropertyGroupRequest} updatePropertyGroupRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyGroupsUpdatePropertyGroup(propertyGroupId: string, updatePropertyGroupRequest: UpdatePropertyGroupRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.propertyGroupsUpdatePropertyGroup(propertyGroupId, updatePropertyGroupRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PropertyGroupsApi - object-oriented interface
 * @export
 * @class PropertyGroupsApi
 * @extends {BaseAPI}
 */
export class PropertyGroupsApi extends BaseAPI {
    /**
     * 
     * @param {CreatePropertyGroupRequest} createPropertyGroupRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyGroupsApi
     */
    public propertyGroupsCreatePropertyGroup(createPropertyGroupRequest: CreatePropertyGroupRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return PropertyGroupsApiFp(this.configuration).propertyGroupsCreatePropertyGroup(createPropertyGroupRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} propertyGroupId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyGroupsApi
     */
    public propertyGroupsDeletePropertyGroup(propertyGroupId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return PropertyGroupsApiFp(this.configuration).propertyGroupsDeletePropertyGroup(propertyGroupId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} propertyGroupId 
     * @param {boolean} [excludeUnlockedOptions] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyGroupsApi
     */
    public propertyGroupsGetPropertyGroupById(propertyGroupId: string, excludeUnlockedOptions?: boolean, xVersion?: string, options?: AxiosRequestConfig) {
        return PropertyGroupsApiFp(this.configuration).propertyGroupsGetPropertyGroupById(propertyGroupId, excludeUnlockedOptions, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [productId] 
     * @param {boolean} [excludeUnmappedOptions] 
     * @param {boolean} [excludeUnlockedOptions] 
     * @param {string} [productVersionId] 
     * @param {Array<string>} [animalSpeciesIds] 
     * @param {Array<string>} [productGroupIds] 
     * @param {string} [excludeProductGroupId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyGroupsApi
     */
    public propertyGroupsGetPropertyGroups(productId?: string, excludeUnmappedOptions?: boolean, excludeUnlockedOptions?: boolean, productVersionId?: string, animalSpeciesIds?: Array<string>, productGroupIds?: Array<string>, excludeProductGroupId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return PropertyGroupsApiFp(this.configuration).propertyGroupsGetPropertyGroups(productId, excludeUnmappedOptions, excludeUnlockedOptions, productVersionId, animalSpeciesIds, productGroupIds, excludeProductGroupId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataSynchronizationRequest} dataSynchronizationRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyGroupsApi
     */
    public propertyGroupsSync(dataSynchronizationRequest: DataSynchronizationRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return PropertyGroupsApiFp(this.configuration).propertyGroupsSync(dataSynchronizationRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} propertyGroupId 
     * @param {UpdatePropertyGroupRequest} updatePropertyGroupRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyGroupsApi
     */
    public propertyGroupsUpdatePropertyGroup(propertyGroupId: string, updatePropertyGroupRequest: UpdatePropertyGroupRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return PropertyGroupsApiFp(this.configuration).propertyGroupsUpdatePropertyGroup(propertyGroupId, updatePropertyGroupRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PropertyOptionsApi - axios parameter creator
 * @export
 */
export const PropertyOptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePropertyOptionRequest} createPropertyOptionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyOptionsCreatePropertyOption: async (createPropertyOptionRequest: CreatePropertyOptionRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPropertyOptionRequest' is not null or undefined
            assertParamExists('propertyOptionsCreatePropertyOption', 'createPropertyOptionRequest', createPropertyOptionRequest)
            const localVarPath = `/api/PropertyOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPropertyOptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} propertyOptionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyOptionsDeletePropertyOption: async (propertyOptionId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyOptionId' is not null or undefined
            assertParamExists('propertyOptionsDeletePropertyOption', 'propertyOptionId', propertyOptionId)
            const localVarPath = `/api/PropertyOptions/{propertyOptionId}`
                .replace(`{${"propertyOptionId"}}`, encodeURIComponent(String(propertyOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} propertyOptionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyOptionsGetPropertyOptionById: async (propertyOptionId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyOptionId' is not null or undefined
            assertParamExists('propertyOptionsGetPropertyOptionById', 'propertyOptionId', propertyOptionId)
            const localVarPath = `/api/PropertyOptions/{propertyOptionId}`
                .replace(`{${"propertyOptionId"}}`, encodeURIComponent(String(propertyOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [propertyGroupId] 
         * @param {string} [animalSpeciesId] 
         * @param {boolean} [excludeUnlockedOptions] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyOptionsGetPropertyOptions: async (propertyGroupId?: string, animalSpeciesId?: string, excludeUnlockedOptions?: boolean, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PropertyOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (propertyGroupId !== undefined) {
                localVarQueryParameter['PropertyGroupId'] = propertyGroupId;
            }

            if (animalSpeciesId !== undefined) {
                localVarQueryParameter['AnimalSpeciesId'] = animalSpeciesId;
            }

            if (excludeUnlockedOptions !== undefined) {
                localVarQueryParameter['ExcludeUnlockedOptions'] = excludeUnlockedOptions;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DataSynchronizationRequest} dataSynchronizationRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyOptionsSync: async (dataSynchronizationRequest: DataSynchronizationRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataSynchronizationRequest' is not null or undefined
            assertParamExists('propertyOptionsSync', 'dataSynchronizationRequest', dataSynchronizationRequest)
            const localVarPath = `/api/PropertyOptions/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataSynchronizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} propertyOptionId 
         * @param {UpdatePropertyOptionRequest} updatePropertyOptionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyOptionsUpdatePropertyOption: async (propertyOptionId: string, updatePropertyOptionRequest: UpdatePropertyOptionRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyOptionId' is not null or undefined
            assertParamExists('propertyOptionsUpdatePropertyOption', 'propertyOptionId', propertyOptionId)
            // verify required parameter 'updatePropertyOptionRequest' is not null or undefined
            assertParamExists('propertyOptionsUpdatePropertyOption', 'updatePropertyOptionRequest', updatePropertyOptionRequest)
            const localVarPath = `/api/PropertyOptions/{propertyOptionId}`
                .replace(`{${"propertyOptionId"}}`, encodeURIComponent(String(propertyOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePropertyOptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropertyOptionsApi - functional programming interface
 * @export
 */
export const PropertyOptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PropertyOptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePropertyOptionRequest} createPropertyOptionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyOptionsCreatePropertyOption(createPropertyOptionRequest: CreatePropertyOptionRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyOptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertyOptionsCreatePropertyOption(createPropertyOptionRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} propertyOptionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyOptionsDeletePropertyOption(propertyOptionId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertyOptionsDeletePropertyOption(propertyOptionId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} propertyOptionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyOptionsGetPropertyOptionById(propertyOptionId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyOptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertyOptionsGetPropertyOptionById(propertyOptionId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [propertyGroupId] 
         * @param {string} [animalSpeciesId] 
         * @param {boolean} [excludeUnlockedOptions] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyOptionsGetPropertyOptions(propertyGroupId?: string, animalSpeciesId?: string, excludeUnlockedOptions?: boolean, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PropertyOptionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertyOptionsGetPropertyOptions(propertyGroupId, animalSpeciesId, excludeUnlockedOptions, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DataSynchronizationRequest} dataSynchronizationRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyOptionsSync(dataSynchronizationRequest: DataSynchronizationRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertyOptionsSync(dataSynchronizationRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} propertyOptionId 
         * @param {UpdatePropertyOptionRequest} updatePropertyOptionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyOptionsUpdatePropertyOption(propertyOptionId: string, updatePropertyOptionRequest: UpdatePropertyOptionRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertyOptionsUpdatePropertyOption(propertyOptionId, updatePropertyOptionRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PropertyOptionsApi - factory interface
 * @export
 */
export const PropertyOptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropertyOptionsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePropertyOptionRequest} createPropertyOptionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyOptionsCreatePropertyOption(createPropertyOptionRequest: CreatePropertyOptionRequest, xVersion?: string, options?: any): AxiosPromise<PropertyOptionResponse> {
            return localVarFp.propertyOptionsCreatePropertyOption(createPropertyOptionRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} propertyOptionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyOptionsDeletePropertyOption(propertyOptionId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.propertyOptionsDeletePropertyOption(propertyOptionId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} propertyOptionId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyOptionsGetPropertyOptionById(propertyOptionId: string, xVersion?: string, options?: any): AxiosPromise<PropertyOptionResponse> {
            return localVarFp.propertyOptionsGetPropertyOptionById(propertyOptionId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [propertyGroupId] 
         * @param {string} [animalSpeciesId] 
         * @param {boolean} [excludeUnlockedOptions] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyOptionsGetPropertyOptions(propertyGroupId?: string, animalSpeciesId?: string, excludeUnlockedOptions?: boolean, xVersion?: string, options?: any): AxiosPromise<Array<PropertyOptionResponse>> {
            return localVarFp.propertyOptionsGetPropertyOptions(propertyGroupId, animalSpeciesId, excludeUnlockedOptions, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DataSynchronizationRequest} dataSynchronizationRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyOptionsSync(dataSynchronizationRequest: DataSynchronizationRequest, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.propertyOptionsSync(dataSynchronizationRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} propertyOptionId 
         * @param {UpdatePropertyOptionRequest} updatePropertyOptionRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyOptionsUpdatePropertyOption(propertyOptionId: string, updatePropertyOptionRequest: UpdatePropertyOptionRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.propertyOptionsUpdatePropertyOption(propertyOptionId, updatePropertyOptionRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PropertyOptionsApi - object-oriented interface
 * @export
 * @class PropertyOptionsApi
 * @extends {BaseAPI}
 */
export class PropertyOptionsApi extends BaseAPI {
    /**
     * 
     * @param {CreatePropertyOptionRequest} createPropertyOptionRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyOptionsApi
     */
    public propertyOptionsCreatePropertyOption(createPropertyOptionRequest: CreatePropertyOptionRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return PropertyOptionsApiFp(this.configuration).propertyOptionsCreatePropertyOption(createPropertyOptionRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} propertyOptionId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyOptionsApi
     */
    public propertyOptionsDeletePropertyOption(propertyOptionId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return PropertyOptionsApiFp(this.configuration).propertyOptionsDeletePropertyOption(propertyOptionId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} propertyOptionId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyOptionsApi
     */
    public propertyOptionsGetPropertyOptionById(propertyOptionId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return PropertyOptionsApiFp(this.configuration).propertyOptionsGetPropertyOptionById(propertyOptionId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [propertyGroupId] 
     * @param {string} [animalSpeciesId] 
     * @param {boolean} [excludeUnlockedOptions] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyOptionsApi
     */
    public propertyOptionsGetPropertyOptions(propertyGroupId?: string, animalSpeciesId?: string, excludeUnlockedOptions?: boolean, xVersion?: string, options?: AxiosRequestConfig) {
        return PropertyOptionsApiFp(this.configuration).propertyOptionsGetPropertyOptions(propertyGroupId, animalSpeciesId, excludeUnlockedOptions, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataSynchronizationRequest} dataSynchronizationRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyOptionsApi
     */
    public propertyOptionsSync(dataSynchronizationRequest: DataSynchronizationRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return PropertyOptionsApiFp(this.configuration).propertyOptionsSync(dataSynchronizationRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} propertyOptionId 
     * @param {UpdatePropertyOptionRequest} updatePropertyOptionRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyOptionsApi
     */
    public propertyOptionsUpdatePropertyOption(propertyOptionId: string, updatePropertyOptionRequest: UpdatePropertyOptionRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return PropertyOptionsApiFp(this.configuration).propertyOptionsUpdatePropertyOption(propertyOptionId, updatePropertyOptionRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PropertyValidationRulesApi - axios parameter creator
 * @export
 */
export const PropertyValidationRulesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePropertyValidationRuleRequest} createPropertyValidationRuleRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyValidationRulesCreatePropertyValidationRule: async (createPropertyValidationRuleRequest: CreatePropertyValidationRuleRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPropertyValidationRuleRequest' is not null or undefined
            assertParamExists('propertyValidationRulesCreatePropertyValidationRule', 'createPropertyValidationRuleRequest', createPropertyValidationRuleRequest)
            const localVarPath = `/api/PropertyValidationRules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPropertyValidationRuleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} propertyValidationRuleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyValidationRulesDeletePropertyValidationRule: async (propertyValidationRuleId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyValidationRuleId' is not null or undefined
            assertParamExists('propertyValidationRulesDeletePropertyValidationRule', 'propertyValidationRuleId', propertyValidationRuleId)
            const localVarPath = `/api/PropertyValidationRules/{propertyValidationRuleId}`
                .replace(`{${"propertyValidationRuleId"}}`, encodeURIComponent(String(propertyValidationRuleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} propertyValidationRuleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyValidationRulesGetPropertyValidationRuleById: async (propertyValidationRuleId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyValidationRuleId' is not null or undefined
            assertParamExists('propertyValidationRulesGetPropertyValidationRuleById', 'propertyValidationRuleId', propertyValidationRuleId)
            const localVarPath = `/api/PropertyValidationRules/{propertyValidationRuleId}`
                .replace(`{${"propertyValidationRuleId"}}`, encodeURIComponent(String(propertyValidationRuleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [animalSpeciesId] 
         * @param {string} [productGroupId] 
         * @param {string} [productGroupMappingId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyValidationRulesGetPropertyValidationRules: async (animalSpeciesId?: string, productGroupId?: string, productGroupMappingId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PropertyValidationRules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (animalSpeciesId !== undefined) {
                localVarQueryParameter['AnimalSpeciesId'] = animalSpeciesId;
            }

            if (productGroupId !== undefined) {
                localVarQueryParameter['ProductGroupId'] = productGroupId;
            }

            if (productGroupMappingId !== undefined) {
                localVarQueryParameter['ProductGroupMappingId'] = productGroupMappingId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} propertyValidationRuleId 
         * @param {UpdatePropertyValidationRuleRequest} updatePropertyValidationRuleRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyValidationRulesUpdatePropertyValidationRule: async (propertyValidationRuleId: string, updatePropertyValidationRuleRequest: UpdatePropertyValidationRuleRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyValidationRuleId' is not null or undefined
            assertParamExists('propertyValidationRulesUpdatePropertyValidationRule', 'propertyValidationRuleId', propertyValidationRuleId)
            // verify required parameter 'updatePropertyValidationRuleRequest' is not null or undefined
            assertParamExists('propertyValidationRulesUpdatePropertyValidationRule', 'updatePropertyValidationRuleRequest', updatePropertyValidationRuleRequest)
            const localVarPath = `/api/PropertyValidationRules/{propertyValidationRuleId}`
                .replace(`{${"propertyValidationRuleId"}}`, encodeURIComponent(String(propertyValidationRuleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePropertyValidationRuleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropertyValidationRulesApi - functional programming interface
 * @export
 */
export const PropertyValidationRulesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PropertyValidationRulesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePropertyValidationRuleRequest} createPropertyValidationRuleRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyValidationRulesCreatePropertyValidationRule(createPropertyValidationRuleRequest: CreatePropertyValidationRuleRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyValidationRuleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertyValidationRulesCreatePropertyValidationRule(createPropertyValidationRuleRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} propertyValidationRuleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyValidationRulesDeletePropertyValidationRule(propertyValidationRuleId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertyValidationRulesDeletePropertyValidationRule(propertyValidationRuleId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} propertyValidationRuleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyValidationRulesGetPropertyValidationRuleById(propertyValidationRuleId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyValidationRuleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertyValidationRulesGetPropertyValidationRuleById(propertyValidationRuleId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [animalSpeciesId] 
         * @param {string} [productGroupId] 
         * @param {string} [productGroupMappingId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyValidationRulesGetPropertyValidationRules(animalSpeciesId?: string, productGroupId?: string, productGroupMappingId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PropertyValidationRuleResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertyValidationRulesGetPropertyValidationRules(animalSpeciesId, productGroupId, productGroupMappingId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} propertyValidationRuleId 
         * @param {UpdatePropertyValidationRuleRequest} updatePropertyValidationRuleRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyValidationRulesUpdatePropertyValidationRule(propertyValidationRuleId: string, updatePropertyValidationRuleRequest: UpdatePropertyValidationRuleRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertyValidationRulesUpdatePropertyValidationRule(propertyValidationRuleId, updatePropertyValidationRuleRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PropertyValidationRulesApi - factory interface
 * @export
 */
export const PropertyValidationRulesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropertyValidationRulesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePropertyValidationRuleRequest} createPropertyValidationRuleRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyValidationRulesCreatePropertyValidationRule(createPropertyValidationRuleRequest: CreatePropertyValidationRuleRequest, xVersion?: string, options?: any): AxiosPromise<PropertyValidationRuleResponse> {
            return localVarFp.propertyValidationRulesCreatePropertyValidationRule(createPropertyValidationRuleRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} propertyValidationRuleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyValidationRulesDeletePropertyValidationRule(propertyValidationRuleId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.propertyValidationRulesDeletePropertyValidationRule(propertyValidationRuleId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} propertyValidationRuleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyValidationRulesGetPropertyValidationRuleById(propertyValidationRuleId: string, xVersion?: string, options?: any): AxiosPromise<PropertyValidationRuleResponse> {
            return localVarFp.propertyValidationRulesGetPropertyValidationRuleById(propertyValidationRuleId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [animalSpeciesId] 
         * @param {string} [productGroupId] 
         * @param {string} [productGroupMappingId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyValidationRulesGetPropertyValidationRules(animalSpeciesId?: string, productGroupId?: string, productGroupMappingId?: string, xVersion?: string, options?: any): AxiosPromise<Array<PropertyValidationRuleResponse>> {
            return localVarFp.propertyValidationRulesGetPropertyValidationRules(animalSpeciesId, productGroupId, productGroupMappingId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} propertyValidationRuleId 
         * @param {UpdatePropertyValidationRuleRequest} updatePropertyValidationRuleRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyValidationRulesUpdatePropertyValidationRule(propertyValidationRuleId: string, updatePropertyValidationRuleRequest: UpdatePropertyValidationRuleRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.propertyValidationRulesUpdatePropertyValidationRule(propertyValidationRuleId, updatePropertyValidationRuleRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PropertyValidationRulesApi - object-oriented interface
 * @export
 * @class PropertyValidationRulesApi
 * @extends {BaseAPI}
 */
export class PropertyValidationRulesApi extends BaseAPI {
    /**
     * 
     * @param {CreatePropertyValidationRuleRequest} createPropertyValidationRuleRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyValidationRulesApi
     */
    public propertyValidationRulesCreatePropertyValidationRule(createPropertyValidationRuleRequest: CreatePropertyValidationRuleRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return PropertyValidationRulesApiFp(this.configuration).propertyValidationRulesCreatePropertyValidationRule(createPropertyValidationRuleRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} propertyValidationRuleId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyValidationRulesApi
     */
    public propertyValidationRulesDeletePropertyValidationRule(propertyValidationRuleId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return PropertyValidationRulesApiFp(this.configuration).propertyValidationRulesDeletePropertyValidationRule(propertyValidationRuleId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} propertyValidationRuleId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyValidationRulesApi
     */
    public propertyValidationRulesGetPropertyValidationRuleById(propertyValidationRuleId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return PropertyValidationRulesApiFp(this.configuration).propertyValidationRulesGetPropertyValidationRuleById(propertyValidationRuleId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [animalSpeciesId] 
     * @param {string} [productGroupId] 
     * @param {string} [productGroupMappingId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyValidationRulesApi
     */
    public propertyValidationRulesGetPropertyValidationRules(animalSpeciesId?: string, productGroupId?: string, productGroupMappingId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return PropertyValidationRulesApiFp(this.configuration).propertyValidationRulesGetPropertyValidationRules(animalSpeciesId, productGroupId, productGroupMappingId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} propertyValidationRuleId 
     * @param {UpdatePropertyValidationRuleRequest} updatePropertyValidationRuleRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyValidationRulesApi
     */
    public propertyValidationRulesUpdatePropertyValidationRule(propertyValidationRuleId: string, updatePropertyValidationRuleRequest: UpdatePropertyValidationRuleRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return PropertyValidationRulesApiFp(this.configuration).propertyValidationRulesUpdatePropertyValidationRule(propertyValidationRuleId, updatePropertyValidationRuleRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShippingProvidersApi - axios parameter creator
 * @export
 */
export const ShippingProvidersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateShippingProviderRequest} createShippingProviderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingProvidersCreateShippingProvider: async (createShippingProviderRequest: CreateShippingProviderRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createShippingProviderRequest' is not null or undefined
            assertParamExists('shippingProvidersCreateShippingProvider', 'createShippingProviderRequest', createShippingProviderRequest)
            const localVarPath = `/api/ShippingProviders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createShippingProviderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} shippingProviderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingProvidersDeleteShippingProvider: async (shippingProviderId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shippingProviderId' is not null or undefined
            assertParamExists('shippingProvidersDeleteShippingProvider', 'shippingProviderId', shippingProviderId)
            const localVarPath = `/api/ShippingProviders/{shippingProviderId}`
                .replace(`{${"shippingProviderId"}}`, encodeURIComponent(String(shippingProviderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} shippingProviderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingProvidersGetShippingProviderById: async (shippingProviderId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shippingProviderId' is not null or undefined
            assertParamExists('shippingProvidersGetShippingProviderById', 'shippingProviderId', shippingProviderId)
            const localVarPath = `/api/ShippingProviders/{shippingProviderId}`
                .replace(`{${"shippingProviderId"}}`, encodeURIComponent(String(shippingProviderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingProvidersGetShippingProviders: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ShippingProviders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} shippingProviderId 
         * @param {UpdateShippingProviderRequest} updateShippingProviderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingProvidersUpdateShippingProvider: async (shippingProviderId: string, updateShippingProviderRequest: UpdateShippingProviderRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shippingProviderId' is not null or undefined
            assertParamExists('shippingProvidersUpdateShippingProvider', 'shippingProviderId', shippingProviderId)
            // verify required parameter 'updateShippingProviderRequest' is not null or undefined
            assertParamExists('shippingProvidersUpdateShippingProvider', 'updateShippingProviderRequest', updateShippingProviderRequest)
            const localVarPath = `/api/ShippingProviders/{shippingProviderId}`
                .replace(`{${"shippingProviderId"}}`, encodeURIComponent(String(shippingProviderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateShippingProviderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShippingProvidersApi - functional programming interface
 * @export
 */
export const ShippingProvidersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShippingProvidersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateShippingProviderRequest} createShippingProviderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shippingProvidersCreateShippingProvider(createShippingProviderRequest: CreateShippingProviderRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShippingProviderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shippingProvidersCreateShippingProvider(createShippingProviderRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} shippingProviderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shippingProvidersDeleteShippingProvider(shippingProviderId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shippingProvidersDeleteShippingProvider(shippingProviderId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} shippingProviderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shippingProvidersGetShippingProviderById(shippingProviderId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShippingProviderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shippingProvidersGetShippingProviderById(shippingProviderId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shippingProvidersGetShippingProviders(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShippingProviderResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shippingProvidersGetShippingProviders(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} shippingProviderId 
         * @param {UpdateShippingProviderRequest} updateShippingProviderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shippingProvidersUpdateShippingProvider(shippingProviderId: string, updateShippingProviderRequest: UpdateShippingProviderRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shippingProvidersUpdateShippingProvider(shippingProviderId, updateShippingProviderRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShippingProvidersApi - factory interface
 * @export
 */
export const ShippingProvidersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShippingProvidersApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateShippingProviderRequest} createShippingProviderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingProvidersCreateShippingProvider(createShippingProviderRequest: CreateShippingProviderRequest, xVersion?: string, options?: any): AxiosPromise<ShippingProviderResponse> {
            return localVarFp.shippingProvidersCreateShippingProvider(createShippingProviderRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} shippingProviderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingProvidersDeleteShippingProvider(shippingProviderId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.shippingProvidersDeleteShippingProvider(shippingProviderId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} shippingProviderId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingProvidersGetShippingProviderById(shippingProviderId: string, xVersion?: string, options?: any): AxiosPromise<ShippingProviderResponse> {
            return localVarFp.shippingProvidersGetShippingProviderById(shippingProviderId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingProvidersGetShippingProviders(xVersion?: string, options?: any): AxiosPromise<Array<ShippingProviderResponse>> {
            return localVarFp.shippingProvidersGetShippingProviders(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} shippingProviderId 
         * @param {UpdateShippingProviderRequest} updateShippingProviderRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingProvidersUpdateShippingProvider(shippingProviderId: string, updateShippingProviderRequest: UpdateShippingProviderRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.shippingProvidersUpdateShippingProvider(shippingProviderId, updateShippingProviderRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShippingProvidersApi - object-oriented interface
 * @export
 * @class ShippingProvidersApi
 * @extends {BaseAPI}
 */
export class ShippingProvidersApi extends BaseAPI {
    /**
     * 
     * @param {CreateShippingProviderRequest} createShippingProviderRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingProvidersApi
     */
    public shippingProvidersCreateShippingProvider(createShippingProviderRequest: CreateShippingProviderRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ShippingProvidersApiFp(this.configuration).shippingProvidersCreateShippingProvider(createShippingProviderRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} shippingProviderId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingProvidersApi
     */
    public shippingProvidersDeleteShippingProvider(shippingProviderId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ShippingProvidersApiFp(this.configuration).shippingProvidersDeleteShippingProvider(shippingProviderId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} shippingProviderId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingProvidersApi
     */
    public shippingProvidersGetShippingProviderById(shippingProviderId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return ShippingProvidersApiFp(this.configuration).shippingProvidersGetShippingProviderById(shippingProviderId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingProvidersApi
     */
    public shippingProvidersGetShippingProviders(xVersion?: string, options?: AxiosRequestConfig) {
        return ShippingProvidersApiFp(this.configuration).shippingProvidersGetShippingProviders(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} shippingProviderId 
     * @param {UpdateShippingProviderRequest} updateShippingProviderRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingProvidersApi
     */
    public shippingProvidersUpdateShippingProvider(shippingProviderId: string, updateShippingProviderRequest: UpdateShippingProviderRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return ShippingProvidersApiFp(this.configuration).shippingProvidersUpdateShippingProvider(shippingProviderId, updateShippingProviderRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SynchronizationsApi - axios parameter creator
 * @export
 */
export const SynchronizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationsSynchronizeCountries: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Synchronizations/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationsSynchronizeFiegeItemMasters: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Synchronizations/fiege/itemMasters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationsSynchronizeLanguages: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Synchronizations/languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationsSynchronizePaymentProviders: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Synchronizations/paymentProviders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationsSynchronizeProductUnits: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Synchronizations/productUnits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationsSynchronizeShippingProviders: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Synchronizations/shippingProviders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationsSynchronizeTaxTypes: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Synchronizations/taxTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationsSynchronizeTaxes: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Synchronizations/taxes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SynchronizationsApi - functional programming interface
 * @export
 */
export const SynchronizationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SynchronizationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async synchronizationsSynchronizeCountries(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SynchronizationActionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.synchronizationsSynchronizeCountries(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async synchronizationsSynchronizeFiegeItemMasters(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.synchronizationsSynchronizeFiegeItemMasters(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async synchronizationsSynchronizeLanguages(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SynchronizationActionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.synchronizationsSynchronizeLanguages(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async synchronizationsSynchronizePaymentProviders(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SynchronizationActionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.synchronizationsSynchronizePaymentProviders(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async synchronizationsSynchronizeProductUnits(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SynchronizationActionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.synchronizationsSynchronizeProductUnits(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async synchronizationsSynchronizeShippingProviders(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SynchronizationActionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.synchronizationsSynchronizeShippingProviders(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async synchronizationsSynchronizeTaxTypes(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SynchronizationActionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.synchronizationsSynchronizeTaxTypes(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async synchronizationsSynchronizeTaxes(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SynchronizationActionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.synchronizationsSynchronizeTaxes(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SynchronizationsApi - factory interface
 * @export
 */
export const SynchronizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SynchronizationsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationsSynchronizeCountries(xVersion?: string, options?: any): AxiosPromise<SynchronizationActionResponse> {
            return localVarFp.synchronizationsSynchronizeCountries(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationsSynchronizeFiegeItemMasters(xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.synchronizationsSynchronizeFiegeItemMasters(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationsSynchronizeLanguages(xVersion?: string, options?: any): AxiosPromise<SynchronizationActionResponse> {
            return localVarFp.synchronizationsSynchronizeLanguages(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationsSynchronizePaymentProviders(xVersion?: string, options?: any): AxiosPromise<SynchronizationActionResponse> {
            return localVarFp.synchronizationsSynchronizePaymentProviders(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationsSynchronizeProductUnits(xVersion?: string, options?: any): AxiosPromise<SynchronizationActionResponse> {
            return localVarFp.synchronizationsSynchronizeProductUnits(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationsSynchronizeShippingProviders(xVersion?: string, options?: any): AxiosPromise<SynchronizationActionResponse> {
            return localVarFp.synchronizationsSynchronizeShippingProviders(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationsSynchronizeTaxTypes(xVersion?: string, options?: any): AxiosPromise<SynchronizationActionResponse> {
            return localVarFp.synchronizationsSynchronizeTaxTypes(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizationsSynchronizeTaxes(xVersion?: string, options?: any): AxiosPromise<SynchronizationActionResponse> {
            return localVarFp.synchronizationsSynchronizeTaxes(xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SynchronizationsApi - object-oriented interface
 * @export
 * @class SynchronizationsApi
 * @extends {BaseAPI}
 */
export class SynchronizationsApi extends BaseAPI {
    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationsApi
     */
    public synchronizationsSynchronizeCountries(xVersion?: string, options?: AxiosRequestConfig) {
        return SynchronizationsApiFp(this.configuration).synchronizationsSynchronizeCountries(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationsApi
     */
    public synchronizationsSynchronizeFiegeItemMasters(xVersion?: string, options?: AxiosRequestConfig) {
        return SynchronizationsApiFp(this.configuration).synchronizationsSynchronizeFiegeItemMasters(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationsApi
     */
    public synchronizationsSynchronizeLanguages(xVersion?: string, options?: AxiosRequestConfig) {
        return SynchronizationsApiFp(this.configuration).synchronizationsSynchronizeLanguages(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationsApi
     */
    public synchronizationsSynchronizePaymentProviders(xVersion?: string, options?: AxiosRequestConfig) {
        return SynchronizationsApiFp(this.configuration).synchronizationsSynchronizePaymentProviders(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationsApi
     */
    public synchronizationsSynchronizeProductUnits(xVersion?: string, options?: AxiosRequestConfig) {
        return SynchronizationsApiFp(this.configuration).synchronizationsSynchronizeProductUnits(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationsApi
     */
    public synchronizationsSynchronizeShippingProviders(xVersion?: string, options?: AxiosRequestConfig) {
        return SynchronizationsApiFp(this.configuration).synchronizationsSynchronizeShippingProviders(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationsApi
     */
    public synchronizationsSynchronizeTaxTypes(xVersion?: string, options?: AxiosRequestConfig) {
        return SynchronizationsApiFp(this.configuration).synchronizationsSynchronizeTaxTypes(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizationsApi
     */
    public synchronizationsSynchronizeTaxes(xVersion?: string, options?: AxiosRequestConfig) {
        return SynchronizationsApiFp(this.configuration).synchronizationsSynchronizeTaxes(xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TagsApi - axios parameter creator
 * @export
 */
export const TagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateTagRequest} createTagRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsCreateTag: async (createTagRequest: CreateTagRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTagRequest' is not null or undefined
            assertParamExists('tagsCreateTag', 'createTagRequest', createTagRequest)
            const localVarPath = `/api/Tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTagRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tagId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsDeleteTag: async (tagId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('tagsDeleteTag', 'tagId', tagId)
            const localVarPath = `/api/Tags/{tagId}`
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tagId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsGetTagById: async (tagId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('tagsGetTagById', 'tagId', tagId)
            const localVarPath = `/api/Tags/{tagId}`
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsGetTags: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tagId 
         * @param {UpdateTagRequest} updateTagRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsUpdateTag: async (tagId: string, updateTagRequest: UpdateTagRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('tagsUpdateTag', 'tagId', tagId)
            // verify required parameter 'updateTagRequest' is not null or undefined
            assertParamExists('tagsUpdateTag', 'updateTagRequest', updateTagRequest)
            const localVarPath = `/api/Tags/{tagId}`
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTagRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagsApi - functional programming interface
 * @export
 */
export const TagsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateTagRequest} createTagRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tagsCreateTag(createTagRequest: CreateTagRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tagsCreateTag(createTagRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tagId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tagsDeleteTag(tagId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tagsDeleteTag(tagId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tagId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tagsGetTagById(tagId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tagsGetTagById(tagId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tagsGetTags(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TagResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tagsGetTags(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tagId 
         * @param {UpdateTagRequest} updateTagRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tagsUpdateTag(tagId: string, updateTagRequest: UpdateTagRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tagsUpdateTag(tagId, updateTagRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TagsApi - factory interface
 * @export
 */
export const TagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateTagRequest} createTagRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsCreateTag(createTagRequest: CreateTagRequest, xVersion?: string, options?: any): AxiosPromise<TagResponse> {
            return localVarFp.tagsCreateTag(createTagRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tagId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsDeleteTag(tagId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.tagsDeleteTag(tagId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tagId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsGetTagById(tagId: string, xVersion?: string, options?: any): AxiosPromise<TagResponse> {
            return localVarFp.tagsGetTagById(tagId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsGetTags(xVersion?: string, options?: any): AxiosPromise<Array<TagResponse>> {
            return localVarFp.tagsGetTags(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tagId 
         * @param {UpdateTagRequest} updateTagRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagsUpdateTag(tagId: string, updateTagRequest: UpdateTagRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.tagsUpdateTag(tagId, updateTagRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TagsApi - object-oriented interface
 * @export
 * @class TagsApi
 * @extends {BaseAPI}
 */
export class TagsApi extends BaseAPI {
    /**
     * 
     * @param {CreateTagRequest} createTagRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public tagsCreateTag(createTagRequest: CreateTagRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return TagsApiFp(this.configuration).tagsCreateTag(createTagRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tagId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public tagsDeleteTag(tagId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return TagsApiFp(this.configuration).tagsDeleteTag(tagId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tagId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public tagsGetTagById(tagId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return TagsApiFp(this.configuration).tagsGetTagById(tagId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public tagsGetTags(xVersion?: string, options?: AxiosRequestConfig) {
        return TagsApiFp(this.configuration).tagsGetTags(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tagId 
     * @param {UpdateTagRequest} updateTagRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public tagsUpdateTag(tagId: string, updateTagRequest: UpdateTagRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return TagsApiFp(this.configuration).tagsUpdateTag(tagId, updateTagRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TaxTypesApi - axios parameter creator
 * @export
 */
export const TaxTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} taxTypeId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxTypesGetTaxTypeById: async (taxTypeId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taxTypeId' is not null or undefined
            assertParamExists('taxTypesGetTaxTypeById', 'taxTypeId', taxTypeId)
            const localVarPath = `/api/TaxTypes/{taxTypeId}`
                .replace(`{${"taxTypeId"}}`, encodeURIComponent(String(taxTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxTypesGetTaxTypes: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/TaxTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaxTypesApi - functional programming interface
 * @export
 */
export const TaxTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaxTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} taxTypeId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxTypesGetTaxTypeById(taxTypeId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxTypesGetTaxTypeById(taxTypeId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxTypesGetTaxTypes(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaxTypeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxTypesGetTaxTypes(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaxTypesApi - factory interface
 * @export
 */
export const TaxTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaxTypesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} taxTypeId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxTypesGetTaxTypeById(taxTypeId: string, xVersion?: string, options?: any): AxiosPromise<TaxTypeResponse> {
            return localVarFp.taxTypesGetTaxTypeById(taxTypeId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxTypesGetTaxTypes(xVersion?: string, options?: any): AxiosPromise<Array<TaxTypeResponse>> {
            return localVarFp.taxTypesGetTaxTypes(xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaxTypesApi - object-oriented interface
 * @export
 * @class TaxTypesApi
 * @extends {BaseAPI}
 */
export class TaxTypesApi extends BaseAPI {
    /**
     * 
     * @param {string} taxTypeId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxTypesApi
     */
    public taxTypesGetTaxTypeById(taxTypeId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return TaxTypesApiFp(this.configuration).taxTypesGetTaxTypeById(taxTypeId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxTypesApi
     */
    public taxTypesGetTaxTypes(xVersion?: string, options?: AxiosRequestConfig) {
        return TaxTypesApiFp(this.configuration).taxTypesGetTaxTypes(xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TaxesApi - axios parameter creator
 * @export
 */
export const TaxesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} taxId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxesGetTaxById: async (taxId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taxId' is not null or undefined
            assertParamExists('taxesGetTaxById', 'taxId', taxId)
            const localVarPath = `/api/Taxes/{taxId}`
                .replace(`{${"taxId"}}`, encodeURIComponent(String(taxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [countryId] 
         * @param {string} [taxTypeId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxesGetTaxes: async (countryId?: string, taxTypeId?: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Taxes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (countryId !== undefined) {
                localVarQueryParameter['CountryId'] = countryId;
            }

            if (taxTypeId !== undefined) {
                localVarQueryParameter['TaxTypeId'] = taxTypeId;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaxesApi - functional programming interface
 * @export
 */
export const TaxesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaxesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} taxId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxesGetTaxById(taxId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxesGetTaxById(taxId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [countryId] 
         * @param {string} [taxTypeId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxesGetTaxes(countryId?: string, taxTypeId?: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaxResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxesGetTaxes(countryId, taxTypeId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaxesApi - factory interface
 * @export
 */
export const TaxesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaxesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} taxId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxesGetTaxById(taxId: string, xVersion?: string, options?: any): AxiosPromise<TaxResponse> {
            return localVarFp.taxesGetTaxById(taxId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [countryId] 
         * @param {string} [taxTypeId] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxesGetTaxes(countryId?: string, taxTypeId?: string, xVersion?: string, options?: any): AxiosPromise<Array<TaxResponse>> {
            return localVarFp.taxesGetTaxes(countryId, taxTypeId, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaxesApi - object-oriented interface
 * @export
 * @class TaxesApi
 * @extends {BaseAPI}
 */
export class TaxesApi extends BaseAPI {
    /**
     * 
     * @param {string} taxId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxesApi
     */
    public taxesGetTaxById(taxId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return TaxesApiFp(this.configuration).taxesGetTaxById(taxId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [countryId] 
     * @param {string} [taxTypeId] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxesApi
     */
    public taxesGetTaxes(countryId?: string, taxTypeId?: string, xVersion?: string, options?: AxiosRequestConfig) {
        return TaxesApiFp(this.configuration).taxesGetTaxes(countryId, taxTypeId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserRolesApi - axios parameter creator
 * @export
 */
export const UserRolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userRoleId 
         * @param {AddUserRolePoliciesRequest} addUserRolePoliciesRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesAddUserRolePolicies: async (userRoleId: string, addUserRolePoliciesRequest: AddUserRolePoliciesRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRoleId' is not null or undefined
            assertParamExists('userRolesAddUserRolePolicies', 'userRoleId', userRoleId)
            // verify required parameter 'addUserRolePoliciesRequest' is not null or undefined
            assertParamExists('userRolesAddUserRolePolicies', 'addUserRolePoliciesRequest', addUserRolePoliciesRequest)
            const localVarPath = `/api/UserRoles/{userRoleId}/policies`
                .replace(`{${"userRoleId"}}`, encodeURIComponent(String(userRoleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addUserRolePoliciesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserRoleRequest} createUserRoleRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesCreateUserRole: async (createUserRoleRequest: CreateUserRoleRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserRoleRequest' is not null or undefined
            assertParamExists('userRolesCreateUserRole', 'createUserRoleRequest', createUserRoleRequest)
            const localVarPath = `/api/UserRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRoleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userRoleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesDeleteUserRole: async (userRoleId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRoleId' is not null or undefined
            assertParamExists('userRolesDeleteUserRole', 'userRoleId', userRoleId)
            const localVarPath = `/api/UserRoles/{userRoleId}`
                .replace(`{${"userRoleId"}}`, encodeURIComponent(String(userRoleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userRoleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesGetUserRoleById: async (userRoleId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRoleId' is not null or undefined
            assertParamExists('userRolesGetUserRoleById', 'userRoleId', userRoleId)
            const localVarPath = `/api/UserRoles/{userRoleId}`
                .replace(`{${"userRoleId"}}`, encodeURIComponent(String(userRoleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesGetUserRoles: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/UserRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userRoleId 
         * @param {RemoveUserRolePoliciesRequest} removeUserRolePoliciesRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesRemoveUserRolePolicies: async (userRoleId: string, removeUserRolePoliciesRequest: RemoveUserRolePoliciesRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRoleId' is not null or undefined
            assertParamExists('userRolesRemoveUserRolePolicies', 'userRoleId', userRoleId)
            // verify required parameter 'removeUserRolePoliciesRequest' is not null or undefined
            assertParamExists('userRolesRemoveUserRolePolicies', 'removeUserRolePoliciesRequest', removeUserRolePoliciesRequest)
            const localVarPath = `/api/UserRoles/{userRoleId}/policies`
                .replace(`{${"userRoleId"}}`, encodeURIComponent(String(userRoleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeUserRolePoliciesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userRoleId 
         * @param {UpdateUserRoleRequest} updateUserRoleRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesUpdateUserRole: async (userRoleId: string, updateUserRoleRequest: UpdateUserRoleRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRoleId' is not null or undefined
            assertParamExists('userRolesUpdateUserRole', 'userRoleId', userRoleId)
            // verify required parameter 'updateUserRoleRequest' is not null or undefined
            assertParamExists('userRolesUpdateUserRole', 'updateUserRoleRequest', updateUserRoleRequest)
            const localVarPath = `/api/UserRoles/{userRoleId}`
                .replace(`{${"userRoleId"}}`, encodeURIComponent(String(userRoleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRoleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserRolesApi - functional programming interface
 * @export
 */
export const UserRolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserRolesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userRoleId 
         * @param {AddUserRolePoliciesRequest} addUserRolePoliciesRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRolesAddUserRolePolicies(userRoleId: string, addUserRolePoliciesRequest: AddUserRolePoliciesRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRoleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRolesAddUserRolePolicies(userRoleId, addUserRolePoliciesRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateUserRoleRequest} createUserRoleRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRolesCreateUserRole(createUserRoleRequest: CreateUserRoleRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRoleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRolesCreateUserRole(createUserRoleRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userRoleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRolesDeleteUserRole(userRoleId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRolesDeleteUserRole(userRoleId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userRoleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRolesGetUserRoleById(userRoleId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRoleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRolesGetUserRoleById(userRoleId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRolesGetUserRoles(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserRoleResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRolesGetUserRoles(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userRoleId 
         * @param {RemoveUserRolePoliciesRequest} removeUserRolePoliciesRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRolesRemoveUserRolePolicies(userRoleId: string, removeUserRolePoliciesRequest: RemoveUserRolePoliciesRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRolesRemoveUserRolePolicies(userRoleId, removeUserRolePoliciesRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userRoleId 
         * @param {UpdateUserRoleRequest} updateUserRoleRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRolesUpdateUserRole(userRoleId: string, updateUserRoleRequest: UpdateUserRoleRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRolesUpdateUserRole(userRoleId, updateUserRoleRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserRolesApi - factory interface
 * @export
 */
export const UserRolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserRolesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userRoleId 
         * @param {AddUserRolePoliciesRequest} addUserRolePoliciesRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesAddUserRolePolicies(userRoleId: string, addUserRolePoliciesRequest: AddUserRolePoliciesRequest, xVersion?: string, options?: any): AxiosPromise<UserRoleResponse> {
            return localVarFp.userRolesAddUserRolePolicies(userRoleId, addUserRolePoliciesRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateUserRoleRequest} createUserRoleRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesCreateUserRole(createUserRoleRequest: CreateUserRoleRequest, xVersion?: string, options?: any): AxiosPromise<UserRoleResponse> {
            return localVarFp.userRolesCreateUserRole(createUserRoleRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userRoleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesDeleteUserRole(userRoleId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.userRolesDeleteUserRole(userRoleId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userRoleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesGetUserRoleById(userRoleId: string, xVersion?: string, options?: any): AxiosPromise<UserRoleResponse> {
            return localVarFp.userRolesGetUserRoleById(userRoleId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesGetUserRoles(xVersion?: string, options?: any): AxiosPromise<Array<UserRoleResponse>> {
            return localVarFp.userRolesGetUserRoles(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userRoleId 
         * @param {RemoveUserRolePoliciesRequest} removeUserRolePoliciesRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesRemoveUserRolePolicies(userRoleId: string, removeUserRolePoliciesRequest: RemoveUserRolePoliciesRequest, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.userRolesRemoveUserRolePolicies(userRoleId, removeUserRolePoliciesRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userRoleId 
         * @param {UpdateUserRoleRequest} updateUserRoleRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRolesUpdateUserRole(userRoleId: string, updateUserRoleRequest: UpdateUserRoleRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.userRolesUpdateUserRole(userRoleId, updateUserRoleRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserRolesApi - object-oriented interface
 * @export
 * @class UserRolesApi
 * @extends {BaseAPI}
 */
export class UserRolesApi extends BaseAPI {
    /**
     * 
     * @param {string} userRoleId 
     * @param {AddUserRolePoliciesRequest} addUserRolePoliciesRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public userRolesAddUserRolePolicies(userRoleId: string, addUserRolePoliciesRequest: AddUserRolePoliciesRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return UserRolesApiFp(this.configuration).userRolesAddUserRolePolicies(userRoleId, addUserRolePoliciesRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateUserRoleRequest} createUserRoleRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public userRolesCreateUserRole(createUserRoleRequest: CreateUserRoleRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return UserRolesApiFp(this.configuration).userRolesCreateUserRole(createUserRoleRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userRoleId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public userRolesDeleteUserRole(userRoleId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return UserRolesApiFp(this.configuration).userRolesDeleteUserRole(userRoleId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userRoleId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public userRolesGetUserRoleById(userRoleId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return UserRolesApiFp(this.configuration).userRolesGetUserRoleById(userRoleId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public userRolesGetUserRoles(xVersion?: string, options?: AxiosRequestConfig) {
        return UserRolesApiFp(this.configuration).userRolesGetUserRoles(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userRoleId 
     * @param {RemoveUserRolePoliciesRequest} removeUserRolePoliciesRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public userRolesRemoveUserRolePolicies(userRoleId: string, removeUserRolePoliciesRequest: RemoveUserRolePoliciesRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return UserRolesApiFp(this.configuration).userRolesRemoveUserRolePolicies(userRoleId, removeUserRolePoliciesRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userRoleId 
     * @param {UpdateUserRoleRequest} updateUserRoleRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public userRolesUpdateUserRole(userRoleId: string, updateUserRoleRequest: UpdateUserRoleRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return UserRolesApiFp(this.configuration).userRolesUpdateUserRole(userRoleId, updateUserRoleRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateUserRequest} createUserRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCreateUser: async (createUserRequest: CreateUserRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserRequest' is not null or undefined
            assertParamExists('usersCreateUser', 'createUserRequest', createUserRequest)
            const localVarPath = `/api/Users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {CreateUserRoleMappingRequest} createUserRoleMappingRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCreateUserRoleMapping: async (userId: string, createUserRoleMappingRequest: CreateUserRoleMappingRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('usersCreateUserRoleMapping', 'userId', userId)
            // verify required parameter 'createUserRoleMappingRequest' is not null or undefined
            assertParamExists('usersCreateUserRoleMapping', 'createUserRoleMappingRequest', createUserRoleMappingRequest)
            const localVarPath = `/api/Users/{userId}/roles`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRoleMappingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersDeleteUser: async (userId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('usersDeleteUser', 'userId', userId)
            const localVarPath = `/api/Users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} roleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersDeleteUserRoleMapping: async (userId: string, roleId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('usersDeleteUserRoleMapping', 'userId', userId)
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('usersDeleteUserRoleMapping', 'roleId', roleId)
            const localVarPath = `/api/Users/{userId}/roles/{roleId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserSettingKey} [userSettingKey] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersDeleteUserSetting: async (userSettingKey?: UserSettingKey, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userSettingKey !== undefined) {
                localVarQueryParameter['userSettingKey'] = userSettingKey;
            }

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGetCurrentUser: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGetUserById: async (userId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('usersGetUserById', 'userId', userId)
            const localVarPath = `/api/Users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGetUsers: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateSubscribedNotificationsRequest} updateSubscribedNotificationsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdateSubscribedNotifications: async (userId: string, updateSubscribedNotificationsRequest: UpdateSubscribedNotificationsRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('usersUpdateSubscribedNotifications', 'userId', userId)
            // verify required parameter 'updateSubscribedNotificationsRequest' is not null or undefined
            assertParamExists('usersUpdateSubscribedNotifications', 'updateSubscribedNotificationsRequest', updateSubscribedNotificationsRequest)
            const localVarPath = `/api/Users/{userId}/notifications`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSubscribedNotificationsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdateUser: async (userId: string, updateUserRequest: UpdateUserRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('usersUpdateUser', 'userId', userId)
            // verify required parameter 'updateUserRequest' is not null or undefined
            assertParamExists('usersUpdateUser', 'updateUserRequest', updateUserRequest)
            const localVarPath = `/api/Users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserSettingRequest} updateUserSettingRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdateUserSetting: async (updateUserSettingRequest: UpdateUserSettingRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserSettingRequest' is not null or undefined
            assertParamExists('usersUpdateUserSetting', 'updateUserSettingRequest', updateUserSettingRequest)
            const localVarPath = `/api/Users/setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserSettingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateUserRequest} createUserRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersCreateUser(createUserRequest: CreateUserRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersCreateUser(createUserRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {CreateUserRoleMappingRequest} createUserRoleMappingRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersCreateUserRoleMapping(userId: string, createUserRoleMappingRequest: CreateUserRoleMappingRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersCreateUserRoleMapping(userId, createUserRoleMappingRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersDeleteUser(userId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersDeleteUser(userId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} roleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersDeleteUserRoleMapping(userId: string, roleId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersDeleteUserRoleMapping(userId, roleId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserSettingKey} [userSettingKey] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersDeleteUserSetting(userSettingKey?: UserSettingKey, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersDeleteUserSetting(userSettingKey, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGetCurrentUser(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGetCurrentUser(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGetUserById(userId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGetUserById(userId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGetUsers(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGetUsers(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateSubscribedNotificationsRequest} updateSubscribedNotificationsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUpdateSubscribedNotifications(userId: string, updateSubscribedNotificationsRequest: UpdateSubscribedNotificationsRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUpdateSubscribedNotifications(userId, updateSubscribedNotificationsRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUpdateUser(userId: string, updateUserRequest: UpdateUserRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUpdateUser(userId, updateUserRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateUserSettingRequest} updateUserSettingRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUpdateUserSetting(updateUserSettingRequest: UpdateUserSettingRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUpdateUserSetting(updateUserSettingRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateUserRequest} createUserRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCreateUser(createUserRequest: CreateUserRequest, xVersion?: string, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.usersCreateUser(createUserRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {CreateUserRoleMappingRequest} createUserRoleMappingRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCreateUserRoleMapping(userId: string, createUserRoleMappingRequest: CreateUserRoleMappingRequest, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.usersCreateUserRoleMapping(userId, createUserRoleMappingRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersDeleteUser(userId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.usersDeleteUser(userId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} roleId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersDeleteUserRoleMapping(userId: string, roleId: string, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.usersDeleteUserRoleMapping(userId, roleId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserSettingKey} [userSettingKey] 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersDeleteUserSetting(userSettingKey?: UserSettingKey, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.usersDeleteUserSetting(userSettingKey, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGetCurrentUser(xVersion?: string, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.usersGetCurrentUser(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGetUserById(userId: string, xVersion?: string, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.usersGetUserById(userId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGetUsers(xVersion?: string, options?: any): AxiosPromise<Array<UserResponse>> {
            return localVarFp.usersGetUsers(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateSubscribedNotificationsRequest} updateSubscribedNotificationsRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdateSubscribedNotifications(userId: string, updateSubscribedNotificationsRequest: UpdateSubscribedNotificationsRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.usersUpdateSubscribedNotifications(userId, updateSubscribedNotificationsRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdateUser(userId: string, updateUserRequest: UpdateUserRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.usersUpdateUser(userId, updateUserRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserSettingRequest} updateUserSettingRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdateUserSetting(updateUserSettingRequest: UpdateUserSettingRequest, xVersion?: string, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.usersUpdateUserSetting(updateUserSettingRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {CreateUserRequest} createUserRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersCreateUser(createUserRequest: CreateUserRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersCreateUser(createUserRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {CreateUserRoleMappingRequest} createUserRoleMappingRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersCreateUserRoleMapping(userId: string, createUserRoleMappingRequest: CreateUserRoleMappingRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersCreateUserRoleMapping(userId, createUserRoleMappingRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersDeleteUser(userId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersDeleteUser(userId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {string} roleId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersDeleteUserRoleMapping(userId: string, roleId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersDeleteUserRoleMapping(userId, roleId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserSettingKey} [userSettingKey] 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersDeleteUserSetting(userSettingKey?: UserSettingKey, xVersion?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersDeleteUserSetting(userSettingKey, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersGetCurrentUser(xVersion?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersGetCurrentUser(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersGetUserById(userId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersGetUserById(userId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersGetUsers(xVersion?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersGetUsers(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {UpdateSubscribedNotificationsRequest} updateSubscribedNotificationsRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUpdateSubscribedNotifications(userId: string, updateSubscribedNotificationsRequest: UpdateSubscribedNotificationsRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUpdateSubscribedNotifications(userId, updateSubscribedNotificationsRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {UpdateUserRequest} updateUserRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUpdateUser(userId: string, updateUserRequest: UpdateUserRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUpdateUser(userId, updateUserRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserSettingRequest} updateUserSettingRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUpdateUserSetting(updateUserSettingRequest: UpdateUserSettingRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUpdateUserSetting(updateUserSettingRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WarehousesApi - axios parameter creator
 * @export
 */
export const WarehousesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateWarehouseRequest} createWarehouseRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousesCreateWarehouse: async (createWarehouseRequest: CreateWarehouseRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createWarehouseRequest' is not null or undefined
            assertParamExists('warehousesCreateWarehouse', 'createWarehouseRequest', createWarehouseRequest)
            const localVarPath = `/api/Warehouses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWarehouseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} warehouseId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousesDeleteWarehouse: async (warehouseId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('warehousesDeleteWarehouse', 'warehouseId', warehouseId)
            const localVarPath = `/api/Warehouses/{warehouseId}`
                .replace(`{${"warehouseId"}}`, encodeURIComponent(String(warehouseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} warehouseId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousesGetWarehouseById: async (warehouseId: string, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('warehousesGetWarehouseById', 'warehouseId', warehouseId)
            const localVarPath = `/api/Warehouses/{warehouseId}`
                .replace(`{${"warehouseId"}}`, encodeURIComponent(String(warehouseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousesGetWarehouses: async (xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Warehouses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DataSynchronizationRequest} dataSynchronizationRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousesSync: async (dataSynchronizationRequest: DataSynchronizationRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataSynchronizationRequest' is not null or undefined
            assertParamExists('warehousesSync', 'dataSynchronizationRequest', dataSynchronizationRequest)
            const localVarPath = `/api/Warehouses/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataSynchronizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} warehouseId 
         * @param {UpdateWarehouseRequest} updateWarehouseRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousesUpdateWarehouse: async (warehouseId: string, updateWarehouseRequest: UpdateWarehouseRequest, xVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('warehousesUpdateWarehouse', 'warehouseId', warehouseId)
            // verify required parameter 'updateWarehouseRequest' is not null or undefined
            assertParamExists('warehousesUpdateWarehouse', 'updateWarehouseRequest', updateWarehouseRequest)
            const localVarPath = `/api/Warehouses/{warehouseId}`
                .replace(`{${"warehouseId"}}`, encodeURIComponent(String(warehouseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xVersion !== undefined && xVersion !== null) {
                localVarHeaderParameter['X-Version'] = String(xVersion);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWarehouseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WarehousesApi - functional programming interface
 * @export
 */
export const WarehousesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WarehousesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateWarehouseRequest} createWarehouseRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async warehousesCreateWarehouse(createWarehouseRequest: CreateWarehouseRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarehouseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.warehousesCreateWarehouse(createWarehouseRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} warehouseId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async warehousesDeleteWarehouse(warehouseId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.warehousesDeleteWarehouse(warehouseId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} warehouseId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async warehousesGetWarehouseById(warehouseId: string, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarehouseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.warehousesGetWarehouseById(warehouseId, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async warehousesGetWarehouses(xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WarehouseResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.warehousesGetWarehouses(xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DataSynchronizationRequest} dataSynchronizationRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async warehousesSync(dataSynchronizationRequest: DataSynchronizationRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.warehousesSync(dataSynchronizationRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} warehouseId 
         * @param {UpdateWarehouseRequest} updateWarehouseRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async warehousesUpdateWarehouse(warehouseId: string, updateWarehouseRequest: UpdateWarehouseRequest, xVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.warehousesUpdateWarehouse(warehouseId, updateWarehouseRequest, xVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WarehousesApi - factory interface
 * @export
 */
export const WarehousesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WarehousesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateWarehouseRequest} createWarehouseRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousesCreateWarehouse(createWarehouseRequest: CreateWarehouseRequest, xVersion?: string, options?: any): AxiosPromise<WarehouseResponse> {
            return localVarFp.warehousesCreateWarehouse(createWarehouseRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} warehouseId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousesDeleteWarehouse(warehouseId: string, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.warehousesDeleteWarehouse(warehouseId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} warehouseId 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousesGetWarehouseById(warehouseId: string, xVersion?: string, options?: any): AxiosPromise<WarehouseResponse> {
            return localVarFp.warehousesGetWarehouseById(warehouseId, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousesGetWarehouses(xVersion?: string, options?: any): AxiosPromise<Array<WarehouseResponse>> {
            return localVarFp.warehousesGetWarehouses(xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DataSynchronizationRequest} dataSynchronizationRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousesSync(dataSynchronizationRequest: DataSynchronizationRequest, xVersion?: string, options?: any): AxiosPromise<any> {
            return localVarFp.warehousesSync(dataSynchronizationRequest, xVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} warehouseId 
         * @param {UpdateWarehouseRequest} updateWarehouseRequest 
         * @param {string} [xVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        warehousesUpdateWarehouse(warehouseId: string, updateWarehouseRequest: UpdateWarehouseRequest, xVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.warehousesUpdateWarehouse(warehouseId, updateWarehouseRequest, xVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WarehousesApi - object-oriented interface
 * @export
 * @class WarehousesApi
 * @extends {BaseAPI}
 */
export class WarehousesApi extends BaseAPI {
    /**
     * 
     * @param {CreateWarehouseRequest} createWarehouseRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehousesApi
     */
    public warehousesCreateWarehouse(createWarehouseRequest: CreateWarehouseRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return WarehousesApiFp(this.configuration).warehousesCreateWarehouse(createWarehouseRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} warehouseId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehousesApi
     */
    public warehousesDeleteWarehouse(warehouseId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return WarehousesApiFp(this.configuration).warehousesDeleteWarehouse(warehouseId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} warehouseId 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehousesApi
     */
    public warehousesGetWarehouseById(warehouseId: string, xVersion?: string, options?: AxiosRequestConfig) {
        return WarehousesApiFp(this.configuration).warehousesGetWarehouseById(warehouseId, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehousesApi
     */
    public warehousesGetWarehouses(xVersion?: string, options?: AxiosRequestConfig) {
        return WarehousesApiFp(this.configuration).warehousesGetWarehouses(xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataSynchronizationRequest} dataSynchronizationRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehousesApi
     */
    public warehousesSync(dataSynchronizationRequest: DataSynchronizationRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return WarehousesApiFp(this.configuration).warehousesSync(dataSynchronizationRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} warehouseId 
     * @param {UpdateWarehouseRequest} updateWarehouseRequest 
     * @param {string} [xVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehousesApi
     */
    public warehousesUpdateWarehouse(warehouseId: string, updateWarehouseRequest: UpdateWarehouseRequest, xVersion?: string, options?: AxiosRequestConfig) {
        return WarehousesApiFp(this.configuration).warehousesUpdateWarehouse(warehouseId, updateWarehouseRequest, xVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


